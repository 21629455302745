
import { SteDeviceViewModelImplemented, } from "./ste.vmi";

export class SteRadPackDeviceViewModelImplemented extends SteDeviceViewModelImplemented {

	public thumbnailImagePath:string = "assets/app/ste/radpack.png";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public static getStaticTitle():string{ // so we can access this w/o an instance i guess
		return super.getStaticTitle() + " RadPack"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return SteRadPackDeviceViewModelImplemented.getStaticTitle();
	}
	
}