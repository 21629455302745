
import { DeviceTypeIds, DeviceViewModelImplemented, } from "../../viewmodels/Device.vmi";
import { AllServcies, } from "../../services/startup/startup.service";
import { GPSScanRecord, Telemetry, VendorData, RadationData, NeutronData, GammaData, GammaNeutronState } from "../../generated_proto/protobuf-ts/pb/v2/data";
import { BluetoothCommonInterface } from "../../services/bluetooth/bluetooth.service";
import { TelemetryViewModelImplemented, } from "../../viewmodels/Telemetry.vmi"
import { MapDataPoint } from "../../services/map/map.interfaces";

import { SimpleEccFrame, UtpMessageType } from "../../services/simple-ecc/simple-ecc.frame";

import { SteBluetoothConnection } from "./ste.vmi"

import * as JSON_IMPORT from "./STE_LRM_Neutron.json"

import { UtilService } from "../../services/util/util.service";

export class LrmBluetoothConnection extends SteBluetoothConnection {

	public heartBeatIntervalMs:number = 1500;

	constructor
	(
		services:AllServcies,
		bluetoothCommonInterface?:BluetoothCommonInterface,
	)
	{
		super(services, bluetoothCommonInterface);
		if(services){
			this.services = services;
		}
		if(bluetoothCommonInterface){
			this.bluetoothCommonInterface = bluetoothCommonInterface;
			// console.log("CREATED STEBLUETOOTH CONNECTION : ", this.bluetoothCommonInterface);
		}
		else {
			console.error("ERROR CREATING STEBLUETOOTH CONNECTION");
		}
	}

	private delay(ms: number) {
		return new Promise( resolve => setTimeout(resolve, ms) );
	}

	public async startImportFile(json?:any) {
		
		var parsed;
		if(json){
			parsed = json;
		}
		else {
			parsed = JSON.parse(JSON.stringify(JSON_IMPORT));
		}
		
		for (var i = 0; i < parsed.length; i++) {
			var event = parsed[i];
			if(parsed[i+1] && parsed[i+1].epoch){
				var time = BigInt(parsed[i+1].epoch) - BigInt(event.epoch);
				await this.delay(Number(time));
			}
			if(event.raw){
				this.processStreamingData(new TextEncoder().encode(event.raw));
			}
		}
	}
	
	private buffer:string = "";
	processStreamingData(data: Uint8Array): Promise<void> {
		const CPS_MULTIPLIER = 4;
		return new Promise( async (resolve, reject) => {
			if(!data || data.length<= 0){
				reject("No Data");
				return;
			}
			var newDataAsText = new TextDecoder().decode(data);

			this.buffer += newDataAsText;
			
			// remove all "?" from string
			this.buffer = this.buffer.replace(/\?/g, "");
			// console.log("New Data: ", new TextDecoder().decode(data))
			if(this.buffer.includes("\r\n")){
				// console.log("************************************")
				// console.log("Complete message -> ready for parsing")
				// console.log(":: this.buffer: ", this.buffer)
				// console.log("************************************")

				// remove all "\r\n" from string
				var fullTelemetry = this.buffer.replace(/\r\n/g, "");
				this.buffer = "";
				var telemetry = Telemetry.create();
				var radationData = RadationData.create();

				const tokens = fullTelemetry.split(",");
				// console.log("tokens", tokens);
				while(tokens.length > 0){
					var token = tokens[0];
					if(token == ' '){
						tokens.shift();
						continue;
					}
					if(token && (token[0] == "N" || token[0] == "G" && token.length>0)){
						// if(tokens[1] == ' '){
						// 	tokens.shift();
						// 	continue;
						// }
						if(token[0] == "N"){
							var neutron:NeutronData = NeutronData.create();
							var number = Number(token.substring(1, token.length));
							if( tokens[1] != "<" && tokens[1] != ">"){
								neutron.neuronAlarmLevel = Math.round(UtilService.stringToInteger(tokens[1]));
							}
							else {
								neutron.neuronAlarmLevel = 0;
							}
							if(token[1] != "T" && tokens[2] && tokens[2].length>0){
								if(tokens[2] == "*"){
									neutron.neutronState = GammaNeutronState.MAX_SATURATION;
									neutron.neutronTotalGrossCounts1E1 = 0;
								}
								else {
									var value = Math.round(UtilService.stringToInteger(tokens[2])) * 1e1;
									if(value){
										neutron.neutronTotalGrossCounts1E1 = value;
									}
									else {
										neutron.neutronTotalGrossCounts1E1 = 0;
									}
								}
							}
							if(tokens[1] == "*"){
								neutron.neutronState = GammaNeutronState.MAX_SATURATION;
							}
							if(tokens[1] == "U"){
								neutron.neutronState = GammaNeutronState.UPDATE;
							}
							if(tokens[2] && tokens[2].length>0){
								neutron.neutronTotalGrossCounts1E1 = ((UtilService.stringToFloat(tokens[2]) * 1e1));
							}
							else {
								neutron.neutronTotalGrossCounts1E1 = 0;
							}
							if(token[1] == "T"){
								neutron.neutronAlarmCountThreshold = ((UtilService.stringToFloat(tokens[3]) * 1e1));
								tokens.shift(); // remove threshold
								radationData.neutronData = neutron;
							}
							else {
								neutron.collectionId = number;
								// if number is already in neutronDataSensors update else add
								var found = false;
								for(var i = 0; i < radationData.neutronDataSensors.length; i++){
									if(radationData.neutronDataSensors[i].collectionId == number){
										radationData.neutronDataSensors[i] = neutron;
										found = true;
										break;
									}
								}
								if(!found){
									radationData.neutronDataSensors.push(neutron);
								}
							}
							tokens.shift(); // remove N(t)
							tokens.shift(); // remove alarm level
							tokens.shift(); // remove total count
						}
						else if(token[0] == "G"){
							var number = Number(token.substring(1, token.length));
							var gamma:GammaData = GammaData.create();
							var number = Number(token.substring(1, token.length));
							
							if(tokens[1] != "*" && tokens[1] != "<" && tokens[1] != ">"){
								gamma.gammaAlarmLevel = Math.round(UtilService.stringToInteger(tokens[1]));
							}
							else {
								gamma.gammaAlarmLevel = 0;
							}
							if(token[1] != "T" && tokens[2] && tokens[2].length>0){
								if(tokens[2] == "*"){
									gamma.gammaState = GammaNeutronState.MAX_SATURATION;
									gamma.gammaTotalGrossCounts = 0;
								}
								else {
									var value = Math.round(UtilService.stringToInteger(tokens[2])) * CPS_MULTIPLIER;
									if(value){
										gamma.gammaTotalGrossCounts = value;
									}
									else {
										gamma.gammaTotalGrossCounts = 0;
									}
								}
							}
							else {
								gamma.gammaTotalGrossCounts = 0;
							}
							if(tokens[1] == "*"){
								gamma.gammaState = GammaNeutronState.MAX_SATURATION;
							}
							if(tokens[1] == "U"){
								gamma.gammaState = GammaNeutronState.UPDATE;
							}
							if(token[1] == "T"){
								// console.log("Found G(t): tokens[3]: ", tokens[3]);
								// console.log("Found G(t): UtilService.stringToInteger(tokens[3]): ", UtilService.stringToInteger(tokens[3]));
								// console.log("Found G(t): Math.round(UtilService.stringToInteger(tokens[3])): ", Math.round(UtilService.stringToInteger(tokens[3])));

								gamma.gammaAlarmCountThreshold = Math.round(UtilService.stringToInteger(tokens[3])) * CPS_MULTIPLIER;
								tokens.shift(); // remove threshold
								radationData.gammaData = gamma;
							}
							else {
								gamma.collectionId = number;
								// if number is already in gammaDataSensors update else add
								var found = false;
								for(var i = 0; i < radationData.gammaDataSensors.length; i++){
									if(radationData.gammaDataSensors[i].collectionId == number){
										radationData.gammaDataSensors[i] = gamma;
										found = true;
										break;
									}
								}
								if(!found){
									radationData.gammaDataSensors.push(gamma);
								}
							}
							tokens.shift(); // remove N(t)
							tokens.shift(); // remove alarm level
							tokens.shift(); // remove total count
						}
					}
					else{
						tokens.shift();
					}
				}
				// console.log("adding raditioan data: ", radationData)
				telemetry.vendorData = VendorData.create();
				telemetry.vendorData.data = {
					oneofKind: "radationData",
					radationData: radationData,
				}

				if(this.parentVMI.model.device?.uuid){
					telemetry.uuid = this.parentVMI.model.device.uuid
				}
				else{
					console.error("ISSUE: No UUID to apply to telemetry");
				}

				await this.services.geolocate?.getCurrentPosition().then((result) => {
					telemetry.longitude1E7 = result.longitude1E7;
					telemetry.latitude1E7 = result.latitude1E7;
					telemetry.elevationCm = result.elevationCm;
					telemetry.gpsScanRecord = GPSScanRecord.clone(result.gpsScanRecord);
				});

				if(this.services.settings?.SETTINGS.APP_ID){
					// console.log("Saving to app id", this.services.settings?.SETTINGS.APP_ID);
					// console.log("Saving Telemetry : ", telemetry);
					// console.log("Model is : ", this.parentVMI.model.device?.uuid);
					this.services.data?.saveTelemetry(this.services.settings?.SETTINGS.APP_ID, this.parentVMI, telemetry, undefined, undefined, undefined, true).then( (saved) => {
						// console.log("bluetoothConnection:initalizeDevice: saved telemetry");
						this.parentVMI.update();
					}).catch( (err) => {
						// console.log("Error saving telemetry to device", err);
						this.parentVMI.update();
					});
				}
			}
		});
	}

	async startHeartBeat() {
		// console.log("STARTING HEARTBEAT FOR STE DEVICE : ", this.heartBeatIntervalMs);
		var bleTxRxService:string = "49535343-fe7d-4ae5-8fa9-9fafd205e455";
		var bleTxRxCharacteristic:string = "49535343-1e4d-4bd9-ba61-23c647249616";
		this.hearBeatTimeout = setInterval( async () => {
			if(this.getIsConnected()){
				// console.log("Sending Heartbeat");
				await this.services.bluetooth?.writeCharacteristic(this, bleTxRxService, bleTxRxCharacteristic, "?");
			}
			else{
				this.stopHeartBeat();
			}
		}, this.heartBeatIntervalMs);
	}
}

export class LrmDeviceViewModelImplemented extends DeviceViewModelImplemented {

	public bluetoothConnection:LrmBluetoothConnection | null;
	public eccFrame : SimpleEccFrame | undefined;

	// public createBluetoothConnection() : Promise<boolean>{
	// 	return new Promise<boolean>( async (resolve, reject) => {
	// 		// console.log("Setting generic LrmDeviceViewModelImplemented CONNECTION")
	// 		if(this.bluetoothConnection){
	// 			// console.log("Already have a bluetooth connection");
	// 			resolve(true);
	// 		}
	// 		if(this.model.device?.uuid){
	// 			// console.log("Creating bluetooth connection getByKey");
	// 			await this.services.data?.getByKey(this.model.device.uuid.toString()+"_btcmi").then( (btcmi:BluetoothCommonInterface) => {
	// 				// console.log("Got bluetooth connection", btcmi);
	// 				this.bluetoothConnection = new LrmBluetoothConnection(this.services, btcmi);
	// 				this.bluetoothConnection.parentVMI = this;
	// 				this.hasBluetoothConnection = true;
	// 				resolve(true);
	// 			})
	// 			.catch( (e ) => {
	// 				console.error(" DIDNT FIND BLUETOOTH LOADED", e);
	// 				reject(e);
	// 			})
	// 		}
	// 	});
	// }

	async initalizeBluetoothConnection() : Promise<boolean> {
		console.log("SteDeviceViewModelImplemented: initalizeBluetoothConnection");
		if(!this.eccFrame){
			this.eccFrame = this.services.simpleEcc?.getNewFrame();
		}
		if(!this.bluetoothConnection){
			if(this.services.bluetooth && this.model.device?.uuid){
				this.bluetoothConnection = this.services.bluetooth.getDeviceConnection(this.model.device?.uuid) as LrmBluetoothConnection;
				if(this.bluetoothConnection){
					this.hasBluetoothConnection = true;
					this.bluetoothConnection.parentVMI = this;
					return this.bluetoothConnection.initalizeDevice();
				}
				// pull from local db, to see if we have a common interface stored
				var commonInterface = await this.services.data?.getByKey(this.model.device.uuid.toString()+"_btcmi") as BluetoothCommonInterface;
				if(commonInterface){
					console.log("Got bluetooth connection", commonInterface)
					this.bluetoothConnection = new LrmBluetoothConnection(this.services, commonInterface);
					this.bluetoothConnection.bluetoothCommonInterface = commonInterface;
					this.bluetoothConnection.parentVMI = this;
					this.hasBluetoothConnection = true;
					return this.bluetoothConnection.initalizeDevice();
				}
			}
			return Promise.reject("No bluetooth service or uuid");
		}
		return this.bluetoothConnection.initalizeDevice();
	}

	public static getStaticTitle():string{ // so we can access this w/o an instance i guess
		return "STE LRM"
	}

	public getPagePath() : string {
		// console.log("LrmDeviceViewModelImplemented :: getPagePath : ", this.model.device?.uuid);
		if(this.model.device){
			return "vendors/ste/ste-lrm/" + this.model.device.uuid;
		}
		return "vendors/ste/ste-lrm/" + 0;
	}

	public getDisplayName() : string {
		return this.getTitle()
	}

	public telemetriesToMapPoints(telemetries:TelemetryViewModelImplemented[]) : MapDataPoint[] {
		var mapDataPoints:MapDataPoint[] = [];
		for (let index = 0; index < telemetries.length; index++) {
			const telemetry = telemetries[index];
			if(telemetry.hasPosition){
				if(telemetry.lnglat[0] != 0 && telemetry.lnglat[1] != 0){
					var mapDataPoint:MapDataPoint = {};
					mapDataPoint.coordinates = telemetry.lnglat;
					mapDataPoint.color = Uint8Array.from([0, 0xff, 0]);
					mapDataPoint.elevation = 1000;
					if(telemetry.model?.telemetry?.vendorData){
						if(telemetry.model?.telemetry?.vendorData.data.oneofKind == "radationData"){
							if(telemetry.model?.telemetry?.vendorData.data.radationData){
								if(telemetry.model?.telemetry?.vendorData.data.radationData.gammaData?.gammaAlarmLevel){
									if(telemetry.model?.telemetry?.vendorData.data.radationData.gammaData?.gammaAlarmLevel<3){
										mapDataPoint.color = Uint8Array.from([0xff, 0xff, 0]);
										mapDataPoint.elevation = 1001;
									}
									else if(telemetry.model?.telemetry?.vendorData.data.radationData.gammaData?.gammaAlarmLevel<6){
										mapDataPoint.color = Uint8Array.from([255, 87, 51]);
										mapDataPoint.elevation = 1001;
									}
									else if(telemetry.model?.telemetry?.vendorData.data.radationData.gammaData?.gammaAlarmLevel<=9){
										mapDataPoint.color = Uint8Array.from([255, 0, 0]);
										mapDataPoint.elevation = 1001;
									}
								}
							}
						}
					}
					mapDataPoint.icon = this.getIcon()
					mapDataPoint.timestamp = telemetry.getBestEpoch();
					mapDataPoints.push(mapDataPoint);
				}
			}
		}
		return mapDataPoints;
	}


	public thumbnailImagePath:string = "assets/app/ste/lrm.png";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public getTitle():string{ // so we can access this w/o an instance i guess
		return LrmDeviceViewModelImplemented.getStaticTitle();
	}

}