
import { AllServcies } from "../services/startup/startup.service"
import { Log, } from '../generated_proto/google/app/model/v1/data_pb';

export class LogViewModelImplemented extends Log {
	public log_prefix:string = " :: FileViewModelImplemented : ";
	private services:AllServcies;
	
	// Since these objects are created at runtime, they need to be passed references to the Services
	constructor(
		services:AllServcies
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		// Can pass as param, not used in this case but would
		// be useful behavior for sub classes
		this.services = services;
	}


	fromPouch(doc:any) : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			// console.log(this.log_prefix + "fromPouch: ", doc);
			// {
			// 	"uid": "lv1_1646979984209",
			// 	"createdMs": 1646979984209,
			// 	"deletedMs": 0,
			// 	"from": "::urpc_ota_request:d_11",
			// 	"message": "OTA Ask Received",
			// 	"tagsList": [],
			// 	"payload": "",
			// 	"payloadUrl": "",
			// 	"metaData": "",
			// }
			// fill out with doc

			(doc.uid) ? this.setUid(doc.uid) : doc.setUid(0);
			(doc.createdMs) ? this.setCreatedMs(doc.createdMs) : this.setCreatedMs(0);
			
			(doc.deletedMs) ? this.setDeletedMs(doc.deletedMs) : this.setDeletedMs(0);
			(doc.from) ? this.setFrom(doc.from) : this.setFrom("");
			(doc.message) ? this.setMessage(doc.message) : this.setMessage("");
			if(doc.tagsList){
				// todo :: visit when building tags
			}
			(doc.payload) ? this.setPayload(doc.payload) : this.setPayload("");
			(doc.payloadUrl) ? this.setPayloadUrl(doc.payloadUrl) : this.setPayloadUrl("");
			(doc.metaData) ? this.setMetaData(doc.metaData) : this.setMetaData("");
			resolve(true);
		});
	}
}