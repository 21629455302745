/*  !!! WARNING !!! --- USE EXCULSIVELY THROUGH THE POUCHDB SERVICE */
// This is so we can extend the functionality of pouchb and have the 
// same interface as the rest of the services. 

// Keep the code more organized and easier to read.
import { AppManagementModel } from '../../generated_proto/protobuf-ts/pb/v2/models';
import { UserCredentials } from '../../generated_proto/protobuf-ts/pb/v2/auth';
import { DataBaseInformationImplemented } from '../data/data.service';
import { LoggerOptions } from '../logger/logger.service';
import { AllServcies } from '../startup/startup.service';
import { AppManagementViewModelImplemented } from '../../viewmodels/AppManagement.vmi';
import { ModelType } from '../../generated_proto/protobuf-ts/pb/v2/models';

export class AppPouchdbService {

	public loggerOptions:LoggerOptions = {
		prefix:"AppPouchdbService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	private localDb:DataBaseInformationImplemented;

	constructor(
	)
	{

	}

	public init(allServices:AllServcies) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {

			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
				if(this.services.pouch){
					this.localDb = this.services.pouch.getDbInformationImpelemnted("localDb", true);
					// await this.saveByKey("test", "test");
					await this.localDb.localDb.info().then( (result) => {
						// console.log("Local DB Info", result);
						// this.services.logger?.debug(this.loggerOptions, "Local DB Info", result);
						resolve(true);
					}).catch( (err) => {
						console.log("localDb ",err);
						reject(err);
					});
					
					this.getByKey("idActiveOverride").then( (result) => {
						console.log("idActiveOverride", result);
						this.services.logger?.debug(this.loggerOptions, "idActiveOverride", result);
						this.services.settings?.startupSetStartupId(result);
					}).catch( (err) => {
						console.log("idActiveOverride :: ",err);
					});
				}
			}
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getByKey(key:string) : Promise<any>{
		var finalKey:string = "appByKey_"+key;
		return new Promise( (resolve, reject) => {
			if(!this.localDb){
				reject({code:0, message:"Local DB Not Set"});
				return;
			}
			this.localDb.localDb.get(finalKey)
			.then( (result) => {
				if(result.data){resolve(result.data);return;}
				if(result.data != "undefined"){
					resolve(result.data);
					return;
				}
				else {reject("no data in key : "+key);}
			}).catch( (err) => {
				reject(err);
			});
		});
	}
	public saveByKey(key:string, data:any) : Promise<boolean>{
		var finalKey:string = "appByKey_"+key;
		console.log(" :: SAVING BY KEY ::", finalKey, data);
		return new Promise( (resolve, reject) => {
			if(!this.localDb){
				reject({code:0, message:"Local DB Not Set"});
				return;
			}
			this.localDb.localDb.get(finalKey)
			.then( (result) => {
				result.data = data;
				console.log(" :: FOUND :: Updating : ", key, " :: RESULT :: ",result)
				this.localDb.localDb.put(result)
				.then( (result) => {
					console.log("update saved")
					resolve(true);
				}).catch( (err) => {
					console.log("update not saved")
					reject(err);
				});
			}).catch( (err) => {
				if(err.status==404){
					console.log(" :: NOT FOUND :: Creating new : ", key)
					this.localDb.localDb.put({
						_id:finalKey,
						data:data
					}).then( (result) => {
						console.log("new saved")
						resolve(true);
					}).catch( (err) => {
						console.log("new not saved")
						reject(err);
					});
				}
				else {
					reject(err);
				}
			});
		});
	}
	public deleteByKey(key:string) : Promise<boolean>{
		var finalKey:string = "appByKey_"+key;
		return new Promise( (resolve, reject) => {
			if(!this.localDb){
				reject({code:0, message:"Local DB Not Set"});
				return;
			}
			this.localDb.localDb.get(finalKey)
			.then( (result) => {
				console.log("DELETING BY KEY AFTER FIND", finalKey, result);
				this.localDb.localDb.remove(result)
				.then( (result) => {
					resolve(true);
				}).catch( (err) => {
					resolve(false);
				});
			}).catch( (err) => {
				resolve(false);
			});
		});
	}


	public getAppMangement(app_id:number) : Promise<AppManagementViewModelImplemented>{
		return new Promise<AppManagementViewModelImplemented>( (resolve, reject) => {
			if(this.services.pouch && this.services.pouch.app && this.services.data){

				var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.APP_MANAGEMENT_MODEL);
				var dbConnection;
				if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
					dbConnection = db.localDb;
				}
				else {
					dbConnection = db.remoteDb;
				}
				var searchOptions = {
					include_docs: true,
					attachments: false,
				}
				dbConnection.allDocs(searchOptions)
				.then( (result:any) => {
					if(result.rows.length>0){
						for (let index = 0; index < result.rows.length; index++) {
							const row = result.rows[index];
							const doc = row.doc;
							if(doc._id[0] == "_" ){
								continue;
							}
							// if(this.services.proto) list.push(this.services.proto.createLocalFromDb(doc));
						}
						// get relations for devices (if applicable)
						// for (let index = 0; index < list.length; index++) {
						// 	const device = list[index];
						// 	if(device.model.reportedByDeviceUrn){
						// 		// find in list the device
						// 		var found = list.find( (d) => {
						// 			return d.generateURN() == device.model.reportedByDeviceUrn;
						// 		});
						// 		if(found){
						// 			device.reportedByDeviceVMI = found;
						// 		}
						// 	}
						// }
						// resolve(list);
					}
					else {
						// resolve([]);
					}
				}).catch( (err) => {
					console.log(err);
					reject(err)
				});
			}
			else {
				reject("Pouch Service not set");
			}
		})
	}



}
