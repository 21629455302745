import { AllServcies } from "../startup/startup.service";
import { SimpleEccFrame } from "./simple-ecc.frame";

export class SimpleEccService {

	constructor(
		servcies?:AllServcies,
	) {
		if(servcies){
			this.setServices(servcies);
		}
	}

	init(services:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(!services){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(services);
				resolve(true);
			}
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	getNewFrame() : SimpleEccFrame{
		return new SimpleEccFrame(this.services);
	}

}

