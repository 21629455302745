import { BluetoothCommonInterface } from "../services/bluetooth/bluetooth.service";
import { AllServcies } from "../services/startup/startup.service";
import { AllDevicesVMI } from "../viewmodels/AllDevices.vmi";
import { PlBluetooth } from "./pl/pl.bluetooth";
import { CommsBluetooth } from "./comms/comms.bluetooth";
import { SteBluetooth } from "./ste/ste.bluetooth";
import { MirionBluetooth } from "./mirion/mirion.bluetooth"

export class BluetoothHelper {

	private vendorHelpers:any[] = [];
	
	constructor
	(

	)
	{

	}

	public services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	init(allServices:AllServcies){
		this.setServices(allServices);
		// Add any vendors that should be bluetooth supported here.
		this.vendorHelpers.push(new SteBluetooth(allServices));
		this.vendorHelpers.push(new PlBluetooth(allServices));
		this.vendorHelpers.push(new CommsBluetooth(allServices));
		this.vendorHelpers.push(new MirionBluetooth(allServices));
	}

	public getSupportedDevices() : AllDevicesVMI[] {
		var possibleVMIs:AllDevicesVMI[] = [];
		for (let index = 0; index < this.vendorHelpers.length; index++) {
			const vendor = this.vendorHelpers[index];
			if(vendor.getSupportedDevices){
				vendor.getSupportedDevices().map( (vmi) => {
					console.log("BluetoothHelper : getSupportedDevices in map : ", vmi);
					possibleVMIs.push(vmi);
				});
			}
		}
		console.log("BluetoothHelper : getSupportedDevices", possibleVMIs);
		return possibleVMIs;
	}

	public async makeDeviceWithType(device:BluetoothCommonInterface) : Promise<AllDevicesVMI|undefined>{
		var newDeviceVMI:AllDevicesVMI|undefined = undefined;
		for (let index = 0; index < this.vendorHelpers.length; index++) {
			const vendor = this.vendorHelpers[index];
			newDeviceVMI = await vendor.makeDeviceWithType(device);
			if( newDeviceVMI ){
				return newDeviceVMI;
			}
		}
		return newDeviceVMI;
	}
}