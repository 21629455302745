import { AllServcies, } from '../startup/startup.service';
import { EventLog, Telemetry, } from '../../generated_proto/protobuf-ts/pb/v2/data'
import { AllDevicesVMI, } from '../../viewmodels/AllDevices.vmi';
import { LoggerOptions, } from '../logger/logger.service';
import { TelemetryViewModelImplemented } from '../../viewmodels/Telemetry.vmi';
import { DataBase, ModelType, TelemetryModel, } from '../../generated_proto/protobuf-ts/pb/v2/models';
import { DeviceTypeIds, DeviceViewModelImplemented, } from '../../viewmodels/Device.vmi';
import { FindByKey, FindByKeyResponse, } from '../data/data.service';
import { UtilService, } from '../util/util.service';
import { EventViewModelImplemented } from '../../viewmodels/Event.vmi';

export class DevicesPouchdbService {

	public loggerOptions:LoggerOptions = {
		prefix:"DevicesPouchdbService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	constructor(
	)
	{

	}
z
	public init(allServices:AllServcies) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
			}
			if(this.services.pouch && this.services.data)
			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	collateByKeys(urn:string, keysFinder:FindByKey[], sortByKey?:string) : Promise<FindByKeyResponse> {
		return new Promise(async (resolve, reject) => {
			if(!this.services.pouch){
				reject({code:0, message:"PouchDB Not Initialized"});
				return;
			}
			var dbConnection = this.services.pouch.getDbInformationImpelemnted(urn).localDb
			if(keysFinder.length <= 0){
				reject({code:0, message:"No Keys Given"});
				return; 
			}

			var fields = keysFinder.map( (key) => {
				return key.key;
			})

			await dbConnection.createIndex({
				index: {
					fields: ["db.createdMs"]
				}
			})

			var finalResult:FindByKeyResponse = {};
			// Go through each key, individually, and find the collated data, with epochMs pair
			for (let index = 0; index < keysFinder.length; index++) {
				const keyFinder = keysFinder[index];
				// Format the selector to comply wiht keyFinder
				var selector:any = {};
				if(keyFinder.selector){
					if(keyFinder.range){
						var andSelectors:any[] = [];
						var tmp = {};
						tmp[keyFinder.key] = keyFinder.selector;
						andSelectors.push(tmp);
						andSelectors.push({"db.createdMs" : {"$gte":""+keyFinder.range.dateStartMillis}});
						andSelectors.push({"db.createdMs" : {"$lte":""+keyFinder.range.dateEndMillis}});
						selector["$and"] = andSelectors;
					}
					else {
						selector[keyFinder.key] = keyFinder.selector;
					}
				}
				var full_query:any = {
					selector: selector,
					fields: [keyFinder.key, "db.createdMs"],
				};
				console.log("Full query is : ", full_query);

				await dbConnection.find(full_query).then( (result) => {
					console.log("result:", result);
					if(result.docs.length>0){
						var processResult:any = {};
						for (let index = 0; index < result.docs.length; index++) {
							const row = result.docs[index];	
							const flattened = UtilService.flattenJson(row, true);
							var keys = Object.keys(flattened);						
							for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
								const key = keys[keyIndex];
								if(key == "createdMs"){
									if(!processResult["epochMs"]){
										processResult["epochMs"] = [];
									}
									processResult["epochMs"].push(parseInt(flattened[key]));
								}
								else{
									if(!processResult["values"]){
										processResult["values"] = [];
									}
									processResult["values"].push(flattened[key]*keyFinder.scale);
								}
							}
						}
						finalResult[keyFinder.key.split(".").pop()||keyFinder.key] = processResult;
					}
				}).catch( (err) => {
					console.error("Error:",err);
				});
			}
			resolve(finalResult);
		});
	}

	public getDevicesList( app_id:number ) : Promise<AllDevicesVMI[]> {
		var list:AllDevicesVMI[] = [];
		var searchOptions = {
			include_docs: true,
			attachments: false,
		}

		return new Promise( (resolve, reject) => {
			if(this.services.pouch && this.services.pouch.app && this.services.data){

				var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
				var dbConnection;
				if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
					dbConnection = db.localDb;
				}
				else {
					dbConnection = db.remoteDb;
				}
				dbConnection.allDocs(searchOptions)
				.then( (result:any) => {
					if(result.rows.length>0){
						for (let index = 0; index < result.rows.length; index++) {
							const row = result.rows[index];
							const doc = row.doc;
							if(doc._id[0] == "_" ){
								continue;
							}
							if(this.services.proto) list.push(this.services.proto.createLocalFromDb(doc));
						}
						// get relations for devices (if applicable)
						for (let index = 0; index < list.length; index++) {
							const device = list[index];
							if(device.model.reportedByDeviceUrn){
								// find in list the device
								var found = list.find( (d) => {
									return d.generateURN() == device.model.reportedByDeviceUrn;
								});
								if(found){
									device.reportedByDeviceVMI = found;
								}
							}
						}
						resolve(list);
					}
					else {
						resolve([]);
					}
				}).catch( (err) => {
					console.log(err);
					reject(err)
				});
			}
			else {
				reject("Pouch Service not set");
			}
		})
	}

	public getDeviceByUuid( app_id:number, uuid:bigint ) : Promise<AllDevicesVMI> {
		return new Promise( (resolve,reject) => {
			if(this.services.pouch && this.services.pouch.app){

				if(this.services.data){
					// console.log("getDeviceByUuid:DB URL ", this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
					var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
					var dbConnection;
					if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
						dbConnection = db.localDb;
					}
					else {
						dbConnection = db.remoteDb;
					}
					dbConnection.get(DeviceViewModelImplemented.GenerateDbKey(uuid))
					.then( (doc:any) => {
						if(this.services.proto){
							var newThing:AllDevicesVMI = this.services.proto.createLocalFromDb(doc)
							resolve(newThing);
						}
					}).catch( (err) => {
						reject(err);
					});
				}
				else {
					reject("Data Service not set");
				}
			}
			else{
				reject("Pouch/app not set");
			}
		});
	}

	getDeviceByUrn( app_id:number, urn:string ) : Promise<AllDevicesVMI> {
		return new Promise( (resolve,reject) => {
			if(this.services.pouch && this.services.pouch.app){
				if(this.services.data){
					var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
					var dbConnection;
					if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
						dbConnection = db.localDb;
					}
					else {
						dbConnection = db.remoteDb;
					}
					dbConnection.get(urn)
					.then( (doc:any) => {
						console.log("getDeviceByUrn:doc",doc)
						if(this.services.proto){
							var newThing:AllDevicesVMI = this.services.proto.createLocalFromDb(doc)
							resolve(newThing);
						}
					}).catch( (err) => {
						reject(err);
					});
				}
				else {
					reject("Data Service not set");
				}
			}
			else{
				reject("Pouch/app not set");
			}
		});
	}
	

	public getDeviceUUIDsByDeviceType( app_id:number, deviceType:DeviceTypeIds ) : Promise<bigint[]> {
		return new Promise( (resolve,reject) => {
			if(this.services.pouch && this.services.pouch.app){

				if(this.services.data){
					var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
					var dbConnection;
					if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
						dbConnection = db.localDb;
					}
					else {
						dbConnection = db.remoteDb;
					}
					dbConnection.find({
						selector: {
							"device.deviceType": deviceType,
						},
						fields: ["device.uuid"],
					}).then( (result:any) => {
						var list:bigint[] = [];
						if(result.docs.length>0){
							for (let index = 0; index < result.docs.length; index++) {
								const row = result.docs[index];
								if(row.device.uuid){
									list.push(BigInt(row.device.uuid));
								}
							}
						}
						resolve(list);
					}).catch( (err) => {
						reject(err);
					});
				}
				else {
					reject("Data Service not set");
				}
			}
			else{
				reject("Pouch/app not set");
			}
		});
	}

	public getDeviceURNsByDeviceType( app_id:number, deviceType:DeviceTypeIds ) : Promise<string[]> {
		return new Promise( (resolve,reject) => {
			if(this.services.pouch && this.services.pouch.app){
				if(this.services.data){
					var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
					var dbConnection;
					if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
						dbConnection = db.localDb;
					}
					else {
						dbConnection = db.remoteDb;
					}
					dbConnection.find({
						selector: {
							"device.deviceType": deviceType,
						},
						fields: ["_id"],
					}).then( (result:any) => {
						var list:string[] = [];
						if(result.docs.length>0){
							for (let index = 0; index < result.docs.length; index++) {
								const row = result.docs[index];
								if(result.docs[index]["_id"]){
									list.push(result.docs[index]["_id"]);
								}
							}
						}
						resolve(list);
					}).catch( (err) => {
						reject(err);
					});
				}
				else {
					reject("Data Service not set");
				}
			}
			else{
				reject("Pouch/app not set");
			}
		});
	}

	public saveDevice( app_id:number, deviceVMI:AllDevicesVMI ) : Promise<boolean> {
		return new Promise( (resolve,reject) => {
			deviceVMI.updateDb();

			var full_doc = Object.assign({}, deviceVMI.modelToJson());
			full_doc["_id"] = deviceVMI.generateDbKey();

			if(!this.services.pouch){
				if(this.services.logger) this.services.logger.error(this.loggerOptions,"Pouch not set");
				reject(this.loggerOptions.prefix+"Pouch not set");
				return;
			}
			if(! this.services.data){
				reject("Data Service not set");
				return
			}
			// console.log("saveDevice:DB URL ", this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
			var dbConnection;
			if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
				dbConnection = db.localDb;
			}
			else {
				dbConnection = db.remoteDb;
			}
			dbConnection.get(deviceVMI.generateDbKey())
			.then( (doc:any) => {
				// console.log("saveDevice:Doc exists");
				doc = Object.assign(doc, full_doc);

				// Onlytime this is updated after a delete would be from the uRPC or other service
				doc._deleted = false;
				if(!this.services.pouch){
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"Pouch not set");
					reject(this.loggerOptions.prefix+"Pouch not set");
					return;
				}
				// console.log("saveDevice:updating doc with put");
				return dbConnection.put(doc)
			})
			.then( (result:any) => {
				resolve(true);
			}).catch( (err) => {
				if(this.services.logger) this.services.logger.warn(this.loggerOptions,"Not Found");
				if(err.status != 404){
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"Error", err);
					reject(err);
					return;
				}
				if(this.services.pouch){
					if(this.services.logger) this.services.logger.warn(this.loggerOptions,"Creating New Sensor");
					dbConnection.put(full_doc).then( (result:any) => {
						resolve(true);
					}).catch( (err) => {
						reject(err);
					});
				}
				else {
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"Pouch not set");
					reject(this.loggerOptions.prefix+"Pouch not set");
				}
			});
		});
	}

	public deleteDevice( app_id:number, deviceVMI:AllDevicesVMI ) : Promise<boolean> {
		return new Promise( (resolve,reject) => {
			deviceVMI.updateDb();
			if(!this.services.pouch){
				if(this.services.logger) this.services.logger.error(this.loggerOptions,"Pouch not set");
				reject(this.loggerOptions.prefix+"Pouch not set");
				return;
			}
			if(! this.services.data){
				reject("Data Service not set");
				return
			}
			console.log("deleteDevice:DB URL ", this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
			var dbConnection;
			if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
				dbConnection = db.localDb;
			}
			else {
				dbConnection = db.remoteDb;
			}
			dbConnection.get(deviceVMI.generateDbKey())
			.then( (doc:any) => {
				console.log("deleteDevice:Doc exists ::: DELETING ");
				doc._deleted = true;
				if(!this.services.pouch){
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"Pouch not set");
					reject(this.loggerOptions.prefix+"Pouch not set");
					return;
				}
				return dbConnection.put(doc)
			})
			.then( ()=> {
				if(!this.services.data || !this.services.pouch ){
					reject("services are missing");
					return;
				}
				if(!deviceVMI.model.device || !deviceVMI.model.device.uuid){
					reject("missing uuid");
					return;
				}
				var devices_telemetry_dbid = TelemetryViewModelImplemented.GenerateDbId(this.services.data.getDbPrefix(app_id), deviceVMI.model.device.uuid);
				var devices_telemetry_db = this.services.pouch.getDbInformationImpelemnted(devices_telemetry_dbid).localDb;
				if(devices_telemetry_db){
					devices_telemetry_db.destroy( (err, response) => {
						if (err) {
							return console.log(err);
						} else {
							console.log ("Database Deleted: ", response);
						}
					})
					this.services.pouch.deleteFromLocal(devices_telemetry_dbid);
				}
			})
			.then( (result:any) => {
				resolve(true);
			}).catch( (err) => {
				if(this.services.logger) this.services.logger.warn(this.loggerOptions,"Not Found");
				reject(err);
			});


		});
	}

	public getLatestTelemetry( deviceVMI:AllDevicesVMI) : Promise<TelemetryViewModelImplemented> {
		return new Promise( (resolve, reject) => {
			var searchOptions = {
				include_docs: true,
				attachments: false,
				inclusive_end: true,
				limit: 1,
			}
			if(deviceVMI == null){
				console.error("getLatestTelemetry : Sensor missing");
				reject("getLatestTelemetry : Sensor missing");
			}
			if(!deviceVMI.model.device?.uuid){
				console.error("getLatestTelemetry : Sensor DB missing");
				reject("getLatestTelemetry : Sensor DB missing");
				return;
			}
			if(!this.services.pouch){
				reject("getLatestTelemetry : Missing pouch");
				return;
			}
			var dbPrefix = this.services.data?.getDbPrefix();
			if(!dbPrefix){
				reject("getTelemetries: Missing db prefix");
				return;
			}
			var dbId=TelemetryViewModelImplemented.GenerateDbId(dbPrefix, deviceVMI.model.device.uuid);
			var sensor_db = this.services.pouch.getDbInformationImpelemnted(dbId).localDb;
			sensor_db.allDocs(searchOptions)
			.then( async (result:any) => {
				if(result.rows.length>0){
					for (let index = 0; index < result.rows.length; index++) {
						const row = result.rows[index];
						const doc = row.doc;
						if(doc._id[0] == "_" ){
							continue;
						}
						if(doc && doc.telemetry){
							var newTelemetry:TelemetryViewModelImplemented = new TelemetryViewModelImplemented(this.services);
							newTelemetry.updateFromModel(doc);
							console.log("getLatestTelemetry : Found", newTelemetry);
							resolve(newTelemetry);
							return;
						}
					}
				}
				else {
					reject("No telemetry found");
				}
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

	public getTelemetries( deviceVMI:AllDevicesVMI, startMs?:number, endMs?:number ) : Promise<TelemetryViewModelImplemented[]> {

		// TODO :: Starting with local check, see if we have within this range already synced.
		var searchOptions:any = {
			include_docs: true,
			attachments: false,
			inclusive_end: true,
		}
		if(endMs){
			searchOptions.startkey = TelemetryViewModelImplemented.GenerateDbKey(endMs);
		}
		if(startMs){
			searchOptions.endkey = TelemetryViewModelImplemented.GenerateDbKey(startMs);
			searchOptions.endkey += '\ufff0';
		}
		return new Promise( async (resolve, reject) => {
			if(deviceVMI == null){
				console.error("getTelemetries: Sensor missing");
				reject("getTelemetries: Sensor missing");
			}
			if(!deviceVMI.model.device?.uuid){
				console.error("getTelemetry : Sensor DB missing");
				reject("getTelemetries: Sensor DB missing");
				return;
			}
			if(!this.services.pouch){
				reject("getTelemetries: Missing pouch");
				return;
			}
			var dbPrefix = this.services.data?.getDbPrefix();
			if(!dbPrefix){
				reject("getTelemetries: Missing db prefix");
				return;
			}
			var telemetryViewModelImplementedList:TelemetryViewModelImplemented[] = [];
			var dbId=TelemetryViewModelImplemented.GenerateDbId(dbPrefix, deviceVMI.model.device.uuid);
			var device_db = this.services.pouch.getDbInformationImpelemnted( dbId ).localDb;

			// Check if all in range are synced already
			// If not, sync those docs FIRST before searching local db.

			console.log("getTelemetries CALLING ON SYNC");
			await this.services.pouch.sync(dbId, startMs, endMs);

			device_db.allDocs(searchOptions)
			.then( async (result:any) => {
				if(result.rows.length>0){
					for (let index = 0; index < result.rows.length; index++) {
						const row = result.rows[index];
						const doc = row.doc;
						if(doc._id[0] == "_" ){
							continue;
						}
						if(doc && doc.telemetry){
							var newTelemetry:TelemetryViewModelImplemented = new TelemetryViewModelImplemented(this.services);
							newTelemetry.updateFromModel(doc);
							telemetryViewModelImplementedList.push(newTelemetry);
						}
					}
					resolve(telemetryViewModelImplementedList.reverse());
				}
				else {
					resolve(telemetryViewModelImplementedList.reverse());
				}
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

	public saveTelemetry(app_id:number, sensorVMI:AllDevicesVMI, telemetry:Telemetry, attached_name?:string, attached_raw?:Uint8Array, reported_by_device_urn?:string, sendToRemote:boolean=false) : Promise<TelemetryViewModelImplemented> {
		return new Promise( async (resolve,reject) => {
			var uuid:bigint = BigInt(0);
			if(sensorVMI.model.device){
				uuid = sensorVMI.model.device.uuid;
			}
			var telemetryVMI:TelemetryViewModelImplemented = new TelemetryViewModelImplemented(this.services);
			telemetryVMI.updateFromTelemetry(telemetry);
			var updateDb:DataBase = DataBase.create();
			updateDb.receivedMs = BigInt(Date.now());
			if(telemetry.epochMs){
				if(telemetry.epochMs > 0){
					// check if getEpochMiliseconds is in seconds, if so, convert to miliseconds
					if(telemetry.epochMs.toString().length < 13){
						console.warn(" !! WARN !! :: saveTelemetry :: Telemetry sent_ms in seconds (wrong), translating to miliseconds : ", Number(telemetry.epochMs).toString().length);
						telemetry.epochMs = BigInt(telemetry.epochMs)*BigInt(1000);
					}
				}
				updateDb = {createdMs:BigInt(telemetry.epochMs), readGroups:[], writeGroups:[]};
			}
			else{
				telemetry.epochMs = BigInt(Date.now());
			}
			telemetryVMI.updateDb(updateDb);

			if(reported_by_device_urn){
				sensorVMI.model.reportedByDeviceUrn = reported_by_device_urn;
			}
			sensorVMI.updateFromTelemetry(telemetry);

			if(this.services.http){
				await this.services.http.post(this.services.pouch?.db_url+"pdb/create/14,2,59388534", {}).toPromise().then( async (response) => {
				})
				.catch( (err) => {
					console.error("Failed to create channel", err);
				});
			}
			
			if(!await this.saveDevice(app_id, sensorVMI)){
				reject("Failed to save sensor");
			}

			if(!this.services.data){
				reject("getTelemetry : Missing Data");
				return;
			}
			var devices_telemetry_dbid = TelemetryViewModelImplemented.GenerateDbId(this.services.data.getDbPrefix(app_id), telemetry);
			
			var shouldSaveTelemetry = true;
			if(!sensorVMI.shouldSaveTelemetry(telemetry)){
				shouldSaveTelemetry = false;
			}
			// broadcast the change
			
			
			if(this.services.platform?.is("capacitor")){
				if(sendToRemote){
					if(this.services.grpcWebClient){
						if(!telemetryVMI.model){
							return Promise.reject(this.loggerOptions.prefix+":saveTelemetry:No Telemetry Model");
						}
						await this.services.grpcWebClient.saveTelemetry(app_id, telemetry).catch( (err) =>{
							console.error("Error saving telemetry model: ", err);
							reject(err);
						});
						resolve(telemetryVMI);
					}
					else {
						reject("services.grpcWebClient not set");
					}
				}
			}
			else {
				// broadcast on the mqtt
				// if(this.services.mqttClient){
				// 	var mqttChannel = devices_telemetry_dbid.replace(/,/g, "/")+"/"+telemetryVMI.generateDbReverseTime();
				// 	if(telemetryVMI.model){
				// 		this.services.mqttClient?.publish(mqttChannel, Buffer.from(TelemetryModel.toBinary(telemetryVMI.model)));
				// 	}
				// }
			}
			
			// break out if we don't need to save the telemetry
			if(!shouldSaveTelemetry){
				resolve(telemetryVMI);
				return;
			}
			// Save the telemetry to the sensor's db
			if(!this.services.pouch){
				reject("getTelemetry : Missing pouch");
				return;
			}
			var devices_telemetry_db = this.services.pouch.getDbInformationImpelemnted(devices_telemetry_dbid).localDb;
			if(this.services.pouchIsServerNode){
				if(this.services.pouch){
					this.services.pouch.addSecurity(app_id,devices_telemetry_db)
				}
			}
			if(devices_telemetry_db && uuid != BigInt(0)){
				var full_doc:any = telemetryVMI.modelToJson();
				if(telemetryVMI.model && telemetryVMI.model.db){
					full_doc["_id"] = telemetryVMI.generateDbKey();
				}
				else {
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"Telemetry missing db");
					reject(this.loggerOptions.prefix+":Telemetry missing db");
					return;
				}
				devices_telemetry_db.put(full_doc)
				.then( async (result:any) => {
					if(attached_name && attached_raw){
						if(!telemetryVMI.model || !telemetryVMI.model.db){
							if(this.services.logger) this.services.logger.error(this.loggerOptions,"Failed Attachment: missing db");
							reject("Failed Attachment: missing db");
							return;
						}
						if(this.services.logger) this.services.logger.warn(this.loggerOptions,"TELEMETRY NOT SAVING ATTACHMENT (NOT IMPLEMENTED)");
						resolve(telemetryVMI);
					}
					else {
						if(sendToRemote && !this.services.pouchIsServerNode && this.services.pouch){
							var remote_devices_telemetry_db = this.services.pouch.getDbInformationImpelemnted(devices_telemetry_dbid).remoteDb;
							remote_devices_telemetry_db.put(full_doc).catch( (e) => {
								console.error("Failed to save telemetry to remote db", e);
							})
						}
						if(this.services.logger) this.services.logger.verbose(this.loggerOptions,"Saved Telemetry", JSON.stringify(telemetryVMI.modelToJson()));
						resolve(telemetryVMI);
					}
				}).catch( (err) => {
					reject(err);
				});
			}
			else{
				reject("Device Database Failure: uuid:"+ uuid);
			}
		});
	}

	saveTelemetryModel(app_id:number, telemetryModel:TelemetryModel ) : Promise<boolean> {
		return new Promise( (resolve,reject) => {
			if(!this.services.data){
				reject("saveTelemetryModel : Missing Data");
				return;
			}
			if(!this.services.pouch){
				reject("saveTelemetryModel : Missing pouch");
				return;
			}
			var telemetry = telemetryModel.telemetry;
			if(!telemetry){
				reject("saveTelemetryModel : Missing telemetry");
				return;
			}
			var uuid = telemetryModel.telemetry?.uuid;
			if(!uuid){
				reject("saveTelemetryModel : Missing uuid");
				return;
			}
			var devices_telemetry_dbid = TelemetryViewModelImplemented.GenerateDbId(this.services.data.getDbPrefix(app_id), telemetry);
			var devices_telemetry_db = this.services.pouch.getDbInformationImpelemnted(devices_telemetry_dbid).localDb;
			if(this.services.pouchIsServerNode){
				if(this.services.pouch){
					this.services.pouch.addSecurity(app_id,devices_telemetry_db)
				}
			}
			if(devices_telemetry_db && uuid != BigInt(0)){
				var full_doc:any = TelemetryModel.toJson(telemetryModel);
				full_doc["_id"] = TelemetryViewModelImplemented.GenerateDbKey(telemetry);
				
				devices_telemetry_db.put(full_doc)
				.then( (result:any) => {
					if(result){
						if(this.services.logger) this.services.logger.verbose(this.loggerOptions,"Saved Telemetry", JSON.stringify(TelemetryModel.toJson(telemetryModel)));
						resolve(true);
					}
					else {
						if(this.services.logger) this.services.logger.error(this.loggerOptions,"Failed to save telemetry");
						reject("Failed to save telemetry");
					}
				}).catch( (err) => {
					reject(err);
				});
			}
			else{
				reject("Device Database Failure: uuid:"+ uuid);
			}
		})
	}

	getDevicesInGroup(app_id:number, device_group_id:number|undefined, variant:number|undefined) : Promise<DeviceViewModelImplemented[]> {
		return new Promise<DeviceViewModelImplemented[]>( (resolve, reject)=> {
			this.services.logger?.verbose(this.loggerOptions,"getDevicesInGroup:starting");
			if(!device_group_id){
				reject("getDevicesInGroup:Missing device_group_id");
				return;
			}

			console.log("device_group_id", device_group_id)
			if(this.services.pouch && this.services.pouch.app && this.services.data){
				var list:DeviceViewModelImplemented[] = [];
				var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_MODEL);
				var dbConnection;
				if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
					dbConnection = db.localDb;
				}
				else {
					dbConnection = db.remoteDb;
				}
				// get last 
				var selector = {
					'device.deviceGroupId': device_group_id
				};
				if(variant && variant != 0){
					console.log("variant", variant)
					console.log("variant", variant)
					console.log("variant", variant)
					console.log("variant", variant)
					console.log("variant", variant)
					selector['device.variant'] = variant;
				}

				dbConnection.find({
					selector: selector
				})
				.then( (result:any) => {
					if(result.docs.length>0){
						for (let index = 0; index < result.docs.length; index++) {
							const doc = result.docs[index];
							if(doc._id[0] == "_" ){
								continue;
							}
							if(this.services.proto) list.push(this.services.proto.createLocalFromDb(doc));
						}
						// get relations for devices (if applicable)
						for (let index = 0; index < list.length; index++) {
							const device = list[index];
							if(device.model.reportedByDeviceUrn){
								// find in list the device
								var found = list.find( (d) => {
									return d.generateURN() == device.model.reportedByDeviceUrn;
								});
								if(found){
									device.reportedByDeviceVMI = found;
								}
							}
						}
						resolve(list);
					}
					else {
						resolve([]);
					}
				}).catch( (err) => {
					console.log(err);
					reject(err)
				});
			}
			else {
				reject("Pouch Service not set");
			}

		})
	}

	saveEventLog(app_id:number, deviceVMI:AllDevicesVMI, eventLog:EventLog) : Promise<boolean> {
		return new Promise( async (resolve,reject) => {
			if(!this.services.pouch){
				reject("saveEventLog : Missing pouch");
				return;
			}
			if(!this.services.data){
				reject("saveEventLog : Missing Data");
				return;
			}
			var devices_eventlog_dbid = EventViewModelImplemented.GenerateDbId(this.services.data.getDbPrefix(app_id));
			var devices_eventlog_db = this.services.pouch.getDbInformationImpelemnted(devices_eventlog_dbid).localDb;
			if(devices_eventlog_db){
				var eventLogVMI:EventViewModelImplemented = new EventViewModelImplemented(this.services);
				eventLogVMI.updateFromEventLog(eventLog);
				var updateDb:DataBase = DataBase.create();
				updateDb.receivedMs = BigInt(Date.now());
				eventLogVMI.updateDb(updateDb);
				var full_doc:any = eventLogVMI.modelToJson();
				if(eventLogVMI.model && eventLogVMI.model.db){
					if( deviceVMI.model.device ){
						full_doc["_id"] = EventViewModelImplemented.GenerateDbKey(deviceVMI.model.device.uuid);
					}
				}
				else {
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"EventLog missing db");
					reject(this.loggerOptions.prefix+":EventLog missing db");
					return;
				}
				devices_eventlog_db.put(full_doc)
				.then( (result:any) => {
					if(this.services.logger) this.services.logger.verbose(this.loggerOptions,"Saved EventLog", JSON.stringify(eventLogVMI.modelToJson()));
					resolve(true);
				}).catch( (err) => {
					reject(err);
				});
			}
			else{
				reject("Device Database Failure");
			}
		});
	}

	getEventLogs( deviceVMI:AllDevicesVMI, startMs?:number, endMs?:number ) : Promise<EventViewModelImplemented[]> {
		return new Promise( async (resolve,reject) => {
			if(!this.services.pouch){
				reject("getEventLogs : Missing pouch");
				return;
			}
			if(!this.services.data){
				reject("getEventLogs : Missing Data");
				return;
			}

		});
	}

}

// Syncing notes:
// https://github.com/pouchdb/pouchdb/issues/6480
// storage : https://pouchdb.com/adapters.html#pouchdb_in_the_browser
// 
