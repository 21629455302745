import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Router, } from '@angular/router';
import { PersonViewModelImplemented } from '../../viewmodels/person.vmi';

import { UserService, } from "../../services/user/user.service"
import { Subscription } from 'rxjs';
import { StartupService } from '../../services/startup/startup.service';
import { UtilService } from 'src/app/services/util/util.service';


@Component({
	selector: 'component-mini-profile',
	templateUrl: './mini-profile.component.html',
	styleUrls: ['./mini-profile.component.scss'],
})
export class MiniProfileComponent implements OnInit, OnDestroy {

	public userProfile:PersonViewModelImplemented;
	private userSubscription:Subscription;

	public shortUsername:string = "";
	public accentColor:string = "";
	public color;

	public hasImage:boolean = false;

	constructor(
		private router: Router,
		private startupService:StartupService,
	)
	{

	}

	ngOnInit() {
		this.startupService.ready().then( async () => {
			if(this.startupService.services.proto){
				this.userProfile = await this.startupService.services.proto.getUserProfile();
				// console.log(" :: User profile : ", this.userProfile.model.person);
				if(this.userProfile.model.person){
					this.shortUsername = this.userProfile.model.person.shortUsername;
				}
				if(this.userProfile.photoUrl){
					this.hasImage = true;
				}
				if(this.userProfile.model.person?.color){
					this.accentColor = UtilService.rgbToHexColor(this.userProfile.model.person.color);
				}
			}
		});
	}

	ngOnDestroy() {
		if(this.userSubscription){
			this.userSubscription.unsubscribe();
		}
	}

	openProfile(){
		this.router.navigate(['protected/profile']);
	}
}
