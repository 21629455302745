

////////////////////////////////////////////////////////////
//// Utils
////////////////////////////////////////////////////////////
import * as mqtt from 'mqtt/dist/mqtt.min'
import { QoS } from "mqtt-packet"
////////////////////////////////////////////////////////////


export interface MqttSubscription {
	subscribed?: boolean;
	qos: QoS;
	topic: string;
	err?: any;
}

interface MqttSubscriptions {
	[topic:string]: MqttSubscription;
}

// https://github.com/sjmf/ng2-mqtt-demo/tree/master/src/app/services/mqtt
export class MqttClient {

	private subscriptions : MqttSubscriptions = {};

	private client: mqtt.MqttClient;
	private connected : boolean = false;
	private options: mqtt.IClientOptions;

	private mqttCallBack: (topic:string, payload:Buffer|string) => Promise<any>;
	
	private uri : string = "";
	private port : number = 443;

	private url : string = "";

	// For MQTT auth
	private username : string = "";
	private password : string = "";

	constructor()
	{

	}
	
	init(uri_in:string, port_in:number, path_in="", username="", password:"", secure:boolean = true, wss:boolean = false, messageCallBack_in?:any) : Promise<any>
	{
		return new Promise( ( resolve, reject) => {
			this.uri = uri_in;
			this.port = port_in;
			console.log(" :: Setting the message callback ::");
			this.mqttCallBack = messageCallBack_in;
	
			this.options = {
				keepalive: 10,
				reconnectPeriod : 10000,
				clientId : 'URPC_' + Math.floor(Math.random() * 65535),
				// ca: [fs.readFileSync(certificate_folder +'/chain1.pem')],
				rejectUnauthorized: false,
				username : this.username,
				password : this.password,
			};
	
			if(wss){
				if(!secure){
					this.url = 'ws'+'://' + this.uri + ':' + this.port +'/' + path_in;
				}
				else {
					this.url = 'wss'+'://' + this.uri + ':' + this.port +'/' + path_in;
				}
			}
			else {
				if(secure){
					this.url = 'mqtts'+'://' + this.uri + ':' + this.port +'/' + path_in;
				}
				else {
					this.url = 'mqtt'+'://' + this.uri + ':' + this.port +'/' + path_in;
				}
			}
			console.log(" :: Connecing to URL : ", this.url);
			resolve(true);
		})
	}

	public connect() {
		return new Promise((resolve) => {
			this.client = mqtt.connect(this.url, this.options);
			
			this.client.on('reconnect', this.onReconnect);
			this.client.on('error', this.onError);
			this.client.on('offline', this.onOffline);
			this.client.handleMessage = (packet, callback) => {
				var start = Date.now();
				this.mqttCallBack(packet["topic"], packet["payload"]).then( (stats) => {
					callback();
				})
				.catch( (err) => {
					callback();
				});
			};
			this.client.on('connect', () => {
				console.log(` :: Mqtt Connection Open : ${this.url} ::`);
				this.connected = true;
				resolve(true);
			});
		});
	}

	public isConnected(){
		return this.connected;
	}

	public startMqttSubscription( topic:string, qos:QoS) {
		this.subscriptions[topic] = {
			topic:topic,
			qos:qos
		};
		this.client.subscribe( topic, {qos:qos});
	}

	public getSubscriptions(){
		return Object.keys(this.subscriptions).map( (key:string) => this.subscriptions[key] );
	}

	public publish( topic:string, data:(Buffer|string), qos:QoS = 0) {
		if(this.client.connected) {
			this.client.publish(topic, data, {qos:qos});
		}
		else {
			console.error('  **ERR** :: MQTT Pub Fail : NOT CONNECTED : ');
		}
	}

	private onReconnect = () => {
		console.log(' :: MQTT Reconnect ::');
		console.log(" :: Connecing to URL : ", this.url);
	}

	private onError = ( err:any ) => {
		console.error('  **ERR** :: MQTT Error : ', err);
	}

	private onOffline() {
		console.log(' :: MQTT diconnected ::');
		this.connected = false;
	}

	public disconnect(): void {
		if (this.client) {
			this.client.end(false,() => {});
		}
	}

	////////////////////////////////////////////////////////////
	// Utils
	////////////////////////////////////////////////////////////

	// Matches the filter with the incomming topic
	private matchTopic (filter:string, topic:string) {
		const filterArray = filter.split('/')
		const length = filterArray.length
		const topicArray = topic.split('/')
		for (var i = 0; i < length; ++i) {
			var left = filterArray[i]
			var right = topicArray[i]
			if (left === '#') return topicArray.length >= length - 1
			if (left !== '+' && left !== right) return false
		}
		return length === topicArray.length
	}

}
