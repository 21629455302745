
import { DeviceTypeIds, DeviceViewModelImplemented, } from "../../viewmodels/Device.vmi";
import { BluetoothConnection, } from "../../services/bluetooth/ble.connetion"
import { CONNECTION_STATE, } from "../../services/constants"
import { AllServcies, } from "../../services/startup/startup.service";
import { GPSScanRecord, Telemetry, VendorData, } from "../../generated_proto/protobuf-ts/pb/v2/data";
import { RadationData, GammaData, NeutronData, } from "../../generated_proto/protobuf-ts/pb/v2/rad_message";
import { UtilService } from "../../services/util/util.service";
import { BluetoothCommonInterface, BluetoothRequestOptions } from "../../services/bluetooth/bluetooth.service";
import { numberToUUID } from "@capacitor-community/bluetooth-le";



export class CommsAssetBluetoothConnection extends BluetoothConnection {

	public heartBeatIntervalMs:number = 110000;

	constructor
	(
		services:AllServcies,
		bluetoothCommonInterface?:BluetoothCommonInterface,
	)
	{
		super(services, bluetoothCommonInterface);
		if(services){
			this.services = services;
		}
		if(bluetoothCommonInterface){
			this.bluetoothCommonInterface = bluetoothCommonInterface;
			console.log("CREATED CommsAssetBluetoothConnection CONNECTION : ", this.bluetoothCommonInterface);
		}
		else {
			console.error("ERROR CREATING STEBLUETOOTH CONNECTION");
		}
	}

	async initalizeDevice() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.connectionState = CONNECTION_STATE.CONNECTING;
			if(!this.getIsConnected()){
				try{
					await this.connect().catch( (e) => reject(e));
				}
				catch(e){
					reject(e);
				}
			}
			this.connectionState = CONNECTION_STATE.CONNECTED;
			resolve(true);
		});
	}

	getRequesetFilter() : BluetoothRequestOptions {
		var requestFilter = {
			namePrefix: "COMMS",
			services:[
			],
			optionalServices: [
				numberToUUID(0x1800),
				numberToUUID(0x00FF),
			], // Note this is required to allow ANY usage of the service on web ble
		}
		return requestFilter;
	}

	readCommsCharacteristicString(characteristic) : Promise<string> {
		return new Promise( async (resolve, reject) => {
			try{
				if(!this.getIsConnected()){await this.connect().catch( (e) => reject(e));}
				var read_result = await this.services.bluetooth?.readCharacteristic(this, 0x00FF, characteristic);

				if(read_result){
					console.log("READ : ", read_result);
					var read_result_ascii:string = UtilService.uint8toAscii(read_result);
					console.log("READ ASCII : ", read_result_ascii);
					if(read_result_ascii)
					{
						resolve(""+read_result_ascii);
					}
				}
				else {
					reject("Read " + characteristic +  "Read Failure");
				}
			}
			catch(e){
				reject(e);
			}
		});
	}

	readCommsCharacteristic(characteristic) : Promise<Uint8Array> {
		return new Promise( async (resolve, reject) => {
			try{
				if(!this.getIsConnected()){await this.connect().catch( (e) => reject(e));}
				var read_result = await this.services.bluetooth?.readCharacteristic(this, 0x00FF, characteristic);

				if(read_result){
					console.log("READ : ", read_result);
					resolve(read_result);
				}
				else {
					reject("Read " + characteristic +  "Read Failure");
				}
			}
			catch(e){
				reject(e);
			}
		});
	}

	writeCommsCharacteristic(characteristic, value) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			try{
				if(!this.getIsConnected()){await this.connect().catch( (e) => reject(e));}

				await this.services.bluetooth?.writeCharacteristic(this, 0x00FF, characteristic, value);

				resolve(true);
			}
			catch(e){
				reject(e);
			}
		});
	}

	async commsConnect() :Promise<void>
	{
		if(!this.getIsConnected())
		{
			await this.connect();
		}
	}

	commsDisconnect() :void
	{
		if(this.getIsConnected())
		{
			this.disconnect();
		}
	}
}

export class CommsAssetDeviceViewModelImplemented extends DeviceViewModelImplemented {

	public bluetoothConnection:CommsAssetBluetoothConnection | null;;

	async initalizeBluetoothConnection() : Promise<boolean> {
		console.log("CommsAssetDeviceViewModelImplemented: initalizeBluetoothConnection");
		if(!this.bluetoothConnection){
			if(this.services.bluetooth && this.model.device?.uuid){
				this.bluetoothConnection = this.services.bluetooth.getDeviceConnection(this.model.device?.uuid);
				if(this.bluetoothConnection){
					this.hasBluetoothConnection = true;
					this.bluetoothConnection.parentVMI = this;
					return this.bluetoothConnection.initalizeDevice();
				}
				// pull from local db, to see if we have a common interface stored
				var commonInterface = await this.services.data?.getByKey(this.model.device.uuid.toString()+"_btcmi") as BluetoothCommonInterface;
				if(commonInterface){
					console.log("Got bluetooth connection", commonInterface)
					this.bluetoothConnection = new CommsAssetBluetoothConnection(this.services, commonInterface);
					this.bluetoothConnection.bluetoothCommonInterface = commonInterface;
					this.bluetoothConnection.parentVMI = this;
					this.hasBluetoothConnection = true;
					return this.bluetoothConnection.initalizeDevice();
				}
			}
			return Promise.reject("No bluetooth service or uuid");
		}
		return this.bluetoothConnection.initalizeDevice();
	}

	public static getStaticTitle() : string {
		return "Comms Module"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return CommsAssetDeviceViewModelImplemented.getStaticTitle();
	}

	public thumbnailImagePath:string = "assets/app/devices/unknown.png";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public getPagePath() : string {
		console.log("CommsAssetDeviceViewModelImplemented :: getPagePath : ", this.model.device?.uuid);
		if(this.model.device){
			if(this.isLocal && this.bluetoothConnection && this.bluetoothConnection.bluetoothCommonInterface.identifier){
				var encodedStringBtoA = btoa(this.bluetoothConnection.bluetoothCommonInterface.identifier).replace('+', '-').replace('/', '_').replace(/=+$/, '');;
				return "vendors/comms/comms-asset/" + this.model.device.uuid+"/" + encodedStringBtoA;
			}
			return "vendors/comms/comms-asset/" + this.model.device.uuid;
		}
		return "vendors/comms/comms-asset/" + 0;
	}

	save(app_id?:number) : Promise<boolean>{
		return Promise.resolve(true);
	}

}