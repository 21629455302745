// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/v2/service.proto" (package "pb.v2", syntax proto3)
// tslint:disable
//
// gRPC Servies. Superset of also uRPC endpoints, so all uRPC's can be used over gRPC (IE: Android/iOS/web can use same interfaces made in uRPC's)
//
import { StreamDeviceModelResponse } from "./service_transports";
import { StreamDeviceModelRequest } from "./service_transports";
import { SaveTelemetryResponse } from "./service_transports";
import { SaveTelemetryRequest } from "./service_transports";
import { GetTokenResponse } from "./service_transports";
import { UserCredentials } from "./auth";
import { Alive } from "./service_transports";
import { Empty } from "../common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated ServiceType for protobuf service pb.v2.GrpcService
 */
export const GrpcService = new ServiceType("pb.v2.GrpcService", [
    { name: "ServiceAlive", options: {}, I: Empty, O: Alive },
    { name: "GetToken", options: {}, I: UserCredentials, O: GetTokenResponse },
    { name: "SaveTelemetry", options: {}, I: SaveTelemetryRequest, O: SaveTelemetryResponse },
    { name: "StreamDeviceModel", serverStreaming: true, options: {}, I: StreamDeviceModelRequest, O: StreamDeviceModelResponse }
]);
