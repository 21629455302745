import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular'
import { SIZE_TO_MEDIA } from '@ionic/core/dist/collection/utils/media'

import { StartupService } from './services/startup/startup.service';
import { MenuPage } from './services/menu/menu.service'
import { LoggerOptions } from './services/logger/logger.service';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

import { KeepAwake } from '@capacitor-community/keep-awake';

import { NordicDFU, StartDFUOptions } from "capacitor-nordic-dfu";

import { FilePicker } from '@capawesome/capacitor-file-picker';


@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {

	public loggerOptions:LoggerOptions = {
		prefix:"AppComponent",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	public appPages:MenuPage[] = [];
	public appManagementPages:MenuPage[] = [];
	public deviceManagementPages:MenuPage[] = [];
	public betaPages:MenuPage[] = [];
	public appFooter:MenuPage[] = [];
	public vendorSections:{sectionTitle:string, pages:MenuPage[]}[] = [];

	public theme_app:string="theme-app2"
	public theme_shade:string="dark";
	public light_active:boolean=false;

	public SETTINGS:any  = {};

	public appVersion = "";
	public appVersionBuild = "";
	public formattedReleaseDateTop = "";
	public formattedReleaseDateBottom = "";

	public isIos:boolean=false;

	public showReleaseNotes:boolean = true;

	public overrideAppId:number = 0;
	public supportedAppIds:number[] = [];

	constructor(
		private router: Router,
		private platform: Platform,
		private startupService:StartupService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
	) {
		this.matIconRegistry.addSvgIcon(
			`signal_cellular_1_bar`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/signal_cellular_1_bar.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`signal_cellular_2_bar`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/signal_cellular_2_bar.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`signal_cellular_3_bar`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/signal_cellular_3_bar.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`signal_cellular_4_bar`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/signal_cellular_4_bar.svg")
		);

		if(this.platform.is("ios")){
			this.isIos=true;
		}
		this.initializeApp();
	}

	public autoHide:boolean = false;
	// https://edupala.com/ionic-menu-with-example/
	// https://lightrun.com/answers/ionic-team-ionic-framework-feat-toggle-menu-when-ion-split-pane-is-visible
	ionSplitPaneChanges($event){
		if($event.detail.visible == false){
			console.log("Splitpane changes : ", $event.detail)
			this.autoHide = true;
		}
		else {
			this.autoHide = false;
		}
	}

	toggleColor(){
		if(!this.light_active){
			this.theme_shade = "light";
			this.startupService.services.settings?.setLightActive(true);
		}
		else {
			this.theme_shade = "dark";
			this.startupService.services.settings?.setLightActive(false);
		}
		this.light_active = !this.light_active;
	}

	// Wire to the function in the object
	clickMenu(clicked:MenuPage){
		if(clicked && clicked.func) clicked.func();
	}

	public showMiniProfile:boolean = true;
	async initializeApp() {
		this.platform.ready().then(async () => {
		

			const keepAwake = async () => {
				await KeepAwake.keepAwake();
			};
			const isSupported = async () => {
				const result = await KeepAwake.isSupported();
				return result.isSupported;
			};
			isSupported().then((supported) => {
				console.log("KeepAwake supported: ", supported);
				if(supported){
					keepAwake();
				}
			})
			.catch( (err) => {
				console.error("KeepAwake isSupported() error : ", err);
			})

			// ! IMPORTANT ! - This is where we handle any initialization of the page (regardless of app entry)
			await this.startupService.init();
			if(this.startupService.services.settings?.SETTINGS){
				this.SETTINGS = this.startupService.services.settings.SETTINGS;
				this.appVersion = this.startupService.services.settings.SETTINGS.APP_VERSION;
				if(this.startupService.services.settings?.SETTINGS.APP_VERSION_BUILD && this.startupService.services.settings?.SETTINGS.APP_VERSION_BUILD.length > 0){
					this.appVersionBuild = " :: " + this.startupService.services.settings.SETTINGS.APP_VERSION_BUILD;
				}
				if(this.startupService.services.settings.SETTINGS.APP_RELEASE_EPOCH){
					var releaseDate = new Date(this.startupService.services.settings.SETTINGS.APP_RELEASE_EPOCH);
					if(releaseDate){
						// if today, say "today at time"
						if( releaseDate.toDateString() == new Date().toDateString() ){
							this.formattedReleaseDateTop = "Updated Today";
							this.formattedReleaseDateBottom = releaseDate.toLocaleTimeString();
						}
						// if yesterday, say "yesterday at time"
						else if( releaseDate.toDateString() == new Date(new Date().setDate(new Date().getDate()-1)).toDateString() ){
							this.formattedReleaseDateTop = "Updated Yesterday";
							this.formattedReleaseDateBottom = releaseDate.toLocaleTimeString();
						}
						// if this year, say "month day at time"
						else if( releaseDate.getFullYear() == new Date().getFullYear() ){
							this.formattedReleaseDateTop = releaseDate.toLocaleDateString();
							this.formattedReleaseDateBottom = releaseDate.toLocaleTimeString();
						}
					}
				}
									
				if(this.startupService.services.settings.SETTINGS.OVER_RIDE_APP_ID){
					this.overrideAppId = this.startupService.services.settings.SETTINGS.OVER_RIDE_APP_ID;
					console.log("Overriding app id to: ", this.overrideAppId);
				}
				var SYNC_SETTINGS = this.startupService.services.settings.SETTINGS.APP_DEV_BACKEND_SYNC_URL_SETTINGS
				if(SYNC_SETTINGS){
					var urls = Object.keys(SYNC_SETTINGS);
					this.supportedAppIds = [];
					for(var i=0; i<urls.length; i++){
						var url = urls[i];
						var urlSettings = SYNC_SETTINGS[url];
						if(urlSettings.apps){
							var urlSettingsKeys = Object.keys(urlSettings.apps);
							console.log("URL Settings keys: ", urlSettingsKeys);
							for (let index = 0; index < urlSettingsKeys.length; index++) {
								const id = urlSettingsKeys[index];
								this.supportedAppIds.push(+id);
							}
						}
					}
				}
			}

			

			// if(this.startupService.services.logger) this.startupService.services.logger.debug(this.loggerOptions, "Starting app with", this.SETTINGS);

			console.log("Hiding release notes: ", this.SETTINGS.APP_HIDE_RELEASE_NOTES);
			if(this.SETTINGS.APP_HIDE_RELEASE_NOTES == true){
				this.showReleaseNotes = false;
			}

			if(this.SETTINGS.APP_USE_STYLE_ID){ // so we can use a specific style for the beta, but use app's id for backend
				this.theme_app = "theme-app"+this.SETTINGS.APP_USE_STYLE_ID;
				// if(this.startupService.services.logger) this.startupService.services.logger.debug(this.loggerOptions, "APP ID SETTING TO APP_USE_STYLE_ID: theme-app"+this.SETTINGS.APP_ID);
			}
			else if(this.SETTINGS.APP_ID){
				this.theme_app = "theme-app"+this.SETTINGS.APP_ID;
				if(this.startupService.services.logger) this.startupService.services.logger.debug(this.loggerOptions, "APP ID SETTING TO THEME: theme-app"+this.SETTINGS.APP_ID);
			}
			if( this.startupService.services.settings?.lightActive ){
				this.light_active = true;
				this.theme_shade = "light";
			}
			else {
				this.light_active = false;
				this.theme_shade = "dark";
			}
			if(this.startupService.services && this.startupService.services.menu){
				this.startupService.services.menu.update.subscribe( () => {
					if(this.startupService.services && this.startupService.services.menu){
						this.appPages = this.startupService.services.menu.getPages();
						this.deviceManagementPages = this.startupService.services.menu.getDeviceManagementPages();
						this.appManagementPages = this.startupService.services.menu.getAppManagementPages();
						this.betaPages = this.startupService.services.menu.getBetaPages();
						this.appFooter = this.startupService.services.menu.getFooterPages();
						this.vendorSections = this.startupService.services.menu.getVendorSections();
						console.log("Vendor sections: ", this.vendorSections)
					}
				})
			}

			if(this.startupService.services.settings?.SETTINGS.APP_FORCE_SIGN_IN_AUTH == true){
				this.showMiniProfile = true;
			}
			else {
				this.showMiniProfile = false;
			}

		});
	}

	public getSupportedAppLogo(id:number){
		return "assets/app/apps/"+id+".png";
	}

	public selectActiveApp(id:number){
		this.startupService.services.settings?.userSetStartupId(id).then( (res) => {
			window.location.href = window.location.origin;
		})
	}
	public clearActiveAppId(){
		this.startupService.services.settings?.userClearActiveAppId().then( (res) => {
			window.location.href = window.location.origin;
		})
	}

	public filePath:string = "https://app.palmexus.com/dfu";
	async pickFile(){
		const result = await FilePicker.pickFiles();
		console.log("Resutl: ", result);
		if(result.files){
			if(result.files[0].path){
				this.filePath = result.files[0].path;
			}
		}
	}

	runFirmware(){
		console.log("Running firmware");
		if(this.filePath.length > 0){
			console.log("Starting DFU: ", this.filePath);
			NordicDFU.addListener('dfuStateDidChange', (info) => {
				console.log("DFU State did change: ", info);
			});
			var options:StartDFUOptions = {
				filePath:this.filePath,
				deviceAddress:"D2:D4:81:48:A2:1E",
			}
			console.log("Starting DFU: ", options)
			NordicDFU.startDFU(options).then( (res) => {
				console.log("DFU Started: ", res);
			})
			.catch( (err) => {
				console.error("DFU Start error: ", err);
			})
		}
	}

	toggleMenu() {
		const splitPane = document.querySelector('ion-split-pane')
		console.log("Splitpane : ", splitPane)
		if(splitPane){
			if (window.matchMedia(SIZE_TO_MEDIA[splitPane.when] || splitPane.when).matches) {
				splitPane.classList.toggle('split-pane-visible')
				console.log("Splitpane visible")
			}
		}
	}

	goToReleaseNotes(){
		this.router.navigate(["/release-notes"]);
		// remove all active popoervers
		const activePopovers = document.querySelectorAll('ion-popover.show-popover');
			activePopovers.forEach((popover) => {
				popover.classList.remove('show-popover');
			}
		);
	}

}