import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { DEFAULT_MAP_SETTINGS, MapSettings } from '../map.service';
import { MatDateRangePicker } from '@angular/material/datepicker';


@Component({
	selector: 'map-settings-modal',
	templateUrl: './map-settings-modal.component.html',
	styleUrls: ['./map-settings-modal.component.scss'],
})
export class MapSettingsModalComponent implements OnInit {

	// private settings:MapSettings = DEFAULT_MAP_SETTINGS;
	@Input() settings:MapSettings;
	@Input() showTimeSelector:boolean;
	@ViewChild('dateRangeStart') dateRangeStart: HTMLInputElement;
	@ViewChild('dateRangeEnd') dateRangeEnd: HTMLInputElement;
	@ViewChild('dateRangePicker') dateRangePicker: MatDateRangePicker<Date>;
	constructor(
		private modalController:ModalController,
	)
	{

	}

	ngOnInit() {
		if(this.settings == null){
			this.settings = DEFAULT_MAP_SETTINGS;
			console.log("Settings default settings: ", this.settings);
		}
		else {
			console.log("Settings modal pop recieved settings: ", this.settings);		
		}
	}
	// Exit and zoom to all fit
	onClickZoomAll() {
		this.settings.zoomAll = true; 
		this.modalController.dismiss(this.settings);
	}

	closeModal() {
		this.modalController.dismiss(this.settings);
	}
	public dateRangeChange(dateRangeStart, dateRangeEnd){
		console.log("Date range changed: ", new Date(dateRangeStart.value).getTime(), new Date(dateRangeEnd.value).getTime());
		this.settings.startMs = new Date(dateRangeStart.value).getTime();
		this.settings.endMs = new Date(dateRangeEnd.value).getTime();
	}
}
