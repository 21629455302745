/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.exportSymbol('proto.FieldType', null, global);
goog.exportSymbol('proto.IntSize', null, global);
goog.exportSymbol('proto.NanoPBOptions', null, global);
goog.exportSymbol('proto.TypenameMangling', null, global);
goog.exportSymbol('proto.nanopb', null, global);
goog.exportSymbol('proto.nanopbEnumopt', null, global);
goog.exportSymbol('proto.nanopbFileopt', null, global);
goog.exportSymbol('proto.nanopbMsgopt', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NanoPBOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NanoPBOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.NanoPBOptions.displayName = 'proto.NanoPBOptions';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NanoPBOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.NanoPBOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NanoPBOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NanoPBOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxSize: jspb.Message.getField(msg, 1),
    maxLength: jspb.Message.getField(msg, 14),
    maxCount: jspb.Message.getField(msg, 2),
    intSize: jspb.Message.getFieldWithDefault(msg, 7, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    longNames: jspb.Message.getFieldWithDefault(msg, 4, true),
    packedStruct: jspb.Message.getFieldWithDefault(msg, 5, false),
    packedEnum: jspb.Message.getFieldWithDefault(msg, 10, false),
    skipMessage: jspb.Message.getFieldWithDefault(msg, 6, false),
    noUnions: jspb.Message.getFieldWithDefault(msg, 8, false),
    msgid: jspb.Message.getField(msg, 9),
    anonymousOneof: jspb.Message.getFieldWithDefault(msg, 11, false),
    proto3: jspb.Message.getFieldWithDefault(msg, 12, false),
    enumToString: jspb.Message.getFieldWithDefault(msg, 13, false),
    fixedLength: jspb.Message.getFieldWithDefault(msg, 15, false),
    fixedCount: jspb.Message.getFieldWithDefault(msg, 16, false),
    mangleNames: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NanoPBOptions}
 */
proto.NanoPBOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NanoPBOptions;
  return proto.NanoPBOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NanoPBOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NanoPBOptions}
 */
proto.NanoPBOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxSize(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxLength(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxCount(value);
      break;
    case 7:
      var value = /** @type {!proto.IntSize} */ (reader.readEnum());
      msg.setIntSize(value);
      break;
    case 3:
      var value = /** @type {!proto.FieldType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLongNames(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPackedStruct(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPackedEnum(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipMessage(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoUnions(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMsgid(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnonymousOneof(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProto3(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnumToString(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFixedLength(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFixedCount(value);
      break;
    case 17:
      var value = /** @type {!proto.TypenameMangling} */ (reader.readEnum());
      msg.setMangleNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NanoPBOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NanoPBOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NanoPBOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NanoPBOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {!proto.IntSize} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {!proto.FieldType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBool(
      15,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeBool(
      16,
      f
    );
  }
  f = /** @type {!proto.TypenameMangling} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * optional int32 max_size = 1;
 * @return {number}
 */
proto.NanoPBOptions.prototype.getMaxSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.NanoPBOptions.prototype.setMaxSize = function(value) {
  jspb.Message.setField(this, 1, value);
};


proto.NanoPBOptions.prototype.clearMaxSize = function() {
  jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasMaxSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 max_length = 14;
 * @return {number}
 */
proto.NanoPBOptions.prototype.getMaxLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.NanoPBOptions.prototype.setMaxLength = function(value) {
  jspb.Message.setField(this, 14, value);
};


proto.NanoPBOptions.prototype.clearMaxLength = function() {
  jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasMaxLength = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int32 max_count = 2;
 * @return {number}
 */
proto.NanoPBOptions.prototype.getMaxCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.NanoPBOptions.prototype.setMaxCount = function(value) {
  jspb.Message.setField(this, 2, value);
};


proto.NanoPBOptions.prototype.clearMaxCount = function() {
  jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasMaxCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IntSize int_size = 7;
 * @return {!proto.IntSize}
 */
proto.NanoPBOptions.prototype.getIntSize = function() {
  return /** @type {!proto.IntSize} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.IntSize} value */
proto.NanoPBOptions.prototype.setIntSize = function(value) {
  jspb.Message.setField(this, 7, value);
};


proto.NanoPBOptions.prototype.clearIntSize = function() {
  jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasIntSize = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FieldType type = 3;
 * @return {!proto.FieldType}
 */
proto.NanoPBOptions.prototype.getType = function() {
  return /** @type {!proto.FieldType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.FieldType} value */
proto.NanoPBOptions.prototype.setType = function(value) {
  jspb.Message.setField(this, 3, value);
};


proto.NanoPBOptions.prototype.clearType = function() {
  jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool long_names = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getLongNames = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, true));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setLongNames = function(value) {
  jspb.Message.setField(this, 4, value);
};


proto.NanoPBOptions.prototype.clearLongNames = function() {
  jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasLongNames = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool packed_struct = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getPackedStruct = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setPackedStruct = function(value) {
  jspb.Message.setField(this, 5, value);
};


proto.NanoPBOptions.prototype.clearPackedStruct = function() {
  jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasPackedStruct = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool packed_enum = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getPackedEnum = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setPackedEnum = function(value) {
  jspb.Message.setField(this, 10, value);
};


proto.NanoPBOptions.prototype.clearPackedEnum = function() {
  jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasPackedEnum = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool skip_message = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getSkipMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setSkipMessage = function(value) {
  jspb.Message.setField(this, 6, value);
};


proto.NanoPBOptions.prototype.clearSkipMessage = function() {
  jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasSkipMessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool no_unions = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getNoUnions = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setNoUnions = function(value) {
  jspb.Message.setField(this, 8, value);
};


proto.NanoPBOptions.prototype.clearNoUnions = function() {
  jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasNoUnions = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint32 msgid = 9;
 * @return {number}
 */
proto.NanoPBOptions.prototype.getMsgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.NanoPBOptions.prototype.setMsgid = function(value) {
  jspb.Message.setField(this, 9, value);
};


proto.NanoPBOptions.prototype.clearMsgid = function() {
  jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasMsgid = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool anonymous_oneof = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getAnonymousOneof = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setAnonymousOneof = function(value) {
  jspb.Message.setField(this, 11, value);
};


proto.NanoPBOptions.prototype.clearAnonymousOneof = function() {
  jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasAnonymousOneof = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool proto3 = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getProto3 = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setProto3 = function(value) {
  jspb.Message.setField(this, 12, value);
};


proto.NanoPBOptions.prototype.clearProto3 = function() {
  jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasProto3 = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool enum_to_string = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getEnumToString = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setEnumToString = function(value) {
  jspb.Message.setField(this, 13, value);
};


proto.NanoPBOptions.prototype.clearEnumToString = function() {
  jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasEnumToString = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool fixed_length = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getFixedLength = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setFixedLength = function(value) {
  jspb.Message.setField(this, 15, value);
};


proto.NanoPBOptions.prototype.clearFixedLength = function() {
  jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasFixedLength = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool fixed_count = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.NanoPBOptions.prototype.getFixedCount = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.NanoPBOptions.prototype.setFixedCount = function(value) {
  jspb.Message.setField(this, 16, value);
};


proto.NanoPBOptions.prototype.clearFixedCount = function() {
  jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasFixedCount = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional TypenameMangling mangle_names = 17;
 * @return {!proto.TypenameMangling}
 */
proto.NanoPBOptions.prototype.getMangleNames = function() {
  return /** @type {!proto.TypenameMangling} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {!proto.TypenameMangling} value */
proto.NanoPBOptions.prototype.setMangleNames = function(value) {
  jspb.Message.setField(this, 17, value);
};


proto.NanoPBOptions.prototype.clearMangleNames = function() {
  jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.NanoPBOptions.prototype.hasMangleNames = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * @enum {number}
 */
proto.FieldType = {
  FT_DEFAULT: 0,
  FT_CALLBACK: 1,
  FT_POINTER: 4,
  FT_STATIC: 2,
  FT_IGNORE: 3,
  FT_INLINE: 5
};

/**
 * @enum {number}
 */
proto.IntSize = {
  IS_DEFAULT: 0,
  IS_8: 8,
  IS_16: 16,
  IS_32: 32,
  IS_64: 64
};

/**
 * @enum {number}
 */
proto.TypenameMangling = {
  M_NONE: 0,
  M_STRIP_PACKAGE: 1,
  M_FLATTEN: 2
};


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `nanopbFileopt`.
 * @type {!jspb.ExtensionFieldInfo.<!proto.NanoPBOptions>}
 */
proto.nanopbFileopt = new jspb.ExtensionFieldInfo(
    1010,
    {nanopbFileopt: 0},
    proto.NanoPBOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.NanoPBOptions.toObject),
    0);

google_protobuf_descriptor_pb.FileOptions.extensionsBinary[1010] = new jspb.ExtensionFieldBinaryInfo(
    proto.nanopbFileopt,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.NanoPBOptions.serializeBinaryToWriter,
    proto.NanoPBOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FileOptions.extensions[1010] = proto.nanopbFileopt;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `nanopbMsgopt`.
 * @type {!jspb.ExtensionFieldInfo.<!proto.NanoPBOptions>}
 */
proto.nanopbMsgopt = new jspb.ExtensionFieldInfo(
    1010,
    {nanopbMsgopt: 0},
    proto.NanoPBOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.NanoPBOptions.toObject),
    0);

google_protobuf_descriptor_pb.MessageOptions.extensionsBinary[1010] = new jspb.ExtensionFieldBinaryInfo(
    proto.nanopbMsgopt,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.NanoPBOptions.serializeBinaryToWriter,
    proto.NanoPBOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.MessageOptions.extensions[1010] = proto.nanopbMsgopt;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `nanopbEnumopt`.
 * @type {!jspb.ExtensionFieldInfo.<!proto.NanoPBOptions>}
 */
proto.nanopbEnumopt = new jspb.ExtensionFieldInfo(
    1010,
    {nanopbEnumopt: 0},
    proto.NanoPBOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.NanoPBOptions.toObject),
    0);

google_protobuf_descriptor_pb.EnumOptions.extensionsBinary[1010] = new jspb.ExtensionFieldBinaryInfo(
    proto.nanopbEnumopt,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.NanoPBOptions.serializeBinaryToWriter,
    proto.NanoPBOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumOptions.extensions[1010] = proto.nanopbEnumopt;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `nanopb`.
 * @type {!jspb.ExtensionFieldInfo.<!proto.NanoPBOptions>}
 */
proto.nanopb = new jspb.ExtensionFieldInfo(
    1010,
    {nanopb: 0},
    proto.NanoPBOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.NanoPBOptions.toObject),
    0);

google_protobuf_descriptor_pb.FieldOptions.extensionsBinary[1010] = new jspb.ExtensionFieldBinaryInfo(
    proto.nanopb,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.NanoPBOptions.serializeBinaryToWriter,
    proto.NanoPBOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FieldOptions.extensions[1010] = proto.nanopb;

goog.object.extend(exports, proto);
