import { AllServcies } from "../services/startup/startup.service";

export class ViewModelImplemented {

	public services:AllServcies;
	constructor
	(
		services?:AllServcies,
	)
	{
		if(services){
			this.services = services;
		}
	}

	public getId() : bigint{
		// TO BE IMPLEMENTED BY THE UNDERLYING VMI
		return BigInt(0);
	}

	public getColor() : Uint8Array {
		let iconColor = new Uint8Array(4);
		var result = Math.floor( Math.abs( Math.sin( Number(this.getId()) || 0) * 16777215 ) % 16777215).toString(16).padStart(6, "0");
		iconColor[0] = parseInt(result[0]+result[1], 16);
		iconColor[1] = parseInt(result[2]+result[3], 16);
		iconColor[2] = parseInt(result[4]+result[5], 16);
		iconColor[3] = 255;
		return iconColor;
	}

}