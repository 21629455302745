
export interface LoggerOptions {
	prefix?:string,
	color?:string,
	allOn?:boolean,
	verboseOn?:boolean,
	debugOn?:boolean,
	warnOn?:boolean,
}

export class LoggerService {

	private gloablOptions:LoggerOptions = {
		allOn:true,
		verboseOn:true,
		debugOn:true,
		warnOn:true,
	}

	private color_reset = "\x1b[0m"
	private color_bright = "\x1b[1m"
	private color_dim = "\x1b[2m"
	private color_underscore = "\x1b[4m"
	private color_blink = "\x1b[5m"
	private color_reverse = "\x1b[7m"
	private color_hidden = "\x1b[8m"
	private color_fgBlack = "\x1b[30m"
	private color_fgRed = "\x1b[31m"
	private color_fgGreen = "\x1b[32m"
	private color_fgYellow = "\x1b[33m"
	private color_fgBlue = "\x1b[34m"
	private color_fgMagenta = "\x1b[35m"
	private color_fgCyan = "\x1b[36m"
	private color_fgWhite = "\x1b[37m"
	private color_bgBlack = "\x1b[40m"
	private color_bgRed = "\x1b[41m"
	private color_bgGreen = "\x1b[42m"
	private color_bgYellow = "\x1b[43m"
	private color_bgBlue = "\x1b[44m"
	private color_bgMagenta = "\x1b[45m"
	private color_bgCyan = "\x1b[46m"
	private color_bgWhite = "\x1b[47m"

	private verbose_color = this.color_fgCyan;
	private debug_color = this.color_fgWhite;
	private warn_color = this.color_fgYellow;
	private error_color = this.color_fgRed;

	// You can add the prefix strings to this to quickly ignore all from that prefix
	// - useful when debugging to eliminate a bunch of console out you dont want to look at
	private ignorePrefixes:string[] = [
		"DeviceAddPage",
		"ComportService",
		"OpenCommsParser",
		"DevicesPage",
	];

	constructor
	(

	)
	{
		
	}

	public addIgnorePrefix( prefix:string ){
		if(this.ignorePrefixes.indexOf(prefix) === -1)  this.ignorePrefixes.push(prefix);
	}

	private formatPrefix(prefix:string) : string {
		return ": [" + prefix + "] :: "
	}

	verbose(options:LoggerOptions, message:string, payload?:any) {
		if(options.prefix){
			if(this.ignorePrefixes.indexOf(options.prefix) !== -1) return;
		}
		else{
			options.prefix = "";
		}

		if( !options.allOn || !options.verboseOn || !this.gloablOptions.allOn || !this.gloablOptions.verboseOn ){
			return;
		}
		var prefix:string = "V" + this.formatPrefix(options.prefix);
		var fullMessage:string = "";
		if(message.length>0){
			fullMessage = prefix + message 
		}
		else {
			fullMessage = prefix;
		}
		if(payload){
			fullMessage = fullMessage +" : "
			if((typeof payload == "object")){
				console.log(this.verbose_color+fullMessage, payload);
			}
		}
		else {
			fullMessage = fullMessage + " ::"
			console.log(this.verbose_color+fullMessage);
		}
	}

	debug(options:LoggerOptions, message:string, payload?:any) {
		if(options.prefix){
			if(this.ignorePrefixes.indexOf(options.prefix) !== -1) return;
		}
		else{
			options.prefix = "";
		}
		if( !options.allOn || !options.debugOn || !this.gloablOptions.allOn || !this.gloablOptions.debugOn ){
			// console.log("Bouncing: ", options);
			return;
		}
		var prefix:string = "D" +  this.formatPrefix(options.prefix);
		var fullMessage:string = "";
		if(message.length>0){
			fullMessage = prefix + message 
		}
		else {
			fullMessage = prefix;
		}
		if(payload){
			fullMessage += " : "
			if((typeof payload == "object")){
				console.log(this.debug_color+fullMessage+this.color_reset, payload);
			}
		}
		else {
			fullMessage += " ::"
			console.log(this.debug_color+fullMessage+this.color_reset);
			fullMessage
		}
	}

	warn(options:LoggerOptions, message:string, payload?:any) {
		if(options.prefix){
			if(this.ignorePrefixes.indexOf(options.prefix) !== -1) return;
		}
		else{
			options.prefix = "";
		}
		if( !options.allOn || !options.warnOn || !this.gloablOptions.allOn || !this.gloablOptions.warnOn ){
			// console.log("Bouncing: ", options);
			return;
		}
		var prefix:string = "D" +  this.formatPrefix(options.prefix);
		var fullMessage:string = "";
		if(message.length>0){
			fullMessage = prefix + message 
		}
		else {
			fullMessage = prefix;
		}
		if(payload){
			fullMessage += " : "
			if((typeof payload == "object")){
				console.log(this.warn_color+fullMessage+this.color_reset, payload);
			}
		}
		else {
			fullMessage += " ::"
			console.log(this.warn_color+fullMessage+this.color_reset);
			fullMessage
		}
	}

	// returns a formatted string so you can use it like.   reject(logger.error(Options, "throw error"))
	// which will log the error and also put it in the throw (IE: popup the error to user or something)
	error(options:LoggerOptions, message:string, payload?:any) : string {
		if( !options.allOn || !this.gloablOptions.allOn ){
			return "";
		}
		if(!options.prefix){
			options.prefix = "";
		}
		var prefix:string = "**ERR" +this.formatPrefix(options.prefix);
		var fullMessage:string = "";
		if(message.length>0){
			fullMessage = prefix + message 
		}
		else {
			fullMessage = prefix;
		}
		if(payload){
			fullMessage += " : "
			if((typeof payload == "object")){
				console.error(this.error_color+fullMessage+this.color_reset, payload);
				return fullMessage + JSON.stringify(payload)
			}
		}
		else {
			fullMessage += " ::"
			console.error(this.error_color+fullMessage+this.color_reset);
			return fullMessage;
		}
		return "";
	}

}
