import { AllServcies } from '../startup/startup.service';
import { LoggerOptions } from '../logger/logger.service';

export class BarcodeHandlerService {

	// handles scanned data from barcodes and from QR codes

	public loggerOptions:LoggerOptions = {
		prefix:"BarcodeHandlerService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	// how long to expect inbetween each button press (max)
	// (this should be faster than humans could reasonably type)
	public triggerTimerMs:number = 150;
	// total window where all presses should happen in (total)
	// (so wer're note reading this data forever and ever in a constant stream)
	public scanWindow:number = 1000;
	public alphaNumericOnly:boolean = false;
	public handleScannedData:((data:string)=>{})|null = null;

	constructor() { }

	public init(services:AllServcies) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(services==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(services);
			}
			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services;
	}

	private scannedData: string = '';
	private timeStart: number = 0;
	private timeOut;
	handleEvent(key){
		// Check if the key pressed is a printable character
		if(this.timeStart == 0){
			this.timeStart = Date.now();
		}
		if(Date.now() - this.timeStart <= this.scanWindow){
			// we're within the window of a scan
			if(this.alphaNumericOnly){
				const alphanumericRegex = /^[a-zA-Z0-9]$/;
				if (alphanumericRegex.test(key)) {
					this.scannedData += key;
				}
			}
			else {
				// allow all standard keyboard characters, except whitespace
				const alphanumericRegex = /^[a-zA-Z0-9\`\~\!\@\#\$\%\^\&\*\(\)\_\-\+\=\[\]\{\}\|\;\:\'\"\,\.\/\<\>\?]$/;
				if (alphanumericRegex.test(key)) {
					this.scannedData += key;
				}
			}
			if(this.timeOut){
				clearTimeout(this.timeOut);
			}
			this.timeOut = setTimeout(() => {
				if(this.handleScannedData){
					if(this.scannedData.length > 0){
						this.handleScannedData(this.scannedData);
					}
				}
				this.timeStart = 0;
				this.scannedData = "";
			}, this.triggerTimerMs);
		}
		else {
			this.timeStart = 0;
			this.scannedData = "";
			if(this.timeOut){
				clearTimeout(this.timeOut);
			}
		}
	}




}
