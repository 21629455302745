// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/v2/data.proto" (package "pb.v2", syntax proto3)
// tslint:disable
//
// Collection subjects -> Targetd for Microcontroller environments for efficent memory usage constraints
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// /RAD DATA

/**
 * @generated from protobuf message pb.v2.RadionuclideData
 */
export interface RadionuclideData {
    /**
     * @generated from protobuf field: uint32 gamma_live_time_duration = 1;
     */
    gammaLiveTimeDuration: number;
    /**
     * @generated from protobuf field: uint32 gamma_real_time_duration = 2;
     */
    gammaRealTimeDuration: number;
    /**
     * @generated from protobuf field: uint32 radionuclide_identificationA = 3;
     */
    radionuclideIdentificationA: number; // Atomic Number and  Neutrons
    /**
     * @generated from protobuf field: uint32 radionuclide_confidenceA = 4;
     */
    radionuclideConfidenceA: number; // 1-100  in %
    /**
     * @generated from protobuf field: uint32 radionuclide_identificationB = 5;
     */
    radionuclideIdentificationB: number;
    /**
     * @generated from protobuf field: uint32 radionuclide_confidenceB = 6;
     */
    radionuclideConfidenceB: number;
    /**
     * @generated from protobuf field: uint32 radionuclide_identificationC = 7;
     */
    radionuclideIdentificationC: number;
    /**
     * @generated from protobuf field: uint32 radionuclide_confidenceC = 8;
     */
    radionuclideConfidenceC: number;
    /**
     * @generated from protobuf field: float calibration_coefficient_A = 9;
     */
    calibrationCoefficientA: number;
    /**
     * @generated from protobuf field: float calibration_coefficient_B = 10;
     */
    calibrationCoefficientB: number;
    /**
     * @generated from protobuf field: float calibration_coefficient_C = 11;
     */
    calibrationCoefficientC: number;
    /**
     * @generated from protobuf field: repeated uint32 spectra = 15;
     */
    spectra: number[];
}
/**
 * @generated from protobuf message pb.v2.GammaData
 */
export interface GammaData {
    /**
     * @generated from protobuf field: uint32 gamma_total_gross_counts = 1;
     */
    gammaTotalGrossCounts: number; // cps
    /**
     * @generated from protobuf field: uint32 gamma_background_count_rate = 2;
     */
    gammaBackgroundCountRate: number; // optional, cps
    /**
     * @generated from protobuf field: uint32 gamma_alarm_count_threshold = 3;
     */
    gammaAlarmCountThreshold: number; // optional, cps
    /**
     * @generated from protobuf field: uint32 gamma_alarm_level = 4;
     */
    gammaAlarmLevel: number; // optional, 0-9
    /**
     * @generated from protobuf field: uint32 gamma_exposure_rate = 5;
     */
    gammaExposureRate: number; // optional, in uR/h * 1e7
    /**
     * @generated from protobuf field: uint32 gamma_total_exposure_rate = 6;
     */
    gammaTotalExposureRate: number; // optional, in uR/h * 1e7
    /**
     * @generated from protobuf field: sint32 gamma_sensor_temperature = 7;
     */
    gammaSensorTemperature: number; // optional, in c
    /**
     * @generated from protobuf field: pb.v2.GammaNeutronState gamma_state = 8;
     */
    gammaState: GammaNeutronState; // optional
    /**
     * @generated from protobuf field: uint32 collection_id = 14;
     */
    collectionId: number;
    /**
     * @generated from protobuf field: pb.v2.DetectorMaterialType gamma_detector_material = 15;
     */
    gammaDetectorMaterial: DetectorMaterialType;
}
/**
 * @generated from protobuf message pb.v2.NeutronData
 */
export interface NeutronData {
    /**
     * @generated from protobuf field: uint32 neutron_total_gross_counts_1e1 = 1 [json_name = "neutronTotalGrossCounts1e1"];
     */
    neutronTotalGrossCounts1E1: number; // convert back 1e-1
    /**
     * @generated from protobuf field: uint32 neutron_background_count_rate = 2;
     */
    neutronBackgroundCountRate: number; // optional
    /**
     * @generated from protobuf field: uint32 neutron_alarm_count_threshold = 3;
     */
    neutronAlarmCountThreshold: number;
    /**
     * @generated from protobuf field: uint32 neuron_alarm_level = 4;
     */
    neuronAlarmLevel: number; // optional
    /**
     * @generated from protobuf field: pb.v2.GammaNeutronState neutron_state = 5;
     */
    neutronState: GammaNeutronState; // optional
    /**
     * ... reserved for future use
     *
     * @generated from protobuf field: uint32 collection_id = 14;
     */
    collectionId: number;
    /**
     * @generated from protobuf field: pb.v2.DetectorMaterialType neutron_detector_material = 15;
     */
    neutronDetectorMaterial: DetectorMaterialType;
}
/**
 * @generated from protobuf message pb.v2.SensorInformation
 */
export interface SensorInformation {
    /**
     * @generated from protobuf field: uint32 sensor_manufacturer = 1;
     */
    sensorManufacturer: number; // optional TODO enum
    /**
     * @generated from protobuf field: uint32 sensor_model = 2;
     */
    sensorModel: number; // optional TODO enum 
    /**
     * @generated from protobuf field: uint32 sensor_serial_number = 3;
     */
    sensorSerialNumber: number; // 
    /**
     * @generated from protobuf field: uint32 sensor_software_version = 4;
     */
    sensorSoftwareVersion: number; // version shifted be hardware.major.minor.patch bytes (shift by 8)
    /**
     * @generated from protobuf field: uint32 sensor_reporting_rate_ms = 5;
     */
    sensorReportingRateMs: number; // optional
    /**
     * @generated from protobuf field: pb.v2.SensorClassType sensorClass = 6;
     */
    sensorClass: SensorClassType;
    /**
     * @generated from protobuf field: pb.v2.SensorInformation.Connection connection = 7;
     */
    connection: SensorInformation_Connection;
    /**
     * @generated from protobuf field: sint32 sensor_rssi_dbm = 8;
     */
    sensorRssiDbm: number; // RSSI of connected device
}
/**
 * @generated from protobuf enum pb.v2.SensorInformation.Connection
 */
export enum SensorInformation_Connection {
    /**
     * @generated from protobuf enum value: CONNECTION_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: CONNECTION_SERIAL = 1;
     */
    SERIAL = 1,
    /**
     * @generated from protobuf enum value: CONNECTION_BT = 2;
     */
    BT = 2,
    /**
     * @generated from protobuf enum value: CONNECTION_BLE = 3;
     */
    BLE = 3,
    /**
     * @generated from protobuf enum value: CONNECTION_WIFI = 4;
     */
    WIFI = 4,
    /**
     * @generated from protobuf enum value: CONNECTION_CELL = 5;
     */
    CELL = 5,
    /**
     * @generated from protobuf enum value: CONNECTION_RF = 6;
     */
    RF = 6,
    /**
     * @generated from protobuf enum value: CONNECTION_LORA = 7;
     */
    LORA = 7
}
/**
 * Root level message
 *
 * @generated from protobuf message pb.v2.RadationData
 */
export interface RadationData {
    /**
     * @generated from protobuf field: pb.v2.SensorInformation sensorInformation = 1;
     */
    sensorInformation?: SensorInformation; // optional
    /**
     * @generated from protobuf field: pb.v2.GammaData gammaData = 2;
     */
    gammaData?: GammaData; // optional
    /**
     * @generated from protobuf field: pb.v2.NeutronData neutronData = 3;
     */
    neutronData?: NeutronData; // optional
    /**
     * @generated from protobuf field: repeated pb.v2.GammaData gammaDataSensors = 4;
     */
    gammaDataSensors: GammaData[]; // optional - for multiple sensors
    /**
     * @generated from protobuf field: repeated pb.v2.NeutronData neutronDataSensors = 5;
     */
    neutronDataSensors: NeutronData[]; // optional - for multiple sensors
    /**
     * ... reserved for future use
     *
     * @generated from protobuf field: pb.v2.RadionuclideData radionuclideData = 15;
     */
    radionuclideData?: RadionuclideData; // optional
}
// //////////////////////////////
// Collected Data
// //////////////////////////////

/**
 * @generated from protobuf message pb.v2.Any
 */
export interface Any {
    /**
     * https://github.com/protocolbuffers/protobuf/blob/master/src/google/protobuf/any.proto
     *
     * @generated from protobuf field: string type_url = 1;
     */
    typeUrl: string;
    /**
     * @generated from protobuf field: bytes value = 2;
     */
    value: Uint8Array;
}
/**
 * @generated from protobuf message pb.v2.CellScanRecord
 */
export interface CellScanRecord {
    /**
     * @generated from protobuf field: uint32 mcc = 1;
     */
    mcc: number;
    /**
     * @generated from protobuf field: uint32 mnc = 2;
     */
    mnc: number;
    /**
     * @generated from protobuf field: uint32 cid = 3;
     */
    cid: number;
    /**
     * @generated from protobuf field: uint32 las = 4;
     */
    las: number;
    /**
     * @generated from protobuf field: sint32 rssi = 5;
     */
    rssi: number;
    /**
     * @generated from protobuf field: uint64 simid = 6;
     */
    simid: bigint; // optional id for the sim card
}
/**
 * @generated from protobuf message pb.v2.GPSScanRecord
 */
export interface GPSScanRecord {
    /**
     * @generated from protobuf field: uint32 fix_type = 4;
     */
    fixType: number; //  SEE POSITION LOCK TYPE IN ENTITIES :: 0=no lock, 1=IMU, 2=2D lock, 3=3d lock, 4=gps+IMU  5=time only, 6=Wifi , 7=CellID, 8=IP Based, 9=Calculated BLE RSSI reported by gateways, 10=User Manual Input of location
    /**
     * @generated from protobuf field: uint32 velocity_kmh = 5;
     */
    velocityKmh: number;
    /**
     * @generated from protobuf field: uint32 heading_degrees = 6;
     */
    headingDegrees: number;
    /**
     * @generated from protobuf field: uint32 sats_locked = 7;
     */
    satsLocked: number;
    /**
     * @generated from protobuf field: uint32 sats_in_view = 8;
     */
    satsInView: number;
    /**
     * @generated from protobuf field: uint32 pdop = 9;
     */
    pdop: number;
    /**
     * @generated from protobuf field: uint32 geopoint_accuracy_cm = 10;
     */
    geopointAccuracyCm: number; // error in cm
    /**
     * @generated from protobuf field: uint32 elevation_accuracy_cm = 11;
     */
    elevationAccuracyCm: number;
    /**
     * @generated from protobuf field: uint64 epoch_miliseconds = 12;
     */
    epochMiliseconds: bigint; // Time gps location was acquired
}
/**
 * @generated from protobuf message pb.v2.PM25Record
 */
export interface PM25Record {
    /**
     * @generated from protobuf field: uint32 pm10_standard = 1;
     */
    pm10Standard: number; // Standard PM1.0
    /**
     * @generated from protobuf field: uint32 pm25_standard = 2;
     */
    pm25Standard: number; // Standard PM2.5
    /**
     * @generated from protobuf field: uint32 pm100_standard = 3;
     */
    pm100Standard: number; // Standard PM10.0
    /**
     * @generated from protobuf field: uint32 pm10_env = 4;
     */
    pm10Env: number; // Environmental PM1.0
    /**
     * @generated from protobuf field: uint32 pm25_env = 5;
     */
    pm25Env: number; // Environmental PM2.5
    /**
     * @generated from protobuf field: uint32 pm100_env = 6;
     */
    pm100Env: number; // Environmental PM10.0
    /**
     * @generated from protobuf field: uint32 particles_03um = 7 [json_name = "particles03um"];
     */
    particles03Um: number; // 0.3um Particle Count
    /**
     * @generated from protobuf field: uint32 particles_05um = 8 [json_name = "particles05um"];
     */
    particles05Um: number; // 0.5um Particle Count
    /**
     * @generated from protobuf field: uint32 particles_10um = 9 [json_name = "particles10um"];
     */
    particles10Um: number; // 1.0um Particle Count
    /**
     * @generated from protobuf field: uint32 particles_25um = 10 [json_name = "particles25um"];
     */
    particles25Um: number; // 2.5um Particle Count
    /**
     * @generated from protobuf field: uint32 particles_50um = 11 [json_name = "particles50um"];
     */
    particles50Um: number; // 5.0um Particle Count
    /**
     * @generated from protobuf field: uint32 particles_100um = 12 [json_name = "particles100um"];
     */
    particles100Um: number; // 10.0um Particle Count
}
/**
 * @generated from protobuf message pb.v2.MOSRecord
 */
export interface MOSRecord {
    /**
     * @generated from protobuf field: uint32 gas_resistance = 1;
     */
    gasResistance: number; // gas resistance from bme 680
    /**
     * @generated from protobuf field: uint32 iaq_index = 2;
     */
    iaqIndex: number; // Indoor air quality (IAQ) classification typically from bme680
    /**
     * @generated from protobuf field: uint32 red_resistance = 3;
     */
    redResistance: number; // red resistance from MiCS6814 red sensor
    /**
     * @generated from protobuf field: uint32 NH3_resistance = 4 [json_name = "NH3Resistance"];
     */
    nH3Resistance: number; // NH3 resistance from MiCS6814 NH3 sensor
    /**
     * @generated from protobuf field: uint32 OX_resistance = 5 [json_name = "OXResistance"];
     */
    oXResistance: number; // OX resistance from MiCS6814 OX sensor
}
/**
 * @generated from protobuf message pb.v2.WindRecord
 */
export interface WindRecord {
    /**
     * TBD: in progress
     *
     * @generated from protobuf field: uint32 wind_direction_degrees = 1;
     */
    windDirectionDegrees: number; // wind direction in degrees, measured magnetic north = 0, instantaneous
    /**
     * @generated from protobuf field: uint32 wind_direction_ave_degrees = 2;
     */
    windDirectionAveDegrees: number; // wind direction in degrees, measured magnetic north = 0, averaged over 2 minutes
    /**
     * @generated from protobuf field: uint32 wind_direction_long_ave_degrees = 3;
     */
    windDirectionLongAveDegrees: number; // wind direction in degrees, measured magnetic north = 0, averaged over 10 minutes
    /**
     * @generated from protobuf field: uint32 wind_speed_cm_s = 4;
     */
    windSpeedCmS: number; // wind average speed in cm/s (instantaneous)
    /**
     * @generated from protobuf field: uint32 wind_speed_ave_cm_s = 5;
     */
    windSpeedAveCmS: number; // wind average speed in cm/s (2 minute average)
    /**
     * @generated from protobuf field: uint32 wind_speed_long_ave_cm_s = 6;
     */
    windSpeedLongAveCmS: number; // wind average speed in cm/s (10 minute average)
    /**
     * @generated from protobuf field: uint32 wind_speed_gust_cm_s = 7;
     */
    windSpeedGustCmS: number; // wind max speed in cm/s
    /**
     * @generated from protobuf field: uint32 wind_speed_lull_cm_s = 8;
     */
    windSpeedLullCmS: number; // wind min speed in cm/s
}
/**
 * @generated from protobuf message pb.v2.Environment
 */
export interface Environment {
    /**
     * @generated from protobuf field: sint32 temperature_millidegree_c = 1;
     */
    temperatureMillidegreeC: number; // Ambient temperature in millidegrees celsius
    /**
     * @generated from protobuf field: uint32 barometric_pa = 2;
     */
    barometricPa: number; // pascal
    /**
     * @generated from protobuf field: uint32 humidity_rh = 3;
     */
    humidityRh: number; // relative humidity
    /**
     * @generated from protobuf field: uint32 CO2_ppm = 4 [json_name = "CO2Ppm"];
     */
    cO2Ppm: number; // parts per million
    /**
     * @generated from protobuf field: uint32 CO_ppm = 5 [json_name = "COPpm"];
     */
    cOPpm: number; // Carbon Monoxide parts per million
    /**
     * @generated from protobuf field: uint32 O2_ppm = 6 [json_name = "O2Ppm"];
     */
    o2Ppm: number; // Oxygen parts per million
    /**
     * @generated from protobuf field: uint32 H2S_ppm = 7 [json_name = "H2SPpm"];
     */
    h2SPpm: number; // Hydrogen Sulfide parts per million
    /**
     * @generated from protobuf field: uint32 VOC_ppm = 8 [json_name = "VOCPpm"];
     */
    vOCPpm: number; // Volatile Organic Compounds parts per million
    /**
     * @generated from protobuf field: uint32 C2H6OH_ppm = 9 [json_name = "C2H6OHPpm"];
     */
    c2H6OHPpm: number; // Ethanol per million
    /**
     * @generated from protobuf field: uint32 H2_ppm = 10 [json_name = "H2Ppm"];
     */
    h2Ppm: number; // Hydrogen
    /**
     * @generated from protobuf field: uint32 NH3_ppm = 11 [json_name = "NH3Ppm"];
     */
    nH3Ppm: number; // Ammonia parts per million
    /**
     * @generated from protobuf field: uint32 CH4_ppm = 12 [json_name = "CH4Ppm"];
     */
    cH4Ppm: number; // Methane parts per million
    /**
     * @generated from protobuf field: uint32 C3H8_ppm = 13 [json_name = "C3H8Ppm"];
     */
    c3H8Ppm: number; // Propane parts per million
    /**
     * @generated from protobuf field: uint32 NO2_ppm = 14 [json_name = "NO2Ppm"];
     */
    nO2Ppm: number; // Nitrogen Oxide parts per million
    /**
     * @generated from protobuf field: pb.v2.MOSRecord mos = 15;
     */
    mos?: MOSRecord;
    /**
     * @generated from protobuf field: pb.v2.PM25Record pmsensor = 16;
     */
    pmsensor?: PM25Record;
    /**
     * @generated from protobuf field: pb.v2.WindRecord wind = 17;
     */
    wind?: WindRecord;
}
/**
 * @generated from protobuf message pb.v2.GridEye
 */
export interface GridEye {
    /**
     * @generated from protobuf field: repeated uint32 data = 1;
     */
    data: number[];
}
/**
 * @generated from protobuf message pb.v2.Health
 */
export interface Health {
    /**
     * @generated from protobuf field: uint32 average_pulse_rate = 1;
     */
    averagePulseRate: number; // Condensed and Calulated average heart rate
    /**
     * @generated from protobuf field: repeated uint32 raw_pulse_rate_samples = 2;
     */
    rawPulseRateSamples: number[]; // Raw heart rate samples
    /**
     * @generated from protobuf field: uint32 raw_pulse_rate_samping_rate_hz = 3;
     */
    rawPulseRateSampingRateHz: number; // sampling rate of data
    /**
     * @generated from protobuf field: uint32 plx_measurement = 4;
     */
    plxMeasurement: number; // SPO2 sample
    /**
     * @generated from protobuf field: uint32 bp_systolic_mmHg = 5;
     */
    bpSystolicMmHg: number; // Blood Pressure systolic
    /**
     * @generated from protobuf field: uint32 bp_diastolic_mmHg = 6;
     */
    bpDiastolicMmHg: number; // Blood Pressure diastolic
    /**
     * @generated from protobuf field: uint32 bp_mean_arterial_mmHg = 7;
     */
    bpMeanArterialMmHg: number; // Blood Pressure mean arterial
    /**
     * @generated from protobuf field: bool body_movement_detected = 8;
     */
    bodyMovementDetected: boolean;
    /**
     * @generated from protobuf field: bool irregular_pulse_detected = 9;
     */
    irregularPulseDetected: boolean;
    /**
     * @generated from protobuf field: uint32 weight_g = 10;
     */
    weightG: number; // Body Weight in grams
    /**
     * @generated from protobuf field: uint32 height_cm = 11;
     */
    heightCm: number; // height in cm
    /**
     * @generated from protobuf field: uint32 body_mass_index = 12;
     */
    bodyMassIndex: number; // body mass index
    /**
     * @generated from protobuf field: uint32 breaths_per_minute = 13;
     */
    breathsPerMinute: number; // number of breaths per minute
    /**
     * @generated from protobuf field: sint32 body_temperature_milli_c = 14;
     */
    bodyTemperatureMilliC: number;
}
/**
 * @generated from protobuf message pb.v2.MessagePack
 */
export interface MessagePack {
    /**
     * @generated from protobuf field: uint32 mpack_version = 1;
     */
    mpackVersion: number;
    /**
     * @generated from protobuf field: uint32 mpack_counter = 2;
     */
    mpackCounter: number;
    /**
     * @generated from protobuf field: bytes data = 3;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message pb.v2.KeyValue
 */
export interface KeyValue {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "valueString";
        /**
         * @generated from protobuf field: string value_string = 2;
         */
        valueString: string;
    } | {
        oneofKind: "valueArray";
        /**
         * @generated from protobuf field: bytes value_array = 3;
         */
        valueArray: Uint8Array;
    } | {
        oneofKind: "valueNumber";
        /**
         * @generated from protobuf field: int64 value_number = 4;
         */
        valueNumber: bigint;
    } | {
        oneofKind: "valueDouble";
        /**
         * @generated from protobuf field: double value_double = 5;
         */
        valueDouble: number;
    } | {
        oneofKind: "valueBool";
        /**
         * @generated from protobuf field: bool value_bool = 6;
         */
        valueBool: boolean;
    } | {
        oneofKind: "null";
        /**
         * @generated from protobuf field: bool null = 7;
         */
        null: boolean; // true = value is null
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message pb.v2.WiFiScanRecord
 */
export interface WiFiScanRecord {
    /**
     * @generated from protobuf field: bytes macAddress = 1;
     */
    macAddress: Uint8Array;
    /**
     * @generated from protobuf field: sint32 signalStrength = 2;
     */
    signalStrength: number;
    /**
     * @generated from protobuf field: uint32 age = 3;
     */
    age: number;
    /**
     * @generated from protobuf field: uint32 channel = 4;
     */
    channel: number;
    /**
     * @generated from protobuf field: uint32 auth_mode = 5;
     */
    authMode: number;
    /**
     * @generated from protobuf field: uint32 wps = 6;
     */
    wps: number;
    /**
     * @generated from protobuf field: string ssid = 7;
     */
    ssid: string;
}
/**
 * @generated from protobuf message pb.v2.BLEScanRecord
 */
export interface BLEScanRecord {
    /**
     * @generated from protobuf field: bytes macAddress = 1;
     */
    macAddress: Uint8Array;
    /**
     * @generated from protobuf field: sint32 rssi = 2;
     */
    rssi: number;
    /**
     * @generated from protobuf field: bytes adv_packet = 3;
     */
    advPacket: Uint8Array;
    /**
     * @generated from protobuf field: bytes rsp_packet = 4;
     */
    rspPacket: Uint8Array;
}
/**
 * @generated from protobuf message pb.v2.LoraConfig
 */
export interface LoraConfig {
    /**
     * @generated from protobuf field: uint32 rf_frequency = 1;
     */
    rfFrequency: number;
    /**
     * @generated from protobuf field: uint32 bandwidth = 2;
     */
    bandwidth: number; // BW_125_KHZ = 0, BW_250_KHZ=1, BW_500_KHZ=2 
    /**
     * @generated from protobuf field: uint32 datarate = 3;
     */
    datarate: number; // SF_6 = 6 ... SF_12 = 12
    /**
     * @generated from protobuf field: uint32 coding_rate = 4;
     */
    codingRate: number; // CR_4_5 = 1, CR_4_6 = 2,	CR_4_7 = 3, CR_4_8 = 4
    /**
     * @generated from protobuf field: uint32 preamble_len = 5;
     */
    preambleLen: number;
    /**
     * @generated from protobuf field: uint32 tx_power = 6;
     */
    txPower: number;
    /**
     * @generated from protobuf field: sint32 rssi = 7;
     */
    rssi: number;
}
/**
 * @generated from protobuf message pb.v2.SystemState
 */
export interface SystemState {
    /**
     * @generated from protobuf field: bytes current_version = 1;
     */
    currentVersion: Uint8Array; // major[2].minor[1].patch[0]
    /**
     * @generated from protobuf field: uint32 uptime_ticks = 2;
     */
    uptimeTicks: number;
    /**
     * @generated from protobuf field: uint32 setting_counter = 3;
     */
    settingCounter: number;
    /**
     * @generated from protobuf field: uint32 chip_version = 4;
     */
    chipVersion: number;
    /**
     * @generated from protobuf field: uint32 battery_voltage_mv = 5;
     */
    batteryVoltageMv: number;
    /**
     * @generated from protobuf field: uint32 solar_voltage_mv = 6;
     */
    solarVoltageMv: number;
    /**
     * @generated from protobuf field: uint32 external_voltage_mv = 7;
     */
    externalVoltageMv: number;
    /**
     * @generated from protobuf field: sint32 system_temperature_c = 8;
     */
    systemTemperatureC: number;
    /**
     * @generated from protobuf field: pb.v2.EventLog events = 12;
     */
    events?: EventLog;
    /**
     * @generated from protobuf field: pb.v2.CellScanRecord connected_cell = 13;
     */
    connectedCell?: CellScanRecord;
    /**
     * @generated from protobuf field: pb.v2.WiFiScanRecord connected_wifi = 14;
     */
    connectedWifi?: WiFiScanRecord;
    /**
     * @generated from protobuf field: pb.v2.LoraConfig current_lora_config = 15;
     */
    currentLoraConfig?: LoraConfig;
    /**
     * @generated from protobuf field: pb.v2.BLEScanRecord connected_ble = 16;
     */
    connectedBle?: BLEScanRecord;
}
/**
 * @generated from protobuf message pb.v2.generic_data
 */
export interface generic_data {
    /**
     * @generated from protobuf field: uint64 data_collection_type = 1;
     */
    dataCollectionType: bigint; // bitfields TBD
    /**
     * @generated from protobuf field: repeated pb.v2.WiFiScanRecord wifi_scan_record = 2;
     */
    wifiScanRecord: WiFiScanRecord[]; // Scanned access points information 
    /**
     * @generated from protobuf field: repeated pb.v2.CellScanRecord cell_scan_record = 3;
     */
    cellScanRecord: CellScanRecord[];
    /**
     * @generated from protobuf field: repeated pb.v2.BLEScanRecord ble_scan_record = 4;
     */
    bleScanRecord: BLEScanRecord[];
    /**
     * @generated from protobuf field: pb.v2.GridEye gridEye = 5;
     */
    gridEye?: GridEye;
    /**
     * @generated from protobuf field: pb.v2.Health health = 6;
     */
    health?: Health;
}
/**
 * @generated from protobuf message pb.v2.RfCollections
 */
export interface RfCollections {
    /**
     * @generated from protobuf field: uint64 rf_collection_type = 1;
     */
    rfCollectionType: bigint; // bitfields TBD
    /**
     * @generated from protobuf field: repeated pb.v2.WiFiScanRecord wifi_scan_record = 2;
     */
    wifiScanRecord: WiFiScanRecord[]; // Scanned access points information 
    /**
     * @generated from protobuf field: repeated pb.v2.CellScanRecord cell_scan_record = 3;
     */
    cellScanRecord: CellScanRecord[];
    /**
     * @generated from protobuf field: repeated pb.v2.BLEScanRecord ble_scan_record = 4;
     */
    bleScanRecord: BLEScanRecord[];
    /**
     * @generated from protobuf field: uint32 average_total_wifi_devices = 5;
     */
    averageTotalWifiDevices: number;
    /**
     * @generated from protobuf field: uint32 average_total_ble_devices = 6;
     */
    averageTotalBleDevices: number;
}
/**
 * @generated from protobuf message pb.v2.VibrationSensor
 */
export interface VibrationSensor {
    /**
     * @generated from protobuf field: uint64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: uint64 epoch_seconds = 2;
     */
    epochSeconds: bigint; // confirmed
    /**
     * @generated from protobuf field: uint32 pcb_accel_lat_max = 3;
     */
    pcbAccelLatMax: number;
    /**
     * @generated from protobuf field: uint32 pcb_accel_lat_rms = 4;
     */
    pcbAccelLatRms: number;
    /**
     * @generated from protobuf field: uint32 pcb_accel_vert_max = 5;
     */
    pcbAccelVertMax: number;
    /**
     * @generated from protobuf field: uint32 pcb_accel_vert_rms = 6;
     */
    pcbAccelVertRms: number;
    /**
     * @generated from protobuf field: uint32 pcb_accel_long_max = 7;
     */
    pcbAccelLongMax: number;
    /**
     * @generated from protobuf field: uint32 pcb_accel_long_rms = 8;
     */
    pcbAccelLongRms: number;
    /**
     * @generated from protobuf field: uint32 adxl_max = 9;
     */
    adxlMax: number; // confirmed
    /**
     * @generated from protobuf field: uint32 adxl_rms = 10;
     */
    adxlRms: number; // confirmed
    /**
     * @generated from protobuf field: sint32 bearing_temp = 11;
     */
    bearingTemp: number;
    /**
     * @generated from protobuf field: uint32 battery_voltage_centivolts = 12;
     */
    batteryVoltageCentivolts: number; // confirmed
    /**
     * @generated from protobuf field: sint32 bearing_temperature_celsius = 13;
     */
    bearingTemperatureCelsius: number; // confirmed
    /**
     * @generated from protobuf field: uint32 zero_crossing_rate = 14;
     */
    zeroCrossingRate: number;
    /**
     * @generated from protobuf field: repeated uint32 fft256_16bit = 15 [json_name = "fft25616bit"];
     */
    fft25616Bit: number[];
    /**
     * @generated from protobuf field: pb.v2.SensorInformation sensor_information = 16;
     */
    sensorInformation?: SensorInformation; // confirmed rssi and software version
}
/**
 * @generated from protobuf message pb.v2.MagneticSwitch
 */
export interface MagneticSwitch {
    /**
     * @generated from protobuf field: uint64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: uint64 epoch_seconds = 2;
     */
    epochSeconds: bigint;
    /**
     * @generated from protobuf field: uint32 battery_voltage_centivolts = 3;
     */
    batteryVoltageCentivolts: number; // confirmed rssi and software version
    /**
     * @generated from protobuf field: uint32 status = 4;
     */
    status: number; // confirmed rssi and software version 1 closed, 2 open, 0 error
    /**
     * @generated from protobuf field: uint32 hall_sense = 5;
     */
    hallSense: number;
    /**
     * @generated from protobuf field: sint32 raw_mag = 6;
     */
    rawMag: number;
    /**
     * @generated from protobuf field: pb.v2.SensorInformation sensor_information = 7;
     */
    sensorInformation?: SensorInformation; // confirmed rssi and software version
}
/**
 * @generated from protobuf message pb.v2.StrainSensor
 */
export interface StrainSensor {
    /**
     * @generated from protobuf field: uint64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: uint64 epoch_seconds = 2;
     */
    epochSeconds: bigint; // confirmed rssi and software version
    /**
     * @generated from protobuf field: uint32 battery_voltage_centivolts = 3;
     */
    batteryVoltageCentivolts: number; // confirmed rssi and software version
    /**
     * @generated from protobuf field: uint32 raw_strain = 4;
     */
    rawStrain: number; // confirmed rssi and software version
    /**
     * @generated from protobuf field: uint32 raw_temperature = 5;
     */
    rawTemperature: number; // confirmed rssi and software version
    /**
     * @generated from protobuf field: uint32 raw_strain_delta_per_minute = 6;
     */
    rawStrainDeltaPerMinute: number; // important
    /**
     * @generated from protobuf field: pb.v2.SensorInformation sensor_information = 7;
     */
    sensorInformation?: SensorInformation; // confirmed rssi and software version
}
/**
 * @generated from protobuf message pb.v2.IndustrialData
 */
export interface IndustrialData {
    /**
     * @generated from protobuf field: pb.v2.VibrationSensor vibration = 11;
     */
    vibration?: VibrationSensor;
    /**
     * @generated from protobuf field: pb.v2.MagneticSwitch valve = 12;
     */
    valve?: MagneticSwitch;
    /**
     * @generated from protobuf field: pb.v2.StrainSensor strain = 13;
     */
    strain?: StrainSensor;
}
/**
 * @generated from protobuf message pb.v2.VendorData
 */
export interface VendorData {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "msgPack";
        /**
         * @generated from protobuf field: pb.v2.MessagePack msg_pack = 1;
         */
        msgPack: MessagePack; // encode extra data in a message pack
    } | {
        oneofKind: "generic";
        /**
         * @generated from protobuf field: pb.v2.generic_data generic = 2;
         */
        generic: generic_data;
    } | {
        oneofKind: "rf";
        /**
         * @generated from protobuf field: pb.v2.RfCollections rf = 3;
         */
        rf: RfCollections;
    } | {
        oneofKind: "industrial";
        /**
         * @generated from protobuf field: pb.v2.IndustrialData industrial = 4;
         */
        industrial: IndustrialData;
    } | {
        oneofKind: "radationData";
        /**
         * @generated from protobuf field: pb.v2.RadationData radation_data = 5;
         */
        radationData: RadationData;
    } | {
        oneofKind: "events";
        /**
         * @generated from protobuf field: pb.v2.EventLog events = 13;
         */
        events: EventLog; // / GOING TO BE DEPRECIATED -> moved to system state
    } | {
        oneofKind: "raw";
        /**
         * @generated from protobuf field: pb.v2.RawData raw = 14;
         */
        raw: RawData;
    } | {
        oneofKind: "customData";
        /**
         * @generated from protobuf field: pb.v2.Any custom_data = 15;
         */
        customData: Any;
    } | {
        oneofKind: undefined;
    };
}
/**
 * see ueventlog.h for structure
 *
 * @generated from protobuf message pb.v2.EventLog
 */
export interface EventLog {
    /**
     * @generated from protobuf field: uint32 version = 1;
     */
    version: number;
    /**
     * @generated from protobuf field: uint32 count = 2;
     */
    count: number;
    /**
     * @generated from protobuf field: repeated uint32 events = 3;
     */
    events: number[];
}
/**
 * @generated from protobuf message pb.v2.RawData
 */
export interface RawData {
    /**
     * @generated from protobuf field: uint32 id_record = 1;
     */
    idRecord: number;
    /**
     * @generated from protobuf field: uint32 type = 3;
     */
    type: number;
    /**
     * @generated from protobuf field: uint32 total_segments = 4;
     */
    totalSegments: number;
    /**
     * @generated from protobuf field: uint32 crc32 = 5;
     */
    crc32: number; // optional if not required
}
/**
 * This is sent seperatly from Telemetry to be turned into a file at the server
 *
 * @generated from protobuf message pb.v2.RawDataSegment
 */
export interface RawDataSegment {
    /**
     * @generated from protobuf field: uint64 uuid = 1;
     */
    uuid: bigint; // uuid of the sensor sending this data
    /**
     * @generated from protobuf field: uint32 id = 2;
     */
    id: number; // identifier for the raw data  (could be generated by a timestamp)
    /**
     * @generated from protobuf field: uint32 segment = 3;
     */
    segment: number;
    /**
     * @generated from protobuf field: bytes data = 4;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message pb.v2.Message_Settings
 */
export interface Message_Settings {
    /**
     * @generated from protobuf field: pb.v2.MessageFlags message_flag = 1;
     */
    messageFlag: MessageFlags; // typically unused, but for indicating to the server certin messages
    /**
     * @generated from protobuf field: uint32 retain_min_count_telemetry = 2;
     */
    retainMinCountTelemetry: number; // hint to db to how many messages to keep, 0=keep as much as possiable
    /**
     * @generated from protobuf field: uint32 retain_min_days_telemetry = 3;
     */
    retainMinDaysTelemetry: number; // hint to how many days to keep data, 0=keep as much as possiable
}
/**
 * Telemetry should hold all common  data between any project, sensor, and device
 *
 * @generated from protobuf message pb.v2.Telemetry
 */
export interface Telemetry {
    /**
     * @generated from protobuf field: uint64 message_counter = 1;
     */
    messageCounter: bigint; // each message will have an ordered message counter starting at 0 per dataset
    /**
     * @generated from protobuf field: uint64 uuid = 2;
     */
    uuid: bigint; // ONBOARDABLE/CHANGABLE - each sensor will have a unique id that will remain constant per "device"
    /**
     * @generated from protobuf field: uint32 sensor_type_id = 3;
     */
    sensorTypeId: number;
    /**
     * @generated from protobuf field: uint64 epoch_ms = 4;
     */
    epochMs: bigint; // mili_seconds in epoch time 
    /**
     * @generated from protobuf field: sfixed32 latitude_1e7 = 5 [json_name = "latitude1e7"];
     */
    latitude1E7: number; // signed fixed point representation. signed fixed * 1e-7 = floating. 
    /**
     * @generated from protobuf field: sfixed32 longitude_1e7 = 6 [json_name = "longitude1e7"];
     */
    longitude1E7: number; // signed fixed point representation. signed fixed * 1e-7 = floating. 
    /**
     * @generated from protobuf field: sint32 elevation_cm = 7;
     */
    elevationCm: number; // Height in cm, 0 is sea-level
    /**
     * @generated from protobuf field: pb.v2.GPSScanRecord gps_scan_record = 8;
     */
    gpsScanRecord?: GPSScanRecord;
    /**
     * @generated from protobuf field: pb.v2.SystemState system = 9;
     */
    system?: SystemState; // system health
    /**
     * @generated from protobuf field: pb.v2.MotionState motion_state = 10;
     */
    motionState: MotionState;
    /**
     * @generated from protobuf field: pb.v2.Environment environment = 11;
     */
    environment?: Environment;
    /**
     * @generated from protobuf field: uint64 serial = 12;
     */
    serial: bigint; // Unique identifier based off the hardware, for example macAddress, imei, something hardware specific.
    /**
     * @generated from protobuf field: pb.v2.Message_Settings settings = 13;
     */
    settings?: Message_Settings;
    /**
     * @generated from protobuf field: pb.v2.VendorData vendor_data = 14;
     */
    vendorData?: VendorData;
    /**
     * @generated from protobuf field: repeated pb.v2.KeyValue key_values = 15;
     */
    keyValues: KeyValue[];
}
/**
 * @generated from protobuf enum pb.v2.DetectorMaterialType
 */
export enum DetectorMaterialType {
    /**
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * sodium iodide [NaI],
     *
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_NAI = 1;
     */
    NAI = 1,
    /**
     * Geiger Mueller tube
     *
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_GMT = 2;
     */
    GMT = 2,
    /**
     * polyvinyl toluene
     *
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_PVT = 3;
     */
    PVT = 3,
    /**
     * CsI(Tl) Thallium activated Cesium Iodide
     *
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_CSI = 4;
     */
    CSI = 4,
    /**
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_HE3 = 5;
     */
    HE3 = 5,
    /**
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_LI6 = 6;
     */
    LI6 = 6,
    /**
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_B10 = 7;
     */
    B10 = 7,
    /**
     * SrI2 (Eu) Gamma Detector
     *
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_SRI = 8;
     */
    SRI = 8,
    /**
     * @generated from protobuf enum value: DETECTOR_MATERIAL_TYPE_CZT = 9;
     */
    CZT = 9
}
/**
 * @generated from protobuf enum pb.v2.GammaNeutronState
 */
export enum GammaNeutronState {
    /**
     * @generated from protobuf enum value: GAMMA_NEUTRON_STATE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: GAMMA_NEUTRON_STATE_NORMAL = 1;
     */
    NORMAL = 1,
    /**
     * @generated from protobuf enum value: GAMMA_NEUTRON_STATE_ALARM = 2;
     */
    ALARM = 2,
    /**
     * @generated from protobuf enum value: GAMMA_NEUTRON_STATE_UPDATE = 3;
     */
    UPDATE = 3,
    /**
     * @generated from protobuf enum value: GAMMA_NEUTRON_STATE_BACKGROUND = 4;
     */
    BACKGROUND = 4,
    /**
     * @generated from protobuf enum value: GAMMA_NEUTRON_STATE_MAX_SATURATION = 5;
     */
    MAX_SATURATION = 5,
    /**
     * @generated from protobuf enum value: GAMMA_NEUTRON_STATE_ERROR = 6;
     */
    ERROR = 6
}
/**
 * @generated from protobuf enum pb.v2.SensorClassType
 */
export enum SensorClassType {
    /**
     * @generated from protobuf enum value: SENSOR_CLASS_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * pager / personal radation device
     *
     * @generated from protobuf enum value: SENSOR_CLASS_TYPE_PRD = 1;
     */
    PRD = 1,
    /**
     * @generated from protobuf enum value: SENSOR_CLASS_TYPE_MOBILE_HANDHELD = 2;
     */
    MOBILE_HANDHELD = 2,
    /**
     * @generated from protobuf enum value: SENSOR_CLASS_TYPE_MOBILE_BACKPACK = 3;
     */
    MOBILE_BACKPACK = 3,
    /**
     * @generated from protobuf enum value: SENSOR_CLASS_TYPE_MOBILE_VEHICLE = 4;
     */
    MOBILE_VEHICLE = 4,
    /**
     * @generated from protobuf enum value: SENSOR_CLASS_TYPE_PORTAL = 5;
     */
    PORTAL = 5
}
/**
 * @generated from protobuf enum pb.v2.MotionState
 */
export enum MotionState {
    /**
     * @generated from protobuf enum value: MOTION_STATE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: MOTION_STATE_STATIONARY = 1;
     */
    STATIONARY = 1,
    /**
     * @generated from protobuf enum value: MOTION_STATE_WALKING = 2;
     */
    WALKING = 2,
    /**
     * @generated from protobuf enum value: MOTION_STATE_JOGGING = 3;
     */
    JOGGING = 3,
    /**
     * @generated from protobuf enum value: MOTION_STATE_BIKING = 4;
     */
    BIKING = 4,
    /**
     * @generated from protobuf enum value: MOTION_STATE_DRIVING = 5;
     */
    DRIVING = 5,
    /**
     * @generated from protobuf enum value: MOTION_STATE_FLYING = 6;
     */
    FLYING = 6,
    /**
     * @generated from protobuf enum value: MOTION_STATE_FALLING = 7;
     */
    FALLING = 7,
    /**
     * @generated from protobuf enum value: MOTION_STATE_TILTED = 8;
     */
    TILTED = 8,
    /**
     * @generated from protobuf enum value: MOTION_STATE_IMPACT = 9;
     */
    IMPACT = 9
}
/**
 * @generated from protobuf enum pb.v2.MessageFlags
 */
export enum MessageFlags {
    /**
     * standard telemetry message use as indtended
     *
     * @generated from protobuf enum value: MESSAGE_FLAG_NORMAL = 0;
     */
    MESSAGE_FLAG_NORMAL = 0,
    /**
     * sim message, to help prevent being confused with real data
     *
     * @generated from protobuf enum value: MESSAGE_FLAG_SIMULATION = 1;
     */
    MESSAGE_FLAG_SIMULATION = 1,
    /**
     * testing message, to not be used for UI
     *
     * @generated from protobuf enum value: MESSAGE_FLAG_TESTING = 2;
     */
    MESSAGE_FLAG_TESTING = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class RadionuclideData$Type extends MessageType<RadionuclideData> {
    constructor() {
        super("pb.v2.RadionuclideData", [
            { no: 1, name: "gamma_live_time_duration", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "gamma_real_time_duration", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "radionuclide_identificationA", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "radionuclide_confidenceA", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "radionuclide_identificationB", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "radionuclide_confidenceB", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "radionuclide_identificationC", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "radionuclide_confidenceC", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "calibration_coefficient_A", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 10, name: "calibration_coefficient_B", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 11, name: "calibration_coefficient_C", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 15, name: "spectra", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/, options: { nanopb: { maxCount: 1024 } } }
        ]);
    }
    create(value?: PartialMessage<RadionuclideData>): RadionuclideData {
        const message = { gammaLiveTimeDuration: 0, gammaRealTimeDuration: 0, radionuclideIdentificationA: 0, radionuclideConfidenceA: 0, radionuclideIdentificationB: 0, radionuclideConfidenceB: 0, radionuclideIdentificationC: 0, radionuclideConfidenceC: 0, calibrationCoefficientA: 0, calibrationCoefficientB: 0, calibrationCoefficientC: 0, spectra: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RadionuclideData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RadionuclideData): RadionuclideData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 gamma_live_time_duration */ 1:
                    message.gammaLiveTimeDuration = reader.uint32();
                    break;
                case /* uint32 gamma_real_time_duration */ 2:
                    message.gammaRealTimeDuration = reader.uint32();
                    break;
                case /* uint32 radionuclide_identificationA */ 3:
                    message.radionuclideIdentificationA = reader.uint32();
                    break;
                case /* uint32 radionuclide_confidenceA */ 4:
                    message.radionuclideConfidenceA = reader.uint32();
                    break;
                case /* uint32 radionuclide_identificationB */ 5:
                    message.radionuclideIdentificationB = reader.uint32();
                    break;
                case /* uint32 radionuclide_confidenceB */ 6:
                    message.radionuclideConfidenceB = reader.uint32();
                    break;
                case /* uint32 radionuclide_identificationC */ 7:
                    message.radionuclideIdentificationC = reader.uint32();
                    break;
                case /* uint32 radionuclide_confidenceC */ 8:
                    message.radionuclideConfidenceC = reader.uint32();
                    break;
                case /* float calibration_coefficient_A */ 9:
                    message.calibrationCoefficientA = reader.float();
                    break;
                case /* float calibration_coefficient_B */ 10:
                    message.calibrationCoefficientB = reader.float();
                    break;
                case /* float calibration_coefficient_C */ 11:
                    message.calibrationCoefficientC = reader.float();
                    break;
                case /* repeated uint32 spectra */ 15:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.spectra.push(reader.uint32());
                    else
                        message.spectra.push(reader.uint32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RadionuclideData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 gamma_live_time_duration = 1; */
        if (message.gammaLiveTimeDuration !== 0)
            writer.tag(1, WireType.Varint).uint32(message.gammaLiveTimeDuration);
        /* uint32 gamma_real_time_duration = 2; */
        if (message.gammaRealTimeDuration !== 0)
            writer.tag(2, WireType.Varint).uint32(message.gammaRealTimeDuration);
        /* uint32 radionuclide_identificationA = 3; */
        if (message.radionuclideIdentificationA !== 0)
            writer.tag(3, WireType.Varint).uint32(message.radionuclideIdentificationA);
        /* uint32 radionuclide_confidenceA = 4; */
        if (message.radionuclideConfidenceA !== 0)
            writer.tag(4, WireType.Varint).uint32(message.radionuclideConfidenceA);
        /* uint32 radionuclide_identificationB = 5; */
        if (message.radionuclideIdentificationB !== 0)
            writer.tag(5, WireType.Varint).uint32(message.radionuclideIdentificationB);
        /* uint32 radionuclide_confidenceB = 6; */
        if (message.radionuclideConfidenceB !== 0)
            writer.tag(6, WireType.Varint).uint32(message.radionuclideConfidenceB);
        /* uint32 radionuclide_identificationC = 7; */
        if (message.radionuclideIdentificationC !== 0)
            writer.tag(7, WireType.Varint).uint32(message.radionuclideIdentificationC);
        /* uint32 radionuclide_confidenceC = 8; */
        if (message.radionuclideConfidenceC !== 0)
            writer.tag(8, WireType.Varint).uint32(message.radionuclideConfidenceC);
        /* float calibration_coefficient_A = 9; */
        if (message.calibrationCoefficientA !== 0)
            writer.tag(9, WireType.Bit32).float(message.calibrationCoefficientA);
        /* float calibration_coefficient_B = 10; */
        if (message.calibrationCoefficientB !== 0)
            writer.tag(10, WireType.Bit32).float(message.calibrationCoefficientB);
        /* float calibration_coefficient_C = 11; */
        if (message.calibrationCoefficientC !== 0)
            writer.tag(11, WireType.Bit32).float(message.calibrationCoefficientC);
        /* repeated uint32 spectra = 15; */
        if (message.spectra.length) {
            writer.tag(15, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.spectra.length; i++)
                writer.uint32(message.spectra[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.RadionuclideData
 */
export const RadionuclideData = new RadionuclideData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GammaData$Type extends MessageType<GammaData> {
    constructor() {
        super("pb.v2.GammaData", [
            { no: 1, name: "gamma_total_gross_counts", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "gamma_background_count_rate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "gamma_alarm_count_threshold", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "gamma_alarm_level", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "gamma_exposure_rate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "gamma_total_exposure_rate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "gamma_sensor_temperature", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 8, name: "gamma_state", kind: "enum", T: () => ["pb.v2.GammaNeutronState", GammaNeutronState, "GAMMA_NEUTRON_STATE_"] },
            { no: 14, name: "collection_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "gamma_detector_material", kind: "enum", T: () => ["pb.v2.DetectorMaterialType", DetectorMaterialType, "DETECTOR_MATERIAL_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GammaData>): GammaData {
        const message = { gammaTotalGrossCounts: 0, gammaBackgroundCountRate: 0, gammaAlarmCountThreshold: 0, gammaAlarmLevel: 0, gammaExposureRate: 0, gammaTotalExposureRate: 0, gammaSensorTemperature: 0, gammaState: 0, collectionId: 0, gammaDetectorMaterial: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GammaData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GammaData): GammaData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 gamma_total_gross_counts */ 1:
                    message.gammaTotalGrossCounts = reader.uint32();
                    break;
                case /* uint32 gamma_background_count_rate */ 2:
                    message.gammaBackgroundCountRate = reader.uint32();
                    break;
                case /* uint32 gamma_alarm_count_threshold */ 3:
                    message.gammaAlarmCountThreshold = reader.uint32();
                    break;
                case /* uint32 gamma_alarm_level */ 4:
                    message.gammaAlarmLevel = reader.uint32();
                    break;
                case /* uint32 gamma_exposure_rate */ 5:
                    message.gammaExposureRate = reader.uint32();
                    break;
                case /* uint32 gamma_total_exposure_rate */ 6:
                    message.gammaTotalExposureRate = reader.uint32();
                    break;
                case /* sint32 gamma_sensor_temperature */ 7:
                    message.gammaSensorTemperature = reader.sint32();
                    break;
                case /* pb.v2.GammaNeutronState gamma_state */ 8:
                    message.gammaState = reader.int32();
                    break;
                case /* uint32 collection_id */ 14:
                    message.collectionId = reader.uint32();
                    break;
                case /* pb.v2.DetectorMaterialType gamma_detector_material */ 15:
                    message.gammaDetectorMaterial = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GammaData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 gamma_total_gross_counts = 1; */
        if (message.gammaTotalGrossCounts !== 0)
            writer.tag(1, WireType.Varint).uint32(message.gammaTotalGrossCounts);
        /* uint32 gamma_background_count_rate = 2; */
        if (message.gammaBackgroundCountRate !== 0)
            writer.tag(2, WireType.Varint).uint32(message.gammaBackgroundCountRate);
        /* uint32 gamma_alarm_count_threshold = 3; */
        if (message.gammaAlarmCountThreshold !== 0)
            writer.tag(3, WireType.Varint).uint32(message.gammaAlarmCountThreshold);
        /* uint32 gamma_alarm_level = 4; */
        if (message.gammaAlarmLevel !== 0)
            writer.tag(4, WireType.Varint).uint32(message.gammaAlarmLevel);
        /* uint32 gamma_exposure_rate = 5; */
        if (message.gammaExposureRate !== 0)
            writer.tag(5, WireType.Varint).uint32(message.gammaExposureRate);
        /* uint32 gamma_total_exposure_rate = 6; */
        if (message.gammaTotalExposureRate !== 0)
            writer.tag(6, WireType.Varint).uint32(message.gammaTotalExposureRate);
        /* sint32 gamma_sensor_temperature = 7; */
        if (message.gammaSensorTemperature !== 0)
            writer.tag(7, WireType.Varint).sint32(message.gammaSensorTemperature);
        /* pb.v2.GammaNeutronState gamma_state = 8; */
        if (message.gammaState !== 0)
            writer.tag(8, WireType.Varint).int32(message.gammaState);
        /* uint32 collection_id = 14; */
        if (message.collectionId !== 0)
            writer.tag(14, WireType.Varint).uint32(message.collectionId);
        /* pb.v2.DetectorMaterialType gamma_detector_material = 15; */
        if (message.gammaDetectorMaterial !== 0)
            writer.tag(15, WireType.Varint).int32(message.gammaDetectorMaterial);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GammaData
 */
export const GammaData = new GammaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NeutronData$Type extends MessageType<NeutronData> {
    constructor() {
        super("pb.v2.NeutronData", [
            { no: 1, name: "neutron_total_gross_counts_1e1", kind: "scalar", jsonName: "neutronTotalGrossCounts1e1", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "neutron_background_count_rate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "neutron_alarm_count_threshold", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "neuron_alarm_level", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "neutron_state", kind: "enum", T: () => ["pb.v2.GammaNeutronState", GammaNeutronState, "GAMMA_NEUTRON_STATE_"] },
            { no: 14, name: "collection_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "neutron_detector_material", kind: "enum", T: () => ["pb.v2.DetectorMaterialType", DetectorMaterialType, "DETECTOR_MATERIAL_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<NeutronData>): NeutronData {
        const message = { neutronTotalGrossCounts1E1: 0, neutronBackgroundCountRate: 0, neutronAlarmCountThreshold: 0, neuronAlarmLevel: 0, neutronState: 0, collectionId: 0, neutronDetectorMaterial: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NeutronData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NeutronData): NeutronData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 neutron_total_gross_counts_1e1 = 1 [json_name = "neutronTotalGrossCounts1e1"];*/ 1:
                    message.neutronTotalGrossCounts1E1 = reader.uint32();
                    break;
                case /* uint32 neutron_background_count_rate */ 2:
                    message.neutronBackgroundCountRate = reader.uint32();
                    break;
                case /* uint32 neutron_alarm_count_threshold */ 3:
                    message.neutronAlarmCountThreshold = reader.uint32();
                    break;
                case /* uint32 neuron_alarm_level */ 4:
                    message.neuronAlarmLevel = reader.uint32();
                    break;
                case /* pb.v2.GammaNeutronState neutron_state */ 5:
                    message.neutronState = reader.int32();
                    break;
                case /* uint32 collection_id */ 14:
                    message.collectionId = reader.uint32();
                    break;
                case /* pb.v2.DetectorMaterialType neutron_detector_material */ 15:
                    message.neutronDetectorMaterial = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NeutronData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 neutron_total_gross_counts_1e1 = 1 [json_name = "neutronTotalGrossCounts1e1"]; */
        if (message.neutronTotalGrossCounts1E1 !== 0)
            writer.tag(1, WireType.Varint).uint32(message.neutronTotalGrossCounts1E1);
        /* uint32 neutron_background_count_rate = 2; */
        if (message.neutronBackgroundCountRate !== 0)
            writer.tag(2, WireType.Varint).uint32(message.neutronBackgroundCountRate);
        /* uint32 neutron_alarm_count_threshold = 3; */
        if (message.neutronAlarmCountThreshold !== 0)
            writer.tag(3, WireType.Varint).uint32(message.neutronAlarmCountThreshold);
        /* uint32 neuron_alarm_level = 4; */
        if (message.neuronAlarmLevel !== 0)
            writer.tag(4, WireType.Varint).uint32(message.neuronAlarmLevel);
        /* pb.v2.GammaNeutronState neutron_state = 5; */
        if (message.neutronState !== 0)
            writer.tag(5, WireType.Varint).int32(message.neutronState);
        /* uint32 collection_id = 14; */
        if (message.collectionId !== 0)
            writer.tag(14, WireType.Varint).uint32(message.collectionId);
        /* pb.v2.DetectorMaterialType neutron_detector_material = 15; */
        if (message.neutronDetectorMaterial !== 0)
            writer.tag(15, WireType.Varint).int32(message.neutronDetectorMaterial);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.NeutronData
 */
export const NeutronData = new NeutronData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SensorInformation$Type extends MessageType<SensorInformation> {
    constructor() {
        super("pb.v2.SensorInformation", [
            { no: 1, name: "sensor_manufacturer", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "sensor_model", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "sensor_serial_number", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "sensor_software_version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "sensor_reporting_rate_ms", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "sensorClass", kind: "enum", T: () => ["pb.v2.SensorClassType", SensorClassType, "SENSOR_CLASS_TYPE_"] },
            { no: 7, name: "connection", kind: "enum", T: () => ["pb.v2.SensorInformation.Connection", SensorInformation_Connection, "CONNECTION_"] },
            { no: 8, name: "sensor_rssi_dbm", kind: "scalar", T: 17 /*ScalarType.SINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SensorInformation>): SensorInformation {
        const message = { sensorManufacturer: 0, sensorModel: 0, sensorSerialNumber: 0, sensorSoftwareVersion: 0, sensorReportingRateMs: 0, sensorClass: 0, connection: 0, sensorRssiDbm: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SensorInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SensorInformation): SensorInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 sensor_manufacturer */ 1:
                    message.sensorManufacturer = reader.uint32();
                    break;
                case /* uint32 sensor_model */ 2:
                    message.sensorModel = reader.uint32();
                    break;
                case /* uint32 sensor_serial_number */ 3:
                    message.sensorSerialNumber = reader.uint32();
                    break;
                case /* uint32 sensor_software_version */ 4:
                    message.sensorSoftwareVersion = reader.uint32();
                    break;
                case /* uint32 sensor_reporting_rate_ms */ 5:
                    message.sensorReportingRateMs = reader.uint32();
                    break;
                case /* pb.v2.SensorClassType sensorClass */ 6:
                    message.sensorClass = reader.int32();
                    break;
                case /* pb.v2.SensorInformation.Connection connection */ 7:
                    message.connection = reader.int32();
                    break;
                case /* sint32 sensor_rssi_dbm */ 8:
                    message.sensorRssiDbm = reader.sint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SensorInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 sensor_manufacturer = 1; */
        if (message.sensorManufacturer !== 0)
            writer.tag(1, WireType.Varint).uint32(message.sensorManufacturer);
        /* uint32 sensor_model = 2; */
        if (message.sensorModel !== 0)
            writer.tag(2, WireType.Varint).uint32(message.sensorModel);
        /* uint32 sensor_serial_number = 3; */
        if (message.sensorSerialNumber !== 0)
            writer.tag(3, WireType.Varint).uint32(message.sensorSerialNumber);
        /* uint32 sensor_software_version = 4; */
        if (message.sensorSoftwareVersion !== 0)
            writer.tag(4, WireType.Varint).uint32(message.sensorSoftwareVersion);
        /* uint32 sensor_reporting_rate_ms = 5; */
        if (message.sensorReportingRateMs !== 0)
            writer.tag(5, WireType.Varint).uint32(message.sensorReportingRateMs);
        /* pb.v2.SensorClassType sensorClass = 6; */
        if (message.sensorClass !== 0)
            writer.tag(6, WireType.Varint).int32(message.sensorClass);
        /* pb.v2.SensorInformation.Connection connection = 7; */
        if (message.connection !== 0)
            writer.tag(7, WireType.Varint).int32(message.connection);
        /* sint32 sensor_rssi_dbm = 8; */
        if (message.sensorRssiDbm !== 0)
            writer.tag(8, WireType.Varint).sint32(message.sensorRssiDbm);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.SensorInformation
 */
export const SensorInformation = new SensorInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RadationData$Type extends MessageType<RadationData> {
    constructor() {
        super("pb.v2.RadationData", [
            { no: 1, name: "sensorInformation", kind: "message", T: () => SensorInformation },
            { no: 2, name: "gammaData", kind: "message", T: () => GammaData },
            { no: 3, name: "neutronData", kind: "message", T: () => NeutronData },
            { no: 4, name: "gammaDataSensors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GammaData },
            { no: 5, name: "neutronDataSensors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NeutronData },
            { no: 15, name: "radionuclideData", kind: "message", T: () => RadionuclideData }
        ]);
    }
    create(value?: PartialMessage<RadationData>): RadationData {
        const message = { gammaDataSensors: [], neutronDataSensors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RadationData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RadationData): RadationData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.SensorInformation sensorInformation */ 1:
                    message.sensorInformation = SensorInformation.internalBinaryRead(reader, reader.uint32(), options, message.sensorInformation);
                    break;
                case /* pb.v2.GammaData gammaData */ 2:
                    message.gammaData = GammaData.internalBinaryRead(reader, reader.uint32(), options, message.gammaData);
                    break;
                case /* pb.v2.NeutronData neutronData */ 3:
                    message.neutronData = NeutronData.internalBinaryRead(reader, reader.uint32(), options, message.neutronData);
                    break;
                case /* repeated pb.v2.GammaData gammaDataSensors */ 4:
                    message.gammaDataSensors.push(GammaData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.NeutronData neutronDataSensors */ 5:
                    message.neutronDataSensors.push(NeutronData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* pb.v2.RadionuclideData radionuclideData */ 15:
                    message.radionuclideData = RadionuclideData.internalBinaryRead(reader, reader.uint32(), options, message.radionuclideData);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RadationData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.SensorInformation sensorInformation = 1; */
        if (message.sensorInformation)
            SensorInformation.internalBinaryWrite(message.sensorInformation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.GammaData gammaData = 2; */
        if (message.gammaData)
            GammaData.internalBinaryWrite(message.gammaData, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.NeutronData neutronData = 3; */
        if (message.neutronData)
            NeutronData.internalBinaryWrite(message.neutronData, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.GammaData gammaDataSensors = 4; */
        for (let i = 0; i < message.gammaDataSensors.length; i++)
            GammaData.internalBinaryWrite(message.gammaDataSensors[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.NeutronData neutronDataSensors = 5; */
        for (let i = 0; i < message.neutronDataSensors.length; i++)
            NeutronData.internalBinaryWrite(message.neutronDataSensors[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.RadionuclideData radionuclideData = 15; */
        if (message.radionuclideData)
            RadionuclideData.internalBinaryWrite(message.radionuclideData, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.RadationData
 */
export const RadationData = new RadationData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Any$Type extends MessageType<Any> {
    constructor() {
        super("pb.v2.Any", [
            { no: 1, name: "type_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<Any>): Any {
        const message = { typeUrl: "", value: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Any>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Any): Any {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type_url */ 1:
                    message.typeUrl = reader.string();
                    break;
                case /* bytes value */ 2:
                    message.value = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Any, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type_url = 1; */
        if (message.typeUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.typeUrl);
        /* bytes value = 2; */
        if (message.value.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Any
 */
export const Any = new Any$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CellScanRecord$Type extends MessageType<CellScanRecord> {
    constructor() {
        super("pb.v2.CellScanRecord", [
            { no: 1, name: "mcc", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "mnc", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "cid", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "las", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "rssi", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 6, name: "simid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<CellScanRecord>): CellScanRecord {
        const message = { mcc: 0, mnc: 0, cid: 0, las: 0, rssi: 0, simid: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CellScanRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CellScanRecord): CellScanRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 mcc */ 1:
                    message.mcc = reader.uint32();
                    break;
                case /* uint32 mnc */ 2:
                    message.mnc = reader.uint32();
                    break;
                case /* uint32 cid */ 3:
                    message.cid = reader.uint32();
                    break;
                case /* uint32 las */ 4:
                    message.las = reader.uint32();
                    break;
                case /* sint32 rssi */ 5:
                    message.rssi = reader.sint32();
                    break;
                case /* uint64 simid */ 6:
                    message.simid = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CellScanRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 mcc = 1; */
        if (message.mcc !== 0)
            writer.tag(1, WireType.Varint).uint32(message.mcc);
        /* uint32 mnc = 2; */
        if (message.mnc !== 0)
            writer.tag(2, WireType.Varint).uint32(message.mnc);
        /* uint32 cid = 3; */
        if (message.cid !== 0)
            writer.tag(3, WireType.Varint).uint32(message.cid);
        /* uint32 las = 4; */
        if (message.las !== 0)
            writer.tag(4, WireType.Varint).uint32(message.las);
        /* sint32 rssi = 5; */
        if (message.rssi !== 0)
            writer.tag(5, WireType.Varint).sint32(message.rssi);
        /* uint64 simid = 6; */
        if (message.simid !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.simid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.CellScanRecord
 */
export const CellScanRecord = new CellScanRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GPSScanRecord$Type extends MessageType<GPSScanRecord> {
    constructor() {
        super("pb.v2.GPSScanRecord", [
            { no: 4, name: "fix_type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "velocity_kmh", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "heading_degrees", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "sats_locked", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "sats_in_view", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "pdop", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "geopoint_accuracy_cm", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "elevation_accuracy_cm", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "epoch_miliseconds", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GPSScanRecord>): GPSScanRecord {
        const message = { fixType: 0, velocityKmh: 0, headingDegrees: 0, satsLocked: 0, satsInView: 0, pdop: 0, geopointAccuracyCm: 0, elevationAccuracyCm: 0, epochMiliseconds: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GPSScanRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GPSScanRecord): GPSScanRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 fix_type */ 4:
                    message.fixType = reader.uint32();
                    break;
                case /* uint32 velocity_kmh */ 5:
                    message.velocityKmh = reader.uint32();
                    break;
                case /* uint32 heading_degrees */ 6:
                    message.headingDegrees = reader.uint32();
                    break;
                case /* uint32 sats_locked */ 7:
                    message.satsLocked = reader.uint32();
                    break;
                case /* uint32 sats_in_view */ 8:
                    message.satsInView = reader.uint32();
                    break;
                case /* uint32 pdop */ 9:
                    message.pdop = reader.uint32();
                    break;
                case /* uint32 geopoint_accuracy_cm */ 10:
                    message.geopointAccuracyCm = reader.uint32();
                    break;
                case /* uint32 elevation_accuracy_cm */ 11:
                    message.elevationAccuracyCm = reader.uint32();
                    break;
                case /* uint64 epoch_miliseconds */ 12:
                    message.epochMiliseconds = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GPSScanRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 fix_type = 4; */
        if (message.fixType !== 0)
            writer.tag(4, WireType.Varint).uint32(message.fixType);
        /* uint32 velocity_kmh = 5; */
        if (message.velocityKmh !== 0)
            writer.tag(5, WireType.Varint).uint32(message.velocityKmh);
        /* uint32 heading_degrees = 6; */
        if (message.headingDegrees !== 0)
            writer.tag(6, WireType.Varint).uint32(message.headingDegrees);
        /* uint32 sats_locked = 7; */
        if (message.satsLocked !== 0)
            writer.tag(7, WireType.Varint).uint32(message.satsLocked);
        /* uint32 sats_in_view = 8; */
        if (message.satsInView !== 0)
            writer.tag(8, WireType.Varint).uint32(message.satsInView);
        /* uint32 pdop = 9; */
        if (message.pdop !== 0)
            writer.tag(9, WireType.Varint).uint32(message.pdop);
        /* uint32 geopoint_accuracy_cm = 10; */
        if (message.geopointAccuracyCm !== 0)
            writer.tag(10, WireType.Varint).uint32(message.geopointAccuracyCm);
        /* uint32 elevation_accuracy_cm = 11; */
        if (message.elevationAccuracyCm !== 0)
            writer.tag(11, WireType.Varint).uint32(message.elevationAccuracyCm);
        /* uint64 epoch_miliseconds = 12; */
        if (message.epochMiliseconds !== 0n)
            writer.tag(12, WireType.Varint).uint64(message.epochMiliseconds);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GPSScanRecord
 */
export const GPSScanRecord = new GPSScanRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PM25Record$Type extends MessageType<PM25Record> {
    constructor() {
        super("pb.v2.PM25Record", [
            { no: 1, name: "pm10_standard", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "pm25_standard", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "pm100_standard", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "pm10_env", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "pm25_env", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "pm100_env", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "particles_03um", kind: "scalar", jsonName: "particles03um", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "particles_05um", kind: "scalar", jsonName: "particles05um", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "particles_10um", kind: "scalar", jsonName: "particles10um", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "particles_25um", kind: "scalar", jsonName: "particles25um", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "particles_50um", kind: "scalar", jsonName: "particles50um", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "particles_100um", kind: "scalar", jsonName: "particles100um", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<PM25Record>): PM25Record {
        const message = { pm10Standard: 0, pm25Standard: 0, pm100Standard: 0, pm10Env: 0, pm25Env: 0, pm100Env: 0, particles03Um: 0, particles05Um: 0, particles10Um: 0, particles25Um: 0, particles50Um: 0, particles100Um: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PM25Record>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PM25Record): PM25Record {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 pm10_standard */ 1:
                    message.pm10Standard = reader.uint32();
                    break;
                case /* uint32 pm25_standard */ 2:
                    message.pm25Standard = reader.uint32();
                    break;
                case /* uint32 pm100_standard */ 3:
                    message.pm100Standard = reader.uint32();
                    break;
                case /* uint32 pm10_env */ 4:
                    message.pm10Env = reader.uint32();
                    break;
                case /* uint32 pm25_env */ 5:
                    message.pm25Env = reader.uint32();
                    break;
                case /* uint32 pm100_env */ 6:
                    message.pm100Env = reader.uint32();
                    break;
                case /* uint32 particles_03um = 7 [json_name = "particles03um"];*/ 7:
                    message.particles03Um = reader.uint32();
                    break;
                case /* uint32 particles_05um = 8 [json_name = "particles05um"];*/ 8:
                    message.particles05Um = reader.uint32();
                    break;
                case /* uint32 particles_10um = 9 [json_name = "particles10um"];*/ 9:
                    message.particles10Um = reader.uint32();
                    break;
                case /* uint32 particles_25um = 10 [json_name = "particles25um"];*/ 10:
                    message.particles25Um = reader.uint32();
                    break;
                case /* uint32 particles_50um = 11 [json_name = "particles50um"];*/ 11:
                    message.particles50Um = reader.uint32();
                    break;
                case /* uint32 particles_100um = 12 [json_name = "particles100um"];*/ 12:
                    message.particles100Um = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PM25Record, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 pm10_standard = 1; */
        if (message.pm10Standard !== 0)
            writer.tag(1, WireType.Varint).uint32(message.pm10Standard);
        /* uint32 pm25_standard = 2; */
        if (message.pm25Standard !== 0)
            writer.tag(2, WireType.Varint).uint32(message.pm25Standard);
        /* uint32 pm100_standard = 3; */
        if (message.pm100Standard !== 0)
            writer.tag(3, WireType.Varint).uint32(message.pm100Standard);
        /* uint32 pm10_env = 4; */
        if (message.pm10Env !== 0)
            writer.tag(4, WireType.Varint).uint32(message.pm10Env);
        /* uint32 pm25_env = 5; */
        if (message.pm25Env !== 0)
            writer.tag(5, WireType.Varint).uint32(message.pm25Env);
        /* uint32 pm100_env = 6; */
        if (message.pm100Env !== 0)
            writer.tag(6, WireType.Varint).uint32(message.pm100Env);
        /* uint32 particles_03um = 7 [json_name = "particles03um"]; */
        if (message.particles03Um !== 0)
            writer.tag(7, WireType.Varint).uint32(message.particles03Um);
        /* uint32 particles_05um = 8 [json_name = "particles05um"]; */
        if (message.particles05Um !== 0)
            writer.tag(8, WireType.Varint).uint32(message.particles05Um);
        /* uint32 particles_10um = 9 [json_name = "particles10um"]; */
        if (message.particles10Um !== 0)
            writer.tag(9, WireType.Varint).uint32(message.particles10Um);
        /* uint32 particles_25um = 10 [json_name = "particles25um"]; */
        if (message.particles25Um !== 0)
            writer.tag(10, WireType.Varint).uint32(message.particles25Um);
        /* uint32 particles_50um = 11 [json_name = "particles50um"]; */
        if (message.particles50Um !== 0)
            writer.tag(11, WireType.Varint).uint32(message.particles50Um);
        /* uint32 particles_100um = 12 [json_name = "particles100um"]; */
        if (message.particles100Um !== 0)
            writer.tag(12, WireType.Varint).uint32(message.particles100Um);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.PM25Record
 */
export const PM25Record = new PM25Record$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MOSRecord$Type extends MessageType<MOSRecord> {
    constructor() {
        super("pb.v2.MOSRecord", [
            { no: 1, name: "gas_resistance", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "iaq_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "red_resistance", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "NH3_resistance", kind: "scalar", jsonName: "NH3Resistance", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "OX_resistance", kind: "scalar", jsonName: "OXResistance", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<MOSRecord>): MOSRecord {
        const message = { gasResistance: 0, iaqIndex: 0, redResistance: 0, nH3Resistance: 0, oXResistance: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MOSRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MOSRecord): MOSRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 gas_resistance */ 1:
                    message.gasResistance = reader.uint32();
                    break;
                case /* uint32 iaq_index */ 2:
                    message.iaqIndex = reader.uint32();
                    break;
                case /* uint32 red_resistance */ 3:
                    message.redResistance = reader.uint32();
                    break;
                case /* uint32 NH3_resistance = 4 [json_name = "NH3Resistance"];*/ 4:
                    message.nH3Resistance = reader.uint32();
                    break;
                case /* uint32 OX_resistance = 5 [json_name = "OXResistance"];*/ 5:
                    message.oXResistance = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MOSRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 gas_resistance = 1; */
        if (message.gasResistance !== 0)
            writer.tag(1, WireType.Varint).uint32(message.gasResistance);
        /* uint32 iaq_index = 2; */
        if (message.iaqIndex !== 0)
            writer.tag(2, WireType.Varint).uint32(message.iaqIndex);
        /* uint32 red_resistance = 3; */
        if (message.redResistance !== 0)
            writer.tag(3, WireType.Varint).uint32(message.redResistance);
        /* uint32 NH3_resistance = 4 [json_name = "NH3Resistance"]; */
        if (message.nH3Resistance !== 0)
            writer.tag(4, WireType.Varint).uint32(message.nH3Resistance);
        /* uint32 OX_resistance = 5 [json_name = "OXResistance"]; */
        if (message.oXResistance !== 0)
            writer.tag(5, WireType.Varint).uint32(message.oXResistance);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.MOSRecord
 */
export const MOSRecord = new MOSRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WindRecord$Type extends MessageType<WindRecord> {
    constructor() {
        super("pb.v2.WindRecord", [
            { no: 1, name: "wind_direction_degrees", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "wind_direction_ave_degrees", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "wind_direction_long_ave_degrees", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "wind_speed_cm_s", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "wind_speed_ave_cm_s", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "wind_speed_long_ave_cm_s", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "wind_speed_gust_cm_s", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "wind_speed_lull_cm_s", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<WindRecord>): WindRecord {
        const message = { windDirectionDegrees: 0, windDirectionAveDegrees: 0, windDirectionLongAveDegrees: 0, windSpeedCmS: 0, windSpeedAveCmS: 0, windSpeedLongAveCmS: 0, windSpeedGustCmS: 0, windSpeedLullCmS: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WindRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WindRecord): WindRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 wind_direction_degrees */ 1:
                    message.windDirectionDegrees = reader.uint32();
                    break;
                case /* uint32 wind_direction_ave_degrees */ 2:
                    message.windDirectionAveDegrees = reader.uint32();
                    break;
                case /* uint32 wind_direction_long_ave_degrees */ 3:
                    message.windDirectionLongAveDegrees = reader.uint32();
                    break;
                case /* uint32 wind_speed_cm_s */ 4:
                    message.windSpeedCmS = reader.uint32();
                    break;
                case /* uint32 wind_speed_ave_cm_s */ 5:
                    message.windSpeedAveCmS = reader.uint32();
                    break;
                case /* uint32 wind_speed_long_ave_cm_s */ 6:
                    message.windSpeedLongAveCmS = reader.uint32();
                    break;
                case /* uint32 wind_speed_gust_cm_s */ 7:
                    message.windSpeedGustCmS = reader.uint32();
                    break;
                case /* uint32 wind_speed_lull_cm_s */ 8:
                    message.windSpeedLullCmS = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WindRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 wind_direction_degrees = 1; */
        if (message.windDirectionDegrees !== 0)
            writer.tag(1, WireType.Varint).uint32(message.windDirectionDegrees);
        /* uint32 wind_direction_ave_degrees = 2; */
        if (message.windDirectionAveDegrees !== 0)
            writer.tag(2, WireType.Varint).uint32(message.windDirectionAveDegrees);
        /* uint32 wind_direction_long_ave_degrees = 3; */
        if (message.windDirectionLongAveDegrees !== 0)
            writer.tag(3, WireType.Varint).uint32(message.windDirectionLongAveDegrees);
        /* uint32 wind_speed_cm_s = 4; */
        if (message.windSpeedCmS !== 0)
            writer.tag(4, WireType.Varint).uint32(message.windSpeedCmS);
        /* uint32 wind_speed_ave_cm_s = 5; */
        if (message.windSpeedAveCmS !== 0)
            writer.tag(5, WireType.Varint).uint32(message.windSpeedAveCmS);
        /* uint32 wind_speed_long_ave_cm_s = 6; */
        if (message.windSpeedLongAveCmS !== 0)
            writer.tag(6, WireType.Varint).uint32(message.windSpeedLongAveCmS);
        /* uint32 wind_speed_gust_cm_s = 7; */
        if (message.windSpeedGustCmS !== 0)
            writer.tag(7, WireType.Varint).uint32(message.windSpeedGustCmS);
        /* uint32 wind_speed_lull_cm_s = 8; */
        if (message.windSpeedLullCmS !== 0)
            writer.tag(8, WireType.Varint).uint32(message.windSpeedLullCmS);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.WindRecord
 */
export const WindRecord = new WindRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Environment$Type extends MessageType<Environment> {
    constructor() {
        super("pb.v2.Environment", [
            { no: 1, name: "temperature_millidegree_c", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 2, name: "barometric_pa", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "humidity_rh", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "CO2_ppm", kind: "scalar", jsonName: "CO2Ppm", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "CO_ppm", kind: "scalar", jsonName: "COPpm", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "O2_ppm", kind: "scalar", jsonName: "O2Ppm", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "H2S_ppm", kind: "scalar", jsonName: "H2SPpm", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "VOC_ppm", kind: "scalar", jsonName: "VOCPpm", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "C2H6OH_ppm", kind: "scalar", jsonName: "C2H6OHPpm", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "H2_ppm", kind: "scalar", jsonName: "H2Ppm", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "NH3_ppm", kind: "scalar", jsonName: "NH3Ppm", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "CH4_ppm", kind: "scalar", jsonName: "CH4Ppm", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "C3H8_ppm", kind: "scalar", jsonName: "C3H8Ppm", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "NO2_ppm", kind: "scalar", jsonName: "NO2Ppm", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "mos", kind: "message", T: () => MOSRecord },
            { no: 16, name: "pmsensor", kind: "message", T: () => PM25Record },
            { no: 17, name: "wind", kind: "message", T: () => WindRecord }
        ]);
    }
    create(value?: PartialMessage<Environment>): Environment {
        const message = { temperatureMillidegreeC: 0, barometricPa: 0, humidityRh: 0, cO2Ppm: 0, cOPpm: 0, o2Ppm: 0, h2SPpm: 0, vOCPpm: 0, c2H6OHPpm: 0, h2Ppm: 0, nH3Ppm: 0, cH4Ppm: 0, c3H8Ppm: 0, nO2Ppm: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Environment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Environment): Environment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint32 temperature_millidegree_c */ 1:
                    message.temperatureMillidegreeC = reader.sint32();
                    break;
                case /* uint32 barometric_pa */ 2:
                    message.barometricPa = reader.uint32();
                    break;
                case /* uint32 humidity_rh */ 3:
                    message.humidityRh = reader.uint32();
                    break;
                case /* uint32 CO2_ppm = 4 [json_name = "CO2Ppm"];*/ 4:
                    message.cO2Ppm = reader.uint32();
                    break;
                case /* uint32 CO_ppm = 5 [json_name = "COPpm"];*/ 5:
                    message.cOPpm = reader.uint32();
                    break;
                case /* uint32 O2_ppm = 6 [json_name = "O2Ppm"];*/ 6:
                    message.o2Ppm = reader.uint32();
                    break;
                case /* uint32 H2S_ppm = 7 [json_name = "H2SPpm"];*/ 7:
                    message.h2SPpm = reader.uint32();
                    break;
                case /* uint32 VOC_ppm = 8 [json_name = "VOCPpm"];*/ 8:
                    message.vOCPpm = reader.uint32();
                    break;
                case /* uint32 C2H6OH_ppm = 9 [json_name = "C2H6OHPpm"];*/ 9:
                    message.c2H6OHPpm = reader.uint32();
                    break;
                case /* uint32 H2_ppm = 10 [json_name = "H2Ppm"];*/ 10:
                    message.h2Ppm = reader.uint32();
                    break;
                case /* uint32 NH3_ppm = 11 [json_name = "NH3Ppm"];*/ 11:
                    message.nH3Ppm = reader.uint32();
                    break;
                case /* uint32 CH4_ppm = 12 [json_name = "CH4Ppm"];*/ 12:
                    message.cH4Ppm = reader.uint32();
                    break;
                case /* uint32 C3H8_ppm = 13 [json_name = "C3H8Ppm"];*/ 13:
                    message.c3H8Ppm = reader.uint32();
                    break;
                case /* uint32 NO2_ppm = 14 [json_name = "NO2Ppm"];*/ 14:
                    message.nO2Ppm = reader.uint32();
                    break;
                case /* pb.v2.MOSRecord mos */ 15:
                    message.mos = MOSRecord.internalBinaryRead(reader, reader.uint32(), options, message.mos);
                    break;
                case /* pb.v2.PM25Record pmsensor */ 16:
                    message.pmsensor = PM25Record.internalBinaryRead(reader, reader.uint32(), options, message.pmsensor);
                    break;
                case /* pb.v2.WindRecord wind */ 17:
                    message.wind = WindRecord.internalBinaryRead(reader, reader.uint32(), options, message.wind);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Environment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint32 temperature_millidegree_c = 1; */
        if (message.temperatureMillidegreeC !== 0)
            writer.tag(1, WireType.Varint).sint32(message.temperatureMillidegreeC);
        /* uint32 barometric_pa = 2; */
        if (message.barometricPa !== 0)
            writer.tag(2, WireType.Varint).uint32(message.barometricPa);
        /* uint32 humidity_rh = 3; */
        if (message.humidityRh !== 0)
            writer.tag(3, WireType.Varint).uint32(message.humidityRh);
        /* uint32 CO2_ppm = 4 [json_name = "CO2Ppm"]; */
        if (message.cO2Ppm !== 0)
            writer.tag(4, WireType.Varint).uint32(message.cO2Ppm);
        /* uint32 CO_ppm = 5 [json_name = "COPpm"]; */
        if (message.cOPpm !== 0)
            writer.tag(5, WireType.Varint).uint32(message.cOPpm);
        /* uint32 O2_ppm = 6 [json_name = "O2Ppm"]; */
        if (message.o2Ppm !== 0)
            writer.tag(6, WireType.Varint).uint32(message.o2Ppm);
        /* uint32 H2S_ppm = 7 [json_name = "H2SPpm"]; */
        if (message.h2SPpm !== 0)
            writer.tag(7, WireType.Varint).uint32(message.h2SPpm);
        /* uint32 VOC_ppm = 8 [json_name = "VOCPpm"]; */
        if (message.vOCPpm !== 0)
            writer.tag(8, WireType.Varint).uint32(message.vOCPpm);
        /* uint32 C2H6OH_ppm = 9 [json_name = "C2H6OHPpm"]; */
        if (message.c2H6OHPpm !== 0)
            writer.tag(9, WireType.Varint).uint32(message.c2H6OHPpm);
        /* uint32 H2_ppm = 10 [json_name = "H2Ppm"]; */
        if (message.h2Ppm !== 0)
            writer.tag(10, WireType.Varint).uint32(message.h2Ppm);
        /* uint32 NH3_ppm = 11 [json_name = "NH3Ppm"]; */
        if (message.nH3Ppm !== 0)
            writer.tag(11, WireType.Varint).uint32(message.nH3Ppm);
        /* uint32 CH4_ppm = 12 [json_name = "CH4Ppm"]; */
        if (message.cH4Ppm !== 0)
            writer.tag(12, WireType.Varint).uint32(message.cH4Ppm);
        /* uint32 C3H8_ppm = 13 [json_name = "C3H8Ppm"]; */
        if (message.c3H8Ppm !== 0)
            writer.tag(13, WireType.Varint).uint32(message.c3H8Ppm);
        /* uint32 NO2_ppm = 14 [json_name = "NO2Ppm"]; */
        if (message.nO2Ppm !== 0)
            writer.tag(14, WireType.Varint).uint32(message.nO2Ppm);
        /* pb.v2.MOSRecord mos = 15; */
        if (message.mos)
            MOSRecord.internalBinaryWrite(message.mos, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.PM25Record pmsensor = 16; */
        if (message.pmsensor)
            PM25Record.internalBinaryWrite(message.pmsensor, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.WindRecord wind = 17; */
        if (message.wind)
            WindRecord.internalBinaryWrite(message.wind, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Environment
 */
export const Environment = new Environment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GridEye$Type extends MessageType<GridEye> {
    constructor() {
        super("pb.v2.GridEye", [
            { no: 1, name: "data", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/, options: { nanopb: { maxCount: 64 } } }
        ]);
    }
    create(value?: PartialMessage<GridEye>): GridEye {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GridEye>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GridEye): GridEye {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated uint32 data */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.data.push(reader.uint32());
                    else
                        message.data.push(reader.uint32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GridEye, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated uint32 data = 1; */
        if (message.data.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.data.length; i++)
                writer.uint32(message.data[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GridEye
 */
export const GridEye = new GridEye$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Health$Type extends MessageType<Health> {
    constructor() {
        super("pb.v2.Health", [
            { no: 1, name: "average_pulse_rate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "raw_pulse_rate_samples", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "raw_pulse_rate_samping_rate_hz", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "plx_measurement", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "bp_systolic_mmHg", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "bp_diastolic_mmHg", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "bp_mean_arterial_mmHg", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "body_movement_detected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "irregular_pulse_detected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "weight_g", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "height_cm", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "body_mass_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "breaths_per_minute", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "body_temperature_milli_c", kind: "scalar", T: 17 /*ScalarType.SINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Health>): Health {
        const message = { averagePulseRate: 0, rawPulseRateSamples: [], rawPulseRateSampingRateHz: 0, plxMeasurement: 0, bpSystolicMmHg: 0, bpDiastolicMmHg: 0, bpMeanArterialMmHg: 0, bodyMovementDetected: false, irregularPulseDetected: false, weightG: 0, heightCm: 0, bodyMassIndex: 0, breathsPerMinute: 0, bodyTemperatureMilliC: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Health>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Health): Health {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 average_pulse_rate */ 1:
                    message.averagePulseRate = reader.uint32();
                    break;
                case /* repeated uint32 raw_pulse_rate_samples */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.rawPulseRateSamples.push(reader.uint32());
                    else
                        message.rawPulseRateSamples.push(reader.uint32());
                    break;
                case /* uint32 raw_pulse_rate_samping_rate_hz */ 3:
                    message.rawPulseRateSampingRateHz = reader.uint32();
                    break;
                case /* uint32 plx_measurement */ 4:
                    message.plxMeasurement = reader.uint32();
                    break;
                case /* uint32 bp_systolic_mmHg */ 5:
                    message.bpSystolicMmHg = reader.uint32();
                    break;
                case /* uint32 bp_diastolic_mmHg */ 6:
                    message.bpDiastolicMmHg = reader.uint32();
                    break;
                case /* uint32 bp_mean_arterial_mmHg */ 7:
                    message.bpMeanArterialMmHg = reader.uint32();
                    break;
                case /* bool body_movement_detected */ 8:
                    message.bodyMovementDetected = reader.bool();
                    break;
                case /* bool irregular_pulse_detected */ 9:
                    message.irregularPulseDetected = reader.bool();
                    break;
                case /* uint32 weight_g */ 10:
                    message.weightG = reader.uint32();
                    break;
                case /* uint32 height_cm */ 11:
                    message.heightCm = reader.uint32();
                    break;
                case /* uint32 body_mass_index */ 12:
                    message.bodyMassIndex = reader.uint32();
                    break;
                case /* uint32 breaths_per_minute */ 13:
                    message.breathsPerMinute = reader.uint32();
                    break;
                case /* sint32 body_temperature_milli_c */ 14:
                    message.bodyTemperatureMilliC = reader.sint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Health, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 average_pulse_rate = 1; */
        if (message.averagePulseRate !== 0)
            writer.tag(1, WireType.Varint).uint32(message.averagePulseRate);
        /* repeated uint32 raw_pulse_rate_samples = 2; */
        if (message.rawPulseRateSamples.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.rawPulseRateSamples.length; i++)
                writer.uint32(message.rawPulseRateSamples[i]);
            writer.join();
        }
        /* uint32 raw_pulse_rate_samping_rate_hz = 3; */
        if (message.rawPulseRateSampingRateHz !== 0)
            writer.tag(3, WireType.Varint).uint32(message.rawPulseRateSampingRateHz);
        /* uint32 plx_measurement = 4; */
        if (message.plxMeasurement !== 0)
            writer.tag(4, WireType.Varint).uint32(message.plxMeasurement);
        /* uint32 bp_systolic_mmHg = 5; */
        if (message.bpSystolicMmHg !== 0)
            writer.tag(5, WireType.Varint).uint32(message.bpSystolicMmHg);
        /* uint32 bp_diastolic_mmHg = 6; */
        if (message.bpDiastolicMmHg !== 0)
            writer.tag(6, WireType.Varint).uint32(message.bpDiastolicMmHg);
        /* uint32 bp_mean_arterial_mmHg = 7; */
        if (message.bpMeanArterialMmHg !== 0)
            writer.tag(7, WireType.Varint).uint32(message.bpMeanArterialMmHg);
        /* bool body_movement_detected = 8; */
        if (message.bodyMovementDetected !== false)
            writer.tag(8, WireType.Varint).bool(message.bodyMovementDetected);
        /* bool irregular_pulse_detected = 9; */
        if (message.irregularPulseDetected !== false)
            writer.tag(9, WireType.Varint).bool(message.irregularPulseDetected);
        /* uint32 weight_g = 10; */
        if (message.weightG !== 0)
            writer.tag(10, WireType.Varint).uint32(message.weightG);
        /* uint32 height_cm = 11; */
        if (message.heightCm !== 0)
            writer.tag(11, WireType.Varint).uint32(message.heightCm);
        /* uint32 body_mass_index = 12; */
        if (message.bodyMassIndex !== 0)
            writer.tag(12, WireType.Varint).uint32(message.bodyMassIndex);
        /* uint32 breaths_per_minute = 13; */
        if (message.breathsPerMinute !== 0)
            writer.tag(13, WireType.Varint).uint32(message.breathsPerMinute);
        /* sint32 body_temperature_milli_c = 14; */
        if (message.bodyTemperatureMilliC !== 0)
            writer.tag(14, WireType.Varint).sint32(message.bodyTemperatureMilliC);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Health
 */
export const Health = new Health$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessagePack$Type extends MessageType<MessagePack> {
    constructor() {
        super("pb.v2.MessagePack", [
            { no: 1, name: "mpack_version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "mpack_counter", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 32 } } }
        ]);
    }
    create(value?: PartialMessage<MessagePack>): MessagePack {
        const message = { mpackVersion: 0, mpackCounter: 0, data: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessagePack>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessagePack): MessagePack {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 mpack_version */ 1:
                    message.mpackVersion = reader.uint32();
                    break;
                case /* uint32 mpack_counter */ 2:
                    message.mpackCounter = reader.uint32();
                    break;
                case /* bytes data */ 3:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessagePack, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 mpack_version = 1; */
        if (message.mpackVersion !== 0)
            writer.tag(1, WireType.Varint).uint32(message.mpackVersion);
        /* uint32 mpack_counter = 2; */
        if (message.mpackCounter !== 0)
            writer.tag(2, WireType.Varint).uint32(message.mpackCounter);
        /* bytes data = 3; */
        if (message.data.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.MessagePack
 */
export const MessagePack = new MessagePack$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KeyValue$Type extends MessageType<KeyValue> {
    constructor() {
        super("pb.v2.KeyValue", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 10 } } },
            { no: 2, name: "value_string", kind: "scalar", oneof: "value", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 32 } } },
            { no: 3, name: "value_array", kind: "scalar", oneof: "value", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 32 } } },
            { no: 4, name: "value_number", kind: "scalar", oneof: "value", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "value_double", kind: "scalar", oneof: "value", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "value_bool", kind: "scalar", oneof: "value", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "null", kind: "scalar", oneof: "value", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<KeyValue>): KeyValue {
        const message = { key: "", value: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KeyValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KeyValue): KeyValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value_string */ 2:
                    message.value = {
                        oneofKind: "valueString",
                        valueString: reader.string()
                    };
                    break;
                case /* bytes value_array */ 3:
                    message.value = {
                        oneofKind: "valueArray",
                        valueArray: reader.bytes()
                    };
                    break;
                case /* int64 value_number */ 4:
                    message.value = {
                        oneofKind: "valueNumber",
                        valueNumber: reader.int64().toBigInt()
                    };
                    break;
                case /* double value_double */ 5:
                    message.value = {
                        oneofKind: "valueDouble",
                        valueDouble: reader.double()
                    };
                    break;
                case /* bool value_bool */ 6:
                    message.value = {
                        oneofKind: "valueBool",
                        valueBool: reader.bool()
                    };
                    break;
                case /* bool null */ 7:
                    message.value = {
                        oneofKind: "null",
                        null: reader.bool()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KeyValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value_string = 2; */
        if (message.value.oneofKind === "valueString")
            writer.tag(2, WireType.LengthDelimited).string(message.value.valueString);
        /* bytes value_array = 3; */
        if (message.value.oneofKind === "valueArray")
            writer.tag(3, WireType.LengthDelimited).bytes(message.value.valueArray);
        /* int64 value_number = 4; */
        if (message.value.oneofKind === "valueNumber")
            writer.tag(4, WireType.Varint).int64(message.value.valueNumber);
        /* double value_double = 5; */
        if (message.value.oneofKind === "valueDouble")
            writer.tag(5, WireType.Bit64).double(message.value.valueDouble);
        /* bool value_bool = 6; */
        if (message.value.oneofKind === "valueBool")
            writer.tag(6, WireType.Varint).bool(message.value.valueBool);
        /* bool null = 7; */
        if (message.value.oneofKind === "null")
            writer.tag(7, WireType.Varint).bool(message.value.null);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.KeyValue
 */
export const KeyValue = new KeyValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WiFiScanRecord$Type extends MessageType<WiFiScanRecord> {
    constructor() {
        super("pb.v2.WiFiScanRecord", [
            { no: 1, name: "macAddress", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 6, fixedLength: true } } },
            { no: 2, name: "signalStrength", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "age", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "channel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "auth_mode", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "wps", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "ssid", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 32 } } }
        ]);
    }
    create(value?: PartialMessage<WiFiScanRecord>): WiFiScanRecord {
        const message = { macAddress: new Uint8Array(0), signalStrength: 0, age: 0, channel: 0, authMode: 0, wps: 0, ssid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WiFiScanRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WiFiScanRecord): WiFiScanRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes macAddress */ 1:
                    message.macAddress = reader.bytes();
                    break;
                case /* sint32 signalStrength */ 2:
                    message.signalStrength = reader.sint32();
                    break;
                case /* uint32 age */ 3:
                    message.age = reader.uint32();
                    break;
                case /* uint32 channel */ 4:
                    message.channel = reader.uint32();
                    break;
                case /* uint32 auth_mode */ 5:
                    message.authMode = reader.uint32();
                    break;
                case /* uint32 wps */ 6:
                    message.wps = reader.uint32();
                    break;
                case /* string ssid */ 7:
                    message.ssid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WiFiScanRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes macAddress = 1; */
        if (message.macAddress.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.macAddress);
        /* sint32 signalStrength = 2; */
        if (message.signalStrength !== 0)
            writer.tag(2, WireType.Varint).sint32(message.signalStrength);
        /* uint32 age = 3; */
        if (message.age !== 0)
            writer.tag(3, WireType.Varint).uint32(message.age);
        /* uint32 channel = 4; */
        if (message.channel !== 0)
            writer.tag(4, WireType.Varint).uint32(message.channel);
        /* uint32 auth_mode = 5; */
        if (message.authMode !== 0)
            writer.tag(5, WireType.Varint).uint32(message.authMode);
        /* uint32 wps = 6; */
        if (message.wps !== 0)
            writer.tag(6, WireType.Varint).uint32(message.wps);
        /* string ssid = 7; */
        if (message.ssid !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.ssid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.WiFiScanRecord
 */
export const WiFiScanRecord = new WiFiScanRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BLEScanRecord$Type extends MessageType<BLEScanRecord> {
    constructor() {
        super("pb.v2.BLEScanRecord", [
            { no: 1, name: "macAddress", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 6, fixedLength: true } } },
            { no: 2, name: "rssi", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "adv_packet", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 31 } } },
            { no: 4, name: "rsp_packet", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 31 } } }
        ]);
    }
    create(value?: PartialMessage<BLEScanRecord>): BLEScanRecord {
        const message = { macAddress: new Uint8Array(0), rssi: 0, advPacket: new Uint8Array(0), rspPacket: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BLEScanRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BLEScanRecord): BLEScanRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes macAddress */ 1:
                    message.macAddress = reader.bytes();
                    break;
                case /* sint32 rssi */ 2:
                    message.rssi = reader.sint32();
                    break;
                case /* bytes adv_packet */ 3:
                    message.advPacket = reader.bytes();
                    break;
                case /* bytes rsp_packet */ 4:
                    message.rspPacket = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BLEScanRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes macAddress = 1; */
        if (message.macAddress.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.macAddress);
        /* sint32 rssi = 2; */
        if (message.rssi !== 0)
            writer.tag(2, WireType.Varint).sint32(message.rssi);
        /* bytes adv_packet = 3; */
        if (message.advPacket.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.advPacket);
        /* bytes rsp_packet = 4; */
        if (message.rspPacket.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.rspPacket);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.BLEScanRecord
 */
export const BLEScanRecord = new BLEScanRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoraConfig$Type extends MessageType<LoraConfig> {
    constructor() {
        super("pb.v2.LoraConfig", [
            { no: 1, name: "rf_frequency", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "bandwidth", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "datarate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "coding_rate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "preamble_len", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "tx_power", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "rssi", kind: "scalar", T: 17 /*ScalarType.SINT32*/ }
        ]);
    }
    create(value?: PartialMessage<LoraConfig>): LoraConfig {
        const message = { rfFrequency: 0, bandwidth: 0, datarate: 0, codingRate: 0, preambleLen: 0, txPower: 0, rssi: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LoraConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoraConfig): LoraConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 rf_frequency */ 1:
                    message.rfFrequency = reader.uint32();
                    break;
                case /* uint32 bandwidth */ 2:
                    message.bandwidth = reader.uint32();
                    break;
                case /* uint32 datarate */ 3:
                    message.datarate = reader.uint32();
                    break;
                case /* uint32 coding_rate */ 4:
                    message.codingRate = reader.uint32();
                    break;
                case /* uint32 preamble_len */ 5:
                    message.preambleLen = reader.uint32();
                    break;
                case /* uint32 tx_power */ 6:
                    message.txPower = reader.uint32();
                    break;
                case /* sint32 rssi */ 7:
                    message.rssi = reader.sint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoraConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 rf_frequency = 1; */
        if (message.rfFrequency !== 0)
            writer.tag(1, WireType.Varint).uint32(message.rfFrequency);
        /* uint32 bandwidth = 2; */
        if (message.bandwidth !== 0)
            writer.tag(2, WireType.Varint).uint32(message.bandwidth);
        /* uint32 datarate = 3; */
        if (message.datarate !== 0)
            writer.tag(3, WireType.Varint).uint32(message.datarate);
        /* uint32 coding_rate = 4; */
        if (message.codingRate !== 0)
            writer.tag(4, WireType.Varint).uint32(message.codingRate);
        /* uint32 preamble_len = 5; */
        if (message.preambleLen !== 0)
            writer.tag(5, WireType.Varint).uint32(message.preambleLen);
        /* uint32 tx_power = 6; */
        if (message.txPower !== 0)
            writer.tag(6, WireType.Varint).uint32(message.txPower);
        /* sint32 rssi = 7; */
        if (message.rssi !== 0)
            writer.tag(7, WireType.Varint).sint32(message.rssi);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.LoraConfig
 */
export const LoraConfig = new LoraConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SystemState$Type extends MessageType<SystemState> {
    constructor() {
        super("pb.v2.SystemState", [
            { no: 1, name: "current_version", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 3 } } },
            { no: 2, name: "uptime_ticks", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "setting_counter", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "chip_version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "battery_voltage_mv", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "solar_voltage_mv", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "external_voltage_mv", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "system_temperature_c", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 12, name: "events", kind: "message", T: () => EventLog },
            { no: 13, name: "connected_cell", kind: "message", T: () => CellScanRecord },
            { no: 14, name: "connected_wifi", kind: "message", T: () => WiFiScanRecord },
            { no: 15, name: "current_lora_config", kind: "message", T: () => LoraConfig },
            { no: 16, name: "connected_ble", kind: "message", T: () => BLEScanRecord }
        ]);
    }
    create(value?: PartialMessage<SystemState>): SystemState {
        const message = { currentVersion: new Uint8Array(0), uptimeTicks: 0, settingCounter: 0, chipVersion: 0, batteryVoltageMv: 0, solarVoltageMv: 0, externalVoltageMv: 0, systemTemperatureC: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SystemState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SystemState): SystemState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes current_version */ 1:
                    message.currentVersion = reader.bytes();
                    break;
                case /* uint32 uptime_ticks */ 2:
                    message.uptimeTicks = reader.uint32();
                    break;
                case /* uint32 setting_counter */ 3:
                    message.settingCounter = reader.uint32();
                    break;
                case /* uint32 chip_version */ 4:
                    message.chipVersion = reader.uint32();
                    break;
                case /* uint32 battery_voltage_mv */ 5:
                    message.batteryVoltageMv = reader.uint32();
                    break;
                case /* uint32 solar_voltage_mv */ 6:
                    message.solarVoltageMv = reader.uint32();
                    break;
                case /* uint32 external_voltage_mv */ 7:
                    message.externalVoltageMv = reader.uint32();
                    break;
                case /* sint32 system_temperature_c */ 8:
                    message.systemTemperatureC = reader.sint32();
                    break;
                case /* pb.v2.EventLog events */ 12:
                    message.events = EventLog.internalBinaryRead(reader, reader.uint32(), options, message.events);
                    break;
                case /* pb.v2.CellScanRecord connected_cell */ 13:
                    message.connectedCell = CellScanRecord.internalBinaryRead(reader, reader.uint32(), options, message.connectedCell);
                    break;
                case /* pb.v2.WiFiScanRecord connected_wifi */ 14:
                    message.connectedWifi = WiFiScanRecord.internalBinaryRead(reader, reader.uint32(), options, message.connectedWifi);
                    break;
                case /* pb.v2.LoraConfig current_lora_config */ 15:
                    message.currentLoraConfig = LoraConfig.internalBinaryRead(reader, reader.uint32(), options, message.currentLoraConfig);
                    break;
                case /* pb.v2.BLEScanRecord connected_ble */ 16:
                    message.connectedBle = BLEScanRecord.internalBinaryRead(reader, reader.uint32(), options, message.connectedBle);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SystemState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes current_version = 1; */
        if (message.currentVersion.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.currentVersion);
        /* uint32 uptime_ticks = 2; */
        if (message.uptimeTicks !== 0)
            writer.tag(2, WireType.Varint).uint32(message.uptimeTicks);
        /* uint32 setting_counter = 3; */
        if (message.settingCounter !== 0)
            writer.tag(3, WireType.Varint).uint32(message.settingCounter);
        /* uint32 chip_version = 4; */
        if (message.chipVersion !== 0)
            writer.tag(4, WireType.Varint).uint32(message.chipVersion);
        /* uint32 battery_voltage_mv = 5; */
        if (message.batteryVoltageMv !== 0)
            writer.tag(5, WireType.Varint).uint32(message.batteryVoltageMv);
        /* uint32 solar_voltage_mv = 6; */
        if (message.solarVoltageMv !== 0)
            writer.tag(6, WireType.Varint).uint32(message.solarVoltageMv);
        /* uint32 external_voltage_mv = 7; */
        if (message.externalVoltageMv !== 0)
            writer.tag(7, WireType.Varint).uint32(message.externalVoltageMv);
        /* sint32 system_temperature_c = 8; */
        if (message.systemTemperatureC !== 0)
            writer.tag(8, WireType.Varint).sint32(message.systemTemperatureC);
        /* pb.v2.EventLog events = 12; */
        if (message.events)
            EventLog.internalBinaryWrite(message.events, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.CellScanRecord connected_cell = 13; */
        if (message.connectedCell)
            CellScanRecord.internalBinaryWrite(message.connectedCell, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.WiFiScanRecord connected_wifi = 14; */
        if (message.connectedWifi)
            WiFiScanRecord.internalBinaryWrite(message.connectedWifi, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.LoraConfig current_lora_config = 15; */
        if (message.currentLoraConfig)
            LoraConfig.internalBinaryWrite(message.currentLoraConfig, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.BLEScanRecord connected_ble = 16; */
        if (message.connectedBle)
            BLEScanRecord.internalBinaryWrite(message.connectedBle, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.SystemState
 */
export const SystemState = new SystemState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class generic_data$Type extends MessageType<generic_data> {
    constructor() {
        super("pb.v2.generic_data", [
            { no: 1, name: "data_collection_type", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "wifi_scan_record", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WiFiScanRecord, options: { nanopb: { maxCount: 10 } } },
            { no: 3, name: "cell_scan_record", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CellScanRecord, options: { nanopb: { maxCount: 3 } } },
            { no: 4, name: "ble_scan_record", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BLEScanRecord, options: { nanopb: { maxCount: 10 } } },
            { no: 5, name: "gridEye", kind: "message", T: () => GridEye },
            { no: 6, name: "health", kind: "message", T: () => Health }
        ]);
    }
    create(value?: PartialMessage<generic_data>): generic_data {
        const message = { dataCollectionType: 0n, wifiScanRecord: [], cellScanRecord: [], bleScanRecord: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<generic_data>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: generic_data): generic_data {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 data_collection_type */ 1:
                    message.dataCollectionType = reader.uint64().toBigInt();
                    break;
                case /* repeated pb.v2.WiFiScanRecord wifi_scan_record */ 2:
                    message.wifiScanRecord.push(WiFiScanRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.CellScanRecord cell_scan_record */ 3:
                    message.cellScanRecord.push(CellScanRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.BLEScanRecord ble_scan_record */ 4:
                    message.bleScanRecord.push(BLEScanRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* pb.v2.GridEye gridEye */ 5:
                    message.gridEye = GridEye.internalBinaryRead(reader, reader.uint32(), options, message.gridEye);
                    break;
                case /* pb.v2.Health health */ 6:
                    message.health = Health.internalBinaryRead(reader, reader.uint32(), options, message.health);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: generic_data, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 data_collection_type = 1; */
        if (message.dataCollectionType !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.dataCollectionType);
        /* repeated pb.v2.WiFiScanRecord wifi_scan_record = 2; */
        for (let i = 0; i < message.wifiScanRecord.length; i++)
            WiFiScanRecord.internalBinaryWrite(message.wifiScanRecord[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.CellScanRecord cell_scan_record = 3; */
        for (let i = 0; i < message.cellScanRecord.length; i++)
            CellScanRecord.internalBinaryWrite(message.cellScanRecord[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.BLEScanRecord ble_scan_record = 4; */
        for (let i = 0; i < message.bleScanRecord.length; i++)
            BLEScanRecord.internalBinaryWrite(message.bleScanRecord[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.GridEye gridEye = 5; */
        if (message.gridEye)
            GridEye.internalBinaryWrite(message.gridEye, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Health health = 6; */
        if (message.health)
            Health.internalBinaryWrite(message.health, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.generic_data
 */
export const generic_data = new generic_data$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RfCollections$Type extends MessageType<RfCollections> {
    constructor() {
        super("pb.v2.RfCollections", [
            { no: 1, name: "rf_collection_type", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "wifi_scan_record", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WiFiScanRecord, options: { nanopb: { maxCount: 10 } } },
            { no: 3, name: "cell_scan_record", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CellScanRecord, options: { nanopb: { maxCount: 3 } } },
            { no: 4, name: "ble_scan_record", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BLEScanRecord, options: { nanopb: { maxCount: 50 } } },
            { no: 5, name: "average_total_wifi_devices", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "average_total_ble_devices", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<RfCollections>): RfCollections {
        const message = { rfCollectionType: 0n, wifiScanRecord: [], cellScanRecord: [], bleScanRecord: [], averageTotalWifiDevices: 0, averageTotalBleDevices: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RfCollections>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RfCollections): RfCollections {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 rf_collection_type */ 1:
                    message.rfCollectionType = reader.uint64().toBigInt();
                    break;
                case /* repeated pb.v2.WiFiScanRecord wifi_scan_record */ 2:
                    message.wifiScanRecord.push(WiFiScanRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.CellScanRecord cell_scan_record */ 3:
                    message.cellScanRecord.push(CellScanRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.BLEScanRecord ble_scan_record */ 4:
                    message.bleScanRecord.push(BLEScanRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 average_total_wifi_devices */ 5:
                    message.averageTotalWifiDevices = reader.uint32();
                    break;
                case /* uint32 average_total_ble_devices */ 6:
                    message.averageTotalBleDevices = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RfCollections, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 rf_collection_type = 1; */
        if (message.rfCollectionType !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.rfCollectionType);
        /* repeated pb.v2.WiFiScanRecord wifi_scan_record = 2; */
        for (let i = 0; i < message.wifiScanRecord.length; i++)
            WiFiScanRecord.internalBinaryWrite(message.wifiScanRecord[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.CellScanRecord cell_scan_record = 3; */
        for (let i = 0; i < message.cellScanRecord.length; i++)
            CellScanRecord.internalBinaryWrite(message.cellScanRecord[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.BLEScanRecord ble_scan_record = 4; */
        for (let i = 0; i < message.bleScanRecord.length; i++)
            BLEScanRecord.internalBinaryWrite(message.bleScanRecord[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint32 average_total_wifi_devices = 5; */
        if (message.averageTotalWifiDevices !== 0)
            writer.tag(5, WireType.Varint).uint32(message.averageTotalWifiDevices);
        /* uint32 average_total_ble_devices = 6; */
        if (message.averageTotalBleDevices !== 0)
            writer.tag(6, WireType.Varint).uint32(message.averageTotalBleDevices);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.RfCollections
 */
export const RfCollections = new RfCollections$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VibrationSensor$Type extends MessageType<VibrationSensor> {
    constructor() {
        super("pb.v2.VibrationSensor", [
            { no: 1, name: "id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "epoch_seconds", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "pcb_accel_lat_max", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "pcb_accel_lat_rms", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "pcb_accel_vert_max", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "pcb_accel_vert_rms", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "pcb_accel_long_max", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "pcb_accel_long_rms", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "adxl_max", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "adxl_rms", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "bearing_temp", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 12, name: "battery_voltage_centivolts", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "bearing_temperature_celsius", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 14, name: "zero_crossing_rate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "fft256_16bit", kind: "scalar", jsonName: "fft25616bit", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/, options: { nanopb: { maxCount: 256 } } },
            { no: 16, name: "sensor_information", kind: "message", T: () => SensorInformation }
        ]);
    }
    create(value?: PartialMessage<VibrationSensor>): VibrationSensor {
        const message = { id: 0n, epochSeconds: 0n, pcbAccelLatMax: 0, pcbAccelLatRms: 0, pcbAccelVertMax: 0, pcbAccelVertRms: 0, pcbAccelLongMax: 0, pcbAccelLongRms: 0, adxlMax: 0, adxlRms: 0, bearingTemp: 0, batteryVoltageCentivolts: 0, bearingTemperatureCelsius: 0, zeroCrossingRate: 0, fft25616Bit: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VibrationSensor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VibrationSensor): VibrationSensor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 id */ 1:
                    message.id = reader.uint64().toBigInt();
                    break;
                case /* uint64 epoch_seconds */ 2:
                    message.epochSeconds = reader.uint64().toBigInt();
                    break;
                case /* uint32 pcb_accel_lat_max */ 3:
                    message.pcbAccelLatMax = reader.uint32();
                    break;
                case /* uint32 pcb_accel_lat_rms */ 4:
                    message.pcbAccelLatRms = reader.uint32();
                    break;
                case /* uint32 pcb_accel_vert_max */ 5:
                    message.pcbAccelVertMax = reader.uint32();
                    break;
                case /* uint32 pcb_accel_vert_rms */ 6:
                    message.pcbAccelVertRms = reader.uint32();
                    break;
                case /* uint32 pcb_accel_long_max */ 7:
                    message.pcbAccelLongMax = reader.uint32();
                    break;
                case /* uint32 pcb_accel_long_rms */ 8:
                    message.pcbAccelLongRms = reader.uint32();
                    break;
                case /* uint32 adxl_max */ 9:
                    message.adxlMax = reader.uint32();
                    break;
                case /* uint32 adxl_rms */ 10:
                    message.adxlRms = reader.uint32();
                    break;
                case /* sint32 bearing_temp */ 11:
                    message.bearingTemp = reader.sint32();
                    break;
                case /* uint32 battery_voltage_centivolts */ 12:
                    message.batteryVoltageCentivolts = reader.uint32();
                    break;
                case /* sint32 bearing_temperature_celsius */ 13:
                    message.bearingTemperatureCelsius = reader.sint32();
                    break;
                case /* uint32 zero_crossing_rate */ 14:
                    message.zeroCrossingRate = reader.uint32();
                    break;
                case /* repeated uint32 fft256_16bit = 15 [json_name = "fft25616bit"];*/ 15:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.fft25616Bit.push(reader.uint32());
                    else
                        message.fft25616Bit.push(reader.uint32());
                    break;
                case /* pb.v2.SensorInformation sensor_information */ 16:
                    message.sensorInformation = SensorInformation.internalBinaryRead(reader, reader.uint32(), options, message.sensorInformation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VibrationSensor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.id);
        /* uint64 epoch_seconds = 2; */
        if (message.epochSeconds !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.epochSeconds);
        /* uint32 pcb_accel_lat_max = 3; */
        if (message.pcbAccelLatMax !== 0)
            writer.tag(3, WireType.Varint).uint32(message.pcbAccelLatMax);
        /* uint32 pcb_accel_lat_rms = 4; */
        if (message.pcbAccelLatRms !== 0)
            writer.tag(4, WireType.Varint).uint32(message.pcbAccelLatRms);
        /* uint32 pcb_accel_vert_max = 5; */
        if (message.pcbAccelVertMax !== 0)
            writer.tag(5, WireType.Varint).uint32(message.pcbAccelVertMax);
        /* uint32 pcb_accel_vert_rms = 6; */
        if (message.pcbAccelVertRms !== 0)
            writer.tag(6, WireType.Varint).uint32(message.pcbAccelVertRms);
        /* uint32 pcb_accel_long_max = 7; */
        if (message.pcbAccelLongMax !== 0)
            writer.tag(7, WireType.Varint).uint32(message.pcbAccelLongMax);
        /* uint32 pcb_accel_long_rms = 8; */
        if (message.pcbAccelLongRms !== 0)
            writer.tag(8, WireType.Varint).uint32(message.pcbAccelLongRms);
        /* uint32 adxl_max = 9; */
        if (message.adxlMax !== 0)
            writer.tag(9, WireType.Varint).uint32(message.adxlMax);
        /* uint32 adxl_rms = 10; */
        if (message.adxlRms !== 0)
            writer.tag(10, WireType.Varint).uint32(message.adxlRms);
        /* sint32 bearing_temp = 11; */
        if (message.bearingTemp !== 0)
            writer.tag(11, WireType.Varint).sint32(message.bearingTemp);
        /* uint32 battery_voltage_centivolts = 12; */
        if (message.batteryVoltageCentivolts !== 0)
            writer.tag(12, WireType.Varint).uint32(message.batteryVoltageCentivolts);
        /* sint32 bearing_temperature_celsius = 13; */
        if (message.bearingTemperatureCelsius !== 0)
            writer.tag(13, WireType.Varint).sint32(message.bearingTemperatureCelsius);
        /* uint32 zero_crossing_rate = 14; */
        if (message.zeroCrossingRate !== 0)
            writer.tag(14, WireType.Varint).uint32(message.zeroCrossingRate);
        /* repeated uint32 fft256_16bit = 15 [json_name = "fft25616bit"]; */
        if (message.fft25616Bit.length) {
            writer.tag(15, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.fft25616Bit.length; i++)
                writer.uint32(message.fft25616Bit[i]);
            writer.join();
        }
        /* pb.v2.SensorInformation sensor_information = 16; */
        if (message.sensorInformation)
            SensorInformation.internalBinaryWrite(message.sensorInformation, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.VibrationSensor
 */
export const VibrationSensor = new VibrationSensor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MagneticSwitch$Type extends MessageType<MagneticSwitch> {
    constructor() {
        super("pb.v2.MagneticSwitch", [
            { no: 1, name: "id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "epoch_seconds", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "battery_voltage_centivolts", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "status", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "hall_sense", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "raw_mag", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 7, name: "sensor_information", kind: "message", T: () => SensorInformation }
        ]);
    }
    create(value?: PartialMessage<MagneticSwitch>): MagneticSwitch {
        const message = { id: 0n, epochSeconds: 0n, batteryVoltageCentivolts: 0, status: 0, hallSense: 0, rawMag: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MagneticSwitch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MagneticSwitch): MagneticSwitch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 id */ 1:
                    message.id = reader.uint64().toBigInt();
                    break;
                case /* uint64 epoch_seconds */ 2:
                    message.epochSeconds = reader.uint64().toBigInt();
                    break;
                case /* uint32 battery_voltage_centivolts */ 3:
                    message.batteryVoltageCentivolts = reader.uint32();
                    break;
                case /* uint32 status */ 4:
                    message.status = reader.uint32();
                    break;
                case /* uint32 hall_sense */ 5:
                    message.hallSense = reader.uint32();
                    break;
                case /* sint32 raw_mag */ 6:
                    message.rawMag = reader.sint32();
                    break;
                case /* pb.v2.SensorInformation sensor_information */ 7:
                    message.sensorInformation = SensorInformation.internalBinaryRead(reader, reader.uint32(), options, message.sensorInformation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MagneticSwitch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.id);
        /* uint64 epoch_seconds = 2; */
        if (message.epochSeconds !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.epochSeconds);
        /* uint32 battery_voltage_centivolts = 3; */
        if (message.batteryVoltageCentivolts !== 0)
            writer.tag(3, WireType.Varint).uint32(message.batteryVoltageCentivolts);
        /* uint32 status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).uint32(message.status);
        /* uint32 hall_sense = 5; */
        if (message.hallSense !== 0)
            writer.tag(5, WireType.Varint).uint32(message.hallSense);
        /* sint32 raw_mag = 6; */
        if (message.rawMag !== 0)
            writer.tag(6, WireType.Varint).sint32(message.rawMag);
        /* pb.v2.SensorInformation sensor_information = 7; */
        if (message.sensorInformation)
            SensorInformation.internalBinaryWrite(message.sensorInformation, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.MagneticSwitch
 */
export const MagneticSwitch = new MagneticSwitch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StrainSensor$Type extends MessageType<StrainSensor> {
    constructor() {
        super("pb.v2.StrainSensor", [
            { no: 1, name: "id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "epoch_seconds", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "battery_voltage_centivolts", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "raw_strain", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "raw_temperature", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "raw_strain_delta_per_minute", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "sensor_information", kind: "message", T: () => SensorInformation }
        ]);
    }
    create(value?: PartialMessage<StrainSensor>): StrainSensor {
        const message = { id: 0n, epochSeconds: 0n, batteryVoltageCentivolts: 0, rawStrain: 0, rawTemperature: 0, rawStrainDeltaPerMinute: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StrainSensor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StrainSensor): StrainSensor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 id */ 1:
                    message.id = reader.uint64().toBigInt();
                    break;
                case /* uint64 epoch_seconds */ 2:
                    message.epochSeconds = reader.uint64().toBigInt();
                    break;
                case /* uint32 battery_voltage_centivolts */ 3:
                    message.batteryVoltageCentivolts = reader.uint32();
                    break;
                case /* uint32 raw_strain */ 4:
                    message.rawStrain = reader.uint32();
                    break;
                case /* uint32 raw_temperature */ 5:
                    message.rawTemperature = reader.uint32();
                    break;
                case /* uint32 raw_strain_delta_per_minute */ 6:
                    message.rawStrainDeltaPerMinute = reader.uint32();
                    break;
                case /* pb.v2.SensorInformation sensor_information */ 7:
                    message.sensorInformation = SensorInformation.internalBinaryRead(reader, reader.uint32(), options, message.sensorInformation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StrainSensor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.id);
        /* uint64 epoch_seconds = 2; */
        if (message.epochSeconds !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.epochSeconds);
        /* uint32 battery_voltage_centivolts = 3; */
        if (message.batteryVoltageCentivolts !== 0)
            writer.tag(3, WireType.Varint).uint32(message.batteryVoltageCentivolts);
        /* uint32 raw_strain = 4; */
        if (message.rawStrain !== 0)
            writer.tag(4, WireType.Varint).uint32(message.rawStrain);
        /* uint32 raw_temperature = 5; */
        if (message.rawTemperature !== 0)
            writer.tag(5, WireType.Varint).uint32(message.rawTemperature);
        /* uint32 raw_strain_delta_per_minute = 6; */
        if (message.rawStrainDeltaPerMinute !== 0)
            writer.tag(6, WireType.Varint).uint32(message.rawStrainDeltaPerMinute);
        /* pb.v2.SensorInformation sensor_information = 7; */
        if (message.sensorInformation)
            SensorInformation.internalBinaryWrite(message.sensorInformation, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.StrainSensor
 */
export const StrainSensor = new StrainSensor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndustrialData$Type extends MessageType<IndustrialData> {
    constructor() {
        super("pb.v2.IndustrialData", [
            { no: 11, name: "vibration", kind: "message", T: () => VibrationSensor },
            { no: 12, name: "valve", kind: "message", T: () => MagneticSwitch },
            { no: 13, name: "strain", kind: "message", T: () => StrainSensor }
        ]);
    }
    create(value?: PartialMessage<IndustrialData>): IndustrialData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IndustrialData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IndustrialData): IndustrialData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.VibrationSensor vibration */ 11:
                    message.vibration = VibrationSensor.internalBinaryRead(reader, reader.uint32(), options, message.vibration);
                    break;
                case /* pb.v2.MagneticSwitch valve */ 12:
                    message.valve = MagneticSwitch.internalBinaryRead(reader, reader.uint32(), options, message.valve);
                    break;
                case /* pb.v2.StrainSensor strain */ 13:
                    message.strain = StrainSensor.internalBinaryRead(reader, reader.uint32(), options, message.strain);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IndustrialData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.VibrationSensor vibration = 11; */
        if (message.vibration)
            VibrationSensor.internalBinaryWrite(message.vibration, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.MagneticSwitch valve = 12; */
        if (message.valve)
            MagneticSwitch.internalBinaryWrite(message.valve, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.StrainSensor strain = 13; */
        if (message.strain)
            StrainSensor.internalBinaryWrite(message.strain, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.IndustrialData
 */
export const IndustrialData = new IndustrialData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VendorData$Type extends MessageType<VendorData> {
    constructor() {
        super("pb.v2.VendorData", [
            { no: 1, name: "msg_pack", kind: "message", oneof: "data", T: () => MessagePack },
            { no: 2, name: "generic", kind: "message", oneof: "data", T: () => generic_data },
            { no: 3, name: "rf", kind: "message", oneof: "data", T: () => RfCollections },
            { no: 4, name: "industrial", kind: "message", oneof: "data", T: () => IndustrialData },
            { no: 5, name: "radation_data", kind: "message", oneof: "data", T: () => RadationData },
            { no: 13, name: "events", kind: "message", oneof: "data", T: () => EventLog },
            { no: 14, name: "raw", kind: "message", oneof: "data", T: () => RawData },
            { no: 15, name: "custom_data", kind: "message", oneof: "data", T: () => Any }
        ]);
    }
    create(value?: PartialMessage<VendorData>): VendorData {
        const message = { data: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VendorData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VendorData): VendorData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.MessagePack msg_pack */ 1:
                    message.data = {
                        oneofKind: "msgPack",
                        msgPack: MessagePack.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).msgPack)
                    };
                    break;
                case /* pb.v2.generic_data generic */ 2:
                    message.data = {
                        oneofKind: "generic",
                        generic: generic_data.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).generic)
                    };
                    break;
                case /* pb.v2.RfCollections rf */ 3:
                    message.data = {
                        oneofKind: "rf",
                        rf: RfCollections.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).rf)
                    };
                    break;
                case /* pb.v2.IndustrialData industrial */ 4:
                    message.data = {
                        oneofKind: "industrial",
                        industrial: IndustrialData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).industrial)
                    };
                    break;
                case /* pb.v2.RadationData radation_data */ 5:
                    message.data = {
                        oneofKind: "radationData",
                        radationData: RadationData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).radationData)
                    };
                    break;
                case /* pb.v2.EventLog events */ 13:
                    message.data = {
                        oneofKind: "events",
                        events: EventLog.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).events)
                    };
                    break;
                case /* pb.v2.RawData raw */ 14:
                    message.data = {
                        oneofKind: "raw",
                        raw: RawData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).raw)
                    };
                    break;
                case /* pb.v2.Any custom_data */ 15:
                    message.data = {
                        oneofKind: "customData",
                        customData: Any.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).customData)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VendorData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.MessagePack msg_pack = 1; */
        if (message.data.oneofKind === "msgPack")
            MessagePack.internalBinaryWrite(message.data.msgPack, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.generic_data generic = 2; */
        if (message.data.oneofKind === "generic")
            generic_data.internalBinaryWrite(message.data.generic, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.RfCollections rf = 3; */
        if (message.data.oneofKind === "rf")
            RfCollections.internalBinaryWrite(message.data.rf, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.IndustrialData industrial = 4; */
        if (message.data.oneofKind === "industrial")
            IndustrialData.internalBinaryWrite(message.data.industrial, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.RadationData radation_data = 5; */
        if (message.data.oneofKind === "radationData")
            RadationData.internalBinaryWrite(message.data.radationData, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.EventLog events = 13; */
        if (message.data.oneofKind === "events")
            EventLog.internalBinaryWrite(message.data.events, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.RawData raw = 14; */
        if (message.data.oneofKind === "raw")
            RawData.internalBinaryWrite(message.data.raw, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Any custom_data = 15; */
        if (message.data.oneofKind === "customData")
            Any.internalBinaryWrite(message.data.customData, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.VendorData
 */
export const VendorData = new VendorData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventLog$Type extends MessageType<EventLog> {
    constructor() {
        super("pb.v2.EventLog", [
            { no: 1, name: "version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "events", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/, options: { nanopb: { maxCount: 128 } } }
        ]);
    }
    create(value?: PartialMessage<EventLog>): EventLog {
        const message = { version: 0, count: 0, events: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventLog): EventLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 version */ 1:
                    message.version = reader.uint32();
                    break;
                case /* uint32 count */ 2:
                    message.count = reader.uint32();
                    break;
                case /* repeated uint32 events */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.events.push(reader.uint32());
                    else
                        message.events.push(reader.uint32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 version = 1; */
        if (message.version !== 0)
            writer.tag(1, WireType.Varint).uint32(message.version);
        /* uint32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).uint32(message.count);
        /* repeated uint32 events = 3; */
        if (message.events.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.events.length; i++)
                writer.uint32(message.events[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.EventLog
 */
export const EventLog = new EventLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RawData$Type extends MessageType<RawData> {
    constructor() {
        super("pb.v2.RawData", [
            { no: 1, name: "id_record", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "total_segments", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "crc32", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<RawData>): RawData {
        const message = { idRecord: 0, type: 0, totalSegments: 0, crc32: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RawData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RawData): RawData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 id_record */ 1:
                    message.idRecord = reader.uint32();
                    break;
                case /* uint32 type */ 3:
                    message.type = reader.uint32();
                    break;
                case /* uint32 total_segments */ 4:
                    message.totalSegments = reader.uint32();
                    break;
                case /* uint32 crc32 */ 5:
                    message.crc32 = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RawData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 id_record = 1; */
        if (message.idRecord !== 0)
            writer.tag(1, WireType.Varint).uint32(message.idRecord);
        /* uint32 type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).uint32(message.type);
        /* uint32 total_segments = 4; */
        if (message.totalSegments !== 0)
            writer.tag(4, WireType.Varint).uint32(message.totalSegments);
        /* uint32 crc32 = 5; */
        if (message.crc32 !== 0)
            writer.tag(5, WireType.Varint).uint32(message.crc32);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.RawData
 */
export const RawData = new RawData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RawDataSegment$Type extends MessageType<RawDataSegment> {
    constructor() {
        super("pb.v2.RawDataSegment", [
            { no: 1, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "segment", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 512 } } }
        ]);
    }
    create(value?: PartialMessage<RawDataSegment>): RawDataSegment {
        const message = { uuid: 0n, id: 0, segment: 0, data: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RawDataSegment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RawDataSegment): RawDataSegment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 uuid */ 1:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                case /* uint32 id */ 2:
                    message.id = reader.uint32();
                    break;
                case /* uint32 segment */ 3:
                    message.segment = reader.uint32();
                    break;
                case /* bytes data */ 4:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RawDataSegment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 uuid = 1; */
        if (message.uuid !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.uuid);
        /* uint32 id = 2; */
        if (message.id !== 0)
            writer.tag(2, WireType.Varint).uint32(message.id);
        /* uint32 segment = 3; */
        if (message.segment !== 0)
            writer.tag(3, WireType.Varint).uint32(message.segment);
        /* bytes data = 4; */
        if (message.data.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.RawDataSegment
 */
export const RawDataSegment = new RawDataSegment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Message_Settings$Type extends MessageType<Message_Settings> {
    constructor() {
        super("pb.v2.Message_Settings", [
            { no: 1, name: "message_flag", kind: "enum", T: () => ["pb.v2.MessageFlags", MessageFlags] },
            { no: 2, name: "retain_min_count_telemetry", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "retain_min_days_telemetry", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Message_Settings>): Message_Settings {
        const message = { messageFlag: 0, retainMinCountTelemetry: 0, retainMinDaysTelemetry: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Message_Settings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Message_Settings): Message_Settings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.MessageFlags message_flag */ 1:
                    message.messageFlag = reader.int32();
                    break;
                case /* uint32 retain_min_count_telemetry */ 2:
                    message.retainMinCountTelemetry = reader.uint32();
                    break;
                case /* uint32 retain_min_days_telemetry */ 3:
                    message.retainMinDaysTelemetry = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Message_Settings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.MessageFlags message_flag = 1; */
        if (message.messageFlag !== 0)
            writer.tag(1, WireType.Varint).int32(message.messageFlag);
        /* uint32 retain_min_count_telemetry = 2; */
        if (message.retainMinCountTelemetry !== 0)
            writer.tag(2, WireType.Varint).uint32(message.retainMinCountTelemetry);
        /* uint32 retain_min_days_telemetry = 3; */
        if (message.retainMinDaysTelemetry !== 0)
            writer.tag(3, WireType.Varint).uint32(message.retainMinDaysTelemetry);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Message_Settings
 */
export const Message_Settings = new Message_Settings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Telemetry$Type extends MessageType<Telemetry> {
    constructor() {
        super("pb.v2.Telemetry", [
            { no: 1, name: "message_counter", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "sensor_type_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "epoch_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "latitude_1e7", kind: "scalar", jsonName: "latitude1e7", T: 15 /*ScalarType.SFIXED32*/ },
            { no: 6, name: "longitude_1e7", kind: "scalar", jsonName: "longitude1e7", T: 15 /*ScalarType.SFIXED32*/ },
            { no: 7, name: "elevation_cm", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 8, name: "gps_scan_record", kind: "message", T: () => GPSScanRecord },
            { no: 9, name: "system", kind: "message", T: () => SystemState },
            { no: 10, name: "motion_state", kind: "enum", T: () => ["pb.v2.MotionState", MotionState, "MOTION_STATE_"] },
            { no: 11, name: "environment", kind: "message", T: () => Environment },
            { no: 12, name: "serial", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 13, name: "settings", kind: "message", T: () => Message_Settings },
            { no: 14, name: "vendor_data", kind: "message", T: () => VendorData },
            { no: 15, name: "key_values", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KeyValue }
        ]);
    }
    create(value?: PartialMessage<Telemetry>): Telemetry {
        const message = { messageCounter: 0n, uuid: 0n, sensorTypeId: 0, epochMs: 0n, latitude1E7: 0, longitude1E7: 0, elevationCm: 0, motionState: 0, serial: 0n, keyValues: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Telemetry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Telemetry): Telemetry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 message_counter */ 1:
                    message.messageCounter = reader.uint64().toBigInt();
                    break;
                case /* uint64 uuid */ 2:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                case /* uint32 sensor_type_id */ 3:
                    message.sensorTypeId = reader.uint32();
                    break;
                case /* uint64 epoch_ms */ 4:
                    message.epochMs = reader.uint64().toBigInt();
                    break;
                case /* sfixed32 latitude_1e7 = 5 [json_name = "latitude1e7"];*/ 5:
                    message.latitude1E7 = reader.sfixed32();
                    break;
                case /* sfixed32 longitude_1e7 = 6 [json_name = "longitude1e7"];*/ 6:
                    message.longitude1E7 = reader.sfixed32();
                    break;
                case /* sint32 elevation_cm */ 7:
                    message.elevationCm = reader.sint32();
                    break;
                case /* pb.v2.GPSScanRecord gps_scan_record */ 8:
                    message.gpsScanRecord = GPSScanRecord.internalBinaryRead(reader, reader.uint32(), options, message.gpsScanRecord);
                    break;
                case /* pb.v2.SystemState system */ 9:
                    message.system = SystemState.internalBinaryRead(reader, reader.uint32(), options, message.system);
                    break;
                case /* pb.v2.MotionState motion_state */ 10:
                    message.motionState = reader.int32();
                    break;
                case /* pb.v2.Environment environment */ 11:
                    message.environment = Environment.internalBinaryRead(reader, reader.uint32(), options, message.environment);
                    break;
                case /* uint64 serial */ 12:
                    message.serial = reader.uint64().toBigInt();
                    break;
                case /* pb.v2.Message_Settings settings */ 13:
                    message.settings = Message_Settings.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                case /* pb.v2.VendorData vendor_data */ 14:
                    message.vendorData = VendorData.internalBinaryRead(reader, reader.uint32(), options, message.vendorData);
                    break;
                case /* repeated pb.v2.KeyValue key_values */ 15:
                    message.keyValues.push(KeyValue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Telemetry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 message_counter = 1; */
        if (message.messageCounter !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.messageCounter);
        /* uint64 uuid = 2; */
        if (message.uuid !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.uuid);
        /* uint32 sensor_type_id = 3; */
        if (message.sensorTypeId !== 0)
            writer.tag(3, WireType.Varint).uint32(message.sensorTypeId);
        /* uint64 epoch_ms = 4; */
        if (message.epochMs !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.epochMs);
        /* sfixed32 latitude_1e7 = 5 [json_name = "latitude1e7"]; */
        if (message.latitude1E7 !== 0)
            writer.tag(5, WireType.Bit32).sfixed32(message.latitude1E7);
        /* sfixed32 longitude_1e7 = 6 [json_name = "longitude1e7"]; */
        if (message.longitude1E7 !== 0)
            writer.tag(6, WireType.Bit32).sfixed32(message.longitude1E7);
        /* sint32 elevation_cm = 7; */
        if (message.elevationCm !== 0)
            writer.tag(7, WireType.Varint).sint32(message.elevationCm);
        /* pb.v2.GPSScanRecord gps_scan_record = 8; */
        if (message.gpsScanRecord)
            GPSScanRecord.internalBinaryWrite(message.gpsScanRecord, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.SystemState system = 9; */
        if (message.system)
            SystemState.internalBinaryWrite(message.system, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.MotionState motion_state = 10; */
        if (message.motionState !== 0)
            writer.tag(10, WireType.Varint).int32(message.motionState);
        /* pb.v2.Environment environment = 11; */
        if (message.environment)
            Environment.internalBinaryWrite(message.environment, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* uint64 serial = 12; */
        if (message.serial !== 0n)
            writer.tag(12, WireType.Varint).uint64(message.serial);
        /* pb.v2.Message_Settings settings = 13; */
        if (message.settings)
            Message_Settings.internalBinaryWrite(message.settings, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.VendorData vendor_data = 14; */
        if (message.vendorData)
            VendorData.internalBinaryWrite(message.vendorData, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.KeyValue key_values = 15; */
        for (let i = 0; i < message.keyValues.length; i++)
            KeyValue.internalBinaryWrite(message.keyValues[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Telemetry
 */
export const Telemetry = new Telemetry$Type();
