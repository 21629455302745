import { AllServcies } from '../startup/startup.service';

import { ModelType } from '../../generated_proto/protobuf-ts/pb/v2/models';
import { FirmwareFileViewModelImplemented } from '../../viewmodels/FirmwareFile.vmi';
import { AssetViewModelImplemented } from '../../viewmodels/Asset.vmi';
import { LoggerOptions } from '../logger/logger.service';


export class AssetManagementPouchdbService {

	public loggerOptions:LoggerOptions = {
		prefix:"AssetManagementPouchdbService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	constructor(
	)
	{

	}

	public init(allServices:AllServcies) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {

			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
			}
			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getAssetList( app_id:number ) : Promise<AssetViewModelImplemented[]> {
		var firmwareList:AssetViewModelImplemented[] = [];
		var searchOptions = {
			include_docs: true,
			attachments: false,
		}
		return new Promise(async (resolve, reject) => {
			if(!this.services.data || !this.services.pouch){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.ASSET_MODEL);
			db.remoteDb.allDocs(searchOptions)
			.then( (result:any) => {
				if(result.rows.length>0){
					for (let index = 0; index < result.rows.length; index++) {
						const row = result.rows[index];
						const doc = row.doc;
						if(doc._id[0] == "_" ){
							continue;
						}
						var assetViewModel:AssetViewModelImplemented = new AssetViewModelImplemented(this.services);
						assetViewModel.updateLocalFromDb(doc);
						firmwareList.push(assetViewModel);
					}
					// sort firmware list
					firmwareList = firmwareList.sort( (a,b) => {
						if(a.model?.db?.updatedMs &&  b.model?.db?.updatedMs){
							return Number(b.model?.db?.updatedMs) - Number(a.model?.db?.updatedMs);
						}
						else {
							return 0;
						}
					})
					resolve(firmwareList);
				}
				else {
					resolve([]);
				}
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

	public saveAsset( app_id:number, assetVMI:AssetViewModelImplemented ) : Promise<boolean> {
		return new Promise( (resolve,reject) => {
			assetVMI.updateDb();

			var full_doc = Object.assign({}, assetVMI.modelToJson());
			full_doc["_id"] = assetVMI.generateDbKey();

			if(!this.services.pouch){
				if(this.services.logger) this.services.logger.error(this.loggerOptions,"Pouch not set");
				reject(this.loggerOptions.prefix+"Pouch not set");
				return;
			}
			if(! this.services.data){
				reject("Data Service not set");
				return
			}
			console.log("saveAsset:DB URL ", this.services.data.getDbPrefix(app_id)+ModelType.ASSET_MODEL);
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.ASSET_MODEL);
			var dbConnection;
			if(this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY){
				dbConnection = db.localDb;
			}
			else {
				dbConnection = db.remoteDb;
			}
			dbConnection.get(assetVMI.generateDbKey())
			.then( (doc:any) => {
				console.log("saveAsset:Doc exists");
				doc = Object.assign(doc, full_doc);
				// Onlytime this is updated after a delete would be from the uRPC or other service
				doc._deleted = false;
				if(!this.services.pouch){
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"Pouch not set");
					reject(this.loggerOptions.prefix+"Pouch not set");
					return;
				}
				console.log("saveAsset:updating doc with put");
				return dbConnection.put(doc)
			})
			.then( (result:any) => {
				resolve(true);
			}).catch( (err) => {
				if(this.services.logger) this.services.logger.warn(this.loggerOptions,"Not Found");
				if(err.status != 404){
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"Error", err);
					reject(err);
					return;
				}
				if(this.services.pouch){
					if(this.services.logger) this.services.logger.warn(this.loggerOptions,"Creating New Sensor");
					dbConnection.put(full_doc).then( (result:any) => {
						resolve(true);
					}).catch( (err) => {
						reject(err);
					});
				}
				else {
					if(this.services.logger) this.services.logger.error(this.loggerOptions,"Pouch not set");
					reject(this.loggerOptions.prefix+"Pouch not set");
				}
			});
		});
	}


}
