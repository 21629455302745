import { Injectable } from '@angular/core';
import { AllServcies } from '../startup/startup.service';

import { BehaviorSubject } from "rxjs";
import { ChannelViewModelImplemented } from '../../viewmodels/channel.vmi';

import { MenuController } from "@ionic/angular";

export interface MenuPage {
	title?:string,
	url?:string,
	icon?:string,
	active?:boolean,
	func?:() => void,
}

@Injectable({
	providedIn: 'root'
})
export class MenuService {

	public update = new BehaviorSubject(false); ;
	public channel_vmi : ChannelViewModelImplemented;

	public appPages:MenuPage[] = [];
	public appManagementPages:MenuPage[] = [];
	public deviceManagementPages:MenuPage[] = [];

	public footerPages:MenuPage[] = [];
	public betaPages:MenuPage[] = [];

	constructor(
		private menu: MenuController
	)
	{
		
	}

	init(allServices:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			this.setServices(allServices);
			if(allServices && allServices.proto){
				this.channel_vmi = allServices.proto.getChannelViewModel();
			}
			this.menu.enable(true);
			resolve(true);
		});
		
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	getPages() : MenuPage[]{
		// this.appPages.push({
		// 	title: 'Session',
		// 	url: 'protected/channel',
		// 	icon: 'people'
		// });
		this.appPages = [];
		if(this.services.settings?.SETTINGS.APP_IS_PROTOTYPE == true){
			return this.appPages;
		}

		if(this.services && this.services.auth && this.services.settings){


			if(this.services.settings.SETTINGS.APP_USE_CHANNELS){
				if(this.services.auth.hasAuth){

					if(this.services.settings.SETTINGS.APP_SHOW_APP_CHANNLES){
						this.appPages.push({
							title: this.services.settings.SETTINGS.APP_CHANNEL_ALIAS || "Channel",
							url: 'protected/channel',
							icon: 'people'
						});
					}
		
					// this.appPages.push({
					// 	title: 'Map',
					// 	url: 'protected/map',
					// 	icon: 'globe'
					// });
				}
				if(this.services.auth.hasAuth && this.channel_vmi.joined){
					// this.appPages.push({
					// 	title: 'People',
					// 	url: 'protected/people',
					// 	icon: 'people'
					// });
	
					this.appPages.push({
						title: 'Chatroom',
						url: 'protected/chatroom',
						icon: 'chatbubbles'
					});
				}
				if(this.services.settings?.SETTINGS.APP_SUPPORT_ASSETS){
					this.appPages.push({
						title: 'Assets',
						url: 'protected/assets',
						icon: 'locate'
					});
				}
				if(this.services.settings?.SETTINGS.APP_SHOW_BUILDINGS){
					this.appPages.push({
						title: 'Sites',
						url: 'protected/sites',
						icon: 'business'
					});
				}
			}
		}

		return this.appPages;
	}

	getAppManagementPages() : MenuPage[]{

		this.appManagementPages = [];
		if(this.services.settings?.SETTINGS.APP_IS_PROTOTYPE == true){
			return this.appManagementPages;
		}

		if(this.services.settings?.SETTINGS.APP_SHOW_APP_MANAGMENT){
			if(this.services.auth && this.services.auth.hasAuth){
				this.appManagementPages.push({
					title: 'Files',
					url: 'app-admin/files',
					icon: 'folder-outline'
				});
			}
		}

		return this.appManagementPages;
	}

	getDeviceManagementPages() : MenuPage[]{

		this.deviceManagementPages = [];
		if(this.services.settings?.SETTINGS.APP_IS_PROTOTYPE == true){
			this.deviceManagementPages.push({
				title:"Device Table",
				icon:"list-outline",
				url:"protected/devices-table"
			})
			return this.deviceManagementPages;
		}

		if(this.services.auth && this.services.auth.hasAuth){
			if(this.services.settings?.SETTINGS.APP_SHOW_DASHBOARD == true){
				this.deviceManagementPages.push({
					title: 'Dashboard',
					url: 'protected/dashboard',
					icon: 'grid-outline'
				})
			}
			if(this.services.settings?.SETTINGS.APP_SHOW_MAP == true){
				this.deviceManagementPages.push({
					title: 'Map',
					url: 'protected/map',
					icon: 'map'
				})
			}
			if(this.services.settings?.SETTINGS.APP_SHOW_DEVICES == true){
				this.deviceManagementPages.push({
					title:"Device Table",
					icon:"list-outline",
					url:"protected/devices-table"
				})
				this.deviceManagementPages.push({
					title: 'Devices',
					url: 'protected/devices',
					icon: 'hardware-chip-outline'
				});
			}
			if(this.services.settings?.SETTINGS.APP_SHOW_ADD_DEVICES == true){
				this.deviceManagementPages.push({
					title: 'Add Device',
					url: 'protected/devices/device-add',
					icon: 'add-circle-outline'
				});
			}
			if(this.services.settings?.SETTINGS.APP_SHOW_DEVICE_MANAGMENT){
				if(this.services.settings?.SETTINGS.APP_SHOW_DEVICE_FIRMWARE){
					this.deviceManagementPages.push({
						title: 'Firmware',
						url: 'device-management/firmware',
						icon: 'code-download'
					});
				}

				if(this.services.settings?.SETTINGS.APP_SHOW_DEVICE_GROUP){
					this.deviceManagementPages.push({
						title: 'Device Group',
						url: 'device-management/device-group',
						icon: 'cube'
					});
				}
				// this.deviceManagementPages.push({
				// 	title: 'Fleet Status',
				// 	url: 'device-management/fleet-management',
				// 	icon: 'stats-chart-outline'
				// });
				if(this.services.settings?.SETTINGS.RUN_MODE == "LOCAL"){
					// this.deviceManagementPages.push({
					// 	title: 'SandBox',
					// 	url: 'protected/sandbox',
					// 	icon: 'cube-outline'
					// });
				}
			}
		}
		return this.deviceManagementPages;
	}

	getVendorSections() : {sectionTitle:string, pages:MenuPage[]}[] {
		var vendorSections: {sectionTitle:string, pages:MenuPage[]}[] = [];
		if(this.services.settings){
			if(this.services.settings.SETTINGS.APP_PL_SHOW_MIGRATION == true){
				var pages:MenuPage[] = [];
				pages.push({
					title: 'Station Status',
					icon: 'qr-code-outline',
					url: 'vendors/pl/station-shipment-status',
					func: () => {
						if(this.services.auth && this.services.router){
							this.services.router.navigate(['tools/tools-menu']);
						}
					},
				})
				vendorSections.push({sectionTitle:"Migration", pages:pages});
			}
		}
		return vendorSections;
	}

	getFooterPages() : MenuPage[] {
		this.footerPages = [];

		if(this.services.settings){
			if(!this.services.settings.SETTINGS.APP_HIDE_TOOLS){
				this.footerPages.push({
					title: 'Tools',
					icon: 'settings-outline',
					url: 'tools/tools-menu',
					func: () => {
						if(this.services.auth && this.services.router){
							this.services.router.navigate(['tools/tools-menu']);
						}
					},
				})
			}
		}

		if(this.services.settings?.SETTINGS.APP_FORCE_SIGN_IN_AUTH){
			this.footerPages.push({
				title: 'Logout',
				icon: 'log-out',
				url: '#',
				func: () => {
					if(this.services.auth && this.services.router){
						this.services.auth.logout();
						this.services.router.navigate(['login']);
					}
					this.update.next(true);
				},
			})
		}


		return this.footerPages;
	}

	getBetaPages() : MenuPage[] {
		if(this.services.auth && this.services.auth.hasAuth){
			if(this.services.settings?.SETTINGS.APP_SHOW_BETA_PAGES){
				if(this.services.settings?.SETTINGS.APP_IS_PROTOTYPE == true){
					this.betaPages.push({
						title: 'SandBox',
						url: 'protected/sandbox',
						icon: 'cube-outline'
					},{
						title: 'Test-Components',
						url: 'component-test',
						icon: 'cube-outline'
					});
				}
				else {
					this.betaPages.push({
						title:"Test Runner",
						icon:"checkmark-done-outline",
						url:"test-runner/test-runner-testbed"
					})
				}
			}
			return this.betaPages;
		}
		return [];
	}
}
