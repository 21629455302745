import { Component, } from '@angular/core';
import { AllServcies } from '../../services/startup/startup.service';

@Component({
	selector: 'app-fullstack',
	templateUrl: './fullstack.component.html',
	styleUrls: ['./fullstack.component.css'],
})
export class FullstackComponent {

	public allServices: AllServcies;

	constructor() { }

	public init(services:AllServcies, example:any): Promise<any> {
		return new Promise((resolve, reject) => {
			// do stuff to init your thing
			this.allServices = services;
			resolve(example);
		});
	}

	public test(): Promise<any> {
		return new Promise((resolve, reject) => {
			// everything to test your component that we can use as a top level tester
			resolve(true);
		});
	}

}
