import { Component, } from '@angular/core';

import { AllServcies } from '../../services/startup/startup.service';
import { FullstackComponent } from '../fullstack-component/fullstack.component';

@Component({
	selector: 'app-test-runner-template',
	templateUrl: './test-runner-template.component.html',
	styleUrls: ['./test-runner-template.component.css'],
})
export class TestRunnerTemplateComponent implements FullstackComponent {

	public allServices: AllServcies;
	public showSomething = "nothing";

	constructor() { }

	public init(services:AllServcies, example:any): Promise<any> {
		return new Promise((resolve, reject) => {
			// do stuff to init your thing
			this.allServices = services;
			resolve(example);
		});
	}

	public test(): Promise<any> {
		return new Promise((resolve, reject) => {

			// refer to the steps in the builder we talked about
			// 1. create a channel
			this.showSomething = "test complete, use this component as an example";
		
			resolve("Test is done? Show test results?");
		});
	}
}
