// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/system.proto" (syntax proto3)
// tslint:disable
/**
 * @generated from protobuf enum AppIds
 */
export enum AppIds {
    /**
     * @generated from protobuf enum value: APP_IDS_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: APP_IDS_APP = 2;
     */
    APP = 2,
    /**
     * @generated from protobuf enum value: APP_IDS_APP = 2;
     */
    BETA = 2,
    /**
     * @generated from protobuf enum value: APP_IDS_DEV = 6;
     */
    DEV = 6,
    /**
     * @generated from protobuf enum value: APP_IDS_PL = 8;
     */
    PL = 8,
    /**
     * @generated from protobuf enum value: APP_IDS_HUM = 10;
     */
    HUM = 10,
    /**
     * @generated from protobuf enum value: APP_IDS_EQ = 12;
     */
    EQ = 12,
    /**
     * @generated from protobuf enum value: APP_IDS_STC = 14;
     */
    STC = 14,
    /**
     * @generated from protobuf enum value: APP_IDS_STE = 16;
     */
    STE = 16,
    /**
     * @generated from protobuf enum value: APP_IDS_COMMS = 18;
     */
    COMMS = 18,
    /**
     * @generated from protobuf enum value: APP_IDS_RL = 20;
     */
    RL = 20,
    /**
     * @generated from protobuf enum value: APP_IDS_AAPL = 22;
     */
    AAPL = 22,
    /**
     * @generated from protobuf enum value: APP_IDS_NORT = 24;
     */
    NORT = 24,
    /**
     * @generated from protobuf enum value: APP_IDS_WEBC = 26;
     */
    WEBC = 26
}
// we need some way of generic storage into the db, but when pulling
// back out, we need to know what type to CAST that data to as a SUB vmi class type.enum
// IE: ALL are DeviceVMI
// -> but if a spcific id, its that sub type vmi, like HumVMI
// -> this is also figured out when doing the initial data collection
//     -> IE: Bluetooth advertisment type, COM port characteristics, etc

// THESE MUST BE UNIQUE > MAPS TO EACH VMI && stored in Device.deviceType

// To ADD a new device type, you must:
// - Set the project type in the DeviceTypeIds enum, IE: GENERIC
// - Add the new device type to the DeviceTypeIds enum, IE: GENERIC_NEW_DEVICE
// - Further paths will define group options, IE: GENERIC_NEW_DEVICE_V1, GENERIC_NEW_DEVICE_V2, etc.
//   from UI can be selected with GENERIC_NEW_DEVICE will return all GENERIC_NEW_DEVICE_V1, GENERIC_NEW_DEVICE_V2, etc.

/**
 * NOTE :: THESE ARE NOT IN ORDER, BUT MUST BE UNIQUE. LOOK AND UPDATE BOTTOM NEXT IF ADDING NEW TYPE
 *
 * @generated from protobuf enum DeviceTypeIds
 */
export enum DeviceTypeIds {
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_GENERIC = 1;
     */
    GENERIC = 1,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_GENERIC_DEVICE = 2;
     */
    GENERIC_DEVICE = 2,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_GENERIC_SIMULATED = 3;
     */
    GENERIC_SIMULATED = 3,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_GENERIC_GATEWAY = 4;
     */
    GENERIC_GATEWAY = 4,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_GENERIC_RADITATION = 5;
     */
    GENERIC_RADITATION = 5,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_GENERIC_BLUETOOTH_BEACON = 6;
     */
    GENERIC_BLUETOOTH_BEACON = 6,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_EQ = 17;
     */
    EQ = 17,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_STE = 18;
     */
    STE = 18,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_STE_PAGER_S = 19;
     */
    STE_PAGER_S = 19,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_STE_RADPACK = 20;
     */
    STE_RADPACK = 20,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_STE_DEV_KIT = 21;
     */
    STE_DEV_KIT = 21,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_STE_LRM = 22;
     */
    STE_LRM = 22,
    /**
     * New nrf platform
     *
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_STE_NRF = 39;
     */
    STE_NRF = 39,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL = 40;
     */
    PL = 40,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL_BLUETOOTH_BEACON = 23;
     */
    PL_BLUETOOTH_BEACON = 23,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL_BLUETOOTH_BEACON_V1 = 24;
     */
    PL_BLUETOOTH_BEACON_V1 = 24,
    /**
     * First run of Audio. Only adverts "PL" and has NO FindMy or Eddystone
     *
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL_BLUETOOTH_BEACON_V2 = 25;
     */
    PL_BLUETOOTH_BEACON_V2 = 25,
    /**
     * Main run of Audio. Adverts PlXxXx (last four of mac). Has FindMy and Eddystone.
     *
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL_BLUETOOTH_BEACON_V2_1 = 47;
     */
    PL_BLUETOOTH_BEACON_V2_1 = 47,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL_STATION = 26;
     */
    PL_STATION = 26,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL_STATION_DEV = 27;
     */
    PL_STATION_DEV = 27,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL_STATION_V1 = 28;
     */
    PL_STATION_V1 = 28,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_PL_STATION_V2 = 29;
     */
    PL_STATION_V2 = 29,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_RL = 41;
     */
    RL = 41,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_RL_BODY_WORN = 30;
     */
    RL_BODY_WORN = 30,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_RL_BODY_WORN_SMALL = 42;
     */
    RL_BODY_WORN_SMALL = 42,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_RL_BODY_WORN_LARGE = 43;
     */
    RL_BODY_WORN_LARGE = 43,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_RL_PORTABLE_GATEWAY = 31;
     */
    RL_PORTABLE_GATEWAY = 31,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_RL_FIXED_GATEWAY = 32;
     */
    RL_FIXED_GATEWAY = 32,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_RL_FIXED_GATEWAY_WIND = 44;
     */
    RL_FIXED_GATEWAY_WIND = 44,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_HUM = 33;
     */
    HUM = 33,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_HUM_GATEWAY = 34;
     */
    HUM_GATEWAY = 34,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_HUM_SENSOR = 35;
     */
    HUM_SENSOR = 35,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_HUM_ATLAS = 36;
     */
    HUM_ATLAS = 36,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_HUM_VIBRATION = 37;
     */
    HUM_VIBRATION = 37,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_HUM_MAGNETIC = 38;
     */
    HUM_MAGNETIC = 38,
    /**
     * SL
     *
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_SL = 45;
     */
    SL = 45,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_SL_MULTI_COMMS = 46;
     */
    SL_MULTI_COMMS = 46,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_MIRION = 123;
     */
    MIRION = 123,
    /**
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_MIRION_ACCURAD = 124;
     */
    MIRION_ACCURAD = 124,
    /**
     * Increment since the above are not ordered
     *
     * @generated from protobuf enum value: DEVICE_TYPE_IDS_NEXT = 125;
     */
    NEXT = 125
}
