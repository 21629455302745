import { Component, OnInit, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Router, } from '@angular/router';
import { Platform, IonAccordionGroup, } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { DeviceViewModelImplemented, DeviceTypeIds, DeviceTypeGroups, DeviceTypeGroup, } from '../../viewmodels/Device.vmi'

import { LoggerOptions } from "../../services/logger/logger.service"
import { StartupService } from '../../services/startup/startup.service';
import { AllDevicesVMI } from '../../viewmodels/AllDevices.vmi';
import { OnboardingStatus } from 'src/app/generated_proto/protobuf-ts/pb/v2/entities';

import { listEnumNumbers } from "@protobuf-ts/runtime";

import Fuse from 'fuse.js'

@Component({
	selector: 'device-list-component',
	templateUrl: './device-list.component.html',
	styleUrls: ['./device-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class DeviceListComponent implements OnInit {

	private loggerOptions:LoggerOptions = {
		prefix:"DevicesPage",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	public allDevices:AllDevicesVMI[] = []
	public filteredDevices:AllDevicesVMI[] = []

	public showLocalDevices: boolean = false;
	public showSearchDevices: boolean = true;

	private protoServiceSub: Subscription;

	public showCreateShipment:boolean = false;

	constructor
	(
		private platform: Platform,
		private router: Router,
		private startupService:StartupService,
		public changeDetectorRef: ChangeDetectorRef,
	)
	{

	}

	ngOnInit() { 
		// this.changeDetectorRef.markForCheck();
	}

	init() {
		this.startupService.ready().then( async (allServices) => {

			if(this.startupService.services.barcodeHandler){
				// this.startupService.services.barcodeHandler.handleScannedData = this.handleScannedData.bind(this);
			}

			console.log("DevicesPage.ionViewDidEnter()")
			this.statusOptions = listEnumNumbers(OnboardingStatus);
			this.filteredByStatus = [OnboardingStatus.UNKNOWN]; // change this once we activate the onboarding process

			if(this.startupService.services.settings?.SETTINGS){
				if (this.platform.is("cordova") || this.platform.is("electron") || this.startupService.services.settings?.SETTINGS) {
					this.showLocalDevices = true;
				}

				if(this.startupService.services.settings.SETTINGS.APP_HIDE_DEVICE_SEARCH == true){
					this.showSearchDevices = false;
				}
	
				if( (this.startupService.services.settings.SETTINGS.APP_SHOW_CREATE_SHIPMENT == true) || false){
					this.showCreateShipment = true;
				}
				else {
					this.showCreateShipment = false;
				}
				
				if(this.startupService.services.data && this.startupService.services.settings.SETTINGS.APP_ID){
					this.startupService.services.data.getDevicesList(this.startupService.services.settings.SETTINGS.APP_ID).then( (devices:AllDevicesVMI[]) => {
						// Short by updated
						console.log("devices: ", devices.length)

						this.allDevices = devices.sort((a, b) => {
							if(a.model.db?.updatedMs && b.model.db?.updatedMs){
								return Number(b.model.db.updatedMs) - Number(a.model.db.updatedMs);
							}
							return 0;
						});
						// Sort by device type
						this.allDevices = this.allDevices.sort((a, b) => {
							if(a.model.device?.deviceType && b.model.device?.deviceType){
								var newGroups:DeviceTypeGroups = a.getDeviceTypeGroups();
								for (let index = 0; index < newGroups.length; index++) {
									const newGroup = newGroups[index];
									// add to deviceTypeOptions if not in it already
									if(!this.deviceTypeOptions.some(obj => obj.deviceTypeId === newGroup.deviceTypeId)){
										this.deviceTypeOptions.push(newGroup);
									}
								}
								return Number(a.model.device?.deviceType) - Number(b.model.device?.deviceType);
							}
							return 0;
						});
						this.deviceTypeOptions = this.deviceTypeOptions.sort((a, b) => {
							return DeviceTypeIds[a.deviceTypeId].localeCompare(DeviceTypeIds[b.deviceTypeId]);
						});
						this.filter();
						this.changeDetectorRef.markForCheck();
						this.showClearAll = false;
					});
				}
			}
		})
	}

	// Each platform/page will have to dictacte how to pass keypress events
	// to the service. this cant be done in a "service" for angular as the DOM 
	// needs to be available for HostListener to fire (i tried it in startupService)
	// @HostListener('document:keydown', ['$event'])
	// handleKeydownEvent(event: KeyboardEvent) {
	// 	if(this.startupService.services.barcodeHandler){
	// 		this.startupService.services.barcodeHandler.handleEvent(event.key);
	// 	}
	// }

	// public handleScannedData(data:string){
	// 	console.log("handleScannedData on devices page: ", data);
	// 	this.searchTerm = data;
	// 	this.filter();
	// }

	public showClearAll:boolean = false;
	filter(){
		this.showClearAll = false;
		this.filteredDevices = this.allDevices;
		if(this.searchTerm.length > 0 && this.allDevices.length > 0){
			this.showClearAll = true;
			const options = {
				includeScore: true,
				shouldSort: true,
				keys: ["model.db.urn","model.device.name", "macAddress", "uuidAsHex"],
			}
			const result = new Fuse(this.filteredDevices, options).search(this.searchTerm)
			this.filteredDevices = result.map((result) => result.item)
			/// ALLOWS US TO WRITE A CUSTOM HARDCODED SEARCH PER VMI
			// this.filteredDevices = this.allDevices.filter( (device:AllDevicesVMI) => {
			// 	return device.checkSearchTerm(this.searchTerm);
			// })
			console.log("result", result)
		}
		if(this.filteredByDeviceType.length>0){
			this.showClearAll = true;
			this.filteredDevices = this.filteredDevices.filter( (device:AllDevicesVMI) => {
				var deviceGroups = device.getDeviceTypeGroups();
				for (let index = 0; index < deviceGroups.length; index++) {
					const deviceGroup = deviceGroups[index];
					var found = this.filteredByDeviceType.some(obj => obj.deviceTypeId === deviceGroup.deviceTypeId);
					if(found){
						return true;
					}
				}
				return false;
			});
		}
		if(this.filteredByStatus.length>0){
			this.showClearAll = true;
			if(!(this.filteredByStatus.length == 1 && this.filteredByStatus[0] == OnboardingStatus.UNKNOWN)){
				this.filteredDevices = this.filteredDevices.filter( (device:AllDevicesVMI) => {
					if(device.model.onboarding?.status){
						return this.filteredByStatus.includes(device.model.onboarding?.status);
					}
					return false;
				});
			}
		}
		// Close all accordians
		if(this.searchAccordianGroup){
			this.searchAccordianGroup.value = undefined;
		}
	}

	clearAllFilters(){
		this.searchTerm = "";
		this.filteredByDeviceType = [];
		this.filteredByStatus = [OnboardingStatus.UNKNOWN];
		this.filter();
		this.showClearAll = false;
	}

	@ViewChild('searchAccordianGroup', { static: true }) searchAccordianGroup: IonAccordionGroup;
	public deviceTypeOptions: DeviceTypeGroups = [];
	public filteredByDeviceType: DeviceTypeGroups = [];
	toggleDeviceType(option:DeviceTypeGroup){
		if(option.deviceTypeId == -1){
			this.filteredByDeviceType = [];
		}
		else if(this.filteredByDeviceType.some(obj => obj.deviceTypeId === option.deviceTypeId)){
			this.filteredByDeviceType = this.filteredByDeviceType.filter(obj => obj.deviceTypeId !== option.deviceTypeId);
		}
		else {
			this.filteredByDeviceType.push(option);
		}
		this.filteredByDeviceType = this.filteredByDeviceType.sort((a, b) => {
			return DeviceTypeIds[a.deviceTypeId].localeCompare(DeviceTypeIds[b.deviceTypeId]);
		});
		this.filter();
	}

	public statusOptions:number[] = [];
	public filteredByStatus:number[] = [];
	public localOnboardingStatus = OnboardingStatus;
	toggleOnboardingStatus(status:number){
		if(status == -1){
			this.filteredByStatus = [];
		}
		else if(this.filteredByStatus.includes(status)){
			// remove
			this.filteredByStatus = this.filteredByStatus.filter(obj => obj !== status);
		}
		else {
			this.filteredByStatus.push(status);
		}
		this.filter();
	}

	public searchTerm: string = "";
	setSearchTerm(searchEvent: any) {
		var search = "";
		if(searchEvent.detail.value){
			search = searchEvent.detail.value;
			this.filter();
		}
		search = search.replace(/\s/g, '');
		this.searchTerm = search;
		this.filter();
	}

	ngOnDestroy() {
		if(this.protoServiceSub){
			this.protoServiceSub.unsubscribe();
		}
	}

	goToPage(page: string) {
		this.router.navigate([page]);
	}

	goToDevice(deviceInput: DeviceViewModelImplemented) {
		this.startupService.services.logger?.verbose(this.loggerOptions, "Going To Devices's Page", deviceInput.getPagePath());
		this.goToPage(deviceInput.getPagePath())
	}

	formatDate(timestampMS: number): string {
		var date: Date = new Date(timestampMS);
		return date.toDateString() + "  @ " + date.getHours().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0');
	}

	toggleGroup(group) {
		group.show = !group.show;
	};

	isGroupShown(group) {
		return group.show;
	};

	goToCreateShipment(){
		this.goToPage("/protected/devices/create-shipment");
	}

	swipeEvent($event, devicesByItems){
		console.log("event: ", $event);
		console.log("devicesByItems: ", devicesByItems);
	}

}
