import { AllServcies, } from '../startup/startup.service';

import { BehaviorSubject } from 'rxjs';
import * as _ from "lodash";

import PouchDB from 'pouchdb';
import PouchAuth from 'pouchdb-authentication'
import PouchFind from "pouchdb-find"

import { DataBase, SyncEventType } from '../../generated_proto/protobuf-ts/pb/v2/models';

import { Channel, ChatMessage, PRIVACY} from '../../generated_proto/google/app/model/v1/data_pb';
import { ChannelViewModel, } from '../../generated_proto/google/app/viewmodel/v1/app_pb';

import { PersonViewModelImplemented } from '../../viewmodels/person.vmi';
import { ChannelViewModelImplemented } from '../../viewmodels/channel.vmi';

import { DataBaseInformationImplemented } from '../data/data.service';
import { LoggerOptions } from '../logger/logger.service';

import { FilesPouchdbService } from './files.pouchdb.service';
import { AppPouchdbService } from './app.pouchdb.service';
import { DevicesPouchdbService } from './devices.pouchdb.service';
import { DeviceManagementPouchdbService } from './deviceManagement.pouchdb.service';
import { AssetManagementPouchdbService } from './assetManagement.pouchdb.service';
import { WebhookPouchdbService } from './webhook.pouchdb.service';
import { GeoNodePouchdbService } from './geoNodeManagment.pouchdb.service';


export class PouchdbService {
	// Extended Services
	public loggerOptions:LoggerOptions = {
		prefix:"PouchdbService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	static DB_NAME_PREFIX = "dB_"; // ALL dbs must start with this to be allowed past the server
	public CHANNEL_DB_PREFIX = "c_"; // ALL dbs must start with this to be allowed past the server
	static USERS_DB = "_users"

	public db_url:string = "";

	private _users_remote:any; // PouchDB Instance - Used to call login functions
	
	private _current_channel_remote:any = null;
	private _current_channel:any = null;

	public files:FilesPouchdbService;
	public app:AppPouchdbService;
	public devices:DevicesPouchdbService;
	public deviceManagement:DeviceManagementPouchdbService;
	public assetManagement:AssetManagementPouchdbService;
	public geoNodePouchdbService:GeoNodePouchdbService;
	public webhook:WebhookPouchdbService;

	constructor(
	)
	{
		
	}

	public init(allServices:AllServcies) : Promise<boolean>{
		this.files = new FilesPouchdbService();
		this.app = new AppPouchdbService();
		this.devices = new DevicesPouchdbService();
		this.deviceManagement = new DeviceManagementPouchdbService();
		this.assetManagement = new AssetManagementPouchdbService();
		this.geoNodePouchdbService = new GeoNodePouchdbService();
		this.webhook = new WebhookPouchdbService();

		return new Promise(async (resolve, reject) => {

			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
			}
			
			if(!allServices.pouchCreator){
				// Web version uses Pouchdb straight
				console.log("PouchDB Via Web - HTTP Implementation");
				PouchDB.plugin(PouchFind)
				allServices.pouchIsServerNode = false;
				if(this.services.settings){
					console.log("PouchdbService: Checking running mode here!!");
					if(this.services.settings.SETTINGS.APP_LOCAL_PRODUCTION_NODE == true){
						console.log("POUCH USING THE CORRECT LOCATION FOR APP_LOCAL_PRODUCTION_NODE")
						this.db_url = "http://"+this.services.settings.SETTINGS.SERVER_LOCAL_URL+":8080/";
					}
					else if(this.services.settings.SETTINGS.RUN_MODE == "LOCAL"){
						console.log("PouchdbService: Running in LOCAL mode");
						this.db_url = "http://"+this.services.settings.SETTINGS.SERVER_LOCAL_URL+"/";
					}
					else{
						console.log("PouchdbService: Running on Production Mode");
						this.db_url = "https://"+this.services.settings.SETTINGS.SERVER_BACKEND_PRODUCTION_URL+"/";
					}
				}
				PouchDB.plugin(PouchAuth);
				if(this.services.platform?.is("android") ){
					// https://github.com/pouchdb-community/pouchdb-adapter-cordova-sqlite
					// https://ionicframework.com/docs/v5/native/sqlite
					console.log("PouchdbService: Using Cordova SQLite Plugin");
					console.log("PouchdbService: Using Cordova SQLite Plugin");
					PouchDB.plugin(require('pouchdb-adapter-cordova-sqlite'));
				}

				allServices.pouchCreator = PouchDB;
			}
			else {
				console.warn("PouchDB Via Nodejs - Direct File Access");
				this.db_url="";
			}

			if(this.services.settings){
				this.CHANNEL_DB_PREFIX = this.services.settings.SETTINGS.PROJECT_SHORT_NAME +"_"+ this.CHANNEL_DB_PREFIX;
			}

			// Connect to Users DB (NOT SYNCABLE)
			this.services.logger?.debug(this.loggerOptions, "PouchdbService: Connecting to Users DB", this.db_url+PouchdbService.USERS_DB);
			this._users_remote = new this.services.pouchCreator(this.db_url+PouchdbService.USERS_DB, {skip_setup: true});
			// await this._users_remote.info().then((info) => {
			// 	console.log("Users Remote: ", info);
			// })
			// .catch((err) => {
			// 	console.log(err);
			// 	resolve(false);
			// 	return;
			// })

			// Init any sub handlers
			console.log("app init")
			this.app.init(allServices);
			console.log("files init")
			this.files.init(allServices);
			console.log("devices init")
			this.devices.init(allServices);
			console.log("deviceManagement init")
			this.deviceManagement.init(allServices);
			console.log("assetManagement init")
			this.assetManagement.init(allServices);
			console.log("geoNodePouchdbService init")
			this.geoNodePouchdbService.init(allServices);
			console.log("webhook init")
			this.webhook.init(allServices);

			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public async addSecurity(appId:number, db:any){
		if(!this.services.pouchIsServerNode){
			return;
		}
		if(!this.services.serverSettings){
			return;
		}
		var admin:string = this.services.serverSettings["SERVER_"+appId+"_ADMIN"];
		var default_user:string = this.services.serverSettings["SERVER_"+appId+"_DEFAULT_USER_USERNAME"];

		if(!admin || admin.length == 0){
			console.error("Missing Admin");
			return;
		}
		if(!default_user || default_user.length == 0){
			console.error("Missing Default User");
			return;
		}

		await db.info().then((info) => {
			var db_security = db.security();
			db_security.fetch().then(() => {
				// add superadmin as role to members and admins
				if(db_security.admins && db_security.admins.name && db_security.admins.names.includes("APP_admin")){
					return;
				}
				db_security.members.names.add("APP_admin");
				db_security.members.roles.add("APP_admin");
				db_security.admins.names.add("APP_admin");
				db_security.admins.roles.add("APP_admin");
				
				db_security.members.names.add(admin);
				db_security.members.roles.add(admin);
				db_security.admins.names.add(admin);
				db_security.admins.roles.add(admin);
				
				// Maybe too late to change this to true default user
				// People already are using these creds
				db_security.members.names.add(default_user);
				db_security.members.roles.add(default_user);
				db_security.admins.names.add(default_user);
				db_security.admins.roles.add(default_user);
				
				db_security.admins.roles.add(""+appId+"_admin");
				db_security.members.roles.add(""+appId+"_member");

				return db_security.save().then( () => {
					db_security.fetch().then(() => {
						// console.log(appId + ": MEMBERS ARE : ", db_security.members);
						// console.log(appId + ": ADMINS ARE : ", db_security.admins);
					})
				});
			}).catch(e => {
				console.error(e);
			});
		})
		.catch((err) => {
		})
	}

	public logout() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			// handle deleting the session
			
		});
	}

	public checkUsername(username:string) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			await this._users_remote.getUser(username, (err, response) => {
				if (err) {
					if (err.name === 'not_found') {
						// console.log("not_found");
						resolve(true);
					} else {
						console.log("error", err);
						reject({code:1, message:"Username available"});
						return;
					}
				} else {
					reject(false);
				}
			});
		});
	};

	public getUsername() : Promise<string> {
		return new Promise( (resolve, reject) => {
			this._users_remote.getSession( (err, response) => {
				if (err) {
					// network error
					// console.error("getSession ", err);
					reject(err);
				} else if (!response.userCtx.name) {
					// nobody's logged in
					// console.error("!response.userCtx.name ", response.userCtx.name);
					reject("!response.userCtx.name");
				} else {
					// console.log("response.userCtx.name ", response.userCtx.name );
					console.log("RESPONSE IS : ", response)
					resolve(response.userCtx.name);
				}
			});
		});
	}

	public getUserVMI() : Promise<any> {
		return new Promise( ( resolve, reject ) => {
			if(!this.getAuthenticated()){
				reject({code:0, message:"Not Authenticated"});
				return;
			}
			this.getUsername().then( (username:string) => {
				this._users_remote.getUser(username, (err, metadata) => {
					if (err) {
						if (err.name === 'not_found') {
							console.log("not_found");
							reject({code:0, message:"Not Found"});
						} else {
							console.log("error", err);
							reject(err);
							return;
						}
					} else {
						if(this.services.encryption){
							// var pb_encoded = this.services.encryption.b64ToPB(metadata.pb_encoded);
							// var user_update:PersonViewModelImplemented = PersonViewModelImplemented.deserializeBinary(pb_encoded) as PersonViewModelImplemented;
							// if(this.services.user){
							// 	this.services.user.updateUserProfile(user_update);
							// }
							resolve({});
						}
						else {
							reject("Encryption missing");
						}
					}
				});
			})
			.catch( (err) => {
				reject(err);
			});

		});
	}

	public updateUserVMI( user:PersonViewModelImplemented ) : Promise<boolean> {
		var metadata:any = {}
		if(this.services.encryption){
			// metadata.pb_encoded = this.services.encryption.uint8Tob64(user.serializeBinary());
		}
		// console.log("updateUserVMI serialized: ", metadata);
		return this.updateUser(metadata);
	}

	public updateUser( metadata:any ) : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			this.getUsername().then( (username) => {
				this._users_remote.getUser(username, (err, user_response) => {
					if (err) {
						if (err.name === 'not_found') {
							console.log("not_found");
						} else {
							console.log("error", err);
							resolve(false);
							return;
						}
					} else {
						var updated = _.mergeWith({}, user_response, metadata, (a, b) => {
							if (_.isArray(a)) {
								return b.concat(a);
							}
						});
						
						// PB is overwritten
						if(metadata.pb_encoded){
							// console.log("updating pb_encoded: ", metadata);
							updated.pb_encoded = metadata.pb_encoded;
						}
						
						// console.log("updated: ", updated);
						// Delete all default metadata that either cant or shouldnt be updated.
						delete(updated.password);
						delete(updated._id);
						delete(updated._rev);
						delete(updated.name);
						delete(updated.type);
						delete(updated.derived_key);
						delete(updated.iterations);
						delete(updated.password_scheme);
						delete(updated.roles);
						delete(updated.salt);
						this._users_remote.putUser(username, {metadata:updated}, (err, response) => {
							if (err){
								console.log("error", err);
								// some other error
								reject(err);
								return;
							}
							else {
								// console.log("response putUser: ", response);
								resolve(true);
							}
						});
					}
				});
			})
			.catch( (err) => {
				reject(err);
			});
		});
	}

	private firstAsk:boolean = true;
	public getAuthenticated() : Promise<string> {
		return new Promise( async (resolve, reject) => {
			await this._users_remote.getSession( (err, response) => {

				// if(this.services.data){
				// 	this.services.data.getByKey("tkn").then( (token) => {
				// 		if(token){
				// 			console.log("Found Token: ", token)
				// 			resolve( token );
				// 			return;
				// 		}
				// 		else{
				// 			reject("token missing");
				// 			return;
				// 		}
				// 	})
				// 	.catch( (e) => {
				// 		reject(e);
				// 		return;
				// 	})
				// }
				// else {
				// 	reject("missing")
				// }
				// // if (err) {
				// // 	// network error
				// // 	console.error("getSession ", err);
				// // 	reject(err);
				// // } else if (!response) {
				// // 	console.error("response ", response);

				// // 	// nobody's logged in
				// // 	console.error("!response.userCtx.name ", response.userCtx.name);
				// // 	reject("!response.userCtx.name");
				// // } else {
				// // 	// response.userCtx.name is the current user
				// // 	// console.log("response.userCtx.name ", response);
				// // 	if(this.firstAsk){
				// // 		this.firstAsk = false;
				// // 		this.getUserVMI().then( (user) => {
				// // 			// console.log("user ", user);
				// // 		});
				// // 	}
				// // 	resolve(true);
				// // }
			});
		});
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// Channel Functions
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	public checkChannelExists( channel_name:string ) : Promise<PRIVACY> {
		return new Promise( async (resolve, reject) => {
			// TODO
			resolve(PRIVACY.UNKNOWN);
		});
	}

	private getChannelHash( channel_name:string ) : string {
		if(this.services.encryption){
			return this.services.encryption.fullDjb2(channel_name);
		}
		console.error("Missing Encryption Service");
		return "";
	}

	private getChannelUrl( channel_name:string ) : string {
		return this.db_url+PouchdbService.DB_NAME_PREFIX+this.CHANNEL_DB_PREFIX+this.getChannelHash(channel_name);
	}

	private getCreateChannelUrl( channel_name:string ) : string {
		return this.db_url+"/pdb/createChannel/"+this.getChannelHash(channel_name)
	}

	public getDbUrl() : string {
		return this.db_url;
	}


	public createChannel( channel_pb : Channel ) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			var new_channel = new Channel();
			new_channel.setName(channel_pb.getName());
			// new_channel.setCreatedBy(this.gunConnection.user().is.pub);

			new_channel.setCreatedMs(Date.now());
			if(!this.services.encryption){
				reject("Missing Encryption Service");
				return;
			}
			var new_channel_serialized = this.services.encryption.uint8Tob64(new_channel.serializeBinary());
			console.log(" :: New Channel is :  ", new_channel.toObject());
			if(channel_pb.getPrivacy() == PRIVACY.PUBLIC || channel_pb.getPrivacy() == PRIVACY.READ_ONLY){
				console.log(":: Privacy is public or readonly : ", channel_pb.getPrivacy() );
			}
			else if (channel_pb.getPrivacy() == PRIVACY.PRIVATE_PASSWORD ){
				console.log(":: Privacy is PRIVATE_PASSWORD : ", channel_pb.getPrivacy() );

				var channel_name_hash:string = this.CHANNEL_DB_PREFIX+this.services.encryption.fullDjb2(channel_pb.getName());
				var full_channel_db_url:string = this.db_url+PouchdbService.DB_NAME_PREFIX+channel_name_hash;

				// TODO :: pass user id to create channel securely
				// TODO :: NODEJS :: Check if this exists/create the http for nodejs thats compatible here.
				if(this.services.http){
					await this.services.http.post(this.db_url+"/pdb/create/"+channel_name_hash, {}).toPromise().then( async (response) => {
						this._current_channel_remote = new this.services.pouchCreator(full_channel_db_url);
						await this._current_channel_remote.info().then((info) => {
							console.log(info);
						})
						.catch((err) => {
							console.log(err);
							reject(err);
							return;
						})
	
						this._current_channel = new this.services.pouchCreator(channel_name_hash);
						this._current_channel.sync(this._current_channel_remote, {live: true, retry: true}).on('error', (err) => {
							console.error("sync error : ", err)
						});
	
						await this._current_channel.info().then((info) => {
							console.log(" Channel info is :", info);
							this._current_channel.get("channel")
							.catch( (err) => {
								if (err.name === 'not_found') {
									return {
										_id: "channel",
										created: Date.now(),
									};
								} else { // hm, some other error
									reject(err);
								}
							})
							.then( (doc) => {
								// update their age
								doc.last_updated = Date.now();
								doc.pb_unencrypted = new_channel_serialized;
								return this._current_channel.put(doc);
							}).then( () => {
								// fetch mittens again
								return this._current_channel.get("channel");
							}).then( (doc) => {
								console.log("Final Doc: " , doc);
								resolve(true);
							})
							.catch( (err) => {
								reject(err);
							});
	
						})
						.catch((err) => {
							reject(err);
						})
					}).catch( (err) => {
						console.error("error from create channel: ", err);
						reject(err);
					});
				}
				else if (channel_pb.getPrivacy() == PRIVACY.PRIVATE_INVITE ){
					console.log(":: Privacy is PRIVATE_INVITE : ", channel_pb.getPrivacy() );
					// This pair gets secret encryption w/ public of the channel and pair of the user
				}
			}
			else{
				reject("HTTP Client Missing");
			}
		});
	}

	public joinChannel( join_channel:Channel ) : Promise<ChannelViewModel> {
		return new Promise( async (resolve, reject) => {

			if(!this.services.encryption){
				reject("Missing Encryption Service");
				return;
			}
			var channel_name_hash:string = this.CHANNEL_DB_PREFIX+this.services.encryption.fullDjb2(join_channel.getName());
			var full_channel_db_url:string = this.db_url+PouchdbService.DB_NAME_PREFIX+channel_name_hash;


			this._current_channel_remote = new this.services.pouchCreator(full_channel_db_url);
			await this._current_channel_remote.info().then((info) => {
				console.log(info);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
				return;
			})

			this._current_channel = new this.services.pouchCreator(channel_name_hash);
			this._current_channel.sync(this._current_channel_remote, {live: true, retry: true}).on('error', (err) => {
				console.error("sync error : ", err)
			});

			await this._current_channel.info().then((info) => {
				console.log(" Channel info is :", info);
				return this._current_channel.get("channel")
			}) 
			.then( (doc:any ) => {
				console.log("Final Doc: " , doc);
				if(doc.pb_unencrypted){
					var channel_vmi = new ChannelViewModel();
					if(this.services.encryption){
						var channel_encoded = this.services.encryption.b64ToPB(doc.pb_unencrypted);
						var channel_pb = Channel.deserializeBinary(channel_encoded);
						channel_vmi.setChannel(channel_pb);
						console.log("Channel View Model: ", channel_vmi.toObject());
						resolve(channel_vmi);
					}
					else {
						reject("Missing Encryption Service");
						return;
					}
				}
				else {
					reject("No channel found");
				}
			})
			.catch( (err) => {
				reject(err);
			})
		});
	}

	public leaveChannel( channel_vmi:ChannelViewModelImplemented ) : Promise<boolean>{
		return this.stopStreamChannel(channel_vmi);
		// return new Promise( (resolve, reject) => {
		// 	resolve(true);
		// });
	}

	public sendChannelMessage( channel_vmi:ChannelViewModelImplemented, message:ChatMessage ) : Promise<boolean>{
		return new Promise( async (resolve,reject) => {
			console.log("Sending message: ", message.toObject());
			var doc_id = "message_"+Date.now();

			this._current_channel.get(doc_id)
			.catch( (err) => {
				if (err.name === 'not_found') {
					return {
						_id: doc_id,
						created: Date.now(),
					};
				} else { // hm, some other error
					throw err;
				}
			})
			.then( (doc) => {
				// update their age
				if(this.services.encryption){
					doc.pb_encoded = this.services.encryption.uint8Tob64(channel_vmi.serializeBinary());
				}
				else{
					reject("Missing Encryption Service");
					return;
				}
				return this._current_channel.put(doc);
			}).then( () => {
				// fetch mittens again
				return this._current_channel.get(doc_id);
			}).then( (doc) => {
				console.log("Final Doc: " , doc);
			});

			resolve(true);
		});
	}

	// Can only WRITE to a channel that you're joined to.
	public saveToChannel( data_key:string, data:any ) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			if(this._current_channel == null){
				reject("No channel selected");
				return;
			}
			this._current_channel.get(data_key)
			.catch( (err) => {
				if (err.name === 'not_found') {
					return {
						_id: data_key,
						created: Date.now(),
					};
				} else { // hm, some other error
					throw err;
				}
			})
			.then( (doc) => {
				// update their age
				doc.last_updated = Date.now();
				doc.data = data;
				return this._current_channel.put(doc);
			}).then( () => {
				// fetch mittens again
				return this._current_channel.get(data_key);
			}).then( (doc) => {
				console.log("Final Doc: " , doc);
			});
			resolve(true);
		});
	};

	private streamHandler:any = null;
	public streamChannel( channel_vmi:ChannelViewModelImplemented ): Promise<boolean> { 
		return new Promise( async ( resolve, reject ) => {
			this.streamHandler = this._current_channel.changes({
				since: 'now',
				live: true,
				include_docs: true
			}).on('change', (change) => {
				// handle change
				console.log("Change: ", change);
			}).on('complete', (info) => {
				// changes() was canceled
				console.log("complete");
			}).on('error',  (err) => {
				console.log(err);
			});
			resolve(true);
		});
	}

	public stopStreamChannel( channel_vmi:ChannelViewModelImplemented ) : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			if(this.streamHandler != null){
				this.streamHandler.cancel();
				this.streamHandler = null;
			}
			resolve(true);
		});
	}


	
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// UTILITY FUNCTIONS
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// SYNC FUNCTIONS
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// This holds the DB connection to the specific db
	private _dbiis:{
		[urn:string]:DataBaseInformationImplemented,
	} = {};

	public clearAllDbii():void{
		this._dbiis = {};
	}

	public getAllLocalDatabases() : Promise<DataBaseInformationImplemented[]> {
		// Tried : DO NOT USE :: https://github.com/pouchdb-community/pouchdb-all-dbs
		// Avoid the typescript issue of databases not found (it exists, but ts is beingw weird)
		return new Promise( (resolve,reject) => {
			if(!indexedDB["databases"]){
				console.error("index db issue");
				return;
			}
			indexedDB["databases"]().then((idbs:any[]) => {
				var dbiis:DataBaseInformationImplemented[] = [];
				for(let i = 0; i < idbs.length; i++){
					let idb = idbs[i];
					if(idb.name.startsWith('_pouch_')){
						var dbid:string = idb.name.split("_pouch_")[1];
						dbiis.push(this.getDbInformationImpelemnted(dbid));
					}
				}
				resolve(dbiis);
			});
		})
	}

	public deleteAllLocalDatabases() {
		if(!indexedDB["databases"]){
			console.error("index db issue");
			return;
		}
		indexedDB["databases"]().then((idbs:any[]) => {
			console.log("Databases: ", idbs);
			for(let i = 0; i < idbs.length; i++){
				let db = idbs[i];
				if(db.name.startsWith('_pouch_')){
					console.log("Deleting pouch: ", db);
					indexedDB.deleteDatabase(db);
				}
			}
		});
	}

	public getUsersDb() : DataBaseInformationImplemented {
		var dbii = new DataBaseInformationImplemented(this.services, "_users");
		dbii.remoteDb = new this.services.pouchCreator( this.db_url+"_users", {
			skip_setup: true,
			// fetch: (url, opts) => fetch(url, { ...opts, credentials: 'include' /* OR 'same-origin' */ })
			fetch: async (url, opts) => {
				if(this.services.auth){
					if(this.services.auth.hasAuth){
						await this.services.auth.getAuth().then( (foundAuth) => {
							if(foundAuth?.method.oneofKind == "token"){
								opts.headers.set("Authorization", "Bearer "+foundAuth.method.token.token);
							}
						}).catch( (e)=> {
							console.error("Auth Error: ", e);
						})
					}
				}
				return PouchDB.fetch(url, opts);
			}
		});
		return dbii;
	}

	// can pass dbid or urn to this, will split and find the dbid from urn.
	public getDbInformationImpelemnted(urn:string, localOnly:boolean=false) : DataBaseInformationImplemented {
		var dbid = urn.split("/")[0];
		if(this._dbiis[dbid]==null){
			var dbii = new DataBaseInformationImplemented(this.services, urn);
			if(dbii.db){
				dbii.db.urn = dbid;
				var localOptions = {skip_setup: true};
				if(this.services.platform?.is("android") ){
					localOptions["adapter"] = "cordova-sqlite";
					localOptions["iosDatabaseLocation"] = "Library";
					localOptions["androidDatabaseImplementation"] = 2;
				}

				dbii.localDb = new this.services.pouchCreator( dbid, localOptions);
				if(!localOnly){
					var remoteOptions = {
						skip_setup: true,
						// fetch: (url, opts) => fetch(url, { ...opts, credentials: 'include' /* OR 'same-origin' */ })
						fetch: async (url, opts) => {
							if(this.services.auth){
								if(this.services.auth.hasAuth){
									await this.services.auth.getAuth().then( (foundAuth) => {
										if(foundAuth?.method.oneofKind == "token"){
											opts.headers.set("Authorization", "Bearer "+foundAuth.method.token.token);
										}
									}).catch( (e)=> {
										console.error("Auth Error: ", e);
									})
								}
							}
							return PouchDB.fetch(url, opts);
						}
					};
					// if(this.services.platform?.is("android") ){
					// 	remoteOptions["adapter"] = "cordova-sqlite";
					// 	localOptions["iosDatabaseLocation"] = "Library";
					// 	localOptions["androidDatabaseImplementation"] = 2;
					// }
					console.log("remote url is : ", this.db_url+dbid)
					dbii.remoteDb = new this.services.pouchCreator( this.db_url+dbid, remoteOptions);
				}
			}
			this._dbiis[dbid] = dbii;
		}
		return this._dbiis[dbid];
	}

	public deleteFromLocal(urn:string) : void {
		var dbid = urn.split("/")[0];
		if(this._dbiis[dbid]==null){
			return;
		}
		else {
			delete this._dbiis[dbid];
		}
	}

	// NOTE : Will retrun EMPTY if disconnected from remote server, this way we can
	// use whats local in the case that we're disconnected. will use local still.
	private getRemoteDbIds(dbii:DataBaseInformationImplemented, latestKey?:string) : Promise<{[uid:number]:DataBase}>{
		return new Promise( (resolve, reject) => {
			var search:any = {
				include_docs: false,
				limit: 50000,
			};
			if(latestKey){
				search.startkey = "";
				search.endkey = latestKey;
			}
			dbii.remoteDb.allDocs(search).then( async (result) => {
				if(result.rows.length<=0){
					resolve({});
					return;
				}
				if(!dbii.db){
					reject("Failure Setting up DB");
					return;
				}
				resolve(this.dbsFromNoDoc(result.rows.map((r)=>r.id), false));
			})
			.catch( (err) => {
				console.error("Failure Fetching ids from remote DB: ", err);
				reject("Failure Fetching ids from remote DB"+err);
				return;
			});
		})
	}

	private local_db_id:string = "_local/sync";
	public getLoaclDbIds(dbii:DataBaseInformationImplemented) : Promise<{[uid:number]:DataBase}>{
		return new Promise( (resolve, reject) => {
			dbii.localDb.allDocs({include_docs: false}).then( async (result) => {
				if(result.rows.length<=0){
					resolve({});
					return;
				}
				if(!dbii.db){
					reject("Failure Setting up DB");
					return;
				}
				resolve(this.dbsFromNoDoc(result.rows.map((r)=>r.id), true));
			})
			.catch( (err) => {
				console.error("Failure Fetching ids from remote DB: ", err);
				reject("Failure Fetching ids from remote DB"+err);
				return;
			});
		})
	}

	// this will build a db model based off the key information alone
	dbsToDocIds(dbs:DataBase[]) : string[] {
		var ids:string[] = [];
		for(let i = 0; i < dbs.length; i++){
			ids.push(""+dbs[i].modelType+","+dbs[i].modelVersion+","+dbs[i].uid);
		}
		return ids;
	}

	public ConvertToBigInt(input) : bigint {
		try {
			return BigInt(input);
		} catch (error) {
			console.warn("ConvertToBigInt:", error);
			return BigInt(0);
		}
	}

	private dbsFromNoDoc(ids:any, fromLocal:boolean) : {[uid:number]:DataBase} {
		var dbs = {};
		if(ids.length<=0){
			return dbs;
		}
		if(ids.length>0){
			for (let index = 0; index < ids.length; index++) {
				const id = ids[index];
				if(id[0] == "_" ){
					continue;
				}
				var keyTokens = id.split(",");
				var newDb = DataBase.create();
				newDb.modelType = this.ConvertToBigInt(parseInt(keyTokens[0]));
				newDb.modelVersion = this.ConvertToBigInt(parseInt(keyTokens[1]));
				newDb.uid = this.ConvertToBigInt(parseInt(keyTokens[2]));
				newDb.createdMs = this.ConvertToBigInt(0xFFFFFFFFFFF) - this.ConvertToBigInt(parseInt(keyTokens[2]));
				newDb.latestMs = this.ConvertToBigInt(0xFFFFFFFFFFF) - this.ConvertToBigInt(parseInt(keyTokens[2]));
				if(fromLocal){
					newDb.syncStatus = SyncEventType.SYNC_FINISHED;
				}
				else {
					newDb.syncStatus = SyncEventType.ID_LOADED;
				}
				dbs[Number(newDb.uid)] = newDb;
			}
		}
		return dbs;
	}

	private updateLocalDbs(target:{[uid:number]:DataBase} , update: {[uid:number]:DataBase}) : {[uid:number]:DataBase} {
		var updateKeys = Object.keys(update);
		for (let index = 0; index < updateKeys.length; index++) {
			const key = updateKeys[index];
			if(target[key]==null){
				target[key] = update[key];
			}
			else{
				var state = target[key].syncStatus || SyncEventType.UNKNOWN;
				target[key] = Object.assign(target[key], update[key]);
				if(state >= SyncEventType.COMPLETE){
					target[key].syncStatus = state;
				}
			}
		}
		return target;
	}

	private updateLocalDbIdsFromRemote(dbii:DataBaseInformationImplemented) : Promise<boolean>{
		return new Promise<boolean>( async (resolve, reject) => {
			var localIds = await this.getLoaclDbIds(dbii);
			if(!localIds){
				reject("Local id get failure");
				return;
			}
			console.log("got localIds keys (synced) : ", Object.keys(localIds).length);

			var latestKey = Object.keys(localIds)[0];
			var remoteIds;
			if(latestKey){
				remoteIds = await this.getRemoteDbIds(dbii);
			}
			else {
				remoteIds = await this.getRemoteDbIds(dbii);
			}
			console.log("got remote keys (unsynced) : ", Object.keys(remoteIds).length);
			if(!remoteIds){
				reject("Remote id get failure");
				return;
			}
			dbii.dbIdCache = this.updateLocalDbs(localIds, remoteIds);
			resolve(true);
		})
	}

	public initSync( urn:string, starteEpochMs?:number, endEpochMs?:number, maximumDocuments?:number,  updater?:BehaviorSubject<number>) : Promise<boolean> {
		console.log("initSync on urn: ", urn);
		var dbii = this.getDbInformationImpelemnted(urn);
		if(dbii){
			return new Promise( async (resolve,reject) => {
				// Get all doc ids from the remote db
				var updated = await this.updateLocalDbIdsFromRemote(dbii)
				if(!updated){
					reject("Update local id db failed");
					return;
				}
				resolve(true);
			});
		}
		return Promise.reject("startSync:Not implemented");
	}

	public sync( urn:string, starteEpochMs?:number, endEpochMs?:number, maximumDocuments?:number,  updater?:BehaviorSubject<number>) : Promise<boolean> {
		console.log("sync on urn: ", urn);
		var dbii = this.getDbInformationImpelemnted(urn);
		if(dbii){
			return new Promise( async (resolve,reject) => {
				// Get all doc ids from the remote db
				if(starteEpochMs && endEpochMs){
					console.group("sync range:");
					console.log("Start: ",  new Date(starteEpochMs ));
					console.log("End:   ",  new Date(endEpochMs));
					console.groupEnd();
					console.log("Getting keys to sync")
					var missingDbs = dbii.getUnsyncedInRange(starteEpochMs, endEpochMs);
					var missingDbKeys = this.dbsToDocIds(missingDbs);
					console.log("Got Keys")
					if(missingDbKeys.length>0){
						console.log("Syncing ", missingDbKeys.length, " keys")
						this.promisifySyncByDocs(dbii, {live:false}, updater, missingDbKeys).then( () => {
							resolve(true)
						})
					}
					else {
						console.log("No missing to sync")
						resolve(true)
					}
				}
				else {
					console.log("No starteEpochMs and endEpochMs provided, not syncing further");
					resolve(true)
				}
			});
		}
		return Promise.reject("startSync:Not implemented");
	}

	public promisifySyncByDocs(dbii:DataBaseInformationImplemented, options?:any, updater?:BehaviorSubject<number>, docIds?:string[], maximumDocuments?:number) : Promise<boolean> {
		return new Promise( (resolve,reject) => {
			if(!dbii.db){
				dbii.addSyncEvent({eventType:SyncEventType.ERROR, eventData:"Failure setting up DB"});
				reject("Failure Setting up DB");
				return;
			}
			var syncOptions:any = {
				live: false,
				retry: true,
				batch_size: 100,
			}
			if(options){
				syncOptions = {... syncOptions, options}
				console.log(" :: Starting ");
			}
			if(docIds) syncOptions.doc_ids = docIds;
			dbii.addSyncEvent({eventType:SyncEventType.STARTED, eventData:"Starting Sync"});
			var documentCounter = 0;
			console.log(" :: Starting Sync");
			console.log(" :: Starting Sync");
			console.log(" :: Starting Sync");
			dbii.sync = dbii.localDb.sync(dbii.remoteDb, syncOptions)
			.on('change', (eventData) => {
				var docs = eventData.change.docs;
				if(eventData.change.docs.length>0){
					var ids:any[] = eventData.change.docs.map((d)=>d._id);
					dbii.dbIdCache = this.updateLocalDbs(dbii.dbIdCache, this.dbsFromNoDoc(ids, true));
				}
				if(updater)updater.next(Date.now());
				if(eventData.change.docs){
					if(maximumDocuments){
						if(eventData.change.docs.length>0){
							documentCounter += eventData.change.docs.length;
						}
						if(documentCounter > maximumDocuments){
							dbii.addSyncEvent({eventType:SyncEventType.ERROR, eventData:"Maximum documents reached"});
							dbii.sync.cancel();
							resolve(true);
						}
					}
				}
				dbii.addSyncEvent({eventType:SyncEventType.CHANGE});
			})
			.on('paused', (eventData) => {
				if(updater)updater.next(Date.now());
				// replication paused (e.g. replication up to date, user went offline)
				dbii.addSyncEvent({eventType:SyncEventType.PAUSED});
			})
			.on('active', (eventData) => {
				if(updater)updater.next(Date.now());
				// replicate resumed (e.g. new changes replicating, user went back online)
				dbii.addSyncEvent({eventType:SyncEventType.ACTIVE});
			})
			.on('denied', (eventData) => {
				if(updater)updater.next(Date.now());
				// a document failed to replicate (e.g. due to permissions)
				dbii.addSyncEvent({eventType:SyncEventType.DENIED});
			})
			.on('complete', (eventData) => {
				if(updater)updater.next(Date.now());
				dbii.addSyncEvent({eventType:SyncEventType.COMPLETE});
				resolve(true);
			}).
			on('error', (eventData) => {
				dbii.addSyncEvent({eventType:SyncEventType.ERROR});
				dbii.sync.cancel();
				reject(eventData);
			});
		});
	}

	public stopSync(dbii:DataBaseInformationImplemented) : Promise<boolean>{
		return new Promise( (resolve,reject) => {
			if(dbii.sync){
				try{
					dbii.sync.cancel();
				}
				catch(e){
					reject("Stop Sync Err: "+dbii.db?.urn+" :: "+JSON.stringify(e))
				}
			}
			resolve(true);
		});
	}

}

// NOTES:
// https://medium.com/@eiri/couchdb-authorization-in-a-database-58c8ee633c96



