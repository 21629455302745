
import { AllServcies } from "../startup/startup.service"

import * as Rx from "rxjs";

// VMI Imports
import { DashboardViewModelImplemented, } from "../../viewmodels/dashboard.vmi";
import { DeviceViewModelImplemented, } from "../../viewmodels/Device.vmi";

// Generated Proto Imports
import { PersonViewModelImplemented } from '../../viewmodels/person.vmi';
import { ChannelViewModelImplemented } from '../../viewmodels/channel.vmi';

import { LoggerOptions } from "../logger/logger.service"

import { vendorFromJson } from "../../vendors/viewmodels.vmi"

import _ from 'lodash'
import { AllDevicesVMI } from '../../viewmodels/AllDevices.vmi';
import { BluetoothConnection } from "../bluetooth/ble.connetion";
import { AllGeoNodeImplemented, GeoNodeViewModelImplemented } from "../..//viewmodels/geo/geonode.vmi";
import { SiteViewModelImplemented } from "../../viewmodels/geo/site.vmi";
import { AreaViewModelImplemented } from "../../viewmodels/geo/area.vmi";
import { FloorViewModelImplemented } from "../../viewmodels/geo/floor.vmi";
import { RoomViewModelImplemented } from "../../viewmodels/geo/room.vmi";

export class ProtoService {

	private loggerOptions:LoggerOptions = {
		prefix:"ProtoService",
		allOn:true,
		verboseOn:false,
		debugOn:true,
	};

	public allDevices:AllDevicesVMI[] = []	
	public allGeoNodes: AllGeoNodeImplemented[] = [];
	public subjectUpdate = new Rx.BehaviorSubject(false);

	constructor(
	) 
	{

	}

	init(services:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(!services){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(services);
				resolve(true);
			}
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getUserProfile() : Promise<PersonViewModelImplemented> {
		// console.error("getUserProfile: V2 PB UPDATE REQUIRED")
		if(this.services.auth){
			return this.services.auth.getUser();
		}
		else {
			return Promise.reject("No Auth Service");
		}
	}

	private channelViewModel:ChannelViewModelImplemented;
	public getChannelViewModel() : ChannelViewModelImplemented {
		if(!this.channelViewModel){
			this.channelViewModel = new ChannelViewModelImplemented(this.services);
		}
		return this.channelViewModel;
	}

	private dashboardViewModelImpelemnted:DashboardViewModelImplemented;
	public getDashboardViewModelImplemented() : DashboardViewModelImplemented {
		// This is where we'd build from local cache, or the existing objects in 
		// our app
		if(!this.dashboardViewModelImpelemnted){
			this.dashboardViewModelImpelemnted = new DashboardViewModelImplemented(this.services);
		}
		return this.dashboardViewModelImpelemnted;
	}

	public getDevicesList() : Promise<AllDevicesVMI[]> {
		// This is where we'd build from local cache, or the existing objects in our app

		if(this.services.data && this.services.settings?.SETTINGS.APP_ID) {
			return this.services.data.getDevicesList(this.services.settings.SETTINGS.APP_ID);
		}
		return Promise.reject(this.loggerOptions.prefix+":getDevicesList:No PouchDB Service");
	}

	public addDeviceViewModelImplemented(device:AllDevicesVMI) : Promise<boolean> {
		return new Promise( (resolve,reject) => {
			this.allDevices.push(device);
			this.subjectUpdate.next(true);
			resolve(true);
		});
	}

	public getDeviceViewModelImplemented(uuid:number,) : Promise<AllDevicesVMI> {
		return new Promise( async (resolve, reject) =>  {
			var device = this.allDevices.find((m:AllDevicesVMI) => m.model.device?.uuid == BigInt(uuid));
			if(!device && this.services.pouch && this.services.settings?.SETTINGS.APP_ID){
				this.services.data?.getDeviceByUuid(this.services.settings?.SETTINGS.APP_ID, BigInt(uuid)).then( async (newDevice:AllDevicesVMI) => {
					this.allDevices.push(newDevice);
					if(newDevice) {
						resolve(newDevice);
						return true;
					}
					return false;
				}).catch( (err) => {
					var bleDevice:BluetoothConnection | null = this.services.bluetooth?.getDeviceConnection(BigInt(uuid)) || null;
					if(bleDevice !=null && bleDevice?.parentVMI){
						resolve(bleDevice.parentVMI);
						return;
					}
					else {
						reject(err)
					}

				});
				
			}
			else {
				if(device)resolve(device);
				else reject("Sensor Not Found");
			}
		});
	}

	public deleteDevice( app_id:number, deviceVMI:AllDevicesVMI ) : Promise<boolean> {
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		console.log("Found Devices : ", this.allDevices)
		var index = this.allDevices.findIndex((m:AllDevicesVMI) => m.model.device?.uuid == deviceVMI.model.device?.uuid);
		if (index > -1) { // only splice array when item is found
			this.allDevices.splice(index, 1); // 2nd parameter means remove one item only
		}
		this.services.logger?.debug(this.loggerOptions, "delete: AppID: "+app_id+" ::model: "+deviceVMI.model.device?.uuid);
		if(this.services.data) return this.services.data.deleteDevice(app_id||2, deviceVMI);
		if(this.services.logger) this.services.logger.error(this.loggerOptions, "delete: DataService missing")
		return Promise.reject("delete: DataService missing");
	}

	public createLocalFromDb( json:any ) : AllDevicesVMI {
		var vendor = vendorFromJson(this.services, json)
		if(vendor){
			return vendor
		}

		// If we have any other ways of creating new sensor, add them here. must be common to all projects
		// If new parsers need to be custom => put under vendors/viewmodels.ts
		var newDevice:DeviceViewModelImplemented = new DeviceViewModelImplemented(this.services);
		newDevice.updateLocalFromDb(json);
		return newDevice;
	}

	public createGeoNodeFromDb( json:any ) : AllGeoNodeImplemented {
		// var type = json.type;
		console.log(json);
		switch(json["geoNode"]["type"]){
			case "GEO_NODE_TYPE_BUILDING":
				var building = new SiteViewModelImplemented(this.services);
				building.jsonToModel(json);
				this.allGeoNodes.push(building);
				return building;
			case "GEO_NODE_TYPE_FLOOR":
				var floor = new FloorViewModelImplemented(this.services);
				floor.jsonToModel(json);
				this.allGeoNodes.push(floor);
				return floor;
			case "GEO_NODE_TYPE_ROOM":
				var room = new RoomViewModelImplemented(this.services);
				room.jsonToModel(json);
				this.allGeoNodes.push(room);
				return room;
			case "GEO_NODE_TYPE_AREA":
				var area = new AreaViewModelImplemented(this.services);
				area.jsonToModel(json);
				this.allGeoNodes.push(area);
				return area;
			default:
				var geonode = new GeoNodeViewModelImplemented(this.services);
				this.allGeoNodes.push(geonode);
				return geonode;
		}

	}
	public deleteBuilding( app_id:number, geonodeVMI:GeoNodeViewModelImplemented ) : Promise<boolean> {
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		console.log("Found Devices : ", this.allDevices)		
		var index = this.allGeoNodes.findIndex((m:AllGeoNodeImplemented) => m.model?.geoNode?.uuid == geonodeVMI.model?.geoNode?.uuid);
		if (index > -1) { // only splice array when item is found
			this.allGeoNodes.splice(index, 1); // 2nd parameter means remove one item only
		}
		this.services.logger?.debug(this.loggerOptions, "delete: AppID: "+app_id+" ::model: "+geonodeVMI.model?.geoNode?.uuid);
		if(this.services.data) return this.services.data.deleteGeoNode(app_id||2, geonodeVMI);
		if(this.services.logger) this.services.logger.error(this.loggerOptions, "delete: DataService missing")
		return Promise.reject("delete: DataService missing");
	}
}
