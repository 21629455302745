
import { DeviceTypeIds, DeviceViewModelImplemented, } from "../../viewmodels/Device.vmi";
import { BluetoothConnection, } from "../../services/bluetooth/ble.connetion"
import { CONNECTION_STATE, } from "../../services/constants"
import { AllServcies, } from "../../services/startup/startup.service";
import { GPSScanRecord, Telemetry, VendorData, RadationData, GammaData, NeutronData, GammaNeutronState, } from "../../generated_proto/protobuf-ts/pb/v2/data";
import { DeviceModel } from "../../generated_proto/protobuf-ts/pb/v2/models";
import { BluetoothCommonInterface, BluetoothRequestOptions } from "../../services/bluetooth/bluetooth.service";

import { encode as varintEncode, decode as varintDecode } from 'varint';

export class MirionBluetoothConnection extends BluetoothConnection {

	public heartBeatIntervalMs:number = 1500;

	
	constructor
	(
		services:AllServcies,
		bluetoothCommonInterface?:BluetoothCommonInterface,
		)
		{
			super(services, bluetoothCommonInterface);
			if(services){
				this.services = services;
			}
			if(bluetoothCommonInterface){
				this.bluetoothCommonInterface = bluetoothCommonInterface;
				console.log("CREATED MirionBluetoothConnection CONNECTION : ", this.bluetoothCommonInterface);
			}
			else {
				console.error("ERROR CREATING MirionBluetoothConnection CONNECTION");
			}
		}
		
		async initalizeDevice() : Promise<boolean> {
		var bleCommandService="49535343-fe7d-4ae5-8fa9-9fafd205e455";
		var bleCommandCharacteristic="49535343-1e4d-4bd9-ba61-23c647249616";
		return new Promise( async (resolve, reject) => {
			this.connectionState = CONNECTION_STATE.CONNECTING;
			if(!this.getIsConnected()){
				try{
					await this.connect().catch( (e) => {
						reject(e);
						return;
					});
				}
				catch(e){
					reject(e);
					return;
				}
			}

			await this.services.bluetooth?.subscribeCharacteristic(this, bleCommandService, bleCommandCharacteristic, async (data) => {
				await this.processStreamingData(data);

				await this.services.util?.sleep(500);
				var command = Uint8Array.from([0x7E, 0x04, 0x00, 0x11, 0xA7, 0x1E, 0x43, 0xE7,]);
				var sentCommand = await this.services.bluetooth?.writeCharacteristic(this, bleCommandService, bleCommandCharacteristic, command, true);
				console.log("Sent command: ", sentCommand);
			});

			var command = Uint8Array.from([0x7E, 0x04, 0x00, 0x11, 0xA7, 0x1E, 0x43, 0xE7,]);
			var sentCommand = await this.services.bluetooth?.writeCharacteristic(this, bleCommandService, bleCommandCharacteristic, command, true);
			console.log("Sent command: ", sentCommand);
			resolve(true);
		});
	}

	getRequesetFilter() : BluetoothRequestOptions {
		var requestFilter:BluetoothRequestOptions = {
			namePrefix: "00",
			services:[
			],
			optionalServices: [
				"49535343-fe7d-4ae5-8fa9-9fafd205e455"
			], // Note this is required to allow ANY usage of the service on web ble
			deviceTypeId: DeviceTypeIds.MIRION_ACCURAD
		}
		// console.log("Name : 0x1800", numberToUUID(0x1800));
		// console.log("Battery : 0x180F", numberToUUID(0x180F));
		// console.log("requestFilter Information :", requestFilter);
		return requestFilter;
	}

	
	processStreamingData(data: Uint8Array): Promise<void> {
		return new Promise( async (resolve, reject) => {
			var littleEndian = true;
			var prefix = ""; // Bytes 0-10 prefix
			var length = 0; // 11, 12
			var messageId = 0; // 13, 14
			var state = 0; // 15
			var doseRate = 0; // 16, 17, 18, 19
			var countRate = 0; // 20, 21, 22, 23
			var backgroundDoseRate = 0; // 24, 25, 26, 27
			var backgroundCountRate = 0; // 28, 29, 30, 31
			var level = 0; // 32, 33, 34, 35
			var time = 0; // 36, 37, 38, 39
			var date = 0; // 40, 41, 42, 43
			var dose = 0; // 44, 45, 46, 47
			var duration = 0; // 48, 49, 50, 51
		
			var batteryState = 0; // 52
			var batteryLevel = 0; // 53
			var systemState = 0; // 54, 55, 56, 57
			var measurementId = 0; // 58, 59, 60, 61
			var checsum = 0; // 62, 63
		
		
			var dataView = new DataView(data.buffer);
			var accuRadPrefix = "#!AccuRad!#"
			
		
			for (let index = 0; index < 11; index++) {
				const byte = data[index];
				prefix += String.fromCharCode(byte);
			}
			
			if(accuRadPrefix != prefix){
				console.error("Wrong prefix");
				return;
			}
		
			length = dataView.getUint16(11, littleEndian);
		
			if(data.length-13 != length){
				console.error("Wrong length");
				return;
			}
		
			messageId = dataView.getUint16(13, littleEndian);
			state = data[15];
			doseRate = dataView.getFloat32(16, littleEndian);
			countRate = dataView.getFloat32(20, littleEndian);
			backgroundDoseRate = dataView.getFloat32(24, littleEndian);
			backgroundCountRate = dataView.getFloat32(28, littleEndian);
			level = dataView.getFloat32(32, littleEndian);
			
			// 5 bits “Hours” = 0 1011 = 0x0B = 11 H
			// 6 bits “Minutes” = 10 1001 = 0x29 = 41 min
			// 6 bits “Seconds” = 11 1001 = 0x39 = 57 s
			// 13 bits “Milliseconds” = 0 0000 0001 0011 = 0x0013 = 19 ms
			// 2 bits “Daylight” = 00 = 0x0 = 0
			// TODO IF NEEDED
			time = dataView.getInt32(36, littleEndian);
			
			date = dataView.getFloat32(40, littleEndian);
			dose = dataView.getFloat32(44, littleEndian);
			duration = dataView.getFloat32(48, littleEndian);
			batteryState = data[52];
			batteryLevel = data[53];
			systemState = dataView.getFloat32(54, littleEndian);
			measurementId = dataView.getFloat32(58, littleEndian);
			checsum = dataView.getUint16(62, littleEndian);
			
			// consolelog all
			// console.log("prefix", prefix);
			// console.log("length", length);
			// console.log("messageId", messageId);
			// console.log("state", state);
			// console.log("doseRate", doseRate);
			// console.log("countRate", countRate);
			// console.log("backgroundDoseRate", backgroundDoseRate);
			// console.log("backgroundCountRate", backgroundCountRate);
			// console.log("level", level);
			// console.log("time", time);
			// var hours = (time & 0xF8000000) >> 27;
			// var minutes = (time & 0x07C00000) >> 22;
			// var seconds = (time & 0x003F0000) >> 16;
			// var milliseconds = (time & 0x0000FFF0) >> 4;
			// var daylight = (time & 0x0000000C) >> 2;
			// console.log("\thours", hours);
			// console.log("\tminutes", minutes);
			// console.log("\tseconds", seconds);
			// console.log("\tmilliseconds", milliseconds);
			// console.log("\tdaylight", daylight);
			// console.log("date", date);
			// console.log("dose", dose);
			// console.log("duration", duration);
			// console.log("batteryState", batteryState);
			// console.log("batteryLevel", batteryLevel);
			// console.log("systemState", systemState);
			// console.log("measurementId", measurementId);
			// console.log("checsum", checsum);

			var telemetry = Telemetry.create();
			var radationData = RadationData.create();
			var gammaData:GammaData = GammaData.create();
			// https://www.unitsconverters.com/en/Microsievertperhour-To-Milliroentgenperhour/Unittounit-4376-4381
			gammaData.gammaExposureRate = Math.floor((doseRate*100)*1e7);
			gammaData.gammaTotalGrossCounts = Math.floor(countRate);
			gammaData.gammaBackgroundCountRate = Math.floor(backgroundCountRate);

			radationData.gammaData = gammaData;
			telemetry.vendorData = VendorData.create();
			telemetry.vendorData.data = {
				oneofKind: "radationData",
				radationData: radationData,
			}
			if(this.parentVMI.model.device?.uuid){
				telemetry.uuid = this.parentVMI.model.device.uuid
			}
			else{
				console.error("ISSUE: No UUID to apply to telemetry");
			}
			try {
				await this.services.geolocate?.getCurrentPosition().then((result) => {
					telemetry.longitude1E7 = result.longitude1E7;
					telemetry.latitude1E7 = result.latitude1E7;
					telemetry.elevationCm = result.elevationCm;
					telemetry.gpsScanRecord = GPSScanRecord.clone(result.gpsScanRecord);
				});
			}
			catch(e){
				console.error("Error getting GPS data", e);
			}

			if(this.services.settings?.SETTINGS.APP_ID){
				console.log("Saving to app id", this.services.settings?.SETTINGS.APP_ID, " :: ", telemetry);
				// console.log("Saving to app id", this.services.settings?.SETTINGS.APP_ID);
				// console.log("Saving Telemetry : ", telemetry);
				// console.log("Model is : ", this.parentVMI.model.device?.uuid);
				this.services.data?.saveTelemetry(this.services.settings?.SETTINGS.APP_ID, this.parentVMI, telemetry, undefined, undefined, undefined, true).then( (saved) => {
					this.parentVMI.update();
				}).catch( (err) => {
					console.log("Error saving telemetry to device", err);
					this.parentVMI.update();
				});
			}

			resolve();
		});
	}
}

export class MirionDeviceViewModelImplemented extends DeviceViewModelImplemented {

	public bluetoothConnection:MirionBluetoothConnection | null;
	public canHaveBluetoothConnection:boolean = true;
	public showTelemetryOffset:boolean = false;

	public radiationCapable:boolean = true;

	constructor
	(
		services:AllServcies,
		bluetoothConnection?:BluetoothConnection,
		model?:DeviceModel,
	)
	{
		super(services, bluetoothConnection, model);
	}

	async initalizeBluetoothConnection(disconnectFn?:()=>any) : Promise<boolean> {
		if(!this.bluetoothConnection){
			if(this.services.bluetooth && this.model.device?.uuid){
				this.bluetoothConnection = this.services.bluetooth.getDeviceConnection(this.model.device?.uuid);
				if(this.bluetoothConnection){
					this.hasBluetoothConnection = true;
					this.bluetoothConnection.parentVMI = this;
					if(this.bluetoothConnection && disconnectFn){
						console.log("Set cbb for the device disconnected")
						this.bluetoothConnection.showDisconnected = disconnectFn;
					}
				}
				else {
					// pull from local db, to see if we have a common interface stored
					var commonInterface = await this.services.data?.getByKey(this.model.device.uuid.toString()+"_btcmi") as BluetoothCommonInterface;
					if(commonInterface){
						console.log("Got bluetooth connection", commonInterface)
						this.bluetoothConnection = new MirionBluetoothConnection(this.services, commonInterface);
						this.bluetoothConnection.bluetoothCommonInterface = commonInterface;
						this.bluetoothConnection.parentVMI = this;
						this.hasBluetoothConnection = true;
						if(this.bluetoothConnection && disconnectFn){
							console.log("Set cbb for the device disconnected")
							this.bluetoothConnection.showDisconnected = disconnectFn;
						}
					}
				}
			}
			else {
				return Promise.reject("No bluetooth service or uuid");
			}
		}
		if(this.bluetoothConnection){
			await this.bluetoothConnection.initalizeDevice();
			return new Promise( async (resolve,reject)=> {
				// handle whatever needs to be done after connecting
				console.log(" :: MirionDeviceViewModelImplemented : initalizeBluetoothConnection : connected : ");
				console.log(" :: handle whatever needs to be done after connecting :")
		
				resolve(true);
			})
		}
		else {
			return Promise.reject("No bluetooth connection");
		}
	}

	public static getStaticTitle():string{ // so we can access this w/o an instance i guess
		return "Mirion AccuRad"
	}

	public getPagePath() : string {
		return super.getPagePath();
	}

	public getDisplayName() : string {
		return this.getTitle()
	}

	public getDisplayUuid(showPrefix:boolean=true) : string {
		var prefix = "SN: ";
		if(!showPrefix){
			prefix = "";
		}
		if(this.model.device?.foundName){
			return prefix+this.model.device.foundName
		}
		return prefix+"n/a";
	}

	public thumbnailImagePath:string = "assets/app/mirion/accurad.png";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public getTitle():string{ // so we can access this w/o an instance i guess
		return MirionDeviceViewModelImplemented.getStaticTitle();
	}

	public getSubTitleDescription():string{
		if(this.model.device?.serial){
			return "SN: "+this.model.device.serial
		}
		else {
			return "";
		}
	}

}