import { Injectable } from '@angular/core';

import * as Settings from '../settings/settings.json'
import { Auth, Token, } from "../../generated_proto/protobuf-ts/pb/v2/auth"

import { AllServcies } from '../startup/startup.service';
import { Person } from '../../generated_proto/protobuf-ts/pb/v2/entities';
import { PersonViewModelImplemented } from '../../viewmodels/person.vmi';


@Injectable({
	providedIn: 'root'
})
export class AuthService {

	public auth:Auth;
	public hasAuth:boolean = false;
	public isInit:boolean = false;

	private PWT_STORAGE_KEY:string='pwtAuth123'

	private personVMI:PersonViewModelImplemented;

	constructor(
	)
	{

	}

	init(allServices:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
			}

			if(JSON.parse(JSON.stringify(Settings)).APP_FORCE_SIGN_IN_AUTH == false){
				this.hasAuth = true;
				resolve(true);
				return;
			}

			if(this.services && this.services.pouch){
				this.getAuth().then( async (isAuthenticated) => {
					if(isAuthenticated){
						this.hasAuth = true;
						this.isInit = true;
						await this.getUser();
						resolve(true);
						return;
					}
					else {
						this.hasAuth = false;
						this.isInit = true;
						resolve(false)
						return;
					}
				})
				.catch( (err) => {
					this.hasAuth = false;
					this.isInit = true;
					resolve(false)
					return;
				});
			}
			// this.services.data.getByKey(this.PWT_STORAGE_KEY).then( (pwtJsonString) => {
			// 	if(pwtJsonString){
			// 		const retrievedArr = JSON.parse(pwtJsonString);
			// 		const retrievedTypedArray = new Uint8Array(retrievedArr);
			// 		this.auth = Auth.deserializeBinary(retrievedTypedArray);
			// 	}
			// 	this.isInit = true;
			// 	resolve(true);
			// }).catch( () => {
			// 	this.isInit = true;
			// 	resolve(false)
			// })
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}
	

	public login( username:string, password:string) : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			if(!this.services){
				console.error("bad services");
				reject("services")
			}
			if(this.services && this.services.httpApiClient){
				this.services.httpApiClient.login(username, password).then( async (tokenString:string) => {
					await this.logout();
					var newAuth:Auth = Auth.create();
					var token:Token = Token.create();
					token.token = tokenString;
					newAuth.method = {
						oneofKind: "token",
						token: token,
					};
					this.auth = newAuth;
					console.log("SET NEW AUTH !!!!!! ::: ", this.auth)
					await this.getUser();
					await this.setAuth(newAuth);
					this.hasAuth = true;
					if(this.services && this.services.menu) this.services.menu.update.next(true);
					// TODO :: Hash and save password if on mobile device
					resolve(true)
				}).catch( (err) => {reject(err)});;
			}
		});
	}

	public isUnused(username:string) : Promise<boolean> {
		return new Promise((resolve, reject)=>{
			if(this.services && this.services.pouch){
				this.services.pouch.checkUsername(username).then( (found)=> {
					console.log("Found: ", found);
					resolve(true);
				})
				.catch( (e) => {
					if(e.code == 1){
						resolve(true);
					}
					else{
						reject({code:1*10 + e.code, message:"GunDB Error: "+e.message});
					}
				});
			}
		});
	}

	public getUser() : Promise<PersonViewModelImplemented> {
		return new Promise( async (resolve, reject) => {
			try{
				if(this.personVMI){
					resolve(this.personVMI);
					return;
				}
				this.personVMI = new PersonViewModelImplemented(this.services);

				var user = await this.services.httpApiClient?.getUser();
				if(user){
					this.personVMI.setPerson(user);
					resolve(this.personVMI);
				}
			}
			catch(e){
				reject(e);
				return;
			}
		});
	}

	udpateUser(person:Person) : Promise<Person> {
		return new Promise( async (resolve, reject) => {
			try{
				var user = await this.services.httpApiClient?.updateUser(person);
				if(user){
					this.personVMI.setPerson(user);
					resolve(user);
				}
				else {
					throw "No User Returned";
				}
			}
			catch(e){
				reject(e);
				return;
			}
		});
	}

	public createUser( username:string, password?:string) : Promise<string> {
		console.log("auth createUser")
		if(!this.services || !this.services.httpApiClient || !this.services.settings?.SETTINGS.APP_ID){
			return Promise.reject("getUsers:no services");
		}
		if(this.services && this.services.httpApiClient) return this.services.httpApiClient.createUser(this.services.settings.SETTINGS.APP_ID, username, password)
		return Promise.reject("createUser:no services");
	}

	public getUsers( ) : Promise<Person[]> {
		if(!this.services || !this.services.httpApiClient || !this.services.settings?.SETTINGS.APP_ID){
			return Promise.reject("getUsers:no services");
		}
		if(this.services && this.services.httpApiClient) return this.services.httpApiClient.getUsers(this.services.settings.SETTINGS.APP_ID)
		return Promise.reject("createUser:no services");
	}

	public async setAuth( auth:Auth ) {
		this.auth = auth;
		this.hasAuth = true;
		const str = Auth.toJsonString(auth);
		if(this.services.data){
			await this.services.data.saveByKey(this.PWT_STORAGE_KEY, str).then((saved) => {
			}).catch((e)=>{
				console.error("Auth save error: ", e);
			});
		}
	}

	// public getAuth() : Auth{
	public getAuth() : Promise<Auth> {
		return new Promise( async (resolve, reject) => {
			if(this.hasAuth && this.isInit == true){
				resolve(this.auth);
			}
			else {
				await this.services.data?.getByKey(this.PWT_STORAGE_KEY).then( (pwtJsonString) => {
					if(pwtJsonString){
						const retrieved = JSON.parse(pwtJsonString);
						this.auth = Auth.fromJson(retrieved);
						this.hasAuth = true;
						resolve(this.auth);
					}
					else{
						reject("no auth stored");
					}
				}).catch( () => {
					reject("no auth found");
					return;
				});
			}
		});
	}

	public clearAuth(){
		if(!this.services || !this.services.pouch){
			return;
		}
		this.services.httpApiClient?.logout();
		this.hasAuth = false;
		
		this.services.data?.deleteByKey(this.PWT_STORAGE_KEY).then( (deleted) => {
		}).catch( (e) => {
			console.error("Error deleting key: ", e);
		});
		// this.services.pouch.logout().then( (done) => {
		// 	if(done){
		// 		if(this.services && this.services.data) this.services.data.deleteByKey(this.PWT_STORAGE_KEY);
		// 		this.hasAuth = false;
		// 	}
		// }).catch( (err) => {
		// 	console.error("Logout Error: ", err);
		// });
	}

	public logout(){
		this.clearAuth();
	}

	private isTokenExpired() : boolean
	{
		if(this.hasAuth == false){
			return true;
		}
		// TODO :: validate the token locally before we start using the token
		// IE :: If already expried, then we shouldn't use it and jump straight to login
		return false;
	}

	public async isChannelJoined() : Promise<boolean> {
		// Get token from storage if we havent
		// NOTE :: Combined with gun, storage wont retreive any keys.
		// It wont throw errors, but reterival will always come up empty, IE: pwtJsonString
		return new Promise( (resolve,reject) => {
			if(!this.services || !this.services.proto){
				reject("auth:isAuthenticated:bad services")
				return;
			}
			console.log("isChannelJoined: ", this.services.proto.getChannelViewModel().joined);
			resolve(this.services.proto.getChannelViewModel().joined);
		});
	}

	public async isAuthenticated() : Promise<boolean> {
		// Get token from storage if we havent
		// NOTE :: Combined with gun, storage wont retreive any keys.
		// It wont throw errors, but reterival will always come up empty, IE: pwtJsonString
		return new Promise( (resolve,reject) => {
			if(JSON.parse(JSON.stringify(Settings)).APP_FORCE_SIGN_IN_AUTH == false){
				this.hasAuth = true;
				resolve(true);
				return;
			}
			this.getAuth().then( (isAuthenticated) => {
				if(isAuthenticated){
					this.hasAuth = true;
					resolve(true);
				}
				else {
					resolve(false);
				}
			}).catch( () => {
				resolve(false)
			})
			this.isInit = true;
		});
	}
}


// https://stackoverflow.com/questions/52419694/how-to-store-uint8array-in-the-browser-with-localstorage-using-javascript
