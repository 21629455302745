import { Asset, Asset_DeviceAssetConnection } from '../generated_proto/protobuf-ts/pb/v2/entities';
import { AssetViewModel, AssetModel, DataBase, ModelType, } from '../generated_proto/protobuf-ts/pb/v2/models'
import { DataService } from '../services/data/data.service';
import { LoggerOptions } from '../services/logger/logger.service';
import { AllServcies } from '../services/startup/startup.service';
import { BluetoothBeaconViewModelImplemented } from './BluetoothBeacon.vmi';
import { ViewModelImplemented } from "./ViewModelImplemented";


export class AssetViewModelImplemented extends ViewModelImplemented implements AssetViewModel {

	model?: AssetModel;

	isOnline: boolean;
	isLocal: boolean;
	hasPosition: boolean;

	public seenByVMIs:any[] = []; //BluetoothBeaconViewModelImplemented or subclasess of

	public loggerOptions:LoggerOptions = {
		prefix:"AssetViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};
	
	constructor
	(
		services:AllServcies,
	)
	{
		super();
		this.model = AssetModel.create();
		this.model.asset = Asset.create();
		if(services){
			this.services = services;
		}
	}

	public hydrateDeviceVMIs(){
		this.seenByVMIs = [];
		if(this.model?.asset?.deviceAssetConnections){
			for(let i = 0; i < this.model.asset.deviceAssetConnections.length; i++){
				var beaconVMI = this.services.data?.getDeviceByUrn(this.services.settings?.SETTINGS.APP_ID, this.model.asset.deviceAssetConnections[i].deviceUrn);
				if(beaconVMI){
					this.seenByVMIs.push(beaconVMI);
				}
			}
		}
	}

	updateBeaconsToModel(){
		if(this.seenByVMIs.length > 0 && this.model?.asset){
			for(let i = 0; i < this.seenByVMIs.length; i++){
				let found = false;
				for(let j = 0; j < this.model.asset.deviceAssetConnections.length; j++){
					if(this.model.asset.deviceAssetConnections[j].deviceUrn == this.seenByVMIs[i].model?.db?.urn){
						found = true;
					}
					if(!this.model.asset.deviceAssetConnections[j].startEpoch || this.model.asset.deviceAssetConnections[j]?.startEpoch == 0n){

					} 
				}
				if(!found){
					if(this.seenByVMIs[i].model?.db?.urn){
						var deviceConnection:Asset_DeviceAssetConnection = Asset_DeviceAssetConnection.create();
						deviceConnection.deviceUrn = this.seenByVMIs[i].model?.db?.urn;
						deviceConnection.startEpoch = BigInt(Date.now());
						this.model.asset.deviceAssetConnections.push(deviceConnection);
					}
				}
			}
		}
	}

	modelToJson(){
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		if(this.model){
			return AssetModel.toJson(this.model);
		}
	}
	setModel(deviceModel_pb:AssetModel) : AssetViewModelImplemented {
		if(deviceModel_pb){
			this.model = deviceModel_pb;
		}
		return this;
	}
	updateLocalFromDb(json:any){
		if(json){
			this.setModel(AssetModel.fromJson(json, {ignoreUnknownFields: true}));
		}
	}
	private static PB_VERSION = 2;
	static GenerateDbId(dbPrefix:string) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return dbPrefix + ModelType.ASSET_MODEL;
	}
	static GenerateDbKey(uuid:bigint) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return DataService.getKeyPrefix(ModelType.ASSET_MODEL,AssetViewModelImplemented.PB_VERSION)+ uuid.toString();
	}
	public generateDbKey() : string {
		if(this.model?.asset?.uuid ){
			return AssetViewModelImplemented.GenerateDbKey(this.model.asset.uuid);
		}
		return "";
	}
	static GenerateURN(dbPrefix:string, asset:Asset) : string {
		if(asset){
			var dbid = this.GenerateDbId(dbPrefix);
			return dbid+"/"+this.GenerateDbKey(asset.uuid);
		}
		console.error("generateURN: No UUID");
		return "";
	}
	public generateURN() : string {
		if(this.model && this.model.asset && this.services.data?.getDbPrefix()){
			return AssetViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.model.asset);
		}
		return "";
	}
	updateDb(db?:DataBase){
		if(!this.model){
			this.model = AssetModel.create();
		}
		if(!this.model.db){
			this.model.db = DataBase.create();
		}
		if(db){
			try{
				DataBase.mergePartial(this.model.db, db);
			}
			catch(err){
				console.error("updateDb: Failed to merge db: "+err);
			}
		}
		if( this.model.db.createdMs && this.model.db.createdMs <= 0){
			this.model.db.createdMs = BigInt(Date.now());
		}
		if(!this.model.asset?.uuid){
			if(this.model.asset?.deviceAssetConnections && this.model.asset.deviceAssetConnections.length > 0){
				// use the first beacon's uuid
				console.log("Using beacon uuid for asset: ", this.model.asset.deviceAssetConnections[0].deviceUrn.split(","));
				this.model.asset.uuid = BigInt(this.model.asset.deviceAssetConnections[0].deviceUrn.split(",")[3]);
			}
			else{
				// generate a random uuid from the name and timestamp
				var name = this.model.asset?.name || "";
				name += Date.now(); // add timestamp to name to make it unique
				// make random uint from name
				var hash = 0;
				for (var i = 0; i < name.length; i++) {
					hash = name.charCodeAt(i) + ((hash << 5) - hash);
				}
				this.model.asset!.uuid = BigInt(hash);
			}
		}
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.ASSET_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}

	save(app_id?:number) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(!app_id){
				app_id = this.services.settings?.SETTINGS.APP_ID;
			}
			this.updateDb();
			// iterate through beaconVMIs
			for (let index = 0; index < this.seenByVMIs.length; index++) {
				const beacon = this.seenByVMIs[index] ;
				beacon.model.device.primaryAssetUrn = this.model?.db?.urn;
				await beacon.save(app_id).catch((err)=>{
					console.error("save: Failed to save beacon: "+err);
					reject(err);
				});
			}
			this.updateBeaconsToModel();
			if(this.services.data){
				await this.services.data.saveAsset(app_id||2, this).then( (result:any) => {
					resolve(true);
					return;
				}).catch( (err) => {
					console.error("save: Failed to save asset: "+err);
					reject(err);
				});
			}
			if(this.services.logger) this.services.logger.error(this.loggerOptions, "save: DataService missing");
			reject(this.loggerOptions.prefix+":DataService missing");
			return;
		});
	}

	addBeaconVMI(beaconVMI:BluetoothBeaconViewModelImplemented){
		// add if not in list by urn
		let found = false;
		for(let i = 0; i < this.seenByVMIs.length; i++){
			if(this.seenByVMIs[i].model?.db?.urn == beaconVMI.model?.db?.urn){
				found = true;
			}
		}
		if(!found){
			this.seenByVMIs.push(beaconVMI);
			this.updateBeaconsToModel();
		}
		console.log("Added beacon: ", beaconVMI, " to asset: ", this.seenByVMIs, "")
	}

	public static getStaticTitle():string{ // so we can access this w/o an instance i guess
		return "Asset"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return AssetViewModelImplemented.getStaticTitle();
	}

	public getDisplayName() : string {
		if(this.model?.asset){
			if(this.model.asset.name) return this.model.asset.name
		}
		return this.getTitle()
	}

	public getDisplayUuid() : string {
		if(this.model?.asset?.uuid){
			return "UUID: "+this.model.asset.uuid
		}
		return "UUID: n/a";
	}

	public goToPage() {
		// this.services.router.navigateByUrl(this.getPagePath());
		if(this.services.nav) this.services.nav.navigateForward(this.getPagePath());
	}
	public getPagePath() : string {
		if(this.model?.asset){
			return "vendors/default/default-asset/" + this.model.asset.uuid;
		}
		return "vendors/default/default-asset/" + 0;
	}

	public ionItemDescription: any;
	public getIonItemDescription(): any {
		if(this.ionItemDescription == null){
			this.ionItemDescription = this.buildIonItemDescription();
		}
		return this.ionItemDescription;
	}

	public buildIonItemDescription(): any {
		var html = `
			<div>
				<ion-label> 
					<div class="ion-label-title">${this.getDisplayName()}</div> 
					<div class="ion-label-body">
						${this.getDisplayUuid()}<br>
						Created: ${new Date(Number(this.model?.db?.updatedMs||0)).toLocaleDateString()} ${new Date(Number(this.model?.db?.updatedMs||0)).toLocaleTimeString()}
					</div>
				</ion-label>
			</div>
			`;

		if(this.services.domSanitizer) this.ionItemDescription = this.services.domSanitizer.bypassSecurityTrustHtml(html);
		return this.ionItemDescription;
	}

}