
import { AllServcies } from "../services/startup/startup.service"
import { ChannelViewModel } from '../generated_proto/google/app/viewmodel/v1/app_pb';

import {BehaviorSubject, } from 'rxjs';

import { Channel, ChatMessage, PRIVACY } from '../generated_proto/google/app/model/v1/data_pb'
export namespace ChannelViewModelImplemented {
	export type AsObject = {
		protobuf?:ChannelViewModel.AsObject,
		joined?:boolean,
	}
}

export class ChannelViewModelImplemented extends ChannelViewModel {

	private log_prefix:string = " :: ChannelViewModelImplemented : ";
	private services:AllServcies;

	public sub: BehaviorSubject<boolean> = new BehaviorSubject(true);

	// Since these objects are created at runtime, they need to be passed references to the Services
	constructor(
		services:AllServcies,
		channel?:ChannelViewModel,
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		// Can pass as param, not used in this case but would
		// be useful behavior for sub classes
		this.services = services;
		if(channel){
			this.update(channel)
		}
	}

	public update(update:ChannelViewModel) {
		// Update our local from a grpc response
		if(update.getChannel()){
			this.setChannel(update.getChannel());
		}
		Object.assign(this, update);
	}

	public updateMessages( update_message:ChatMessage[]) {
		this.setMessagesList(update_message)
	}

	public toViewObject() : ChannelViewModelImplemented.AsObject {
		return {
			protobuf : this.toObject(),
			joined: this.joined,
		}
	}

	public checkExists( channel_name:string ) : Promise<PRIVACY> {
		if(!this.services){
			console.error("Services doesnt exist");
		}
		if(this.services.pouch) return this.services.pouch.checkChannelExists(channel_name)
		return Promise.reject("Pouch Service Not Set");
	}

	public create( channel:Channel ) : Promise<boolean>{
		if(this.services.pouch){
			return this.services.pouch.createChannel(channel);
		}
		return Promise.reject("Pouch Service Not Set");
	}

	public joined:boolean = false;
	joinChannel( join_channel:Channel ) : Promise<boolean>{
		return new Promise( (resolve, reject) => {
			if(this.joined){
				reject({code:0, message:"Channel is already joined"});
				return;
			}
			if(join_channel.getName().length<=0){
				reject({code:1, message:"Channel Name is invalid"});
				return;
			}

			// Keeping this abstracted here : Allows us to join via gRPC or another mechanism later ...
			if(this.services.pouch){
				this.services.pouch.joinChannel(join_channel).then( (pb_channel:ChannelViewModel) => {
					if(pb_channel){
						this.joined = true;
						if(this.services.menu) this.services.menu.update.next(true);
						this.update(pb_channel)
						resolve(true);
					}
					else {
						reject({code:2, message:"PB Channel Missing"});
					}
				})
				.catch( (e) => {
					reject({code:3*10+e.code, message:"Internal Error:"+e.error});
				});
			}
			else {
				reject("Pouch Service Not Set");
			}
		});
	}


	public leaveChannel() : Promise<boolean>{
		console.log(" Channel leaving channel");
		return new Promise( (resolve, reject) => {
			if(!this.joined){
				resolve(true);
				return;
			}
			if(this.services.pouch){
				this.services.pouch.leaveChannel(this).then( (success) => {
					if(success){
						console.log(":: Left the channel!");
						this.joined = false;
						if(this.services.menu) this.services.menu.update.next(true);
						return;
					}
					else {
						reject({code:0, message:"Gundb Leave channel"})
					}
				}).catch( (e) => {
					console.error(e);
					reject(e)
				});
			}
			else {
				reject("Pouch Service Not Set");
			}
		});
	}


	sendMessage( message:ChatMessage) : Promise<boolean>{
		if(this.services.pouch) return this.services.pouch.sendChannelMessage(this, message);
		return Promise.reject("Pouch Service Not Set");
	}


	refresh() {
		// Update our local UI
		// This is where you can handle any UI specific bindings from the model's data 
	}

	public streaming:boolean = false;
	stream() : Promise<boolean> {
		// console.log(this.log_prefix, "Calling Stream");

		// Subscribe to the nessiary data sourcse to build the channel view
		// This might be more than one service/source  (ie: sub to mqtt, and gun or whatever)

		if(this.services.pouch) return this.services.pouch.streamChannel(this)
		return Promise.reject("Pouch Service Not Set");

	}


}	