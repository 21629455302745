import { AllServcies, } from "../../services/startup/startup.service";
import { LoggerOptions } from "../../services/logger/logger.service";
import { DataService } from "../../services/data/data.service";

import { DataBase, GeoNodeModel, GeoNodeViewModel, ModelType, } from "../../generated_proto/protobuf-ts/pb/v2/models";
import { GeoNode, GeoNodeType } from "../../generated_proto/protobuf-ts/pb/v2/entities";

import { ViewModelImplemented, } from "../ViewModelImplemented";
import { SiteViewModelImplemented } from "./site.vmi";
import { FloorViewModelImplemented } from "./floor.vmi";
import { RoomViewModelImplemented } from "./room.vmi";
import { AreaViewModelImplemented } from "./area.vmi";
export type AllGeoNodeImplemented = GeoNodeViewModelImplemented | SiteViewModelImplemented | FloorViewModelImplemented | AreaViewModelImplemented | RoomViewModelImplemented;
export class GeoNodeViewModelImplemented extends ViewModelImplemented implements GeoNodeViewModel {

	model?: GeoNodeModel;
	public loggerOptions: LoggerOptions = {
		prefix: "GeoNodeViewModelImplemented",
		allOn: true,
		verboseOn: true,
		debugOn: true,
	};

	constructor(
		services: AllServcies,
	) {
		super();
		this.services = services;
		this.model = GeoNodeModel.create();
		this.model.geoNode = GeoNode.create();
	}

	public formattedName: string;

	save(app_id?: number): Promise<boolean> {
		if (!app_id) {
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		//only generated a new uuid if we dont have one
		if (!this.model?.geoNode?.uuid || this.model?.geoNode?.uuid < 0) {
			// generate a random uuid from the name and timestamp
			var name = this.model?.geoNode?.name || "";
			name += Date.now(); // add timestamp to name to make it unique
			// make random uint from name
			var hash = 0;
			for (var i = 0; i < name.length; i++) {
				hash = name.charCodeAt(i) + ((hash << 5) - hash);
			}
			if (this.model && this.model.geoNode) {
				this.model.geoNode.uuid = BigInt(hash);
			}
		}
		console.log("save: ", this.model, this.modelToJson());
		if (this.services.data) return this.services.data.saveGeoNode(app_id || 2, this);
		if (this.services.logger) this.services.logger.error(this.loggerOptions, "save: DataService missing");
		return Promise.reject(this.loggerOptions.prefix + ":DataService missing");
	}
	async delete(app_id?: number): Promise<boolean> {
		if (!app_id) {
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		this.services.logger?.debug(this.loggerOptions, "delete: AppID: " + app_id + " ::model: " + this.model?.geoNode?.uuid);
		if (this.services.data) return this.services.data?.deleteGeoNode(app_id || 2, this);
		if (this.services.logger) this.services.logger.error(this.loggerOptions, "delete: DataService missing");
		return Promise.reject(this.loggerOptions.prefix + ":DataService missing");
	}

	modelToJson() {
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		if (this.model) {
			console.log("this.model.geoNode?.uuid: ", this.model);
			return GeoNodeModel.toJson(this.model);
		}
	}

	private static PB_VERSION = 2;
	static GenerateDbId(dbPrefix: string): string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return dbPrefix + ModelType.GEONODE_MODEL;
	}
	static GenerateDbKey(uuid: bigint): string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return DataService.getKeyPrefix(ModelType.GEONODE_MODEL, GeoNodeViewModelImplemented.PB_VERSION) + uuid.toString();
	}
	public generateDbKey(): string {
		if (this.model?.geoNode?.uuid) {
			return GeoNodeViewModelImplemented.GenerateDbKey(this.model.geoNode.uuid);
		}
		return "";
	}
	static GenerateURN(dbPrefix: string, geoNode: GeoNode): string {
		if (geoNode) {
			var dbid = this.GenerateDbId(dbPrefix);
			return dbid + "/" + this.GenerateDbKey(geoNode.uuid);
		}
		console.error("generateURN: No UUID");
		return "";
	}
	public generateURN(): string {
		if (this.model && this.model.geoNode && this.services.data?.getDbPrefix()) {
			return GeoNodeViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.model.geoNode);
		}
		return "";
	}
	updateDb(db?: DataBase) {
		if (!this.model?.db) {
			if (!this.model) {
				this.model = GeoNodeModel.create();
			}
			this.model.db = DataBase.create();
		}
		if (db) {
			try {
				DataBase.mergePartial(this.model.db, db);
			}
			catch (err) {
				console.error("updateDb: Failed to merge db: " + err);
			}
		}
		if (this.model.db.createdMs && this.model.db.createdMs <= 0) {
			this.model.db.createdMs = BigInt(Date.now());
		}
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.GEONODE_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}

	jsonToModel(json: any) {
		if (json) {
			this.model = GeoNodeModel.fromJson(json, { ignoreUnknownFields: true });
		}
	}

	public getName() {
		return this.model?.geoNode?.name || "No Name";
	}

	public getUUID() {
		return this.model?.geoNode?.uuid || BigInt(0);
	}
	public getURN() {
		return this.generateURN();
	}
	public getDescription() {
		return this.model?.geoNode?.description || "";
	}

	public setDescription(description: string | undefined) {
		if (this.model && this.model.geoNode) {
			this.model.geoNode.description = description || "";
		}
	}
	public setName(name: string | undefined) {
		if (this.model && this.model.geoNode) {
			this.model.geoNode.name = name || "";
		}
	}

	public getType() {
		if (this.model && this.model.geoNode) {
			return this.model.geoNode.type;
		}
	}
	public setType(type: GeoNodeType) {
		if (this.model && this.model.geoNode) {
			this.model.geoNode.type = type;
		}
	}
	private sortPoints(points: number[][][]): number[] {
		const sortedPoints = points[0].sort((a, b) => {
			if (a[0] === b[0]) {
				// If longitude coordinates are equal, sort by latitude coordinate (bottom to top)
				return a[1] - b[1];
			} else {
				// Otherwise, sort by longitude coordinate (left to right)
				return a[0] - b[0];
			}
		});
		const left = sortedPoints[0][0];
		const right = sortedPoints[sortedPoints.length - 1][0];
		const top = sortedPoints[sortedPoints.length - 1][1];
		const bottom = sortedPoints[0][1];
		console.log(left, right, top, bottom);

		return [left, bottom, right, top];
	}
	public getCoords(): number[] {
		// console.log(this.model?.geoNode)
		if (this.model?.geoNode?.geoJson && this.model.geoNode.geoJson) {
			var parse = JSON.parse(this.model.geoNode.geoJson);
			var coords = parse["features"][0]["geometry"]["coordinates"]
			console.log(parse["features"][0]["geometry"]["coordinates"]);
			return this.sortPoints(coords);
		}
		else {
			return [];
		}

	}

	getUpdatedMs() {
		return this.model?.db?.updatedMs || BigInt(0);
	}

	public setImageUrl(url: string) {
		console.log(this.model, this.model?.geoNode, url);
		if (this.model && this.model.geoNode) {
			this.model.imageUrl = url;
		}
	}
	public getImageUrl() {
		return this.model?.imageUrl || "";
	}

	public deleteGeoNode() {
		if (this.model && this.model.geoNode) {
			this.model.geoNode = undefined;
		}
	}
}
