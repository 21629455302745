
import { BluetoothCommonInterface } from "../../services/bluetooth/bluetooth.service";
import { AllDevicesVMI } from "../../viewmodels/AllDevices.vmi";

import { Device, } from "../../generated_proto/protobuf-ts/pb/v2/entities"
import { AllServcies } from "../../services/startup/startup.service";
import { PlBeaconBluetoothConnection, PlBeaconDeviceViewModelImplemented } from "./pl-beacon.vmi";
import { DeviceTypeIds } from "../../viewmodels/Device.vmi";
import { EncryptionService } from "../../services/encryption/encryption.service";

export class PlBluetooth {
	
	constructor
	(
		private allServices:AllServcies,
	)
	{
		if(allServices){
			this.setServices(allServices);
		}
	}

	public services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getSupportedDevices() : AllDevicesVMI[] {
		if(this.services.settings?.SETTINGS.APP_ID){
			if(this.services.settings.SETTINGS.APP_ID != 8){
				return [];
			}
		}
		var possibleVMIs:AllDevicesVMI[] = [];
		possibleVMIs.push(new PlBeaconDeviceViewModelImplemented(this.services, new PlBeaconBluetoothConnection(this.services, {name:"PL"})));
		console.log("PlBluetooth : getSupportedDevices", possibleVMIs);
		return possibleVMIs;
	}

	public async makeDeviceWithType(device: BluetoothCommonInterface): Promise<AllDevicesVMI | undefined> {
		return new Promise( async (resolve,reject) => {
			if(device.name !== undefined || device.name !== null || device.name != "unknown"){
				var name_filter = "pl";
				if(device.name && device.name.toLowerCase().includes(name_filter)){
					if(device.identifier){
						var blc = new PlBeaconBluetoothConnection(this.services, device)
						var newDevice = new PlBeaconDeviceViewModelImplemented(this.services);
						newDevice.isLocal = true;
						newDevice.isTemporary = true;
						var deviceModel = Device.create();
						// console.log("Found and new deviceVMI: Calling to set BLUETOOTH CONNECTION :: Raw", device.manufactureData);
						if(device.manufactureData){
							// console.log("Found and new deviceVMI: Calling to set BLUETOOTH CONNECTION :: HEX", EncryptionService.toHexString(device.manufactureData));
							var result = await this.services.bluetooth?.parseManufacturerData(device.manufactureData)
							// console.log("Found and new deviceVMI: Calling to set BLUETOOTH CONNECTION :: PARSED", result);
							
							///////////////////////////////////////////////////////
							// THIS IS AN ISSUE
							///////////////////////////////////////////////////////
							// These numbers are NOT matching the mac address pattern we'd expect from the pager firmware code
							///////////////////////////////////////////////////////
							if(result?.appleAdvertisement){
								if(result.appleAdvertisement.iBeacon){
									if(result.appleAdvertisement.iBeacon.iBeaconUUID){
										// console.log("iBeaconUuid : 0x", EncryptionService.toHexString(result.appleAdvertisement.iBeacon.iBeaconUUID));
									}
									if(result.appleAdvertisement.iBeacon.iBeaconMajor){
										// console.log("iBeaconMajor : 0x", result.appleAdvertisement.iBeacon.iBeaconMajor.toString(16));
									}
									if(result.appleAdvertisement.iBeacon.iBeaconMinor){
										// console.log("iBeaconMinor : 0x", result.appleAdvertisement.iBeacon.iBeaconMinor.toString(16));
									}
								}
							}
						}
						// Possible : connect and get the mac address here -> build a unique id to connect to
						// await blc.connect().then( () => {
						// 	console.log("Device is connected")
						// }).catch( (e) => {
						// 	console.error("Device failed to connect : ", e);
						// });

						// To look this up in the db in any capacity, we need to know the ID before we could try and go look it up
						// With web, we have no other data to base this off of other than the name (any advert is hidden)
						// OR - connect and ask the device for some more information
						deviceModel.uuid = BigInt(0); // <======= issue here
						if(device.name.toLowerCase() == "pl"){
							resolve(undefined);
							return;
						}
						var nameToId = device.name.toLowerCase();
						// remove pl from the name
						nameToId = nameToId.replace("pl", "");
						if(nameToId.length == 4){
							// convert hex string to number
							var hex = parseInt(nameToId, 16);
							if(hex == 0x0000){
								resolve(undefined);
								return;
							}
							deviceModel.uuid = BigInt(hex);
							deviceModel.deviceType = DeviceTypeIds.PL_BLUETOOTH_BEACON_V2; // doesnt really matter since not pulling it out of a db and casting back.
							// console.log("Found and new deviceVMI: Calling to set BLUETOOTH CONNECTION :: nameToId :: "+nameToId+" ::HEX", hex);
						}
						else {
							deviceModel.deviceType = DeviceTypeIds.PL_BLUETOOTH_BEACON; // doesnt really matter since not pulling it out of a db and casting back.
							// console.log("Found and new deviceVMI: Calling to set BLUETOOTH CONNECTION :: nameToId :: "+nameToId+" ::HEX", "INVALID LENGTH");
						}

						deviceModel.name = "Pure Locate Asset";
						deviceModel.foundName = device.name;
						newDevice.setDevice(deviceModel);
						newDevice.setBlueToothConnection(blc);
						resolve(newDevice);
						return;
					}
				}
				else {
					// console.log("PlBluetooth : makeDeviceWithType : No device name found");
					resolve(undefined);
					return;
				}
			}
		})
	}
}

// 30364928
// Found and exisiting to deviceVMI:  1661035124194:312
// ADD AND GO TO BLUETOOTH ::  1661035124194:312