import { BehaviorSubject } from "rxjs";

import { AllServcies } from "../services/startup/startup.service"

import { SensorViewModel, SensorViewModelRequest, } from '../generated_proto/google/app/viewmodel/v1/app_pb';
import { TelemetryViewModelImplemented, } from "./Telemetry.vmi"

import { GPSPosition, Sensor, SensorType, } from '../generated_proto/google/app/model/v1/data_pb'
export { SensorType, }

import { LoggerOptions, } from "../services/logger/logger.service"

import { BluetoothConnection,} from "../services/bluetooth/ble.connetion"
import { SerialPortConnection, } from "../services/comport/serialport.connection"

import { MapDataPoint, MapTooltip, } from "../services/map/map.interfaces";
import { RGBAColor, } from "@deck.gl/core";
import { LogViewModelImplemented } from "./Log.vmi";

export namespace SensorViewModelImplemented {
	export type AsObject = {
		protobuf?:SensorViewModel.AsObject,
		lastUpdatedTimeFormatted?:string,
	}
}
export class SensorViewModelImplemented extends SensorViewModel {

	public loggerOptions:LoggerOptions = {
		prefix:"SensorsViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	public bluetoothConnection:BluetoothConnection;
	public serialPortConnection:SerialPortConnection;

	public services:AllServcies;
	public kaitaiHistory:any[] = [];
	public allTelemetry: TelemetryViewModelImplemented[] = [];
	public subjectUpdate = new BehaviorSubject(false);
	public rawData:any = [];
	public currentFlatTelemetry:any = {};
	public allTelemetryFlat : string[] = [];

	// Since these objects are created at runtime, they need to be passed references to the Services
	constructor
	(
		services:AllServcies,
		bluetoothConnection?:BluetoothConnection,
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		this.setSensor(new Sensor())
		if(services){
			this.services = services;
		}
		if(bluetoothConnection != null){
			this.bluetoothConnection = bluetoothConnection;
			// this.bluetoothConnection.parentVMI = this;
		}
	}

	update( update? : SensorViewModelImplemented ) {
		this.services.logger?.verbose(this.loggerOptions, "Sensor getting update : "+ Date.now());
		// Update our local from a internet response

		if(update){
			console.error("update: V2 PB UPDATE REQUIRED")
			// if(update.getSensor().getIsLocal()){
			// 	// send to gun db.
			// }
	
			// if(update.getTotalTelemetryCount()){
			// 	this.setTotalTelemetryCount(update.getTotalTelemetryCount())
			// }
			// if(update.getSensor()){
			// 	if(this.getSensor()){
			// 		this.setSensor(Object.assign(this.getSensor(), update.getSensor()));
			// 	}
			// 	else {
			// 		this.setSensor(update.getSensor());
			// 	}
			// 	if(update.getTelemetryVMIList().length>0){
			// 		// this.addSensorUpdate(update.getSensor())
			// 		this.allTelemetry = update.getTelemetryVMIList();
			// 	}
			// }
		}

		// Sort in the right order
		this.allTelemetry.sort((a, b) => {
			return b.getBestEpoch() - a.getBestEpoch();
		});


		//TODO: FIX THIS, is not running at the right times for the UI to get it. 
		// Run all Formarters
		if( this.allTelemetry.length > 0 ){
			this.currentFlatTelemetry = this.formatCurrentFlatTelemetry();
			console.log("currentFlatTelemetry", this.currentFlatTelemetry);
			this.subjectUpdate.next(true);
		}
		else
		{
			console.log("No telemetry to format");
		}
		// format timestamp


	}

	setLoggerOptions( loggerOptions:LoggerOptions ){
		this.loggerOptions = loggerOptions;
	}

	setSensor(value?: Sensor): SensorViewModel {
		super.setSensor(value) // be sure to call the base class
		// then we extend it by calculating the missing info
		console.error("setSensor: V2 PB UPDATE REQUIRED")
		// if(value.getColor().length == 0){

			// var color = "#" + Math.floor( Math.abs( Math.sin(this.getSensor().getUuid() || 0) * 16777215 ) % 16777215).toString(16).padStart(6, "0")
			// this.getSensor().setColor(color);
		// }
		return this;
	}

	initalizeBluetoothConnection( selecteFile:any = null) : Promise<boolean> {
		// NOTE :: This code shouldn't be run, but depenedent on the underling sensor
		this.services.logger?.warn(this.loggerOptions, "!! Not calling sensor's implemented !!");
		
		return new Promise( (resolve, reject ) => {
			if(selecteFile!=null){
				this.services.logger?.warn(this.loggerOptions, "Opening Comport with File data");
			}
			return this.bluetoothConnection.initalizeDevice();
		});
	}

	initalizeSerialPort( selecteFile:any = null) : Promise<boolean> {
		// NOTE :: This code shouldn't be run, but depenedent on the underling sensor
		this.services.logger?.warn(this.loggerOptions, "!! Not calling sensor's implemented !!");
		return new Promise( (resolve, reject ) => {
			if(selecteFile!=null){
				this.services.logger?.warn(this.loggerOptions, "Opening Comport with File data");
			}
			return this.serialPortConnection.connect(115200, selecteFile);
		});
	}

	getTelemetryVMIList( nonZeroPosition:boolean=false ) : TelemetryViewModelImplemented[] {

		if(nonZeroPosition){
			return this.allTelemetry.filter((t:TelemetryViewModelImplemented) => t.hasPosition );
		}
		else {
			return this.allTelemetry;
		}
	}

	public flattenJSON(obj={}, res = {}, extraKey = '') {
		var keys = Object.keys(obj);
		for (let index = 0; index < keys.length; index++) {
			const key = keys[index];
			if(typeof obj[key] !== 'object'){
				res[extraKey + key] = obj[key];
			}else{
				this.flattenJSON(obj[key], res, `${extraKey}${key}.`);
			};
		}
		return res;
	};

	formatCurrentFlatTelemetry() : TelemetryViewModelImplemented {
		console.error("formatCurrentFlatTelemetry: V2 PB UPDATE REQUIRED")
		return new TelemetryViewModelImplemented();
		// if( this.allTelemetry.length>0){
		// 	var viewObject = this.allTelemetry[0].toViewObject();
		// 	var flatproto = this.flattenJSON(viewObject.protobuf);
		// 	var flattened = this.flattenJSON(viewObject.protobuf.gatewayData);

		// 	this.currentFlatTelemetry = {...this.currentFlatTelemetry, ...flatproto, ...flattened};
		// 	return this.currentFlatTelemetry;
		// }
	}

	formatFlatTelemetry(telemetries: TelemetryViewModelImplemented[]) {
		// var data_object = telemetries.map((t) => {
		// 	var viewObject = t.toViewObject();
		// 	var flatproto = this.flattenJSON(viewObject.protobuf);
		// 	var flattened = this.flattenJSON(viewObject.protobuf.gatewayData);
		// 	var final:any = {}
		// 	// Any leading or customized i guess for the table
		// 	final.timeStamp = viewObject.timeStampFormatted;
		// 	// final.batteryVoltageCentivolts = viewObject.protobuf.battery.batteryVoltageCentivolts;
		// 	final.epochMiliseconds = viewObject.protobuf.sentMs;
		// 	final = {...final, ...flatproto, ...flattened};
		// 	if(this.allTelemetryFlat.length <= 0){
		// 		this.allTelemetryFlat = Object.keys(final);
		// 	}
		// 	return final
		// })
		console.error("formatFlatTelemetry: V2 PB UPDATE REQUIRED")
	}

	addTelemetry( telem:any, raw_data?:Uint8Array ){
		// best way of keying this?
		// if(telem == null){
		// 	telem = new Telemetry();
		// 	telem.setReceivedMs(Date.now());
		// 	telem.setMessageCounter(0);
		// }
		// var telemVMI:TelemetryViewModelImplemented = new TelemetryViewModelImplemented();
		// telemVMI.update(telem)
		// // TODO :: Bind the raw data that created this telem
		// if(raw_data){
		// 	telemVMI.setRawTelemetry(raw_data);
		// }
		// this.allTelemetry.unshift(telemVMI);
		// // this.allTelemetry.length = Math.min(this.allTelemetry.length, 50);

		// this.allTelemetry.sort(( a:TelemetryViewModelImplemented, b:TelemetryViewModelImplemented)=>{
		// 	return b.getBestEpoch()-a.getBestEpoch()
		// });
		
		// this.subjectUpdate.next(true);
		// console.log("All telemetry is : ");
		// this.allTelemetry.map( (t) => {
		// 	console.log("T: ", t.toObject());
		// })
	}

	addTelemetryViewModel( telemVMI:TelemetryViewModelImplemented ) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.allTelemetry.unshift(telemVMI);
			this.allTelemetry.sort( (a,b) => {
				return b.getBestEpoch() - a.getBestEpoch();
			});
			// Used for testing some random times in the async getting of telemetry
			// await new Promise( (resolve, reject) => {
			// 	setTimeout( () => {
			// 		resolve({});
			// 	}, Math.floor(Math.random() * 3000));
			// });
			resolve(true);
		});
	}

	refresh( includeTelemetry:boolean=false ) : Promise<boolean> {
		// Update our local UI
		// This is where you can handle any UI specific bindings from the model's data 
		return new Promise( (resolve, reject) => {
			// console.log("Refresh is getting it: ", this.getSensor().getUuid(), " : ", includeTelemetry);
			console.error("refresh: V2 PB UPDATE REQUIRED");

			// this.services.pouch.devices.getSensorByUuid(BigInt(this.getSensor().getUuid()), includeTelemetry).then( (sensor:SensorViewModelImplemented) => {
			// 	this.update(sensor)
			// 	this.subjectUpdate.next(true);
			// 	resolve(true)
			// }).catch( (err) => {
			// 	this.subjectUpdate.next(true);
			// 	reject(err);
			// });
		});
	}

	// stream( sensorVMR : SensorViewModelRequest ) {
	stream( includeTelemetry:boolean=false ) {
		// TODO :: Subscribe to the sensor db and update the model
	}

	deleteTelemetry() : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			console.error("deleteTelemetry: V2 PB UPDATE REQUIRED");
			reject("deleteTelemetry: V2 PB UPDATE REQUIRED");
			// if(this.getSensor()){
			// 	this.allTelemetry = [];
			// 	this.getSensor().clearTelemetryList();
			// 	this.subjectUpdate.next(true);
			// 	this.services.proto.subjectUpdate.next(true);
			// 	resolve(true);
			// }
			// else {
			// 	reject("Sensor Doesnt Exist");
			// }
		});
	}

	deleteServerTelemetry() : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			console.error("deleteServerTelemetry: V2 PB UPDATE REQUIRED");
			reject("deleteServerTelemetry: V2 PB UPDATE REQUIRED");
			// if(this.getSensor()){
			// 	var request:SensorViewModelImplemented = new SensorViewModelImplemented();
			// 	var requestSensor = new Sensor();
			// 	requestSensor.setUid(this.getSensor().getUid());
			// 	request.setSensor(requestSensor);
			// 	this.services.grpc.deleteTelemetry(request).then(() => {
			// 		this.allTelemetry = [];
			// 		this.getSensor().clearTelemetryList();
			// 		this.subjectUpdate.next(true);
			// 		this.services.proto.subjectUpdate.next(true);
			// 		resolve(true);
			// 	}).catch( (e) => {
			// 		reject(e);
			// 	});
			// }
			// else {
			// 	reject("Sensor Doesnt Exist");
			// }
		});
	}

	deleteSelf() {
		console.error("deleteSelf: V2 PB UPDATE REQUIRED");
		// if(this.getSensor()){
		// 	this.services.proto.deleteSensorViewModelImplemented(this.getSensor().getUuid())
		// }
	}

	/// Commands
	sendReboot(){
		// common to all sensors, not likely
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	/// UI Helpers
	//////////////////////////////////////////////////////////////////////////////////////////
	toViewObject() : SensorViewModelImplemented.AsObject {
		var timestamp:number =  this.getLastUpdateEpochMs();
		if( timestamp.toString().length<13){
			timestamp = timestamp*1000;
		}
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
		];
		var d:Date = new Date(timestamp);
		var dateFormatted:string =  monthNames[d.getMonth()] + " " + d.getDate() + " @ " + d.getHours().toString().padStart(2, '0') +":"+d.getMinutes().toString().padStart(2, '0');
		
		return {
			protobuf: this.toObject(),
			lastUpdatedTimeFormatted:dateFormatted,
		}
	}
	public static getStaticTitle():string{ // so we can access this w/o an instance i guess
		return "Sensor"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return SensorViewModelImplemented.getStaticTitle();
	}
	public goToPage() {
		// this.services.router.navigateByUrl(this.getPagePath());
		if(this.services.nav) this.services.nav.navigateForward(this.getPagePath());
	}
	public async openModal() {
		// TODO : After setting up page.
		// console.log("Going to : ", this.getPagePath());
		// const sensorModal = await this.services.modalController.create({
		// 	component: MapTilelayerModalComponent,
		// 	cssClass: "modal-cover",
		// 	componentProps: {},
		// });
		// sensorModal.onDidDismiss().then((rawData:any) => {
		// });
		// await sensorModal.present();
		// this.services.router.navigate([this.getPagePath()]);
	}
	public getPagePath() : string {
		console.error("getPagePath: V2 PB UPDATE REQUIRED");
		// return "vendors/template/template-page/" + this.getSensor().getUuid();
		return "vendors/template/template-page/" + 0;
	}
	
	public getColor() : RGBAColor {
		console.error("getColor: V2 PB UPDATE REQUIRED");

		// let iconColor = this.getSensor().getColor();
		// var color = (iconColor == "" || iconColor == null) ? "#" + Math.floor( Math.abs( Math.sin(this.getSensor().getUuid() || 0) * 16777215 ) % 16777215).toString(16).padStart(6, "0") : iconColor;
		// var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
		// var rgb:RGBAColor = [
		// 	parseInt(result[1], 16),
		// 	parseInt(result[2], 16),
		// 	parseInt(result[3], 16),
		// ];
		// return rgb;
		return [255,255,255,1];
	}
	getIcon() : string {
		return ""
	}

	// Because for some reason, img [src]=""  has a really hard time with functions() ... so ok. its a member.
	public thumbnailImagePath:string = "assets/app/devices/unknown.png";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public getDisplayName() : string {
		console.error("getPagePath: V2 PB UPDATE REQUIRED");
		// if(this.getSensor()){
		// 	if(this.getSensor().getName()) return this.getSensor().getName()
		// 	if(this.getSensor().getFoundName()) return this.getSensor().getFoundName()
		// }
		// return this.getTitle()
		return "Sensor";
	}

	public ionItemDescription: any;
	public getIonItemDescription(): any {
		if(this.ionItemDescription == null){
			this.ionItemDescription = this.buildIonItemDescription();
		}
		return this.ionItemDescription;
	}
	public buildIonItemDescription(): any {
		// var html = `
		// 	<ion-thumbnail slot="start"> 
		// 		<img src="${this.getThumbnailImagePath()}"> 
		// 	</ion-thumbnail> 
		// 	<ion-label> 
		// 		<div class="ion-label-title">${this.getDisplayName()}</div> 
		// 		<div class="ion-label-body">
		// 			UUID: ${this.getSensor().getUuid()}<br>
		// 			Last Seen : ${new Date(this.getSensor().getLastConnectionMs()).toLocaleDateString()}
		// 		</div>
		// 	</ion-label>`;
		// this.ionItemDescription = this.services.domSanitizer.bypassSecurityTrustHtml(html);
		// return this.ionItemDescription;
		console.error("buildIonItemDescription: V2 PB UPDATE REQUIRED");
		var html = `
			<ion-thumbnail slot="start"> 
			buildIonItemDescription: V2 PB UPDATE REQUIRED
			</ion-label>`;
		if(this.services.domSanitizer){
			this.ionItemDescription = this.services.domSanitizer.bypassSecurityTrustHtml(html);
		}
		return this.ionItemDescription;
	}
	//////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////
	/// Map Helpers
	//////////////////////////////////////////////////////////////////////////////////////////
	public getMapTooltip() : MapTooltip {
		// return {
		// 	html:""+this.getSensor().getUid(),
		// }
		console.error("getMapTooltip: V2 PB UPDATE REQUIRED");
		return {
			html:"getMapTooltip: V2 PB UPDATE REQUIRED",
		}
	}
	public getMapClick() : void {
		this.goToPage();
	}
	public getCurrentMapDataPoint() : MapDataPoint{
		var mapDataPoint:MapDataPoint = {};
		console.error("getCurrentMapDataPoint: V2 PB UPDATE REQUIRED");
		// if(this.getSensor().hasLatestTelemetry()){
		// 	if(!this.getSensor().getLatestTelemetry().hasPosition()){
		// 		return null;
		// 	}
		// 	var latestPosition:GPSPosition = this.getSensor().getLatestTelemetry().getPosition();
		// 	if(latestPosition.getLatitude1e7() == 0 || latestPosition.getLongitude1e7() == 0){
		// 		return null;
		// 	}
		// 	mapDataPoint.allSensorVMI = this;
		// 	mapDataPoint.coordinates = [latestPosition.getLongitude1e7() * 1e-7, latestPosition.getLatitude1e7() * 1e-7]
		// 	mapDataPoint.color = this.getColor();
		// 	mapDataPoint.getTooltip = this.getMapTooltip.bind(this);
		// 	mapDataPoint.icon = this.getIcon()
		// 	mapDataPoint.getClick = this.getMapClick.bind(this);
		// 	return mapDataPoint;
		// }
		return mapDataPoint;
	}
	public getTelemetryMapDataPoints() : MapDataPoint[]{
		console.error("getTelemetryMapDataPoints: V2 PB UPDATE REQUIRED");
		var mapDataPoints:MapDataPoint[] = [];
		this.getTelemetryVMIList().map( ( telem:TelemetryViewModelImplemented ) => {
			// if(telem.getTelemetry().hasPosition()){
			// 	if(telem.getTelemetry().getPosition().getLatitude1e7() != 0 && telem.getTelemetry().getPosition().getLongitude1e7() != 0  ){
			// 		var mapDataPoint:MapDataPoint = {};
			// 		var telemPosition:GPSPosition = telem.getTelemetry().getPosition();
			// 		mapDataPoint.coordinates = [telemPosition.getLongitude1e7() * 1e-7, telemPosition.getLatitude1e7() * 1e-7]
			// 		mapDataPoint.color = this.getColor();
			// 		mapDataPoint.icon = this.getIcon()
			// 		mapDataPoint.timestamp = telem.getBestEpoch();
			// 		mapDataPoints.push(mapDataPoint);
			// 	}
			// }
			// else {
			// 	console.log("Doesnt have position");
			// }
		})
		return mapDataPoints;
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	/// Kaitai Helpers
	//////////////////////////////////////////////////////////////////////////////////////////

	updateFromRaw( payload:Uint8Array){}
	updateFromKaitai( parsed_message:any, raw_data:any ) {
		this.services.logger?.warn(this.loggerOptions, "Warning. Not implemented.");
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	/// Pouch Helpers
	//////////////////////////////////////////////////////////////////////////////////////////
	fromJson(){
		this.update()
	}
	fromGrpc(){
		this.update()
	}

	fromPouch(doc:any) {
		// console.log("fromPouch", doc);
		// {
		// 	"streamState": 0,
		// 	"isonline": false,
		// 	"lastUpdateEpochMs": 0,
		// 	"latestLoadEpochMs": 0,
		// 	"oldestLoadEpochMs": 0,
		// 	"oldestEpochMs": 0,
		// 	"totalTelemetryCount": 0,
		// 	"sensor": {
		// 		"uid": "d_10",
		// 		"uuid": 10,
		// 		"serial": 0,
		// 		"createdMs": 1646185745522,
		// 		"name": "",
		// 		"foundName": "",
		// 		"type": 0,
		// 		"islocal": false,
		// 		"description": "",
		// 		"iconUrl": "",
		// 		"color": "#000000",
		// 		"lastUpdate": 1646185745522,
		// 		"telemetryList": [],
		// 		"deviceGroupId": 0,
		// 		"tagsList": [],
		// 		"manufacturer": "",
		// 		"productName": "",
		// 		"version": "",
		// 		"manufacturerType": "",
		// 		"keyvaluesList": []
		// 	}
		// }
		(doc.totalTelemetryCount) ? this.setTotalTelemetryCount(doc.totalTelemetryCount) : this.setTotalTelemetryCount(0);

		if (doc.sensor){
			var sensor:Sensor = new Sensor();
			(doc.sensor.uid) ? sensor.setUid(doc.sensor.uid) : sensor.setUid("0");
			(doc.sensor.uuid) ? sensor.setUuid(doc.sensor.uuid) : sensor.setUuid(0);
			(doc.sensor.serial) ? sensor.setSerial(doc.sensor.serial) : sensor.setSerial(0);
			(doc.sensor.createdMs) ? sensor.setCreatedMs(doc.sensor.createdMs) : sensor.setCreatedMs(0);
			(doc.sensor.name) ? sensor.setName(doc.sensor.name) : sensor.setName("Unknown");
			(doc.sensor.foundName) ? sensor.setFoundName(doc.sensor.foundName) : sensor.setFoundName("");
			(doc.sensor.type) ? sensor.setType(doc.sensor.type) : sensor.setType(0);
			(doc.sensor.isLocal) ? sensor.setIsLocal(doc.sensor.islocal) : sensor.setIsLocal(false);
			(doc.sensor.description) ? sensor.setDescription(doc.sensor.description) : sensor.setDescription("");
			(doc.sensor.iconUrl) ? sensor.setIconUrl(doc.sensor.iconUrl) : sensor.setIconUrl("");
			(doc.sensor.color) ? sensor.setColor(doc.sensor.color) : sensor.setColor("#000000");
			(doc.sensor.lastConnectionMs) ? sensor.setLastConnectionMs(doc.sensor.lastConnectionMs) : sensor.setLastConnectionMs(0);
			(doc.sensor.telemetryList) ? sensor.setTelemetryList(doc.sensor.telemetryList) : sensor.setTelemetryList([]);
			(doc.sensor.deviceGroupId) ? sensor.setDeviceGroupId(doc.sensor.groupId) : sensor.setDeviceGroupId(0);
			(doc.sensor.tagsList) ? sensor.setTagsList(doc.sensor.tagsList) : sensor.setTagsList([]);
			(doc.sensor.manufacturer) ? sensor.setManufacturer(doc.sensor.manufacturer) : sensor.setManufacturer("");
			(doc.sensor.productName) ? sensor.setProductName(doc.sensor.productName) : sensor.setProductName("");
			(doc.sensor.manufacturerType) ? sensor.setManufacturerType(doc.sensor.manufacturerType) : sensor.setManufacturerType("");
			(doc.sensor.keyvaluesList) ? sensor.setKeyvaluesList(doc.sensor.keyvaluesList) : sensor.setKeyvaluesList([]);
			this.setSensor(sensor);
		}

		this.update();
	}

	toJson() : any{
		// console.log("TODO :: Sensor to pouch to VMI");
		var full_doc = this.toObject();
		return full_doc;
	}

	//////////////////////////////////////////////////////////////////////////////////////////

	saveLogMessage(message:string, from?:string) : Promise<boolean> {
		var newLog = new LogViewModelImplemented(this.services);
		if(from){
			if(from.length>0){
				console.error("saveLogMessage: V2 PB UPDATE REQUIRED");
				// newLog.setFrom(from+":"+this.getSensor().getUid());
				newLog.setFrom(from+":"+0);
			}
		}
		newLog.setMessage(message);

		// if(this.services.pouch && this.services.pouch.devices){
			// return this.services.pouch.devices.saveSensorLog(this, newLog);
		// }
		return Promise.reject("Pouch->Sensors missing");
	}

	getLogMessages() : Promise<LogViewModelImplemented[]> {
		// if(this.services.pouch && this.services.pouch.devices){
		// 	return this.services.pouch.devices.getSensorLogs(this);
		// }
		return Promise.reject("Pouch->Sensors missing");
	}
}
