
var EC = require('elliptic').ec;
var ec = new EC("secp256k1");

import * as sjcl from 'sjcl'

import md5 from 'md5';

export class EncryptionService {

	private subtle:any;

	constructor() {
	}

	init() : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if (typeof window !== 'undefined') {
				if(window.crypto && window.crypto.subtle){
					// TODO :: this is still kind of complicated using this code in nodejs
					// They make this much harder than it should be to share code between
					// the two worlds of browser and nodejs
					this.subtle = window.crypto.subtle;
					resolve(true);
					return; 
				}
				else{
					console.error("WEB Crypto API not supported!!!!");
					reject("No crypto support");
				}
			}
			else {
				// const { subtle } = require('crypto').webcrypto;
				// this.subtle = subtle;
			}
			resolve(true);
		})
	}

	public static toHexString(byteArray:Uint8Array):string{
		return Array.from(byteArray, function(byte:any) {
			return ('0' + (byte & 0xFF).toString(16)).slice(-2);
		}).join('').replace(/ /g,'')
	}
	public toHexString(byteArray:Uint8Array) {
		return EncryptionService.toHexString(byteArray);
	}
	public fromHexString(hexString:string) : Uint8Array {
		if(hexString.length === 0 || hexString.length % 2 !== 0 || hexString == null){
			console.log("Invalid hex string: RETURNING BLANK", hexString)
			return new Uint8Array();
		}
		return new Uint8Array(hexString.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
	}
	public bufferToHex(buffer:ArrayBuffer) {
		return this.toHexString(new Uint8Array(buffer));
	}
	public appendUint8Array( a:Uint8Array, b:Uint8Array) : Uint8Array{
		var c = new Uint8Array(a.length + b.length);
		c.set(a);
		c.set(b, a.length);
		return c;
	}
	// https://www.rfc-editor.org/rfc/rfc5480#section-2.2
	// The first octet of the OCTET STRING indicates whether the key iscompressed or uncompressed.  The uncompressed form is indicated
	// by 0x04 and the compressed form is indicated by either 0x02 or 0x03 (see 2.3.3 in [SEC1]).  The public key MUST be rejected if any other value is included in the first octet.
	public addCompressCodeToPublicKey = (key:Uint8Array) : Uint8Array => {
		if(key.length == 64) {
			return this.appendUint8Array(new Uint8Array([0x04]), key);
		}
		return key;
	}
	public removeCompressCodeToPublicKey = (key:Uint8Array) : Uint8Array => {
		if(key[0] == 0x04 && key.length == 65) {
			return key.slice(1);
		}
		return key;
	}
	public toSha256(data:Uint8Array) : Promise<Uint8Array> {
		return new Promise(async (resolve, reject) => {
			try {
				var sha256 = await this.subtle.digest('SHA-256', data);
				resolve(new Uint8Array(sha256));
			} catch(err) {
				console.error("Error in SHA-256 : ", err);
				reject(err);
			}
		})
	}

	public eccGcmCalculateSecret( privateKey:Uint8Array, publicKey:Uint8Array ) : Promise<Uint8Array> {
		return new Promise(async (resolve, reject) => {
			try {
				console.log(".:: eccGcmCalculateSecret: eccGcmCalculateSecret: eccGcmCalculateSecret: eccGcmCalculateSecret ::.")
				console.log("serverKey: ", EncryptionService.toHexString(privateKey))
				console.log("clientKey: ", EncryptionService.toHexString(publicKey))
				var serverKey = ec.keyFromPrivate(privateKey);
				console.log("Server PUBLIC KEY FROM THE PRIVATE WOULD BE : ", EncryptionService.toHexString(new Uint8Array(serverKey.getPublic().encode('hex'))));
				var clientKey = ec.keyFromPublic( this.addCompressCodeToPublicKey( publicKey ) );
				var derrived = serverKey.derive(clientKey.getPublic());
				console.log("Raw Derrived: ", derrived);
				var hex_secret = derrived.toString(16);  // Convert out from BigInt, todo, find Uint8Array output
				if (hex_secret.length % 2 === 1) {
					hex_secret = "0" + hex_secret; // Pad with leading zero if needed
				}
				console.log("Secret Generated (before hash) : ", hex_secret);
				var secret = this.fromHexString(hex_secret);
				console.log("secret : ", secret);
				console.log("secret (hex) : ", EncryptionService.toHexString(secret));
				var secret_hashed:Uint8Array = await this.toSha256(secret);
				console.log("Secret Generated (after hash) : ", this.bufferToHex(secret_hashed));
				resolve(secret_hashed);
			}
			catch(err) {
				reject(err);
			}
		});
	}
	public aesGcmGetTag(encrypted:Uint8Array, tagLength:number=32) : number {
		if (tagLength === void 0) tagLength = 32;
		var array:Uint8Array = encrypted.slice(encrypted.byteLength - ((tagLength + 7) >> 3));
		// console.log("aesGcmGetTag : ", this.toHexString(array));
		var data_view:DataView = new DataView(array.buffer);
		var asUint32 = data_view.getUint32(0, true);
		// console.log("Hex Tag Parsed is (number) : ", asUint32);
		return asUint32;
	}
	public aesGcmGetData(encrypted:Uint8Array, tagLength:number=32) : Uint8Array {
		if (tagLength === void 0) tagLength = 32;
		var array:Uint8Array = encrypted.slice(0, encrypted.byteLength - ((tagLength + 7) >> 3));
		return array;
	}
	public aesGcmEncrypt( raw_key:Uint8Array, iv:Uint8Array, ciphertext:Uint8Array) : Promise<{data:Uint8Array, tag:number}> {
		return new Promise(async (resolve, reject) => {
			const alg = { name: 'AES-GCM', iv: iv, length: 256, tagLength:32 };
			var aesKey = await this.subtle.importKey('raw', raw_key, alg, true, ['encrypt']);
			// console.log("aesGcmEncrypt:key: ", this.toHexString(raw_key));
			// console.log("aesGcmEncrypt:iv: ", this.toHexString(iv));
			// console.log("aesGcmEncrypt:ciphertext: ", this.toHexString(ciphertext));
			this.subtle.encrypt( alg, aesKey, ciphertext )
			.then((encrypted:ArrayBuffer) => {
				// console.log("FULL (w/tag) encrypted is : ", this.bufferToHex(encrypted))
				// console.log("FULL (w/tag) encrypted length is : ", encrypted.byteLength)
				var ret = {data:this.aesGcmGetData(new Uint8Array(encrypted)), tag:this.aesGcmGetTag(new Uint8Array(encrypted))};
				resolve(ret);
			}).catch((err:any) => {
				console.error("Error in AES-GCM Encryption : ", err);
				reject(err);
			});
		})
	}

	public aesGcmDecrypt( raw_key:Uint8Array, iv:Uint8Array, ciphertext:Uint8Array, tag:Uint8Array ) : Promise<Uint8Array> {
		return new Promise(async (resolve, reject) => {
			const alg = { name: 'AES-GCM', iv: iv, length: 256, tagLength:32 };
			var aesKey = await this.subtle.importKey('raw', raw_key, alg, true, ['decrypt']);
			var ciphertextAndTag = this.appendUint8Array(ciphertext, tag.reverse() );

			if(ciphertextAndTag.length<=0){
				console.error("Error in AES-GCM Decryption : ", "Ciphertext is empty");
				resolve(new Uint8Array(0));
			}

			this.subtle.decrypt( alg, aesKey,ciphertextAndTag )
			.then((decrypted:ArrayBuffer) => {
				resolve(new Uint8Array(decrypted));
			}).catch((err:any) => {
				ciphertextAndTag = this.appendUint8Array(ciphertext, tag );
				this.subtle.decrypt(
					alg,
					aesKey,
					ciphertextAndTag 
				)
				.then((decrypted:ArrayBuffer) => {
					resolve(new Uint8Array(decrypted));
				}).catch((err:any) => {
					console.log("Error decrypting:",JSON.stringify(err));
					reject("Failed to decrypt");
				});
			});
		})
	}

	public uint8Tob64(data:Uint8Array) : string {
		var array = [].slice.call(data)
		return sjcl.codec.base64.fromBits(array);
	}

	public b64ToPB(data:string) : Uint8Array {
		var bits = sjcl.codec.base64.toBits(data);
		return new Uint8Array(bits);
	}

	public fullDjb2(str:string) : string {
		'use strict';
		var hash = 5381,index = str.length;
		while (index) {
			hash = (hash * 33) ^ str.charCodeAt(--index);
		}
		var full = hash >>> 0;
		return full.toString(16);
	}

	///// 
	public generateKey() : any {
		return ec.genKeyPair();
	}

	public getPublicKey(key:any) : string {
		return key.getPublic().encode('hex');
	}

	public getPrivateKey(key:any) : string {
		return key.getPrivate().toString(16);
	}
	
	public md5(data:Uint8Array) : Promise<Uint8Array> {
		return new Promise(async (resolve, reject) => {
			try {
				// TODO :: Crypto no longer supports SHA256
				// var wordArray = CryptoJS.lib.WordArray.create(data);
				// var md5Hash = CryptoJS.MD5(wordArray).toString();
				var md5Hash = md5(data);
				resolve(this.fromHexString(md5Hash));
			} catch(err) {
				console.error("Error in MD5 : ", err);
				reject(err);
			}
		})
	}
}


// https://gist.github.com/enepomnyaschih/72c423f727d395eeaa09697058238727


// how to make new keys with openSSL

// make a new key
// openssl ecparam -name secp256k1 -genkey -noout -out ecc_key.pem
// openssl ec -in ecc_key.pem -pubout -text -noout | grep "pub:" -A5 | sed 's/[:\t ]//g' | tail -n5 | tr -d '\n' | cut -c3- | xxd -r -p | xxd -p | fold -w2 | sed 's/^/0x/' | paste -sd ', ' -

// openssl ec -in ecc_key.pem -pubout -text -noout | grep "pub:" -A5 | sed 's/[:\t ]//g' | tail -n5 | tr -d '\n' | cut -c3- | xxd -r -p | xxd -p | sed 's/\(..\)/0x\1,/g' | sed 's/^0x04,//' | sed 's/,$//'
// remove key 
// rm ecc_key.pem