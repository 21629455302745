import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'src/app/services/util/util.service';

// View Models
import { DeviceViewModelImplemented } from '../../../viewmodels/Device.vmi';

@Component({
	selector: 'device-status-component',
	templateUrl: './device-status.component.html',
	styleUrls: ['./device-status.component.scss'],
})
export class DeviceStatusComponent implements OnInit {

	// @Input()
	public deviceVMI: DeviceViewModelImplemented;

	public derrivedTelmetryFormatted: any = {};

	constructor() { }

	ngOnInit() {
		

	}

	getDate(ms:bigint|undefined):Date{
		if(!ms) return new Date();
		return new Date(Number(ms))
	}

	getPrintableJson(data:any) : any {
		return UtilService.embeddedBigIntToString(data);
	}

	refresh( derrivedTelmetryFormatted:any) {
		this.derrivedTelmetryFormatted = derrivedTelmetryFormatted;
	}

	getVersionString(input:any, versionByte:number):string{
		if(input.model.derivedTelemetry.system.currentVersion[versionByte]){
			return ""+input.model.derivedTelemetry.system.currentVersion[versionByte];
		}
		return "n/a"
	}

	asAnyType(val):any {
		return val as any;
	}

}
