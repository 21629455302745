import { AllServcies } from "../services/startup/startup.service"
import { ViewModelImplemented } from "./ViewModelImplemented";
import { FileViewModelImplemented } from './File.vmi';
import { DevicePartitionViewModelImplemented } from './DevicePartition.vmi';
import { DeviceGroupModel, DeviceGroupViewModel, DataBase, ModelType, } from "../generated_proto/protobuf-ts/pb/v2/models";
import { DeviceGroup, DeviceGroup_Partition, } from "../generated_proto/protobuf-ts/pb/v2/entities";
import { DeviceViewModelImplemented } from "./Device.vmi";
import { DataService } from "../services/data/data.service";
import { LoggerOptions } from "../services/logger/logger.service";
import { JsonValue } from "@protobuf-ts/runtime"

export class DeviceGroupViewModelImplemented extends ViewModelImplemented implements DeviceGroupViewModel {

	public model: DeviceGroupModel;
	public photoUrl:string = "";

	public loggerOptions:LoggerOptions = {
		prefix:"DeviceGroupViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	// VMI's
	private photoVMI:FileViewModelImplemented; // File is populated through md5 ask when needed (only when needed)
	private partitions:DevicePartitionViewModelImplemented[] = [];
	public devices:DeviceViewModelImplemented[] = [];

	// Since these objects are created at runtime, they need to be passed references to the Services
	constructor(
		services:AllServcies
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		// Can pass as param, not used in this case but would
		// be useful behavior for sub classes
		this.services = services;
		this.model = DeviceGroupModel.create();
		this.model.deviceGroup = DeviceGroup.create();
		this.model.deviceGroup.deviceGroupId = 0;
		this.photoVMI = new FileViewModelImplemented(this.services);
	}

	updateLocalFromDb(update:DeviceGroupModel) {
		try{
			DeviceGroupModel.mergePartial(this.model, update);
		}
		catch(e){
			console.error("updateLocalFromDb:Error merging model", e);
			return;
		}
		this.partitions = [];
		if(this.model.deviceGroup){
			for(let partition of this.model.deviceGroup.partitions){
				var newPartition = new DevicePartitionViewModelImplemented(this.services);
				newPartition.update(partition);
				this.partitions.push(newPartition);
			}
		}
		if(this.model.photoUrn){
			if(this.services.data){
				var url = this.services.data.getBackendUrl()+"/";
				this.photoUrl = url+this.model.photoUrn+"/attachment";
			}
		}
	}

	public static PB_VERSION = 2;
	static GenerateDbId(dbPrefix:string) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return dbPrefix + ModelType.DEVICE_GROUP_MODEL;
	}
	static GenerateDbKey(id:number, variant?:number) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		// convert md5 to base64url
		var key = DataService.getKeyPrefix(ModelType.DEVICE_GROUP_MODEL,DeviceGroupViewModelImplemented.PB_VERSION) + id;
		if(variant != 0){
			key += ","+variant;
		}
		console.log("GenerateDbKey: ", key);
		return key;
	}
	public generateDbKey() : string {
		console.log("this.model.deviceGroup.deviceGroupIdVariant: ", this.model.deviceGroup?.deviceGroupIdVariant);
		console.log("this.model.deviceGroup.deviceGroupIdVariant: ", this.model.deviceGroup?.deviceGroupIdVariant);
		console.log("this.model.deviceGroup.deviceGroupIdVariant: ", this.model.deviceGroup?.deviceGroupIdVariant);
		console.log("this.model.deviceGroup.deviceGroupIdVariant: ", this.model.deviceGroup?.deviceGroupIdVariant);
		console.log("this.model.deviceGroup.deviceGroupIdVariant: ", this.model.deviceGroup?.deviceGroupIdVariant);
		console.log("this.model.deviceGroup.deviceGroupIdVariant: ", this.model.deviceGroup?.deviceGroupIdVariant);

		if(this.model.deviceGroup?.deviceGroupId ){
			return DeviceGroupViewModelImplemented.GenerateDbKey(this.model.deviceGroup.deviceGroupId, this.model.deviceGroup.deviceGroupIdVariant);
		}
		return DeviceGroupViewModelImplemented.GenerateDbKey(0);
	}
	static GenerateURN(dbPrefix:string, deviceGroup:DeviceGroup) : string {
		if(deviceGroup){
			var dbid = this.GenerateDbId(dbPrefix);
			return dbid+"/"+this.GenerateDbKey(deviceGroup.deviceGroupId, deviceGroup.deviceGroupIdVariant);
		}
		console.error("generateURN: No deviceGroupId");
		return "";
	}
	public generateURN() : string {
		if(this.model && this.model.deviceGroup && this.services.data?.getDbPrefix()){
			return DeviceGroupViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.model.deviceGroup);
		}
		return "";
	}
	updateDb(db?:DataBase){
		if(!this.model.db){
			this.model.db = DataBase.create();
		}
		if(db){
			try{
				DataBase.mergePartial(this.model.db, db);
			}
			catch(e){
				console.error("updateDb:Error merging model", e);
				return;
			}
		}
		if( this.model.db.createdMs && this.model.db.createdMs <= 0){
			this.model.db.createdMs = BigInt(Date.now());
		}
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.DEVICE_GROUP_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}

	modelToJson() : JsonValue {
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		if(this.model.deviceGroup){
			this.model.deviceGroup.partitions = [];
		}
		for (let index = 0; index < this.partitions.length; index++) {
			const partition = this.partitions[index];
			if(this.model.deviceGroup){
				this.model.deviceGroup.partitions[index] = DeviceGroup_Partition.fromJson(DeviceGroup_Partition.toJson(partition));
			}
		}
		return DeviceGroupModel.toJson(this.model);
	}

	getDevices(includeAll:boolean=false): Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			if(this.services.pouch && this.services.pouch.deviceManagement){
				if(!this.model.db?.urn){
					reject("Device Group not saved");
					return;
				}
				if(!this.services.settings?.SETTINGS.APP_ID){
					reject("App ID Not Set");
					return;
				}
				var variant = 0;
				if(!includeAll && this.model.deviceGroup?.deviceGroupIdVariant){
					variant = this.model.deviceGroup?.deviceGroupIdVariant;
				}
				console.log("CALLING getDevicesInGroupgetDevicesInGroupgetDevicesInGroupgetDevicesInGroup : ", variant)
				this.services.pouch.devices.getDevicesInGroup(this.services.settings?.SETTINGS.APP_ID, this.model.deviceGroup?.deviceGroupId, variant)
				.then( (devices) => {
					console.log("Got devices for group : ", devices.length);
					this.devices = devices.sort((a,b) => {
						if(a.model.device?.deviceGroupIdVariant == this.model.deviceGroup?.deviceGroupIdVariant){
							return -1;
						}
						return 0;
					});
					resolve(true);
				})
				.catch( (err) => {
					reject(err);
				});
			}
		});
	}

	async setPhoto(photo_in:any){
		if(this.services.logger) this.services.logger.verbose(this.loggerOptions, "setPhoto");
		await this.photoVMI.setFile(photo_in);
	}

	latestParitionsDisplayArray():string[]{
		var ret:string[] = [];
		for (let index = 0; index < this.partitions.length; index++) {
			const partition = this.partitions[index];
			ret.push(partition.getLatestFirmwareVersionString());
		}
		return ret;
	}

	getParitionsList(): DevicePartitionViewModelImplemented[] {
		return this.partitions;
	}
	addNewPartition(){
		var newPartition = new DevicePartitionViewModelImplemented(this.services);
		newPartition.order = this.partitions.length;
		this.partitions.push(newPartition);
	}
	deletePartition(partition:DevicePartitionViewModelImplemented){
		var index = this.partitions.indexOf(partition);
		if(index > -1){
			this.partitions.splice(index, 1);
		}
		for(let i = 0; i < this.partitions.length; i++){
			this.partitions[i].order = i;
		}
	}
	getParition( id:number ): DevicePartitionViewModelImplemented | null {
		var partition = this.partitions.find(p=>p.order==id);
		if(partition){
			return partition;
		}
		return null;
	}
	save(app_id?:number) : Promise<boolean>{
		// this.setCreatedMs(Date.now());
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		return new Promise( async (resolve, reject) => {
			if(!this.photoVMI.fileIsSet){
				console.log("Photo is not set ... ")
			}
			// Save the base file
			if(this.photoVMI.fileIsSet){
				// Ignore if file already exits.
				if(this.services.pouch && this.services.pouch.files){
					if(!this.services.settings){
						reject("Settings Service Not Set");
						return;
					}
					if(!this.services.settings.SETTINGS.APP_ID){
						reject("App ID Not Set");
						return;
					}
					await this.services.pouch.files.saveFile(this.services.settings.SETTINGS.APP_ID, this.photoVMI).then( () => {
						// this.setPhotoMd5(this.photo.getMd5());
						if(this.photoVMI.model.db?.urn){
							this.model.photoUrn = this.photoVMI.model.db.urn;
						}
					}).catch( () =>{
						reject("Failed to save photo");
					})
				}
				else {
					reject("Missing Pouch -> Files services");
				}
			}
			if(this.services.pouch && this.services.pouch.app){
				this.services.pouch.deviceManagement.saveDeviceGroup(app_id||2, this)
				.then( (success) => {
					console.log("Saved DeviceGroup : ", success);
					(success) ? resolve(true) : resolve(false);
				})
				.catch( (err) => {
					console.log("Failed to save device group FILE ERROR : ", err);
					reject(err);
				});
			}
			else {
				reject("Missing Pouch -> App services");
			}

		})
	}
	delete(app_id?:number):Promise<boolean>{
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		return new Promise( async (resolve, reject) => {
			if(this.services.pouch && this.services.pouch.app){
				this.services.pouch.deviceManagement.deleteDeviceGroup(app_id||2, this)
				.then( (success) => {
					(success) ? resolve(true) : resolve(false);
				})
				.catch( (err) => {
					reject(err);
				});
			}
		});
	}

}