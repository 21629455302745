import { Position } from './../../generated_proto/protobuf-ts/app/model/v2/data';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, } from '@angular/core';

import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexDataLabels,
	ApexFill,
	ApexYAxis,
	ApexXAxis,
	ApexStroke,
	ApexLegend,
	ApexChart,
	ApexTooltip,
} from "ng-apexcharts";

export type ChartOptions = {
	title: any;
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	fill: ApexFill;
	legend: ApexLegend;
	yaxis: ApexYAxis;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	tooltip:ApexTooltip;
	colors:string[];
};

import { StartupService } from 'src/app/services/startup/startup.service';

enum TimePeriodSelected {
	Day,
	Week,
	Month,
	Year,
	All,
}

@Component({
	selector: 'time-selector-component',
	templateUrl: './time-selector.component.html',
	styleUrls: ['./time-selector.component.css'],
})
export class TimeSelectorComponent implements OnInit {

	readonly debounceTime:number = 800;
	readonly maximumPoints:number = 1000;

	private maxEpochMs:number = Date.now();

	@ViewChild("chartComponent", { static: false }) chartComponent: ChartComponent;

	@Input()
	public latestMs:number = Date.now();

	@Input()
	public linkToZeroChart:boolean = false;

	@Output()
	public expandTimeRange = new EventEmitter<{startEpochMs:number, endEpochMs:number}>();
	private expandTimeRangeDebouncer:ReplaySubject<{startEpochMs:number, endEpochMs:number}> = new ReplaySubject();

	// subtract one day
	public selectionStartEpochMs:number= Date.now() -  24 * 60 * 60 * 1000;
	public selectionEndEpochMs:number = Date.now();

	public changedStartStopEpoch:BehaviorSubject<{startEpochMs:number, endEpochMs:number}> = new BehaviorSubject({startEpochMs:this.selectionStartEpochMs, endEpochMs:this.selectionEndEpochMs});
	private changedStartStopEpochDebouncer:BehaviorSubject<{startEpochMs:number, endEpochMs:number}> = new BehaviorSubject({startEpochMs:this.selectionStartEpochMs, endEpochMs:this.selectionEndEpochMs});

	public epochMsRange: any[] = [];
	public syncedState:  any[] = [];

	public currentSelection = TimePeriodSelected.Month;

	private skipProgramaticSelection:boolean = false;

	private zoomMin:number = 0;
	private zoomMax:number = 0;

	constructor(
		private startupService: StartupService,
	) {
		
	}

	ngOnInit() {
		this.initCharts();
		this.changedStartStopEpochDebouncer.pipe(debounceTime(this.debounceTime))
		.subscribe((value) => 	{
			this.changedStartStopEpoch.next(value);
		});
		this.expandTimeRangeDebouncer.pipe(debounceTime(this.debounceTime))
		.subscribe((value) => 	{
			// console.group("emmiting debounced expandTimeRange:");
			// console.log("Start: ",  new Date( value.startEpochMs ));
			// console.log("End:   ",  new Date( value.endEpochMs ));
			// console.groupEnd();
			this.expandTimeRange.emit(value);
		});
	}

	public chartTimes: ChartOptions;
	public initCharts(): void {
		this.chartTimes = {
			title: {
				text: "date selector",
				align: "center",
				floating: true,
				margin: 0,
			},
			series: [
			],
			colors: ["#008FFB"],
			chart: {
				animations: {
					enabled: false,
				},
				height: 110,
				type: "bar",
				// background: "#F6F8FA",
				toolbar: {
					show:false,
				},
				selection: {
					enabled: false,
				},
				events:{
					dataPointSelection: (event, chartContext, config) => {
						var time = config.w.config.xaxis.categories[config.dataPointIndex]
						if(time){
							console.log("dataPointSelection",time);
							console.log("Chose : ", new Date(time), " : ", new Date(time + 86400000));
							this.changedStartStopEpochDebouncer.next({startEpochMs:Math.floor(time), endEpochMs:Math.ceil(time + 86400000)});
						}
					},
				}
			},
			fill: {
				type: 'solid',
				opacity: .6,
			},
			legend: {
				position: "top",
				horizontalAlign: "left"
			},
			stroke: {
				// width: 100,
				curve: "straight"
			},
			// https://github.com/apexcharts/apexcharts.js/issues/214
			dataLabels: {
				enabled: false,
				// formatter: function(value, { seriesIndex, dataPointIndex, w }) {
				// 	var date = new Date(w.config.xaxis.categories[dataPointIndex]);
				// 	const formattedDate = date.toLocaleDateString('en-GB', {
				// 		day: 'numeric', month: 'short', year: 'numeric'
				// 	  }).replace(/ /g, ' ');
				// 	  return formattedDate;
				//   }
			},
			tooltip: {
				x: {
					format: "ddd dd MMM yyyy"
				}
			},
			xaxis: {
				// type: 'datetime',
				tickAmount: 30,
				floating: true,

				position: 'top',
				labels: {
					rotateAlways: true,
					rotate: -90,
					datetimeUTC: false,
					minHeight: 80,
    				// maxHeight: 180,
					trim: false,
					offsetY: 10,
					
					// datetimeFormatter: {
					// 	year: 'yyyy',
					// 	month: "MMM 'yy",
					// 	day: 'ddd MMM dd',
					// },
					// format: "ddd MMM dd",
					// style: {
						// fontWeight: "bold",
						// cssClass: 'xaxis-label',
					// },
					formatter: (value) => {
						var date = new Date(value);
						const formattedDate = date.toLocaleDateString('en-GB', {
							weekday: 'short' , month: 'short', day: 'numeric'
						}).replace(/ /g, ' ');
						return formattedDate;
					}
				},
			},
			yaxis: {
				show: false,
				floating: true,
				axisTicks: {
					show: false
				},
				axisBorder: {
					show: false
				},
				labels: {
					show: false
				},
			},
		};
		this.startupService.ready().then( () => {
			if(this.startupService.services.settings?.lightActive == true){
				if(this.startupService.services.settings?.SETTINGS.APP_STYLE_LIGHT_ACCENT_COLOR){
					var colors = [this.startupService.services.settings.SETTINGS.APP_STYLE_LIGHT_ACCENT_COLOR];
					console.log("this.startupService.services.settings.SETTINGS.APP_STYLE_LIGHT_ACCENT_COLOR :: ", this.startupService.services.settings.SETTINGS.APP_STYLE_LIGHT_ACCENT_COLOR)
					this.chartTimes.colors = colors;
					if(this.chartComponent){
						this.chartComponent.updateOptions(this.chartTimes);
					}
				}
			}
			else {
				if(this.startupService.services.settings?.SETTINGS.APP_STYLE_DARK_ACCENT_COLOR){
					var colors = [this.startupService.services.settings.SETTINGS.APP_STYLE_DARK_ACCENT_COLOR];
					this.chartTimes.colors = colors;
					if(this.chartComponent){
						this.chartComponent.updateOptions(this.chartTimes);
					}
					console.log("this.startupService.services.settings.SETTINGS.APP_STYLE_DARK_ACCENT_COLOR :: ", this.startupService.services.settings.SETTINGS.APP_STYLE_DARK_ACCENT_COLOR)
				}
			}
			//call in 10 ms superhack? 
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 10);
		})
	}

	public update( state:{epochMs:number[], syncedState:number[]} ) : Promise<{startEpochMs:number, endEpochMs:number}>{
		return new Promise( (resolve,reject) => {
			this.epochMsRange = state.epochMs;
			this.syncedState = state.syncedState;

			// Convert epcohMs to a object of days, number of counts per day
			var chartByDays = {};
			this.epochMsRange.map( (epoch) => {
				let day = new Date(epoch)
				day.setHours(0);
				day.setMinutes(0);
				day.setSeconds(0);
				day.setMilliseconds(0);
				if(chartByDays[day.getTime()]){
					chartByDays[day.getTime()] += 1;
				}
				else {
					chartByDays[day.getTime()] = 1;
				}
			})

			this.selectionStartEpochMs = this.epochMsRange[this.epochMsRange.length-1];
			this.selectionEndEpochMs = this.epochMsRange[0];

			var updateChart:any = {
				series: [{
					name: "Samples",
					data: Object.keys(chartByDays).map( (key) => {
						return chartByDays[key]
					}).reverse()
				}],
				xaxis: {
					categories: Object.keys(chartByDays).map( (key) => parseInt(key)).reverse(),
				},
			};
			this.chartComponent.updateOptions(updateChart);

			this.chartComponent.zoomX(this.maxEpochMs-2.628e+9, this.maxEpochMs);

			resolve({startEpochMs:this.selectionStartEpochMs, endEpochMs:this.selectionEndEpochMs});
		})
	}

	onClickLinePrev(){
		this.maxEpochMs -= 86400000;
		this.chartComponent.zoomX(this.maxEpochMs-2.628e+9, this.maxEpochMs);
		this.expandTimeRangeDebouncer.next({startEpochMs:this.maxEpochMs-2.628e+9, endEpochMs:this.maxEpochMs});
	}
	onClickLineNext(){
		this.maxEpochMs += 86400000;
		this.chartComponent.zoomX(this.maxEpochMs-2.628e+9, this.maxEpochMs);
		this.expandTimeRangeDebouncer.next({startEpochMs:this.maxEpochMs-2.628e+9, endEpochMs:this.maxEpochMs});
	}

	// https://stackoverflow.com/questions/63823557/angular-material-datepickerrange-get-value-on-change
	dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
		if(dateRangeStart.value && dateRangeEnd.value){
			console.log(dateRangeStart.value, dateRangeEnd.value);
			var startDate = new Date(dateRangeStart.value);
			var endDate = new Date(dateRangeEnd.value);
			// https://stackoverflow.com/questions/8636617/how-to-get-start-and-end-of-day-in-javascript
			endDate.setUTCHours(23,59,59,999);
			this.changedStartStopEpochDebouncer.next({startEpochMs:Math.floor(startDate.getTime()), endEpochMs:Math.ceil(endDate.getTime())});
		}
	}
}
 