import { Component, OnInit, ChangeDetectorRef, ViewChild, Input, } from '@angular/core';
//// Table
import { MatPaginator, } from '@angular/material/paginator';
import { MatTableDataSource,  } from '@angular/material/table';
import { MatSort, } from '@angular/material/sort';
import { TelemetryViewModelImplemented } from '../../viewmodels/Telemetry.vmi';
import { eventFormatted, EventType } from 'src/app/viewmodels/Device.vmi';
export interface Person {
	id: number;
	name: string;
	age: number;
}
@Component({
	selector: 'event-table-component',
	templateUrl: './event-table.component.html',
	styleUrls: ['./event-table.component.scss'],
})
export class EventTableComponent implements OnInit {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	
	public telemetryViewModelImplemented:TelemetryViewModelImplemented[] = [];
	public displayedColumns: string[] = ['event_id', 'event_name', 'start_time', 'stop_time', 'event_data'];
	public colorsMap = new Map<string, string>();
	public colors = [  '#008FFB',  '#00E396',  '#FEB019',  '#FF4560',  '#775DD0',  '#008FFB',  '#00E396',  '#FEB019',  '#FF4560',  '#775DD0'];
	constructor(
		public changeDetectorRef:ChangeDetectorRef,
	){

	}

	ngOnInit() {}

	public refresh( events:eventFormatted[]) {
		console.log("Refresh called on EventTableComponent");
		for (var i = 0; i < events.length; i++) {
			var colorIndex = i % this.colors.length;
			var eventName = events[i].event_name;
			var color = this.colors[colorIndex];
			this.colorsMap.set(eventName, color);
		  }
		this.tableRender(events);
		this.changeDetectorRef.detectChanges();
	}

	public flattenJSON(obj={}, res = {}, extraKey = '') {
		var keys = Object.keys(obj);
		for (let index = 0; index < keys.length; index++) {
			const key = keys[index];
			if(typeof obj[key] !== 'object'){
				if(obj[key]) //only add if value exists. Note filters out values of 0
				{
					res[extraKey + key] = obj[key];
				}
			}else{
				this.flattenJSON(obj[key], res, `${extraKey}${key}.`);
			};
		}
		return res;
	};

	public selectedRowIndex: number = -1;
	public dataSource: MatTableDataSource<eventFormatted>;
	fullColumnOptions : string[] = [];
	public dropdownSettings = {
		singleSelection: false,
		allowSearchFilter: true,
		itemsShowLimit: 0,
	};

	public compareWith(e1, e2) {
		// fallback to single element comparison
		return e1 && e2 ? e1.id === e2.id : e1 === e2;
	}

	displayColumnsChange( event ) {
		this.displayedColumns = event.detail.value;
	}

	// TODO :: Sashi i think you were working on this here
	onItemSelect(event){
		console.warn("!!WARN : Not Implemented : ", event);
	}
	onSelectAll(event){
		console.warn("!!WARN : Not Implemented : ", event);
	}
	
	public tableRender(events: eventFormatted[]) {
		// var data_object = telemetries.map((t) => {
		// 	var flatproto = this.flattenJSON(viewObject.protobuf.telemetry);
		// 	var final:any = {}
		// 	// // Any leading or customized i guess for the table
		// 	// final.timeStamp = viewObject.timeStampFormatted;
		// 	// final.batteryVoltageCentivolts = viewObject.protobuf.battery.batteryVoltageCentivolts;
		// 	// final.epochMiliseconds = viewObject.protobuf.sentMs;
		// 	final = {...final, ...flatproto,};
		// 	if(this.fullColumnOptions.length <= 0){
		// 		this.fullColumnOptions = Object.keys(final);
		// 		this.displayedColumns = Object.keys(final);
		// 	}
		// 	return final
		// })

		console.log("this.fullColumnOptions",this.fullColumnOptions);
		this.dataSource = new MatTableDataSource<eventFormatted>(events);
		console.log(this.dataSource);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.changeDetectorRef.detectChanges();
	}

}
