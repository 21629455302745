import { AllServcies } from "../services/startup/startup.service"
import { TelemetryViewModel, TelemetryModel, DataBase, ModelType } from "../generated_proto/protobuf-ts/pb/v2/models";
import { GPSScanRecord, Telemetry } from "../generated_proto/protobuf-ts/pb/v2/data";
import { LoggerOptions } from "../services/logger/logger.service";
import { JsonValue } from "@protobuf-ts/runtime";
import { ViewModelImplemented } from "./ViewModelImplemented";
import { DataService } from "../services/data/data.service";

export class TelemetryViewModelImplemented extends ViewModelImplemented implements TelemetryViewModel {
	model?: TelemetryModel;
	hasPosition: boolean;
	colorRgb: Uint8Array = new Uint8Array([255, 0, 0, 255]);
	currentVersionFormatted: string ="n/a";
	public date: Date;

	public loggerOptions:LoggerOptions = {
		prefix:"TelemetryViewModelImplemented",
		allOn:false,
		verboseOn:false,
		debugOn:false,
	};

	constructor
	(
		services?:AllServcies,
	)
	{
		super(services);
		if(services){
			this.services = services;
		}
	}

	// Common VMI implementations
	public getId(): bigint {
		if(this.model?.telemetry?.uuid){
			return this.model.telemetry?.uuid;
		}
		else if(this.model?.db?.uid){
			return this.model.db?.uid;
		}
		else if(this.model?.db?.uuid){
			return this.model.db?.uuid;
		}
		else {
			return BigInt(0);
		}
	}


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// Local View Model Updates
	// Recalculate any differences needed. IE Formattting for display, or pulling any other functions to set the correct satet of the model.
	public setIsLatest(){
		// Calculate all needed views once for the view model displayed for the gauges and such
	}
	
	private update(){
		this.colorRgb = this.getColor();
		this.updatePosition();

		if(this.model?.telemetry?.epochMs)
		{
			this.date=new Date(Number(this.model.telemetry.epochMs));
		}
		if(this.model?.telemetry?.system?.currentVersion){
			this.currentVersionFormatted = this.model?.telemetry?.system?.currentVersion[2] + "." + this.model?.telemetry?.system?.currentVersion[1] + "." + this.model?.telemetry?.system?.currentVersion[0];
		}

	}
	updateFromTelemetry( telemetry:Telemetry ){
		if(!this.model){
			this.model = TelemetryModel.create();
		}
		if(telemetry && this.model){
			this.model.telemetry = telemetry;
		}
		this.update();
	}
	updateFromModel( model:JsonValue ){
		if(!this.model){
			this.model = TelemetryModel.create();
		}
		if((model as any).telemetry?.system?.connectedCell?.simid){
			var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
			if(base64regex.test((model as any).telemetry.system.connectedCell.simid as any)){
				(model as any).telemetry.system.connectedCell.simid = "0";
			}
		}
		if((model as any).telemetry?.system?.connectedCell?.rssi){
			var rssi = BigInt((model as any).telemetry.system.connectedCell.rssi);
			if(rssi > 2**(32-1)){
				(model as any).telemetry.system.connectedCell.rssi = Number(BigInt.asIntN(32,rssi));
			}
			if((model as any).telemetry.system.connectedCell.rssi>0){
				(model as any).telemetry.system.connectedCell.rssi *= -1;
			}
		}

		try{
			var check:TelemetryModel = TelemetryModel.fromJson(model, {ignoreUnknownFields: true});
			if(model && this.model && TelemetryModel.is(check)){
				this.model = check;
				if(this.services.logger) this.services.logger.verbose(this.loggerOptions, "updateFromModel:final", this.model);
				this.update();
				return;
			}
			if(this.services.logger) this.services.logger.error(this.loggerOptions, "updateFromModel: Model is not a TelemetryModel");
		}
		catch(e){
			console.warn("updateFromModel:error: ",e);
		}

	}
	modelToJson():JsonValue{
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		if(this.model) return TelemetryModel.toJson(this.model);
		return null;
	}

	getObject(theObject) {
		var result = null;
		if(theObject instanceof Array) {
			for(var i = 0; i < theObject.length; i++) {
				result = this.getObject(theObject[i]);
			}
		}
		else
		{
			for(var prop in theObject) {
				console.log("prop:", prop);
				if(prop == 'id') {
					if(theObject[prop] == 1) {
						return theObject;
					}
				}
				if(theObject[prop] instanceof Object || theObject[prop] instanceof Array)
					result = this.getObject(theObject[prop]);
			}
		}
		return result;
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// Server Requests
	private static PB_VERSION = 2;
	// This is the database this "thing" actually resides in
	static GenerateDbId(dbPrefix:string, device_uuid:bigint|Telemetry) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		if(typeof device_uuid != "bigint"){
			device_uuid = device_uuid.uuid;
		}
		return dbPrefix + ModelType.TELEMETRY_MODEL.toString() +DataService.DB_KEY_SPLIT_CHAR+ device_uuid.toString();
	}
	static GenerateDbKey(telemetry:number|Telemetry) : string {
		var epochMs:number;
		if(typeof telemetry != "number"){
			epochMs = Number(telemetry.epochMs);
		}
		else{
			epochMs = telemetry;
		}
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return DataService.getKeyPrefix(ModelType.TELEMETRY_MODEL,TelemetryViewModelImplemented.PB_VERSION)+ (BigInt(0xFFFFFFFFFFF)-BigInt(epochMs));
	}
	static GenerateDbReverseTime(telemetry:number|Telemetry) : BigInt {
		var epochMs:number;
		if(typeof telemetry != "number"){
			epochMs = Number(telemetry.epochMs);
		}
		else{
			epochMs = telemetry;
		}
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return (BigInt(0xFFFFFFFFFFF)-BigInt(epochMs));
	}
	public generateDbReverseTime() : BigInt {
		if(this.model?.telemetry){
			return TelemetryViewModelImplemented.GenerateDbReverseTime(this.model.telemetry);
		}
		return BigInt(0);
	}
	public generateDbKey() : string {
		if(this.model?.telemetry ){
			return TelemetryViewModelImplemented.GenerateDbKey(this.model.telemetry);
		}
		return "";
	}
	static GenerateURN(dbPrefix:string, telemetry:Telemetry) : string {
		if(telemetry){
			var dbid = this.GenerateDbId(dbPrefix, telemetry);
			return dbid+"/"+this.GenerateDbKey(telemetry);
		}
		console.error("generateURN: No UUID");
		return "";
	}
	public generateURN() : string {
		if(this.model && this.model.telemetry && this.services.data?.getDbPrefix()){
			return TelemetryViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.model.telemetry);
		}
		return "";
	}
	updateDb(db?:DataBase){
		if(!this.model){
			this.model = TelemetryModel.create();
		}
		if(!this.model.db){
			this.model.db = DataBase.create();
		}
		if(db){
			try{
				DataBase.mergePartial(this.model.db, db);
			}
			catch(e){
				console.error("updateDb:error: ",e);
			}
		}
		if(this.model.db.createdMs && this.model.db.createdMs <= 0){
			this.model.db.createdMs = BigInt(Date.now());
		}
		if(this.model.telemetry) this.model.db.uid = BigInt(this.model.telemetry.uuid);
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.TELEMETRY_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// Parsing
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// NOTE :: This parsed_data_context is specific ONLY to the device type
	// It is not to be used generically for all UI
	private parsed_data_context:any = null;
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Misc UI Functions
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		toViewObject() : any {
		var timestamp:number =  this.getBestEpoch();
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
		];
		var d:Date = new Date(timestamp);
		var dateFormatted:string =  monthNames[d.getMonth()] + " " + d.getDate() + " @ " + d.getHours().toString().padStart(2, '0') +":"+d.getMinutes().toString().padStart(2, '0');
		
		var latitude:number=0;
		var longitude:number=0;
		if(this.hasPosition && this.model && this.model.telemetry){
			latitude = parseFloat((this.model?.telemetry?.latitude1E7*1e-7).toFixed(6));
			longitude = parseFloat((this.model?.telemetry?.latitude1E7*1e-7).toFixed(6));
		}

		return {
			protobuf: this.model,
			hasPosition: this.hasPosition,
			latitude: latitude,
			longitude:longitude,
			timeStampFormatted:dateFormatted,
		}
	}

	getTelemId() :string {
		return this.getBestEpoch()+":"+this.model?.telemetry?.messageCounter;
	}

	// Depending on the map that we're using .. some want lat/lng sometimes lng/lat
	public lnglat:number[] = [0,0];
	public latlng:number[] = [0,0];
	public elevationCm:number = 0;
	updatePosition() {
		if(this.model && this.model.telemetry && this.model?.telemetry?.latitude1E7 !=0 && this.model?.telemetry?.longitude1E7 !=0){
			this.hasPosition = true;
			this.lnglat = [this.model.telemetry.longitude1E7*1e-7, this.model.telemetry.latitude1E7*1e-7];
			this.latlng = [this.model.telemetry.latitude1E7*1e-7, this.model.telemetry.longitude1E7*1e-7];
			if( this.model?.telemetry?.elevationCm){
				this.elevationCm = this.model?.telemetry?.elevationCm;
			}
		}
		else {
			this.hasPosition = false;
		}
	}

	public rawTelemetry:Uint8Array
	setRawTelemetry(raw:Uint8Array){
		this.rawTelemetry = raw;
	}

	getBestEpoch():number{
		if(this.model?.telemetry?.epochMs != BigInt(0)){
			return Number(this.model?.telemetry?.epochMs);
		}
		if(this.model.telemetry.gpsScanRecord?.epochMiliseconds){
			return Number(this.model.telemetry.gpsScanRecord.epochMiliseconds);
		}
		if(this.model.db?.createdMs){
			return Number(this.model.db.createdMs);
		}
		return Date.now();
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


}