
import { DeviceTypeIds } from "../viewmodels/Device.vmi";

// import { HumGatewaySensorViewModelImplemented, } from "./hum/hum-gateway.vmi"

import { SteDeviceViewModelImplemented } from "./ste/ste.vmi"
import { StePagerDeviceViewModelImplemented } from "./ste/radpager.vmi"
import { SteRadPackDeviceViewModelImplemented } from "./ste/radpack.vmi";
import { SteDevKitDeviceViewModelImplemented } from "./ste/stedevkit.vmi";
import { LrmDeviceViewModelImplemented } from "./ste/lrm.vmi";
import { PlBeaconDeviceViewModelImplemented } from "./pl/pl-beacon.vmi";
import { PlStationViewModelImplemented } from "./pl/pl-station.vmi";
import { HumSensorViewModelImplemented } from "./hum/hum-sensor.vmi";
import { HumGatewayViewModelImplemented } from "./hum/hum-gateway.vmi";
import { Device } from "../generated_proto/protobuf-ts/pb/v2/entities";
import { FixedGatewaytDeviceViewModelImplemented } from "./rl/fixed-gateway.vmi";
import { CommsAssetDeviceViewModelImplemented } from "./comms/comms-asset.vmi";
import { RlBodyWornDeviceViewModelImplemented } from "./rl/body-worn.vmi";
import { MirionDeviceViewModelImplemented } from "./mirion/mirion.vmi";

export type VendorVMIs = SteDeviceViewModelImplemented | StePagerDeviceViewModelImplemented | CommsAssetDeviceViewModelImplemented;

export function vendorFromJson(services:any, json:any): VendorVMIs | null {
	// We can handle any parsing of data here to build a VMI.
	// Sometimes this depends on connection, or data,
	// or some combonation of other things to figure out the correct sensor type.
	// Handle any specifics to find the device type, and return the correct VMI.

	// *******************************
	// ************* STE *************
	// *******************************
	if(json.device.deviceType == DeviceTypeIds.STE_PAGER_S){
		var pagerS = new StePagerDeviceViewModelImplemented(services);
		pagerS.updateLocalFromDb(json);
		return pagerS;
	}
	if(json.device.deviceType == DeviceTypeIds.STE_NRF){
		var pagerNRF = new StePagerDeviceViewModelImplemented(services);
		pagerNRF.updateLocalFromDb(json);
		if(!pagerNRF.model.device){
			pagerNRF.model.device = Device.create();
		}
		pagerNRF.model.device.deviceType = DeviceTypeIds.STE_NRF;
		return pagerNRF;
	}
	if(json.device.deviceType == DeviceTypeIds.STE_RADPACK){
		var radpack = new SteRadPackDeviceViewModelImplemented(services);
		radpack.updateLocalFromDb(json);
		return radpack;
	}
	if(json.device.deviceType == DeviceTypeIds.STE_LRM){
		var lrm = new LrmDeviceViewModelImplemented(services);
		lrm.updateLocalFromDb(json);
		return lrm;
	}
	if(json.device.deviceType == DeviceTypeIds.STE_DEV_KIT){
		var radpack = new SteDevKitDeviceViewModelImplemented(services);
		radpack.updateLocalFromDb(json);
		return radpack;
	}

	// **************************************
	// ************* PureLocate *************
	// **************************************
	if(json.device.deviceType >= DeviceTypeIds.PL_BLUETOOTH_BEACON && json.device.deviceType <= DeviceTypeIds.PL_BLUETOOTH_BEACON_V2 || json.device.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V2_1){
		var bluetoothBeacon = new PlBeaconDeviceViewModelImplemented(services);
		bluetoothBeacon.updateLocalFromDb(json);
		return bluetoothBeacon;
	}
	if(json.device.deviceType >= DeviceTypeIds.PL_STATION && json.device.deviceType <= DeviceTypeIds.PL_STATION_V2){
		var station = new PlStationViewModelImplemented(services);
		station.updateLocalFromDb(json);
		return station;
	}
	
	// *******************************
	// ************* HUM *************
	// *******************************
	if(json.device.deviceType >= DeviceTypeIds.HUM_SENSOR && json.device.deviceType <= DeviceTypeIds.HUM_MAGNETIC){
		var humSensor = new HumSensorViewModelImplemented(services);
		humSensor.updateLocalFromDb(json);
		return humSensor;
	}
	if(json.device.deviceType == DeviceTypeIds.HUM_GATEWAY){
		var humGateway = new HumGatewayViewModelImplemented(services);
		humGateway.updateLocalFromDb(json);
		return humGateway;
	}
	if(json.device.deviceType == DeviceTypeIds.HUM_GATEWAY){
		var humGateway = new HumGatewayViewModelImplemented(services);
		humGateway.updateLocalFromDb(json);
		return humGateway;
	}

	// ***********************************
	// ************* Redline *************
	// ***********************************
	if(json.device.deviceType == DeviceTypeIds.RL_FIXED_GATEWAY){
		var rlFixed = new FixedGatewaytDeviceViewModelImplemented(services);
		rlFixed.updateLocalFromDb(json);
		return rlFixed;
	}
	if(json.device.deviceType == DeviceTypeIds.RL_BODY_WORN){
		var rlBody = new RlBodyWornDeviceViewModelImplemented(services);
		rlBody.updateLocalFromDb(json);
		return rlBody;
	}


	// ***********************************
	// ************* SL *************
	// ***********************************
	if(json.device.deviceType == DeviceTypeIds.SL_MULTI_COMMS){
		var comms = new CommsAssetDeviceViewModelImplemented(services);
		comms.updateLocalFromDb(json);
		return comms;
	}


	// ***********************************
	// ************* Mirion **************
	// ***********************************
	if(json.device.deviceType == DeviceTypeIds.MIRION_ACCURAD){
		var accurad = new MirionDeviceViewModelImplemented(services);
		accurad.updateLocalFromDb(json);
		return accurad;
	}

	

	return null;
}