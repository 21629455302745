// source: app/model/v1/data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var nanopb_pb = require('../../../nanopb_pb.js');
goog.object.extend(proto, nanopb_pb);
var app_model_v1_hum2_pb = require('../../../app/model/v1/hum2_pb.js');
goog.object.extend(proto, app_model_v1_hum2_pb);
goog.exportSymbol('proto.app.model.v1.Any', null, global);
goog.exportSymbol('proto.app.model.v1.Battery', null, global);
goog.exportSymbol('proto.app.model.v1.CellScanRecord', null, global);
goog.exportSymbol('proto.app.model.v1.Channel', null, global);
goog.exportSymbol('proto.app.model.v1.ChatMessage', null, global);
goog.exportSymbol('proto.app.model.v1.ChatMessage.ChatdataCase', null, global);
goog.exportSymbol('proto.app.model.v1.DeviceGroup', null, global);
goog.exportSymbol('proto.app.model.v1.DevicePartition', null, global);
goog.exportSymbol('proto.app.model.v1.Environment', null, global);
goog.exportSymbol('proto.app.model.v1.Event', null, global);
goog.exportSymbol('proto.app.model.v1.File', null, global);
goog.exportSymbol('proto.app.model.v1.GPSPosition', null, global);
goog.exportSymbol('proto.app.model.v1.GridEye', null, global);
goog.exportSymbol('proto.app.model.v1.Heart', null, global);
goog.exportSymbol('proto.app.model.v1.KeyValue', null, global);
goog.exportSymbol('proto.app.model.v1.KeyValue.ValueCase', null, global);
goog.exportSymbol('proto.app.model.v1.Log', null, global);
goog.exportSymbol('proto.app.model.v1.MessagePack', null, global);
goog.exportSymbol('proto.app.model.v1.MicroInformation', null, global);
goog.exportSymbol('proto.app.model.v1.Mission', null, global);
goog.exportSymbol('proto.app.model.v1.Motion_State', null, global);
goog.exportSymbol('proto.app.model.v1.OTAFile', null, global);
goog.exportSymbol('proto.app.model.v1.Organization', null, global);
goog.exportSymbol('proto.app.model.v1.PRIVACY', null, global);
goog.exportSymbol('proto.app.model.v1.Person', null, global);
goog.exportSymbol('proto.app.model.v1.Sensor', null, global);
goog.exportSymbol('proto.app.model.v1.SensorType', null, global);
goog.exportSymbol('proto.app.model.v1.TITLE', null, global);
goog.exportSymbol('proto.app.model.v1.Tag', null, global);
goog.exportSymbol('proto.app.model.v1.TagType', null, global);
goog.exportSymbol('proto.app.model.v1.Telemetry', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Any = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.Any, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Any.displayName = 'proto.app.model.v1.Any';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.CellScanRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.CellScanRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.CellScanRecord.displayName = 'proto.app.model.v1.CellScanRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.GPSPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.GPSPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.GPSPosition.displayName = 'proto.app.model.v1.GPSPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Environment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.Environment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Environment.displayName = 'proto.app.model.v1.Environment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Battery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.Battery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Battery.displayName = 'proto.app.model.v1.Battery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.GridEye = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.GridEye.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.GridEye, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.GridEye.displayName = 'proto.app.model.v1.GridEye';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Heart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.Heart.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.Heart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Heart.displayName = 'proto.app.model.v1.Heart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.MessagePack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.MessagePack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.MessagePack.displayName = 'proto.app.model.v1.MessagePack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.KeyValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.app.model.v1.KeyValue.oneofGroups_);
};
goog.inherits(proto.app.model.v1.KeyValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.KeyValue.displayName = 'proto.app.model.v1.KeyValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Telemetry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.Telemetry.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.Telemetry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Telemetry.displayName = 'proto.app.model.v1.Telemetry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Sensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.Sensor.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.Sensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Sensor.displayName = 'proto.app.model.v1.Sensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.MicroInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.MicroInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.MicroInformation.displayName = 'proto.app.model.v1.MicroInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.DevicePartition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.DevicePartition.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.DevicePartition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.DevicePartition.displayName = 'proto.app.model.v1.DevicePartition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.DeviceGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.DeviceGroup.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.DeviceGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.DeviceGroup.displayName = 'proto.app.model.v1.DeviceGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.OTAFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.OTAFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.OTAFile.displayName = 'proto.app.model.v1.OTAFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.File.displayName = 'proto.app.model.v1.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Person = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.Person.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.Person, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Person.displayName = 'proto.app.model.v1.Person';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.ChatMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.app.model.v1.ChatMessage.oneofGroups_);
};
goog.inherits(proto.app.model.v1.ChatMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.ChatMessage.displayName = 'proto.app.model.v1.ChatMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Event.displayName = 'proto.app.model.v1.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Mission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.Mission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Mission.displayName = 'proto.app.model.v1.Mission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Channel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.Channel.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.Channel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Channel.displayName = 'proto.app.model.v1.Channel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Tag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.Tag.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Tag.displayName = 'proto.app.model.v1.Tag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.Organization.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Organization.displayName = 'proto.app.model.v1.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.Log = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.Log.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.Log, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.Log.displayName = 'proto.app.model.v1.Log';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Any.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Any.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Any} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Any.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: msg.getValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Any}
 */
proto.app.model.v1.Any.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Any;
  return proto.app.model.v1.Any.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Any} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Any}
 */
proto.app.model.v1.Any.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeUrl(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Any.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Any.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Any} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Any.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string type_url = 1;
 * @return {string}
 */
proto.app.model.v1.Any.prototype.getTypeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Any} returns this
 */
proto.app.model.v1.Any.prototype.setTypeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes value = 2;
 * @return {string}
 */
proto.app.model.v1.Any.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes value = 2;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.app.model.v1.Any.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.Any.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.Any} returns this
 */
proto.app.model.v1.Any.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.CellScanRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.CellScanRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.CellScanRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.CellScanRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    mcc: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mnc: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    las: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rssi: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.CellScanRecord}
 */
proto.app.model.v1.CellScanRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.CellScanRecord;
  return proto.app.model.v1.CellScanRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.CellScanRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.CellScanRecord}
 */
proto.app.model.v1.CellScanRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMcc(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMnc(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLas(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRssi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.CellScanRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.CellScanRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.CellScanRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.CellScanRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMcc();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMnc();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLas();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRssi();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 mcc = 1;
 * @return {number}
 */
proto.app.model.v1.CellScanRecord.prototype.getMcc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.CellScanRecord} returns this
 */
proto.app.model.v1.CellScanRecord.prototype.setMcc = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 mnc = 2;
 * @return {number}
 */
proto.app.model.v1.CellScanRecord.prototype.getMnc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.CellScanRecord} returns this
 */
proto.app.model.v1.CellScanRecord.prototype.setMnc = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 cid = 3;
 * @return {number}
 */
proto.app.model.v1.CellScanRecord.prototype.getCid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.CellScanRecord} returns this
 */
proto.app.model.v1.CellScanRecord.prototype.setCid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 las = 4;
 * @return {number}
 */
proto.app.model.v1.CellScanRecord.prototype.getLas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.CellScanRecord} returns this
 */
proto.app.model.v1.CellScanRecord.prototype.setLas = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 rssi = 5;
 * @return {number}
 */
proto.app.model.v1.CellScanRecord.prototype.getRssi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.CellScanRecord} returns this
 */
proto.app.model.v1.CellScanRecord.prototype.setRssi = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.GPSPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.GPSPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.GPSPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.GPSPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude1e7: jspb.Message.getFieldWithDefault(msg, 1, 0),
    longitude1e7: jspb.Message.getFieldWithDefault(msg, 2, 0),
    elevationCm: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fixType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    velocityKmh: jspb.Message.getFieldWithDefault(msg, 5, 0),
    headingDegrees: jspb.Message.getFieldWithDefault(msg, 6, 0),
    satsLocked: jspb.Message.getFieldWithDefault(msg, 7, 0),
    satsInView: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pdop: jspb.Message.getFieldWithDefault(msg, 9, 0),
    geopointAccuracyCm: jspb.Message.getFieldWithDefault(msg, 10, 0),
    elevationAccuracyCm: jspb.Message.getFieldWithDefault(msg, 11, 0),
    epochMiliseconds: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.GPSPosition}
 */
proto.app.model.v1.GPSPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.GPSPosition;
  return proto.app.model.v1.GPSPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.GPSPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.GPSPosition}
 */
proto.app.model.v1.GPSPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setLatitude1e7(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setLongitude1e7(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setElevationCm(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFixType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVelocityKmh(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeadingDegrees(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSatsLocked(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSatsInView(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPdop(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGeopointAccuracyCm(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setElevationAccuracyCm(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEpochMiliseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.GPSPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.GPSPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.GPSPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.GPSPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude1e7();
  if (f !== 0) {
    writer.writeSfixed32(
      1,
      f
    );
  }
  f = message.getLongitude1e7();
  if (f !== 0) {
    writer.writeSfixed32(
      2,
      f
    );
  }
  f = message.getElevationCm();
  if (f !== 0) {
    writer.writeSint32(
      3,
      f
    );
  }
  f = message.getFixType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getVelocityKmh();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getHeadingDegrees();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSatsLocked();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSatsInView();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPdop();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getGeopointAccuracyCm();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getElevationAccuracyCm();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getEpochMiliseconds();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
};


/**
 * optional sfixed32 latitude_1e7 = 1;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getLatitude1e7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setLatitude1e7 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sfixed32 longitude_1e7 = 2;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getLongitude1e7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setLongitude1e7 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional sint32 elevation_cm = 3;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getElevationCm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setElevationCm = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 fix_type = 4;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getFixType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setFixType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 velocity_kmh = 5;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getVelocityKmh = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setVelocityKmh = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 heading_degrees = 6;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getHeadingDegrees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setHeadingDegrees = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 sats_locked = 7;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getSatsLocked = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setSatsLocked = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 sats_in_view = 8;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getSatsInView = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setSatsInView = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 pdop = 9;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getPdop = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setPdop = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 geopoint_accuracy_cm = 10;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getGeopointAccuracyCm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setGeopointAccuracyCm = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 elevation_accuracy_cm = 11;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getElevationAccuracyCm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setElevationAccuracyCm = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint64 epoch_miliseconds = 12;
 * @return {number}
 */
proto.app.model.v1.GPSPosition.prototype.getEpochMiliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.GPSPosition} returns this
 */
proto.app.model.v1.GPSPosition.prototype.setEpochMiliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Environment.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Environment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Environment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Environment.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemTemperatureCelsius: jspb.Message.getFieldWithDefault(msg, 1, 0),
    temperatureCelsius: jspb.Message.getFieldWithDefault(msg, 2, 0),
    barometric: jspb.Message.getFieldWithDefault(msg, 3, 0),
    humidity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gas: jspb.Message.getFieldWithDefault(msg, 5, 0),
    o2: jspb.Message.getFieldWithDefault(msg, 6, 0),
    h2sCo: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Environment}
 */
proto.app.model.v1.Environment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Environment;
  return proto.app.model.v1.Environment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Environment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Environment}
 */
proto.app.model.v1.Environment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSystemTemperatureCelsius(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTemperatureCelsius(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBarometric(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHumidity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGas(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setO2(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setH2sCo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Environment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Environment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Environment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Environment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemTemperatureCelsius();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getTemperatureCelsius();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getBarometric();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getHumidity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGas();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getO2();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getH2sCo();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional sint32 system_temperature_celsius = 1;
 * @return {number}
 */
proto.app.model.v1.Environment.prototype.getSystemTemperatureCelsius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Environment} returns this
 */
proto.app.model.v1.Environment.prototype.setSystemTemperatureCelsius = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 temperature_celsius = 2;
 * @return {number}
 */
proto.app.model.v1.Environment.prototype.getTemperatureCelsius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Environment} returns this
 */
proto.app.model.v1.Environment.prototype.setTemperatureCelsius = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 barometric = 3;
 * @return {number}
 */
proto.app.model.v1.Environment.prototype.getBarometric = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Environment} returns this
 */
proto.app.model.v1.Environment.prototype.setBarometric = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 humidity = 4;
 * @return {number}
 */
proto.app.model.v1.Environment.prototype.getHumidity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Environment} returns this
 */
proto.app.model.v1.Environment.prototype.setHumidity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 gas = 5;
 * @return {number}
 */
proto.app.model.v1.Environment.prototype.getGas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Environment} returns this
 */
proto.app.model.v1.Environment.prototype.setGas = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 o2 = 6;
 * @return {number}
 */
proto.app.model.v1.Environment.prototype.getO2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Environment} returns this
 */
proto.app.model.v1.Environment.prototype.setO2 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 h2s_co = 7;
 * @return {number}
 */
proto.app.model.v1.Environment.prototype.getH2sCo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Environment} returns this
 */
proto.app.model.v1.Environment.prototype.setH2sCo = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Battery.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Battery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Battery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Battery.toObject = function(includeInstance, msg) {
  var f, obj = {
    batteryVoltageCentivolts: jspb.Message.getFieldWithDefault(msg, 1, 0),
    solarVoltageCentivolts: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mainVoltageCentivolts: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Battery}
 */
proto.app.model.v1.Battery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Battery;
  return proto.app.model.v1.Battery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Battery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Battery}
 */
proto.app.model.v1.Battery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatteryVoltageCentivolts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSolarVoltageCentivolts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMainVoltageCentivolts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Battery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Battery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Battery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Battery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatteryVoltageCentivolts();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSolarVoltageCentivolts();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMainVoltageCentivolts();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional uint32 battery_voltage_centivolts = 1;
 * @return {number}
 */
proto.app.model.v1.Battery.prototype.getBatteryVoltageCentivolts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Battery} returns this
 */
proto.app.model.v1.Battery.prototype.setBatteryVoltageCentivolts = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 solar_voltage_centivolts = 2;
 * @return {number}
 */
proto.app.model.v1.Battery.prototype.getSolarVoltageCentivolts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Battery} returns this
 */
proto.app.model.v1.Battery.prototype.setSolarVoltageCentivolts = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 main_voltage_centivolts = 3;
 * @return {number}
 */
proto.app.model.v1.Battery.prototype.getMainVoltageCentivolts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Battery} returns this
 */
proto.app.model.v1.Battery.prototype.setMainVoltageCentivolts = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.GridEye.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.GridEye.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.GridEye.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.GridEye} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.GridEye.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.GridEye}
 */
proto.app.model.v1.GridEye.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.GridEye;
  return proto.app.model.v1.GridEye.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.GridEye} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.GridEye}
 */
proto.app.model.v1.GridEye.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addData(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.GridEye.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.GridEye.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.GridEye} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.GridEye.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 data = 1;
 * @return {!Array<number>}
 */
proto.app.model.v1.GridEye.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.model.v1.GridEye} returns this
 */
proto.app.model.v1.GridEye.prototype.setDataList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.GridEye} returns this
 */
proto.app.model.v1.GridEye.prototype.addData = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.GridEye} returns this
 */
proto.app.model.v1.GridEye.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.Heart.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Heart.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Heart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Heart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Heart.toObject = function(includeInstance, msg) {
  var f, obj = {
    heartRate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    heartRateSamplesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Heart}
 */
proto.app.model.v1.Heart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Heart;
  return proto.app.model.v1.Heart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Heart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Heart}
 */
proto.app.model.v1.Heart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeartRate(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHeartRateSamples(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Heart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Heart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Heart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Heart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeartRate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHeartRateSamplesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 heart_rate = 1;
 * @return {number}
 */
proto.app.model.v1.Heart.prototype.getHeartRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Heart} returns this
 */
proto.app.model.v1.Heart.prototype.setHeartRate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 heart_rate_samples = 2;
 * @return {!Array<number>}
 */
proto.app.model.v1.Heart.prototype.getHeartRateSamplesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.model.v1.Heart} returns this
 */
proto.app.model.v1.Heart.prototype.setHeartRateSamplesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Heart} returns this
 */
proto.app.model.v1.Heart.prototype.addHeartRateSamples = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Heart} returns this
 */
proto.app.model.v1.Heart.prototype.clearHeartRateSamplesList = function() {
  return this.setHeartRateSamplesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.MessagePack.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.MessagePack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.MessagePack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.MessagePack.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.MessagePack}
 */
proto.app.model.v1.MessagePack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.MessagePack;
  return proto.app.model.v1.MessagePack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.MessagePack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.MessagePack}
 */
proto.app.model.v1.MessagePack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.MessagePack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.MessagePack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.MessagePack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.MessagePack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.app.model.v1.MessagePack.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.MessagePack} returns this
 */
proto.app.model.v1.MessagePack.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {string}
 */
proto.app.model.v1.MessagePack.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.app.model.v1.MessagePack.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.MessagePack.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.MessagePack} returns this
 */
proto.app.model.v1.MessagePack.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.app.model.v1.KeyValue.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.app.model.v1.KeyValue.ValueCase = {
  VALUE_NOT_SET: 0,
  VALUE_STRING: 2,
  VALUE_BYTES: 3
};

/**
 * @return {proto.app.model.v1.KeyValue.ValueCase}
 */
proto.app.model.v1.KeyValue.prototype.getValueCase = function() {
  return /** @type {proto.app.model.v1.KeyValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.app.model.v1.KeyValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.KeyValue.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.KeyValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.KeyValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.KeyValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valueString: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueBytes: msg.getValueBytes_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.KeyValue}
 */
proto.app.model.v1.KeyValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.KeyValue;
  return proto.app.model.v1.KeyValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.KeyValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.KeyValue}
 */
proto.app.model.v1.KeyValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueString(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValueBytes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.KeyValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.KeyValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.KeyValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.KeyValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.app.model.v1.KeyValue.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.KeyValue} returns this
 */
proto.app.model.v1.KeyValue.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value_string = 2;
 * @return {string}
 */
proto.app.model.v1.KeyValue.prototype.getValueString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.KeyValue} returns this
 */
proto.app.model.v1.KeyValue.prototype.setValueString = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.app.model.v1.KeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.app.model.v1.KeyValue} returns this
 */
proto.app.model.v1.KeyValue.prototype.clearValueString = function() {
  return jspb.Message.setOneofField(this, 2, proto.app.model.v1.KeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.KeyValue.prototype.hasValueString = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes value_bytes = 3;
 * @return {string}
 */
proto.app.model.v1.KeyValue.prototype.getValueBytes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes value_bytes = 3;
 * This is a type-conversion wrapper around `getValueBytes()`
 * @return {string}
 */
proto.app.model.v1.KeyValue.prototype.getValueBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValueBytes()));
};


/**
 * optional bytes value_bytes = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValueBytes()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.KeyValue.prototype.getValueBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValueBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.KeyValue} returns this
 */
proto.app.model.v1.KeyValue.prototype.setValueBytes = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.app.model.v1.KeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.app.model.v1.KeyValue} returns this
 */
proto.app.model.v1.KeyValue.prototype.clearValueBytes = function() {
  return jspb.Message.setOneofField(this, 3, proto.app.model.v1.KeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.KeyValue.prototype.hasValueBytes = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.Telemetry.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Telemetry.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Telemetry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Telemetry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Telemetry.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messageCounter: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sensorType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sentMs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    receivedMs: jspb.Message.getFieldWithDefault(msg, 6, 0),
    deletedMs: jspb.Message.getFieldWithDefault(msg, 7, 0),
    msgPack: (f = msg.getMsgPack()) && proto.app.model.v1.MessagePack.toObject(includeInstance, f),
    keyValuesList: jspb.Message.toObjectList(msg.getKeyValuesList(),
    proto.app.model.v1.KeyValue.toObject, includeInstance),
    battery: (f = msg.getBattery()) && proto.app.model.v1.Battery.toObject(includeInstance, f),
    motionState: jspb.Message.getFieldWithDefault(msg, 11, 0),
    position: (f = msg.getPosition()) && proto.app.model.v1.GPSPosition.toObject(includeInstance, f),
    cell: (f = msg.getCell()) && proto.app.model.v1.CellScanRecord.toObject(includeInstance, f),
    environment: (f = msg.getEnvironment()) && proto.app.model.v1.Environment.toObject(includeInstance, f),
    grideye: (f = msg.getGrideye()) && proto.app.model.v1.GridEye.toObject(includeInstance, f),
    heart: (f = msg.getHeart()) && proto.app.model.v1.Heart.toObject(includeInstance, f),
    customData: (f = msg.getCustomData()) && proto.app.model.v1.Any.toObject(includeInstance, f),
    gatewayData: (f = msg.getGatewayData()) && app_model_v1_hum2_pb.HUM_Gateway_Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Telemetry}
 */
proto.app.model.v1.Telemetry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Telemetry;
  return proto.app.model.v1.Telemetry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Telemetry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Telemetry}
 */
proto.app.model.v1.Telemetry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMessageCounter(value);
      break;
    case 4:
      var value = /** @type {!proto.app.model.v1.SensorType} */ (reader.readEnum());
      msg.setSensorType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSentMs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReceivedMs(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeletedMs(value);
      break;
    case 8:
      var value = new proto.app.model.v1.MessagePack;
      reader.readMessage(value,proto.app.model.v1.MessagePack.deserializeBinaryFromReader);
      msg.setMsgPack(value);
      break;
    case 9:
      var value = new proto.app.model.v1.KeyValue;
      reader.readMessage(value,proto.app.model.v1.KeyValue.deserializeBinaryFromReader);
      msg.addKeyValues(value);
      break;
    case 10:
      var value = new proto.app.model.v1.Battery;
      reader.readMessage(value,proto.app.model.v1.Battery.deserializeBinaryFromReader);
      msg.setBattery(value);
      break;
    case 11:
      var value = /** @type {!proto.app.model.v1.Motion_State} */ (reader.readEnum());
      msg.setMotionState(value);
      break;
    case 12:
      var value = new proto.app.model.v1.GPSPosition;
      reader.readMessage(value,proto.app.model.v1.GPSPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 13:
      var value = new proto.app.model.v1.CellScanRecord;
      reader.readMessage(value,proto.app.model.v1.CellScanRecord.deserializeBinaryFromReader);
      msg.setCell(value);
      break;
    case 14:
      var value = new proto.app.model.v1.Environment;
      reader.readMessage(value,proto.app.model.v1.Environment.deserializeBinaryFromReader);
      msg.setEnvironment(value);
      break;
    case 15:
      var value = new proto.app.model.v1.GridEye;
      reader.readMessage(value,proto.app.model.v1.GridEye.deserializeBinaryFromReader);
      msg.setGrideye(value);
      break;
    case 16:
      var value = new proto.app.model.v1.Heart;
      reader.readMessage(value,proto.app.model.v1.Heart.deserializeBinaryFromReader);
      msg.setHeart(value);
      break;
    case 17:
      var value = new proto.app.model.v1.Any;
      reader.readMessage(value,proto.app.model.v1.Any.deserializeBinaryFromReader);
      msg.setCustomData(value);
      break;
    case 18:
      var value = new app_model_v1_hum2_pb.HUM_Gateway_Data;
      reader.readMessage(value,app_model_v1_hum2_pb.HUM_Gateway_Data.deserializeBinaryFromReader);
      msg.setGatewayData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Telemetry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Telemetry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Telemetry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Telemetry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getMessageCounter();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getSensorType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSentMs();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getReceivedMs();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getDeletedMs();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getMsgPack();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.app.model.v1.MessagePack.serializeBinaryToWriter
    );
  }
  f = message.getKeyValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.app.model.v1.KeyValue.serializeBinaryToWriter
    );
  }
  f = message.getBattery();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.app.model.v1.Battery.serializeBinaryToWriter
    );
  }
  f = message.getMotionState();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.app.model.v1.GPSPosition.serializeBinaryToWriter
    );
  }
  f = message.getCell();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.app.model.v1.CellScanRecord.serializeBinaryToWriter
    );
  }
  f = message.getEnvironment();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.app.model.v1.Environment.serializeBinaryToWriter
    );
  }
  f = message.getGrideye();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.app.model.v1.GridEye.serializeBinaryToWriter
    );
  }
  f = message.getHeart();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.app.model.v1.Heart.serializeBinaryToWriter
    );
  }
  f = message.getCustomData();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.app.model.v1.Any.serializeBinaryToWriter
    );
  }
  f = message.getGatewayData();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      app_model_v1_hum2_pb.HUM_Gateway_Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Telemetry.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 uuid = 2;
 * @return {number}
 */
proto.app.model.v1.Telemetry.prototype.getUuid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.setUuid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 message_counter = 3;
 * @return {number}
 */
proto.app.model.v1.Telemetry.prototype.getMessageCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.setMessageCounter = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional SensorType sensor_type = 4;
 * @return {!proto.app.model.v1.SensorType}
 */
proto.app.model.v1.Telemetry.prototype.getSensorType = function() {
  return /** @type {!proto.app.model.v1.SensorType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.app.model.v1.SensorType} value
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.setSensorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 sent_ms = 5;
 * @return {number}
 */
proto.app.model.v1.Telemetry.prototype.getSentMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.setSentMs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 received_ms = 6;
 * @return {number}
 */
proto.app.model.v1.Telemetry.prototype.getReceivedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.setReceivedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 deleted_ms = 7;
 * @return {number}
 */
proto.app.model.v1.Telemetry.prototype.getDeletedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.setDeletedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional MessagePack msg_pack = 8;
 * @return {?proto.app.model.v1.MessagePack}
 */
proto.app.model.v1.Telemetry.prototype.getMsgPack = function() {
  return /** @type{?proto.app.model.v1.MessagePack} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.MessagePack, 8));
};


/**
 * @param {?proto.app.model.v1.MessagePack|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setMsgPack = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearMsgPack = function() {
  return this.setMsgPack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasMsgPack = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated KeyValue key_values = 9;
 * @return {!Array<!proto.app.model.v1.KeyValue>}
 */
proto.app.model.v1.Telemetry.prototype.getKeyValuesList = function() {
  return /** @type{!Array<!proto.app.model.v1.KeyValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.KeyValue, 9));
};


/**
 * @param {!Array<!proto.app.model.v1.KeyValue>} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setKeyValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.app.model.v1.KeyValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.KeyValue}
 */
proto.app.model.v1.Telemetry.prototype.addKeyValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.app.model.v1.KeyValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearKeyValuesList = function() {
  return this.setKeyValuesList([]);
};


/**
 * optional Battery battery = 10;
 * @return {?proto.app.model.v1.Battery}
 */
proto.app.model.v1.Telemetry.prototype.getBattery = function() {
  return /** @type{?proto.app.model.v1.Battery} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.Battery, 10));
};


/**
 * @param {?proto.app.model.v1.Battery|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setBattery = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearBattery = function() {
  return this.setBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Motion_State motion_state = 11;
 * @return {!proto.app.model.v1.Motion_State}
 */
proto.app.model.v1.Telemetry.prototype.getMotionState = function() {
  return /** @type {!proto.app.model.v1.Motion_State} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.app.model.v1.Motion_State} value
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.setMotionState = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional GPSPosition position = 12;
 * @return {?proto.app.model.v1.GPSPosition}
 */
proto.app.model.v1.Telemetry.prototype.getPosition = function() {
  return /** @type{?proto.app.model.v1.GPSPosition} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.GPSPosition, 12));
};


/**
 * @param {?proto.app.model.v1.GPSPosition|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional CellScanRecord cell = 13;
 * @return {?proto.app.model.v1.CellScanRecord}
 */
proto.app.model.v1.Telemetry.prototype.getCell = function() {
  return /** @type{?proto.app.model.v1.CellScanRecord} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.CellScanRecord, 13));
};


/**
 * @param {?proto.app.model.v1.CellScanRecord|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setCell = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearCell = function() {
  return this.setCell(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasCell = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Environment environment = 14;
 * @return {?proto.app.model.v1.Environment}
 */
proto.app.model.v1.Telemetry.prototype.getEnvironment = function() {
  return /** @type{?proto.app.model.v1.Environment} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.Environment, 14));
};


/**
 * @param {?proto.app.model.v1.Environment|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setEnvironment = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearEnvironment = function() {
  return this.setEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasEnvironment = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional GridEye gridEye = 15;
 * @return {?proto.app.model.v1.GridEye}
 */
proto.app.model.v1.Telemetry.prototype.getGrideye = function() {
  return /** @type{?proto.app.model.v1.GridEye} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.GridEye, 15));
};


/**
 * @param {?proto.app.model.v1.GridEye|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setGrideye = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearGrideye = function() {
  return this.setGrideye(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasGrideye = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Heart heart = 16;
 * @return {?proto.app.model.v1.Heart}
 */
proto.app.model.v1.Telemetry.prototype.getHeart = function() {
  return /** @type{?proto.app.model.v1.Heart} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.Heart, 16));
};


/**
 * @param {?proto.app.model.v1.Heart|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setHeart = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearHeart = function() {
  return this.setHeart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasHeart = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Any custom_data = 17;
 * @return {?proto.app.model.v1.Any}
 */
proto.app.model.v1.Telemetry.prototype.getCustomData = function() {
  return /** @type{?proto.app.model.v1.Any} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.Any, 17));
};


/**
 * @param {?proto.app.model.v1.Any|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setCustomData = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearCustomData = function() {
  return this.setCustomData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasCustomData = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional HUM_Gateway_Data gateway_data = 18;
 * @return {?proto.app.model.v1.HUM_Gateway_Data}
 */
proto.app.model.v1.Telemetry.prototype.getGatewayData = function() {
  return /** @type{?proto.app.model.v1.HUM_Gateway_Data} */ (
    jspb.Message.getWrapperField(this, app_model_v1_hum2_pb.HUM_Gateway_Data, 18));
};


/**
 * @param {?proto.app.model.v1.HUM_Gateway_Data|undefined} value
 * @return {!proto.app.model.v1.Telemetry} returns this
*/
proto.app.model.v1.Telemetry.prototype.setGatewayData = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Telemetry} returns this
 */
proto.app.model.v1.Telemetry.prototype.clearGatewayData = function() {
  return this.setGatewayData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Telemetry.prototype.hasGatewayData = function() {
  return jspb.Message.getField(this, 18) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.Sensor.repeatedFields_ = [16,19,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Sensor.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Sensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Sensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Sensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    serial: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdMs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deletedMs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    foundName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isLocal: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    microInformation: (f = msg.getMicroInformation()) && proto.app.model.v1.MicroInformation.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    iconUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    color: jspb.Message.getFieldWithDefault(msg, 13, ""),
    lastConnectionMs: jspb.Message.getFieldWithDefault(msg, 14, 0),
    latestTelemetry: (f = msg.getLatestTelemetry()) && proto.app.model.v1.Telemetry.toObject(includeInstance, f),
    telemetryList: jspb.Message.toObjectList(msg.getTelemetryList(),
    proto.app.model.v1.Telemetry.toObject, includeInstance),
    settings: (f = msg.getSettings()) && proto.app.model.v1.MessagePack.toObject(includeInstance, f),
    deviceGroupId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.app.model.v1.Tag.toObject, includeInstance),
    manufacturer: jspb.Message.getFieldWithDefault(msg, 20, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 21, ""),
    hardwareVersion: jspb.Message.getFieldWithDefault(msg, 22, ""),
    manufacturerType: jspb.Message.getFieldWithDefault(msg, 23, ""),
    isBlocked: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    logMask: jspb.Message.getFieldWithDefault(msg, 25, 0),
    keyvaluesList: jspb.Message.toObjectList(msg.getKeyvaluesList(),
    proto.app.model.v1.KeyValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Sensor}
 */
proto.app.model.v1.Sensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Sensor;
  return proto.app.model.v1.Sensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Sensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Sensor}
 */
proto.app.model.v1.Sensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSerial(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedMs(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeletedMs(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFoundName(value);
      break;
    case 8:
      var value = /** @type {!proto.app.model.v1.SensorType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLocal(value);
      break;
    case 10:
      var value = new proto.app.model.v1.MicroInformation;
      reader.readMessage(value,proto.app.model.v1.MicroInformation.deserializeBinaryFromReader);
      msg.setMicroInformation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastConnectionMs(value);
      break;
    case 15:
      var value = new proto.app.model.v1.Telemetry;
      reader.readMessage(value,proto.app.model.v1.Telemetry.deserializeBinaryFromReader);
      msg.setLatestTelemetry(value);
      break;
    case 16:
      var value = new proto.app.model.v1.Telemetry;
      reader.readMessage(value,proto.app.model.v1.Telemetry.deserializeBinaryFromReader);
      msg.addTelemetry(value);
      break;
    case 17:
      var value = new proto.app.model.v1.MessagePack;
      reader.readMessage(value,proto.app.model.v1.MessagePack.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeviceGroupId(value);
      break;
    case 19:
      var value = new proto.app.model.v1.Tag;
      reader.readMessage(value,proto.app.model.v1.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturer(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareVersion(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturerType(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBlocked(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLogMask(value);
      break;
    case 26:
      var value = new proto.app.model.v1.KeyValue;
      reader.readMessage(value,proto.app.model.v1.KeyValue.deserializeBinaryFromReader);
      msg.addKeyvalues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Sensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Sensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Sensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Sensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getSerial();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCreatedMs();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDeletedMs();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFoundName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getIsLocal();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMicroInformation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.app.model.v1.MicroInformation.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIconUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLastConnectionMs();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getLatestTelemetry();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.app.model.v1.Telemetry.serializeBinaryToWriter
    );
  }
  f = message.getTelemetryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.app.model.v1.Telemetry.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.app.model.v1.MessagePack.serializeBinaryToWriter
    );
  }
  f = message.getDeviceGroupId();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.app.model.v1.Tag.serializeBinaryToWriter
    );
  }
  f = message.getManufacturer();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getManufacturerType();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getIsBlocked();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getLogMask();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getKeyvaluesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.app.model.v1.KeyValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 uuid = 2;
 * @return {number}
 */
proto.app.model.v1.Sensor.prototype.getUuid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setUuid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 serial = 3;
 * @return {number}
 */
proto.app.model.v1.Sensor.prototype.getSerial = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setSerial = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 created_ms = 4;
 * @return {number}
 */
proto.app.model.v1.Sensor.prototype.getCreatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setCreatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 deleted_ms = 5;
 * @return {number}
 */
proto.app.model.v1.Sensor.prototype.getDeletedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setDeletedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string found_name = 7;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getFoundName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setFoundName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional SensorType type = 8;
 * @return {!proto.app.model.v1.SensorType}
 */
proto.app.model.v1.Sensor.prototype.getType = function() {
  return /** @type {!proto.app.model.v1.SensorType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.app.model.v1.SensorType} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool is_local = 9;
 * @return {boolean}
 */
proto.app.model.v1.Sensor.prototype.getIsLocal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setIsLocal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional MicroInformation micro_information = 10;
 * @return {?proto.app.model.v1.MicroInformation}
 */
proto.app.model.v1.Sensor.prototype.getMicroInformation = function() {
  return /** @type{?proto.app.model.v1.MicroInformation} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.MicroInformation, 10));
};


/**
 * @param {?proto.app.model.v1.MicroInformation|undefined} value
 * @return {!proto.app.model.v1.Sensor} returns this
*/
proto.app.model.v1.Sensor.prototype.setMicroInformation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.clearMicroInformation = function() {
  return this.setMicroInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Sensor.prototype.hasMicroInformation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string icon_url = 12;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getIconUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setIconUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string color = 13;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 last_connection_ms = 14;
 * @return {number}
 */
proto.app.model.v1.Sensor.prototype.getLastConnectionMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setLastConnectionMs = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Telemetry latest_telemetry = 15;
 * @return {?proto.app.model.v1.Telemetry}
 */
proto.app.model.v1.Sensor.prototype.getLatestTelemetry = function() {
  return /** @type{?proto.app.model.v1.Telemetry} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.Telemetry, 15));
};


/**
 * @param {?proto.app.model.v1.Telemetry|undefined} value
 * @return {!proto.app.model.v1.Sensor} returns this
*/
proto.app.model.v1.Sensor.prototype.setLatestTelemetry = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.clearLatestTelemetry = function() {
  return this.setLatestTelemetry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Sensor.prototype.hasLatestTelemetry = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated Telemetry telemetry = 16;
 * @return {!Array<!proto.app.model.v1.Telemetry>}
 */
proto.app.model.v1.Sensor.prototype.getTelemetryList = function() {
  return /** @type{!Array<!proto.app.model.v1.Telemetry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.Telemetry, 16));
};


/**
 * @param {!Array<!proto.app.model.v1.Telemetry>} value
 * @return {!proto.app.model.v1.Sensor} returns this
*/
proto.app.model.v1.Sensor.prototype.setTelemetryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.app.model.v1.Telemetry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Telemetry}
 */
proto.app.model.v1.Sensor.prototype.addTelemetry = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.app.model.v1.Telemetry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.clearTelemetryList = function() {
  return this.setTelemetryList([]);
};


/**
 * optional MessagePack settings = 17;
 * @return {?proto.app.model.v1.MessagePack}
 */
proto.app.model.v1.Sensor.prototype.getSettings = function() {
  return /** @type{?proto.app.model.v1.MessagePack} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.MessagePack, 17));
};


/**
 * @param {?proto.app.model.v1.MessagePack|undefined} value
 * @return {!proto.app.model.v1.Sensor} returns this
*/
proto.app.model.v1.Sensor.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.Sensor.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional uint32 device_group_id = 18;
 * @return {number}
 */
proto.app.model.v1.Sensor.prototype.getDeviceGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setDeviceGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * repeated Tag tags = 19;
 * @return {!Array<!proto.app.model.v1.Tag>}
 */
proto.app.model.v1.Sensor.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.app.model.v1.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.Tag, 19));
};


/**
 * @param {!Array<!proto.app.model.v1.Tag>} value
 * @return {!proto.app.model.v1.Sensor} returns this
*/
proto.app.model.v1.Sensor.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.app.model.v1.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Tag}
 */
proto.app.model.v1.Sensor.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.app.model.v1.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string manufacturer = 20;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getManufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setManufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string product_name = 21;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string hardware_version = 22;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setHardwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string manufacturer_type = 23;
 * @return {string}
 */
proto.app.model.v1.Sensor.prototype.getManufacturerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setManufacturerType = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool is_blocked = 24;
 * @return {boolean}
 */
proto.app.model.v1.Sensor.prototype.getIsBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setIsBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional uint32 log_mask = 25;
 * @return {number}
 */
proto.app.model.v1.Sensor.prototype.getLogMask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.setLogMask = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * repeated KeyValue keyValues = 26;
 * @return {!Array<!proto.app.model.v1.KeyValue>}
 */
proto.app.model.v1.Sensor.prototype.getKeyvaluesList = function() {
  return /** @type{!Array<!proto.app.model.v1.KeyValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.KeyValue, 26));
};


/**
 * @param {!Array<!proto.app.model.v1.KeyValue>} value
 * @return {!proto.app.model.v1.Sensor} returns this
*/
proto.app.model.v1.Sensor.prototype.setKeyvaluesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.app.model.v1.KeyValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.KeyValue}
 */
proto.app.model.v1.Sensor.prototype.addKeyvalues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.app.model.v1.KeyValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Sensor} returns this
 */
proto.app.model.v1.Sensor.prototype.clearKeyvaluesList = function() {
  return this.setKeyvaluesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.MicroInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.MicroInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.MicroInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.MicroInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastConnectionMs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    microId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    publicKey: msg.getPublicKey_asB64(),
    privateKey: msg.getPrivateKey_asB64(),
    ipV4: msg.getIpV4_asB64(),
    ipV6: msg.getIpV6_asB64(),
    portIn: jspb.Message.getFieldWithDefault(msg, 7, 0),
    portOut: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.MicroInformation}
 */
proto.app.model.v1.MicroInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.MicroInformation;
  return proto.app.model.v1.MicroInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.MicroInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.MicroInformation}
 */
proto.app.model.v1.MicroInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastConnectionMs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMicroId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPublicKey(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPrivateKey(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setIpV4(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setIpV6(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPortIn(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPortOut(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.MicroInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.MicroInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.MicroInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.MicroInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastConnectionMs();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMicroId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPublicKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getPrivateKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getIpV4_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getIpV6_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getPortIn();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getPortOut();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 last_connection_ms = 1;
 * @return {number}
 */
proto.app.model.v1.MicroInformation.prototype.getLastConnectionMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.MicroInformation} returns this
 */
proto.app.model.v1.MicroInformation.prototype.setLastConnectionMs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 micro_id = 2;
 * @return {number}
 */
proto.app.model.v1.MicroInformation.prototype.getMicroId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.MicroInformation} returns this
 */
proto.app.model.v1.MicroInformation.prototype.setMicroId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes public_key = 3;
 * @return {string}
 */
proto.app.model.v1.MicroInformation.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes public_key = 3;
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {string}
 */
proto.app.model.v1.MicroInformation.prototype.getPublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPublicKey()));
};


/**
 * optional bytes public_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.MicroInformation.prototype.getPublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPublicKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.MicroInformation} returns this
 */
proto.app.model.v1.MicroInformation.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes private_key = 4;
 * @return {string}
 */
proto.app.model.v1.MicroInformation.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes private_key = 4;
 * This is a type-conversion wrapper around `getPrivateKey()`
 * @return {string}
 */
proto.app.model.v1.MicroInformation.prototype.getPrivateKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPrivateKey()));
};


/**
 * optional bytes private_key = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPrivateKey()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.MicroInformation.prototype.getPrivateKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPrivateKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.MicroInformation} returns this
 */
proto.app.model.v1.MicroInformation.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bytes ip_v4 = 5;
 * @return {string}
 */
proto.app.model.v1.MicroInformation.prototype.getIpV4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes ip_v4 = 5;
 * This is a type-conversion wrapper around `getIpV4()`
 * @return {string}
 */
proto.app.model.v1.MicroInformation.prototype.getIpV4_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getIpV4()));
};


/**
 * optional bytes ip_v4 = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getIpV4()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.MicroInformation.prototype.getIpV4_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getIpV4()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.MicroInformation} returns this
 */
proto.app.model.v1.MicroInformation.prototype.setIpV4 = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional bytes ip_v6 = 6;
 * @return {string}
 */
proto.app.model.v1.MicroInformation.prototype.getIpV6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes ip_v6 = 6;
 * This is a type-conversion wrapper around `getIpV6()`
 * @return {string}
 */
proto.app.model.v1.MicroInformation.prototype.getIpV6_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getIpV6()));
};


/**
 * optional bytes ip_v6 = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getIpV6()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.MicroInformation.prototype.getIpV6_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getIpV6()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.MicroInformation} returns this
 */
proto.app.model.v1.MicroInformation.prototype.setIpV6 = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional uint32 port_in = 7;
 * @return {number}
 */
proto.app.model.v1.MicroInformation.prototype.getPortIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.MicroInformation} returns this
 */
proto.app.model.v1.MicroInformation.prototype.setPortIn = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 port_out = 8;
 * @return {number}
 */
proto.app.model.v1.MicroInformation.prototype.getPortOut = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.MicroInformation} returns this
 */
proto.app.model.v1.MicroInformation.prototype.setPortOut = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.DevicePartition.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.DevicePartition.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.DevicePartition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.DevicePartition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.DevicePartition.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partitionOrder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    otaIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.DevicePartition}
 */
proto.app.model.v1.DevicePartition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.DevicePartition;
  return proto.app.model.v1.DevicePartition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.DevicePartition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.DevicePartition}
 */
proto.app.model.v1.DevicePartition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPartitionOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addOtaId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.DevicePartition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.DevicePartition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.DevicePartition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.DevicePartition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartitionOrder();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOtaIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.DevicePartition.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.DevicePartition} returns this
 */
proto.app.model.v1.DevicePartition.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 partition_order = 2;
 * @return {number}
 */
proto.app.model.v1.DevicePartition.prototype.getPartitionOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.DevicePartition} returns this
 */
proto.app.model.v1.DevicePartition.prototype.setPartitionOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.app.model.v1.DevicePartition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.DevicePartition} returns this
 */
proto.app.model.v1.DevicePartition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.app.model.v1.DevicePartition.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.DevicePartition} returns this
 */
proto.app.model.v1.DevicePartition.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string ota_id = 5;
 * @return {!Array<string>}
 */
proto.app.model.v1.DevicePartition.prototype.getOtaIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.app.model.v1.DevicePartition} returns this
 */
proto.app.model.v1.DevicePartition.prototype.setOtaIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.DevicePartition} returns this
 */
proto.app.model.v1.DevicePartition.prototype.addOtaId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.DevicePartition} returns this
 */
proto.app.model.v1.DevicePartition.prototype.clearOtaIdList = function() {
  return this.setOtaIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.DeviceGroup.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.DeviceGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.DeviceGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.DeviceGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.DeviceGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deletedMs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    photoMd5: msg.getPhotoMd5_asB64(),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paritionsList: jspb.Message.toObjectList(msg.getParitionsList(),
    proto.app.model.v1.DevicePartition.toObject, includeInstance),
    sensorUuidsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.DeviceGroup}
 */
proto.app.model.v1.DeviceGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.DeviceGroup;
  return proto.app.model.v1.DeviceGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.DeviceGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.DeviceGroup}
 */
proto.app.model.v1.DeviceGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedMs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeletedMs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPhotoMd5(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = new proto.app.model.v1.DevicePartition;
      reader.readMessage(value,proto.app.model.v1.DevicePartition.deserializeBinaryFromReader);
      msg.addParitions(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSensorUuids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.DeviceGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.DeviceGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.DeviceGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.DeviceGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCreatedMs();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDeletedMs();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhotoMd5_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getParitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.app.model.v1.DevicePartition.serializeBinaryToWriter
    );
  }
  f = message.getSensorUuidsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      9,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.DeviceGroup.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.app.model.v1.DeviceGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 created_ms = 3;
 * @return {number}
 */
proto.app.model.v1.DeviceGroup.prototype.getCreatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.setCreatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 deleted_ms = 4;
 * @return {number}
 */
proto.app.model.v1.DeviceGroup.prototype.getDeletedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.setDeletedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.app.model.v1.DeviceGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bytes photo_md5 = 6;
 * @return {string}
 */
proto.app.model.v1.DeviceGroup.prototype.getPhotoMd5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes photo_md5 = 6;
 * This is a type-conversion wrapper around `getPhotoMd5()`
 * @return {string}
 */
proto.app.model.v1.DeviceGroup.prototype.getPhotoMd5_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPhotoMd5()));
};


/**
 * optional bytes photo_md5 = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPhotoMd5()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.DeviceGroup.prototype.getPhotoMd5_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPhotoMd5()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.setPhotoMd5 = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.app.model.v1.DeviceGroup.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated DevicePartition paritions = 8;
 * @return {!Array<!proto.app.model.v1.DevicePartition>}
 */
proto.app.model.v1.DeviceGroup.prototype.getParitionsList = function() {
  return /** @type{!Array<!proto.app.model.v1.DevicePartition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.DevicePartition, 8));
};


/**
 * @param {!Array<!proto.app.model.v1.DevicePartition>} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
*/
proto.app.model.v1.DeviceGroup.prototype.setParitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.app.model.v1.DevicePartition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.DevicePartition}
 */
proto.app.model.v1.DeviceGroup.prototype.addParitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.app.model.v1.DevicePartition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.clearParitionsList = function() {
  return this.setParitionsList([]);
};


/**
 * repeated uint64 sensor_uuids = 9;
 * @return {!Array<number>}
 */
proto.app.model.v1.DeviceGroup.prototype.getSensorUuidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.setSensorUuidsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.addSensorUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.DeviceGroup} returns this
 */
proto.app.model.v1.DeviceGroup.prototype.clearSensorUuidsList = function() {
  return this.setSensorUuidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.OTAFile.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.OTAFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.OTAFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.OTAFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileMd5: msg.getFileMd5_asB64(),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    verMajor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    verMinor: jspb.Message.getFieldWithDefault(msg, 5, 0),
    uploadedMs: jspb.Message.getFieldWithDefault(msg, 6, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    releaseNotes: jspb.Message.getFieldWithDefault(msg, 8, ""),
    commitUrl: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.OTAFile}
 */
proto.app.model.v1.OTAFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.OTAFile;
  return proto.app.model.v1.OTAFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.OTAFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.OTAFile}
 */
proto.app.model.v1.OTAFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFileMd5(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVerMajor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVerMinor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUploadedMs(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseNotes(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommitUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.OTAFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.OTAFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.OTAFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.OTAFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileMd5_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVerMajor();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getVerMinor();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getUploadedMs();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getReleaseNotes();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCommitUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.OTAFile.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes file_md5 = 2;
 * @return {string}
 */
proto.app.model.v1.OTAFile.prototype.getFileMd5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes file_md5 = 2;
 * This is a type-conversion wrapper around `getFileMd5()`
 * @return {string}
 */
proto.app.model.v1.OTAFile.prototype.getFileMd5_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFileMd5()));
};


/**
 * optional bytes file_md5 = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFileMd5()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.OTAFile.prototype.getFileMd5_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFileMd5()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setFileMd5 = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.app.model.v1.OTAFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 ver_major = 4;
 * @return {number}
 */
proto.app.model.v1.OTAFile.prototype.getVerMajor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setVerMajor = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 ver_minor = 5;
 * @return {number}
 */
proto.app.model.v1.OTAFile.prototype.getVerMinor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setVerMinor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 uploaded_ms = 6;
 * @return {number}
 */
proto.app.model.v1.OTAFile.prototype.getUploadedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setUploadedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.app.model.v1.OTAFile.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string release_notes = 8;
 * @return {string}
 */
proto.app.model.v1.OTAFile.prototype.getReleaseNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setReleaseNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string commit_url = 9;
 * @return {string}
 */
proto.app.model.v1.OTAFile.prototype.getCommitUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.OTAFile} returns this
 */
proto.app.model.v1.OTAFile.prototype.setCommitUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.File.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    md5: msg.getMd5_asB64(),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mimeType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    length: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdMs: jspb.Message.getFieldWithDefault(msg, 6, 0),
    uploadedMs: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deletedMs: jspb.Message.getFieldWithDefault(msg, 8, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 9, ""),
    data: msg.getData_asB64(),
    dataUrl: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.File}
 */
proto.app.model.v1.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.File;
  return proto.app.model.v1.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.File}
 */
proto.app.model.v1.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMd5(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedMs(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUploadedMs(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeletedMs(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMd5_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMimeType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCreatedMs();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getUploadedMs();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getDeletedMs();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
  f = message.getDataUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.File.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes md5 = 2;
 * @return {string}
 */
proto.app.model.v1.File.prototype.getMd5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes md5 = 2;
 * This is a type-conversion wrapper around `getMd5()`
 * @return {string}
 */
proto.app.model.v1.File.prototype.getMd5_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMd5()));
};


/**
 * optional bytes md5 = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMd5()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.File.prototype.getMd5_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMd5()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setMd5 = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.app.model.v1.File.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mime_type = 4;
 * @return {string}
 */
proto.app.model.v1.File.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setMimeType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 length = 5;
 * @return {number}
 */
proto.app.model.v1.File.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 created_ms = 6;
 * @return {number}
 */
proto.app.model.v1.File.prototype.getCreatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setCreatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 uploaded_ms = 7;
 * @return {number}
 */
proto.app.model.v1.File.prototype.getUploadedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setUploadedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 deleted_ms = 8;
 * @return {number}
 */
proto.app.model.v1.File.prototype.getDeletedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setDeletedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string notes = 9;
 * @return {string}
 */
proto.app.model.v1.File.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bytes data = 10;
 * @return {string}
 */
proto.app.model.v1.File.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes data = 10;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.app.model.v1.File.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.File.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 10, value);
};


/**
 * optional string data_url = 11;
 * @return {string}
 */
proto.app.model.v1.File.prototype.getDataUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.File} returns this
 */
proto.app.model.v1.File.prototype.setDataUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.Person.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Person.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Person.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Person} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Person.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    encryptPub: jspb.Message.getFieldWithDefault(msg, 4, ""),
    encryptPriv: jspb.Message.getFieldWithDefault(msg, 5, ""),
    signPub: jspb.Message.getFieldWithDefault(msg, 6, ""),
    signPriv: jspb.Message.getFieldWithDefault(msg, 7, ""),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    email: jspb.Message.getFieldWithDefault(msg, 9, ""),
    title: jspb.Message.getFieldWithDefault(msg, 10, 0),
    contactsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    profilePicture: msg.getProfilePicture_asB64(),
    shortUsername: jspb.Message.getFieldWithDefault(msg, 13, ""),
    color: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Person}
 */
proto.app.model.v1.Person.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Person;
  return proto.app.model.v1.Person.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Person} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Person}
 */
proto.app.model.v1.Person.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncryptPub(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncryptPriv(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignPub(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignPriv(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = /** @type {!proto.app.model.v1.TITLE} */ (reader.readEnum());
      msg.setTitle(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addContacts(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setProfilePicture(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortUsername(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Person.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Person.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Person} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Person.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEncryptPub();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEncryptPriv();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSignPub();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSignPriv();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getProfilePicture_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = message.getShortUsername();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 uuid = 2;
 * @return {number}
 */
proto.app.model.v1.Person.prototype.getUuid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setUuid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string encrypt_pub = 4;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getEncryptPub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setEncryptPub = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string encrypt_priv = 5;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getEncryptPriv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setEncryptPriv = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sign_pub = 6;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getSignPub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setSignPub = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sign_priv = 7;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getSignPriv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setSignPriv = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email = 9;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional TITLE title = 10;
 * @return {!proto.app.model.v1.TITLE}
 */
proto.app.model.v1.Person.prototype.getTitle = function() {
  return /** @type {!proto.app.model.v1.TITLE} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.app.model.v1.TITLE} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * repeated string contacts = 11;
 * @return {!Array<string>}
 */
proto.app.model.v1.Person.prototype.getContactsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setContactsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.addContacts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional bytes profile_picture = 12;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes profile_picture = 12;
 * This is a type-conversion wrapper around `getProfilePicture()`
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getProfilePicture_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getProfilePicture()));
};


/**
 * optional bytes profile_picture = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getProfilePicture()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.Person.prototype.getProfilePicture_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getProfilePicture()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3BytesField(this, 12, value);
};


/**
 * optional string short_username = 13;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getShortUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setShortUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string color = 14;
 * @return {string}
 */
proto.app.model.v1.Person.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Person} returns this
 */
proto.app.model.v1.Person.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.app.model.v1.ChatMessage.oneofGroups_ = [[8,9]];

/**
 * @enum {number}
 */
proto.app.model.v1.ChatMessage.ChatdataCase = {
  CHATDATA_NOT_SET: 0,
  PHOTO_B64: 8,
  AUDIO_MSG: 9
};

/**
 * @return {proto.app.model.v1.ChatMessage.ChatdataCase}
 */
proto.app.model.v1.ChatMessage.prototype.getChatdataCase = function() {
  return /** @type {proto.app.model.v1.ChatMessage.ChatdataCase} */(jspb.Message.computeOneofCase(this, proto.app.model.v1.ChatMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.ChatMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.ChatMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.ChatMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.ChatMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deletedMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gpsPosition: (f = msg.getGpsPosition()) && proto.app.model.v1.GPSPosition.toObject(includeInstance, f),
    authorUid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    recipientUid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    message: jspb.Message.getFieldWithDefault(msg, 7, ""),
    photoB64: jspb.Message.getFieldWithDefault(msg, 8, ""),
    audioMsg: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.ChatMessage}
 */
proto.app.model.v1.ChatMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.ChatMessage;
  return proto.app.model.v1.ChatMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.ChatMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.ChatMessage}
 */
proto.app.model.v1.ChatMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeletedMs(value);
      break;
    case 4:
      var value = new proto.app.model.v1.GPSPosition;
      reader.readMessage(value,proto.app.model.v1.GPSPosition.deserializeBinaryFromReader);
      msg.setGpsPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorUid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipientUid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoB64(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudioMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.ChatMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.ChatMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.ChatMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.ChatMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedMs();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDeletedMs();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getGpsPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.app.model.v1.GPSPosition.serializeBinaryToWriter
    );
  }
  f = message.getAuthorUid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRecipientUid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.ChatMessage.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 created_ms = 2;
 * @return {number}
 */
proto.app.model.v1.ChatMessage.prototype.getCreatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.setCreatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 deleted_ms = 3;
 * @return {number}
 */
proto.app.model.v1.ChatMessage.prototype.getDeletedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.setDeletedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional GPSPosition gps_position = 4;
 * @return {?proto.app.model.v1.GPSPosition}
 */
proto.app.model.v1.ChatMessage.prototype.getGpsPosition = function() {
  return /** @type{?proto.app.model.v1.GPSPosition} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.GPSPosition, 4));
};


/**
 * @param {?proto.app.model.v1.GPSPosition|undefined} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
*/
proto.app.model.v1.ChatMessage.prototype.setGpsPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.clearGpsPosition = function() {
  return this.setGpsPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.ChatMessage.prototype.hasGpsPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string author_uid = 5;
 * @return {string}
 */
proto.app.model.v1.ChatMessage.prototype.getAuthorUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.setAuthorUid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string recipient_uid = 6;
 * @return {string}
 */
proto.app.model.v1.ChatMessage.prototype.getRecipientUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.setRecipientUid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string message = 7;
 * @return {string}
 */
proto.app.model.v1.ChatMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string photo_b64 = 8;
 * @return {string}
 */
proto.app.model.v1.ChatMessage.prototype.getPhotoB64 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.setPhotoB64 = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.app.model.v1.ChatMessage.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.clearPhotoB64 = function() {
  return jspb.Message.setOneofField(this, 8, proto.app.model.v1.ChatMessage.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.ChatMessage.prototype.hasPhotoB64 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string audio_msg = 9;
 * @return {string}
 */
proto.app.model.v1.ChatMessage.prototype.getAudioMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.setAudioMsg = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.app.model.v1.ChatMessage.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.app.model.v1.ChatMessage} returns this
 */
proto.app.model.v1.ChatMessage.prototype.clearAudioMsg = function() {
  return jspb.Message.setOneofField(this, 9, proto.app.model.v1.ChatMessage.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.ChatMessage.prototype.hasAudioMsg = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Event}
 */
proto.app.model.v1.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Event;
  return proto.app.model.v1.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Event}
 */
proto.app.model.v1.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Event.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Event} returns this
 */
proto.app.model.v1.Event.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_message = 2;
 * @return {string}
 */
proto.app.model.v1.Event.prototype.getDisplayMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Event} returns this
 */
proto.app.model.v1.Event.prototype.setDisplayMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Mission.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Mission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Mission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Mission.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startMs: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Mission}
 */
proto.app.model.v1.Mission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Mission;
  return proto.app.model.v1.Mission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Mission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Mission}
 */
proto.app.model.v1.Mission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Mission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Mission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Mission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Mission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStartMs();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Mission.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Mission} returns this
 */
proto.app.model.v1.Mission.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 uuid = 2;
 * @return {number}
 */
proto.app.model.v1.Mission.prototype.getUuid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Mission} returns this
 */
proto.app.model.v1.Mission.prototype.setUuid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 start_ms = 3;
 * @return {number}
 */
proto.app.model.v1.Mission.prototype.getStartMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Mission} returns this
 */
proto.app.model.v1.Mission.prototype.setStartMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.Channel.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Channel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Channel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Channel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Channel.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deletedMs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    privacy: jspb.Message.getFieldWithDefault(msg, 7, 0),
    subChannelUidList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    password: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Channel}
 */
proto.app.model.v1.Channel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Channel;
  return proto.app.model.v1.Channel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Channel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Channel}
 */
proto.app.model.v1.Channel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedMs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeletedMs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 7:
      var value = /** @type {!proto.app.model.v1.PRIVACY} */ (reader.readEnum());
      msg.setPrivacy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubChannelUid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Channel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Channel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Channel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Channel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCreatedMs();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDeletedMs();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrivacy();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSubChannelUidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Channel.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 uuid = 2;
 * @return {number}
 */
proto.app.model.v1.Channel.prototype.getUuid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setUuid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 created_ms = 3;
 * @return {number}
 */
proto.app.model.v1.Channel.prototype.getCreatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setCreatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 deleted_ms = 4;
 * @return {number}
 */
proto.app.model.v1.Channel.prototype.getDeletedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setDeletedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.app.model.v1.Channel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string created_by = 6;
 * @return {string}
 */
proto.app.model.v1.Channel.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PRIVACY privacy = 7;
 * @return {!proto.app.model.v1.PRIVACY}
 */
proto.app.model.v1.Channel.prototype.getPrivacy = function() {
  return /** @type {!proto.app.model.v1.PRIVACY} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.app.model.v1.PRIVACY} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setPrivacy = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated string sub_channel_uid = 8;
 * @return {!Array<string>}
 */
proto.app.model.v1.Channel.prototype.getSubChannelUidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setSubChannelUidList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.addSubChannelUid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.clearSubChannelUidList = function() {
  return this.setSubChannelUidList([]);
};


/**
 * optional string password = 9;
 * @return {string}
 */
proto.app.model.v1.Channel.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Channel} returns this
 */
proto.app.model.v1.Channel.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.Tag.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Tag.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Tag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Tag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Tag.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    parentUid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    childUidList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Tag}
 */
proto.app.model.v1.Tag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Tag;
  return proto.app.model.v1.Tag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Tag}
 */
proto.app.model.v1.Tag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentUid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Tag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Tag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagType();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getParentUid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChildUidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Tag.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Tag} returns this
 */
proto.app.model.v1.Tag.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 uuid = 2;
 * @return {number}
 */
proto.app.model.v1.Tag.prototype.getUuid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Tag} returns this
 */
proto.app.model.v1.Tag.prototype.setUuid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.app.model.v1.Tag.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Tag} returns this
 */
proto.app.model.v1.Tag.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 tag_type = 4;
 * @return {number}
 */
proto.app.model.v1.Tag.prototype.getTagType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Tag} returns this
 */
proto.app.model.v1.Tag.prototype.setTagType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string parent_uid = 5;
 * @return {string}
 */
proto.app.model.v1.Tag.prototype.getParentUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Tag} returns this
 */
proto.app.model.v1.Tag.prototype.setParentUid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string child_uid = 6;
 * @return {!Array<string>}
 */
proto.app.model.v1.Tag.prototype.getChildUidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.app.model.v1.Tag} returns this
 */
proto.app.model.v1.Tag.prototype.setChildUidList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Tag} returns this
 */
proto.app.model.v1.Tag.prototype.addChildUid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Tag} returns this
 */
proto.app.model.v1.Tag.prototype.clearChildUidList = function() {
  return this.setChildUidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.Organization.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    proto.app.model.v1.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Organization}
 */
proto.app.model.v1.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Organization;
  return proto.app.model.v1.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Organization}
 */
proto.app.model.v1.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.app.model.v1.Person;
      reader.readMessage(value,proto.app.model.v1.Person.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.model.v1.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Organization.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Organization} returns this
 */
proto.app.model.v1.Organization.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 uuid = 2;
 * @return {number}
 */
proto.app.model.v1.Organization.prototype.getUuid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Organization} returns this
 */
proto.app.model.v1.Organization.prototype.setUuid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.app.model.v1.Organization.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Organization} returns this
 */
proto.app.model.v1.Organization.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Person people = 4;
 * @return {!Array<!proto.app.model.v1.Person>}
 */
proto.app.model.v1.Organization.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.app.model.v1.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.Person, 4));
};


/**
 * @param {!Array<!proto.app.model.v1.Person>} value
 * @return {!proto.app.model.v1.Organization} returns this
*/
proto.app.model.v1.Organization.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.model.v1.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Person}
 */
proto.app.model.v1.Organization.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.model.v1.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Organization} returns this
 */
proto.app.model.v1.Organization.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.Log.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.Log.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.Log.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.Log} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Log.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deletedMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    from: jspb.Message.getFieldWithDefault(msg, 4, ""),
    message: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.app.model.v1.Tag.toObject, includeInstance),
    payload: msg.getPayload_asB64(),
    payloadUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    metaData: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.Log}
 */
proto.app.model.v1.Log.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.Log;
  return proto.app.model.v1.Log.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.Log} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.Log}
 */
proto.app.model.v1.Log.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeletedMs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = new proto.app.model.v1.Tag;
      reader.readMessage(value,proto.app.model.v1.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetaData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.Log.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.Log.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.Log} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.Log.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedMs();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDeletedMs();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.app.model.v1.Tag.serializeBinaryToWriter
    );
  }
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getPayloadUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMetaData();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.app.model.v1.Log.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 created_ms = 2;
 * @return {number}
 */
proto.app.model.v1.Log.prototype.getCreatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.setCreatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 deleted_ms = 3;
 * @return {number}
 */
proto.app.model.v1.Log.prototype.getDeletedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.setDeletedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string from = 4;
 * @return {string}
 */
proto.app.model.v1.Log.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string message = 5;
 * @return {string}
 */
proto.app.model.v1.Log.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Tag tags = 6;
 * @return {!Array<!proto.app.model.v1.Tag>}
 */
proto.app.model.v1.Log.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.app.model.v1.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.Tag, 6));
};


/**
 * @param {!Array<!proto.app.model.v1.Tag>} value
 * @return {!proto.app.model.v1.Log} returns this
*/
proto.app.model.v1.Log.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.app.model.v1.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Tag}
 */
proto.app.model.v1.Log.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.app.model.v1.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional bytes payload = 7;
 * @return {string}
 */
proto.app.model.v1.Log.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes payload = 7;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.app.model.v1.Log.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.Log.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional string payload_url = 8;
 * @return {string}
 */
proto.app.model.v1.Log.prototype.getPayloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.setPayloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string meta_data = 9;
 * @return {string}
 */
proto.app.model.v1.Log.prototype.getMetaData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.model.v1.Log} returns this
 */
proto.app.model.v1.Log.prototype.setMetaData = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.app.model.v1.Motion_State = {
  MOTION_STATE_UNKNOWN: 0,
  MOTION_STATE_STATIONARY: 1,
  MOTION_CURRENTLY_MOVING: 2,
  MOTION_CURRENTLY_PARKED: 3,
  MOTION_WAS_PARKED_NOW_MOVING: 4,
  MOTION_WAS_MOVING_NOW_PARKED: 5,
  MOTION_STATE_WALKING: 6,
  MOTION_STATE_DRIVING: 7
};

/**
 * @enum {number}
 */
proto.app.model.v1.SensorType = {
  SENSOR_UNKNOWN: 0,
  SENSOR_VIRTUAL: 1,
  SENSOR_GATEWAY: 2,
  SENSOR_BOOMERANG: 3,
  SENSOR_VALVE: 4
};

/**
 * @enum {number}
 */
proto.app.model.v1.TITLE = {
  TITLE_UNKNOWN: 0,
  USER: 1,
  STAFF: 2,
  ADMIN: 3
};

/**
 * @enum {number}
 */
proto.app.model.v1.PRIVACY = {
  UNKNOWN: 0,
  PUBLIC: 1,
  READ_ONLY: 2,
  PRIVATE_PASSWORD: 3,
  PRIVATE_INVITE: 4
};

/**
 * @enum {number}
 */
proto.app.model.v1.TagType = {
  TAG_TYPE_UNKNOWN: 0,
  TAG_TYPE_SYSTEM: 1,
  TAG_TYPE_APP: 2,
  TAG_TYPE_WORKSPACE: 3,
  TAG_TYPE_CHANNEL: 4,
  TAG_TYPE_USER: 5
};

goog.object.extend(exports, proto.app.model.v1);
