import { ViewModelImplemented } from "./ViewModelImplemented"
import { AllServcies } from "../services/startup/startup.service"

import { EventViewModel, EventModel, ModelType, DataBase, } from '../generated_proto/protobuf-ts/pb/v2/models';
import { LoggerOptions } from "../services/logger/logger.service";
import { DataService } from "../services/data/data.service";
import { EventLog } from "../generated_proto/protobuf-ts/pb/v2/data";


export class EventViewModelImplemented extends ViewModelImplemented implements EventViewModel {
	
	model?: EventModel;

	public uuid:bigint;

	public services:AllServcies;

	public loggerOptions:LoggerOptions = {
		prefix:"EventViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	constructor(
		services:AllServcies
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		// Can pass as param, not used in this case but would
		// be useful behavior for sub classes
		this.services = services;
	}

	public updateFromEventLog(eventLog:EventLog):void{
		if(this.model){
			this.model.event = eventLog;
		}
	}

	modelToJson(){
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		if(this.model){
			return EventModel.toJson(this.model);
		}
	}

	private static PB_VERSION = 2;
	static GenerateDbId(dbPrefix:string) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return dbPrefix + ModelType.EVENT_MODEL;
	}
	static GenerateDbKey(uuid:bigint) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return DataService.getKeyPrefix(ModelType.EVENT_MODEL, EventViewModelImplemented.PB_VERSION)+ uuid.toString();
	}
	static GenerateURN(dbPrefix:string, uuid:bigint) : string {
		if(uuid){
			var dbid = this.GenerateDbId(dbPrefix);
			return dbid+"/"+this.GenerateDbKey(uuid);
		}
		console.error("generateURN: No UUID");
		return "";
	}
	public generateURN() : string {
		if(this.model && this.model.event && this.services.data?.getDbPrefix()){
			return EventViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.uuid);
		}
		return "";
	}
	updateDb(db?:DataBase){
		if(!this.model){
			this.model = EventModel.create();
		}
		if(!this.model.db){
			this.model.db = DataBase.create();
		}
		if(db){
			try{
				DataBase.mergePartial(this.model.db, db);
			}
			catch(err){
				console.error("updateDb: Failed to merge db: "+err);
			}
		}
		if( this.model.db.createdMs && this.model.db.createdMs <= 0){
			this.model.db.createdMs = BigInt(Date.now());
		}
		if(this.uuid) this.model.db.uid = BigInt( this.uuid );
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.EVENT_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}
}

