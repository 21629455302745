// source: app/model/v1/hum2.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var nanopb_pb = require('../../../nanopb_pb.js');
goog.object.extend(proto, nanopb_pb);
goog.exportSymbol('proto.app.model.v1.HUM_Boomerang_Data', null, global);
goog.exportSymbol('proto.app.model.v1.HUM_CellScanRecord', null, global);
goog.exportSymbol('proto.app.model.v1.HUM_Device_Type', null, global);
goog.exportSymbol('proto.app.model.v1.HUM_GPSScanRecord', null, global);
goog.exportSymbol('proto.app.model.v1.HUM_Gatewate_State', null, global);
goog.exportSymbol('proto.app.model.v1.HUM_Gateway_Data', null, global);
goog.exportSymbol('proto.app.model.v1.HUM_Gateway_Impact', null, global);
goog.exportSymbol('proto.app.model.v1.HUM_Valve_Data', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.HUM_Boomerang_Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.HUM_Boomerang_Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.HUM_Boomerang_Data.displayName = 'proto.app.model.v1.HUM_Boomerang_Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.HUM_Valve_Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.HUM_Valve_Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.HUM_Valve_Data.displayName = 'proto.app.model.v1.HUM_Valve_Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.HUM_Gateway_Impact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.HUM_Gateway_Impact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.HUM_Gateway_Impact.displayName = 'proto.app.model.v1.HUM_Gateway_Impact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.HUM_CellScanRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.HUM_CellScanRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.HUM_CellScanRecord.displayName = 'proto.app.model.v1.HUM_CellScanRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.HUM_GPSScanRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.model.v1.HUM_GPSScanRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.HUM_GPSScanRecord.displayName = 'proto.app.model.v1.HUM_GPSScanRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.model.v1.HUM_Gateway_Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.model.v1.HUM_Gateway_Data.repeatedFields_, null);
};
goog.inherits(proto.app.model.v1.HUM_Gateway_Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.model.v1.HUM_Gateway_Data.displayName = 'proto.app.model.v1.HUM_Gateway_Data';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.HUM_Boomerang_Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.HUM_Boomerang_Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_Boomerang_Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    epochSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rms: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pcbAccelLatMax: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pcbAccelLatRms: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pcbAccelVertMax: jspb.Message.getFieldWithDefault(msg, 13, 0),
    pcbAccelVertRms: jspb.Message.getFieldWithDefault(msg, 14, 0),
    pcbAccelLongMax: jspb.Message.getFieldWithDefault(msg, 15, 0),
    pcbAccelLongRms: jspb.Message.getFieldWithDefault(msg, 16, 0),
    adxlMax: jspb.Message.getFieldWithDefault(msg, 8, 0),
    adxlRms: jspb.Message.getFieldWithDefault(msg, 9, 0),
    bearingTemp: jspb.Message.getFieldWithDefault(msg, 10, 0),
    batteryVoltageCentivolts: jspb.Message.getFieldWithDefault(msg, 11, 0),
    bearingTemperatureCelsius: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.HUM_Boomerang_Data}
 */
proto.app.model.v1.HUM_Boomerang_Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.HUM_Boomerang_Data;
  return proto.app.model.v1.HUM_Boomerang_Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.HUM_Boomerang_Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.HUM_Boomerang_Data}
 */
proto.app.model.v1.HUM_Boomerang_Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.model.v1.HUM_Device_Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEpochSeconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRms(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPcbAccelLatMax(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPcbAccelLatRms(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPcbAccelVertMax(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPcbAccelVertRms(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPcbAccelLongMax(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPcbAccelLongRms(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdxlMax(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdxlRms(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBearingTemp(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatteryVoltageCentivolts(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setBearingTemperatureCelsius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.HUM_Boomerang_Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.HUM_Boomerang_Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_Boomerang_Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getEpochSeconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getRms();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPcbAccelLatMax();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPcbAccelLatRms();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPcbAccelVertMax();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getPcbAccelVertRms();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getPcbAccelLongMax();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getPcbAccelLongRms();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getAdxlMax();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAdxlRms();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getBearingTemp();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getBatteryVoltageCentivolts();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getBearingTemperatureCelsius();
  if (f !== 0) {
    writer.writeSint32(
      12,
      f
    );
  }
};


/**
 * optional HUM_Device_Type type = 1;
 * @return {!proto.app.model.v1.HUM_Device_Type}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getType = function() {
  return /** @type {!proto.app.model.v1.HUM_Device_Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.model.v1.HUM_Device_Type} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 id = 2;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 epoch_seconds = 3;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getEpochSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setEpochSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 rms = 4;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getRms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setRms = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 pcb_accel_lat_max = 6;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getPcbAccelLatMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setPcbAccelLatMax = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 pcb_accel_lat_rms = 7;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getPcbAccelLatRms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setPcbAccelLatRms = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 pcb_accel_vert_max = 13;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getPcbAccelVertMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setPcbAccelVertMax = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 pcb_accel_vert_rms = 14;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getPcbAccelVertRms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setPcbAccelVertRms = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 pcb_accel_long_max = 15;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getPcbAccelLongMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setPcbAccelLongMax = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 pcb_accel_long_rms = 16;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getPcbAccelLongRms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setPcbAccelLongRms = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 adxl_max = 8;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getAdxlMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setAdxlMax = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 adxl_rms = 9;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getAdxlRms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setAdxlRms = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 bearing_temp = 10;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getBearingTemp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setBearingTemp = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 battery_voltage_centivolts = 11;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getBatteryVoltageCentivolts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setBatteryVoltageCentivolts = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional sint32 bearing_temperature_celsius = 12;
 * @return {number}
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.getBearingTemperatureCelsius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Boomerang_Data} returns this
 */
proto.app.model.v1.HUM_Boomerang_Data.prototype.setBearingTemperatureCelsius = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.HUM_Valve_Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.HUM_Valve_Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_Valve_Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    epochSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    batteryVoltageCentivolts: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hallSense: jspb.Message.getFieldWithDefault(msg, 7, 0),
    rawMag: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.HUM_Valve_Data}
 */
proto.app.model.v1.HUM_Valve_Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.HUM_Valve_Data;
  return proto.app.model.v1.HUM_Valve_Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.HUM_Valve_Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.HUM_Valve_Data}
 */
proto.app.model.v1.HUM_Valve_Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.model.v1.HUM_Device_Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEpochSeconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatteryVoltageCentivolts(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHallSense(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRawMag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.HUM_Valve_Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.HUM_Valve_Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_Valve_Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getEpochSeconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getBatteryVoltageCentivolts();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getHallSense();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getRawMag();
  if (f !== 0) {
    writer.writeSint32(
      8,
      f
    );
  }
};


/**
 * optional HUM_Device_Type type = 1;
 * @return {!proto.app.model.v1.HUM_Device_Type}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.getType = function() {
  return /** @type {!proto.app.model.v1.HUM_Device_Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.model.v1.HUM_Device_Type} value
 * @return {!proto.app.model.v1.HUM_Valve_Data} returns this
 */
proto.app.model.v1.HUM_Valve_Data.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 id = 2;
 * @return {number}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Valve_Data} returns this
 */
proto.app.model.v1.HUM_Valve_Data.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 epoch_seconds = 3;
 * @return {number}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.getEpochSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Valve_Data} returns this
 */
proto.app.model.v1.HUM_Valve_Data.prototype.setEpochSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 battery_voltage_centivolts = 5;
 * @return {number}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.getBatteryVoltageCentivolts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Valve_Data} returns this
 */
proto.app.model.v1.HUM_Valve_Data.prototype.setBatteryVoltageCentivolts = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 status = 6;
 * @return {number}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Valve_Data} returns this
 */
proto.app.model.v1.HUM_Valve_Data.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 hall_sense = 7;
 * @return {number}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.getHallSense = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Valve_Data} returns this
 */
proto.app.model.v1.HUM_Valve_Data.prototype.setHallSense = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional sint32 raw_mag = 8;
 * @return {number}
 */
proto.app.model.v1.HUM_Valve_Data.prototype.getRawMag = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Valve_Data} returns this
 */
proto.app.model.v1.HUM_Valve_Data.prototype.setRawMag = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.HUM_Gateway_Impact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.HUM_Gateway_Impact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_Gateway_Impact.toObject = function(includeInstance, msg) {
  var f, obj = {
    impactEpochSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    preImpactVelocityKmh: jspb.Message.getFieldWithDefault(msg, 2, 0),
    impactZMax: jspb.Message.getFieldWithDefault(msg, 3, 0),
    impactZSum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    impactYMax: jspb.Message.getFieldWithDefault(msg, 9, 0),
    impactYSum: jspb.Message.getFieldWithDefault(msg, 10, 0),
    impactXMax: jspb.Message.getFieldWithDefault(msg, 11, 0),
    impactXSum: jspb.Message.getFieldWithDefault(msg, 12, 0),
    impactZData: msg.getImpactZData_asB64(),
    impactYData: msg.getImpactYData_asB64(),
    impactXData: msg.getImpactXData_asB64(),
    xAccelerometerPostMillag: jspb.Message.getFieldWithDefault(msg, 6, 0),
    yAccelerometerPostMillag: jspb.Message.getFieldWithDefault(msg, 7, 0),
    zAccelerometerPostMillag: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.HUM_Gateway_Impact}
 */
proto.app.model.v1.HUM_Gateway_Impact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.HUM_Gateway_Impact;
  return proto.app.model.v1.HUM_Gateway_Impact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.HUM_Gateway_Impact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.HUM_Gateway_Impact}
 */
proto.app.model.v1.HUM_Gateway_Impact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setImpactEpochSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPreImpactVelocityKmh(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImpactZMax(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImpactZSum(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImpactYMax(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImpactYSum(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImpactXMax(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImpactXSum(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImpactZData(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImpactYData(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImpactXData(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setXAccelerometerPostMillag(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYAccelerometerPostMillag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZAccelerometerPostMillag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.HUM_Gateway_Impact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.HUM_Gateway_Impact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_Gateway_Impact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImpactEpochSeconds();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPreImpactVelocityKmh();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getImpactZMax();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getImpactZSum();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getImpactYMax();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getImpactYSum();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getImpactXMax();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getImpactXSum();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getImpactZData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getImpactYData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getImpactXData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getXAccelerometerPostMillag();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getYAccelerometerPostMillag();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getZAccelerometerPostMillag();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional uint64 impact_epoch_seconds = 1;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactEpochSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactEpochSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pre_impact_velocity_kmh = 2;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getPreImpactVelocityKmh = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setPreImpactVelocityKmh = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 impact_z_max = 3;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactZMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactZMax = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 impact_z_sum = 4;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactZSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactZSum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 impact_y_max = 9;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactYMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactYMax = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 impact_y_sum = 10;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactYSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactYSum = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 impact_x_max = 11;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactXMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactXMax = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 impact_x_sum = 12;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactXSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactXSum = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bytes impact_z_data = 5;
 * @return {string}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactZData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes impact_z_data = 5;
 * This is a type-conversion wrapper around `getImpactZData()`
 * @return {string}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactZData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImpactZData()));
};


/**
 * optional bytes impact_z_data = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImpactZData()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactZData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImpactZData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactZData = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional bytes impact_y_data = 13;
 * @return {string}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactYData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes impact_y_data = 13;
 * This is a type-conversion wrapper around `getImpactYData()`
 * @return {string}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactYData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImpactYData()));
};


/**
 * optional bytes impact_y_data = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImpactYData()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactYData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImpactYData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactYData = function(value) {
  return jspb.Message.setProto3BytesField(this, 13, value);
};


/**
 * optional bytes impact_x_data = 14;
 * @return {string}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactXData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes impact_x_data = 14;
 * This is a type-conversion wrapper around `getImpactXData()`
 * @return {string}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactXData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImpactXData()));
};


/**
 * optional bytes impact_x_data = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImpactXData()`
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getImpactXData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImpactXData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setImpactXData = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional int32 x_accelerometer_post_millag = 6;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getXAccelerometerPostMillag = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setXAccelerometerPostMillag = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 y_accelerometer_post_millag = 7;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getYAccelerometerPostMillag = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setYAccelerometerPostMillag = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 z_accelerometer_post_millag = 8;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.getZAccelerometerPostMillag = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Impact} returns this
 */
proto.app.model.v1.HUM_Gateway_Impact.prototype.setZAccelerometerPostMillag = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.HUM_CellScanRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.HUM_CellScanRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_CellScanRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    mcc: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mnc: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    las: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rssi: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.HUM_CellScanRecord}
 */
proto.app.model.v1.HUM_CellScanRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.HUM_CellScanRecord;
  return proto.app.model.v1.HUM_CellScanRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.HUM_CellScanRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.HUM_CellScanRecord}
 */
proto.app.model.v1.HUM_CellScanRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMcc(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMnc(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLas(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRssi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.HUM_CellScanRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.HUM_CellScanRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_CellScanRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMcc();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMnc();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLas();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRssi();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 mcc = 1;
 * @return {number}
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.getMcc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_CellScanRecord} returns this
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.setMcc = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 mnc = 2;
 * @return {number}
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.getMnc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_CellScanRecord} returns this
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.setMnc = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 cid = 3;
 * @return {number}
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.getCid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_CellScanRecord} returns this
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.setCid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 las = 4;
 * @return {number}
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.getLas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_CellScanRecord} returns this
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.setLas = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 rssi = 5;
 * @return {number}
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.getRssi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_CellScanRecord} returns this
 */
proto.app.model.v1.HUM_CellScanRecord.prototype.setRssi = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.HUM_GPSScanRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.HUM_GPSScanRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_GPSScanRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    fixType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    velocityKmh: jspb.Message.getFieldWithDefault(msg, 2, 0),
    headingDegrees: jspb.Message.getFieldWithDefault(msg, 3, 0),
    satsLocked: jspb.Message.getFieldWithDefault(msg, 4, 0),
    satsInView: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pdop: jspb.Message.getFieldWithDefault(msg, 6, 0),
    geopointAccuracyCm: jspb.Message.getFieldWithDefault(msg, 7, 0),
    elevationAccuracyCm: jspb.Message.getFieldWithDefault(msg, 8, 0),
    latitude1e7: jspb.Message.getFieldWithDefault(msg, 9, 0),
    longitude1e7: jspb.Message.getFieldWithDefault(msg, 10, 0),
    elevationCm: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.HUM_GPSScanRecord}
 */
proto.app.model.v1.HUM_GPSScanRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.HUM_GPSScanRecord;
  return proto.app.model.v1.HUM_GPSScanRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.HUM_GPSScanRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.HUM_GPSScanRecord}
 */
proto.app.model.v1.HUM_GPSScanRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFixType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVelocityKmh(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeadingDegrees(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSatsLocked(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSatsInView(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPdop(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGeopointAccuracyCm(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setElevationAccuracyCm(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setLatitude1e7(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setLongitude1e7(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setElevationCm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.HUM_GPSScanRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.HUM_GPSScanRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_GPSScanRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFixType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVelocityKmh();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHeadingDegrees();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSatsLocked();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSatsInView();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPdop();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGeopointAccuracyCm();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getElevationAccuracyCm();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLatitude1e7();
  if (f !== 0) {
    writer.writeSfixed32(
      9,
      f
    );
  }
  f = message.getLongitude1e7();
  if (f !== 0) {
    writer.writeSfixed32(
      10,
      f
    );
  }
  f = message.getElevationCm();
  if (f !== 0) {
    writer.writeSint32(
      11,
      f
    );
  }
};


/**
 * optional int32 fix_type = 1;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getFixType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setFixType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 velocity_kmh = 2;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getVelocityKmh = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setVelocityKmh = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 heading_degrees = 3;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getHeadingDegrees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setHeadingDegrees = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 sats_locked = 4;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getSatsLocked = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setSatsLocked = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sats_in_view = 5;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getSatsInView = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setSatsInView = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 pdop = 6;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getPdop = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setPdop = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 geopoint_accuracy_cm = 7;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getGeopointAccuracyCm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setGeopointAccuracyCm = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 elevation_accuracy_cm = 8;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getElevationAccuracyCm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setElevationAccuracyCm = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional sfixed32 latitude_1e7 = 9;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getLatitude1e7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setLatitude1e7 = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional sfixed32 longitude_1e7 = 10;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getLongitude1e7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setLongitude1e7 = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional sint32 elevation_cm = 11;
 * @return {number}
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.getElevationCm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_GPSScanRecord} returns this
 */
proto.app.model.v1.HUM_GPSScanRecord.prototype.setElevationCm = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.model.v1.HUM_Gateway_Data.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.toObject = function(opt_includeInstance) {
  return proto.app.model.v1.HUM_Gateway_Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.model.v1.HUM_Gateway_Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_Gateway_Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    epochSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gatewayState: jspb.Message.getFieldWithDefault(msg, 14, 0),
    currentConfigId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    batteryVoltageCentivolts: jspb.Message.getFieldWithDefault(msg, 4, 0),
    solarVoltageCentivolts: jspb.Message.getFieldWithDefault(msg, 13, 0),
    systemTemperatureCelsius: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gpsScanRecord: (f = msg.getGpsScanRecord()) && proto.app.model.v1.HUM_GPSScanRecord.toObject(includeInstance, f),
    cellScanRecord: (f = msg.getCellScanRecord()) && proto.app.model.v1.HUM_CellScanRecord.toObject(includeInstance, f),
    boomerangList: jspb.Message.toObjectList(msg.getBoomerangList(),
    proto.app.model.v1.HUM_Boomerang_Data.toObject, includeInstance),
    valveList: jspb.Message.toObjectList(msg.getValveList(),
    proto.app.model.v1.HUM_Valve_Data.toObject, includeInstance),
    gatewayImpact: (f = msg.getGatewayImpact()) && proto.app.model.v1.HUM_Gateway_Impact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.model.v1.HUM_Gateway_Data}
 */
proto.app.model.v1.HUM_Gateway_Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.model.v1.HUM_Gateway_Data;
  return proto.app.model.v1.HUM_Gateway_Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.model.v1.HUM_Gateway_Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.model.v1.HUM_Gateway_Data}
 */
proto.app.model.v1.HUM_Gateway_Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.model.v1.HUM_Device_Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEpochSeconds(value);
      break;
    case 14:
      var value = /** @type {!proto.app.model.v1.HUM_Gatewate_State} */ (reader.readEnum());
      msg.setGatewayState(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentConfigId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatteryVoltageCentivolts(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSolarVoltageCentivolts(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSystemTemperatureCelsius(value);
      break;
    case 9:
      var value = new proto.app.model.v1.HUM_GPSScanRecord;
      reader.readMessage(value,proto.app.model.v1.HUM_GPSScanRecord.deserializeBinaryFromReader);
      msg.setGpsScanRecord(value);
      break;
    case 10:
      var value = new proto.app.model.v1.HUM_CellScanRecord;
      reader.readMessage(value,proto.app.model.v1.HUM_CellScanRecord.deserializeBinaryFromReader);
      msg.setCellScanRecord(value);
      break;
    case 11:
      var value = new proto.app.model.v1.HUM_Boomerang_Data;
      reader.readMessage(value,proto.app.model.v1.HUM_Boomerang_Data.deserializeBinaryFromReader);
      msg.addBoomerang(value);
      break;
    case 12:
      var value = new proto.app.model.v1.HUM_Valve_Data;
      reader.readMessage(value,proto.app.model.v1.HUM_Valve_Data.deserializeBinaryFromReader);
      msg.addValve(value);
      break;
    case 16:
      var value = new proto.app.model.v1.HUM_Gateway_Impact;
      reader.readMessage(value,proto.app.model.v1.HUM_Gateway_Impact.deserializeBinaryFromReader);
      msg.setGatewayImpact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.model.v1.HUM_Gateway_Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.model.v1.HUM_Gateway_Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.model.v1.HUM_Gateway_Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getEpochSeconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getGatewayState();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getCurrentConfigId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getBatteryVoltageCentivolts();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSolarVoltageCentivolts();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getSystemTemperatureCelsius();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getGpsScanRecord();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.app.model.v1.HUM_GPSScanRecord.serializeBinaryToWriter
    );
  }
  f = message.getCellScanRecord();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.app.model.v1.HUM_CellScanRecord.serializeBinaryToWriter
    );
  }
  f = message.getBoomerangList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.app.model.v1.HUM_Boomerang_Data.serializeBinaryToWriter
    );
  }
  f = message.getValveList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.app.model.v1.HUM_Valve_Data.serializeBinaryToWriter
    );
  }
  f = message.getGatewayImpact();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.app.model.v1.HUM_Gateway_Impact.serializeBinaryToWriter
    );
  }
};


/**
 * optional HUM_Device_Type type = 1;
 * @return {!proto.app.model.v1.HUM_Device_Type}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getType = function() {
  return /** @type {!proto.app.model.v1.HUM_Device_Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.model.v1.HUM_Device_Type} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 id = 2;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 epoch_seconds = 3;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getEpochSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.setEpochSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional HUM_Gatewate_State gateway_state = 14;
 * @return {!proto.app.model.v1.HUM_Gatewate_State}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getGatewayState = function() {
  return /** @type {!proto.app.model.v1.HUM_Gatewate_State} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.app.model.v1.HUM_Gatewate_State} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.setGatewayState = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int32 current_config_id = 15;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getCurrentConfigId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.setCurrentConfigId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 battery_voltage_centivolts = 4;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getBatteryVoltageCentivolts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.setBatteryVoltageCentivolts = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 solar_voltage_centivolts = 13;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getSolarVoltageCentivolts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.setSolarVoltageCentivolts = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional sint32 system_temperature_celsius = 5;
 * @return {number}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getSystemTemperatureCelsius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.setSystemTemperatureCelsius = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional HUM_GPSScanRecord gps_scan_record = 9;
 * @return {?proto.app.model.v1.HUM_GPSScanRecord}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getGpsScanRecord = function() {
  return /** @type{?proto.app.model.v1.HUM_GPSScanRecord} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.HUM_GPSScanRecord, 9));
};


/**
 * @param {?proto.app.model.v1.HUM_GPSScanRecord|undefined} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
*/
proto.app.model.v1.HUM_Gateway_Data.prototype.setGpsScanRecord = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.clearGpsScanRecord = function() {
  return this.setGpsScanRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.hasGpsScanRecord = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional HUM_CellScanRecord cell_scan_record = 10;
 * @return {?proto.app.model.v1.HUM_CellScanRecord}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getCellScanRecord = function() {
  return /** @type{?proto.app.model.v1.HUM_CellScanRecord} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.HUM_CellScanRecord, 10));
};


/**
 * @param {?proto.app.model.v1.HUM_CellScanRecord|undefined} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
*/
proto.app.model.v1.HUM_Gateway_Data.prototype.setCellScanRecord = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.clearCellScanRecord = function() {
  return this.setCellScanRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.hasCellScanRecord = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated HUM_Boomerang_Data boomerang = 11;
 * @return {!Array<!proto.app.model.v1.HUM_Boomerang_Data>}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getBoomerangList = function() {
  return /** @type{!Array<!proto.app.model.v1.HUM_Boomerang_Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.HUM_Boomerang_Data, 11));
};


/**
 * @param {!Array<!proto.app.model.v1.HUM_Boomerang_Data>} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
*/
proto.app.model.v1.HUM_Gateway_Data.prototype.setBoomerangList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.app.model.v1.HUM_Boomerang_Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.HUM_Boomerang_Data}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.addBoomerang = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.app.model.v1.HUM_Boomerang_Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.clearBoomerangList = function() {
  return this.setBoomerangList([]);
};


/**
 * repeated HUM_Valve_Data valve = 12;
 * @return {!Array<!proto.app.model.v1.HUM_Valve_Data>}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getValveList = function() {
  return /** @type{!Array<!proto.app.model.v1.HUM_Valve_Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.model.v1.HUM_Valve_Data, 12));
};


/**
 * @param {!Array<!proto.app.model.v1.HUM_Valve_Data>} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
*/
proto.app.model.v1.HUM_Gateway_Data.prototype.setValveList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.app.model.v1.HUM_Valve_Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.HUM_Valve_Data}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.addValve = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.app.model.v1.HUM_Valve_Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.clearValveList = function() {
  return this.setValveList([]);
};


/**
 * optional HUM_Gateway_Impact gateway_impact = 16;
 * @return {?proto.app.model.v1.HUM_Gateway_Impact}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.getGatewayImpact = function() {
  return /** @type{?proto.app.model.v1.HUM_Gateway_Impact} */ (
    jspb.Message.getWrapperField(this, proto.app.model.v1.HUM_Gateway_Impact, 16));
};


/**
 * @param {?proto.app.model.v1.HUM_Gateway_Impact|undefined} value
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
*/
proto.app.model.v1.HUM_Gateway_Data.prototype.setGatewayImpact = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.model.v1.HUM_Gateway_Data} returns this
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.clearGatewayImpact = function() {
  return this.setGatewayImpact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.model.v1.HUM_Gateway_Data.prototype.hasGatewayImpact = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * @enum {number}
 */
proto.app.model.v1.HUM_Gatewate_State = {
  UNKNOWN: 0,
  CURRENTLY_MOVING: 1,
  CURRENTLY_PARKED: 2,
  WAS_PARKED_NOW_MOVING: 3,
  WAS_MOVING_NOW_PARKED: 4
};

/**
 * @enum {number}
 */
proto.app.model.v1.HUM_Device_Type = {
  GATEWAY: 0,
  BOOMERANG: 1,
  VALVE: 2
};

goog.object.extend(exports, proto.app.model.v1);
