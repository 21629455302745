import { Component, Input, ViewChild, ElementRef, HostListener, } from '@angular/core';

import { DEFAULT_MAP_SETTINGS, MapDataPoint, MapService, MapSettings, MapTooltip, } from "../map.service"
import { Deck, } from "@deck.gl/core";

import { LayerOptions, } from "../map.service"
import { SafeHtml } from '@angular/platform-browser';

import { IonIcon, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { DrawPolygonMode, TransformMode, SnappableMode, DrawRectangleMode, ViewMode } from "@nebula.gl/edit-modes";
import { AllGeoNodeImplemented } from '../../../viewmodels/geo/geonode.vmi';
import { AllDevicesVMI } from '../../../viewmodels/AllDevices.vmi';
import { BehaviorSubject } from 'rxjs';
@Component({
	selector: 'sensor-timeline-component',
	templateUrl: './sensor-timeline-component.page.html',
	styleUrls: ['./sensor-timeline-component.page.scss'],
})
export class SensorTimelineComponentPage {

	@Input() showAsIcons: boolean = true; // might change how this works ...
	@Input() showControlPanel: boolean = true;
	@Input() enableEditLayer: boolean = false;
	@Input() changeSettingsHandler: Function;
	@Input("featureCollection") featureCollection;
	@Input() showTimeSelector: boolean = true;
	@Input("deviceVMIs") deviceVMIs: AllDevicesVMI[];
	@ViewChild('mapCanvas') mapCanvas: ElementRef<HTMLCanvasElement>;
	@ViewChild('fsIcon') fsIcon: IonIcon;
	elem: any;
	public mapIsLoaded: boolean = false;
	public showTracks: boolean = false;
	private deck: Deck;
	public mapName: string = this.defaultMapName();
	private firstUpdate: boolean = true;
	public mapSettings: MapSettings = DEFAULT_MAP_SETTINGS;
	@Input() isResizable = true;
	private bounds: number[][] = this.mapService.getDefaultBounds();
	public isfullscreen: boolean = false;

	public noDevicesDetectedLabel: string = "Loading ... "

	private triplayer: LayerOptions[];
	private circleLayer: LayerOptions[];
	private currentPointLayer: LayerOptions[];
	private bitmapLayer: LayerOptions;
	private editLayer: LayerOptions;
	private editMode: any;
	private geoNodeCached: AllGeoNodeImplemented
	public isMapDoneLoading = new BehaviorSubject<boolean>(false);
	public myFeatureCollection = {
		type: "FeatureCollection",
		features: new Array()
	};
	public hideForMobile: boolean = false;
	public currentTool: string;
	public component_id = "servertimelinecomponent" + Date.now();
	public lastUpdate = {
		updateLayer: {},
		startMs: 0,
		endMs: 0
	}
	constructor(
		public mapService: MapService,
		public platform: Platform,
		public router: Router,
	) {
		window[this.component_id] = this.featureCollection;
		// platform is capacitor
		this.platform.ready().then(() => {
			if (this.platform.is("capacitor")) {
				this.hideForMobile = true;
			}
		})
			.catch(() => {
				console.log("platform not ready")
			})
	}


	private defaultMapName(): string {
		return "map-canvas-" + Math.floor(Math.random() * 1e7 * Date.now())
	}

	public init(mapName: string, mapSettings?: MapSettings): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			this.editMode = ViewMode;
			this.currentTool = "Pan";
			if (mapSettings) {
				this.mapSettings = { ...DEFAULT_MAP_SETTINGS, ...mapSettings };
			}
			(mapName == null || mapName.length <= 0) ? this.mapName = this.defaultMapName() : this.mapName = mapName;
			this.noDevicesDetectedLabel = "Loading ... ";
			await this.mapService.buildMap(this.mapName, this.mapCanvas, []).then((deck: Deck) => {
				this.deck = deck;
				this.bounds = this.mapService.getDefaultBounds();
				resolve(true);
			}).catch((err) => {
				console.error("Error building map : ", err);
				reject(false);
			});
			this.elem = document.documentElement;

		});


	}

	public ionViewDidEnter() {
		this.noDevicesDetectedLabel = "Loading ... ";
	}
	public async update(updateLayer?: LayerOptions, startMs?: number, endMs?: number) {
		if (this.deck == null) return;
		var currentPoint: MapDataPoint = {};
		console.log(this.mapSettings);
		if (updateLayer) {
			this.lastUpdate.updateLayer = updateLayer;
			this.lastUpdate.startMs = startMs || 0;
			this.lastUpdate.endMs = endMs || 0;
			console.log("last update", this.lastUpdate);
			console.log("Calling udpate layer");
			if (this.triplayer == null || this.circleLayer == null || this.currentPointLayer == null || this.bitmapLayer == null || this.editLayer == null) {
				this.triplayer = [];
				this.circleLayer = [];
				this.currentPointLayer = [];
				this.bitmapLayer = {};
				this.editLayer = {}
			}
			// Build Triplayer layer from current map points
			// if (updateLayer.name && !this.triplayer.name) {				
			// 	this.triplayer.name = updateLayer.name;
			// 	console.log("Calling udpate layer: updateLayer.name: ", updateLayer.name);

			// }
			if (updateLayer.devices) {
				console.log("Calling udpate layer: updateLayer.devices: ", updateLayer.devices.length);
				if (updateLayer.devices.length > 0) {
					// get current (icon layer)
					this.currentPointLayer = [];
					var points: MapDataPoint[] = [];
					for (let index = 0; index < updateLayer.devices.length; index++) {
						const element = updateLayer.devices[index];
						try {
							var current_point = await element.getCurrentMapDataPoint();
							current_point.getClick = () => {
								this.router.navigate(["vendors/default/default-device", element.getId()]);
							};
							current_point.getTooltip = element.getTooltipHtml.bind(element);
							points.push(current_point);
						}
						catch (e) {
							// console.error(e);
						}
					}
					// this.currentPointLayer.push({ layer: this.mapService.buildIconLayer("currrent location" + Math.random().toString(), [points.shift() || {}], 0.4)})
					if (updateLayer.telemetryMapDataPoints) {
						this.triplayer = [];
						for (let index = 0; index < updateLayer.telemetryMapDataPoints.length; index++) {
							var tripPoints: MapDataPoint[] = updateLayer.telemetryMapDataPoints[index]
							if (tripPoints.length > 0) {
								currentPoint = tripPoints[0];
								console.log(currentPoint);
								var device = tripPoints[0].deviceVMI;
								console.log(device)
								currentPoint.getClick = () => {
									this.router.navigate(["vendors/default/default-device", currentPoint.deviceVMI?.getId()]);
								};
								var tt: MapTooltip = {
									html: `<h2>${device?.getDisplayName() || "Device"}</h2><div>${device?.getDisplayUuid() || device?.getId()}</div>`,
								}
								currentPoint.getTooltip = device?.getTooltipHtml.bind(device, currentPoint);
								this.currentPointLayer.push({ layer: this.mapService.buildIconLayer("currrent location" + Math.random().toString(), [currentPoint], 1) });
								var name = ""
								if (updateLayer.name) {
									name = updateLayer.name + Math.random().toString();
								}
								if(this.mapSettings.showTracks){
									this.triplayer.push({ name: name, layer: await this.mapService.buildLineLayer(name, tripPoints) });
									this.circleLayer.push({ name: name + "circle", layer: await this.mapService.buildCircleLayer("circle-layer", tripPoints) });
								}
								this.bounds = this.mapService.updateBounds(this.bounds, tripPoints);
								this.mapService.zoomFitLayers(this.deck, this.bounds);
							}
						}
						// this.currentPointLayer.push({ layer: this.mapService.buildIconLayer("currrent location" + Math.random().toString(), points, 0.4)})
					} else {
						// this.currentPointLayer.push({ layer: this.mapService.buildIconLayer("currrent location" + Math.random().toString(), [points.shift() || {}]) });
						this.currentPointLayer.push({ layer: this.mapService.buildIconLayer("currrent location" + Math.random().toString(), points, 1)})
						this.triplayer = [];
						this.circleLayer = [];
					}
				}
			}
			if (updateLayer.geoNodes) {
				this.geoNodeCached = updateLayer.geoNodes[0];
				console.log("Calling udpate layer: updateLayer.geoNodes: ", updateLayer.geoNodes[0]);
				this.bitmapLayer.layer = []
				for (let index = 0; index < updateLayer.geoNodes.length; index++) {
					this.bitmapLayer.layer.push(this.mapService.buildBitmapLayer(updateLayer.geoNodes[index].getName() + "geo-nodes", updateLayer.geoNodes[index]?.getCoords(), updateLayer.geoNodes[index].getImageUrl()));
				}
				console.log(this.bitmapLayer.layer);
				console.log('this.geoNodeCached', this.geoNodeCached);
				if (this.geoNodeCached?.model?.geoNode?.geoJson) {
					console.log(this.geoNodeCached.model.geoNode.geoJson)
					MapService.myFeatureCollection = JSON.parse(this.geoNodeCached.model.geoNode.geoJson);
				}
				console.log("updateLayer.geoNodes", MapService.myFeatureCollection);
			}
			if (this.enableEditLayer) {
				this.editLayer.layer = this.mapService.buildEditableGeoJsonLayer("nebula", MapService.myFeatureCollection, this.editMode, ({ updatedData, editType, _featureIndexes, editContext }) => {
					MapService.myFeatureCollection = updatedData
					if (this.geoNodeCached && this.geoNodeCached.model && this.geoNodeCached.model.geoNode && this.geoNodeCached.model.geoNode.geoJson) {
						console.log(this.geoNodeCached.model.geoNode.geoJson);
						this.geoNodeCached.model.geoNode.geoJson = JSON.stringify(updatedData);

					}
					if (['movePosition', 'extruding', 'rotating', 'translating', 'scaling', 'addFeature'].includes(editType)) {
						console.log('onEdit', editType, editContext, updatedData, MapService.myFeatureCollection);
					}
					this.update({ geoNodes: [this.geoNodeCached] });


				});
				this.deck.setProps({ getCursor: this.editLayer.layer.getCursor.bind(this.editLayer.layer) });
			}
			this.mapIsLoaded = true;
		}
		if (this.mapSettings.zoomAll) {
			if (this.mapService.zoomFitLayers(this.deck, this.bounds)) {
				this.firstUpdate = false;
			}
		}
		var layers = this.getLayers();
		if (layers.length > 0) {
			this.mapService.updateMap(this.deck, layers);
		}
		// 	if(this.triplayer && this.triplayer.layer && this.circleLayer.layer && this.bitmapLayer.layer) {
		// 	console.log("updating with line lyer in here")
		// 	this.mapService.updateMap(this.deck, this.bitmapLayer.layer.concat([this.editLayer.layer, this.triplayer.layer, this.circleLayer.layer, this.currentPointLayer.layer]));
		// }
		// else if (this.currentPointLayer && this.currentPointLayer.layer && this.triplayer.layer) {
		// 	this.mapService.updateMap(this.deck, [this.bitmapLayer.layer, this.editLayer.layer, this.currentPointLayer.layer]);
		// }
		// 		else if (this.currentPointLayer && this.currentPointLayer.layer) {
		// 	this.mapService.updateMap(this.deck, [this.bitmapLayer.layer, this.editLayer.layer, this.currentPointLayer.layer]);
		// }
		// else if (this.bitmapLayer.layer) {
		// 	this.mapService.updateMap(this.deck, [this.editLayer.layer, this.bitmapLayer.layer]);
		// }
		// else {
		// 	this.mapService.updateMap(this.deck, [this.editLayer.layer]);
		// }
		if (!this.firstUpdate) {
			this.noDevicesDetectedLabel = "No Devices Detected"
		}
		this.isMapDoneLoading.next(true);
		this.mapIsLoaded = true;
	}
	getLayers() {
		var layers: any[] = [];
		if (this.triplayer.length) {
			for (let i = 0; i < this.triplayer.length; i++) {
				layers.push(this.triplayer[i].layer);
			}
		}
		if (this.circleLayer.length > 0) {
			for (let i = 0; i < this.circleLayer.length; i++) {
				layers.push(this.circleLayer[i].layer);
			}
		}
		if (this.bitmapLayer.layer) {
			layers.push(this.bitmapLayer.layer);
		}
		if (this.editLayer.layer) {
			layers.push(this.editLayer.layer);
		}
		if (this.currentPointLayer.length > 0) {
			for (let i = 0; i < this.currentPointLayer.length; i++) {
				layers.push(this.currentPointLayer[i].layer);
			}
		}
		return layers;
	}
	public showFocusTab: boolean = false;
	public focusTab: SafeHtml;
	public mapClick(mapPoint?: MapDataPoint) {
		if (mapPoint) {
			this.focusTab = "going to have some html here";
			this.showFocusTab = true;
			return;
		}
		this.showFocusTab = false;
	}

	public selectTileLayer() {
		console.log("Calling select tile layer");
		this.mapService.selectTileLayer().then(() => {
			console.log("sensor timeline got done");
			this.update();
		});
	}

	public changeSettings() {
		this.mapService.changeSettings(this.mapSettings).then((newSettings: MapSettings) => {
			console.log('changedSettings', newSettings)
			this.mapSettings = newSettings;
			if (!this.mapSettings.startMs && !this.mapSettings.endMs) {
				this.mapSettings.startMs = this.lastUpdate.startMs;
				this.mapSettings.endMs = this.lastUpdate.endMs;
			}
			this.lastUpdate.startMs = this.mapSettings?.startMs || Date.now() - 86400000;
			this.lastUpdate.endMs = this.mapSettings?.endMs || Date.now();
			console.log(this.lastUpdate)
		}).catch(() => { }).finally(() => {
			this.update(this.lastUpdate.updateLayer, this.mapSettings.startMs, this.mapSettings.endMs);
		});
	}

	onFullScreen() {
		const docElmWithBrowsersFullScreenFunctions = document.querySelector("#mapComp") as HTMLElement & {
			mozRequestFullScreen(): Promise<void>;
			webkitRequestFullscreen(): Promise<void>;
			msRequestFullscreen(): Promise<void>;
		};

		if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
			docElmWithBrowsersFullScreenFunctions.requestFullscreen();
		} else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
			/* Firefox */
			docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
		} else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
		} else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
			/* IE/Edge */
			docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
		}
	}

	closeFullScreen() {
		const docWithBrowsersExitFunctions = document as Document & {
			mozCancelFullScreen(): Promise<void>;
			webkitExitFullscreen(): Promise<void>;
			msExitFullscreen(): Promise<void>;
		};
		if (docWithBrowsersExitFunctions.exitFullscreen) {
			docWithBrowsersExitFunctions.exitFullscreen();
		} else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
			/* Firefox */
			docWithBrowsersExitFunctions.mozCancelFullScreen();
		} else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
			/* Chrome, Safari and Opera */
			docWithBrowsersExitFunctions.webkitExitFullscreen();
		} else if (docWithBrowsersExitFunctions.msExitFullscreen) {
			/* IE/Edge */
			docWithBrowsersExitFunctions.msExitFullscreen();
		}
	}

	@HostListener('document:fullscreenchange', ['$event'])
	@HostListener('document:webkitfullscreenchange', ['$event'])
	@HostListener('document:mozfullscreenchange', ['$event'])
	@HostListener('document:MSFullscreenChange', ['$event'])
	fullscreenmode() {
		console.log(this.isfullscreen)
		if (this.isfullscreen) {
			this.isfullscreen = false;
			this.fsIcon.name = "expand-outline";
		}
		else {
			this.isfullscreen = true;
			this.fsIcon.name = "contract";
		}

	}
	toggleFullscreen() {
		if (this.isfullscreen) {
			this.closeFullScreen();
		}
		else {
			this.onFullScreen()
		}
	}

	public setEditLayerMode(mode: string) {
		if (!this.editLayer.layer)
			return
		switch (mode) {
			case "transform":
				// this.editLayer.layer.setState({mode: new SnappableMode(new TransformMode()), modeConfig: {}, selectionTool: null})
				this.editMode = new SnappableMode(new TransformMode())
				this.currentTool = "Transform"
				this.update(this.editLayer.layer)
				break;
			case "pan":
				this.editMode = ViewMode;
				this.currentTool = "Pan"
				this.update(this.editLayer.layer);
				break;
			case "rectangle":
				this.editMode = DrawRectangleMode
				this.currentTool = "Rectangle"
				this.update(this.editLayer.layer);
				break;
			case "polygon":
				this.editMode = DrawPolygonMode
				this.currentTool = "Polygon"
				this.update(this.editLayer.layer);
				break;
		}
		console.log(this.editLayer)
	}
	setShowTracks(value) {
		this.showTracks = value;
		this.triplayer = [];
	}
}
