import { AllServcies } from "../startup/startup.service"
import { GPSPosition, Sensor } from '../../generated_proto/google/app/model/v1/data_pb'
import { SensorViewModelImplemented, SensorType } from "../../viewmodels/sensor.vmi"
import { CONNECTION_STATE } from "../constants"
import { LoggerOptions } from "../logger/logger.service"

export class SerialPortConnection {

	private loggerOptions:LoggerOptions = {
		prefix:"SerialPortConnection",
		allOn:true,
		verboseOn:false,
		debugOn:true,
	};

	public connectionState:CONNECTION_STATE = CONNECTION_STATE.UNKNOWN;
	public parentVMI:SensorViewModelImplemented;
	private isConnected:boolean = false;
	public isStreaming:boolean = false;
	public isSubscribed:boolean = false;
	private services:AllServcies;;

	private sensorType:SensorType = SensorType.SENSOR_UNKNOWN;

	public path:string="";

	public pnpId:string="";

	public counter:number = 0;

	constructor
	(
		services:AllServcies,
		parentVMI?:SensorViewModelImplemented,
	)
	{
		if(services){
			this.services = services;
		}
		if(parentVMI){
			this.parentVMI = parentVMI;
		}
	}

	// This context is from the underlying plugin
	// IE: Elecron :
	// {
	// locationId: undefined
	// manufacturer: "SAMSUNG"
	// path: "/dev/ttyACM0"
	// pnpId: "usb-SAMSUNG_SAMSUNG_Android_988e50304851493153-if01"
	// productId: "6860"
	// serialNumber: "988e50304851493153"
	// vendorId: "04e8"
	// }

	public context:any = null;
	setContext( context:any ) {
		this.context = context;
	}
	getContext() : any {
		return this.context;
	}
	setSensorType(st:SensorType) : void {
		this.sensorType = st;
	}
	getSensorType() : SensorType{
		return this.sensorType;
	}
	connect(baudrate:number, selecteFile:any = null) : Promise<boolean>{
		if(this.services){
			this.services.logger?.verbose(this.loggerOptions, "connect");
			if(this.services.comport){
				return this.services.comport.connect(this, baudrate, selecteFile);
			}
		}
		return Promise.reject("no services")
	}
	writeByte(byte:number) : Promise<boolean>{
		if(this.services){
			this.services.logger?.debug(this.loggerOptions, "writing byte: ", byte);
			if(this.services.comport) return this.services.comport.writeByte(this, byte);
		}
		return Promise.reject("writeByte:no services");
	}
	writeBytes(bytes:Buffer) : Promise<boolean>{
		if(this.services){
			return new Promise( async (resolve,reject) => {
				for (let index = 0; index < bytes.length; index++) {
					const byte = bytes[index];
					if(this.services.comport)await this.services.comport.writeByte(this, byte).catch(e=> reject(e));
				}
				resolve(true);
			});
		}
		return Promise.reject("writeBytes:no services");
	}
	disconnect() {
		if(this.services.comport){
			return this.services.comport.disconnect(this);
		}
	}

	async processStreamingData(data) {
		// if(this.parentVMI.getSensor().getType() == SensorType.SENSOR_PAGER){
		// 	this.connectionState = CONNECTION_STATE.STREAMING;

		// 	var newSensor:Sensor = await this.services.parsers.getTelemetrySTE(data);
		// 	newSensor.getTelemetryList()[0].setEpochMiliseconds(Date.now());
		// 	newSensor.getTelemetryList()[0].setMessageCounter(this.counter++);
		// 	newSensor.getTelemetryList()[0].setUid(this.parentVMI.getSensor().getUid());
		// 	newSensor.getTelemetryList()[0].setUuid(this.parentVMI.getSensor().getUuid());

		// 	var position_serial = (await this.services.native.getGPSPosition()).serializeBinary();
		// 	if(position_serial){
		// 		newSensor.getTelemetryList()[0].setPosition(GPSPosition.deserializeBinary(position_serial));
		// 	}

		// 	if(this.parentVMI){
		// 		this.parentVMI.addSensorUpdate(newSensor);
		// 	}
		// }
	}
	
	setIsConnected( isConnected:boolean=false ){
		this.isConnected = isConnected;
		if(this.isConnected){
			if(this.connectionState<=CONNECTION_STATE.CONNECTED){
				this.connectionState = CONNECTION_STATE.CONNECTED;
			}
		}
		else {
			this.services.logger?.verbose(this.loggerOptions, "Setting display for disconnected");
			this.connectionState = CONNECTION_STATE.DISCONNECTED;
		}
	}
	getIsConnected(){
		return this.isConnected;
	}
}