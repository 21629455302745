import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { AppAdminGuard, AuthGuard, ChannelGuard,  } from './services/auth/auth.guard';
import * as Settings from "./services/settings/settings.json"

var startupPage = (JSON.parse(JSON.stringify(Settings)).APP_STARTUP_PAGE.length>0) ? JSON.parse(JSON.stringify(Settings)).APP_STARTUP_PAGE: 'protected/devices';
// var startupPage = 'protected/devices';

const routes: Routes = [
	{ path: '', redirectTo: startupPage, pathMatch: 'full'},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
	},
	{
		path: 'protected',
		canActivate: [AuthGuard], // Must be authenticated to view these paths
		loadChildren: () => import('./protected/protected-routing.module').then( m => m.ProtectedRoutingModule)
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
	},
	{
		path: 'tools',
		canActivate: [AuthGuard], // Must be authenticated to view these paths
		loadChildren: () => import('./tools/tools-routing.module').then( m => m.ToolsRoutingModule)
	},
	{
		path: 'app-admin',
		canActivate: [AppAdminGuard], // Must be authenticated and app admin to view these paths
		loadChildren: () => import('./app-admin/app-admin-routing.module').then( m => m.AppAdminRoutingModule)
	},
	{
		path: 'device-management',
		canActivate: [AppAdminGuard], // Must be authenticated and app admin to view these paths
		loadChildren: () => import('./device-management/device-management-routing.module').then( m => m.DeviceManagementRoutingModule)
	},
	{
		path: 'test-runner',
		canActivate: [AppAdminGuard], // Must be authenticated and app admin to view these paths
		loadChildren: () => import('./test-runner/test-runner-routing.module').then( m => m.TestRunnerRoutingModule)
	},
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Vendor Paths
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	{
		path: 'vendors',
		loadChildren: () => import('./vendors/vendors-routing.module').then( m => m.VendorsRoutingModule)
	},
	{
		path: 'release-notes',
		loadChildren: () => import('./release-notes/release-notes.module').then( m => m.ReleaseNotesPageModule)
	},
  {
    path: 'prototype-device',
    loadChildren: () => import('./vendors/external/pages/prototype-device/prototype-device.module').then( m => m.PrototypeDevicePageModule)
  },



	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
]
@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
