import { DeviceViewModelImplemented } from "../../viewmodels/Device.vmi";

export class RlBodyWornDeviceViewModelImplemented extends DeviceViewModelImplemented {


	public static getStaticTitle() : string {
		return "Body Worn Device"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return RlBodyWornDeviceViewModelImplemented.getStaticTitle();
	}

	public thumbnailImagePath:string = "assets/app/rl/body-worn.jpg";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}
}