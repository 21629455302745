import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditSettingsModalPageRoutingModule } from './edit-settings-modal-routing.module';

import { EditSettingsModalPage } from './edit-settings-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditSettingsModalPageRoutingModule
  ],
  declarations: [EditSettingsModalPage]
})
export class EditSettingsModalPageModule {}
