import { Component, Input, } from '@angular/core';

@Component({

	selector: 'auth-image',
	template: '<img [src]="src | imageAuth | async" style="height: 100%; max-width: 100%; object-fit: contain;" [style.width.px]=width [alt]="alt"/>',
})
export class AuthImageComponent {

	@Input() src: string|undefined;
	@Input() alt = '';
	@Input() width: number|undefined;
}
