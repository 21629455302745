import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SensorMapLineComponentPageRoutingModule } from './sensor-map-line-component-routing.module';

import { SensorMapLineComponentPage } from './sensor-map-line-component.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SensorMapLineComponentPageRoutingModule
  ],
  declarations: [SensorMapLineComponentPage]
})
export class SensorMapLineComponentPageModule {}
