// Handle the currently logged in users information and commands
import { Injectable, resolveForwardRef } from '@angular/core';
import { PersonViewModelImplemented } from '../../viewmodels/person.vmi';
import { AllServcies } from '../startup/startup.service';

import { BehaviorSubject } from "rxjs";
import { ChannelViewModelImplemented } from '../../viewmodels/channel.vmi';
import { Channel, Person } from '../../generated_proto/google/app/model/v1/data_pb';

export interface AllKeys {
	user_encrypt_pair?: any,
	user_sign_pair?: any,
	user_public_key?: string,
	user_uuid?:string,
}

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private userProfileVMI : PersonViewModelImplemented;
	public previousChannels : ChannelViewModelImplemented[] = [];
	public update = new BehaviorSubject(false); ;

	constructor(

	)
	{

	}

	init(allServices:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			this.setServices(allServices);
			if(this.services.auth && this.services.auth.hasAuth){
				resolve(true)
			}
			else {
				resolve(true);
			}
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}



}
