
/*  !!! WARNING !!! --- USE EXCULSIVELY THROUGH THE POUCHDB SERVICE */
// This is so we can extend the functionality of pouchb and have the 
// same interface as the rest of the services. 

// Keep the code more organized and easier to read.

import { FileViewModelImplemented, } from '../../viewmodels/File.vmi';
import { AllServcies } from '../startup/startup.service';
import { ModelType } from '../../generated_proto/protobuf-ts/pb/v2/models';

export class FilesPouchdbService {


	constructor(
	)
	{

	}

	public init(allServices:AllServcies) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
			}
			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getList( app_id:number ) : Promise<FileViewModelImplemented[]> {
		var fileList:FileViewModelImplemented[] = [];

		var searchOptions = {
			include_docs: true,
			attachments: false,
		}
		return new Promise( (resolve, reject) => {
			if(!this.services.pouch || !this.services.data){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.FILE_MODEL);
			db.remoteDb.allDocs(searchOptions)
			.then( (result:any) => {
				if(result.rows.length>0){
					for (let index = 0; index < result.rows.length; index++) {
						const row = result.rows[index];
						const doc = row.doc;
						var newFile:FileViewModelImplemented = new FileViewModelImplemented(this.services);
						newFile.updateLocalFromDb(doc);
						fileList.push(newFile);
					}
					resolve(fileList);
				}
				else {
					resolve([]);
				}
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

	public getFile( app_id:number, md5:Uint8Array, getAttachment:boolean=false, updateFile?:FileViewModelImplemented ) : Promise<FileViewModelImplemented> {
		return new Promise( (resolve, reject) => {
			
			var _id = FileViewModelImplemented.GenerateDbKey(md5);
	
			var fetchOptions = {
				attachments: false,
			}
			// Some weird bug with getAttachment where its complaining about blob types.
			// Its less efficent in node to do thsi as a full get in b64, but in web
			// you're required to do this anyway. so, we'll fetch as stringified with rest
			// if(!(typeof window === 'undefined')){
			// 	fetchOptions.attachments = getAttachment;
			// }
			if(!this.services.pouch || !this.services.data){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.FILE_MODEL);
			db.remoteDb.get(_id, fetchOptions)
			.then( async (result:any) => {
				if(result){
					var newFile:FileViewModelImplemented = new FileViewModelImplemented(this.services);
					if(updateFile){
						newFile = updateFile;
					}
					await newFile.updateLocalFromDb(result);
					if(!getAttachment){
						resolve(newFile);
						return;
					}
					var keys = Object.keys(result._attachments);
					if(keys.length<=0){
						reject("Attachments not found");
						return;
					}
					if(typeof window === 'undefined'){
						db.remoteDb.getAttachment(_id, keys[0]).then( (data:Blob|Buffer) => {
							// if blob get uint8array from blob
							if(typeof window === 'undefined'){
								data = data as Buffer;
								if(newFile.model.file)newFile.model.file.data = data
								resolve(newFile);
							}
							else {
								data = data as Blob;
								var fileReader = new FileReader();
								fileReader.onload = () => {
									if(newFile.model.file)newFile.model.file.data = new Uint8Array(fileReader.result as ArrayBuffer)
									resolve(newFile);
								}
								fileReader.readAsArrayBuffer(data);
							}
						}).catch( (err) => {
							console.log(err);
							reject(err);
						});
					}
					else {
						resolve(newFile)
					}
				}
				else {
					reject("No File Found");
				}
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

	public saveFile( app_id:number, file:FileViewModelImplemented ) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(!file.model.file){
				reject("No File Found");
				return;
			}
			var  attachment:any = {}; // Done this way to allow dynamic file name setting
			attachment["attachment"] = {
				content_type: file.model.file.mimeType,
				data: file.getFile()
			};
			var full_doc:any = Object.assign({}, file.modelToJson());
			full_doc._id = file.generateDbKey(),
			full_doc._attachments = attachment;

			if(!this.services.pouch || !this.services.data){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.FILE_MODEL);
			db.remoteDb.get(file.generateDbKey())
			.then((doc) => {
				resolve(false) // Already exists, didn't save.
			})
			.catch((err) => {
				db.remoteDb.put(full_doc) // save full doc
				.then( () => {
					resolve(true)
				})
				.catch( (err) => {
					console.log(err);
					reject(true)
				});
			});
		})
	}

	public deleteFile( app_id:number, file:FileViewModelImplemented ) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(!file.model.file){
				reject("No File Found");
				return;
			}
			var _id = file.generateDbKey();
			if(!this.services.pouch || !this.services.data){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.FILE_MODEL);
			await db.remoteDb.get(_id)
			.then( (doc:any) => {
				db.remoteDb.remove(doc).then( () => {
					resolve(true)
				}).catch( (err) => {
					console.log(err);
					reject(err)
				});
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

}
