import { Component, OnInit, SimpleChanges, ViewChild, Input, ChangeDetectorRef } from '@angular/core';

import { ChartComponent } from 'ng-apexcharts';

@Component({
	selector: 'device-charts-component',
	templateUrl: './device-charts.component.html',
	styleUrls: ['./device-charts.component.scss'],
})
export class DeviceChartsComponent implements OnInit {

	// public derrivedTelmetryFormatted: any = {};
	public loading:boolean = true;

	public sensorsArray:any = [];

	constructor(
		public changeDetectorRef:ChangeDetectorRef,
	){

	}

	ngOnInit() {
		// console.log("SensorChartComponent ngOnInit");
	}

	public ngOnChanges(changes: SimpleChanges) {
		// console.log("changes is : ", changes);
	}

	private iterateDerrivedToList(obj:any){
		var keys = Object.keys(obj);
		for (let index = 0; index < keys.length; index++) {
			const sensor:any = obj[keys[index]];
			if(sensor.values && sensor.chartValid){
				this.sensorsArray.push(sensor);
			}
			else {
				if(Object.keys(sensor).length>0){
					this.iterateDerrivedToList(sensor);
				}
			}
		}
	}

	public showLoading(){
		this.loading = true;
	}

	public refresh( derrivedTelmetryFormatted:any) {
		console.log("derrivedTelmetryFormatted is : ", derrivedTelmetryFormatted);
		if(Object.keys(derrivedTelmetryFormatted).length>0){
			this.loading = false;
		}

		this.sensorsArray = [];
		this.iterateDerrivedToList(derrivedTelmetryFormatted);
		this.sensorsArray = this.sensorsArray.sort((a,b)=>{
			if(!a.order){
				a.order = this.sensorsArray.length;
			}
			if(!b.order){
				b.order = this.sensorsArray.length;
			}
			return a.order - b.order;
		});

		// this.derrivedTelmetryFormatted = derrivedTelmetryFormatted;
		this.changeDetectorRef.detectChanges();
	}

}
