
import { DeviceTypeGroups, DeviceViewModelImplemented } from "../../viewmodels/Device.vmi";
import { AllServcies } from "../../services/startup/startup.service"
import { DeviceTypeIds, } from "../../viewmodels/Device.vmi";


export class HumSensorViewModelImplemented extends DeviceViewModelImplemented {

	constructor
	(
		services:AllServcies,
	)
	{
		super(services);
		if(this.model.device){
			this.model.device.deviceType = DeviceTypeIds.HUM_SENSOR;
		}
	}

	public static getStaticTitle() : string {
		return "Hum Sensor : "
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		var title = HumSensorViewModelImplemented.getStaticTitle();
		if(this.model.derivedTelemetry?.vendorData?.data?.oneofKind == "industrial"){
			if(this.model.derivedTelemetry?.vendorData?.data?.industrial.strain){
				return title + "Strain"
			}
			if(this.model.derivedTelemetry?.vendorData?.data?.industrial.vibration){
				return title + "Vibration"
			}
			if(this.model.derivedTelemetry?.vendorData?.data?.industrial.valve){
				return title + "Valve"
			}
		}
		return "Hum Sensor"
	}

	public thumbnailImagePath:string = "assets/app/hum/hum-sensor.webp";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public getDeviceTypeGroups() : DeviceTypeGroups {
		var groups:DeviceTypeGroups = [];
		if(this.services.settings?.SETTINGS?.APP_ID == 2){
			groups.push({
				deviceTypeId: DeviceTypeIds.HUM,
				formattedName: "HUM",
			});
		}
		if(this.model.device?.deviceType == DeviceTypeIds.HUM_SENSOR){
			groups.push({
				deviceTypeId: DeviceTypeIds.HUM_SENSOR,
				formattedName: "HUM Sensors",
			});
		}
		if(this.model.device?.deviceType == DeviceTypeIds.HUM_MAGNETIC){
			groups.push( {
				deviceTypeId: DeviceTypeIds.HUM_MAGNETIC,
				formattedName: "HUM Magnetic",
			});
		}
		if(this.model.device?.deviceType == DeviceTypeIds.HUM_VIBRATION){
			groups.push( {
				deviceTypeId: DeviceTypeIds.HUM_VIBRATION,
				formattedName: "HUM Vibration",
			});
		}
		return groups;
	}

}