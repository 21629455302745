
import { DeviceTypeIds, DeviceViewModelImplemented, } from "../../viewmodels/Device.vmi";
import { BluetoothConnection, } from "../../services/bluetooth/ble.connetion"
import { CONNECTION_STATE, } from "../../services/constants"
import { AllServcies, } from "../../services/startup/startup.service";
import { GPSScanRecord, Telemetry, VendorData, RadationData, GammaData, NeutronData, GammaNeutronState, } from "../../generated_proto/protobuf-ts/pb/v2/data";
import { UtilService } from "../../services/util/util.service";
import { DeviceModel } from "../../generated_proto/protobuf-ts/pb/v2/models";
import { BluetoothCommonInterface } from "../../services/bluetooth/bluetooth.service";
import { TelemetryViewModelImplemented, } from "../../viewmodels/Telemetry.vmi"
import { MapDataPoint } from "../../services/map/map.interfaces";
import { SimpleEccFrame, UtpMessageType } from "../../services/simple-ecc/simple-ecc.frame";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { SteCommands, SteCommand, Location, SessionLogResponse, SessionLog, } from "../../generated_proto/protobuf-ts/pb/v2/vendors/ste_ble_data";
import { DeviceModel as STE_DeviceModel} from "../../generated_proto/protobuf-ts/pb/v2/vendors/ste_ble_data";

import { encode as varintEncode, decode as varintDecode } from 'varint';

export class SteBluetoothConnection extends BluetoothConnection {

	public heartBeatIntervalMs:number = 1500;

	constructor
	(
		services:AllServcies,
		bluetoothCommonInterface?:BluetoothCommonInterface,
	)
	{
		super(services, bluetoothCommonInterface);
		if(services){
			this.services = services;
		}
		if(bluetoothCommonInterface){
			this.bluetoothCommonInterface = bluetoothCommonInterface;
			console.log("CREATED STEBLUETOOTH CONNECTION : ", this.bluetoothCommonInterface);
		}
		else {
			console.error("ERROR CREATING STEBLUETOOTH CONNECTION");
		}
	}

	async initalizeDevice() : Promise<boolean> {

		this.keepConnected = true;
		return new Promise( async (resolve, reject) => {
			console.log("STARTING STE BLUETOOTH CONNECTING AND ALL THAT");
			var bleTxRxService:string = "49535343-fe7d-4ae5-8fa9-9fafd205e455";
			var bleTxRxCharacteristic:string = "49535343-1e4d-4bd9-ba61-23c647249616";

			var bleCommandService = 0xff00;
			var bleCommandCharacteristic = 0x0001;
			var bleSessionSubscribeCharacteristic = 0x0002;

			var parentVMI = this.parentVMI as SteDeviceViewModelImplemented;
			var eccFrame = parentVMI.eccFrame;
			if(!eccFrame){
				reject("No SimpleEccFrame");
				return;
			}
			var publicKey = Uint8Array.from([ 165,  67,  90, 101,  75, 154, 137,  87,  25,  59, 214, 105, 221, 198, 100,  97,  25,  35,  64, 155,  47, 194, 192, 127, 208, 197, 101,  69, 221, 127, 196,  61, 124, 177, 101,   0, 205, 180,  68,  63,  31, 210, 177, 16, 210,  46, 143,  74,  50,  71, 219,  88, 175, 204, 195, 179, 166, 201, 193,  65, 226,  87,  84, 200]);
			eccFrame.setPublicKey(publicKey);
			var privateKey  =  Uint8Array.from([ 177, 123, 93,  95,  81,  56,  10,  82, 236,  22,  5, 135, 158, 212, 114, 178, 234, 250, 14, 248, 181,  53, 219, 154, 192, 164, 59,  61, 160, 196, 162,  50]);
			eccFrame.setPrivateKey(privateKey);

			if(this.parentVMI.model.device?.deviceType == DeviceTypeIds.STE_NRF){
				if(this.getIsConnected()){
					resolve(true);
					return;
				}
			}
			else {
				resolve(true);
			}

			this.connectionState = CONNECTION_STATE.CONNECTING;
			if(!this.getIsConnected()){
				try{
					await this.connect().catch( (e) => {
						reject(e);
						return;
					});
				}
				catch(e){
					reject(e);
					return;
				}
			}

			await this.services.data?.saveDevice( this.services.settings?.SETTINGS.APP_ID, this.parentVMI ).then( (saved) => {
				console.log("bluetoothConnection:initalizeDevice: saved device");
				this.parentVMI.update();
			}).catch( (err) => {
				console.log("Error saving sensor to device", err);
			});

			if(this.parentVMI.model.device?.deviceType == DeviceTypeIds.STE_LRM){
				await this.services.util?.sleep(50);
				await this.services.bluetooth?.writeCharacteristic(this, bleTxRxService, bleTxRxCharacteristic, "CL!", true);
				await this.services.util?.sleep(50);
			}
			else  if(this.parentVMI.model.device?.deviceType == DeviceTypeIds.STE_RADPACK){
				await this.services.util?.sleep(50);
				await this.services.bluetooth?.writeCharacteristic(this, bleTxRxService, bleTxRxCharacteristic, "CR!", true);
				await this.services.util?.sleep(50);
			}

			if(this.parentVMI.model.device?.deviceType != DeviceTypeIds.STE_NRF){
				resolve(true);
			}

			this.connectionState = CONNECTION_STATE.CONNECTED;
			var processEccFrameForSte = async (data, command:boolean=false) => {
				if(eccFrame){
					// need to process whatever is coming through here first
					try{
						var newEccFrame = new SimpleEccFrame(this.services);
						newEccFrame.setServices(this.services);
						newEccFrame.setPublicKey(publicKey);
						newEccFrame.setPrivateKey(privateKey);
						if(eccFrame.client_secret.length>0){
							newEccFrame.client_secret = Uint8Array.from(eccFrame.client_secret);
						}
						var parsedData = await newEccFrame.parseFullData(data);
						this.connectionState = CONNECTION_STATE.STREAMING;
						if(newEccFrame.message_type == UtpMessageType.CLIENT_PUB_ECC256_KEY_SEND_ONBOARDING){
							console.log("UtpMessageType.CLIENT_PUB_ECC256_KEY_SEND_ONBOARDING")
							if(newEccFrame.client_secret){
								eccFrame.client_secret = Uint8Array.from(newEccFrame.client_secret);
							}
						}
						else if(newEccFrame.message_type == UtpMessageType.MESSAGE_ECC256_GCM_SEND){
							console.log("UtpMessageType.MESSAGE_ECC256_GCM_SEND")
							// command response
							var length = varintDecode(parsedData);
							var commandData = parsedData.slice(varintDecode.bytes);
							var commandResponse = SteCommand.fromBinary(commandData);
							parentVMI.processCommandResponse(commandResponse, command);
						}
						else if(newEccFrame.message_type == UtpMessageType.RAW_STREAM){
							console.log("UtpMessageType.RAW_STREAM")
							this.processStreamingData(parsedData).catch( (err) => {
								reject(err)
							});
						}
					}
					catch(e){
						console.error("Error decrypting data", e);
					}
				}
				else { // no eccFrame, old devices processs the streaming data here
					console.warn("No eccFrame, bleCommandService streaming data w/o encryption to process");
				}
			}

			if(this.parentVMI.model.device?.deviceType == DeviceTypeIds.STE_PAGER_S || this.parentVMI.model.device?.deviceType == DeviceTypeIds.STE_NRF){
				
				// await this.services.bluetooth?.forceGetCharacteristics(this);
				console.log("Done doing charactersitscs")
				if(this.parentVMI.model.device.deviceType == DeviceTypeIds.STE_NRF){
					// important to do onboarding here. Pass keys and get encryption setup
					console.log("writing bytes")
					if(this.services.encryption){
						console.log("Bluetooth here does have encryption")
					}
					// await this.services.bluetooth?.writeCharacteristic(this, bleCommandService, bleCommandCharacteristic, Uint8Array.from([0,1,2]));
					console.log("Subbing for command channel")
					await this.services.util?.sleep(100);
					
					console.log("bleCommandCharacteristic: ", bleCommandCharacteristic);
					await this.services.bluetooth?.subscribeCharacteristic(this, bleCommandService, bleCommandCharacteristic, async (data) => {
						this.connectionState = CONNECTION_STATE.INITIALIZING;
						processEccFrameForSte(data, true)
					});
					await this.services.util?.sleep(600);
					console.log("bleSessionSubscribeCharacteristic: ", bleSessionSubscribeCharacteristic);
					await this.services.bluetooth?.subscribeCharacteristic(this, bleCommandService, bleSessionSubscribeCharacteristic, async (data) => {
						// console.log("Subscribing to session channel, has client secret, processing the catchup");
						if(eccFrame?.hasClientSecret()){
							// console.log("catch up has secret :: decoding ");
							// console.log("catchup raw data : ", EncryptionService.toHexString(data));
							processEccFrameForSte(data)
						}
						else {
							console.log("On Subscribing to session channel, no client secret, sending onboarding bytes")
						}
					});
					
					// delay one second
					await this.services.util?.sleep(600);
					eccFrame.dev_id = 0x01;
					var dataOut = await eccFrame.getOnboardingBytes(this.services);
					console.log("bleCommandCharacteristic : Writing out ounboarding Bytes : ", EncryptionService.toHexString(dataOut));
					await this.services.bluetooth?.writeCharacteristic(this, bleCommandService, bleCommandCharacteristic, dataOut, false)?.catch( (e) => {
						console.log("Error writing onboarding bytes: ", e);
					})
					await this.services.util?.sleep(200);
					resolve(true);
				}
				else {
					await this.services.bluetooth?.writeCharacteristic(this, bleTxRxService, bleTxRxCharacteristic, "ID?", true);
					console.log(" :: Sending DR? ::");
					await this.services.bluetooth?.writeCharacteristic(this, bleTxRxService, bleTxRxCharacteristic, "DR?\r\n", true)?.then( () => {
						this.isStreaming = true;
					});
					eccFrame = undefined;
				}
			}
			
			if(this.parentVMI.model.device?.deviceType == DeviceTypeIds.STE_RADPACK){
				await this.services.util?.sleep(1000)
			}
			
			if( this.parentVMI.model.device?.deviceType != DeviceTypeIds.STE_NRF && !this.isSubscribed){
				console.log("Checking if needing to send heartbeat")
				console.log("SENDING HEARTBEAT IN ", this.heartBeatIntervalMs)
				this.startHeartBeat();
				await this.services.util?.sleep(100)
				this.connectionState = CONNECTION_STATE.INITIALIZING;
				await this.services.bluetooth?.subscribeCharacteristic(this, bleTxRxService, bleTxRxCharacteristic, async (data) => {
					this.connectionState = CONNECTION_STATE.STREAMING;
					console.log("Subscribe responsse worked for the streaming channel: ", EncryptionService.toHexString(data));
					this.processStreamingData(data).catch( (err) => {
						reject(err)
					});
				})
				this.isSubscribed = true;
			}
			else {
				console.log(" :: Already Subscribed ::");
			}
		});
	}

	
	processStreamingData(data: Uint8Array): Promise<void> {
		const CPS_MULTIPLIER = 4;
		var points = [
			{x:0, y:0 },
			{x:1541, y:400 },
			{x:3499, y:1000 },
			{x:5351, y:1600 },
		];
		// var points = [
		// 	{x:0, y:0 },
		// 	{x:1253, y:371},
		// 	{x:1840, y:660},
		// 	{x:2487, y:993},
		// 	{x:4093, y:1983},
		// 	{x:5573, y:2977},
		// 	{x:6840, y:3971},
		// 	{x:8333, y:4962},
		// 	{x:9480, y:5956},
		// ];
		var isOverSaturated = (y:number) : boolean => {
			// only used for testing over saturated
			// if(x>500){
			// 	return true;
			// }
			if(y>=6000){
				return true;
			}
			return false;
		}
		var exposureRateConversion = (x:number ) : number => {
			// Find the two points that x falls between
			try{
				if(x == 0){
					return 0;
				}
				let point1 = points[0];
				let point2 = points[0];
				for (let i = 1; i < points.length; i++) {
					if (x == points[i].x) {
						return points[i].y;
					}
					if (x < points[i].x) {
						point1 = points[i - 1];
						point2 = points[i];
						break;
					}
				}

				// cap the max and min to interpolation array
				if(x<points[0].x){
					return points[0].y;
				}
				if(x>points[points.length-1].x){
					point1 = points[points.length-2];
					point2 = points[points.length-1];
				}
				if(!point1 || !point2){
					console.error("getInterpolatedGamma: FINDING A CONVERSION SET")
					return 0;
				}
				// Interpolate between the two points using linear interpolation
				let t = (x - point1.x) / (point2.x - point1.x);
				let y = (1 - t) * point1.y + t * point2.y;
				// compute rise over run
				let rise = point2.y - point1.y;
				let run = point2.x - point1.x;
				let slope = rise/run;
				if(y>6000){
					return 6000;
				}
				if( Number.isNaN(y) || y == undefined || y == null || y == Infinity){
					console.error("getInterpolatedGamma: NaN")
					return 0;
				}
				return y;
			}
			catch(e){
				console.error("getInterpolatedGamma: ", e)
				return 0;
			}
		}

		return new Promise( async (resolve, reject) => {
			if(!data || data.length<= 0){
				reject("No Data");
				return;
			}

			var charCodeTranslated:string = "";
			for (let index = 0; index < data.length; index++) {
				const byte = data[index];
				charCodeTranslated += String.fromCharCode(byte);
			}

			var data_array:string[] = charCodeTranslated.split("\r");
			data_array = data_array.map((r) => r.replace(/\r?\n|\r/g, ""))
			data_array = data_array.filter( (r) => r.length>0)

			var telemetry = Telemetry.create();
			var radationData = RadationData.create();

			for (let index = 0; index < data_array.length; index++) {
				const element:string = data_array[index];
				if(element.includes("GT,")){
					var split = element.split(",");
					if(split.length>=2){
						var gammaData:GammaData = GammaData.create();
						if(split[1] && split[1].length>0){
							gammaData.gammaAlarmLevel = Math.round(UtilService.stringToInteger(split[1]));
						}
						if(split[2] && split[2].length>0){
							gammaData.gammaTotalGrossCounts = Math.round(UtilService.stringToInteger(split[2])) * CPS_MULTIPLIER;
							var exposureRate = Math.round(exposureRateConversion(gammaData.gammaTotalGrossCounts));
							gammaData.gammaExposureRate = exposureRate*1e7;
							if(isOverSaturated(exposureRate)){
								gammaData.gammaState = GammaNeutronState.MAX_SATURATION;
							}
						}
						if(split[3] && split[3].length>0){
							gammaData.gammaAlarmCountThreshold = Math.round(UtilService.stringToInteger(split[3])) * CPS_MULTIPLIER;
						}
						radationData.gammaData = gammaData;
						if(split[4] == "NT"){
							var neutronData:NeutronData = NeutronData.create();
							if(split[5] && split[5].length>0){
								if(split[5] != "*" && split[5] != "<" && split[5] != ">"){
									neutronData.neuronAlarmLevel = Math.round(UtilService.stringToInteger(split[5]));
								}
							}
							if(split[6] && split[6].length>0){
								neutronData.neutronTotalGrossCounts1E1 = ((UtilService.stringToFloat(split[6]) * 1e1));
							}
							if(split[7] && split[7].length>0){
								neutronData.neutronAlarmCountThreshold = ((UtilService.stringToFloat(split[7]) * 1e1));
							}
							radationData.neutronData = neutronData;
						}
					}
				}
				else if(element.includes("M,")) {
					var split = element.split(",");
					if(split.length>=2){
						// newSensor.setManufacturer(split[1]);
						console.log("split", split);
					}
				}
				else if(element.includes("SN,")) {
					var split = element.split(",");
					if(split.length>=2){
						var number:number = parseInt(split[1]);
						if(number>0){
							// newSensor.setSerial(number);
							console.log("split", split);
						}
					}
				}
				else if(element.includes("WL,")) {
					var split = element.split(",");
					if(split.length>=2){
						var number:number = parseInt(split[1]);
						if(number>0){
							// newSensor.setThreshold(number);
							console.log("split", split);
						}
					}
				}
				else if(element.includes("PN,")) {
					var split = element.split(",");
					if(split.length>=2){
						if(split[1].length>0){
							// newSensor.setProductName(split[1])
							console.log("split", split);
						}
					}
				}
				else if(element.includes("V,")) {
					var split = element.split(",");
					if(split.length>=2){
						if(split[1].length>0){
							// newSensor.setVersion(split[1])
							console.log("split", split);
						}
					}
				}
				else if(element.includes("T,")) {
					var split = element.split(",");
					if(split.length>=2){
						if(split[1].length>0){
							// newSensor.setManufacturerType(split[1])
							console.log("split", split);
						}
					}
				}
				else {
					// console.warn(" :: PARSE NOT FOUND :", element);
				}
			}

			telemetry.vendorData = VendorData.create();
			telemetry.vendorData.data = {
				oneofKind: "radationData",
				radationData: radationData,
			}
			if(this.parentVMI.model.device?.uuid){
				telemetry.uuid = this.parentVMI.model.device.uuid
			}
			else{
				console.error("ISSUE: No UUID to apply to telemetry");
			}

			await this.services.geolocate?.getCurrentPosition().then((result) => {
				telemetry.longitude1E7 = result.longitude1E7;
				telemetry.latitude1E7 = result.latitude1E7;
				telemetry.elevationCm = result.elevationCm;
				telemetry.gpsScanRecord = GPSScanRecord.clone(result.gpsScanRecord);
			});

			if(this.services.settings?.SETTINGS.APP_ID){
				// console.log("Saving to app id", this.services.settings?.SETTINGS.APP_ID);
				// console.log("Saving Telemetry : ", telemetry);
				// console.log("Model is : ", this.parentVMI.model.device?.uuid);
				this.services.data?.saveTelemetry(this.services.settings?.SETTINGS.APP_ID, this.parentVMI, telemetry, undefined, undefined, undefined, true).then( (saved) => {
					this.parentVMI.update();
				}).catch( (err) => {
					console.log("Error saving telemetry to device", err);
					this.parentVMI.update();
				});
			}
		});
	}

	async startHeartBeat() {
		console.log("STARTING HEARTBEAT FOR STE DEVICE : ", this.heartBeatIntervalMs);
		var bleTxRxService:string = "49535343-fe7d-4ae5-8fa9-9fafd205e455";
		var bleTxRxCharacteristic:string = "49535343-1e4d-4bd9-ba61-23c647249616";
		this.hearBeatTimeout = setInterval( async () => {
			if(this.getIsConnected()){
				console.log("Sending Heartbeat");
				await this.services.bluetooth?.writeCharacteristic(this, bleTxRxService, bleTxRxCharacteristic, "?", true);
			}
			else{
				this.stopHeartBeat();
			}
		}, this.heartBeatIntervalMs);
	}
}

export class SteDeviceViewModelImplemented extends DeviceViewModelImplemented {

	public bluetoothConnection:SteBluetoothConnection | null;

	public eccFrame : SimpleEccFrame | undefined;
	public showTelemetryOffset:boolean = false;

	private bleCommandService = 0xff00;
	private bleCommandCharacteristic = 0x0001;

	public hasStartedCatchup:boolean=false;
	public steDeviceData:any = {};
	public steDeviceLoading:boolean = true;
	public currentSteCommand:SteCommands = SteCommands.UNKNOWN;
	public currentCommandToastController:any = null;

	public radiationCapable:boolean = true;

	public loggingEnabled:boolean = false;
	constructor
	(
		services:AllServcies,
		bluetoothConnection?:BluetoothConnection,
		model?:DeviceModel,
	)
	{
		super(services, bluetoothConnection, model);

		// get saved info locally
		this.services.data?.getByKey(this.model.device?.uuid.toString()+"_steLoggingEnabled").then( (data) => {
			if(data){
				this.loggingEnabled = data;
			}
		}).catch( (err) => {
			console.log("Error getting steDeviceData", err);
		});
	}

	async initalizeBluetoothConnection() : Promise<boolean> {
		if(!this.eccFrame){
			this.eccFrame = this.services.simpleEcc?.getNewFrame();
		}
		console.log("SteDeviceViewModelImplemented: initalizeBluetoothConnection");
		if(!this.bluetoothConnection){
			if(this.services.bluetooth && this.model.device?.uuid){
				this.bluetoothConnection = this.services.bluetooth.getDeviceConnection(this.model.device?.uuid);
				if(this.bluetoothConnection){
					this.hasBluetoothConnection = true;
					this.bluetoothConnection.parentVMI = this;
				}
				else {
					// pull from local db, to see if we have a common interface stored
					var commonInterface = await this.services.data?.getByKey(this.model.device.uuid.toString()+"_btcmi") as BluetoothCommonInterface;
					if(commonInterface){
						console.log("Got bluetooth connection", commonInterface)
						this.bluetoothConnection = new SteBluetoothConnection(this.services, commonInterface);
						this.bluetoothConnection.bluetoothCommonInterface = commonInterface;
						this.bluetoothConnection.parentVMI = this;
						this.hasBluetoothConnection = true;
					}
				}
			}
			else {
				return Promise.reject("No bluetooth service or uuid");
			}
		}
		if(this.bluetoothConnection){
			await this.bluetoothConnection.initalizeDevice();
			return new Promise( async (resolve,reject)=> {
				resolve(true);
				this.steDeviceLoading = false;
				await this.services.util?.sleep(10000);
				try{await this.setSessionTimestamp();}catch(e){console.error(e);}
				await this.services.util?.sleep(500);
				try{await this.setSessionLocation();}catch(e){console.error(e);}
				try{
					await this.services.util?.sleep(500);
					var getSystemInfo = await this.getSystemInfo();
					console.log("Called getSystemInfo: ", getSystemInfo);
				}
				catch(e){
					console.error(e);
				}
				try{
					await this.services.util?.sleep(500);
					var getModelString = await this.getModelString();
					console.log("Calling Model String is : ", getModelString);
				}catch(e){
					console.log(" ERROR :: bluetoothConnection.initalizeDevice :: getModelString")
					console.error(e);
				}
				try{
					await this.services.util?.sleep(500);
					var getLoggingState = await this.getLoggingState();
					console.log("Logging State is : ", getLoggingState);
				}catch(e){
					console.log(" ERROR :: bluetoothConnection.initalizeDevice :: getLoggingState")
					console.error(e);
				}

				try{
					await this.services.util?.sleep(500);
					var serialNumber = await this.getSerialNumber();
					console.log("Serial Number is : ", serialNumber);
				}catch(e){
					console.log(" ERROR :: bluetoothConnection.initalizeDevice :: serialNumber")
					console.error(e);
				}
				try{
					await this.services.util?.sleep(500);
					// if(this.hasStartedCatchup){
					// 	console.log("Already started catchup");
					// 	await this.callUpdateSessionCatchup();
					// }
					// else {
					// 	console.log("Starting catchup");
					// 	await this.startSessionCatchup();
					// 	this.hasStartedCatchup = true;
					// }
				}
				catch(e){
					reject(e);
				}
				this.steDeviceLoading = false;
			})
		}
		else {
			return Promise.reject("No bluetooth connection");
		}
	}

	public static getStaticTitle():string{ // so we can access this w/o an instance i guess
		return "STE"
	}

	public getPagePath() : string {
		console.log("this.services.settings?.SETTINGS.APP_ID: ", this.services.settings?.SETTINGS.APP_ID)
		if(this.services.settings?.SETTINGS.APP_ID != 16){
			return super.getPagePath();
		}
		if(this.model.device){
			return "vendors/ste/ste-device/" + this.model.device.uuid;
		}
		return "vendors/ste/ste-device/" + 0;
	}

	public getTitle():string{ // so we can access this w/o an instance i guess
		return SteDeviceViewModelImplemented.getStaticTitle();
	}

	public getDisplayName() : string {
		return this.getTitle()
	}

	public getDisplayUuid() : string {
		if(this.model.device?.serial){
			return "SN: "+this.model.device.serial
		}
		return "SN: n/a";
	}

	public thumbnailImagePath:string = "assets/app/ste/radPager.png";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public getSubTitleDescription():string{
		if(this.model.device?.serial){
			return "SN: "+this.model.device.serial
		}
		else {
			return "SN: n/a";
		}
	}

	public processFullRawLogData(id:number, fullData:Uint8Array, command:boolean=false, update:boolean=false){
		var buffer = new Uint8Array(fullData.length);
		buffer.set(fullData);
		var sessionLogs:SessionLog[] = [];

		while(buffer.length){
			try{
				var length = varintDecode(buffer);
				if(length>32){
					console.log("Length is too long, skipping")
					throw "Length is too long";
				}
				var commandData = buffer.slice(varintDecode.bytes, varintDecode.bytes+length);
				// if command data is all zeros, skip
				if(commandData.every( (v) => v == 0)){
					buffer = buffer.slice(varintDecode.bytes+length);
					continue;
				}
				var sessionLog = SessionLog.fromBinary(commandData);
				buffer = buffer.slice(varintDecode.bytes+length);
				if(sessionLog.averageCounts){
					sessionLog.averageCounts = Math.round(sessionLog.averageCounts * 4);
				}
				sessionLogs.push(sessionLog);
			}
			catch(e){
				buffer = buffer.slice(1);
				console.error("Error parsing session log", e);
			}
		}
		if(!this.steDeviceData.sessionLogs){
			this.steDeviceData.sessionLogs = {};
		}
		if(!this.steDeviceData.sessionLogs[id]){
			this.steDeviceData.sessionLogs[id] = {};
		}
		
		if(!update){
			this.steDeviceData.sessionLogs[id].history= sessionLogs;
		}
		else {
			if(this.steDeviceData.sessionLogs[id] && this.steDeviceData.sessionLogs[id].history){
				this.steDeviceData.sessionLogs[id].history = this.steDeviceData.sessionLogs[id].history.concat(sessionLogs);
			}
		}
		this.steDeviceData.sessionLogs[id].finalTimestamp= Date.now();
		console.log("is Command :: Triggerthe share :: ", command,);
		if(command && sessionLogs.length>0){
			// triger share command
			var csv:string = "Over Range Detected,Highest Alarm Level,Highest Alarm Time (ms),Average Alarm Level,Total Alarm Time (ms), Average Counts Per Second,Average Exposure Rate (uR/h),Total session dose (uR)\n";
			var totalDosage:number = 0;
			var timeStart:number = 0;
			if(this.steDeviceData.sessionLogs[id].session){
				if(this.steDeviceData.sessionLogs[id].session.timeStart){
					timeStart = Number(this.steDeviceData.sessionLogs[id].session.timeStart);
					csv = "time,"+csv;
				}
			}
			for (let index = 0; index < sessionLogs.length; index++) {
				var sessionLog:SessionLog = sessionLogs[index];
				if(timeStart != 0){
					var newDate = new Date(timeStart+(index*(1.0074*60*1000)));
					csv+=this.formatExportTimeString(newDate)+",";
				}
				if(sessionLog.saturated){
					csv+="Yes";
				}
				else {
					csv+="No";
				}
				csv+=","+sessionLog.highAlarmLevel||0;
				csv+=","+sessionLog.highAlarmLevelTime||0;
				csv+=","+sessionLog.averageAlarmLevel||0;
				csv+=","+sessionLog.totalAlarmTime||0;
				csv+=","+sessionLog.averageCounts||0;
				if(sessionLog.averageCounts){
					csv+=","+this.exposureRateConversion(sessionLog.averageCounts).toFixed(3);
					totalDosage += (this.exposureRateConversion(sessionLog.averageCounts)/(1.0074*60));
					csv+=","+totalDosage.toFixed(3);
				}
				else {
					csv+=",0";
					csv+=","+totalDosage;
				}
				csv += "\n";
			}
			if(sessionLogs.length>0){
				if(this.services.uiHelper){
					this.services.uiHelper.shareAsFile("session_"+id+"_"+Date.now()+".csv", csv);
				}
				else {
					console.error("No UI Helper :: Cant share");
				}
			}
			else {
				this.services.uiHelper?.showError("No data to share");
			}
			console.log("CSV is : ", csv);
		}
	}

	public processCommandResponse(commandResponse:SteCommand, command:boolean=false) : void {
		console.log("GOT STE COMMAND RESPONSE : ", commandResponse);
		console.log("GOT STE COMMAND RESPONSE : ", commandResponse);
		console.log("GOT STE COMMAND RESPONSE : ", commandResponse);
		console.log("GOT STE COMMAND RESPONSE NUMBER : ", commandResponse);
		console.log("GOT STE COMMAND RESPONSE NUMBER : ", commandResponse);
		console.log("GOT STE COMMAND RESPONSE NUMBER : ", commandResponse);
		console.log("GOT STE COMMAND RESPONSE STRING : ", SteCommands[commandResponse.command]);
		console.log("GOT STE COMMAND RESPONSE STRING : ", SteCommands[commandResponse.command]);
		console.log("GOT STE COMMAND RESPONSE STRING : ", SteCommands[commandResponse.command]);
		
		if(commandResponse.command == SteCommands.GET_SERIAL_NUMBER){
			// this.services.uiHelper?.showSuccess("Got Serial Number",{duration: 1000});
			if(commandResponse.commandData?.oneofKind == "serialNumber"){
				if(commandResponse.commandData.serialNumber){
					console.log("Got serial number : ", commandResponse.commandData.serialNumber);
					console.log("Got serial number hex: ",  commandResponse.commandData.serialNumber.toString(16));
					if(this.model.device){
						this.model.device.serial = BigInt(commandResponse.commandData.serialNumber);
						this.services.uiHelper?.detectChanges()
					}
				}
			}
		}
		else if(commandResponse.command == SteCommands.GET_LOGGING_STATE){
			// this.services.uiHelper?.showSuccess("Got Logging State",{duration: 1000});
			if(commandResponse.commandData?.oneofKind == "loggingToggle"){
				if(commandResponse.commandData.loggingToggle == 0 || commandResponse.commandData.loggingToggle == 1){
					console.log("getLoggingState Response : ", commandResponse.commandData.loggingToggle);
					console.log("getLoggingState Response : ", commandResponse.commandData.loggingToggle);
					console.log("getLoggingState Response : ", commandResponse.commandData.loggingToggle);
					console.log("Got Logging hex: ",  commandResponse.commandData.loggingToggle.toString(16));
					if(this.model.device){
						if(commandResponse.commandData.loggingToggle == 1){
							this.loggingEnabled = true;
						}
						else {
							this.loggingEnabled = false;
						}
						this.services.data?.saveByKey(this.model.device.uuid.toString()+"_steLoggingEnabled", this.loggingEnabled);
						this.services.uiHelper?.detectChanges()
					}
				}
			}
		}
		else if(commandResponse.command == SteCommands.GET_MODEL){
			// this.services.uiHelper?.showSuccess("Got GET_MODEL",{duration: 1000});
			if(commandResponse.commandData?.oneofKind == "deviceModel"){
				if(commandResponse.commandData.deviceModel){
					console.log("Got deviceModel GET_MODEL : ", commandResponse.commandData.deviceModel);
					console.log("Got deviceModel GET_MODEL : ", commandResponse.commandData.deviceModel);
					console.log("Got deviceModel GET_MODEL : ", commandResponse.commandData.deviceModel);
					console.log("Got deviceModel GET_MODEL : ", commandResponse.commandData.deviceModel);
					console.log("Got deviceModel GET_MODEL : ", commandResponse.commandData.deviceModel);
					console.log("Got deviceModel GET_MODEL : ", commandResponse.commandData.deviceModel);
					if(this.model.device){
						var bytes = commandResponse.commandData.deviceModel.model;
						var modelAsString = "";
						for (let index = 0; index < bytes.length; index++) {
							const byte = bytes[index];
							if(byte != 0){
								modelAsString += String.fromCharCode(byte);
							}
						}
						console.log("Got deviceModel string: ",  modelAsString);
						this.model.device.productName = modelAsString;
						this.services.uiHelper?.detectChanges()
					}
				}
			}
		}
		else if(commandResponse.command == SteCommands.DELETE_ALL_SESSIONS){
			// this.services.uiHelper?.showSuccess("Got Delete All Sessions",{duration: 1000});
		}
		else if(commandResponse.command == SteCommands.GET_SESSION_IDS){
			// this.services.uiHelper?.showSuccess("Got Session Ids", {duration: 1000});
			if(commandResponse.commandData?.oneofKind == "sessionIdList"){
				if(commandResponse.commandData.sessionIdList){
					if(!this.steDeviceData){
						this.steDeviceData = {};
					}
					if(!this.steDeviceData.sessionIds){
						this.steDeviceData.sessionIds = [];
					}
					commandResponse.commandData.sessionIdList.sessionEntries.forEach( (entry) => {
						// find if the session id is already in the list
						// by checking if .sessionId is in array
						var found = false;
						for (let index = 0; index < this.steDeviceData.sessionIds.length; index++) {
							const session = this.steDeviceData.sessionIds[index];
							if(session.sessionId == entry.sessionId){
								found = true;
								break;
							}
						}
						if(!found){
							this.steDeviceData.sessionIds.push(entry);
						}
					});
					// sort this.steDeviceData.sessionIds
					this.steDeviceData.sessionIds = this.steDeviceData.sessionIds.sort( (a,b) => {
						return b.sessionId-a.sessionId;
					});
					this.services.uiHelper?.detectChanges()
					console.log(JSON.stringify(this.steDeviceData, (key, value) => typeof value === 'bigint' ? value.toString() : value ));
				}
			}
		}
		else if(commandResponse.command == SteCommands.GET_SESSION_LOGS){
			if(commandResponse.commandData?.oneofKind == "logResponse"){
				if(commandResponse.commandData.logResponse){
					// add to the session id history
					if(!this.steDeviceData){
						this.steDeviceData = {};
					}
					if(!this.steDeviceData.sessionHistoryBuffer){
						this.steDeviceData.sessionHistoryBuffer= {};
					}
					var id = commandResponse.commandData.logResponse.id;
					if(!this.steDeviceData.sessionHistoryBuffer[id]){
						this.steDeviceData.sessionHistoryBuffer[id] = [];
					}
					if(!this.steDeviceData.sessionLogs){
						this.steDeviceData.sessionLogs = {};
					}
					if(commandResponse.commandData.logResponse.session){
						if(!this.steDeviceData.sessionLogs[id]){
							this.steDeviceData.sessionLogs[id] = {};
						}
						this.steDeviceData.sessionLogs[id].session= commandResponse.commandData.logResponse.session;
					}
					
					this.steDeviceData.sessionHistoryBuffer[id].push(commandResponse.commandData.logResponse);
					var totalLengthReceived = 0;
					var totalBytes = 0;
					var haveReceivedAll:boolean = false;
					for (let index = 0; index < this.steDeviceData.sessionHistoryBuffer[id].length; index++) {
						const sessionLogResponse:SessionLogResponse = this.steDeviceData.sessionHistoryBuffer[id][index];
						totalBytes = sessionLogResponse.totalBytes;
						totalLengthReceived += sessionLogResponse.segmentLength;
					}
					console.log("Log response is : ", commandResponse.commandData.logResponse)
					if(totalLengthReceived >= commandResponse.commandData.logResponse.totalBytes){
						haveReceivedAll = true;
						// console.log("Have received ALL of the data :: Time to parse the protos.")
						// console.log("Have received ALL of the data :: Time to parse the protos : ", totalLengthReceived, " of ", commandResponse.commandData.logResponse.totalBytes);
						// console.log("TO JSON HERE : :::", SteCommand.toJsonString(commandResponse));
						// sort by the segment number
						this.steDeviceData.sessionHistoryBuffer[id] = this.steDeviceData.sessionHistoryBuffer[id].sort( (a,b) => {
							return a.currentPosition - b.currentPosition;
						});
						var fullData = new Uint8Array(commandResponse.commandData.logResponse.totalBytes);
						var offset = 0;
						for (let index = 0; index < this.steDeviceData.sessionHistoryBuffer[id].length; index++) {
							const sessionLogResponse:SessionLogResponse = this.steDeviceData.sessionHistoryBuffer[id][index];
							fullData.set(sessionLogResponse.rawEncodedSessionLogs, offset);
							offset += sessionLogResponse.rawEncodedSessionLogs.length;
						}
						// console.log("Full Data is : ", fullData);
						this.processFullRawLogData(id, fullData, command, false);
						this.steDeviceData.sessionHistoryBuffer[id] = [];
						// this.services.uiHelper?.showSuccess("Got Session Logs");
					}
					else {
						if(commandResponse.commandData.logResponse.currentPosition+totalLengthReceived == commandResponse.commandData.logResponse.totalBytes){
							// console.log("processing update catchup message");
							this.steDeviceData.sessionHistoryBuffer[id] = this.steDeviceData.sessionHistoryBuffer[id].sort( (a,b) => {
								return a.currentPosition - b.currentPosition;
							});
							var fullData = new Uint8Array(totalLengthReceived);
							var offset = 0;
							for (let index = 0; index < this.steDeviceData.sessionHistoryBuffer[id].length; index++) {
								const sessionLogResponse:SessionLogResponse = this.steDeviceData.sessionHistoryBuffer[id][index];
								fullData.set(sessionLogResponse.rawEncodedSessionLogs, offset);
								offset += sessionLogResponse.rawEncodedSessionLogs.length;
							}
							// console.log("Full Data is : ", fullData);
							this.processFullRawLogData(id, fullData, command, true);
							this.steDeviceData.sessionHistoryBuffer[id] = [];
						}
						else {
							
							console.log("Have NOT received ALL of the data :: ", totalLengthReceived, " of ", commandResponse.commandData.logResponse.totalBytes);
						}
					}

					this.services.uiHelper?.detectChanges()
				}
			}
		}
		else if(commandResponse.command == SteCommands.GET_SYSTEM_INFO){
			if(commandResponse.commandData?.oneofKind == "systemInfo"){
				if(commandResponse.commandData.systemInfo){
					console.log("Got system info : ", commandResponse.commandData.systemInfo);
					if(!this.steDeviceData){
						this.steDeviceData = {};
					}
					if(!this.steDeviceData.systemInfo){
						this.steDeviceData.systemInfo = {};
					}
					this.steDeviceData.systemInfo = commandResponse.commandData.systemInfo;

				}
			}
		}
	}

	private packAndEncryptCommand(steCommand:SteCommand) : Promise<Uint8Array> {
		return new Promise( async (resolve, reject) => {
			try {
				if(this.eccFrame?.hasClientSecret()){
					console.log("Serializing STE command: ", steCommand);
					var commadBytes = SteCommand.toBinary(steCommand);
					var varIntLength = varintEncode(commadBytes.length);
					var commandBytes = new Uint8Array(commadBytes.length + varIntLength.length);
					commandBytes.set(varIntLength, 0);
					commandBytes.set(commadBytes, varIntLength.length);
					this.eccFrame.message_type = UtpMessageType.MESSAGE_ECC256_GCM_SEND;
					var serialized = await this.eccFrame.serialize(this.eccFrame.client_secret, commandBytes);
					resolve(serialized);
				}
				else {
					reject("No client secret");
				}
			}
			catch(e){
				console.error("Error encrypting command", e);
				reject(e);
			}
		});
	}

	private setLogging(enabled:boolean=false) : Promise<void> {
		return new Promise( async ( resolve, reject ) => {
			try {
				this.services.data?.saveByKey(this.model.device?.uuid.toString()+"_steLoggingEnabled", true).then( (data) => {
					console.log("Saved steLoggingEnabled");
				}).catch( (err) => {
					console.log("Error saving steLoggingEnabled", err);
				});
				if(this.bluetoothConnection){
					var steCommand:SteCommand = SteCommand.create();
					steCommand.command = SteCommands.SET_LOGGING_STATE;
					steCommand.commandData = {
						oneofKind: "loggingToggle",
						loggingToggle: Number(enabled?1:0),
					};
					
					console.log("*******************************************")
					console.log("STE COMMAND setLogging Sending : ", steCommand)
					console.log("STE COMMAND setLogging Sending : ", steCommand)
					console.log("STE COMMAND setLogging Sending : ", steCommand)
					console.log("STE COMMAND setLogging Sending : ", steCommand)
					console.log("STE COMMAND setLogging Sending : ", steCommand)
					console.log("*******************************************")


					var serialized = await this.packAndEncryptCommand(steCommand);
					await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
				}
			}
			catch(e){
				console.error("Error setLogging", e);
				reject(e);
			}
		});
	}

	public enableLogging() : void {
		this.loggingEnabled = true;
		this.setLogging(this.loggingEnabled);
	}

	public disableLogging() : void {
		this.loggingEnabled = false;
		this.setLogging(this.loggingEnabled);
	}

	public getLoggingState() : Promise<bigint> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.GET_LOGGING_STATE;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){ await this.currentCommandToastController?.dismiss()}
			}
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.GET_LOGGING_STATE;
						console.log("*******************************************")
						console.log("STE COMMAND getLoggingState Sending : ", steCommand)
						console.log("STE COMMAND getLoggingState Sending : ", steCommand)
						console.log("STE COMMAND getLoggingState Sending : ", steCommand)
						console.log("STE COMMAND getLoggingState Sending : ", steCommand)
						console.log("STE COMMAND getLoggingState Sending : ", steCommand)
						console.log("*******************************************")
						var serialized = await this.packAndEncryptCommand(steCommand);
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
					}
					resolve(0n);
				}
				catch(e){
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: getLoggingState");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
		});
	}

	public setModelString(modelString:string) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.SET_MODEL;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){await this.currentCommandToastController?.dismiss()}
			}
			console.log("setModelString :: ", modelString);
			console.log("setModelString :: ", modelString);
			this.currentCommandToastController = this.services.uiHelper?.showLoading("Setting modal :: ");
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						var deviceModel = STE_DeviceModel.create();
						const uint8Array = new Uint8Array(16);
						for (let i = 0; i < modelString.length; i++) {
							uint8Array[i] = modelString.charCodeAt(i);
							console.log(`Char is: ${modelString[i]} uint8Array[i] :  ${uint8Array[i]}`)
						}
						deviceModel.model = uint8Array;
						steCommand.command = SteCommands.SET_MODEL;
						steCommand.commandData = {
							oneofKind: "deviceModel",
							deviceModel: deviceModel,
						};
						console.log("*******************************************")
						console.log("setModelString Sending : ", steCommand);
						console.log("setModelString Sending : ", steCommand);
						console.log("setModelString Sending : ", steCommand);
						console.log("*******************************************")
						var serialized = await this.packAndEncryptCommand(steCommand);
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
						this.services.uiHelper?.showSuccess("setModelString Success",{duration: 1000});
						resolve(true);
					}
					else {
						reject("setModelString :: No client secret");
					}
				}
				catch(e){
					// if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
					// this.services.uiHelper?.showError("Error setSerialNumber :: "+JSON.stringify(e));
					// this.currentSteCommand = SteCommands.UNKNOWN;
					// this.currentCommandToastController = null;
					// resolveReject = true;
					// console.error("Error setSerialNumber", e);
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: setSerialNumber");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
			// setTimeout( () => {
			// 	if(!resolveReject && this.currentCommandToastController != SteCommands.SET_SERIAL_NUMBER){
			// 		if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
			// 		this.services.uiHelper?.showWarning("Timed out Get setSerialNumber ");
			// 		this.currentSteCommand = SteCommands.UNKNOWN;
			// 		this.currentCommandToastController = null;
			// 	}
			// }, 10 * 1000);
		});
	}

	public getModelString() : Promise<bigint> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.GET_MODEL;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){ await this.currentCommandToastController?.dismiss()}
			}
			// this.currentCommandToastController = this.services.uiHelper?.showLoading("Get getSerialNumber :: ");
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.GET_MODEL;
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("*******************************************")
						console.log("getModelString Sending : ", steCommand);
						console.log("getModelString Sending : ", steCommand);
						console.log("getModelString Sending : ", steCommand);
						console.log("*******************************************")
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
					}
					resolve(0n);
				}
				catch(e){
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: getModelString");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
		});
	}



	public setSerialNumber(serialNumber:bigint) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.SET_SERIAL_NUMBER;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){await this.currentCommandToastController?.dismiss()}
			}
			this.currentCommandToastController = this.services.uiHelper?.showLoading("Set Serial :: ");
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.SET_SERIAL_NUMBER;
						steCommand.commandData = {
							oneofKind: "serialNumber",
							serialNumber: Number(serialNumber),
						};
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("*******************************************")
						console.log("setSerialNumber Sending : ", steCommand);
						console.log("setSerialNumber Sending : ", steCommand);
						console.log("setSerialNumber Sending : ", steCommand);
						console.log("*******************************************")
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
						this.services.uiHelper?.showSuccess("Set Serial Success",{duration: 1000});
						resolve(true);
					}
					else {
						reject("No client secret");
					}
				}
				catch(e){
					// if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
					// this.services.uiHelper?.showError("Error setSerialNumber :: "+JSON.stringify(e));
					// this.currentSteCommand = SteCommands.UNKNOWN;
					// this.currentCommandToastController = null;
					// resolveReject = true;
					// console.error("Error setSerialNumber", e);
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: setSerialNumber");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
			// setTimeout( () => {
			// 	if(!resolveReject && this.currentCommandToastController != SteCommands.SET_SERIAL_NUMBER){
			// 		if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
			// 		this.services.uiHelper?.showWarning("Timed out Get setSerialNumber ");
			// 		this.currentSteCommand = SteCommands.UNKNOWN;
			// 		this.currentCommandToastController = null;
			// 	}
			// }, 10 * 1000);
		});
	}

	public getSerialNumber() : Promise<bigint> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.GET_SERIAL_NUMBER;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){ await this.currentCommandToastController?.dismiss()}
			}
			// this.currentCommandToastController = this.services.uiHelper?.showLoading("Get getSerialNumber :: ");
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.GET_SERIAL_NUMBER;
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("*******************************************")
						console.log("getSerialNumber Sending : ", steCommand);
						console.log("getSerialNumber Sending : ", steCommand);
						console.log("getSerialNumber Sending : ", steCommand);
						console.log("*******************************************")
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
					}
					resolve(0n);
				}
				catch(e){
					// if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
					// this.services.uiHelper?.showError("Error getSerialNumber :: "+JSON.stringify(e));
					// this.currentSteCommand = SteCommands.UNKNOWN;
					// this.currentCommandToastController = null;
					// resolveReject = true;
					// console.error("Error getSerialNumber", e);
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: getSerialNumber");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
			// setTimeout( () => {
			// 	if(!resolveReject && this.currentCommandToastController != SteCommands.GET_SERIAL_NUMBER){
			// 		if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
			// 		this.services.uiHelper?.showWarning("Timed out Get getSerialNumber ");
			// 		this.currentSteCommand = SteCommands.UNKNOWN;
			// 		this.currentCommandToastController = null;
			// 	}
			// }, 10 * 1000);
		});
	}

	public getSystemInfo() : Promise<bigint> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.GET_SYSTEM_INFO;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){ await this.currentCommandToastController?.dismiss()}
			}
			this.currentCommandToastController = this.services.uiHelper?.showLoading("Get getSystemInfo :: ");
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.GET_SYSTEM_INFO;
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("*******************************************")
						console.log("getSystemInfo Sending : ", steCommand);
						console.log("getSystemInfo Sending : ", steCommand);
						console.log("getSystemInfo Sending : ", steCommand);
						console.log("*******************************************")
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
					}
					resolve(0n);
				}
				catch(e){
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: getSystemInfo");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
		});
	}

	public getSessionIds() : Promise<bigint> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.GET_SESSION_IDS;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){await this.currentCommandToastController?.dismiss()}
			}
			this.currentCommandToastController = this.services.uiHelper?.showLoading("Get session ids :: ");
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.GET_SESSION_IDS;
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("CALLING GET SESSION IDS!!!")
						this.steDeviceData.sessionIds = [];
						console.log("*******************************************")
						console.log("getSessionIds Sending : ", steCommand);
						console.log("getSessionIds Sending : ", steCommand);
						console.log("getSessionIds Sending : ", steCommand);
						console.log("*******************************************")
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
						this.services.uiHelper?.detectChanges()
					}
					resolve(0n);
				}
				catch(e){
					// if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
					// this.services.uiHelper?.showError("Error getSessionIds :: "+JSON.stringify(e));
					// this.currentSteCommand = SteCommands.UNKNOWN;
					// this.currentCommandToastController = null;
					// resolveReject = true;
					// console.error("Error getSessionIds", e);
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: getSessionIds");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
			// setTimeout( () => {
			// 	if(!resolveReject && this.currentCommandToastController != SteCommands.GET_SESSION_IDS){
			// 		if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
			// 		this.services.uiHelper?.showWarning("Timed out Get getSessionIds ");
			// 		this.currentSteCommand = SteCommands.UNKNOWN;
			// 		this.currentCommandToastController = null;
			// 	}
			// }, 10 * 1000);
		});
	}

	public getSessionLogs(id:number) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.GET_SESSION_LOGS;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){await this.currentCommandToastController?.dismiss()}
			}
			this.currentCommandToastController = this.services.uiHelper?.showLoading("Get session logs :: "+ id);
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.GET_SESSION_LOGS;
						steCommand.commandData = {
							oneofKind: "sessionId",
							sessionId: id,
						};
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("*******************************************")
						console.log("getSessionLogs Sending : ", steCommand);
						console.log("getSessionLogs Sending : ", steCommand);
						console.log("getSessionLogs Sending : ", steCommand);
						console.log("*******************************************")

						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
						this.services.uiHelper?.detectChanges()
					}
					resolve(true);
				}
				catch(e){
					// if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
					// this.services.uiHelper?.showError("Error get session :: "+id+" :: "+JSON.stringify(e));
					// this.currentSteCommand = SteCommands.UNKNOWN;
					// this.currentCommandToastController = null;
					// resolveReject = true;
					// console.error("Error get session :: "+id, e);
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: Get session logs sessions");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
			// setTimeout( () => {
			// 	if(!resolveReject && this.currentCommandToastController != SteCommands.GET_SESSION_LOGS){
			// 		if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
			// 		this.services.uiHelper?.showWarning("Timed out Get session logs :: "+id);
			// 		this.currentSteCommand = SteCommands.UNKNOWN;
			// 		this.currentCommandToastController = null;
			// 	}
			// }, 10 * 1000);
		});
	}

	public deleteSession(id:number) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.DELETE_SESSION;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){await this.currentCommandToastController?.dismiss()}
			}
			this.currentCommandToastController = this.services.uiHelper?.showLoading("Deleting Session :: "+ id);
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.DELETE_SESSION;
						steCommand.commandData = {
							oneofKind: "sessionId",
							sessionId: id,
						};
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("*******************************************")
						console.log("deleteSession Sending : ", steCommand);
						console.log("deleteSession Sending : ", steCommand);
						console.log("deleteSession Sending : ", steCommand);
						console.log("*******************************************")
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
						var index = -1;
						for (let index = 0; index < this.steDeviceData.sessionIds.length; index++) {
							const session = this.steDeviceData.sessionIds[index];
							if(session.sessionId == id){
								index = index;
								break;
							}
						}
						if(index>=0){
							this.steDeviceData.sessionIds.splice(index,1);
						}
						this.services.uiHelper?.detectChanges()
					}
					resolve(true);
				}
				catch(e){
					// if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
					// this.services.uiHelper?.showError("Error deleting session :: "+id+" :: "+JSON.stringify(e));
					// this.currentSteCommand = SteCommands.UNKNOWN;
					// this.currentCommandToastController = null;
					// resolveReject = true;
					// console.error("Error deleteSession :: "+id, e);
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: Failed deleting session :: "+id);
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
			// setTimeout( () => {
			// 	if(!resolveReject && this.currentCommandToastController != SteCommands.DELETE_SESSION){
			// 		if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
			// 		this.services.uiHelper?.showWarning("Timed out deleting session :: "+id);
			// 		this.currentSteCommand = SteCommands.UNKNOWN;
			// 		this.currentCommandToastController = null;
			// 	}
			// }, 10 * 1000);
		});
	}

	public deleteAllSessions() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.currentSteCommand = SteCommands.DELETE_ALL_SESSIONS;
			if(this.currentCommandToastController){
				if(this.currentCommandToastController?.dismiss){ await this.currentCommandToastController?.dismiss()}
			}
			this.currentCommandToastController = this.services.uiHelper?.showLoading("Deleting all sessions (Takes a long time)");
			var resolveReject:boolean = false;
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.DELETE_ALL_SESSIONS;
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("*******************************************")
						console.log("deleteAllSessions Sending : ", steCommand);
						console.log("deleteAllSessions Sending : ", steCommand);
						console.log("deleteAllSessions Sending : ", steCommand);
						console.log("*******************************************")

						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
						this.steDeviceData.sessionIds = [];
						this.services.uiHelper?.detectChanges()
					}
					resolveReject = true;
					resolve(true);
				}
				catch(e){
					// if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
					// this.services.uiHelper?.showError("Error deleting all sessions :: "+JSON.stringify(e));
					// this.currentSteCommand = SteCommands.UNKNOWN;
					// this.currentCommandToastController = null;
					// resolveReject = true;
					// console.error("Error deleteAllSessions", e);
					reject(e);
				}
			}
			else {
				resolveReject = true;
				console.error("No bluetooth connection");
				if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
				this.services.uiHelper?.showError("No bluetooth connection :: Failed deleting all sessions");
				this.currentSteCommand = SteCommands.UNKNOWN;
				this.currentCommandToastController = null;
				resolveReject = true;
				reject("No bluetooth connection");
			}
			// timeout
			// setTimeout( () => {
			// 	if(!resolveReject){
			// 		if(this.currentCommandToastController?.dismiss){this.currentCommandToastController?.dismiss()}
			// 		this.services.uiHelper?.showError("Timed out deleting all sessions");
			// 		this.currentSteCommand = SteCommands.UNKNOWN;
			// 		this.currentCommandToastController = null;
			// 	}
			// }, 1* 60 * 1000);
		});
	}

	public startSessionCatchup() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.START_FULL_CURRENT_SESSION_NOTIFY;
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("CALLING startSessionCatchup!!!");
						console.log("*******************************************")
						console.log("startSessionCatchup Sending : ", steCommand);
						console.log("startSessionCatchup Sending : ", steCommand);
						console.log("startSessionCatchup Sending : ", steCommand);
						console.log("*******************************************")

						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false)?.catch(()=>{})
					}
					resolve(true);
				}
				catch(e){
					reject(e);
				}
			}
			else {
				reject("No bluetooth connection");
			}
		});
	}
	private points = [
		{x:0, y:0 },
		{x:1541, y:400 },
		{x:3499, y:1000 },
		{x:5351, y:1600 },
	]
	// private points = [
	// 	{x:0, y:0},
	// 	{x:1253, y:371},
	// 	{x:1840, y:660},
	// 	{x:2487, y:993},
	// 	{x:4093, y:1983},
	// 	{x:5573, y:2977},
	// 	{x:6840, y:3971},
	// 	{x:8333, y:4962},
	// 	{x:9480, y:5956},
	// ]
	private exposureRateConversion(x:number ) : number  {
		// Find the two points that x falls between
		try{
			if(x == 0){
				return 0;
			}
			let point1 = this.points[0];
			let point2 = this.points[0];
			for (let i = 1; i < this.points.length; i++) {
				if (x == this.points[i].x) {
					return this.points[i].y;
				}
				if (x < this.points[i].x) {
					point1 = this.points[i - 1];
					point2 = this.points[i];
					break;
				}
			}
			// cap the max and min to interpolation array
			if(x<this.points[0].x){
				return this.points[0].y;
			}
			if(x>this.points[this.points.length-1].x){
				point1 = this.points[this.points.length-2];
				point2 = this.points[this.points.length-1];
			}
			if(!point1 || !point2){
				console.error("getInterpolatedGamma: FINDING A CONVERSION SET")
				return 0;
			}
			// Interpolate between the two points using linear interpolation
			let t = (x - point1.x) / (point2.x - point1.x);
			let y = (1 - t) * point1.y + t * point2.y;
			// compute rise over run
			let rise = point2.y - point1.y;
			let run = point2.x - point1.x;
			let slope = rise/run;
			if(y>6000){
				return 6000;
			}
			if( Number.isNaN(y) || y == undefined || y == null || y == Infinity){
				console.error("getInterpolatedGamma: NaN")
				return 0;
			}
			return y;
		}
		catch(e){
			console.error("getInterpolatedGamma: ", e)
			return 0;
		}
	}

	public formatExportTimeString(date){
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');
	  
		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	  }

	public exportCurrentSession() {
		var highestKey = 0;
		for (const key in this.steDeviceData.sessionLogs) {
			if (Object.prototype.hasOwnProperty.call(this.steDeviceData.sessionLogs, key)) {
				const element = this.steDeviceData.sessionLogs[key];
				if(Number(key)>highestKey){
					highestKey = Number(key);
				}
			}
		}
		// console.log("latest session id is : ", highestKey);
		// get session data
		var sessionLogs = this.steDeviceData.sessionLogs[highestKey];
		console.log("Current Key: ", highestKey, " :: ");
		console.log("Current device data: ", this.steDeviceData.sessionLogs);
		if(sessionLogs.history && sessionLogs.history.length>0){
			var csv:string = "Over Range Detected,Highest Alarm Level,Highest Alarm Time (ms),Average Alarm Level,Total Alarm Time (ms), Average Counts Per Second,Average Exposure Rate (uR/h),Total session dose (uR)\n";
			var timeStart = 0;
			// if the session given from device has a timestamp set, use that as the start time
			console.log("checking session time: ", sessionLogs)
			if(sessionLogs.session && sessionLogs.session.timeStart){
				timeStart = Number(sessionLogs.session.timeStart);
				csv = "time,"+csv;
			}
			else {
				// fallback is using the last timestamp and back calculating the start time
				if(sessionLogs.finalTimestamp){
					timeStart = Number(sessionLogs.finalTimestamp)-(sessionLogs.history.length*(1.0074*60*1000));
					csv = "time,"+csv;
				}
				else {
					console.log("no fallback time");
				}
			}
			var  totalDosage = 0;
			for (let index = 0; index < sessionLogs.history.length; index++) {
				var sessionLog:SessionLog = sessionLogs.history[index];
				if(timeStart != 0){
					var newDate = new Date(timeStart+(index*(1.0074*60*1000)));
					// convert date to ISO 8601 string YYYY-MM-DD hh:mm:ss
					csv+=this.formatExportTimeString(newDate)+",";
				}
				if(sessionLog.saturated){
					csv+="Yes";
				}
				else {
					csv+="No";
				}
				csv+=","+sessionLog.highAlarmLevel||0;
				csv+=","+sessionLog.highAlarmLevelTime||0;
				csv+=","+sessionLog.averageAlarmLevel||0;
				csv+=","+sessionLog.totalAlarmTime||0;
				csv+=","+sessionLog.averageCounts||0;
				if(sessionLog.averageCounts){
					csv+=","+this.exposureRateConversion(sessionLog.averageCounts).toFixed(3);
					totalDosage += (this.exposureRateConversion(sessionLog.averageCounts)/(1.0074*60));
					csv+=","+totalDosage.toFixed(3);
				}
				else {
					csv+=",0";
					csv+=","+totalDosage;
				}
				csv += "\n";
			}
			if(sessionLogs.history.length){
				if(this.services.uiHelper){
					this.services.uiHelper.shareAsFile("session_"+highestKey+"_"+Date.now()+".csv", csv);
				}
				else {
					console.error("No UI Helper :: Cant share");
				}
			}
			else {
				this.services.uiHelper?.showError("No data to share");
			}
			console.log("CSV is : ", csv);
		}
	}

	public callUpdateSessionCatchup() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.UPDATE_CURRENT_SESSION_NOTIFY;
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("CALLING callUpdateSessionCatchup!!!");
						console.log("*******************************************")
						console.log("callUpdateSessionCatchup Sending : ", steCommand);
						console.log("callUpdateSessionCatchup Sending : ", steCommand);
						console.log("callUpdateSessionCatchup Sending : ", steCommand);
						console.log("*******************************************")

						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false);
					}
					resolve(true);
				}
				catch(e){
					// console.error("Error getting serial number", JSON.stringify(e));
					reject(e);
				}
			}
			else {
				reject("No bluetooth connection");
			}
		});
	}

	public setSessionTimestamp() : Promise<boolean>{
		return new Promise( async (resolve,reject) => {
			if(this.bluetoothConnection){
				try {
					if(this.eccFrame?.hasClientSecret()){
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.SET_SESSION_TIMESTAMP;
						steCommand.commandData = {
							oneofKind: "sessionTimestamp",
							sessionTimestamp: BigInt(Date.now()),
						};
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("CALLING setSessionTimestamp!!!");
						console.log("*******************************************")
						console.log("setSessionTimestamp Sending : ", steCommand);
						console.log("setSessionTimestamp Sending : ", steCommand);
						console.log("setSessionTimestamp Sending : ", steCommand);
						console.log("*******************************************")

						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false)?.catch( (e) => {
							console.log("Session timestamp failed: ", e)
						})
					}
					resolve(true);
				}
				catch(e){
					console.error("Error setting serial number", e);
					reject(e);
				}
			}
			else {
				reject("No bluetooth connection");
			}
		})
	}

	public setSessionLocation() : Promise<boolean>{
		return new Promise( async (resolve,reject) => {
			try {
				await this.services.geolocate?.getCurrentPosition().then( async (result) => {
					if(result.latitude1E7 != 0 && result.longitude1E7 != 0 && this.bluetoothConnection ){
						var location = Location.create();
						location.latitude1E7 = result.latitude1E7;
						location.longitude1E7 = result.longitude1E7;
						var steCommand:SteCommand = SteCommand.create();
						steCommand.command = SteCommands.SET_LOCATION;
						steCommand.commandData = {
							oneofKind: "location",
							location: location,
						};
						var serialized = await this.packAndEncryptCommand(steCommand);
						console.log("CALLING setSessionLocation!!!");
						console.log("*******************************************")
						console.log("setSessionLocation Sending : ", steCommand);
						console.log("setSessionLocation Sending : ", steCommand);
						console.log("setSessionLocation Sending : ", steCommand);
						console.log("*******************************************")
						
						await this.services.bluetooth?.writeCharacteristic(this.bluetoothConnection, this.bleCommandService, this.bleCommandCharacteristic, serialized, false)?.catch( (e) => {
							console.log("Session location failed: ", e)
						})
						resolve(true);
					}
				});
			}
			catch(e){
				console.error("Error setting serial number", e);
				reject(e);
			}
		})
	}


	public telemetriesToMapPoints(telemetries:TelemetryViewModelImplemented[]) : MapDataPoint[] {
		var mapDataPoints:MapDataPoint[] = [];
		for (let index = 0; index < telemetries.length; index++) {
			const telemetry = telemetries[index];
			if(telemetry.hasPosition){
				if(telemetry.lnglat[0] != 0 && telemetry.lnglat[1] != 0){
					var mapDataPoint:MapDataPoint = {};
					mapDataPoint.coordinates = telemetry.lnglat;
					mapDataPoint.color = Uint8Array.from([0, 0xff, 0]);
					mapDataPoint.elevation = 1000;
					if(telemetry.model?.telemetry?.vendorData){
						if(telemetry.model?.telemetry?.vendorData.data.oneofKind == "radationData"){
							if(telemetry.model?.telemetry?.vendorData.data.radationData){
								if(telemetry.model?.telemetry?.vendorData.data.radationData.gammaData?.gammaAlarmLevel){
									if(telemetry.model?.telemetry?.vendorData.data.radationData.gammaData?.gammaAlarmLevel<3){
										mapDataPoint.color = Uint8Array.from([0xff, 0xff, 0]);
										mapDataPoint.elevation = 1001;
									}
									else if(telemetry.model?.telemetry?.vendorData.data.radationData.gammaData?.gammaAlarmLevel<6){
										mapDataPoint.color = Uint8Array.from([255, 87, 51]);
										mapDataPoint.elevation = 1001;
									}
									else if(telemetry.model?.telemetry?.vendorData.data.radationData.gammaData?.gammaAlarmLevel<=9){
										mapDataPoint.color = Uint8Array.from([255, 0, 0]);
										mapDataPoint.elevation = 1001;
									}
								}
							}
						}
					}
					mapDataPoint.icon = this.getIcon()
					mapDataPoint.timestamp = telemetry.getBestEpoch();
					mapDataPoints.push(mapDataPoint);
				}
			}
		}
		return mapDataPoints;
	}

}