import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

// https://www.youtube.com/watch?v=AF0jnmbbpbE
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';

// Custom
import { ComponentsModule } from './components/components.module';
import { MatSortModule } from '@angular/material/sort';
import { MaterialModule } from './material.module';

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		MaterialModule,
		BrowserModule,
		HttpClientModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		BrowserAnimationsModule,
		ComponentsModule,
		MatSortModule
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		EmailComposer
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
