import { BehaviorSubject, map } from "rxjs";

import { AllServcies } from "../services/startup/startup.service"

import { AllDevicesVMI } from "./AllDevices.vmi"
import { TelemetryViewModelImplemented, } from "./Telemetry.vmi"
import { DeviceModel, DeviceViewModel, DataBase, ModelType } from '../generated_proto/protobuf-ts/pb/v2/models'
import { DeviceTypeIds, } from '../generated_proto/protobuf-ts/pb/system'
export { DeviceTypeIds, }
import { Telemetry,  } from '../generated_proto/protobuf-ts/pb/v2/data'
import { Device, Position } from "../generated_proto/protobuf-ts/pb/v2/entities";
import { Settings } from "../generated_proto/protobuf-ts/pb/v2/settings";

import { LoggerOptions, } from "../services/logger/logger.service"
import { BluetoothConnection,} from "../services/bluetooth/ble.connetion"
import { SerialPortConnection, } from "../services/comport/serialport.connection"

import { ViewModelImplemented } from "./ViewModelImplemented";

import lodash from "lodash";
import deepdash from "deepdash";
import { DataService, FindByKey, FindByKeyResponse, } from "../services/data/data.service";
import { MapDataPoint, MapTooltip } from "../services/map/map.interfaces";

import structuredClone from '@ungap/structured-clone';
import { BluetoothCommonInterface } from "../services/bluetooth/bluetooth.service";
const _ = deepdash(lodash);


export type DeviceTypeGroup = {
	deviceTypeId: number,
	formattedName: string,
};
export type DeviceTypeGroups =  DeviceTypeGroup[];

export enum UnitTypes {
	UNKNOWN = 0,
	CELSIUS,
	MILLICELSIUS,
	FARENHEIT,
	PERCENT,
	UG_M3,
	MILLIVOLTS,
	CENTIVOLTS,
	PPM,
	RSSI,
	PASCAL,
	RELATIVE_HUMIDITY,
	CENTIMETERS,
	METERS,
}

export interface EventType {
	id: number;
	name: string;
  }
  
export const eventTypes: EventType[] = [
{ id: 0, name: "Reserved" },
{ id: 1, name: "Log Test" },
{ id: 2, name: "Coredump PC" },
{ id: 3, name: "Reset" },
{ id: 4, name: "Watchdog" },
{ id: 5, name: "Daily Report Attempt" },
{ id: 6, name: "GPS On" },
{ id: 7, name: "GPS Off" },
{ id: 8, name: "GPS Lock" },
{ id: 9, name: "GPS Lost Lock" },
{ id: 10, name: "GPS Timeout" },
{ id: 11, name: "GPS Process Got Fix" },
{ id: 12, name: "GPS Process Timeout" },
{ id: 13, name: "GPS Best Lock" },
{ id: 14, name: "Got Time Lock" },
{ id: 15, name: "GPS Timeout" },
{ id: 16, name: "URPC Onboarded" },
{ id: 17, name: "URPC Sent" },
{ id: 18, name: "URPC Failed" },
{ id: 19, name: "OTA Updated" },
{ id: 20, name: "OTA Fault" },
{ id: 21, name: "LTE Connected" },
{ id: 22, name: "LTE CellID Change" },
{ id: 23, name: "LTE RSSI Change" },
{ id: 24, name: "LTE Disconnected" },
{ id: 25, name: "LTE Failed" },
{ id: 26, name: "LTE On" },
{ id: 27, name: "LTE Off" },
{ id: 28, name: "LoRa On" },
{ id: 29, name: "LoRa Off" },
{ id: 30, name: "Motion Detected" },
{ id: 31, name: "Motion Stopped" },
{ id: 32, name: "Motion Dormant" },
{ id: 33, name: "Motion Active" },
{ id: 34, name: "Config Changed" },
{ id: 35, name: "Battery Level Change" },
{ id: 36, name: "Solar Level Change" },
{ id: 37, name: "High CPU Usage" },
{ id: 38, name: "Log Fault Detected" },
];

export interface eventFormatted {
	event_id: number,
	event_name: string,
	start_time: string,
	stop_time: string,
	event_data: any,
}
  
export interface modelFormatted  {
	chartValid?:boolean,
	gaugeValid?:boolean,
	thresholds?:any,
	min?: number,
	max?: number,
	unitScale?: number,
	offset?: number,
	offsetScale?: number,
	isplayname?: string,
	units?:string,
	default?: number,
	value?: number,
	epochMs?:number,
	values?:number[],
	epochMsList?:number[],
}

export class DeviceViewModelImplemented extends ViewModelImplemented implements DeviceViewModel {

	// View Model Implemented - Schema set by protobuf
	public model: DeviceModel;
	public telemetryCollection: DataBase[]; // client must implement a getTelemetryCollection() from db.
	// public telemetryCollection: TelemetryViewModel[]; // client must implement a getTelemetryCollection() from db.
	public rawCollection: DataBase[]; // client must implement a getTelemetryCollection() from db.
	// public rawCollection: TelemetryViewModel[]; // client must implement a getTelemetryCollection() from db.
	public isOnline: boolean;

	public isTemporary:boolean=false;
	public isLocal: boolean;

	public hasPosition: boolean;
	public colorRgb: Uint8Array = new Uint8Array([255, 0, 0, 255]); // this is overwritten by ID if no color set.
	public uuidAsHex:string = "";

	public reportedByDeviceVMI:AllDevicesVMI;

	public showTelemetryOffset:boolean = true;

	public loggerOptions:LoggerOptions = {
		prefix:"DeviceViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};
	
	public services:AllServcies;
	public canHaveBluetoothConnection:boolean = false;
	public hasBluetoothConnection: boolean = false;
	public bluetoothConnection:BluetoothConnection | null;
	public serialPortConnection:SerialPortConnection;

	public radiationCapable:boolean = false;

	// Updater is given the last update in epoch ms
	public updater = new BehaviorSubject<number>(Date.now());
	public telemetryUpdater = new BehaviorSubject<Telemetry|undefined>(undefined);

	//TODO: check out CO2Ppm vs cO2Ppm. had to make both cases. not ideal cO2Ppm is used to look up the flattened, and CO2Ppm is for poutchdb 
	public ui_options = {
		'barometricPa': {gaugethresholds: {'0': {color: 'red'},'.3': {color: 'orange'},'.4': {color: 'orangeRed'},'.5': {color: 'green'}}, gaugemax: 1.1, scale: 0.00000986923, displayname: "Barometric", units:"atm" },
		'humidityRh': {gaugethresholds: {'0': {color: 'green'},'70': {color: 'orange'},'80': {color: 'orangeRed'},'90': {color: 'red'}}, gaugemax: 100, scale: 1, displayname: "Relative Humidity", units:"%" }, 
		'temperatureMillidegreeC': {gaugethresholds: {'0': {color: 'blue'},'13': {color: 'purple'},'17': {color: 'green'},'27': {color: 'orangeRed'},'30': {color: 'red'}}, gaugemax: 55, scale: .001, displayname: "Temperature", units:"C" }, 
		'CO2Ppm': {gaugethresholds: {'0': {color: 'green'},'700': {color: 'orange'},'1000': {color: 'orangeRed'},'1100': {color: 'red'}}, gaugemax: 10000, scale: 1, displayname: "CO2", units:"ppm" }, 
		'cO2Ppm': {gaugethresholds: {'0': {color: 'green'},'700': {color: 'orange'},'1000': {color: 'orangeRed'},'1100': {color: 'red'}}, gaugemax: 10000, scale: 1, displayname: "CO2", units:"ppm" }, 
		'batteryVoltageMv': {gaugethresholds: {'3.2': {color: 'red'},'3.4': {color: 'orangeRed'},'3.5': {color: 'orange'},'3.7': {color: 'green'}}, gaugemax: 4.5, scale: .001, displayname: "Battery", units:"V" }, 
		'pm10Standard': {gaugethresholds: {'0': {color: 'green'},'15': {color: 'orange'},'20': {color: 'orangeRed'},'30': {color: 'red'}}, gaugemax: 50, scale: 1, displayname: "Particulate Matter 1.0u", units:"g/m3" }, 
		'pm25Standard': {gaugethresholds: {'0': {color: 'green'},'20': {color: 'orange'},'30': {color: 'orangeRed'},'35': {color: 'red'}}, gaugemax: 50, scale: 1, displayname: "Particulate Matter 2.5u", units:"g/m3" }, 
		'pm100Standard': {gaugethresholds: {'0': {color: 'green'},'30': {color: 'orange'},'35': {color: 'orangeRed'},'40': {color: 'red'}}, gaugemax: 50, scale: 1, displayname: "Particulate Matter 10u", units:"g/m3" }, 
	};

	constructor
	(
		services:AllServcies,
		bluetoothConnection?:BluetoothConnection,
		model?:DeviceModel,
	)
	{
		super();
		this.model = DeviceModel.create();
		this.model.device = Device.create();
		this.model.device.deviceType = DeviceTypeIds.GENERIC_DEVICE;
		
		if(services){
			this.services = services;
		}
		if(bluetoothConnection != null){
			this.bluetoothConnection = bluetoothConnection;
			this.bluetoothConnection.parentVMI = this;
		}
		if(model){
			this.setModel(model);
		}
		// get random number - useful in diagnosing if you're accessing same objects
		this.checkTimer = ""+Date.now()+":"+Math.floor(Math.random()*1000);

		if(this.model.device?.uuid){
			this.uuidAsHex = this.model.device?.uuid.toString(16);;
		}

		this.updateDeviceSpecificModelFormatter();
	}

	checkTimer:string = "0";

	// From storage to create a new bluetooth connectiong
	// this will retreive the local db about the connection / other sources and create the bluetooth connection
	// public createBluetoothConnection() : Promise<boolean>{
	// 	return new Promise((resolve,reject) => {
	// 		if(this.bluetoothConnection){
	// 			console.log("Already have a bluetooth connection");
	// 			resolve(true)
	// 		}
	// 		if(this.model.device?.uuid){
	// 			this.services.data?.getByKey(this.model.device.uuid.toString()+"_btcmi").then( (btcmi:BluetoothCommonInterface) => {
	// 				if(!this.services.bluetooth){
	// 					reject("No bluetooth service");
	// 					return;
	// 				}
	// 				// check if we already have a bluetooth connection
	// 				if(!btcmi.identifier){
	// 					reject("No bluetooth identifier");
	// 					return;
	// 				}

	// 				this.bluetoothConnection = this.services.bluetooth.getDeviceConnection(btcmi.identifier)

	// 				if(!this.bluetoothConnection){
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Creating bluetooth connection with : ", btcmi);


	// 					this.bluetoothConnection = new BluetoothConnection(this.services, btcmi);
	// 					console.log("Created bluetooth connection with : ", btcmi);
	// 					console.log("Created bluetooth connection with : ", btcmi);
	// 					console.log("Created bluetooth connection with : ", btcmi);
	// 					this.bluetoothConnection.parentVMI = this;
	// 					this.hasBluetoothConnection = true;
	// 				}
	// 				else {
	// 					console.log("createBluetoothConnection :: Found bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Found bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Found bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Found bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Found bluetooth connection with : ", btcmi);
	// 					console.log("createBluetoothConnection :: Found bluetooth connection with : ", btcmi);
	// 				}

	// 				resolve(true);
	// 			})
	// 			.catch( (e ) => {
	// 				console.log(" DIDNT FIND BLUETOOTH LOADED");
	// 				reject(e);
	// 			})
	// 		}
	// 	})
	// }

	// From bluetooth service to create a new bluetooth connection
	setBlueToothConnection(bluetoothConnection:BluetoothConnection){
		if(bluetoothConnection != null){
			this.bluetoothConnection = bluetoothConnection;
			this.bluetoothConnection.parentVMI = this;
			this.hasBluetoothConnection = true;
		}
	}

	async initalizeBluetoothConnection( disconnectFn?:()=>any) : Promise<boolean> {
		console.log("DeviceViewModelImplemented: initalizeBluetoothConnection");

		this.hasBluetoothConnection = false;
		if(!this.bluetoothConnection){
			if(this.services.bluetooth && this.model.device?.uuid){
				this.bluetoothConnection = this.services.bluetooth.getDeviceConnection(this.model.device?.uuid);

				if(this.bluetoothConnection){
					this.hasBluetoothConnection = true;
					this.bluetoothConnection.parentVMI = this;
					if(this.bluetoothConnection && disconnectFn){
						console.log("Set cbb for the device disconnected")
						this.bluetoothConnection.showDisconnected = disconnectFn;
					}
					return this.bluetoothConnection.initalizeDevice();
				}
				// pull from local db, to see if we have a common interface stored
				var commonInterface = await this.services.data?.getByKey(this.model.device.uuid.toString()+"_btcmi") as BluetoothCommonInterface;
				if(commonInterface){
					console.log("Got bluetooth connection", commonInterface)
					this.bluetoothConnection = new BluetoothConnection(this.services, commonInterface);
					this.bluetoothConnection.bluetoothCommonInterface = commonInterface;
					this.bluetoothConnection.parentVMI = this;
					this.hasBluetoothConnection = true;
					if(this.bluetoothConnection && disconnectFn){
						console.log("Set cbb for the device disconnected")
						this.bluetoothConnection.showDisconnected = disconnectFn;
					}
					return this.bluetoothConnection.initalizeDevice();
				}
			}
			return Promise.reject("No bluetooth service or uuid");
		}
		return this.bluetoothConnection.initalizeDevice();
	}
	

	setLoggerOptions( loggerOptions:LoggerOptions ){
		this.loggerOptions = loggerOptions;
	}

	// Common VMI implementations
	public getId(): bigint {
		if(this.model.device?.uuid){
			return this.model.device?.uuid;
		}
		else if(this.model.db?.uid){
			return this.model.db?.uid;
		}
		else if(this.model.db?.uuid){
			return this.model.db?.uuid;
		}
		else {
			return BigInt(0);
		}
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// Local View Model Updates
	// this "redraws" the view model from the model infromation already given.
	// it will NOT fetch more data from sever. Use this to call approparite functions to upate the ViweModel
	// after the model/sub messages or any other state change. to be efficent. you can call the speific update functions. but this
	// is the catch all.
	update(){
		this.colorRgb = this.getColor();
		this.updater.next(Date.now());
	}
	setModel(deviceModel_pb:DeviceModel) : DeviceViewModelImplemented {
		if(deviceModel_pb){
			this.model = deviceModel_pb;
		}
		this.update();
		return this;
	}
	setDevice(device:Device){
		if(!this.model){
			this.model = DeviceModel.create();
		}
		if(device){
			this.model.device = device;
		}
		this.update();
	}
	setManualPosition(position:Position){
		console.log("setManualPosition", position);
		if(position){
			console.log("has");
			if(!this.model.device){
				this.model.device = Device.create();
				console.log("Make device: ", this.model.device);
			}
			this.model.device.manualPosition = position;
			console.log("setManualPosition : done");
		}
	}
	updateFromVmi(updateVMI:DeviceViewModelImplemented){
		if(updateVMI){
			this.updateFromModel(updateVMI.model);
			if(updateVMI.allTelemetry.length>0){
				this.allTelemetry = updateVMI.allTelemetry;
			}
		}
	}
	updateFromModel( model:DeviceModel ){
		if(this.services.logger) this.services.logger.verbose(this.loggerOptions, "updateFromModel", model);
		console.log("Model: ", model)
		// if(model.derivedTelemetry?.system?.connectedCell?.simid){
		// 	var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
		// 	if(base64regex.test(model.derivedTelemetry.system.connectedCell.simid as any)){
		// 		model.derivedTelemetry.system.connectedCell.simid = BigInt(0);
		// 	}
		// }
		
		if(!this.model){
			this.model = DeviceModel.create(model);
		}
		if(model && this.model){
			try{
				if(!this.model.derivedTelemetry){
					this.model.derivedTelemetry = Telemetry.create();
				}
				var tmpLatitude1E7 = this.model.derivedTelemetry.latitude1E7;
				var tmpLongitude1E7 = this.model.derivedTelemetry.longitude1E7;
				var tmpElevationCm = this.model.derivedTelemetry.elevationCm;
				var tmpGpsScanRecord = this.model.derivedTelemetry.gpsScanRecord;
				DeviceModel.mergePartial(this.model, model);
				if(model.derivedTelemetry?.latitude1E7 == 0 || model.derivedTelemetry?.longitude1E7 == 0){
					this.model.derivedTelemetry.latitude1E7 = tmpLatitude1E7;
					this.model.derivedTelemetry.longitude1E7 = tmpLongitude1E7;
					this.model.derivedTelemetry.elevationCm = tmpElevationCm;
					this.model.derivedTelemetry.gpsScanRecord = tmpGpsScanRecord;
				}
			}
			catch(e){
				console.log("updateFromModel:Error merging model: ", e);
			};
		}
		console.log("Model is : ", model);
		this.update();
	}
	updateFromTelemetry( telemetry:Telemetry ){
		this.telemetryUpdater.next(telemetry);
		if(telemetry){
			if(this.model.device) {
				this.model.device.uuid = telemetry.uuid;
				this.model.newestTelemetryMs = telemetry.epochMs;
				// This field is updated any time 
				this.model.lastConnectMs = telemetry.epochMs;
			}
			if(!this.model.derivedTelemetry){
				this.model.derivedTelemetry = Telemetry.create();
			}
			try{
				var tmpLatitude1E7 = this.model.derivedTelemetry.latitude1E7;
				var tmpLongitude1E7 = this.model.derivedTelemetry.longitude1E7;
				var tmpElevationCm = this.model.derivedTelemetry.elevationCm;
				var tmpGpsScanRecord = this.model.derivedTelemetry.gpsScanRecord;
				Telemetry.mergePartial(this.model.derivedTelemetry, telemetry);
				if(telemetry.latitude1E7 == 0 || telemetry.longitude1E7 == 0){
					this.model.derivedTelemetry.latitude1E7 = tmpLatitude1E7;
					this.model.derivedTelemetry.longitude1E7 = tmpLongitude1E7;
					this.model.derivedTelemetry.elevationCm = tmpElevationCm;
					this.model.derivedTelemetry.gpsScanRecord = tmpGpsScanRecord;
				}
				else {
					if(this.model.device){
						this.model.device.reportedPosition = Position.create();
						this.model.device.reportedPosition.epochMs = telemetry.epochMs;
						this.model.device.reportedPosition.latitude = telemetry.latitude1E7*1e-7;
						this.model.device.reportedPosition.longitude = telemetry.longitude1E7*1e-7;
						this.model.device.reportedPosition.elevationCm = telemetry.elevationCm;
						if(telemetry.gpsScanRecord){
							this.model.device.reportedPosition.fixType = telemetry.gpsScanRecord.fixType;
							this.model.device.reportedPosition.locationPrecisionCm = telemetry.gpsScanRecord.geopointAccuracyCm;
						}
						console.log("updateFromTelemetry: ", this.model.device.reportedPosition);
						console.log("updateFromTelemetry: ", this.model.device.reportedPosition);
					}
				}

			}
			catch(e){
				console.log("updateFromTelemetry:Error merging model: ", e);
			};
		}
		this.update();
		if(this.services.logger) this.services.logger.verbose(this.loggerOptions, "updateFromTelemetry");
	}
	updateDeviceSettings( deviceSettings:Settings ){
		if(deviceSettings){
			if(this.model.device){
				this.model.device.uuid = deviceSettings.uuid;
				this.model.device.deviceSettings = deviceSettings;
			}
		}
	}
	updateTargetSettings( targetSettings:Settings ){
		if(targetSettings){
			if(this.model.device){
				this.model.device.targetSettings = targetSettings;
			}
		}
	}
	addTelemetryViewModel( telemVMI:TelemetryViewModelImplemented) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.allTelemetry.unshift(telemVMI);
			this.allTelemetry.sort( (a,b) => {
				return b.getBestEpoch() - a.getBestEpoch();
			});
			resolve(true);
		});
	}
	addCurrentTelemetryTest( telem:Telemetry) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.model.derivedTelemetry = telem;
			var telemVMI = new TelemetryViewModelImplemented(this.services);
			telemVMI.updateFromTelemetry(telem);
			this.allTelemetry.unshift(telemVMI);
			this.allTelemetry.sort( (a,b) => {
				return b.getBestEpoch() - a.getBestEpoch();
			});
			resolve(true);
		});
	}
	modelToJson(){
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		return DeviceModel.toJson(this.model);
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////
	/// UI Helpers
	//////////////////////////////////////////////////////////////////////////////////////////
	public static getStaticTitle():string{ // so we can access this w/o an instance i guess
		return "Device"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return DeviceViewModelImplemented.getStaticTitle();
	}

	public static getStaticSubTitleDescription(){
		return "Device";
	}
	public getSubTitleDescription():string{
		return DeviceViewModelImplemented.getStaticSubTitleDescription();
	}

	public goToPage(prototype:boolean=false) {
		// this.services.router.navigateByUrl(this.getPagePath());
		if(this.services.nav) this.services.nav.navigateForward(this.getPagePath(prototype));
	}
	public getPagePath(prototype:boolean=false) : string {
		if(this.model.device){
			if(this.services.settings?.SETTINGS.APP_IS_PROTOTYPE || prototype){
				return "vendors/default/prototype-device/" + this.model.device?.uuid;
			}
			else {
				return "vendors/default/default-device/" + this.model.device.uuid;
			}
		}
		return "vendors/default/default-device/" + 0;
	}

	public getColor() : Uint8Array {
		if(this.model.device && this.model.device.color && this.model.device.color.length>0){
			if(this.model.device.color[3] <= 0.3){
				this.model.device.color[3] = 1;
			}
			return this.model.device.color;
		}
		else{
			return super.getColor();
		}
	}
	getIcon() : string {
		if(this.model.device?.iconUrl){
			return this.model.device.iconUrl;
		}
		return ""
	}

	// Because for some reason, img [src]=""  has a really hard time with functions() ... so ok. its a member.
	public thumbnailImagePath:string = "assets/app/devices/unknown.png";
	public getThumbnailImagePath() : string{
		if(this.model.device?.deviceType == DeviceTypeIds.GENERIC_SIMULATED){
			return "assets/app/devices/simulator.png";
		}
		return this.thumbnailImagePath;
	}

	public getDisplayName() : string {
		if(this.model.device){
			if(this.model.device.name) return this.model.device.name
			if(this.model.device.foundName) return this.model.device.foundName
		}
		if(this.bluetoothConnection?.bluetoothCommonInterface?.name){
			return this.bluetoothConnection.bluetoothCommonInterface.name;
		}
		return this.getTitle()
	}

	public getBluetoothMac() : string {
		if(this.bluetoothConnection?.bluetoothCommonInterface?.address){
			return "MAC: "+this.bluetoothConnection.bluetoothCommonInterface.address
		}
		return "MAC: n/a";
	}

	public getDisplayUuid(showPrefix:boolean=true) : string {
		var prefix = "SN: ";
		if(!showPrefix){
			prefix = "";
		}
		if(this.model.device?.uuid){
			return prefix+this.model.device.uuid
		}
		return prefix+"n/a";
	}

	public ionItemDescription: any;
	public getIonItemDescription(): any {
		if(this.ionItemDescription == null){
			this.ionItemDescription = this.buildIonItemDescription();
		}
		return this.ionItemDescription;
	}
	public buildIonItemDescription(): any {
		var html = `
			<ion-thumbnail slot="start"> 
				<img src="${this.getThumbnailImagePath()}"> 
			</ion-thumbnail> 
			<div>
				<ion-label> 
					<div class="ion-label-title">${this.getDisplayName()}</div> 
					<div class="ion-label-body">
						${this.getDisplayUuid()}<br>
						Last: ${new Date(Number(this.model.db?.updatedMs||0)).toLocaleDateString()} ${new Date(Number(this.model.db?.updatedMs||0)).toLocaleTimeString()}
					</div>
				</ion-label>
			</div>
			`;

			if(this.reportedByDeviceVMI){
				if(this.reportedByDeviceVMI.model.device?.deviceType == DeviceTypeIds.GENERIC_GATEWAY || this.reportedByDeviceVMI.model.device?.deviceType == DeviceTypeIds.HUM_GATEWAY){
					html = html.concat( `
						<ion-chip><ion-label> Gateway: ${this.reportedByDeviceVMI.getDisplayName()}  </ion-label> </ion-chip>
						</a>
					`);
				}
				else {
					html = html.concat( `<ion-chip> <ion-label> Via: ${this.reportedByDeviceVMI.getDisplayName()}  </ion-label> </ion-chip>`);
				}
			}

			// System
			if(this.model.derivedTelemetry?.system?.currentVersion)
			{
				if( !(this.model.derivedTelemetry?.system?.currentVersion[2] == undefined || this.model.derivedTelemetry?.system?.currentVersion[1] == undefined || this.model.derivedTelemetry?.system?.currentVersion[0] == undefined ) ){
					html = html.concat( `<ion-chip> <ion-label> V:${this.model.derivedTelemetry?.system?.currentVersion[2]}.${this.model.derivedTelemetry?.system?.currentVersion[1]}.${this.model.derivedTelemetry?.system?.currentVersion[0]} </ion-label> </ion-chip>`);
				}
			}
			if(this.model.derivedTelemetry?.environment?.cO2Ppm)
			{
				html = html.concat( `<ion-chip> <ion-label> CO2: ${this.model.derivedTelemetry?.environment?.cO2Ppm} ppm</ion-label> </ion-chip>`);
			}

			if(this.model.derivedTelemetry?.environment?.temperatureMillidegreeC)
			{
				html = html.concat(  `<ion-chip> <ion-label> Temp: ${(this.model.derivedTelemetry?.environment?.temperatureMillidegreeC * .001).toFixed(2)} C</ion-label> </ion-chip>`);
			}
			if(this.model.derivedTelemetry?.environment?.pmsensor?.pm10Standard)
			{
				html = html.concat(  `<ion-chip> <ion-label> PM1.0: ${(this.model.derivedTelemetry?.environment?.pmsensor?.pm10Standard )} ppm</ion-label> </ion-chip>`);
			}

			if(this.model.derivedTelemetry?.environment?.pmsensor?.pm25Standard)
			{
				html = html.concat(  `<ion-chip> <ion-label> PM2.5: ${(this.model.derivedTelemetry?.environment?.pmsensor?.pm25Standard )} ppm</ion-label> </ion-chip>`);
			}

			// system
			if(this.model.derivedTelemetry?.system?.batteryVoltageMv)
			{
				html = html.concat(  `<ion-chip> <ion-label> ${(this.model.derivedTelemetry?.system?.batteryVoltageMv * .001).toFixed(2) } V</ion-label> </ion-chip>`);
			}
			if(this.model.derivedTelemetry?.system?.solarVoltageMv)
			{
				html = html.concat(  `<ion-chip> <ion-label> Solar: ${(this.model.derivedTelemetry?.system?.solarVoltageMv * .001).toFixed(2) } V</ion-label> </ion-chip>`);
			}
			if(this.model.derivedTelemetry?.system?.systemTemperatureC)
			{
				html = html.concat(  `<ion-chip> <ion-label> ${(this.model.derivedTelemetry?.system?.systemTemperatureC).toFixed(2) } C°</ion-label> </ion-chip>`);
			}
			if(this.model.derivedTelemetry?.system?.currentLoraConfig?.rssi)
			{
				html = html.concat(  `<ion-chip> <ion-label> RSSI: ${this.model.derivedTelemetry?.system?.currentLoraConfig?.rssi} dBm</ion-label> </ion-chip>`);
			}

			if(this.model.derivedTelemetry?.environment?.wind?.windSpeedCmS)
			{
				html = html.concat(  `<ion-chip> <ion-label> ${(this.model.derivedTelemetry?.environment?.wind?.windSpeedCmS )} cm/s</ion-label> </ion-chip>`);
			}

			if(this.model.derivedTelemetry?.environment?.wind?.windDirectionDegrees)
			{
				html = html.concat(  `<ion-chip> <ion-label> ${(this.model.derivedTelemetry?.environment?.wind?.windDirectionDegrees )}°</ion-label> </ion-chip>`);
			}

			// vibration
			if(this.model.derivedTelemetry?.vendorData?.data.oneofKind == "industrial" && this.model.derivedTelemetry?.vendorData?.data.industrial?.vibration?.adxlRms)
			{
				html = html.concat(  `<ion-chip> <ion-label> ADXL MAX: ${(this.model.derivedTelemetry?.vendorData?.data.industrial?.vibration?.adxlMax * .01).toFixed(2) } G</ion-label> </ion-chip>`);
			}
			if(this.model.derivedTelemetry?.vendorData?.data.oneofKind == "industrial" && this.model.derivedTelemetry?.vendorData?.data.industrial?.vibration?.adxlRms)
			{
				html = html.concat(  `<ion-chip> <ion-label> ADXL: ${(this.model.derivedTelemetry?.vendorData?.data.industrial?.vibration?.adxlRms * .01).toFixed(2) } G<sub>rms</sub></ion-label> </ion-chip>`);
			}


			// valve
			if(this.model.derivedTelemetry?.vendorData?.data.oneofKind == "industrial" && this.model.derivedTelemetry?.vendorData?.data.industrial?.valve?.batteryVoltageCentivolts)
			{
				html = html.concat(  `<ion-chip> <ion-label> ${(this.model.derivedTelemetry?.vendorData?.data.industrial?.valve?.batteryVoltageCentivolts* .01).toFixed(2) } V</ion-label> </ion-chip>`);
			}
			if(this.model.derivedTelemetry?.vendorData?.data.oneofKind == "industrial" && this.model.derivedTelemetry?.vendorData?.data.industrial?.valve)
			{
				html = html.concat(  `<ion-chip> <ion-label> Status: ${(this.model.derivedTelemetry?.vendorData?.data.industrial?.valve?.status ? "open" : "closed") }</ion-label> </ion-chip>`);
			}

			// strain
			if(this.model.derivedTelemetry?.vendorData?.data.oneofKind == "industrial" && this.model.derivedTelemetry?.vendorData?.data.industrial?.strain?.batteryVoltageCentivolts)
			{
				html = html.concat(  `<ion-chip> <ion-label> ${(this.model.derivedTelemetry?.vendorData?.data.industrial?.strain?.batteryVoltageCentivolts* .01).toFixed(2) } V</ion-label> </ion-chip>`);
			}
			if(this.model.derivedTelemetry?.vendorData?.data.oneofKind == "industrial" && this.model.derivedTelemetry?.vendorData?.data.industrial?.strain?.rawStrainDeltaPerMinute)
			{
				html = html.concat(  `<ion-chip> <ion-label> Raw Strain: ${(this.model.derivedTelemetry?.vendorData?.data.industrial?.strain?.rawStrainDeltaPerMinute).toFixed(2) }</ion-label> </ion-chip>`);
			}

			if(this.model.derivedTelemetry?.vendorData?.data.oneofKind == "radationData" && this.model.derivedTelemetry?.vendorData?.data.radationData?.gammaData?.gammaTotalGrossCounts)
			{
				html = html.concat(  `<ion-chip> <ion-label> Gamma CPS: ${(this.model.derivedTelemetry?.vendorData?.data.radationData?.gammaData?.gammaTotalGrossCounts).toFixed(2) }</ion-label> </ion-chip>`);
			}
			if(this.model.derivedTelemetry?.vendorData?.data.oneofKind == "radationData" && this.model.derivedTelemetry?.vendorData?.data.radationData?.gammaData?.gammaExposureRate)
			{
				html = html.concat(  `<ion-chip> <ion-label> Gamma Dose: ${(this.model.derivedTelemetry?.vendorData?.data.radationData?.gammaData?.gammaExposureRate*1e-7).toFixed(5) } uR/h</ion-label> </ion-chip>`);
			}

		if(this.services.domSanitizer) this.ionItemDescription = this.services.domSanitizer.bypassSecurityTrustHtml(html);
		return this.ionItemDescription;
	}
	//////////////////////////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Manage Collections
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	public allTelemetry: TelemetryViewModelImplemented[] = [];

	getDerivedTelemetryVMI() : Promise<TelemetryViewModelImplemented>{
		return new Promise( (resolve, reject) => {
			if(this.model.derivedTelemetry){
				var derrivedTelemetryVMI:TelemetryViewModelImplemented = new TelemetryViewModelImplemented(this.services);
				derrivedTelemetryVMI.updateFromTelemetry(this.model.derivedTelemetry);
				resolve(derrivedTelemetryVMI);
			}
			else{
				reject("not loaded");
			}
		})
	}
	getLastTelemetryVMI() : Promise<TelemetryViewModelImplemented> {
		if(this.model.derivedTelemetry){
			return new Promise( (resolve,reject) => {
				if(this.model.derivedTelemetry){
					var derrivedTelemetryVMI:TelemetryViewModelImplemented = new TelemetryViewModelImplemented(this.services);
					derrivedTelemetryVMI.updateFromTelemetry(this.model.derivedTelemetry);
					resolve(derrivedTelemetryVMI);
				}
				else {
					reject("Model not loaded");
				}
			});
		}
		else if(this.model.device?.uuid && this.services.pouch?.devices) {
			return this.services.pouch?.devices.getLatestTelemetry(this)
		}
		return Promise.reject("getLastTelemetryVMI: No UUID FAILED TO getLastTelemetryVMI");
	}

	getTelemetryTimes(startEpochMs?:number, endEpochMs?:number ) : Promise<{epochMs:number[], syncedState:number[]}> {
		return new Promise( (resolve,reject) => {
			var urn:string = this.getTelemeryUrn();
			if(urn && this.services.data){
				this.services.data.getDbii(urn).then( (dbii) => {
					var times = dbii.getStatusInRange(startEpochMs, endEpochMs)
					resolve(times);
				});
			}
			else {
				reject("urn missing");
			}
		})
	}

	getTelemetryVmis(startMs?:number, stopMs?:number ) : Promise<TelemetryViewModelImplemented[]> {
		if(this.services.pouch?.devices){
			return this.services.pouch?.devices.getTelemetries(this, startMs, stopMs)
		}
		return Promise.reject("Failed to get pouch sensors service");
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// Server Requests
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	getTelemetryPage( ) : TelemetryViewModelImplemented[]{

		return this.allTelemetry;
	}
	refresh(includeTelemetry:boolean=false) : Promise<boolean> {
		// Update our local UI
		// This is where you can handle any UI specific bindings from the model's data 
		return new Promise( (resolve, reject) => {
			if(!this.model || !this.model.device || !this.services.data){
				this.services.logger?.error(this.loggerOptions, "refresh: Model Device Pouch not set");
				reject(this.loggerOptions.prefix+"refresh: Model or Device not set");
				return;
			}
			this.services.logger?.verbose(this.loggerOptions, "refresh: Model: "+this.model.device.uuid);

			if(this.services.settings?.SETTINGS.APP_ID){
				this.services.data.getDeviceByUuid(this.services.settings?.SETTINGS.APP_ID, this.model.device.uuid).then( (sensorVMI:AllDevicesVMI) => {
					this.updateFromVmi(sensorVMI)
					resolve(true)
				}).catch( (err) => {
					reject(err);
				});
			}
		});
	}
	async save(app_id?:number) : Promise<boolean>{
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		if(this.services.data) return this.services.data.saveDevice(app_id||2, this);
		if(this.services.logger) this.services.logger.error(this.loggerOptions, "save: DataService missing");
		return Promise.reject(this.loggerOptions.prefix+":DataService missing");
	}
	async delete(app_id?:number) : Promise<boolean> {
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		if(this.bluetoothConnection){
			await this.bluetoothConnection.disconnect();
			// delay one second
			await new Promise(resolve => setTimeout(resolve, 1000));
			this.bluetoothConnection = null;
		}
		this.services.logger?.debug(this.loggerOptions, "delete: AppID: "+app_id+" ::model: "+this.model.device?.uuid);
		if(this.services.proto) return this.services.proto.deleteDevice(app_id||2, this);
		if(this.services.logger) this.services.logger.error(this.loggerOptions, "delete: DataService missing");
		return Promise.reject(this.loggerOptions.prefix+":DataService missing");
	}
	updateLocalFromDb(json:any){
		if(json){
			if(json.derivedTelemetry?.system?.connectedCell?.simid){
				var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
				if(base64regex.test(json.derivedTelemetry.system.connectedCell.simid)){
					json.derivedTelemetry.system.connectedCell.simid = 0;
				}
			}
			this.setModel(DeviceModel.fromJson(json, {ignoreUnknownFields: true}));
			if(this.model.device?.uuid){
				this.uuidAsHex = this.model.device?.uuid.toString(16);;
			}
		}
	}
	private static PB_VERSION = 2;
	static GenerateDbId(dbPrefix:string) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return dbPrefix + ModelType.DEVICE_MODEL;
	}
	static GenerateDbKey(uuid:bigint) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return DataService.getKeyPrefix(ModelType.DEVICE_MODEL,DeviceViewModelImplemented.PB_VERSION)+ uuid.toString();
	}
	public generateDbKey() : string {
		if(this.model.device?.uuid ){
			return DeviceViewModelImplemented.GenerateDbKey(this.model.device.uuid);
		}
		return "";
	}
	static GenerateURN(dbPrefix:string, device:Device) : string {
		if(device){
			var dbid = this.GenerateDbId(dbPrefix);
			return dbid+"/"+this.GenerateDbKey(device.uuid);
		}
		console.error("generateURN: No UUID");
		return "";
	}
	public generateURN() : string {
		if(this.model && this.model.device && this.services.data?.getDbPrefix()){
			return DeviceViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.model.device);
		}
		return "";
	}
	updateDb(db?:DataBase){
		if(!this.model.db){
			this.model.db = DataBase.create();
		}
		if(db){
			try{
				DataBase.mergePartial(this.model.db, db);
			}
			catch(err){
				console.error("updateDb: Failed to merge db: "+err);
			}
		}
		if( this.model.db.createdMs && this.model.db.createdMs <= 0){
			this.model.db.createdMs = BigInt(Date.now());
		}
		if(this.model.device) this.model.db.uid = BigInt(this.model.device.uuid);
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.DEVICE_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// UI Accessors
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	public getCurrentMapDataPoint() : Promise<MapDataPoint>{
		return new Promise( (resolve, reject) => {
			var mapDataPoint:MapDataPoint = {};
			if(this.model.device?.manualPosition){
				if(this.model.device.manualPosition.latitude != 0 && this.model.device.manualPosition.longitude != 0){
					var manualPosition:Position = this.model.device.manualPosition;
					mapDataPoint.coordinates = [manualPosition.longitude, manualPosition.latitude];
					mapDataPoint.color = this.getColor();
					mapDataPoint.icon = this.getIcon()
					mapDataPoint.timestamp = Date.now();
					resolve(mapDataPoint);
					return;
				}
			}
			if(this.model.device?.reportedPosition){
				if(this.model.device.reportedPosition.latitude != 0 && this.model.device.reportedPosition.longitude != 0){
					var reportedPosition:Position = this.model.device.reportedPosition;
					mapDataPoint.coordinates = [reportedPosition.longitude, reportedPosition.latitude];
					mapDataPoint.color = this.getColor();
					mapDataPoint.icon = this.getIcon()
					mapDataPoint.timestamp = Date.now();
					resolve(mapDataPoint);
					return;
				}
			}
			if(this.model.device?.calculatedPosition){
				if(this.model.device?.calculatedPosition.latitude != 0 && this.model.device?.calculatedPosition.longitude != 0){
					var manualPosition:Position = this.model.device.calculatedPosition;
					mapDataPoint.coordinates = [manualPosition.longitude, manualPosition.latitude];
					mapDataPoint.color = this.getColor();
					mapDataPoint.icon = this.getIcon()
					mapDataPoint.timestamp = Date.now();
					resolve(mapDataPoint);
					return;
				}
			}
			if(this.model.derivedTelemetry){
				if(this.model.derivedTelemetry.latitude1E7 !=0 && this.model.derivedTelemetry.longitude1E7 != 0){
					mapDataPoint.coordinates = [this.model.derivedTelemetry.longitude1E7*1e-7, this.model.derivedTelemetry.latitude1E7*1e-7];
					mapDataPoint.color = this.getColor();
					mapDataPoint.icon = this.getIcon()
					mapDataPoint.timestamp = Date.now();
					resolve(mapDataPoint);
					return;
				}
			}
			reject("Can not find current map data point");
		})
	}

	public telemetriesToMapPoints(telemetries:TelemetryViewModelImplemented[]) : MapDataPoint[] {
		var mapDataPoints:MapDataPoint[] = [];
		for (let index = 0; index < telemetries.length; index++) {
			const telem:TelemetryViewModelImplemented = telemetries[index];
			if(telem.hasPosition){
				if(telem.lnglat[0] != 0 && telem.lnglat[1] != 0){
					var mapDataPoint:MapDataPoint = {};
					mapDataPoint.coordinates = telem.lnglat;
					mapDataPoint.elevation = telem.elevationCm/100;
					mapDataPoint.color = this.getColor();
					mapDataPoint.icon = this.getIcon();
					if(telem.model?.telemetry?.vendorData?.data.oneofKind == "radationData"){
						console.log("telem.model?.telemetry?.vendorData?.data.radationData.gammaData?.gammaExposureRate:", telem.model?.telemetry?.vendorData?.data.radationData.gammaData?.gammaExposureRate)
						if(telem.model?.telemetry?.vendorData?.data.radationData){
							if(telem.model?.telemetry?.vendorData?.data.radationData.gammaData?.gammaExposureRate && telem.model?.telemetry?.vendorData?.data.radationData.gammaData?.gammaExposureRate*1e-7 < 10){
								// mapDataPoint.color is yellow
								mapDataPoint.color[3] = 50
							}
							if(telem.model?.telemetry?.vendorData?.data.radationData.gammaData?.gammaExposureRate && telem.model?.telemetry?.vendorData?.data.radationData.gammaData?.gammaExposureRate*1e-7 > 10){
								// mapDataPoint.color is yellow
								mapDataPoint.color = Uint8Array.from([255,255,0,255])
							}
							if(telem.model?.telemetry?.vendorData?.data.radationData.gammaData?.gammaExposureRate && telem.model?.telemetry?.vendorData?.data.radationData.gammaData?.gammaExposureRate*1e-7 > 17){
								// mapDataPoint.color is red
								mapDataPoint.color = Uint8Array.from([255,0,0,255])
							}
						}
					}
					mapDataPoint.timestamp = telem.getBestEpoch();

					var derrivedTelmetryFormatted = structuredClone({},this.modelFormatter.telemetry);
					this.traverseModelForValues(this.modelFormatter.telemetry, derrivedTelmetryFormatted, this.model.derivedTelemetry, {}, "telemetry.", [telem], true);

					mapDataPoint.telemetryFormatted = derrivedTelmetryFormatted;
					mapDataPoints.push(mapDataPoint);
					// mapDataPoint.deviceVMI = this;
				}
			}
		}
		return mapDataPoints;
	}

	public getTelemetryMapDataPoints( startMs?:number, endMs?:number) : Promise<MapDataPoint[]>{
		return new Promise( (resolve,reject) => {
			this.getTelemetryVmis(startMs, endMs).then( (telemetries:TelemetryViewModelImplemented[]) => {
				telemetries = telemetries.reverse();
				var mapDataPoints:MapDataPoint[] = this.telemetriesToMapPoints(telemetries);
				mapDataPoints[0].deviceVMI = this;
				resolve(mapDataPoints);
			}).catch( (err) => {
				console.error("getTelemetryMapDataPoints: ",err);
				reject("getTelemetryMapDataPoints: "+err)
			});
		})
	}

	private getTelemeryUrn() : string {
		if(this.services.data && this.model.device){
			return TelemetryViewModelImplemented.GenerateDbId(this.services.data.getDbPrefix(), this.model.device.uuid)
		}
		console.error("getTelemeryUrn: No Service || UUID");
		return "";
	}

	collateData(keysFinder:FindByKey[]) : Promise<FindByKeyResponse> {
		if(this.services.data && this.model.device){
			var sortByKey:string = "";
			var urn:string = this.getTelemeryUrn();
			return this.services.data.collateByKeys(urn, keysFinder||[], sortByKey)
		}
		return Promise.reject("No Service || UUID");
	}

	checkSearchTerm(searchTerm:string) : boolean {
		if(this.model.device){
			if(this.getDisplayName().toLowerCase().includes(searchTerm.toLowerCase())){
				return true;
			}
			if(this["macAddress"]){
				console.log("FOund mac address", this["macAddress"])
			}
		}
		return false;
	}

	public getDeviceTypeGroups() : DeviceTypeGroups {
		var groups = [{
			deviceTypeId: DeviceTypeIds.GENERIC,
			formattedName: "Generic",
		}];
		if(this.model.device?.deviceType == DeviceTypeIds.GENERIC_DEVICE){
			groups.push({
				deviceTypeId: DeviceTypeIds.GENERIC_DEVICE,
				formattedName: "Devices",
			});
		}
		else {
			if(this.model.device?.deviceType){
				// console.warn("Device Type Group formatting not implemented (optional)");
				var name = DeviceTypeIds[this.model.device.deviceType].replace(/_/g, " ").replace(/\w\S*/g, (txt) => {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				})
				groups.push({
					deviceTypeId: this.model.device.deviceType,
					formattedName: name,
				});
			}
		}
		return groups;
	}

	
	public modelFormatter = {
		telemetry : {
			elevationCm:{
				UnitType: UnitTypes.CENTIMETERS,
				order: 0,
				chartValid:true,
				gaugeValid:false,
				tableValid: true,
				thresholds: {
					'0': {color: 'red'},'.3': {color: 'orange'},'.4': {color: 'orangeRed'},'.5': {color: 'green'}
				},
				min: 0,
				max: Number.MAX_VALUE,
				unitScale: 1,
				offset: 0,
				offsetScale: 1,
				displayname: "Elevation",
				units:"cm",
				default: 0,
				value: 0,
				epochMs:0,
				values: [],
				epochMsList:[],
				collate: true,
			},
			system: {
				uptimeTicks: {
					UnitType: UnitTypes.UNKNOWN,
					order: 0,
					chartValid:true,
					gaugeValid:false,
					tableValid: false,
					thresholds: {
						'0': {color: 'red'},'.3': {color: 'orange'},'.4': {color: 'orangeRed'},'.5': {color: 'green'}
					},
					min: 0,
					max: Number.MAX_VALUE,
					unitScale: 1,
					offset: 0,
					offsetScale: 1,
					displayname: "Uptime Ticks",
					units:"ticks",
					default: 0,
					value: 0,
					epochMs:0,
					values: [],
					epochMsList:[],
					collate: true,
				},
				batteryVoltageMv: {
					unitType: UnitTypes.MILLIVOLTS,
					order: 0,
					chartValid:true,
					gaugeValid:true,
					tableValid: true,
					thresholds: {
						'0': {color: 'red'},'3.5': {color: 'orange'},'3.6': {color: 'orangeRed'},'3.75': {color: 'green'}
					},
					min: 3.3,
					max: 5,
					chartUseMaxMin:false,
					unitScale: .001,
					offset: 0,
					offsetScale: 1,
					displayname: "Battery Voltage",
					units:"V",
					default: 0,
					value: 0,
					epochMs:0,
					values: [],
					epochMsList:[],
					collate: true,
				},
				solarVoltageMv: {
					unitType: UnitTypes.MILLIVOLTS,
					order: 0,
					chartValid:true,
					gaugeValid:true,
					tableValid: true,
					thresholds: {
						'0': {color: 'red'},'3.5': {color: 'orange'},'3.6': {color: 'orangeRed'},'3.75': {color: 'green'}
					},
					min: 0,
					max: 5,
					// chartUseMaxMin:true,
					unitScale: .001,
					offset: 0,
					offsetScale: 1,
					displayname: "Solar Voltage",
					units:"V",
					default: 0,
					value: 0,
					epochMs:0,
					values: [],
					epochMsList:[],
					collate: true,
				},
				systemTemperatureC: {
					unitType: UnitTypes.CELSIUS,
					order: 0,
					chartValid:true,
					gaugeValid:true,
					tableValid: true,
					thresholds: {
						'0': {color: 'blue'},'13': {color: 'purple'},'17': {color: 'green'},'27': {color: 'orangeRed'},'30': {color: 'red'}
					},
					min:0,
					max: 55,
					// chartUseMaxMin:true,
					unitScale: 1,
					offset: 0,
					offsetScale: 1,
					displayname: "System Temperature",
					units:"C",
					default: 0,
					value: 0,
					epochMs:0,
					values: [],
					epochMsList:[],
					collate: true,
				},
				connectedCell: {
					rssi: {
						unitType: UnitTypes.RSSI,
						order: 0,
						chartValid:true,
						gaugeValid:false,
						tableValid: true,
						thresholds: {
							'0': {color: 'red'},'.3': {color: 'orange'},'.4': {color: 'orangeRed'},'.5': {color: 'green'}
						},
						min: -140,
						max: -50,
						chartUseMaxMin:true,
						unitScale: 1,
						offset: 0,
						offsetScale: 1,
						displayname: "RSSI",
						units:"dBm",
						default: 0,
						value: 0,
						epochMs:0,
						values: [],
						epochMsList:[],
						collate: true,
					}
				},
				currentLoraConfig:{
					rssi: {
						unitType: UnitTypes.RSSI,
						order: 0,
						chartValid:true,
						gaugeValid:false,
						tableValid: true,
						thresholds: {
							'0': {color: 'red'},'.3': {color: 'orange'},'.4': {color: 'orangeRed'},'.5': {color: 'green'}
						},
						min: -140,
						max: -50,
						chartUseMaxMin:true,
						unitScale: 1,
						offset: 0,
						offsetScale: 1,
						displayname: "RSSI",
						units:"dBm",
						default: 0,
						value: 0,
						epochMs:0,
						values: [],
						epochMsList:[],
						collate: true,
					}
				}
			},
			environment :{
				
				barometricPa: {
					unitType: UnitTypes.PASCAL,
					order:4,
					chartValid:true,
					gaugeValid:true,
					tableValid: true,
					thresholds: {
						'0': {color: 'red'},'.3': {color: 'orange'},'.4': {color: 'orangeRed'},'.5': {color: 'green'}
					},
					min: 0.5,
					max: 1.1,
					// chartUseMaxMin:true,
					unitScale: 0.001,
					offset: 0,
					offsetScale: 1,
					displayname: "Barometric",
					units:"kPa",
					default: 0,
					value: 0,
					epochMs:0,
					values: [],
					epochMsList:[],
					collate: true,
				},
				humidityRh: {
					unitType: UnitTypes.RELATIVE_HUMIDITY,
					order:5,
					chartValid:true,
					gaugeValid:true,
					tableValid: true,
					thresholds: {
						'0': {color: 'green'},'70': {color: 'orange'},'80': {color: 'orangeRed'},'90': {color: 'red'}
					},
					min:0,
					max: 100,
					chartUseMaxMin:true,
					unitScale: 1,
					offset: 0,
					offsetScale: 1,
					displayname: "Relative Humidity",
					units:"%",
					default: 0,
					value: 0,
					epochMs:0,
					values: [],
					epochMsList:[],
					collate: true,
				},
				temperatureMillidegreeC:{
					unitType: UnitTypes.MILLICELSIUS,
					order:3,
					chartValid:true,
					gaugeValid:true,
					tableValid: true,
					thresholds: {
						'0': {color: 'blue'},'13': {color: 'purple'},'17': {color: 'green'},'27': {color: 'orangeRed'},'30': {color: 'red'}
					},
					min:0,
					max: 55,
					// chartUseMaxMin:true,
					unitScale: .001,
					offset: -3000,
					offsetScale: 1,
					displayname: "Temperature",
					units:"C",
					default: 0,
					value: 0,
					epochMs:0,
					values: [],
					epochMsList:[],
					collate: true,
				},
				cO2Ppm: {
					unitType: UnitTypes.PPM,
					order:1,
					chartValid:true,
					gaugeValid:true,
					tableValid: true,
					thresholds: {
						'0': {color: 'green'},'700': {color: 'orange'},'1000': {color: 'orangeRed'},'1100': {color: 'red'}
					},
					min:0,
					max: 10000,
					// chartUseMaxMin:true,
					unitScale: 1,
					offset: 0,
					offsetScale: 1,
					displayname: "CO2",
					units:"ppm",
					default: 0,
					value: 0,
					epochMs:0,
					collate: true,
					values: [],
					epochMsList:[],
				}, 
				pmsensor : {
					pm10Standard:{
						unitType: UnitTypes.UG_M3,
						order:1,
						chartValid:true,
						gaugeValid:true,
						thresholds:{
							'0': {color: 'green'},'30': {color: 'orange'},'40': {color: 'orangeRed'},'50': {color: 'red'}
						},
						min:0,
						max: 100,
						// chartUseMaxMin:true,
						unitScale: 1,
						offset: 0,
						offsetScale: 1,
						displayname: "Particulate Matter 1.0μ",
						units:"μg/m3",
						default: 0,
						value: 0,
						epochMs:0,
						values: [],
						epochMsList:[],
						collate: true,
					},
					pm25Standard:{
						unitType: UnitTypes.UG_M3,
						order:2,
						chartValid:true,
						gaugeValid:true,
						tableValid: true,
						thresholds:{
							'0': {color: 'green'},'15': {color: 'orange'},'20': {color: 'orangeRed'},'30': {color: 'red'}
						},
						min:0,
						max: 50,
						// chartUseMaxMin:true,
						unitScale: 1,
						offset: 0,
						offsetScale: 1,
						displayname: "Particulate Matter 2.5μ",
						units:"μg/m3",
						default: 0,
						value: 0,
						epochMs:0,
						values: [],
						epochMsList:[],
						collate: true,
					}
				},
				wind :{
					windDirectionDegrees :{
						UnitType: UnitTypes.UNKNOWN,
						order: 0,
						chartValid:true,
						gaugeValid:true,
						tableValid: true,
						min: 0,
						max: 360,
						unitScale: 1,
						offset: 0,
						offsetScale: 1,
						displayname: "Wind Direction",
						units:"°",
						default: 0,
						value: 0,
						epochMs:0,
						values: [],
						epochMsList:[],
						collate: true,
					},
					windSpeedCmS :{
						UnitType: UnitTypes.UNKNOWN,
						order: 0,
						chartValid:true,
						gaugeValid:true,
						tableValid: true,
						thresholds:{
							'0': {color: 'green'},'36': {color: 'orange'},'72': {color: 'orangeRed'},'150': {color: 'red'}
						},
						min: 0,
						max: 1000,
						unitScale: .01,
						offset: 0,
						offsetScale: 1,
						displayname: "Wind Speed",
						units:"m/s",
						default: 0,
						value: 0,
						epochMs:0,
						values: [],
						epochMsList:[],
						collate: true,
					},
					windDirectionAveDegrees :{
						UnitType: UnitTypes.UNKNOWN,
						order: 0,
						chartValid:true,
						gaugeValid:false,
						tableValid: true,
						min: 0,
						max: 360,
						unitScale: 1,
						offset: 0,
						offsetScale: 1,
						displayname: "Wind Direction Average (2 minute)",
						units:"°",
						default: 0,
						value: 0,
						epochMs:0,
						values: [],
						epochMsList:[],
						collate: true,
					},
					windSpeedAveCmS :{
						UnitType: UnitTypes.UNKNOWN,
						order: 0,
						chartValid:true,
						gaugeValid:false,
						tableValid: true,
						thresholds:{
							'0': {color: 'green'},'36': {color: 'orange'},'72': {color: 'orangeRed'},'150': {color: 'red'}
						},
						min: 0,
						max: 1000,
						unitScale: .01,
						offset: 0,
						offsetScale: 1,
						displayname: "Wind Speed Average (2 minute)",
						units:"m/s",
						default: 0,
						value: 0,
						epochMs:0,
						values: [],
						epochMsList:[],
						collate: true,
					}
				}
			},
			// vendorData.data.industrial.valve.batteryVoltageCentivolts
			vendorData: {
				data: {
					radationData: {
						gammaData: {
							gammaTotalGrossCounts: {
								// 16
								UnitType: UnitTypes.UNKNOWN,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'green'},'20': {color: 'orange'},'35': {color: 'orangeRed'},'50': {color: 'red'}
								},
								min: 0,
								max: 20,
								unitScale: 1,
								offset: 0,
								offsetScale: 1,
								displayname: "Gamma Counts",
								units:"CPS",
								order:3,
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
						 	gammaAlarmCountThreshold: {
								// 56
								UnitType: UnitTypes.UNKNOWN,
								order: 0,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'green'},'4': {color: 'orange'},'5': {color: 'orangeRed'},'6': {color: 'red'}
								},
								min: 0,
								max: 5000,
								unitScale: 1,
								offset: 0,
								offsetScale: 1,
								displayname: "Gamma Alarm Threshold",
								units:"CPS",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
						 	gammaExposureRate: {
								// 40000000,
								UnitType: UnitTypes.UNKNOWN,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'green'},'7': {color: 'orange'},'10': {color: 'orangeRed'},'2000': {color: 'red'}
								},
								min: 0,
								max: 20,
								unitScale: 1e-7,
								offset: 0,
								order:1,
								offsetScale: 1,
								displayname: "Dosage",
								units:"uR/h",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
						},
						neutronData:{
							neutronTotalGrossCounts1E1:{
								// 16
								UnitType: UnitTypes.UNKNOWN,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'green'},'20': {color: 'orange'},'35': {color: 'orangeRed'},'50': {color: 'red'}
								},
								min: 0,
								max: 20,
								unitScale: 1e-1,
								offset: 0,
								offsetScale: 1,
								displayname: "Neutron Counts",
								units:"CPS",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
						}
					},
					
					industrial : {
						vibration: {
							adxlRms:{
								UnitType: UnitTypes.UNKNOWN,
								order: 0,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'green'},'4': {color: 'orange'},'5': {color: 'orangeRed'},'6': {color: 'red'}
								},
								min: 0,
								max: 20,
								unitScale: .01,
								offset: 0,
								offsetScale: 1,
								displayname: "ADXL RMS",
								units:"RMS(G)",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
							adxlMax:{
								UnitType: UnitTypes.UNKNOWN,
								order: 0,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'green'},'4': {color: 'orange'},'5': {color: 'orangeRed'},'6': {color: 'red'}
								},
								min: 0,
								max: 100,
								unitScale: .01,
								offset: 0,
								offsetScale: 1,
								displayname: "ADXL MAX",
								units:"G",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							}
						},
						valve: {
							batteryVoltageCentivolts : {
								unitType: UnitTypes.CENTIVOLTS,
								order: 0,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'red'},'2.9': {color: 'orange'},'3.0': {color: 'orangeRed'},'3.3': {color: 'green'}
								},
								min: 2.1,
								max: 3.6,
								chartUseMaxMin:true,
								unitScale: .01,
								offset: 0,
								offsetScale: 1,
								displayname: "Valve Battery Voltage",
								units:"V",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
							// vendorData.data.industrial.valve.status
							status:{
								UnitType: UnitTypes.UNKNOWN,
								order: 0,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'red'},'1': {color: 'green'}
								},
								min: 0,
								max: 1,
								unitScale: 1,
								offset: 0,
								offsetScale: 1,
								displayname: "Status",
								units:"",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
							rawMag:{
								UnitType: UnitTypes.UNKNOWN,
								order: 0,
								chartValid:true,
								gaugeValid:true,
								tableValid: true,
								thresholds: {
									'0': {color: 'red'},'1': {color: 'green'}
								},
								min: 0,
								max: 1,
								unitScale: 1,
								offset: 0,
								offsetScale: 1,
								displayname: "Raw Mag",
								units:"",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							}
						},
						strain : {
							rawTemperature : {
								UnitType: UnitTypes.UNKNOWN,
								order: 0,
								chartValid:true,
								gaugeValid:false,
								tableValid: true,
								thresholds: {
									'0': {color: 'red'},'1': {color: 'green'}
								},
								chartUseMaxMin:false,
								min: 0,
								max: 4096,
								unitScale: 1,
								offset: 0,
								offsetScale: 1,
								displayname: "Raw Temperature",
								units:"",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
							rawStrain : {
								UnitType: UnitTypes.UNKNOWN,
								order: 0,
								chartValid:true,
								gaugeValid:false,
								tableValid: true,
								thresholds: {
									'0': {color: 'red'},'1': {color: 'green'}
								},
								chartUseMaxMin:false,
								min: 0,
								max: 4096,
								unitScale: 1,
								offset: 0,
								offsetScale: 1,
								displayname: "Raw Strain",
								units:"",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							},
							rawStrainDeltaPerMinute : {
								UnitType: UnitTypes.UNKNOWN,
								order: 0,
								chartValid:true,
								gaugeValid:false,
								tableValid: true,
								thresholds: {
									'0': {color: 'red'},'1': {color: 'green'}
								},
								chartUseMaxMin:false,
								min: 0,
								max: 1,
								unitScale: 1,
								offset: 0,
								offsetScale: 1,
								displayname: "Strain Delta / Minute",
								units:"",
								default: 0,
								value: 0,
								epochMs:0,
								values: [],
								epochMsList:[],
								collate: true,
							}
						},
					}
				}
			},
			gpsScanRecord : {
				velocityKmh: {
					UnitType: UnitTypes.UNKNOWN,
					order: 0,
					chartValid:true,
					gaugeValid:false,
					tableValid: true,
					thresholds: {
						'0': {color: 'red'},'.3': {color: 'orange'},'.4': {color: 'orangeRed'},'.5': {color: 'green'}
					},
					chartUseMaxMin:false,
					unitScale: 1,
					offset: 0,
					offsetScale: 1,
					displayname: "Velocity",
					units:"km/h",
					default: 0,
					value: 0,
					epochMs:0,
					values: [],
					epochMsList:[],
					collate: true,
	 			}
			},
		},
	};
	traverseForDisplayNames(obj){
		var object = {};
		for (const key in obj) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				if ("displayname" in obj[key]) {
					object[key] = obj[key]["displayname"]
				}
				if("priority" in obj[key]){
					object[key]["priority"] = obj[key]["priority"];
				}
				object = {...object, ...this.traverseForDisplayNames(obj[key])}; // Recursive call for nested objects
			}
		}
		return object;
	}
	public getDisplayNamesByOrder(){
		var fmt = structuredClone(this.modelFormatter);
		var displayNames = this.traverseForDisplayNames(fmt);
		console.log(displayNames);
	}
	// value only - no formatting object information. only the timestamp/value
	private async traverseModelForValues(compareObj, finalObj, obj={}, res = {}, extraKey = '', telemetries?:TelemetryViewModelImplemented[], valueOnly=false) {
		var keys = Object.keys(obj);
		for (let index = 0; index < keys.length; index++) {
			const key = keys[index];
			if(typeof obj[key] !== 'object'){
				if(compareObj[key]){
					var foundValue = obj[key];
					var compare:modelFormatted = structuredClone(compareObj[key]);
					if(!valueOnly){
						finalObj[key] = compare;
					}
					else {
						finalObj[key] = {};
					}
					var offset = 0
					var offsetScale = 1;
					if(compare.offset){
						offset = compare.offset;
					}
					if(compare.offsetScale){
						offsetScale = compare.offsetScale;
					}
					if(compare.unitScale){
						// check if foundValue is a bigint
						if(typeof foundValue === 'bigint'){
							finalObj[key].value = (((foundValue*BigInt(offsetScale)))+BigInt(offset)) * BigInt(compare.unitScale);
						}
						else{
							finalObj[key].value = ((foundValue*offsetScale)+offset) * compare.unitScale;
						}
					}
					else {
						finalObj[key].value = ((foundValue*offsetScale)+offset);
					}
					if(finalObj[key].unitType){
						/// TODO :: Pull this from common settings for f/c degrees
						if(finalObj[key].unitType == UnitTypes.CELSIUS || finalObj[key].unitType == UnitTypes.MILLICELSIUS){
							finalObj[key].value = finalObj[key].value * 1.8 + 32;
							finalObj[key].units = "F";
							if(finalObj[key].thresholds){
								var adjustedThresholds = {};
								var thresholdKeys = Object.keys(finalObj[key].thresholds);
								for (let index = 0; index < thresholdKeys.length; index++) {
									const thresholdKey = thresholdKeys[index];
									var thresholdValue = parseFloat(thresholdKey) * 1.8 + 32;
									adjustedThresholds[thresholdValue] = finalObj[key].thresholds[thresholdKey];
								}
								finalObj[key].thresholds = adjustedThresholds;
							}
							if(finalObj[key].max){
								finalObj[key].max = finalObj[key].max * 1.8 + 32;
							}
							if(finalObj[key].min){
								finalObj[key].min = finalObj[key].min * 1.8 + 32;
							}
						}
					}
					var finalKey = extraKey+key
					if(telemetries){
						if(telemetries.length>1){
							for (let index = 0; index < telemetries.length; index++) {
								const telemetry:TelemetryViewModelImplemented = telemetries[index];
								if(telemetry.model?.telemetry){
									var value = _.get(telemetry.model, finalKey);
									if(value){
										if(telemetry.model.telemetry.epochMs && finalObj[key].collate == true){
											if(!finalObj[key].epochMsList){
												finalObj[key].epochMsList = [];
											}
											finalObj[key].epochMsList.push(Number(telemetry.model.telemetry.epochMs));
											if(!finalObj[key].values){
												finalObj[key].values = [];
											}
											var offset = 0
											var offsetScale = 1;
											if(compare.offset){
												offset = compare.offset;
											}
											if(compare.offsetScale){
												offsetScale = compare.offsetScale;
											}

											if(compare.unitScale){
												if(finalObj[key].unitType){
													if(finalObj[key].unitType == UnitTypes.CELSIUS || finalObj[key].unitType == UnitTypes.MILLICELSIUS){
														finalObj[key].values.push(((value*offsetScale)+offset) * compare.unitScale* 1.8 + 32);
														continue;
													}
												}
												finalObj[key].values.push(((value*offsetScale)+offset) * compare.unitScale);
											}
											else {
												finalObj[key].values.push(((value*offsetScale)+offset))
											}
										}
									}
								}
							}
						}
					}
				}
			}
			else{
				if(compareObj[key]){
					finalObj[key] = {};
					await this.traverseModelForValues(compareObj[key], finalObj[key], obj[key], res, `${extraKey}${key}.`, telemetries);
				}
			};
		}
		return finalObj;
	}
	traverseObject(obj, field, value) {
		for (const key in obj) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				if (field in obj[key]) {
					obj[key][field] = value; // Change the value of the field
				}
				this.traverseObject(obj[key], key, value); // Recursive call for nested objects
			}
		}
	}
	
	private updateDeviceSpecificModelFormatter():any{
		if(this.model.device?.sensorsConfiguration){
			var sensorsConfiguration = this.model.device.sensorsConfiguration;
			if(sensorsConfiguration.offsetEnvironmentTemperatureMillidegreeC){
				this.modelFormatter.telemetry.environment.temperatureMillidegreeC.offset = sensorsConfiguration.offsetEnvironmentTemperatureMillidegreeC;
			}
			if(sensorsConfiguration.offsetEnvironmentTemperatureScaleFactor){
				this.modelFormatter.telemetry.environment.temperatureMillidegreeC.offsetScale = sensorsConfiguration.offsetEnvironmentTemperatureScaleFactor;
			}
		}
	}

	// Call the function with the modelFormatter object
	async  getFormattedDerrivedTelemeteryForTable( ) : Promise<any>{
		return new Promise( async (resolve, reject) => {
			this.updateDeviceSpecificModelFormatter();
			var derrivedTelmetryFormatted = structuredClone({},this.modelFormatter.telemetry);
			this.traverseObject(derrivedTelmetryFormatted, "collate", false)
			if(this.model.derivedTelemetry){
				console.log("this.model.derivedTelemetry: ", this.model.derivedTelemetry)
				await this.traverseModelForValues(this.modelFormatter.telemetry, derrivedTelmetryFormatted, this.model.derivedTelemetry, {}, "telemetry.", );
			}
			resolve(derrivedTelmetryFormatted);
		})
	}
	async getFormattedDerrivedTelemetery( ) : Promise<any> {
		return new Promise( async (resolve, reject) => {
			this.updateDeviceSpecificModelFormatter();
			var derrivedTelmetryFormatted = structuredClone({},this.modelFormatter.telemetry);
			if(this.model.derivedTelemetry){
				console.log("this.model.derivedTelemetry: ", this.model.derivedTelemetry)
				await this.traverseModelForValues(this.modelFormatter.telemetry, derrivedTelmetryFormatted, this.model.derivedTelemetry, {}, "telemetry.", );
			}
			console.log("derrivedTelmetryFormatted: ", derrivedTelmetryFormatted)
			resolve(derrivedTelmetryFormatted);
		});
	}

	async getFormattedEventHistory(telemetries:TelemetryViewModelImplemented[]) : Promise<eventFormatted[]> {
		return new Promise( async (resolve,reject) => {
			var formatted_events: eventFormatted[] = [];
			telemetries.forEach( (telemetry) => {
				if(telemetry.model?.telemetry?.system?.events){
					telemetry.model?.telemetry?.system?.events.events.forEach( (event) => {
						var event_id = event >>  22;
						var event_model = eventTypes.find(event => event.id == event_id);
						if(event_model){
							var viewobject = telemetry.toViewObject();
							var proto = viewobject.protobuf;
							var locale: Intl.DateTimeFormatOptions = { year: "numeric", month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
							var formatted_event: eventFormatted = {
								start_time: new Date(Number(proto.telemetry.epochMs)).toLocaleDateString('en-US', locale),
								stop_time: new Date(Number(proto.telemetry.epochMs)+59000).toLocaleDateString('en-US', locale),
								event_id: event_id,
								event_name: event_model.name,
								event_data: 0,
							}
							formatted_events.push(formatted_event);
						}
					});
				}
			});
			resolve(formatted_events);	
		});
	}

	async hasEvents(telemetries:TelemetryViewModelImplemented[]) : Promise<boolean> {
		return new Promise( async (resolve,reject) => {
			var has_events = false;
			telemetries.forEach( (telemetry) => {
				if(telemetry.model?.telemetry?.system?.events){
					has_events = true;
				}
			});
			resolve(has_events);	
		});
	}

	private normalizeFormattedTelemetryModel(model:any){
		// If we want to normalize and sync up the epochs to be the same for all
		// We'll need to traverse one more time and fill in null and times to the collected sets
	}

	async getFormattedTelemeteryHistory(telemetries:TelemetryViewModelImplemented[]) : Promise<any> {
		return new Promise( async (resolve,reject) => {
			this.updateDeviceSpecificModelFormatter();
			var derrivedTelmetryFormatted = structuredClone({},this.modelFormatter.telemetry);
			await this.traverseModelForValues(this.modelFormatter.telemetry, derrivedTelmetryFormatted, this.model.derivedTelemetry, {}, "telemetry.", telemetries);
			// console.log("derrivedTelmetryFormatted: ", derrivedTelmetryFormatted)
			// for (let index = 0; index < telemetries.length; index++) {
			// 	const telemetry = telemetries[index];
			// 	if(telemetry.model?.telemetry?.epochMs){
			// 		if(!derrivedTelmetryFormatted["allEpochMs"]){
			// 			derrivedTelmetryFormatted["allEpochMs"] = [];
			// 		}
			// 		derrivedTelmetryFormatted["allEpochMs"].push(Number(telemetry.model.telemetry.epochMs));
			// 	}
			// }
			console.log("getFormattedTelemeteryHistory: ", derrivedTelmetryFormatted)
			resolve(derrivedTelmetryFormatted);
		})
	}

	// 1) get dbii situated (so all remote keys are loaded into local ram)
	// 2) get the telemetries loaded between start and end epoch times (start is oldest, end is latest time)
	// 3) return after those telemetries are synced to local db and are ready to be querired
	initSync(starteEpochMs?:number, endEpochMs?:number) : Promise<boolean> {
		if(this.services.data && this.model.device) {
			var urn:string = this.getTelemeryUrn();
			return new Promise( async (resolve, reject) => {
				await this.services.data?.initSync(urn, starteEpochMs, endEpochMs, undefined, this.updater).catch( (err) => reject(err));
				resolve(true);
			})
		}
		if(this.services.logger) this.services.logger.error(this.loggerOptions, "smallSync:DataService missing");
		return Promise.reject(this.loggerOptions.prefix+":smallSync:DataService missing");
	}

	// Sync the rest of the documents, after the telemetry is loaded
	// depending on the platform, this will sync a limit, or all of the documents
	sync(starteEpochMs?:number, endEpochMs?:number) : Promise<boolean>{
		// first, update our local db's id list
		if(this.services.data && this.model.device) {
			var urn:string = this.getTelemeryUrn();
			return new Promise( async (resolve, reject) => {
				console.log("Starting sync for: ", urn);
				await this.services.data?.sync(urn, starteEpochMs, endEpochMs, undefined, this.updater).catch( (err) => reject(err));
				console.log("ENDING sync for: ", urn);
				resolve(true);
			})
		}
		if(this.services.logger) this.services.logger.error(this.loggerOptions, "smallSync:DataService missing");
		return Promise.reject(this.loggerOptions.prefix+":smallSync:DataService missing");
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	getTooltipHtml(d){
		var tt:MapTooltip = {}
		if(d != undefined){
			console.log(d)
			tt = {
				html: `<h2>${this.getDisplayName() || "Device"}</h2><div>${this.getDisplayUuid()}</div><div>Update Time: ${new Date(Number(d.timestamp)).toLocaleString()}</div>`
			}
		}
		else {
			tt = {
				html: `<h2>${this.getDisplayName() || "Device"}</h2><div>${this.getDisplayUuid()}</div><div>Update Time: ${new Date(Number(this.model.derivedTelemetry?.epochMs)).toLocaleString()}</div>`
			}
		}

		return tt;
	}

	public shouldSaveTelemetry( telemetry:Telemetry ) : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			if(this.model.derivedTelemetry?.epochMs){
				// if more than 10 seconds old
				if(telemetry.epochMs > this.model.derivedTelemetry.epochMs + 10000n){
					resolve(true);
				}
			}
			resolve(false);
		});
	}
	

}