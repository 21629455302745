
import { SteDeviceViewModelImplemented, } from "./ste.vmi";

export class StePagerDeviceViewModelImplemented extends SteDeviceViewModelImplemented {

	public static getStaticTitle():string{ // so we can access this w/o an instance i guess
		return super.getStaticTitle() + " RadPager"
	}

	public getPagePath() : string {
		console.log("this.services.settings?.SETTINGS.APP_ID: ", this.services.settings?.SETTINGS.APP_ID)
		if(this.services.settings?.SETTINGS.APP_ID != 16){
			return super.getPagePath();
		}
		if(this.model.device){
			return "vendors/ste/ste-device/" + this.model.device.uuid;
		}
		return "vendors/ste/ste-device/" + 0;
	}

	public getTitle():string{ // so we can access this w/o an instance i guess
		return StePagerDeviceViewModelImplemented.getStaticTitle();
	}

	public getDisplayName() : string {
		return this.getTitle()
	}

	public getDisplayUuid() : string {
		if(this.model.device?.serial){
			return "SN: "+this.model.device.serial
		}
		return "SN: n/a";
	}

	public thumbnailImagePath:string = "assets/app/ste/radPager.png";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public getSubTitleDescription():string{
		if(this.model.device?.serial){
			return "SN: "+this.model.device.serial
		}
		else {
			return "SN: n/a";
		}
	}

}