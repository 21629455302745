// source: app/viewmodel/v1/app.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var nanopb_pb = require('../../../nanopb_pb.js');
goog.object.extend(proto, nanopb_pb);
var app_grpc_v1_auth_pb = require('../../../app/grpc/v1/auth_pb.js');
goog.object.extend(proto, app_grpc_v1_auth_pb);
var app_grpc_v1_stream_pb = require('../../../app/grpc/v1/stream_pb.js');
goog.object.extend(proto, app_grpc_v1_stream_pb);
var app_model_v1_data_pb = require('../../../app/model/v1/data_pb.js');
goog.object.extend(proto, app_model_v1_data_pb);
goog.exportSymbol('proto.app.viewmodel.v1.APP_CONSTANTS', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.AppViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.ChannelViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.DashboardViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.PeopleViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.PeopleViewModelRequest', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.PersonViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.SensorViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.SensorViewModelRequest', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.SensorsViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.TagViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.TagsViewModel', null, global);
goog.exportSymbol('proto.app.viewmodel.v1.TelemetryViewModel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.TelemetryViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.viewmodel.v1.TelemetryViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.TelemetryViewModel.displayName = 'proto.app.viewmodel.v1.TelemetryViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.SensorViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.viewmodel.v1.SensorViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.SensorViewModel.displayName = 'proto.app.viewmodel.v1.SensorViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.SensorViewModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.viewmodel.v1.SensorViewModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.SensorViewModelRequest.displayName = 'proto.app.viewmodel.v1.SensorViewModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.SensorsViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.SensorsViewModel.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.SensorsViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.SensorsViewModel.displayName = 'proto.app.viewmodel.v1.SensorsViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.PersonViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.PersonViewModel.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.PersonViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.PersonViewModel.displayName = 'proto.app.viewmodel.v1.PersonViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.PeopleViewModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.PeopleViewModelRequest.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.PeopleViewModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.PeopleViewModelRequest.displayName = 'proto.app.viewmodel.v1.PeopleViewModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.PeopleViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.PeopleViewModel.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.PeopleViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.PeopleViewModel.displayName = 'proto.app.viewmodel.v1.PeopleViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.ChannelViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.ChannelViewModel.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.ChannelViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.ChannelViewModel.displayName = 'proto.app.viewmodel.v1.ChannelViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.DashboardViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.DashboardViewModel.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.DashboardViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.DashboardViewModel.displayName = 'proto.app.viewmodel.v1.DashboardViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.TagViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.TagViewModel.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.TagViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.TagViewModel.displayName = 'proto.app.viewmodel.v1.TagViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.TagsViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.TagsViewModel.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.TagsViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.TagsViewModel.displayName = 'proto.app.viewmodel.v1.TagsViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.viewmodel.v1.AppViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.viewmodel.v1.AppViewModel.repeatedFields_, null);
};
goog.inherits(proto.app.viewmodel.v1.AppViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.viewmodel.v1.AppViewModel.displayName = 'proto.app.viewmodel.v1.AppViewModel';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.TelemetryViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.TelemetryViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.TelemetryViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.TelemetryViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    authentication: (f = msg.getAuthentication()) && app_grpc_v1_auth_pb.Auth.toObject(includeInstance, f),
    telemetry: (f = msg.getTelemetry()) && app_model_v1_data_pb.Telemetry.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.TelemetryViewModel}
 */
proto.app.viewmodel.v1.TelemetryViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.TelemetryViewModel;
  return proto.app.viewmodel.v1.TelemetryViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.TelemetryViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.TelemetryViewModel}
 */
proto.app.viewmodel.v1.TelemetryViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_grpc_v1_auth_pb.Auth;
      reader.readMessage(value,app_grpc_v1_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuthentication(value);
      break;
    case 2:
      var value = new app_model_v1_data_pb.Telemetry;
      reader.readMessage(value,app_model_v1_data_pb.Telemetry.deserializeBinaryFromReader);
      msg.setTelemetry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.TelemetryViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.TelemetryViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.TelemetryViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.TelemetryViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthentication();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_grpc_v1_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getTelemetry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      app_model_v1_data_pb.Telemetry.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.grpc.v1.Auth authentication = 1;
 * @return {?proto.app.grpc.v1.Auth}
 */
proto.app.viewmodel.v1.TelemetryViewModel.prototype.getAuthentication = function() {
  return /** @type{?proto.app.grpc.v1.Auth} */ (
    jspb.Message.getWrapperField(this, app_grpc_v1_auth_pb.Auth, 1));
};


/**
 * @param {?proto.app.grpc.v1.Auth|undefined} value
 * @return {!proto.app.viewmodel.v1.TelemetryViewModel} returns this
*/
proto.app.viewmodel.v1.TelemetryViewModel.prototype.setAuthentication = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.TelemetryViewModel} returns this
 */
proto.app.viewmodel.v1.TelemetryViewModel.prototype.clearAuthentication = function() {
  return this.setAuthentication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.TelemetryViewModel.prototype.hasAuthentication = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional app.model.v1.Telemetry telemetry = 2;
 * @return {?proto.app.model.v1.Telemetry}
 */
proto.app.viewmodel.v1.TelemetryViewModel.prototype.getTelemetry = function() {
  return /** @type{?proto.app.model.v1.Telemetry} */ (
    jspb.Message.getWrapperField(this, app_model_v1_data_pb.Telemetry, 2));
};


/**
 * @param {?proto.app.model.v1.Telemetry|undefined} value
 * @return {!proto.app.viewmodel.v1.TelemetryViewModel} returns this
*/
proto.app.viewmodel.v1.TelemetryViewModel.prototype.setTelemetry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.TelemetryViewModel} returns this
 */
proto.app.viewmodel.v1.TelemetryViewModel.prototype.clearTelemetry = function() {
  return this.setTelemetry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.TelemetryViewModel.prototype.hasTelemetry = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.SensorViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.SensorViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.SensorViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    authentication: (f = msg.getAuthentication()) && app_grpc_v1_auth_pb.Auth.toObject(includeInstance, f),
    streamState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    peopleViewModel: (f = msg.getPeopleViewModel()) && proto.app.viewmodel.v1.PeopleViewModel.toObject(includeInstance, f),
    isonline: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    lastUpdateEpochMs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    latestLoadEpochMs: jspb.Message.getFieldWithDefault(msg, 6, 0),
    oldestLoadEpochMs: jspb.Message.getFieldWithDefault(msg, 7, 0),
    oldestEpochMs: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalTelemetryCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sensor: (f = msg.getSensor()) && app_model_v1_data_pb.Sensor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.SensorViewModel}
 */
proto.app.viewmodel.v1.SensorViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.SensorViewModel;
  return proto.app.viewmodel.v1.SensorViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.SensorViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.SensorViewModel}
 */
proto.app.viewmodel.v1.SensorViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_grpc_v1_auth_pb.Auth;
      reader.readMessage(value,app_grpc_v1_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuthentication(value);
      break;
    case 2:
      var value = /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (reader.readEnum());
      msg.setStreamState(value);
      break;
    case 3:
      var value = new proto.app.viewmodel.v1.PeopleViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.PeopleViewModel.deserializeBinaryFromReader);
      msg.setPeopleViewModel(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsonline(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdateEpochMs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLatestLoadEpochMs(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOldestLoadEpochMs(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOldestEpochMs(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalTelemetryCount(value);
      break;
    case 10:
      var value = new app_model_v1_data_pb.Sensor;
      reader.readMessage(value,app_model_v1_data_pb.Sensor.deserializeBinaryFromReader);
      msg.setSensor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.SensorViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.SensorViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.SensorViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthentication();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_grpc_v1_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getStreamState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPeopleViewModel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.viewmodel.v1.PeopleViewModel.serializeBinaryToWriter
    );
  }
  f = message.getIsonline();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLastUpdateEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getLatestLoadEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getOldestLoadEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getOldestEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getTotalTelemetryCount();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getSensor();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      app_model_v1_data_pb.Sensor.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.grpc.v1.Auth authentication = 1;
 * @return {?proto.app.grpc.v1.Auth}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getAuthentication = function() {
  return /** @type{?proto.app.grpc.v1.Auth} */ (
    jspb.Message.getWrapperField(this, app_grpc_v1_auth_pb.Auth, 1));
};


/**
 * @param {?proto.app.grpc.v1.Auth|undefined} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
*/
proto.app.viewmodel.v1.SensorViewModel.prototype.setAuthentication = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.clearAuthentication = function() {
  return this.setAuthentication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.hasAuthentication = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional app.grpc.v1.STREAM_STATE stream_state = 2;
 * @return {!proto.app.grpc.v1.STREAM_STATE}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getStreamState = function() {
  return /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.grpc.v1.STREAM_STATE} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.setStreamState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional PeopleViewModel people_view_model = 3;
 * @return {?proto.app.viewmodel.v1.PeopleViewModel}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getPeopleViewModel = function() {
  return /** @type{?proto.app.viewmodel.v1.PeopleViewModel} */ (
    jspb.Message.getWrapperField(this, proto.app.viewmodel.v1.PeopleViewModel, 3));
};


/**
 * @param {?proto.app.viewmodel.v1.PeopleViewModel|undefined} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
*/
proto.app.viewmodel.v1.SensorViewModel.prototype.setPeopleViewModel = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.clearPeopleViewModel = function() {
  return this.setPeopleViewModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.hasPeopleViewModel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool isOnline = 4;
 * @return {boolean}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getIsonline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.setIsonline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional uint64 last_update_epoch_ms = 5;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getLastUpdateEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.setLastUpdateEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 latest_load_epoch_ms = 6;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getLatestLoadEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.setLatestLoadEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 oldest_load_epoch_ms = 7;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getOldestLoadEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.setOldestLoadEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 oldest_epoch_ms = 8;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getOldestEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.setOldestEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 total_telemetry_count = 9;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getTotalTelemetryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.setTotalTelemetryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional app.model.v1.Sensor sensor = 10;
 * @return {?proto.app.model.v1.Sensor}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.getSensor = function() {
  return /** @type{?proto.app.model.v1.Sensor} */ (
    jspb.Message.getWrapperField(this, app_model_v1_data_pb.Sensor, 10));
};


/**
 * @param {?proto.app.model.v1.Sensor|undefined} value
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
*/
proto.app.viewmodel.v1.SensorViewModel.prototype.setSensor = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.SensorViewModel} returns this
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.clearSensor = function() {
  return this.setSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.SensorViewModel.prototype.hasSensor = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.SensorViewModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.SensorViewModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.SensorViewModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authentication: (f = msg.getAuthentication()) && app_grpc_v1_auth_pb.Auth.toObject(includeInstance, f),
    telemetryCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startEpochMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endEpochMs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pagenumbersize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pagenumberrequested: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sensor: (f = msg.getSensor()) && app_model_v1_data_pb.Sensor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.SensorViewModelRequest;
  return proto.app.viewmodel.v1.SensorViewModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.SensorViewModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_grpc_v1_auth_pb.Auth;
      reader.readMessage(value,app_grpc_v1_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuthentication(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTelemetryCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartEpochMs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndEpochMs(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumbersize(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumberrequested(value);
      break;
    case 7:
      var value = new app_model_v1_data_pb.Sensor;
      reader.readMessage(value,app_model_v1_data_pb.Sensor.deserializeBinaryFromReader);
      msg.setSensor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.SensorViewModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.SensorViewModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.SensorViewModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthentication();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_grpc_v1_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getTelemetryCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStartEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getEndEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getPagenumbersize();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPagenumberrequested();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getSensor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      app_model_v1_data_pb.Sensor.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.grpc.v1.Auth authentication = 1;
 * @return {?proto.app.grpc.v1.Auth}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.getAuthentication = function() {
  return /** @type{?proto.app.grpc.v1.Auth} */ (
    jspb.Message.getWrapperField(this, app_grpc_v1_auth_pb.Auth, 1));
};


/**
 * @param {?proto.app.grpc.v1.Auth|undefined} value
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
*/
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.setAuthentication = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.clearAuthentication = function() {
  return this.setAuthentication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.hasAuthentication = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 telemetry_count = 2;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.getTelemetryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.setTelemetryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 start_epoch_ms = 3;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.getStartEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.setStartEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 end_epoch_ms = 4;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.getEndEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.setEndEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 pageNumberSize = 5;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.getPagenumbersize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.setPagenumbersize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 pageNumberRequested = 6;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.getPagenumberrequested = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.setPagenumberrequested = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional app.model.v1.Sensor sensor = 7;
 * @return {?proto.app.model.v1.Sensor}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.getSensor = function() {
  return /** @type{?proto.app.model.v1.Sensor} */ (
    jspb.Message.getWrapperField(this, app_model_v1_data_pb.Sensor, 7));
};


/**
 * @param {?proto.app.model.v1.Sensor|undefined} value
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
*/
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.setSensor = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.SensorViewModelRequest} returns this
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.clearSensor = function() {
  return this.setSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.SensorViewModelRequest.prototype.hasSensor = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.SensorsViewModel.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.SensorsViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.SensorsViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.SensorsViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    authentication: (f = msg.getAuthentication()) && app_grpc_v1_auth_pb.Auth.toObject(includeInstance, f),
    streamState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastUpdateEpochMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sensorViewModelsList: jspb.Message.toObjectList(msg.getSensorViewModelsList(),
    proto.app.viewmodel.v1.SensorViewModel.toObject, includeInstance),
    bleScannedViewModelsList: jspb.Message.toObjectList(msg.getBleScannedViewModelsList(),
    proto.app.viewmodel.v1.SensorViewModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.SensorsViewModel}
 */
proto.app.viewmodel.v1.SensorsViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.SensorsViewModel;
  return proto.app.viewmodel.v1.SensorsViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.SensorsViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.SensorsViewModel}
 */
proto.app.viewmodel.v1.SensorsViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_grpc_v1_auth_pb.Auth;
      reader.readMessage(value,app_grpc_v1_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuthentication(value);
      break;
    case 2:
      var value = /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (reader.readEnum());
      msg.setStreamState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdateEpochMs(value);
      break;
    case 4:
      var value = new proto.app.viewmodel.v1.SensorViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.SensorViewModel.deserializeBinaryFromReader);
      msg.addSensorViewModels(value);
      break;
    case 5:
      var value = new proto.app.viewmodel.v1.SensorViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.SensorViewModel.deserializeBinaryFromReader);
      msg.addBleScannedViewModels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.SensorsViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.SensorsViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.SensorsViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthentication();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_grpc_v1_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getStreamState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLastUpdateEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getSensorViewModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.viewmodel.v1.SensorViewModel.serializeBinaryToWriter
    );
  }
  f = message.getBleScannedViewModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.app.viewmodel.v1.SensorViewModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.grpc.v1.Auth authentication = 1;
 * @return {?proto.app.grpc.v1.Auth}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.getAuthentication = function() {
  return /** @type{?proto.app.grpc.v1.Auth} */ (
    jspb.Message.getWrapperField(this, app_grpc_v1_auth_pb.Auth, 1));
};


/**
 * @param {?proto.app.grpc.v1.Auth|undefined} value
 * @return {!proto.app.viewmodel.v1.SensorsViewModel} returns this
*/
proto.app.viewmodel.v1.SensorsViewModel.prototype.setAuthentication = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.SensorsViewModel} returns this
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.clearAuthentication = function() {
  return this.setAuthentication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.hasAuthentication = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional app.grpc.v1.STREAM_STATE stream_state = 2;
 * @return {!proto.app.grpc.v1.STREAM_STATE}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.getStreamState = function() {
  return /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.grpc.v1.STREAM_STATE} value
 * @return {!proto.app.viewmodel.v1.SensorsViewModel} returns this
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.setStreamState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint64 last_update_epoch_ms = 3;
 * @return {number}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.getLastUpdateEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.SensorsViewModel} returns this
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.setLastUpdateEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated SensorViewModel sensor_view_models = 4;
 * @return {!Array<!proto.app.viewmodel.v1.SensorViewModel>}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.getSensorViewModelsList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.SensorViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.SensorViewModel, 4));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.SensorViewModel>} value
 * @return {!proto.app.viewmodel.v1.SensorsViewModel} returns this
*/
proto.app.viewmodel.v1.SensorsViewModel.prototype.setSensorViewModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.viewmodel.v1.SensorViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.SensorViewModel}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.addSensorViewModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.viewmodel.v1.SensorViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.SensorsViewModel} returns this
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.clearSensorViewModelsList = function() {
  return this.setSensorViewModelsList([]);
};


/**
 * repeated SensorViewModel ble_scanned_view_models = 5;
 * @return {!Array<!proto.app.viewmodel.v1.SensorViewModel>}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.getBleScannedViewModelsList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.SensorViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.SensorViewModel, 5));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.SensorViewModel>} value
 * @return {!proto.app.viewmodel.v1.SensorsViewModel} returns this
*/
proto.app.viewmodel.v1.SensorsViewModel.prototype.setBleScannedViewModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.app.viewmodel.v1.SensorViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.SensorViewModel}
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.addBleScannedViewModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.app.viewmodel.v1.SensorViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.SensorsViewModel} returns this
 */
proto.app.viewmodel.v1.SensorsViewModel.prototype.clearBleScannedViewModelsList = function() {
  return this.setBleScannedViewModelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.PersonViewModel.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.PersonViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.PersonViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.PersonViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    authentication: (f = msg.getAuthentication()) && app_grpc_v1_auth_pb.Auth.toObject(includeInstance, f),
    streamState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isonline: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    lastUpdateEpochMs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    position: (f = msg.getPosition()) && app_model_v1_data_pb.GPSPosition.toObject(includeInstance, f),
    person: (f = msg.getPerson()) && app_model_v1_data_pb.Person.toObject(includeInstance, f),
    sensorsList: jspb.Message.toObjectList(msg.getSensorsList(),
    app_model_v1_data_pb.Sensor.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    app_model_v1_data_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.PersonViewModel}
 */
proto.app.viewmodel.v1.PersonViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.PersonViewModel;
  return proto.app.viewmodel.v1.PersonViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.PersonViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.PersonViewModel}
 */
proto.app.viewmodel.v1.PersonViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_grpc_v1_auth_pb.Auth;
      reader.readMessage(value,app_grpc_v1_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuthentication(value);
      break;
    case 2:
      var value = /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (reader.readEnum());
      msg.setStreamState(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsonline(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdateEpochMs(value);
      break;
    case 5:
      var value = new app_model_v1_data_pb.GPSPosition;
      reader.readMessage(value,app_model_v1_data_pb.GPSPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 6:
      var value = new app_model_v1_data_pb.Person;
      reader.readMessage(value,app_model_v1_data_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 7:
      var value = new app_model_v1_data_pb.Sensor;
      reader.readMessage(value,app_model_v1_data_pb.Sensor.deserializeBinaryFromReader);
      msg.addSensors(value);
      break;
    case 8:
      var value = new app_model_v1_data_pb.Channel;
      reader.readMessage(value,app_model_v1_data_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.PersonViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.PersonViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.PersonViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthentication();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_grpc_v1_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getStreamState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIsonline();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLastUpdateEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      app_model_v1_data_pb.GPSPosition.serializeBinaryToWriter
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      app_model_v1_data_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      app_model_v1_data_pb.Sensor.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      app_model_v1_data_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.grpc.v1.Auth authentication = 1;
 * @return {?proto.app.grpc.v1.Auth}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.getAuthentication = function() {
  return /** @type{?proto.app.grpc.v1.Auth} */ (
    jspb.Message.getWrapperField(this, app_grpc_v1_auth_pb.Auth, 1));
};


/**
 * @param {?proto.app.grpc.v1.Auth|undefined} value
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
*/
proto.app.viewmodel.v1.PersonViewModel.prototype.setAuthentication = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.clearAuthentication = function() {
  return this.setAuthentication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.hasAuthentication = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional app.grpc.v1.STREAM_STATE stream_state = 2;
 * @return {!proto.app.grpc.v1.STREAM_STATE}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.getStreamState = function() {
  return /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.grpc.v1.STREAM_STATE} value
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.setStreamState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool isOnline = 3;
 * @return {boolean}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.getIsonline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.setIsonline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional uint64 last_update_epoch_ms = 4;
 * @return {number}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.getLastUpdateEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.setLastUpdateEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional app.model.v1.GPSPosition position = 5;
 * @return {?proto.app.model.v1.GPSPosition}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.getPosition = function() {
  return /** @type{?proto.app.model.v1.GPSPosition} */ (
    jspb.Message.getWrapperField(this, app_model_v1_data_pb.GPSPosition, 5));
};


/**
 * @param {?proto.app.model.v1.GPSPosition|undefined} value
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
*/
proto.app.viewmodel.v1.PersonViewModel.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional app.model.v1.Person person = 6;
 * @return {?proto.app.model.v1.Person}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.getPerson = function() {
  return /** @type{?proto.app.model.v1.Person} */ (
    jspb.Message.getWrapperField(this, app_model_v1_data_pb.Person, 6));
};


/**
 * @param {?proto.app.model.v1.Person|undefined} value
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
*/
proto.app.viewmodel.v1.PersonViewModel.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated app.model.v1.Sensor sensors = 7;
 * @return {!Array<!proto.app.model.v1.Sensor>}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.getSensorsList = function() {
  return /** @type{!Array<!proto.app.model.v1.Sensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, app_model_v1_data_pb.Sensor, 7));
};


/**
 * @param {!Array<!proto.app.model.v1.Sensor>} value
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
*/
proto.app.viewmodel.v1.PersonViewModel.prototype.setSensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.app.model.v1.Sensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Sensor}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.addSensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.app.model.v1.Sensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.clearSensorsList = function() {
  return this.setSensorsList([]);
};


/**
 * repeated app.model.v1.Channel channels = 8;
 * @return {!Array<!proto.app.model.v1.Channel>}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.app.model.v1.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, app_model_v1_data_pb.Channel, 8));
};


/**
 * @param {!Array<!proto.app.model.v1.Channel>} value
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
*/
proto.app.viewmodel.v1.PersonViewModel.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.app.model.v1.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Channel}
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.app.model.v1.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.PersonViewModel} returns this
 */
proto.app.viewmodel.v1.PersonViewModel.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.PeopleViewModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.PeopleViewModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authentication: (f = msg.getAuthentication()) && app_grpc_v1_auth_pb.Auth.toObject(includeInstance, f),
    peopleViewModel: (f = msg.getPeopleViewModel()) && proto.app.viewmodel.v1.PeopleViewModel.toObject(includeInstance, f),
    nameSearch: jspb.Message.getFieldWithDefault(msg, 3, ""),
    titleList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    sortNameAZ: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    sortNameLastAZ: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.PeopleViewModelRequest;
  return proto.app.viewmodel.v1.PeopleViewModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.PeopleViewModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_grpc_v1_auth_pb.Auth;
      reader.readMessage(value,app_grpc_v1_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuthentication(value);
      break;
    case 2:
      var value = new proto.app.viewmodel.v1.PeopleViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.PeopleViewModel.deserializeBinaryFromReader);
      msg.setPeopleViewModel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameSearch(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.app.model.v1.TITLE>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTitle(values[i]);
      }
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSortNameAZ(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSortNameLastAZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.PeopleViewModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.PeopleViewModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthentication();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_grpc_v1_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getPeopleViewModel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.viewmodel.v1.PeopleViewModel.serializeBinaryToWriter
    );
  }
  f = message.getNameSearch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitleList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getSortNameAZ();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSortNameLastAZ();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional app.grpc.v1.Auth authentication = 1;
 * @return {?proto.app.grpc.v1.Auth}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.getAuthentication = function() {
  return /** @type{?proto.app.grpc.v1.Auth} */ (
    jspb.Message.getWrapperField(this, app_grpc_v1_auth_pb.Auth, 1));
};


/**
 * @param {?proto.app.grpc.v1.Auth|undefined} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
*/
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.setAuthentication = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.clearAuthentication = function() {
  return this.setAuthentication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.hasAuthentication = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PeopleViewModel people_view_model = 2;
 * @return {?proto.app.viewmodel.v1.PeopleViewModel}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.getPeopleViewModel = function() {
  return /** @type{?proto.app.viewmodel.v1.PeopleViewModel} */ (
    jspb.Message.getWrapperField(this, proto.app.viewmodel.v1.PeopleViewModel, 2));
};


/**
 * @param {?proto.app.viewmodel.v1.PeopleViewModel|undefined} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
*/
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.setPeopleViewModel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.clearPeopleViewModel = function() {
  return this.setPeopleViewModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.hasPeopleViewModel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name_search = 3;
 * @return {string}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.getNameSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.setNameSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated app.model.v1.TITLE title = 4;
 * @return {!Array<!proto.app.model.v1.TITLE>}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.getTitleList = function() {
  return /** @type {!Array<!proto.app.model.v1.TITLE>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.app.model.v1.TITLE>} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.setTitleList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.app.model.v1.TITLE} value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.addTitle = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.clearTitleList = function() {
  return this.setTitleList([]);
};


/**
 * optional bool sort_name_a_z = 5;
 * @return {boolean}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.getSortNameAZ = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.setSortNameAZ = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool sort_name_last_a_z = 6;
 * @return {boolean}
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.getSortNameLastAZ = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModelRequest} returns this
 */
proto.app.viewmodel.v1.PeopleViewModelRequest.prototype.setSortNameLastAZ = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.PeopleViewModel.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.PeopleViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.PeopleViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.PeopleViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamState: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lastUpdateEpochMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPeople: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sortedPeopleList: jspb.Message.toObjectList(msg.getSortedPeopleList(),
    proto.app.viewmodel.v1.PersonViewModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.PeopleViewModel}
 */
proto.app.viewmodel.v1.PeopleViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.PeopleViewModel;
  return proto.app.viewmodel.v1.PeopleViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.PeopleViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.PeopleViewModel}
 */
proto.app.viewmodel.v1.PeopleViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (reader.readEnum());
      msg.setStreamState(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdateEpochMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPeople(value);
      break;
    case 4:
      var value = new proto.app.viewmodel.v1.PersonViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.PersonViewModel.deserializeBinaryFromReader);
      msg.addSortedPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.PeopleViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.PeopleViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.PeopleViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLastUpdateEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTotalPeople();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSortedPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.viewmodel.v1.PersonViewModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.grpc.v1.STREAM_STATE stream_state = 1;
 * @return {!proto.app.grpc.v1.STREAM_STATE}
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.getStreamState = function() {
  return /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.grpc.v1.STREAM_STATE} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModel} returns this
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.setStreamState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 last_update_epoch_ms = 2;
 * @return {number}
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.getLastUpdateEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModel} returns this
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.setLastUpdateEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_people = 3;
 * @return {number}
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.getTotalPeople = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModel} returns this
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.setTotalPeople = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated PersonViewModel sorted_people = 4;
 * @return {!Array<!proto.app.viewmodel.v1.PersonViewModel>}
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.getSortedPeopleList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.PersonViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.PersonViewModel, 4));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.PersonViewModel>} value
 * @return {!proto.app.viewmodel.v1.PeopleViewModel} returns this
*/
proto.app.viewmodel.v1.PeopleViewModel.prototype.setSortedPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.viewmodel.v1.PersonViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.PersonViewModel}
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.addSortedPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.viewmodel.v1.PersonViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.PeopleViewModel} returns this
 */
proto.app.viewmodel.v1.PeopleViewModel.prototype.clearSortedPeopleList = function() {
  return this.setSortedPeopleList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.ChannelViewModel.repeatedFields_ = [5,6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.ChannelViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.ChannelViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.ChannelViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamState: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lastUpdateEpochMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPeople: jspb.Message.getFieldWithDefault(msg, 3, 0),
    channel: (f = msg.getChannel()) && app_model_v1_data_pb.Channel.toObject(includeInstance, f),
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    proto.app.viewmodel.v1.PersonViewModel.toObject, includeInstance),
    sensorsList: jspb.Message.toObjectList(msg.getSensorsList(),
    proto.app.viewmodel.v1.PersonViewModel.toObject, includeInstance),
    encryptPair: jspb.Message.getFieldWithDefault(msg, 7, ""),
    signPair: jspb.Message.getFieldWithDefault(msg, 8, ""),
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    app_model_v1_data_pb.ChatMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.ChannelViewModel}
 */
proto.app.viewmodel.v1.ChannelViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.ChannelViewModel;
  return proto.app.viewmodel.v1.ChannelViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.ChannelViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.ChannelViewModel}
 */
proto.app.viewmodel.v1.ChannelViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (reader.readEnum());
      msg.setStreamState(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdateEpochMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPeople(value);
      break;
    case 4:
      var value = new app_model_v1_data_pb.Channel;
      reader.readMessage(value,app_model_v1_data_pb.Channel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 5:
      var value = new proto.app.viewmodel.v1.PersonViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.PersonViewModel.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    case 6:
      var value = new proto.app.viewmodel.v1.PersonViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.PersonViewModel.deserializeBinaryFromReader);
      msg.addSensors(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncryptPair(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignPair(value);
      break;
    case 9:
      var value = new app_model_v1_data_pb.ChatMessage;
      reader.readMessage(value,app_model_v1_data_pb.ChatMessage.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.ChannelViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.ChannelViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.ChannelViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLastUpdateEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTotalPeople();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      app_model_v1_data_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.app.viewmodel.v1.PersonViewModel.serializeBinaryToWriter
    );
  }
  f = message.getSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.app.viewmodel.v1.PersonViewModel.serializeBinaryToWriter
    );
  }
  f = message.getEncryptPair();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSignPair();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      app_model_v1_data_pb.ChatMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.grpc.v1.STREAM_STATE stream_state = 1;
 * @return {!proto.app.grpc.v1.STREAM_STATE}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getStreamState = function() {
  return /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.grpc.v1.STREAM_STATE} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.setStreamState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 last_update_epoch_ms = 2;
 * @return {number}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getLastUpdateEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.setLastUpdateEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_people = 3;
 * @return {number}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getTotalPeople = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.setTotalPeople = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional app.model.v1.Channel Channel = 4;
 * @return {?proto.app.model.v1.Channel}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getChannel = function() {
  return /** @type{?proto.app.model.v1.Channel} */ (
    jspb.Message.getWrapperField(this, app_model_v1_data_pb.Channel, 4));
};


/**
 * @param {?proto.app.model.v1.Channel|undefined} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
*/
proto.app.viewmodel.v1.ChannelViewModel.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated PersonViewModel people = 5;
 * @return {!Array<!proto.app.viewmodel.v1.PersonViewModel>}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.PersonViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.PersonViewModel, 5));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.PersonViewModel>} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
*/
proto.app.viewmodel.v1.ChannelViewModel.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.app.viewmodel.v1.PersonViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.PersonViewModel}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.app.viewmodel.v1.PersonViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};


/**
 * repeated PersonViewModel sensors = 6;
 * @return {!Array<!proto.app.viewmodel.v1.PersonViewModel>}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getSensorsList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.PersonViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.PersonViewModel, 6));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.PersonViewModel>} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
*/
proto.app.viewmodel.v1.ChannelViewModel.prototype.setSensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.app.viewmodel.v1.PersonViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.PersonViewModel}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.addSensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.app.viewmodel.v1.PersonViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.clearSensorsList = function() {
  return this.setSensorsList([]);
};


/**
 * optional string encrypt_pair = 7;
 * @return {string}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getEncryptPair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.setEncryptPair = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sign_pair = 8;
 * @return {string}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getSignPair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.setSignPair = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated app.model.v1.ChatMessage messages = 9;
 * @return {!Array<!proto.app.model.v1.ChatMessage>}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.app.model.v1.ChatMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, app_model_v1_data_pb.ChatMessage, 9));
};


/**
 * @param {!Array<!proto.app.model.v1.ChatMessage>} value
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
*/
proto.app.viewmodel.v1.ChannelViewModel.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.app.model.v1.ChatMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.ChatMessage}
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.app.model.v1.ChatMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.ChannelViewModel} returns this
 */
proto.app.viewmodel.v1.ChannelViewModel.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.DashboardViewModel.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.DashboardViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.DashboardViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.DashboardViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    authentication: (f = msg.getAuthentication()) && app_grpc_v1_auth_pb.Auth.toObject(includeInstance, f),
    streamState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastUpdateEpochMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalPeople: jspb.Message.getFieldWithDefault(msg, 4, 0),
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    proto.app.viewmodel.v1.PersonViewModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.DashboardViewModel}
 */
proto.app.viewmodel.v1.DashboardViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.DashboardViewModel;
  return proto.app.viewmodel.v1.DashboardViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.DashboardViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.DashboardViewModel}
 */
proto.app.viewmodel.v1.DashboardViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_grpc_v1_auth_pb.Auth;
      reader.readMessage(value,app_grpc_v1_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuthentication(value);
      break;
    case 2:
      var value = /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (reader.readEnum());
      msg.setStreamState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdateEpochMs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPeople(value);
      break;
    case 5:
      var value = new proto.app.viewmodel.v1.PersonViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.PersonViewModel.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.DashboardViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.DashboardViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.DashboardViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthentication();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_grpc_v1_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getStreamState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLastUpdateEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getTotalPeople();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.app.viewmodel.v1.PersonViewModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.grpc.v1.Auth authentication = 1;
 * @return {?proto.app.grpc.v1.Auth}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.getAuthentication = function() {
  return /** @type{?proto.app.grpc.v1.Auth} */ (
    jspb.Message.getWrapperField(this, app_grpc_v1_auth_pb.Auth, 1));
};


/**
 * @param {?proto.app.grpc.v1.Auth|undefined} value
 * @return {!proto.app.viewmodel.v1.DashboardViewModel} returns this
*/
proto.app.viewmodel.v1.DashboardViewModel.prototype.setAuthentication = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.DashboardViewModel} returns this
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.clearAuthentication = function() {
  return this.setAuthentication(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.hasAuthentication = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional app.grpc.v1.STREAM_STATE stream_state = 2;
 * @return {!proto.app.grpc.v1.STREAM_STATE}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.getStreamState = function() {
  return /** @type {!proto.app.grpc.v1.STREAM_STATE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.grpc.v1.STREAM_STATE} value
 * @return {!proto.app.viewmodel.v1.DashboardViewModel} returns this
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.setStreamState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint64 last_update_epoch_ms = 3;
 * @return {number}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.getLastUpdateEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.DashboardViewModel} returns this
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.setLastUpdateEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 total_people = 4;
 * @return {number}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.getTotalPeople = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.viewmodel.v1.DashboardViewModel} returns this
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.setTotalPeople = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated PersonViewModel people = 5;
 * @return {!Array<!proto.app.viewmodel.v1.PersonViewModel>}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.PersonViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.PersonViewModel, 5));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.PersonViewModel>} value
 * @return {!proto.app.viewmodel.v1.DashboardViewModel} returns this
*/
proto.app.viewmodel.v1.DashboardViewModel.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.app.viewmodel.v1.PersonViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.PersonViewModel}
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.app.viewmodel.v1.PersonViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.DashboardViewModel} returns this
 */
proto.app.viewmodel.v1.DashboardViewModel.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.TagViewModel.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.TagViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.TagViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.TagViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    tags: (f = msg.getTags()) && app_model_v1_data_pb.Tag.toObject(includeInstance, f),
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    proto.app.viewmodel.v1.PersonViewModel.toObject, includeInstance),
    sensorsList: jspb.Message.toObjectList(msg.getSensorsList(),
    proto.app.viewmodel.v1.SensorViewModel.toObject, includeInstance),
    telemetriesList: jspb.Message.toObjectList(msg.getTelemetriesList(),
    proto.app.viewmodel.v1.TelemetryViewModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.TagViewModel}
 */
proto.app.viewmodel.v1.TagViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.TagViewModel;
  return proto.app.viewmodel.v1.TagViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.TagViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.TagViewModel}
 */
proto.app.viewmodel.v1.TagViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_model_v1_data_pb.Tag;
      reader.readMessage(value,app_model_v1_data_pb.Tag.deserializeBinaryFromReader);
      msg.setTags(value);
      break;
    case 2:
      var value = new proto.app.viewmodel.v1.PersonViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.PersonViewModel.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    case 3:
      var value = new proto.app.viewmodel.v1.SensorViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.SensorViewModel.deserializeBinaryFromReader);
      msg.addSensors(value);
      break;
    case 4:
      var value = new proto.app.viewmodel.v1.TelemetryViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.TelemetryViewModel.deserializeBinaryFromReader);
      msg.addTelemetries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.TagViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.TagViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.TagViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTags();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_model_v1_data_pb.Tag.serializeBinaryToWriter
    );
  }
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.app.viewmodel.v1.PersonViewModel.serializeBinaryToWriter
    );
  }
  f = message.getSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.viewmodel.v1.SensorViewModel.serializeBinaryToWriter
    );
  }
  f = message.getTelemetriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.viewmodel.v1.TelemetryViewModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.model.v1.Tag tags = 1;
 * @return {?proto.app.model.v1.Tag}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.getTags = function() {
  return /** @type{?proto.app.model.v1.Tag} */ (
    jspb.Message.getWrapperField(this, app_model_v1_data_pb.Tag, 1));
};


/**
 * @param {?proto.app.model.v1.Tag|undefined} value
 * @return {!proto.app.viewmodel.v1.TagViewModel} returns this
*/
proto.app.viewmodel.v1.TagViewModel.prototype.setTags = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.TagViewModel} returns this
 */
proto.app.viewmodel.v1.TagViewModel.prototype.clearTags = function() {
  return this.setTags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.hasTags = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PersonViewModel people = 2;
 * @return {!Array<!proto.app.viewmodel.v1.PersonViewModel>}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.PersonViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.PersonViewModel, 2));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.PersonViewModel>} value
 * @return {!proto.app.viewmodel.v1.TagViewModel} returns this
*/
proto.app.viewmodel.v1.TagViewModel.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.app.viewmodel.v1.PersonViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.PersonViewModel}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.app.viewmodel.v1.PersonViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.TagViewModel} returns this
 */
proto.app.viewmodel.v1.TagViewModel.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};


/**
 * repeated SensorViewModel sensors = 3;
 * @return {!Array<!proto.app.viewmodel.v1.SensorViewModel>}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.getSensorsList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.SensorViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.SensorViewModel, 3));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.SensorViewModel>} value
 * @return {!proto.app.viewmodel.v1.TagViewModel} returns this
*/
proto.app.viewmodel.v1.TagViewModel.prototype.setSensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.viewmodel.v1.SensorViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.SensorViewModel}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.addSensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.viewmodel.v1.SensorViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.TagViewModel} returns this
 */
proto.app.viewmodel.v1.TagViewModel.prototype.clearSensorsList = function() {
  return this.setSensorsList([]);
};


/**
 * repeated TelemetryViewModel telemetries = 4;
 * @return {!Array<!proto.app.viewmodel.v1.TelemetryViewModel>}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.getTelemetriesList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.TelemetryViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.TelemetryViewModel, 4));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.TelemetryViewModel>} value
 * @return {!proto.app.viewmodel.v1.TagViewModel} returns this
*/
proto.app.viewmodel.v1.TagViewModel.prototype.setTelemetriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.viewmodel.v1.TelemetryViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.TelemetryViewModel}
 */
proto.app.viewmodel.v1.TagViewModel.prototype.addTelemetries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.viewmodel.v1.TelemetryViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.TagViewModel} returns this
 */
proto.app.viewmodel.v1.TagViewModel.prototype.clearTelemetriesList = function() {
  return this.setTelemetriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.TagsViewModel.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.TagsViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.TagsViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.TagsViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.TagsViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.app.viewmodel.v1.TagViewModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.TagsViewModel}
 */
proto.app.viewmodel.v1.TagsViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.TagsViewModel;
  return proto.app.viewmodel.v1.TagsViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.TagsViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.TagsViewModel}
 */
proto.app.viewmodel.v1.TagsViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.viewmodel.v1.TagViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.TagViewModel.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.TagsViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.TagsViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.TagsViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.TagsViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.app.viewmodel.v1.TagViewModel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TagViewModel tags = 1;
 * @return {!Array<!proto.app.viewmodel.v1.TagViewModel>}
 */
proto.app.viewmodel.v1.TagsViewModel.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.TagViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.TagViewModel, 1));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.TagViewModel>} value
 * @return {!proto.app.viewmodel.v1.TagsViewModel} returns this
*/
proto.app.viewmodel.v1.TagsViewModel.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.app.viewmodel.v1.TagViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.TagViewModel}
 */
proto.app.viewmodel.v1.TagsViewModel.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.app.viewmodel.v1.TagViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.TagsViewModel} returns this
 */
proto.app.viewmodel.v1.TagsViewModel.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.viewmodel.v1.AppViewModel.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.viewmodel.v1.AppViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.app.viewmodel.v1.AppViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.viewmodel.v1.AppViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.AppViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && app_model_v1_data_pb.Organization.toObject(includeInstance, f),
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    proto.app.viewmodel.v1.PersonViewModel.toObject, includeInstance),
    sensorsList: jspb.Message.toObjectList(msg.getSensorsList(),
    app_model_v1_data_pb.Sensor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.viewmodel.v1.AppViewModel}
 */
proto.app.viewmodel.v1.AppViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.viewmodel.v1.AppViewModel;
  return proto.app.viewmodel.v1.AppViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.viewmodel.v1.AppViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.viewmodel.v1.AppViewModel}
 */
proto.app.viewmodel.v1.AppViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new app_model_v1_data_pb.Organization;
      reader.readMessage(value,app_model_v1_data_pb.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new proto.app.viewmodel.v1.PersonViewModel;
      reader.readMessage(value,proto.app.viewmodel.v1.PersonViewModel.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    case 3:
      var value = new app_model_v1_data_pb.Sensor;
      reader.readMessage(value,app_model_v1_data_pb.Sensor.deserializeBinaryFromReader);
      msg.addSensors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.viewmodel.v1.AppViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.viewmodel.v1.AppViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.viewmodel.v1.AppViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.viewmodel.v1.AppViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      app_model_v1_data_pb.Organization.serializeBinaryToWriter
    );
  }
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.app.viewmodel.v1.PersonViewModel.serializeBinaryToWriter
    );
  }
  f = message.getSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      app_model_v1_data_pb.Sensor.serializeBinaryToWriter
    );
  }
};


/**
 * optional app.model.v1.Organization organization = 1;
 * @return {?proto.app.model.v1.Organization}
 */
proto.app.viewmodel.v1.AppViewModel.prototype.getOrganization = function() {
  return /** @type{?proto.app.model.v1.Organization} */ (
    jspb.Message.getWrapperField(this, app_model_v1_data_pb.Organization, 1));
};


/**
 * @param {?proto.app.model.v1.Organization|undefined} value
 * @return {!proto.app.viewmodel.v1.AppViewModel} returns this
*/
proto.app.viewmodel.v1.AppViewModel.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.viewmodel.v1.AppViewModel} returns this
 */
proto.app.viewmodel.v1.AppViewModel.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.viewmodel.v1.AppViewModel.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PersonViewModel people = 2;
 * @return {!Array<!proto.app.viewmodel.v1.PersonViewModel>}
 */
proto.app.viewmodel.v1.AppViewModel.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.app.viewmodel.v1.PersonViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.viewmodel.v1.PersonViewModel, 2));
};


/**
 * @param {!Array<!proto.app.viewmodel.v1.PersonViewModel>} value
 * @return {!proto.app.viewmodel.v1.AppViewModel} returns this
*/
proto.app.viewmodel.v1.AppViewModel.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.app.viewmodel.v1.PersonViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.viewmodel.v1.PersonViewModel}
 */
proto.app.viewmodel.v1.AppViewModel.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.app.viewmodel.v1.PersonViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.AppViewModel} returns this
 */
proto.app.viewmodel.v1.AppViewModel.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};


/**
 * repeated app.model.v1.Sensor sensors = 3;
 * @return {!Array<!proto.app.model.v1.Sensor>}
 */
proto.app.viewmodel.v1.AppViewModel.prototype.getSensorsList = function() {
  return /** @type{!Array<!proto.app.model.v1.Sensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, app_model_v1_data_pb.Sensor, 3));
};


/**
 * @param {!Array<!proto.app.model.v1.Sensor>} value
 * @return {!proto.app.viewmodel.v1.AppViewModel} returns this
*/
proto.app.viewmodel.v1.AppViewModel.prototype.setSensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.model.v1.Sensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.model.v1.Sensor}
 */
proto.app.viewmodel.v1.AppViewModel.prototype.addSensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.model.v1.Sensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.viewmodel.v1.AppViewModel} returns this
 */
proto.app.viewmodel.v1.AppViewModel.prototype.clearSensorsList = function() {
  return this.setSensorsList([]);
};


/**
 * @enum {number}
 */
proto.app.viewmodel.v1.APP_CONSTANTS = {
  APP_CONSTANTS_DEFAULT: 0,
  APP_CONSTANTS_OFFLINE_THRESHOLD: 5000
};

goog.object.extend(exports, proto.app.viewmodel.v1);
