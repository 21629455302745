import { Injectable } from '@angular/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { random } from 'lodash';
import { AllServcies } from '../startup/startup.service';


@Injectable({
	providedIn: 'root'
})
export class LocalNotificationsService {

	constructor(

	)
	{
	}
	
	init(allServices:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			this.setServices(allServices);
			await LocalNotifications.checkPermissions().then( async (permissions) => {
				// console.log("LocalNotifications.checkPermissions", permissions);
				if(!permissions.display || permissions.display !== "granted"){
					await LocalNotifications.requestPermissions().then( (permissions) => {
						console.log("LocalNotifications.requestPermissions", permissions);
					});
				}
			}).catch( (e) => {
				console.log("LocalNotifications.checkPermissions Error ", e);
			})
			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	showLocalNotificationAnnouncment(title: string, body: string, at: Date, id: number = random(0, 1000)): void {
		LocalNotifications.removeAllDeliveredNotifications();
		LocalNotifications.schedule({
			notifications: [
				{
					title,
					body,
					id,
					schedule: {
						at,
					},
				},
			],
		});
	}

	// alarm1.wav
	showLocalNotificationAlarm(title: string, body: string, at: Date, id: number = random(0, 1000)): void {
		LocalNotifications.removeAllDeliveredNotifications();
		LocalNotifications.schedule({
			notifications: [
				{
					title,
					body,
					id,
					iconColor: '#FF0000',
					sound: 'alarm1.wav',
					schedule: {
						at,
					},
				},
			],
		});
	}
}
