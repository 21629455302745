// source: app/grpc/v1/auth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var nanopb_pb = require('../../../nanopb_pb.js');
goog.object.extend(proto, nanopb_pb);
goog.exportSymbol('proto.app.grpc.v1.Auth', null, global);
goog.exportSymbol('proto.app.grpc.v1.Auth.MethodCase', null, global);
goog.exportSymbol('proto.app.grpc.v1.ECC_GCM256', null, global);
goog.exportSymbol('proto.app.grpc.v1.PermissionSet', null, global);
goog.exportSymbol('proto.app.grpc.v1.Token', null, global);
goog.exportSymbol('proto.app.grpc.v1.Token.ALG_types', null, global);
goog.exportSymbol('proto.app.grpc.v1.Token.Body', null, global);
goog.exportSymbol('proto.app.grpc.v1.UserCredentials', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.grpc.v1.PermissionSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.grpc.v1.PermissionSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.grpc.v1.PermissionSet.displayName = 'proto.app.grpc.v1.PermissionSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.grpc.v1.UserCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.grpc.v1.UserCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.grpc.v1.UserCredentials.displayName = 'proto.app.grpc.v1.UserCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.grpc.v1.Token = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.grpc.v1.Token, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.grpc.v1.Token.displayName = 'proto.app.grpc.v1.Token';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.grpc.v1.Token.Body = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.grpc.v1.Token.Body, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.grpc.v1.Token.Body.displayName = 'proto.app.grpc.v1.Token.Body';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.grpc.v1.ECC_GCM256 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.grpc.v1.ECC_GCM256, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.grpc.v1.ECC_GCM256.displayName = 'proto.app.grpc.v1.ECC_GCM256';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.grpc.v1.Auth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.app.grpc.v1.Auth.oneofGroups_);
};
goog.inherits(proto.app.grpc.v1.Auth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.grpc.v1.Auth.displayName = 'proto.app.grpc.v1.Auth';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.grpc.v1.PermissionSet.prototype.toObject = function(opt_includeInstance) {
  return proto.app.grpc.v1.PermissionSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.grpc.v1.PermissionSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.PermissionSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    unmask: jspb.Message.getFieldWithDefault(msg, 1, 0),
    user: jspb.Message.getFieldWithDefault(msg, 2, 0),
    group: jspb.Message.getFieldWithDefault(msg, 3, 0),
    permissions: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.grpc.v1.PermissionSet}
 */
proto.app.grpc.v1.PermissionSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.grpc.v1.PermissionSet;
  return proto.app.grpc.v1.PermissionSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.grpc.v1.PermissionSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.grpc.v1.PermissionSet}
 */
proto.app.grpc.v1.PermissionSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUnmask(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setGroup(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.PermissionSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.grpc.v1.PermissionSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.grpc.v1.PermissionSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.PermissionSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnmask();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getUser();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getGroup();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getPermissions();
  if (f !== 0) {
    writer.writeFixed32(
      4,
      f
    );
  }
};


/**
 * optional uint64 unmask = 1;
 * @return {number}
 */
proto.app.grpc.v1.PermissionSet.prototype.getUnmask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.PermissionSet} returns this
 */
proto.app.grpc.v1.PermissionSet.prototype.setUnmask = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 user = 2;
 * @return {number}
 */
proto.app.grpc.v1.PermissionSet.prototype.getUser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.PermissionSet} returns this
 */
proto.app.grpc.v1.PermissionSet.prototype.setUser = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 group = 3;
 * @return {number}
 */
proto.app.grpc.v1.PermissionSet.prototype.getGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.PermissionSet} returns this
 */
proto.app.grpc.v1.PermissionSet.prototype.setGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional fixed32 permissions = 4;
 * @return {number}
 */
proto.app.grpc.v1.PermissionSet.prototype.getPermissions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.PermissionSet} returns this
 */
proto.app.grpc.v1.PermissionSet.prototype.setPermissions = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.grpc.v1.UserCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.app.grpc.v1.UserCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.grpc.v1.UserCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.UserCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.grpc.v1.UserCredentials}
 */
proto.app.grpc.v1.UserCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.grpc.v1.UserCredentials;
  return proto.app.grpc.v1.UserCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.grpc.v1.UserCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.grpc.v1.UserCredentials}
 */
proto.app.grpc.v1.UserCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.UserCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.grpc.v1.UserCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.grpc.v1.UserCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.UserCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.app.grpc.v1.UserCredentials.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.grpc.v1.UserCredentials} returns this
 */
proto.app.grpc.v1.UserCredentials.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.app.grpc.v1.UserCredentials.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.grpc.v1.UserCredentials} returns this
 */
proto.app.grpc.v1.UserCredentials.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.grpc.v1.Token.prototype.toObject = function(opt_includeInstance) {
  return proto.app.grpc.v1.Token.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.grpc.v1.Token} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.Token.toObject = function(includeInstance, msg) {
  var f, obj = {
    alg: jspb.Message.getFieldWithDefault(msg, 1, 0),
    body: (f = msg.getBody()) && proto.app.grpc.v1.Token.Body.toObject(includeInstance, f),
    signature: msg.getSignature_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.grpc.v1.Token}
 */
proto.app.grpc.v1.Token.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.grpc.v1.Token;
  return proto.app.grpc.v1.Token.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.grpc.v1.Token} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.grpc.v1.Token}
 */
proto.app.grpc.v1.Token.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.grpc.v1.Token.ALG_types} */ (reader.readEnum());
      msg.setAlg(value);
      break;
    case 2:
      var value = new proto.app.grpc.v1.Token.Body;
      reader.readMessage(value,proto.app.grpc.v1.Token.Body.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.Token.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.grpc.v1.Token.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.grpc.v1.Token} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.Token.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlg();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.grpc.v1.Token.Body.serializeBinaryToWriter
    );
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.app.grpc.v1.Token.ALG_types = {
  DEFAULT: 0,
  RS256: 4,
  ES256: 7
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.grpc.v1.Token.Body.prototype.toObject = function(opt_includeInstance) {
  return proto.app.grpc.v1.Token.Body.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.grpc.v1.Token.Body} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.Token.Body.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuedEpocMs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    expirationEpochMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userUuid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userOffset: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.grpc.v1.Token.Body}
 */
proto.app.grpc.v1.Token.Body.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.grpc.v1.Token.Body;
  return proto.app.grpc.v1.Token.Body.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.grpc.v1.Token.Body} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.grpc.v1.Token.Body}
 */
proto.app.grpc.v1.Token.Body.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIssuedEpocMs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExpirationEpochMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserUuid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.Token.Body.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.grpc.v1.Token.Body.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.grpc.v1.Token.Body} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.Token.Body.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuedEpocMs();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getExpirationEpochMs();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getUserUuid();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUserOffset();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 issued_epoc_ms = 1;
 * @return {number}
 */
proto.app.grpc.v1.Token.Body.prototype.getIssuedEpocMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.Token.Body} returns this
 */
proto.app.grpc.v1.Token.Body.prototype.setIssuedEpocMs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 expiration_epoch_ms = 2;
 * @return {number}
 */
proto.app.grpc.v1.Token.Body.prototype.getExpirationEpochMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.Token.Body} returns this
 */
proto.app.grpc.v1.Token.Body.prototype.setExpirationEpochMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 user_UUID = 3;
 * @return {number}
 */
proto.app.grpc.v1.Token.Body.prototype.getUserUuid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.Token.Body} returns this
 */
proto.app.grpc.v1.Token.Body.prototype.setUserUuid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 user_offset = 4;
 * @return {number}
 */
proto.app.grpc.v1.Token.Body.prototype.getUserOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.Token.Body} returns this
 */
proto.app.grpc.v1.Token.Body.prototype.setUserOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional ALG_types alg = 1;
 * @return {!proto.app.grpc.v1.Token.ALG_types}
 */
proto.app.grpc.v1.Token.prototype.getAlg = function() {
  return /** @type {!proto.app.grpc.v1.Token.ALG_types} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.grpc.v1.Token.ALG_types} value
 * @return {!proto.app.grpc.v1.Token} returns this
 */
proto.app.grpc.v1.Token.prototype.setAlg = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Body body = 2;
 * @return {?proto.app.grpc.v1.Token.Body}
 */
proto.app.grpc.v1.Token.prototype.getBody = function() {
  return /** @type{?proto.app.grpc.v1.Token.Body} */ (
    jspb.Message.getWrapperField(this, proto.app.grpc.v1.Token.Body, 2));
};


/**
 * @param {?proto.app.grpc.v1.Token.Body|undefined} value
 * @return {!proto.app.grpc.v1.Token} returns this
*/
proto.app.grpc.v1.Token.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.grpc.v1.Token} returns this
 */
proto.app.grpc.v1.Token.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.grpc.v1.Token.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes signature = 3;
 * @return {string}
 */
proto.app.grpc.v1.Token.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes signature = 3;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.app.grpc.v1.Token.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.Token.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.grpc.v1.Token} returns this
 */
proto.app.grpc.v1.Token.prototype.setSignature = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.toObject = function(opt_includeInstance) {
  return proto.app.grpc.v1.ECC_GCM256.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.grpc.v1.ECC_GCM256} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.ECC_GCM256.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    epoch: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sequenceNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    length: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sourcePublicCrc: jspb.Message.getFieldWithDefault(msg, 5, 0),
    destinationPublicCrc: jspb.Message.getFieldWithDefault(msg, 6, 0),
    gcmTag: msg.getGcmTag_asB64(),
    publicKey: msg.getPublicKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.grpc.v1.ECC_GCM256}
 */
proto.app.grpc.v1.ECC_GCM256.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.grpc.v1.ECC_GCM256;
  return proto.app.grpc.v1.ECC_GCM256.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.grpc.v1.ECC_GCM256} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.grpc.v1.ECC_GCM256}
 */
proto.app.grpc.v1.ECC_GCM256.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTypeVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEpoch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSequenceNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourcePublicCrc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDestinationPublicCrc(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGcmTag(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.grpc.v1.ECC_GCM256.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.grpc.v1.ECC_GCM256} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.ECC_GCM256.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeVersion();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getEpoch();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getSequenceNumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSourcePublicCrc();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDestinationPublicCrc();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getGcmTag_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getPublicKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
};


/**
 * optional uint64 type_version = 1;
 * @return {number}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getTypeVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.ECC_GCM256} returns this
 */
proto.app.grpc.v1.ECC_GCM256.prototype.setTypeVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 epoch = 2;
 * @return {number}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getEpoch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.ECC_GCM256} returns this
 */
proto.app.grpc.v1.ECC_GCM256.prototype.setEpoch = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 sequence_number = 3;
 * @return {number}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.ECC_GCM256} returns this
 */
proto.app.grpc.v1.ECC_GCM256.prototype.setSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 length = 4;
 * @return {number}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.ECC_GCM256} returns this
 */
proto.app.grpc.v1.ECC_GCM256.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 source_public_crc = 5;
 * @return {number}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getSourcePublicCrc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.ECC_GCM256} returns this
 */
proto.app.grpc.v1.ECC_GCM256.prototype.setSourcePublicCrc = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 destination_public_crc = 6;
 * @return {number}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getDestinationPublicCrc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.grpc.v1.ECC_GCM256} returns this
 */
proto.app.grpc.v1.ECC_GCM256.prototype.setDestinationPublicCrc = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bytes gcm_tag = 7;
 * @return {string}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getGcmTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes gcm_tag = 7;
 * This is a type-conversion wrapper around `getGcmTag()`
 * @return {string}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getGcmTag_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGcmTag()));
};


/**
 * optional bytes gcm_tag = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGcmTag()`
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getGcmTag_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGcmTag()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.grpc.v1.ECC_GCM256} returns this
 */
proto.app.grpc.v1.ECC_GCM256.prototype.setGcmTag = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional bytes public_key = 8;
 * @return {string}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes public_key = 8;
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {string}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getPublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPublicKey()));
};


/**
 * optional bytes public_key = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.ECC_GCM256.prototype.getPublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPublicKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.app.grpc.v1.ECC_GCM256} returns this
 */
proto.app.grpc.v1.ECC_GCM256.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.app.grpc.v1.Auth.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.app.grpc.v1.Auth.MethodCase = {
  METHOD_NOT_SET: 0,
  TOKEN: 1,
  ECC_GCM256: 2,
  USER: 3
};

/**
 * @return {proto.app.grpc.v1.Auth.MethodCase}
 */
proto.app.grpc.v1.Auth.prototype.getMethodCase = function() {
  return /** @type {proto.app.grpc.v1.Auth.MethodCase} */(jspb.Message.computeOneofCase(this, proto.app.grpc.v1.Auth.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.grpc.v1.Auth.prototype.toObject = function(opt_includeInstance) {
  return proto.app.grpc.v1.Auth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.grpc.v1.Auth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.Auth.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: (f = msg.getToken()) && proto.app.grpc.v1.Token.toObject(includeInstance, f),
    eccGcm256: (f = msg.getEccGcm256()) && proto.app.grpc.v1.ECC_GCM256.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.app.grpc.v1.UserCredentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.grpc.v1.Auth}
 */
proto.app.grpc.v1.Auth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.grpc.v1.Auth;
  return proto.app.grpc.v1.Auth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.grpc.v1.Auth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.grpc.v1.Auth}
 */
proto.app.grpc.v1.Auth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.grpc.v1.Token;
      reader.readMessage(value,proto.app.grpc.v1.Token.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.app.grpc.v1.ECC_GCM256;
      reader.readMessage(value,proto.app.grpc.v1.ECC_GCM256.deserializeBinaryFromReader);
      msg.setEccGcm256(value);
      break;
    case 3:
      var value = new proto.app.grpc.v1.UserCredentials;
      reader.readMessage(value,proto.app.grpc.v1.UserCredentials.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.grpc.v1.Auth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.grpc.v1.Auth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.grpc.v1.Auth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.grpc.v1.Auth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.grpc.v1.Token.serializeBinaryToWriter
    );
  }
  f = message.getEccGcm256();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.grpc.v1.ECC_GCM256.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.grpc.v1.UserCredentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional Token token = 1;
 * @return {?proto.app.grpc.v1.Token}
 */
proto.app.grpc.v1.Auth.prototype.getToken = function() {
  return /** @type{?proto.app.grpc.v1.Token} */ (
    jspb.Message.getWrapperField(this, proto.app.grpc.v1.Token, 1));
};


/**
 * @param {?proto.app.grpc.v1.Token|undefined} value
 * @return {!proto.app.grpc.v1.Auth} returns this
*/
proto.app.grpc.v1.Auth.prototype.setToken = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.app.grpc.v1.Auth.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.grpc.v1.Auth} returns this
 */
proto.app.grpc.v1.Auth.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.grpc.v1.Auth.prototype.hasToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ECC_GCM256 ecc_gcm256 = 2;
 * @return {?proto.app.grpc.v1.ECC_GCM256}
 */
proto.app.grpc.v1.Auth.prototype.getEccGcm256 = function() {
  return /** @type{?proto.app.grpc.v1.ECC_GCM256} */ (
    jspb.Message.getWrapperField(this, proto.app.grpc.v1.ECC_GCM256, 2));
};


/**
 * @param {?proto.app.grpc.v1.ECC_GCM256|undefined} value
 * @return {!proto.app.grpc.v1.Auth} returns this
*/
proto.app.grpc.v1.Auth.prototype.setEccGcm256 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.app.grpc.v1.Auth.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.grpc.v1.Auth} returns this
 */
proto.app.grpc.v1.Auth.prototype.clearEccGcm256 = function() {
  return this.setEccGcm256(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.grpc.v1.Auth.prototype.hasEccGcm256 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserCredentials user = 3;
 * @return {?proto.app.grpc.v1.UserCredentials}
 */
proto.app.grpc.v1.Auth.prototype.getUser = function() {
  return /** @type{?proto.app.grpc.v1.UserCredentials} */ (
    jspb.Message.getWrapperField(this, proto.app.grpc.v1.UserCredentials, 3));
};


/**
 * @param {?proto.app.grpc.v1.UserCredentials|undefined} value
 * @return {!proto.app.grpc.v1.Auth} returns this
*/
proto.app.grpc.v1.Auth.prototype.setUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.app.grpc.v1.Auth.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.grpc.v1.Auth} returns this
 */
proto.app.grpc.v1.Auth.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.grpc.v1.Auth.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.app.grpc.v1);
