// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/v2/service_transports.proto" (package "pb.v2", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TelemetryModel } from "./models";
import { DeviceModel } from "./models";
import { Telemetry } from "./data";
import { Token } from "./auth";
/**
 * @generated from protobuf message pb.v2.Alive
 */
export interface Alive {
    /**
     * @generated from protobuf field: bool alive = 1;
     */
    alive: boolean;
    /**
     * @generated from protobuf field: int64 start_epoch_ms = 2;
     */
    startEpochMs: bigint;
}
/**
 * ////////////////////////
 * SaveTelemetry
 * ////////////////////////
 *
 * @generated from protobuf message pb.v2.GetTokenResponse
 */
export interface GetTokenResponse {
    /**
     * @generated from protobuf field: pb.v2.FailRequest fail_request = 1;
     */
    failRequest: FailRequest;
    /**
     * @generated from protobuf field: pb.v2.Token token = 2;
     */
    token?: Token;
}
/**
 * ////////////////////////
 * SaveTelemetry
 * ////////////////////////
 *
 * @generated from protobuf message pb.v2.SaveTelemetryRequest
 */
export interface SaveTelemetryRequest {
    /**
     * @generated from protobuf field: uint32 app_id = 1;
     */
    appId: number;
    /**
     * @generated from protobuf field: pb.v2.Telemetry telemetry = 2;
     */
    telemetry?: Telemetry;
}
/**
 * @generated from protobuf message pb.v2.SaveTelemetryResponse
 */
export interface SaveTelemetryResponse {
    /**
     * @generated from protobuf field: pb.v2.FailRequest fail_request = 1;
     */
    failRequest: FailRequest;
    /**
     * @generated from protobuf field: string failure_message = 2;
     */
    failureMessage: string;
}
/**
 * ////////////////////////
 * StreamDeviceModel
 * ////////////////////////
 *
 * @generated from protobuf message pb.v2.StreamDeviceModelRequest
 */
export interface StreamDeviceModelRequest {
    /**
     * @generated from protobuf field: uint32 app_id = 1;
     */
    appId: number;
    /**
     * @generated from protobuf field: uint64 uuid = 2;
     */
    uuid: bigint;
}
/**
 * @generated from protobuf message pb.v2.StreamDeviceModelResponse
 */
export interface StreamDeviceModelResponse {
    /**
     * @generated from protobuf field: pb.v2.FailRequest fail_request = 1;
     */
    failRequest: FailRequest;
    /**
     * @generated from protobuf field: pb.v2.DeviceModel device_model = 2;
     */
    deviceModel?: DeviceModel;
    /**
     * @generated from protobuf field: repeated pb.v2.TelemetryModel telemetry_models = 3;
     */
    telemetryModels: TelemetryModel[];
}
/**
 * @generated from protobuf enum pb.v2.FailRequest
 */
export enum FailRequest {
    /**
     * don't fail
     *
     * @generated from protobuf enum value: FAIL_REQUEST_OK = 0;
     */
    OK = 0,
    /**
     * @generated from protobuf enum value: FAIL_REQUEST_SERVER_UNKNONW_ERROR = 1;
     */
    SERVER_UNKNONW_ERROR = 1,
    /**
     * @generated from protobuf enum value: FAIL_REQUEST_NO_AUTH = 2;
     */
    NO_AUTH = 2,
    /**
     * @generated from protobuf enum value: FAIL_REQUEST_NO_PERMISSION = 3;
     */
    NO_PERMISSION = 3,
    /**
     * @generated from protobuf enum value: FAIL_REQUEST_NO_SESSION = 4;
     */
    NO_SESSION = 4,
    /**
     * @generated from protobuf enum value: FAIL_REQUEST_BAD_REQUEST = 5;
     */
    BAD_REQUEST = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class Alive$Type extends MessageType<Alive> {
    constructor() {
        super("pb.v2.Alive", [
            { no: 1, name: "alive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "start_epoch_ms", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Alive>): Alive {
        const message = { alive: false, startEpochMs: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Alive>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Alive): Alive {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool alive */ 1:
                    message.alive = reader.bool();
                    break;
                case /* int64 start_epoch_ms */ 2:
                    message.startEpochMs = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Alive, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool alive = 1; */
        if (message.alive !== false)
            writer.tag(1, WireType.Varint).bool(message.alive);
        /* int64 start_epoch_ms = 2; */
        if (message.startEpochMs !== 0n)
            writer.tag(2, WireType.Varint).int64(message.startEpochMs);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Alive
 */
export const Alive = new Alive$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTokenResponse$Type extends MessageType<GetTokenResponse> {
    constructor() {
        super("pb.v2.GetTokenResponse", [
            { no: 1, name: "fail_request", kind: "enum", T: () => ["pb.v2.FailRequest", FailRequest, "FAIL_REQUEST_"] },
            { no: 2, name: "token", kind: "message", T: () => Token }
        ]);
    }
    create(value?: PartialMessage<GetTokenResponse>): GetTokenResponse {
        const message = { failRequest: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTokenResponse): GetTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.FailRequest fail_request */ 1:
                    message.failRequest = reader.int32();
                    break;
                case /* pb.v2.Token token */ 2:
                    message.token = Token.internalBinaryRead(reader, reader.uint32(), options, message.token);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.FailRequest fail_request = 1; */
        if (message.failRequest !== 0)
            writer.tag(1, WireType.Varint).int32(message.failRequest);
        /* pb.v2.Token token = 2; */
        if (message.token)
            Token.internalBinaryWrite(message.token, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GetTokenResponse
 */
export const GetTokenResponse = new GetTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTelemetryRequest$Type extends MessageType<SaveTelemetryRequest> {
    constructor() {
        super("pb.v2.SaveTelemetryRequest", [
            { no: 1, name: "app_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "telemetry", kind: "message", T: () => Telemetry }
        ]);
    }
    create(value?: PartialMessage<SaveTelemetryRequest>): SaveTelemetryRequest {
        const message = { appId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTelemetryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTelemetryRequest): SaveTelemetryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 app_id */ 1:
                    message.appId = reader.uint32();
                    break;
                case /* pb.v2.Telemetry telemetry */ 2:
                    message.telemetry = Telemetry.internalBinaryRead(reader, reader.uint32(), options, message.telemetry);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTelemetryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 app_id = 1; */
        if (message.appId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.appId);
        /* pb.v2.Telemetry telemetry = 2; */
        if (message.telemetry)
            Telemetry.internalBinaryWrite(message.telemetry, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.SaveTelemetryRequest
 */
export const SaveTelemetryRequest = new SaveTelemetryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTelemetryResponse$Type extends MessageType<SaveTelemetryResponse> {
    constructor() {
        super("pb.v2.SaveTelemetryResponse", [
            { no: 1, name: "fail_request", kind: "enum", T: () => ["pb.v2.FailRequest", FailRequest, "FAIL_REQUEST_"] },
            { no: 2, name: "failure_message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SaveTelemetryResponse>): SaveTelemetryResponse {
        const message = { failRequest: 0, failureMessage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTelemetryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTelemetryResponse): SaveTelemetryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.FailRequest fail_request */ 1:
                    message.failRequest = reader.int32();
                    break;
                case /* string failure_message */ 2:
                    message.failureMessage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTelemetryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.FailRequest fail_request = 1; */
        if (message.failRequest !== 0)
            writer.tag(1, WireType.Varint).int32(message.failRequest);
        /* string failure_message = 2; */
        if (message.failureMessage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.failureMessage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.SaveTelemetryResponse
 */
export const SaveTelemetryResponse = new SaveTelemetryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamDeviceModelRequest$Type extends MessageType<StreamDeviceModelRequest> {
    constructor() {
        super("pb.v2.StreamDeviceModelRequest", [
            { no: 1, name: "app_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<StreamDeviceModelRequest>): StreamDeviceModelRequest {
        const message = { appId: 0, uuid: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StreamDeviceModelRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamDeviceModelRequest): StreamDeviceModelRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 app_id */ 1:
                    message.appId = reader.uint32();
                    break;
                case /* uint64 uuid */ 2:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamDeviceModelRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 app_id = 1; */
        if (message.appId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.appId);
        /* uint64 uuid = 2; */
        if (message.uuid !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.uuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.StreamDeviceModelRequest
 */
export const StreamDeviceModelRequest = new StreamDeviceModelRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamDeviceModelResponse$Type extends MessageType<StreamDeviceModelResponse> {
    constructor() {
        super("pb.v2.StreamDeviceModelResponse", [
            { no: 1, name: "fail_request", kind: "enum", T: () => ["pb.v2.FailRequest", FailRequest, "FAIL_REQUEST_"] },
            { no: 2, name: "device_model", kind: "message", T: () => DeviceModel },
            { no: 3, name: "telemetry_models", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TelemetryModel }
        ]);
    }
    create(value?: PartialMessage<StreamDeviceModelResponse>): StreamDeviceModelResponse {
        const message = { failRequest: 0, telemetryModels: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StreamDeviceModelResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamDeviceModelResponse): StreamDeviceModelResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.FailRequest fail_request */ 1:
                    message.failRequest = reader.int32();
                    break;
                case /* pb.v2.DeviceModel device_model */ 2:
                    message.deviceModel = DeviceModel.internalBinaryRead(reader, reader.uint32(), options, message.deviceModel);
                    break;
                case /* repeated pb.v2.TelemetryModel telemetry_models */ 3:
                    message.telemetryModels.push(TelemetryModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamDeviceModelResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.FailRequest fail_request = 1; */
        if (message.failRequest !== 0)
            writer.tag(1, WireType.Varint).int32(message.failRequest);
        /* pb.v2.DeviceModel device_model = 2; */
        if (message.deviceModel)
            DeviceModel.internalBinaryWrite(message.deviceModel, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.TelemetryModel telemetry_models = 3; */
        for (let i = 0; i < message.telemetryModels.length; i++)
            TelemetryModel.internalBinaryWrite(message.telemetryModels[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.StreamDeviceModelResponse
 */
export const StreamDeviceModelResponse = new StreamDeviceModelResponse$Type();
