// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/v2/settings.proto" (package "pb.v2", syntax proto3)
// tslint:disable
//
// Collection subjects -> Targetd for Microcontroller environments for efficent memory usage constraints
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message pb.v2.AnySetting
 */
export interface AnySetting {
    /**
     * https://github.com/protocolbuffers/protobuf/blob/master/src/google/protobuf/any.proto
     *
     * @generated from protobuf field: string type_url = 1;
     */
    typeUrl: string;
    /**
     * @generated from protobuf field: bytes value = 2;
     */
    value: Uint8Array;
}
/**
 * @generated from protobuf message pb.v2.VendorSettings
 */
export interface VendorSettings {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "customData";
        /**
         * @generated from protobuf field: pb.v2.AnySetting custom_data = 1;
         */
        customData: AnySetting;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message pb.v2.SettingsKeyValue
 */
export interface SettingsKeyValue {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "valueString";
        /**
         * @generated from protobuf field: string value_string = 2;
         */
        valueString: string;
    } | {
        oneofKind: "valueArray";
        /**
         * @generated from protobuf field: bytes value_array = 3;
         */
        valueArray: Uint8Array;
    } | {
        oneofKind: "valueNumber";
        /**
         * @generated from protobuf field: int64 value_number = 4;
         */
        valueNumber: bigint;
    } | {
        oneofKind: "valueDouble";
        /**
         * @generated from protobuf field: double value_double = 5;
         */
        valueDouble: number;
    } | {
        oneofKind: "valueBool";
        /**
         * @generated from protobuf field: bool value_bool = 6;
         */
        valueBool: boolean;
    } | {
        oneofKind: "null";
        /**
         * @generated from protobuf field: bool null = 7;
         */
        null: boolean; // true = value is null
    } | {
        oneofKind: undefined;
    };
}
// settings exchange protocol will look like this:
// device powers up for the first time and sets default settings. the counter will start at 0
// device will send settings to the server. 
// at this time the server will know all the settings of the device and build a UI for the user to edit them.
// if any settings are changed the counter will ++, and the server will send the new settings to the device once a urpc message is recieved
// the device will send the settings back to the server as an ack.
// if a server recieves a settings with a counter less than its current counter it will ignore the settings and send the current settings to the device
// if the device recieves a settings with a counter less than its current counter it will ignore its settings

/**
 * @generated from protobuf message pb.v2.Wifi_AP
 */
export interface Wifi_AP {
    /**
     * @generated from protobuf field: string ssid = 1;
     */
    ssid: string;
    /**
     * @generated from protobuf field: string pw = 2;
     */
    pw: string;
    /**
     * @generated from protobuf field: string identity = 3;
     */
    identity: string;
    /**
     * @generated from protobuf field: string username = 4;
     */
    username: string; // wpa2
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string; // wpa2
}
/**
 * //////////////////////////////////////////////////////////////
 * Generic Settings
 * //////////////////////////////////////////////////////////////
 *
 * @generated from protobuf message pb.v2.Settings
 */
export interface Settings {
    /**
     * @generated from protobuf field: uint64 uuid = 1;
     */
    uuid: bigint; // ONBOARDABLE/CHANGABLE - each sensor will have a unique id that will remain constant per "device"
    /**
     * @generated from protobuf field: uint64 serial = 2;
     */
    serial: bigint; // Unique identifier based off the hardware, for example macAddress, imei, something hardware specific.
    /**
     * @generated from protobuf field: uint32 version = 3;
     */
    version: number; // ++ each time this proto is changed
    /**
     * @generated from protobuf field: uint32 setting_counter_id = 4;
     */
    settingCounterId: number; // ++ each time a setting is changed.
    /**
     * @generated from protobuf field: pb.v2.SettingsStatus status = 13;
     */
    status: SettingsStatus;
    /**
     * @generated from protobuf field: pb.v2.VendorSettings vendor_settings = 14;
     */
    vendorSettings?: VendorSettings;
    /**
     * @generated from protobuf field: repeated pb.v2.SettingsKeyValue key_values = 15;
     */
    keyValues: SettingsKeyValue[];
    /**
     * @generated from protobuf field: pb.v2.Wifi_AP primary_ap = 16;
     */
    primaryAp?: Wifi_AP;
    /**
     * @generated from protobuf field: repeated pb.v2.Wifi_AP ap_list = 17;
     */
    apList: Wifi_AP[];
}
/**
 * @generated from protobuf enum pb.v2.SettingsStatus
 */
export enum SettingsStatus {
    /**
     * @generated from protobuf enum value: SETTINGS_STATUS_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * from sensor to server
     *
     * @generated from protobuf enum value: SETTINGS_STATUS_SENDING_CURRENT = 1;
     */
    SENDING_CURRENT = 1,
    /**
     * from server to sensor
     *
     * @generated from protobuf enum value: SETTINGS_STATUS_SENDING_UPDATED = 2;
     */
    SENDING_UPDATED = 2,
    /**
     * @generated from protobuf enum value: SETTINGS_STATUS_RECEIVED = 3;
     */
    RECEIVED = 3,
    /**
     * @generated from protobuf enum value: SETTINGS_STATUS_ACCEPTED = 4;
     */
    ACCEPTED = 4,
    /**
     * MORE_ERROR_CODES_CAN_FOLLOW
     *
     * @generated from protobuf enum value: SETTINGS_STATUS_REJECTED = 5;
     */
    REJECTED = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class AnySetting$Type extends MessageType<AnySetting> {
    constructor() {
        super("pb.v2.AnySetting", [
            { no: 1, name: "type_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<AnySetting>): AnySetting {
        const message = { typeUrl: "", value: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnySetting>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnySetting): AnySetting {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type_url */ 1:
                    message.typeUrl = reader.string();
                    break;
                case /* bytes value */ 2:
                    message.value = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnySetting, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type_url = 1; */
        if (message.typeUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.typeUrl);
        /* bytes value = 2; */
        if (message.value.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AnySetting
 */
export const AnySetting = new AnySetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VendorSettings$Type extends MessageType<VendorSettings> {
    constructor() {
        super("pb.v2.VendorSettings", [
            { no: 1, name: "custom_data", kind: "message", oneof: "data", T: () => AnySetting }
        ]);
    }
    create(value?: PartialMessage<VendorSettings>): VendorSettings {
        const message = { data: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VendorSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VendorSettings): VendorSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.AnySetting custom_data */ 1:
                    message.data = {
                        oneofKind: "customData",
                        customData: AnySetting.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).customData)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VendorSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.AnySetting custom_data = 1; */
        if (message.data.oneofKind === "customData")
            AnySetting.internalBinaryWrite(message.data.customData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.VendorSettings
 */
export const VendorSettings = new VendorSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingsKeyValue$Type extends MessageType<SettingsKeyValue> {
    constructor() {
        super("pb.v2.SettingsKeyValue", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 10, fixedLength: true } } },
            { no: 2, name: "value_string", kind: "scalar", oneof: "value", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 32, fixedLength: true } } },
            { no: 3, name: "value_array", kind: "scalar", oneof: "value", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 32, fixedLength: true } } },
            { no: 4, name: "value_number", kind: "scalar", oneof: "value", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "value_double", kind: "scalar", oneof: "value", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "value_bool", kind: "scalar", oneof: "value", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "null", kind: "scalar", oneof: "value", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SettingsKeyValue>): SettingsKeyValue {
        const message = { key: "", value: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SettingsKeyValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SettingsKeyValue): SettingsKeyValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value_string */ 2:
                    message.value = {
                        oneofKind: "valueString",
                        valueString: reader.string()
                    };
                    break;
                case /* bytes value_array */ 3:
                    message.value = {
                        oneofKind: "valueArray",
                        valueArray: reader.bytes()
                    };
                    break;
                case /* int64 value_number */ 4:
                    message.value = {
                        oneofKind: "valueNumber",
                        valueNumber: reader.int64().toBigInt()
                    };
                    break;
                case /* double value_double */ 5:
                    message.value = {
                        oneofKind: "valueDouble",
                        valueDouble: reader.double()
                    };
                    break;
                case /* bool value_bool */ 6:
                    message.value = {
                        oneofKind: "valueBool",
                        valueBool: reader.bool()
                    };
                    break;
                case /* bool null */ 7:
                    message.value = {
                        oneofKind: "null",
                        null: reader.bool()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SettingsKeyValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value_string = 2; */
        if (message.value.oneofKind === "valueString")
            writer.tag(2, WireType.LengthDelimited).string(message.value.valueString);
        /* bytes value_array = 3; */
        if (message.value.oneofKind === "valueArray")
            writer.tag(3, WireType.LengthDelimited).bytes(message.value.valueArray);
        /* int64 value_number = 4; */
        if (message.value.oneofKind === "valueNumber")
            writer.tag(4, WireType.Varint).int64(message.value.valueNumber);
        /* double value_double = 5; */
        if (message.value.oneofKind === "valueDouble")
            writer.tag(5, WireType.Bit64).double(message.value.valueDouble);
        /* bool value_bool = 6; */
        if (message.value.oneofKind === "valueBool")
            writer.tag(6, WireType.Varint).bool(message.value.valueBool);
        /* bool null = 7; */
        if (message.value.oneofKind === "null")
            writer.tag(7, WireType.Varint).bool(message.value.null);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.SettingsKeyValue
 */
export const SettingsKeyValue = new SettingsKeyValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Wifi_AP$Type extends MessageType<Wifi_AP> {
    constructor() {
        super("pb.v2.Wifi_AP", [
            { no: 1, name: "ssid", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 33 } } },
            { no: 2, name: "pw", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 63 } } },
            { no: 3, name: "identity", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 33 } } },
            { no: 4, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 33 } } },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 63 } } }
        ]);
    }
    create(value?: PartialMessage<Wifi_AP>): Wifi_AP {
        const message = { ssid: "", pw: "", identity: "", username: "", password: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Wifi_AP>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Wifi_AP): Wifi_AP {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ssid */ 1:
                    message.ssid = reader.string();
                    break;
                case /* string pw */ 2:
                    message.pw = reader.string();
                    break;
                case /* string identity */ 3:
                    message.identity = reader.string();
                    break;
                case /* string username */ 4:
                    message.username = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Wifi_AP, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ssid = 1; */
        if (message.ssid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ssid);
        /* string pw = 2; */
        if (message.pw !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pw);
        /* string identity = 3; */
        if (message.identity !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.identity);
        /* string username = 4; */
        if (message.username !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.username);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Wifi_AP
 */
export const Wifi_AP = new Wifi_AP$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Settings$Type extends MessageType<Settings> {
    constructor() {
        super("pb.v2.Settings", [
            { no: 1, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "serial", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "setting_counter_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "status", kind: "enum", T: () => ["pb.v2.SettingsStatus", SettingsStatus, "SETTINGS_STATUS_"] },
            { no: 14, name: "vendor_settings", kind: "message", T: () => VendorSettings },
            { no: 15, name: "key_values", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingsKeyValue, options: { nanopb: { maxCount: 10 } } },
            { no: 16, name: "primary_ap", kind: "message", T: () => Wifi_AP },
            { no: 17, name: "ap_list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Wifi_AP, options: { nanopb: { maxCount: 5 } } }
        ]);
    }
    create(value?: PartialMessage<Settings>): Settings {
        const message = { uuid: 0n, serial: 0n, version: 0, settingCounterId: 0, status: 0, keyValues: [], apList: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Settings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Settings): Settings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 uuid */ 1:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                case /* uint64 serial */ 2:
                    message.serial = reader.uint64().toBigInt();
                    break;
                case /* uint32 version */ 3:
                    message.version = reader.uint32();
                    break;
                case /* uint32 setting_counter_id */ 4:
                    message.settingCounterId = reader.uint32();
                    break;
                case /* pb.v2.SettingsStatus status */ 13:
                    message.status = reader.int32();
                    break;
                case /* pb.v2.VendorSettings vendor_settings */ 14:
                    message.vendorSettings = VendorSettings.internalBinaryRead(reader, reader.uint32(), options, message.vendorSettings);
                    break;
                case /* repeated pb.v2.SettingsKeyValue key_values */ 15:
                    message.keyValues.push(SettingsKeyValue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* pb.v2.Wifi_AP primary_ap */ 16:
                    message.primaryAp = Wifi_AP.internalBinaryRead(reader, reader.uint32(), options, message.primaryAp);
                    break;
                case /* repeated pb.v2.Wifi_AP ap_list */ 17:
                    message.apList.push(Wifi_AP.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Settings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 uuid = 1; */
        if (message.uuid !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.uuid);
        /* uint64 serial = 2; */
        if (message.serial !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.serial);
        /* uint32 version = 3; */
        if (message.version !== 0)
            writer.tag(3, WireType.Varint).uint32(message.version);
        /* uint32 setting_counter_id = 4; */
        if (message.settingCounterId !== 0)
            writer.tag(4, WireType.Varint).uint32(message.settingCounterId);
        /* pb.v2.SettingsStatus status = 13; */
        if (message.status !== 0)
            writer.tag(13, WireType.Varint).int32(message.status);
        /* pb.v2.VendorSettings vendor_settings = 14; */
        if (message.vendorSettings)
            VendorSettings.internalBinaryWrite(message.vendorSettings, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.SettingsKeyValue key_values = 15; */
        for (let i = 0; i < message.keyValues.length; i++)
            SettingsKeyValue.internalBinaryWrite(message.keyValues[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Wifi_AP primary_ap = 16; */
        if (message.primaryAp)
            Wifi_AP.internalBinaryWrite(message.primaryAp, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.Wifi_AP ap_list = 17; */
        for (let i = 0; i < message.apList.length; i++)
            Wifi_AP.internalBinaryWrite(message.apList[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Settings
 */
export const Settings = new Settings$Type();
