import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from "@ionic/angular";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MiniProfileComponent } from './mini-profile/mini-profile.component';

// Device
import { DeviceStatusComponent, } from './device/device-status/device-status.component'
import { DeviceChartsComponent, } from './device/device-charts/device-charts.component'
import { EditSettingsModalPageModule, } from './device/edit-settings-modal/edit-settings-modal.module';

import { ApexChartComponent, } from "./apex-chart/apex-chart.component"
import { GenericTableComponent, } from "./generic-table/generic-table.component"

import { MaterialModule } from '../material.module';
import { DaySelectorComponent } from './day-selector/day-selector.component';
import { DyGraphSensorComponent } from './dygraph-sensor-view/dygraph-sensor-view.component';
import { TimeSelectorComponent, } from "./time-selector/time-selector.component";

import { DygraphChartComponent } from './dygraph-chart/dygraph-chart.component';
import { NgxGaugeModule } from 'ngx-gauge';

import { NgApexchartsModule } from "ng-apexcharts";

import { MapTilelayerModalComponent } from "./map/map-tilelayer-modal/map-tilelayer-modal.component";
import { MapSettingsModalComponent } from "./map/map-settings-modal/map-settings-modal.component"

import { MapComponentPageModule } from "./map/map-component/map-component.module"
import { SensorTimelineComponentPageModule } from "./map/sensor-timeline-component/sensor-timeline-component.module";
import { SensorMapLineComponentPageModule } from "./map/sensor-map-line-component/sensor-map-line-component.module"
import { MapSelectPositionModalPageModule } from './map/map-select-position-modal/map-select-position-modal.module';

import { AuthImageComponent } from "./auth-image/auth-image.component"

import { ImageAuthPipe } from "./pipes/image-auth.pipe"
import { BigintPipe } from './pipes/bigint.pipe'

import { EventLogComponent } from "./event-log/event-log.component"
import { EventTableComponent } from "./event-table/event-table.component"
import { DeviceListComponent } from './device-list/device-list.component';
import { SensorTimelineComponentPage } from './map/sensor-timeline-component/sensor-timeline-component.page';

import { SelectBluetoothWebModalComponent } from "./select-bluetooth-web-modal/select-bluetooth-web-modal.component"
import { GenericDeviceTableComponent } from './generic-device-table/generic-device-table.component';

import { FullstackComponent } from './fullstack-component/fullstack.component';
import { ChannelComponent } from './channel-component/channel.component';
import { TestRunnerTemplateComponent } from './test-runner-template/test-runner-template.component';


declare global {
	interface Window {
		ApexCharts: any;
	}
}

@NgModule({
	declarations: [
		MapTilelayerModalComponent,
		MapSettingsModalComponent,
		MiniProfileComponent,
		DaySelectorComponent,
		TimeSelectorComponent,
		DyGraphSensorComponent,
		DygraphChartComponent,
		AuthImageComponent,
		GenericTableComponent,
		DeviceStatusComponent,
		DeviceChartsComponent,
		ApexChartComponent,
		ImageAuthPipe,
		BigintPipe,
		EventLogComponent,
		EventTableComponent,
		DeviceListComponent,
		SelectBluetoothWebModalComponent,
		GenericDeviceTableComponent,
		FullstackComponent,
		ChannelComponent,
		TestRunnerTemplateComponent,
	],
	imports: [
		MaterialModule,
		IonicModule,
		CommonModule,
		FormsModule,
		NgxGaugeModule,
		NgApexchartsModule,
		SensorMapLineComponentPageModule,
		MapSelectPositionModalPageModule,
		EditSettingsModalPageModule,
		NgMultiSelectDropDownModule.forRoot(),
	],
	exports: [
		MiniProfileComponent,
		DaySelectorComponent,
		TimeSelectorComponent,
		DyGraphSensorComponent,
		DygraphChartComponent,
		AuthImageComponent,
		GenericTableComponent,
		DeviceStatusComponent,
		DeviceChartsComponent,
		ApexChartComponent,
		ImageAuthPipe,
		BigintPipe,
		EventLogComponent,
		EventTableComponent,
		DeviceListComponent,
		SelectBluetoothWebModalComponent,
		SensorTimelineComponentPageModule,
		GenericDeviceTableComponent,
		FullstackComponent,
		ChannelComponent,
		TestRunnerTemplateComponent,
	],
})

export class ComponentsModule { }
export {
	MaterialModule,
	SensorTimelineComponentPageModule,
	SensorMapLineComponentPageModule,
	DeviceStatusComponent,
}
