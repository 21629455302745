import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MapSelectPositionModalPageRoutingModule } from './map-select-position-modal-routing.module';

import { MapSelectPositionModalPage } from './map-select-position-modal.page';
import { MapSelectPositionComponent } from '../map-select-position/map-select-position.component';

@NgModule({
	declarations: [
		MapSelectPositionComponent,
		MapSelectPositionModalPage,
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		MapSelectPositionModalPageRoutingModule,
	],
})
export class MapSelectPositionModalPageModule {}
