import { Injectable } from '@angular/core';
import opencage from 'opencage-api-client';
import { AllServcies } from '../startup/startup.service';
import { Geolocation } from '@capacitor/geolocation';
import { GPSScanRecord, RfCollections, } from '../../generated_proto/protobuf-ts/pb/v2/data';
import { Position, PositonFixType, } from "../../generated_proto/protobuf-ts/pb/v2/entities";

import axios, { AxiosRequestConfig, AxiosPromise } from "axios";
import { EncryptionService } from '../encryption/encryption.service';
// import * as googleGeolocation from 'google-geolocation'; // DON'T USE THSI :: NODE ONLY AND ITS A SINGLE POST REQUEST. NOT WORTH IT.

export class GeolocateService {

	private geoCodeKey: string = "a81faa83bd864280ab179502a17ab2f6";
	private watchId:string;

	public static GoogleGeolocationApiKey:string = "AIzaSyCE2T5ZyF2uYMBUmlV7bk5dM_jpu6Sadkw";

	private longitude1E7:number = 0;
	private latitude1E7:number = 0;
	private elevationCm:number = 0;
	private gpsScanRecord:GPSScanRecord = GPSScanRecord.create();

	private hasOnePostion:boolean = false;

	constructor
	(

	)
	{

	}

	init(services:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(!services){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(services);

				if(this.services.settings?.SETTINGS?.APP_geoCodeKey){
					this.geoCodeKey = this.services.settings.SETTINGS.APP_geoCodeKey;
				}

				resolve(true);
				await Geolocation.checkPermissions().then( async (result) => {
					if(result.location == "prompt"){
						if( ! (services.platform?.is('ios') || services.platform?.is("desktop" ) )){
							await Geolocation.requestPermissions().then( (result) => {
								console.log("Geolocation.requestPermissions: ", result);
							})
							.catch( (e) => {
								console.error("Geolocation.requestPermissions: ", e);
							});
						}
					}
					if(result.location == "denied"){
						this.services.uiHelper?.showError("Location Permissions Denied, please change in settings");
					}
				})
				.catch( (e) => {
					console.error("Geolocation.checkPermissions: ", e);
				});

				// console.log("Geolocation.requestPermissions()...");
				if( ! (services.platform?.is('ios') || services.platform?.is("desktop" ) )){
					await Geolocation.requestPermissions().then( (result) => {
						console.log("Geolocation.requestPermissions: ", result);
					})
					.catch( (e) => {
						console.error("Geolocation.requestPermissions: ", e);
					});
				}
				await Geolocation.getCurrentPosition().then( (result) => {
					if(result?.coords){
						this.hasOnePostion = true;
						if(result.coords.latitude){
							this.latitude1E7 = Math.round(result.coords.latitude * 1e7);
						}
						if(result.coords.longitude){
							this.longitude1E7 = Math.round(result.coords.longitude * 1e7);
						}
						if(result.coords.accuracy){
							this.gpsScanRecord.geopointAccuracyCm = Math.round(result.coords.accuracy*100);
						}
						if(result.coords.altitudeAccuracy){
							this.gpsScanRecord.elevationAccuracyCm = Math.round(result.coords.altitudeAccuracy*100);
						}
						if(result.coords.altitude){
							this.elevationCm = Math.round(result.coords.altitude*100);
						}
					}
				})
				.catch( (e) => {
					console.error("Geolocation.requestPermissions: ", e);
				});
				
				Geolocation.watchPosition({enableHighAccuracy:true}, (result) => {
					if(result?.coords){
						this.hasOnePostion = true;
						if(result.coords.latitude){
							this.latitude1E7 = Math.round(result.coords.latitude * 1e7);
						}
						if(result.coords.longitude){
							this.longitude1E7 = Math.round(result.coords.longitude * 1e7);
						}
						if(result.coords.accuracy){
							this.gpsScanRecord.geopointAccuracyCm = Math.round(result.coords.accuracy*100);
						}
						if(result.coords.altitudeAccuracy){
							this.gpsScanRecord.elevationAccuracyCm = Math.round(result.coords.altitudeAccuracy*100);
						}
						if(result.coords.altitude){
							this.elevationCm = Math.round(result.coords.altitude*100);
						}
					}
				}).then( (watchId) => {
					// console.log("Geolocation.watchPosition: Started", watchId);
					this.watchId = watchId;
				})
				.catch( (error) => {
					console.log("Geolocation.watchPosition: Error", error);
				})
			}
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	getCurrentPosition() : Promise<{longitude1E7:number, latitude1E7:number, elevationCm:number, gpsScanRecord:GPSScanRecord}> {
		return new Promise( async (resolve, reject) =>  {
			if(this.hasOnePostion){
				resolve({longitude1E7:this.longitude1E7, latitude1E7:this.latitude1E7, elevationCm:this.elevationCm, gpsScanRecord:this.gpsScanRecord});
			}
			else {
				reject({code:0, message:"No Position Found"});
			}
		});
	}

	public async addressToPosition(address: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			// https://github.com/tsamaya/opencage-api-client
			// q: the query string to be geocoded: a placename, address or coordinates as lat,long
			opencage.geocode({ q: address, key: this.geoCodeKey}).then((response: any) => {
				if (response.results.length > 0) {
					resolve(response.results[0].geometry);
				} else {
					reject();
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	public async positionToApproxAddress(latitude:number, longitude:number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			// https://github.com/tsamaya/opencage-api-client
			// q: the query string to be geocoded: a placename, address or coordinates as lat,long
			opencage.geocode({ q: ""+latitude+","+longitude, key: this.geoCodeKey}).then((response: any) => {
				if (response.results.length > 0) {
					if(response.results[0].formatted){
						resolve(response.results[0].formatted);
					}
					else{
						reject("address not found")
					}
				} else {
					reject("no results found");
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	// https://developers.google.com/maps/documentation/geolocation/overview#wifi_access_point_object
	public static RfCollectionsToPosition(rfCollection:RfCollections): Promise<Position> {
		return new Promise<Position>( async (resolve, reject) => {
			var gParams:any = {};
			gParams.considerIp = false;

			if(rfCollection.wifiScanRecord && rfCollection.wifiScanRecord.length ){
				gParams["wifiAccessPoints"] = [];
				for (let index = 0; index < rfCollection.wifiScanRecord.length; index++) {
					const wifiScan = rfCollection.wifiScanRecord[index];
					var googleWifi:any = {};
					if(wifiScan.macAddress){
						googleWifi.macAddress = EncryptionService.toHexString(wifiScan.macAddress);
						if(googleWifi.macAddress){
							googleWifi.macAddress = googleWifi.macAddress.match(/.{2}/g).join(":");
						}
					}
					if(wifiScan.signalStrength){
						googleWifi.signalStrength = wifiScan.signalStrength;
					}
					if(wifiScan.channel){
						googleWifi.channel = wifiScan.channel;
					}
					googleWifi.age = 0;
					googleWifi.signalToNoiseRatio = 0;
					console.log("wifiScan: ", googleWifi);
					gParams["wifiAccessPoints"].push(googleWifi);
				}
			}

			await axios.post( "https://www.googleapis.com/geolocation/v1/geolocate?key="+GeolocateService.GoogleGeolocationApiKey, gParams)
			.then(res => {
				console.log("Google response: ");
				console.log("Google response: ");
				console.log("Google response: ");
				console.log("Google response: ");
				console.log("Google response: ");
				console.log("Google response: ");
				console.log("Google response: ");
				console.log("Google response: ");
				console.log("Google response: ");
				console.log("Google response: ");
				if(res.data){
					console.log("Google response: ", res.data)
					var position:Position = Position.create();
					if(res.data.location){
						position.latitude = res.data.location.lat;
						position.longitude = res.data.location.lng;
						if(res.data.accuracy){
							position.locationPrecisionCm = Math.round(res.data.accuracy*100); // convert to cm
						}
						if(rfCollection.wifiScanRecord && rfCollection.wifiScanRecord.length ){
							position.fixType = PositonFixType.POSITION_FIX_TYPE_WIFI;
						}
						else if(rfCollection.cellScanRecord && rfCollection.cellScanRecord.length){
							position.fixType = PositonFixType.POSITION_FIX_TYPE_CELLID;
						}
					}
					resolve(position);
				}
				else{
					reject("Google response missing correct data set")
				}
			})
			.catch(error => {
				console.error("error is: ", error)
				reject(error);
			});
		});
	}
}
