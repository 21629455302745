
import { BluetoothConnection, } from "./ble.connetion"

import { AllServcies, } from '../startup/startup.service';
import { BluetoothRequestOptions, BluetoothService, } from './bluetooth.service';
import { AllDevicesVMI, } from '../../viewmodels/AllDevices.vmi';
import { DeviceTypeIds } from "../../viewmodels/Device.vmi";
import { ConnectionInformation, Device, } from '../../generated_proto/protobuf-ts/pb/v2/entities';

import { BleClient, numbersToDataView, dataViewToNumbers, numberToUUID, } from '@capacitor-community/bluetooth-le';
import { LoggerOptions } from "../../services/logger/logger.service"
import { UtilService } from "../util/util.service";
import { BluetoothCommonInterface } from "./bluetooth.service";

export class CapacitorBleService {

	// Plugin Used for cordova BLE
	// https://github.com/randdusing/cordova-plugin-bluetoothle

	private loggerOptions:LoggerOptions = {
		prefix:"CapacitorBleService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};
	private bluetoothService:BluetoothService;
	

	constructor(
	)
	{

	}

	init(bluetoothService:BluetoothService, allServices:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(bluetoothService==null || allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
				if(!this.services.platform){
					reject({code:1, message:"Platform Not Given"});
					return;
				}
				if( this.services.platform.is("capacitor") ) {
					this.services.platform.ready().then( async (readySource) => {
						console.log("BluetoothService.init() - Capacitor")
						try {
							this.services.logger?.debug(this.loggerOptions, "BluetoothService.init() - Capacitor");
							await BleClient.initialize();
							await BleClient.enable();

						} catch (error) {
							console.error(error);
							console.log("error.toString(): ", error.toString())
							console.log("error.toString().includes",error.toString().includes("enable is not available on web"))
							if(!error.toString().includes("enable is not available on web")){
								// show util popup error
								// this.services.uiHelper?.showWarning("Bluetooth Error :: Bluetooth is not enabled on this device. Please enable bluetooth and try again.", {duration:2000, userDismiss:true})
							}
						}
					});
				}
				this.bluetoothService = bluetoothService;
				resolve(true);
			}
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	private async delay(milisec) {
		return new Promise(resolve => {
			setTimeout(() => { resolve('') }, milisec);
		})
	}

	public requestDevice(requestOptions:BluetoothRequestOptions) : Promise<any>{
		return new Promise( async (resolve, reject) => {
			try {
				await BleClient.initialize();
				console.log("CAPACITOR ASKING FOR BLE WITH : ", requestOptions)
				let device = await BleClient.requestDevice(requestOptions);
				// optionalServices: [BATTERY_SERVICE, POLAR_PMD_SERVICE],

				this.services.logger?.debug(this.loggerOptions, "requestDevice", device);
				var newDeviceVMI:AllDevicesVMI;
				var makeDevice:BluetoothCommonInterface = {
					name:device.name || "unknown",
					context:device,
					identifier:device.deviceId,
				};
				if(requestOptions.deviceTypeId){
					console.log("requestDevice : deviceTypeId : ")
					makeDevice.deviceTypeId = requestOptions.deviceTypeId;
				}
				console.log("requestDevice : deviceTypeId : ", requestOptions)
				console.log("requestDevice : makeDevice : ", makeDevice)


				newDeviceVMI = await this.bluetoothService.makeDeviceWithType(makeDevice);
				newDeviceVMI.isLocal = true;
				if(newDeviceVMI.model.device){
					newDeviceVMI.model.device.connectionInfo = ConnectionInformation.create();
					newDeviceVMI.model.device.connectionInfo.lastConnectionMs = BigInt(Date.now());
				}
				if( (newDeviceVMI.model.device?.deviceType && newDeviceVMI.model.device?.deviceType > 0) || requestOptions.allowUnknowns){
					resolve(newDeviceVMI);
				}
				else {
					reject("Unknown Device");
				}
			} catch (error) {
				this.services.logger?.error(this.loggerOptions, "requestDevice", error);
				reject(error);
			}
		});
	}

	public getScan(milliseconds_scan:number=2000, allowUnknowns:boolean=false, filterByDeviceType:DeviceTypeIds[]=[]) : Promise<AllDevicesVMI[]> {
		console.log(" :: Capacitor : BLE : Scanning for devices");
		return new Promise( async (resolve, reject)=> {
			var devicesVMI:AllDevicesVMI[] = [];
			if(!this.services.platform){
				reject({code:1, message:"Platform Not Given"});
				return;
			}
			if(this.services.platform.is('capacitor')){
				try {
					await BleClient.initialize();
					await BleClient.requestLEScan({},
						async (peripheral) => {
							var newDeviceVMI:AllDevicesVMI;
							var formatted:BluetoothCommonInterface = {
								name:peripheral.localName || peripheral.device.name || "unknown",
								context:peripheral.device,
								identifier:peripheral.device.deviceId,
							}
							if(peripheral.manufacturerData){
								// iterate though key key
								var keys = Object.keys(peripheral.manufacturerData);
								for(var i=0; i<keys.length; i++){
									var key = keys[i];
									// convert "key" to a number
									var keyNumber = parseInt(key);
									// convert keyNumber to uint32
									var keyNumberUint8 = new Uint8Array([keyNumber]);
									// if keynumber is only one byte, add 00 before
									if(keyNumberUint8.length == 1){
										var keyNumberUint8WithZero = new Uint8Array(2);
										keyNumberUint8WithZero.set(keyNumberUint8, 0);
										keyNumberUint8 = keyNumberUint8WithZero;
									}
									var dataAsUint8= new Uint8Array(peripheral.manufacturerData[key].buffer);
									var dataAsUint8WithKey = new Uint8Array(keyNumberUint8.length + dataAsUint8.length);
									dataAsUint8WithKey.set(keyNumberUint8);
									dataAsUint8WithKey.set(dataAsUint8, keyNumberUint8.length);
									formatted.manufactureData = dataAsUint8WithKey;
								}
							}
							console.log("asking to make device with type : ", formatted)
							try{
								newDeviceVMI = await this.bluetoothService.makeDeviceWithType(formatted);
							}
							catch(error){
								console.error("Failed to make device with type : ", error)
								return;
							}
							// console.log('received new scan result', peripheral);
							newDeviceVMI.isLocal = true;
							if(!newDeviceVMI.bluetoothConnection){
								reject({code:2, message:"Bluetooth Connection Not Found"});
								return;
							}
							newDeviceVMI.bluetoothConnection.setContext(peripheral.device);
							if(peripheral.rssi){
								newDeviceVMI.bluetoothConnection.rssi = peripheral.rssi;
							}
							if(newDeviceVMI.model.device){
								newDeviceVMI.model.device.connectionInfo = ConnectionInformation.create();
								newDeviceVMI.model.device.connectionInfo.lastConnectionMs = BigInt(Date.now());
							}
							if( (newDeviceVMI.model.device?.deviceType && newDeviceVMI.model.device?.deviceType > 0) || allowUnknowns){
								// console.log("Perhipheral Found: ", peripheral);
								var found = false;
								// Deduplication -> some platforms dont do this correctly, so checking ourselves.
								for(var i = 0; i < devicesVMI.length; i++) {
									if(!devicesVMI[i] || !devicesVMI[i].bluetoothConnection){
										continue;
									}
									else if (devicesVMI && devicesVMI[i] && devicesVMI[i].bluetoothConnection != null && devicesVMI[i].bluetoothConnection!.bleContext.deviceId == peripheral.device.deviceId) {
										found = true;
										break;
									}
								}
								if(!found){
									var pushDeviceType:boolean = false;
									if(this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES && this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES.length){
										for(var i=0; i<this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES.length; i++){
											if(this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES[i] == newDeviceVMI.model.device?.deviceType){
												pushDeviceType = true;
												break;
											}
										}
									}
									else {
										pushDeviceType = true;
									}
									if(pushDeviceType){
										devicesVMI.push(newDeviceVMI);
									}
								}
							}
						}
					);
					var firstScan:boolean = true;
					var waitScan = setInterval(async () => {
						if(devicesVMI.length>0 && !firstScan){
							console.log(" :: Cordova : BLE : Scanning Complete");
							await BleClient.stopLEScan();
							// devicesVMI = devicesVMI.sort((a, b) => b.getSensor().getPersonUuid()-a.getSensor().getPersonUuid())
							clearInterval(waitScan);
							resolve(devicesVMI);
						}
						else {
							if(firstScan){
								setTimeout( () => {
									console.log(" :: Waiting for first scan ::");
									firstScan = false;
								}, milliseconds_scan);
							}
						}
					}, milliseconds_scan);
				} catch (error) {
					console.error(error);
				}
			}
		});
	}

	public async connect( bluetoothConnection:BluetoothConnection ) : Promise<boolean> {
		return await new Promise( async (resolve, reject) => {
			if(bluetoothConnection.bluetoothCommonInterface.identifier == null || bluetoothConnection.bluetoothCommonInterface.identifier  == undefined ){
				reject("No Identifier set");
				return;
			}

			if(bluetoothConnection.getContext() == null || bluetoothConnection.getContext() == undefined){
				if((this.services.platform?.is('desktop') || (this.services.platform?.is("android") && this.services.platform?.is("mobileweb"))) ||
					(!this.services.platform?.is('ios') && (this.services.platform?.is("tablet") && this.services.platform?.is("hybrid"))) ||
					this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] == true
				){
					console.warn(" :: Capacitor : BLE : Reconnecting to device : deal with the reconnecting on web");
					console.log("Forcing Web Bluetooth : ", bluetoothConnection)
					if(!bluetoothConnection.bluetoothCommonInterface.name){
						reject("connect: missing connection");
					}
					else {
						var getRequesetFilter = bluetoothConnection.getRequesetFilter();
						var request:any = {
							name:bluetoothConnection.bluetoothCommonInterface.name
						}
						if(getRequesetFilter.optionalServices){
							request.optionalServices = getRequesetFilter.optionalServices;
						}
						console.log(" :: Capacitor : BLE : Optional services : ", request)
						let device = await BleClient.requestDevice(request);
						console.log(" :: Capacitor : BLE : Connecting to device : ", device);
						if(device.deviceId){
							bluetoothConnection.bluetoothCommonInterface.identifier = device.deviceId;
						}
					}
				}
				else {
					if(bluetoothConnection.bluetoothCommonInterface.identifier){
						await BleClient.getDevices([bluetoothConnection.bluetoothCommonInterface.identifier]).then( async (devices) => {
							if(devices.length > 0){
								bluetoothConnection.setContext({device:devices[0]});
							}
						});
					}
					else {
						console.log("missing identifier : ", bluetoothConnection.bluetoothCommonInterface);
						reject("connect: missing connection");
						return;
					}
				}
			}
			console.log("context is : ", bluetoothConnection.bluetoothCommonInterface.identifier);
			try {
				// var devices = await BleClient.getDevices([bluetoothConnection.bluetoothCommonInterface.identifier]);
				console.log(" :: Capacitor : BLE : Connecting to device : ", bluetoothConnection.bluetoothCommonInterface.identifier);
				await BleClient.connect(bluetoothConnection.bluetoothCommonInterface.identifier, () => this.onDisconnect(bluetoothConnection));
				console.log('connected to device', bluetoothConnection.bluetoothCommonInterface.identifier);
				bluetoothConnection.setIsConnected(true);
				await this.delay(100); // could remove?
				resolve(true);
			}
			catch (error) {
				console.error("Connected got an error : "+bluetoothConnection.bluetoothCommonInterface.identifier+" :", error);
				bluetoothConnection.setIsConnected(false);
				reject(error);
			}
		});
	}

	private async onDisconnect(bluetoothConnection:BluetoothConnection) {
		console.log(" :: Capacitor : BLE : Disconnected from the device : ");
		bluetoothConnection.setIsConnected(false);
		if(bluetoothConnection.keepConnected){
			bluetoothConnection.initalizeDevice();
		}
	}

	public async disconnect( bluetoothConnection:BluetoothConnection ) : Promise<boolean> {
		return await new Promise( async (resolve, reject) => {
			if(bluetoothConnection.bluetoothCommonInterface.identifier == null || bluetoothConnection.bluetoothCommonInterface.identifier  == undefined ){
				reject("No Identifier set");
				return;
			}
			try {
				console.log("CALLED DISCONNECT")
				await BleClient.disconnect(bluetoothConnection.bluetoothCommonInterface.identifier);
				console.log('Disconnected device', bluetoothConnection.bluetoothCommonInterface.identifier);
				resolve(true);
			}
			catch (error) {
				console.error("Disconnected got an error : ", error);
				reject(error);
			}
		});
	}

	public async forceGetCharacteristics(bluetoothConnection:BluetoothConnection) : Promise<any>{
		return await new Promise((resolve, reject) => {
			resolve(true);
			// var params = {address:bluetoothConnection.getContext().address}
			// console.log("Asing for services of params :  ", params);
			// this.services.cordovaBluetooth?.discover(params).then( (response) => {
			// 	var context = bluetoothConnection.getContext();
			// 	context.services = response.services;
			// 	console.log(":: Cordova : BLE : forceGetCharacteristics got services : ", response);
			// 	resolve(response);
			// })
			// .catch( (e) => {
			// 	console.log("Failure to get characteristcs?");
			// 	reject(e);
			// })
		});
	}

	public async writeCharacteristic( bluetoothConnection:BluetoothConnection, service_input:string|number="", characteristic_input:string|number="", data:Uint8Array, withoutResponse:boolean=false) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			console.log("calling write characteristic from capacitor")
			var service:string = "";
			if(typeof service_input == "string"){
				service = service_input
			}
			if(typeof service_input == "number"){
				service = numberToUUID(service_input);
			}

			var characteristic:string = "";
			if(typeof characteristic_input == "string"){
				characteristic = characteristic_input
			}
			if(typeof characteristic_input == "number"){
				characteristic = numberToUUID(characteristic_input);
			}

			if(!bluetoothConnection.getIsConnected()){
				console.warn("called write when not connected")
				reject("called write when not connected");
				return;
			}

			console.log(" Asking to writeCharacteristic : Service: ", service, " :: Characteristic :",characteristic);
			try{
				if(bluetoothConnection.bluetoothCommonInterface.identifier){
					var services = await BleClient.getServices(bluetoothConnection.bluetoothCommonInterface.identifier);
					console.log("translated : 0xFF00 : ", numberToUUID(0xFF00));
					console.log("service_input : ", service_input );
					
					console.log(" :: Capacitor : BLE : writeCharacteristic got services : ", services);
					console.log(" :: Capacitor : BLE : writeCharacteristic got data : ", data);
					if(withoutResponse){
						console.log(" :: Writing without response : ");
						await BleClient.writeWithoutResponse(bluetoothConnection.bluetoothCommonInterface.identifier, service, characteristic, new DataView(data.buffer));
					}
					else{
						console.log(" :: Writing with response ");
						await BleClient.write(bluetoothConnection.bluetoothCommonInterface.identifier, service, characteristic, new DataView(data.buffer));
					}
					resolve(true);
				}
				else {
					reject("missing identifier");
				}
				
			}
			catch(error){
				reject(error);
			}
		});
	}

	public readCharacteristic( bluetoothConnection:BluetoothConnection, service_input:string|number="", characteristic_input:string|number="" ) : Promise<Uint8Array> {
		return new Promise( async (resolve, reject) => {
			var service:string = "";
			if(typeof service_input == "string"){
				service = service_input
			}
			if(typeof service_input == "number"){
				service = numberToUUID(service_input);
			}
			var characteristic:string = "";
			if(typeof characteristic_input == "string"){
				characteristic = characteristic_input
			}
			if(typeof characteristic_input == "number"){
				characteristic = numberToUUID(characteristic_input);
			}
			if(!bluetoothConnection.getIsConnected()){
				console.warn("called write when not connected")
				reject("called write when not connected");
				return;
			}
			console.log(" Asking to readCharacteristic : Service: ", service, " :: Characteristic :",characteristic);
			try{
				if(bluetoothConnection.bluetoothCommonInterface.identifier){
					var services = await BleClient.getServices(bluetoothConnection.bluetoothCommonInterface.identifier);
					console.log(" :: Capacitor : BLE : readCharacteristic got services : ", services);
					console.log("service_input : ", service_input, " translated : ",service );
					console.log("characteristic_input : ", characteristic_input, " translated : ",characteristic );
					var value = await BleClient.read(bluetoothConnection.bluetoothCommonInterface.identifier, service, characteristic);
					console.log(" :: Capacitor : BLE : readCharacteristic got value : ", value);
					resolve(new Uint8Array(value.buffer));
				}
				else {
					reject("missing identifier");
				}
			}
			catch(error){
				reject(error);
			}
		});
	}

	public async subscribeCharacteristic( bluetoothConnection:BluetoothConnection, service_input:string|number="", characteristic_input:string|number="", cb:(data:Uint8Array)=>void ) : Promise<boolean>{
		return new Promise( async (resolve, reject)=>{
			var service:string = "";
			if(typeof service_input == "string"){
				service = service_input
			}
			if(typeof service_input == "number"){
				service = numberToUUID(service_input);
				console.log("[subscribeCharacteristic] :: Changing service to uuid: ", service_input, " :: ", service)
			}
			var characteristic:string = "";
			if(typeof characteristic_input == "string"){
				characteristic = characteristic_input
			}
			if(typeof characteristic_input == "number"){
				characteristic = numberToUUID(characteristic_input);
				console.log("[subscribeCharacteristic] :: Changing characteristic to uuid: ", characteristic_input, " :: ", characteristic)
			}
			if(!bluetoothConnection.getIsConnected()){
				console.warn("[subscribeCharacteristic] :: called subscribeCharacteristic when not connected")
				reject("[subscribeCharacteristic] :: called subscribeCharacteristic when not connected")
				return;
			}
			if(! bluetoothConnection.bluetoothCommonInterface.identifier){
				reject("[subscribeCharacteristic] :: missing deviceId");
				return;
			}
			console.log("[subscribeCharacteristic] :: Asking to subscribeCharacteristic : Service: ", service, " :: Characteristic :",characteristic);
			await BleClient.startNotifications(
				bluetoothConnection.bluetoothCommonInterface.identifier,
				service,
				characteristic,
				(data:DataView) => {
					var dataViewNumbers = dataViewToNumbers(data);
					// console.log("[subscribeCharacteristic] :: Capacitor : BLE : Got data from subscribeCharacteristic : ", dataViewNumbers);
					var raw_data:Uint8Array = new Uint8Array(data.buffer);
					if(raw_data){
						cb(raw_data);
					}
				}
			).then( (value) => {
				console.log("[subscribeCharacteristic] :: Subscribed to : Servcie: ", service, " :: Characteristic :",characteristic);
				console.log("[subscribeCharacteristic] :: Got value : ", value);
			})
			.catch( (e) => {
				console.log("[subscribeCharacteristic] :: Failure to subscribeCharacteristic?");
				reject(e);
			});
			resolve(true);
		});
	}

	public async indicateCharacteristic( bluetoothConnection:BluetoothConnection, service_string:string="", characteristic_string:string="", cb:(data:Uint8Array)=>void ) : Promise<boolean>{
		return new Promise( async (resolve, reject)=>{
			if(!bluetoothConnection.getIsConnected()){
				console.warn("called write when not connected")
				reject("called write when not connected")
				return;
			}
			console.log(" Asking to indicateCharacteristic : Servcie: ", service_string, " :: Characteristic :",characteristic_string);
			resolve(true);
			// var target_service:string = "";
			// var target_characteristic:string = "";
			// var context = bluetoothConnection.getContext();
			// if(context.services){
			// 	context.services.map( service => {
			// 		if(service.characteristics){
			// 			service.characteristics.map( (characteristic)=> {
			// 				if(characteristic.uuid.replace(/-/g, "").normalize().toLowerCase() ===  characteristic_string.normalize().toLowerCase()){
			// 					// console.log(" ::: FOUND IT : ", characteristic_string);
			// 					target_service = service.uuid;
			// 					target_characteristic = characteristic.uuid;
			// 				}
			// 			})
			// 		}
			// 	});
			// }
			// else {
			// 	reject("need to get services and characteristics");
			// }
			// if(target_service.length <= 0 && target_characteristic.length <= 0){
			// 	reject("service/characteristic not found");
			// 	return;
			// }
			// var params = {address:context.address, service:target_service, characteristic:target_characteristic}
			// this.services.cordovaBluetooth?.subscribe(params).subscribe( (response) => {
			// 	if( response.status == "subscribed"){
			// 		resolve(true);
			// 	}
			// 	else if( response.status == "subscribedResult"){
			// 		var raw_data:Uint8Array|undefined = this.services.cordovaBluetooth?.encodedStringToBytes(response.value);
			// 		if(raw_data){
			// 			cb(raw_data);
			// 		}
			// 	}
			// },
			// (err)=> {
			// 	console.error(" :: Cordova : BLE : sub error : ", err);
			// })
		});
	}
}
