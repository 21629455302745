import { DeviceViewModelImplemented } from "../../viewmodels/Device.vmi";

export class FixedGatewaytDeviceViewModelImplemented extends DeviceViewModelImplemented {


	public static getStaticTitle() : string {
		return "Fixed Gateway"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return FixedGatewaytDeviceViewModelImplemented.getStaticTitle();
	}

	public thumbnailImagePath:string = "assets/app/rl/fixed-gateway.jpg";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}
}