import { Injectable } from '@angular/core';

import { AllServcies } from "../startup/startup.service"


import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { Person } from 'src/app/generated_proto/protobuf-ts/pb/v2/entities';
export class HttpApiClientService {


	constructor(

	)
	{

	}


	init(services:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(!services){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(services);
				resolve(true);
			}
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	private getDbUrl():Promise<string>{
		return new Promise( async (resolve, reject) => {
			if(this.services.pouch){
				try{
					var db_url = await this.services.pouch.getDbUrl();
					resolve(db_url);
				}
				catch(err){
					reject(err);
				}
			}
			else {
				reject("no pouch service");
			}
		});
	}

	public login( username:string, password:string, ) : Promise<string> {
		return new Promise( async (resolve, reject) => {
			try{
				console.log("Calling login: ", username, password);
				var db_url = await this.getDbUrl();
				console.log("Calling on url: ", db_url);
				await axios.delete( db_url+"_session", { withCredentials: true }).then( (resp) => {
					console.log("deleted session: ", resp)
				})
				await axios.post( db_url+"auth", {username:username, password:password})
				.then(res => {
					if(res.data.data.token){
						resolve(res.data.data.token);
					}
					else{
						reject("Response missing token")
					}
				})
				.catch(error => {
					console.log("error is: ", error)
					reject(error);
				});
			}
			catch(e){
				reject(e);
			}
		});
	}

	public logout() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			try{
				var db_url = await this.getDbUrl();
				await axios.delete( db_url+"_session", { withCredentials: true }).then( (resp) => {
					console.log("deleted session: ", resp)
					resolve(true);
				})
				.catch(error => {
					console.log("error is: ", error)
					reject(error);
				});
			}
			catch(e){
				reject(e);
			}
		});
	}

	public getUser() : Promise<Person>{
		return new Promise( async (resolve, reject) => {
			if(!this.services){
				console.error("bad services");
				reject("services")
			}
			if(this.services && this.services.auth && this.services.auth.hasAuth){
				var db_url = await this.getDbUrl();
				var token = await this.getAuthToken();
				await axios.get(db_url+"user", {
					headers: {
						'Authorization': 'Bearer '+token
					}
				})
				.then(async res => {
					if(res.data.data){
						// console.log("got user: ", res.data.data)
						try{
							var person = Person.fromJson(res.data.data)
							resolve(person);
						}
						catch(e){
							console.error("Error parsing user: ", e);
							reject(e);
						}
					}
					else {
						reject("server issue getting user");
					}
				})
				.catch(error => {
					reject(error);
				});
				console.log()
			}
			else {
				reject("not authenticated")
			}
		});
	}

	public updateUser(person:Person) : Promise<Person>{
		return new Promise( async (resolve, reject) => {
			if(!this.services){
				console.error("bad services");
				reject("services")
			}
			if(this.services && this.services.auth && this.services.auth.hasAuth){
				var db_url = await this.getDbUrl();
				var token = await this.getAuthToken();
				await axios.post(db_url+"user",Person.toJson(person), {
					headers: {
						'Authorization': 'Bearer '+token
					}
				})
				.then(async res => {
					if(res.data.data){
						console.log("Got response user: ", res.data.data);
						try{
							var person = Person.fromJson(res.data.data)
							resolve(person);
						}
						catch(e){
							console.error("Error parsing user: ", e);
							reject(e);
						}
					}
					else {
						reject("server issue updating user");
					}
				})
				.catch(error => {
					reject(error);
				});
				console.log()
			}
			else {
				reject("not authenticated")
			}
		});
	}

	createUser(appId:number, username:string, password?:string) : Promise<string>{
		return new Promise( async (resolve, reject) => {
			if(!this.services){
				console.error("bad services");
				reject("services")
			}
			if(this.services && this.services.auth && this.services.auth.hasAuth){
				var db_url = await this.getDbUrl();
				var token = await this.getAuthToken();
				console.log("calling with token: ", token);

				await axios.post(db_url+"user/create",{
					appId:appId,
					username:username,
					password:password,
				}, {
					headers: {
						'Authorization': 'Bearer '+token
					}
				})
				.then(async res => {
					if(res.data.data.password){
						resolve(res.data.data.password);
					}
					else {
						reject("server issue creating user");
					}
				})
				.catch(error => {
					console.log("error is: ", error)
					reject(error);
				});
				console.log()
			}
			else {
				reject("not authenticated")
			}
		});
	}

	getAuthToken() : Promise<string> {
		return new Promise( async (resolve, reject) => {
			if(!this.services || !this.services.auth){
				console.error("bad services");
				reject("services")
				return;
			}
			this.services.auth.getAuth().then( (foundAuth) => {
				if(foundAuth?.method.oneofKind == "token"){
					resolve(foundAuth.method.token.token);
				}
				else {
					reject("no token found");
				}
			}).catch( (e)=> {
				console.error("Auth Error: ", e);
			})
		});
	}

	getUsers(appId:number) : Promise<Person[]>{
		return new Promise( async (resolve, reject) => {
			if(!this.services){
				console.error("bad services");
				reject("services")
			}
			try{
				console.log("Calling getUsers");
				var db_url = await this.getDbUrl();
				console.log("Calling on url: ", db_url);
				if(this.services && this.services.auth && this.services.auth.hasAuth){
					var token = await this.getAuthToken();
					console.log("calling with token: ", token);
					await axios.post(db_url+"users",{
						appId:appId
					}, {
						headers: {
							'Authorization': 'Bearer '+token
						}
					})
					.then(async res => {
						// Handle response
						var users:Person[] = [];
						console.log("Finished the response : ", res.data.data)
						if(res.data.data.users){
							for (let index = 0; index < res.data.data.users.length; index++) {
								const user = res.data.data.users[index];
								users.push( await Person.fromJson(user, {ignoreUnknownFields: true}));
							}
						}
						resolve(users);
					})
					.catch(error => {
						console.log("error is: ", error)
						reject(error);
					});
					console.log()
				}
				else {
					reject("not authenticated")
				}
			}
			catch(e){
				reject(e);
			}
		});
	}
}
