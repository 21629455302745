// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/v2/entities.proto" (package "pb.v2", syntax proto3)
// tslint:disable
//
// Storage Subjects w/in documnets/nodes. IE: File can contain multiple subjects. Many Message Types can be stored in a single file/document.
// VM Pulls them out for viewing.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { KeyValue } from "./data";
import { MessagePack } from "./data";
import { Settings } from "./settings";
/**
 * @generated from protobuf message pb.v2.Position
 */
export interface Position {
    /**
     * @generated from protobuf field: float latitude = 1;
     */
    latitude: number;
    /**
     * @generated from protobuf field: float longitude = 2;
     */
    longitude: number;
    /**
     * @generated from protobuf field: sint32 elevation_cm = 3;
     */
    elevationCm: number; // Height in cm, 0 is sea-level
    /**
     * @generated from protobuf field: uint64 geohash = 4;
     */
    geohash: bigint;
    /**
     * @generated from protobuf field: uint32 location_precision_cm = 5;
     */
    locationPrecisionCm: number;
    /**
     * @generated from protobuf field: pb.v2.PositonFixType fix_type = 6;
     */
    fixType: PositonFixType; // 0=no lock, 1=IMU, 2=2D lock, 3=3d lock, 4=gps+IMU  5=time only, 6=Wifi , 7=CellID, 8=IP Based, 8
    /**
     * @generated from protobuf field: string plus_code = 7;
     */
    plusCode: string; // Open Location Code :: https://github.com/google/open-location-code
    /**
     * @generated from protobuf field: uint64 epoch_ms = 8;
     */
    epochMs: bigint;
    /**
     * @generated from protobuf field: optional sint32 rssi = 9;
     */
    rssi?: number; // depending on the fix type, this RSSI of bluetooth, cellular, wifi, etc.
    /**
     * @generated from protobuf field: optional string gateway_telemetry_urn = 10;
     */
    gatewayTelemetryUrn?: string; // the urn of the telemetry used to create this position
}
/**
 * @generated from protobuf message pb.v2.Address
 */
export interface Address {
    /**
     * @generated from protobuf field: string address_line_1 = 1;
     */
    addressLine1: string;
    /**
     * @generated from protobuf field: string address_line_2 = 2;
     */
    addressLine2: string;
    /**
     * @generated from protobuf field: string city = 3;
     */
    city: string;
    /**
     * @generated from protobuf field: string state = 4;
     */
    state: string;
    /**
     * @generated from protobuf field: string zip = 5;
     */
    zip: string; // zip code is string because it can be 5 or 9 digits with dash
    /**
     * @generated from protobuf field: string country = 6;
     */
    country: string;
}
// //////////////////////////////
// Stored Data
// //////////////////////////////
// This data is stored as is, in the server. Translations into actual models to be used
// is done at the client side into VMI's and other protos. This is due to the storage
// of several messages being split into several protos which are stored in different locations/dbs/services.
// Particularly -> repeated fields are converted to their urn's. They can be fetched independently.

/**
 * Micro uTP/uRPC network connected information.
 *
 * @generated from protobuf message pb.v2.ConnectionInformation
 */
export interface ConnectionInformation {
    /**
     * @generated from protobuf field: uint64 last_connection_ms = 1;
     */
    lastConnectionMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint32 micro_dev_id = 2;
     */
    microDevId: number; // utp hash id -> hash of the public key lookup
    /**
     * @generated from protobuf field: bytes public_key = 3;
     */
    publicKey: Uint8Array; // Public key for encrypting data
    /**
     * @generated from protobuf field: bytes private_key = 4;
     */
    privateKey: Uint8Array; // Private key for decrypting data (typically not used on server)
    /**
     * @generated from protobuf field: bytes ip_v4 = 5;
     */
    ipV4: Uint8Array; // ipv4 address
    /**
     * @generated from protobuf field: bytes ip_v6 = 6;
     */
    ipV6: Uint8Array; // ipv6 address
    /**
     * @generated from protobuf field: uint32 port_in = 7;
     */
    portIn: number; // port in (received from device)
    /**
     * @generated from protobuf field: uint32 port_out = 8;
     */
    portOut: number; // port out (sent to device)
}
/**
 * @generated from protobuf message pb.v2.AppleAdvertisement
 */
export interface AppleAdvertisement {
    /**
     * @generated from protobuf field: pb.v2.AppleAdvertisement.AppleUnknown unknown = 1;
     */
    unknown?: AppleAdvertisement_AppleUnknown;
    /**
     * @generated from protobuf field: pb.v2.AppleAdvertisement.IBeacon iBeacon = 2;
     */
    iBeacon?: AppleAdvertisement_IBeacon;
    /**
     * @generated from protobuf field: pb.v2.AppleAdvertisement.FindMy findMy = 3;
     */
    findMy?: AppleAdvertisement_FindMy;
    /**
     * @generated from protobuf field: pb.v2.AppleAdvertisement.Handoff handoff = 4;
     */
    handoff?: AppleAdvertisement_Handoff;
    /**
     * @generated from protobuf field: pb.v2.AppleAdvertisement.NearbyInfo nearbyInfo = 5;
     */
    nearbyInfo?: AppleAdvertisement_NearbyInfo;
}
/**
 * @generated from protobuf message pb.v2.AppleAdvertisement.AppleUnknown
 */
export interface AppleAdvertisement_AppleUnknown {
    /**
     * @generated from protobuf field: uint32 type = 1;
     */
    type: number;
    /**
     * @generated from protobuf field: bytes data = 2;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message pb.v2.AppleAdvertisement.IBeacon
 */
export interface AppleAdvertisement_IBeacon {
    /**
     * @generated from protobuf field: bytes iBeacon_UUID = 1;
     */
    iBeaconUUID: Uint8Array; // 16 bytes
    /**
     * @generated from protobuf field: uint32 iBeacon_major = 2;
     */
    iBeaconMajor: number; // 2 bytes
    /**
     * @generated from protobuf field: uint32 iBeacon_minor = 3;
     */
    iBeaconMinor: number; // 2 bytes
    /**
     * @generated from protobuf field: uint32 iBeacon_tx_power = 4;
     */
    iBeaconTxPower: number; // 1 byte
}
/**
 * https://github.com/furiousMAC/continuity/blob/master/messages/findmy.md
 *
 * @generated from protobuf message pb.v2.AppleAdvertisement.FindMy
 */
export interface AppleAdvertisement_FindMy {
    /**
     * Loaded from the user, or onboarding, or such. To relate at a later time
     *
     * @generated from protobuf field: bytes findMy_public_key = 1;
     */
    findMyPublicKey: Uint8Array; // set public key
    /**
     * @generated from protobuf field: bytes findMy_private_key = 2;
     */
    findMyPrivateKey: Uint8Array; // set private key, this is never advertised, but can be used for lookup
    /**
     * From Advertised
     *
     * @generated from protobuf field: uint32 findMy_status = 3;
     */
    findMyStatus: number; // https://gitlab.com/peprojects/purelocate/purelocate-audiotag-firmware/-/blob/master/app/audio_tag/src/main.c#L105
    /**
     * @generated from protobuf field: bytes findMy_advertised_public_key = 4;
     */
    findMyAdvertisedPublicKey: Uint8Array; // Public key bytes 6..27 as seen, possibly with the N+1 rollover
    /**
     * @generated from protobuf field: uint32 findMy_first_bits_of_advertised_public_key = 5;
     */
    findMyFirstBitsOfAdvertisedPublicKey: number; // First two bits of public key
    /**
     * @generated from protobuf field: uint32 findMy_hint = 6;
     */
    findMyHint: number; // Hint (0x00 on iOS reports)
}
/**
 * https://github.com/furiousMAC/continuity/blob/master/messages/handoff.md
 *
 * @generated from protobuf message pb.v2.AppleAdvertisement.Handoff
 */
export interface AppleAdvertisement_Handoff {
    /**
     * @generated from protobuf field: uint32 version = 1;
     */
    version: number; // single byte
    /**
     * @generated from protobuf field: uint32 iv = 2;
     */
    iv: number; // 2 bytes
    /**
     * @generated from protobuf field: uint32 auth = 3;
     */
    auth: number; // 1 byte
    /**
     * @generated from protobuf field: bytes data = 4;
     */
    data: Uint8Array; // 10 bytes
}
/**
 * https://github.com/furiousMAC/continuity/blob/master/messages/nearby_info.md
 *
 * @generated from protobuf message pb.v2.AppleAdvertisement.NearbyInfo
 */
export interface AppleAdvertisement_NearbyInfo {
    /**
     * @generated from protobuf field: uint32 status = 1;
     */
    status: number; // single byte
}
/**
 * https://github.com/google/eddystone/blob/master/eddystone-tlm/tlm-plain.md
 *
 * @generated from protobuf message pb.v2.EddystoneTLM
 */
export interface EddystoneTLM {
    /**
     * @generated from protobuf field: uint32 battery_voltage_mv = 1;
     */
    batteryVoltageMv: number;
    /**
     * @generated from protobuf field: sint32 temperature = 2;
     */
    temperature: number;
    /**
     * @generated from protobuf field: uint32 advertisement_frames_count = 3;
     */
    advertisementFramesCount: number;
    /**
     * @generated from protobuf field: uint32 second_resolution_counter = 4;
     */
    secondResolutionCounter: number;
}
/**
 * https://github.com/google/eddystone/blob/master/eddystone-tlm/tlm-encrypted.md
 *
 * @generated from protobuf message pb.v2.EddystoneTLM_Encrypted
 */
export interface EddystoneTLM_Encrypted {
    /**
     * @generated from protobuf field: bytes encrypted = 1;
     */
    encrypted: Uint8Array;
    /**
     * @generated from protobuf field: uint32 salt = 2;
     */
    salt: number; // The 16 bit random salt
    /**
     * @generated from protobuf field: uint32 mic = 3;
     */
    mic: number; // The 16-bit integrity check tag
}
/**
 * https://github.com/google/eddystone/tree/master/eddystone-eid
 *
 * @generated from protobuf message pb.v2.EddystoneEID
 */
export interface EddystoneEID {
    /**
     * @generated from protobuf field: sint32 tx_power = 1;
     */
    txPower: number; // Tx power is the received power at 0 meters, in dBm, and the value ranges from -100 dBm to +20 dBm to a resolution of 1 dBm.
    /**
     * @generated from protobuf field: bytes ephemeral_identifier = 2;
     */
    ephemeralIdentifier: Uint8Array;
}
/**
 * https://github.com/google/eddystone/tree/master/eddystone-uid
 *
 * @generated from protobuf message pb.v2.EddystoneUID
 */
export interface EddystoneUID {
    /**
     * @generated from protobuf field: sint32 tx_power = 1;
     */
    txPower: number; // Tx power is the received power at 0 meters, in dBm, and the value ranges from -100 dBm to +20 dBm to a resolution of 1 dBm.
    /**
     * @generated from protobuf field: bytes namespace_id = 2;
     */
    namespaceId: Uint8Array; // 10-byte Namespace
    /**
     * @generated from protobuf field: bytes instance_id = 3;
     */
    instanceId: Uint8Array; // 6-byte Instance
}
/**
 * https://github.com/google/eddystone/tree/master/eddystone-url
 *
 * @generated from protobuf message pb.v2.EddystoneURL
 */
export interface EddystoneURL {
    /**
     * @generated from protobuf field: sint32 tx_power = 1;
     */
    txPower: number; // Tx power is the received power at 0 meters, in dBm, and the value ranges from -100 dBm to +20 dBm to a resolution of 1 dBm.
    /**
     * @generated from protobuf field: uint32 url_scheme = 2;
     */
    urlScheme: number;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string; // 1-17 bytes
}
/**
 * https://github.com/google/eddystone
 *
 * @generated from protobuf message pb.v2.Eddystone
 */
export interface Eddystone {
    /**
     * Each instance may contain one or more of the following messages. IE: A beacon may advertise both a UID and a URL
     *
     * @generated from protobuf field: pb.v2.EddystoneTLM eddystone_telemetry = 1;
     */
    eddystoneTelemetry?: EddystoneTLM; // Most common
    /**
     * @generated from protobuf field: pb.v2.EddystoneTLM_Encrypted eddystone_telemetry_encrypted = 2;
     */
    eddystoneTelemetryEncrypted?: EddystoneTLM_Encrypted;
    /**
     * @generated from protobuf field: pb.v2.EddystoneEID eddystone_eid = 3;
     */
    eddystoneEid?: EddystoneEID;
    /**
     * @generated from protobuf field: pb.v2.EddystoneUID eddystone_uid = 4;
     */
    eddystoneUid?: EddystoneUID;
    /**
     * @generated from protobuf field: pb.v2.EddystoneURL eddystone_url = 5;
     */
    eddystoneUrl?: EddystoneURL;
}
/**
 * https://btprodspecificationrefs.blob.core.windows.net/assigned-numbers/Assigned%20Number%20Types/Assigned_Numbers.pdf
 *
 * @generated from protobuf message pb.v2.GAPCommonDataType
 */
export interface GAPCommonDataType {
    /**
     * Flags parsed out
     *
     * @generated from protobuf field: uint32 flags = 1;
     */
    flags: number;
    /**
     * @generated from protobuf field: bool limitedDiscoverableMode = 2;
     */
    limitedDiscoverableMode: boolean;
    /**
     * @generated from protobuf field: bool generalDiscoverableMode = 3;
     */
    generalDiscoverableMode: boolean;
    /**
     * @generated from protobuf field: bool brEdrNotSupported = 4;
     */
    brEdrNotSupported: boolean;
    /**
     * @generated from protobuf field: bool simultaneousLeBrEdrController = 5;
     */
    simultaneousLeBrEdrController: boolean;
    /**
     * @generated from protobuf field: bool simultaneousLeBrEdrHost = 6;
     */
    simultaneousLeBrEdrHost: boolean;
    /**
     * @generated from protobuf field: repeated uint32 service_uuids_16 = 7;
     */
    serviceUuids16: number[];
    /**
     * @generated from protobuf field: repeated uint32 service_uuids_32 = 8;
     */
    serviceUuids32: number[];
    /**
     * @generated from protobuf field: repeated bytes service_uuids_128 = 9;
     */
    serviceUuids128: Uint8Array[];
    /**
     * @generated from protobuf field: string local_name_short = 10;
     */
    localNameShort: string;
    /**
     * @generated from protobuf field: string local_name_complete = 11;
     */
    localNameComplete: string;
    /**
     * @generated from protobuf field: int32 tx_power_level = 12;
     */
    txPowerLevel: number;
    /**
     * @generated from protobuf field: uint32 class_of_device = 13;
     */
    classOfDevice: number;
    /**
     * @generated from protobuf field: bytes simple_pairing_hash_c192 = 14;
     */
    simplePairingHashC192: Uint8Array;
    /**
     * @generated from protobuf field: bytes simple_pairing_randomizer_r192 = 15;
     */
    simplePairingRandomizerR192: Uint8Array;
    /**
     * @generated from protobuf field: bytes security_manager_tk_value = 16;
     */
    securityManagerTkValue: Uint8Array;
    /**
     * @generated from protobuf field: bytes security_manager_oob_flags = 17;
     */
    securityManagerOobFlags: Uint8Array;
    /**
     * message PeripheralConnectionIntervalRange {
     * 	uint32 minimum = 1;
     * 	uint32 maximum = 2;
     * }
     *
     * @generated from protobuf field: uint32 peripheral_connection_interval_range = 18;
     */
    peripheralConnectionIntervalRange: number;
    /**
     * @generated from protobuf field: repeated uint32 service_solicitation_uuids_16 = 19;
     */
    serviceSolicitationUuids16: number[];
    /**
     * @generated from protobuf field: repeated bytes service_solicitation_uuids_128 = 20;
     */
    serviceSolicitationUuids128: Uint8Array[];
    /**
     * @generated from protobuf field: bytes service_data_16_uuid = 21;
     */
    serviceData16Uuid: Uint8Array;
    /**
     * @generated from protobuf field: bytes public_target_address = 22;
     */
    publicTargetAddress: Uint8Array;
    /**
     * @generated from protobuf field: bytes random_target_address = 23;
     */
    randomTargetAddress: Uint8Array;
    /**
     * @generated from protobuf field: uint32 appearance = 24;
     */
    appearance: number;
    /**
     * @generated from protobuf field: uint32 advertising_interval = 25;
     */
    advertisingInterval: number;
    /**
     * @generated from protobuf field: bytes le_bluetooth_device_address = 26;
     */
    leBluetoothDeviceAddress: Uint8Array;
    /**
     * @generated from protobuf field: bool le_role = 27;
     */
    leRole: boolean;
    /**
     * @generated from protobuf field: bytes simple_pairing_hash_c256 = 28;
     */
    simplePairingHashC256: Uint8Array;
    /**
     * @generated from protobuf field: bytes simple_pairing_randomizer_r256 = 29;
     */
    simplePairingRandomizerR256: Uint8Array;
    /**
     * @generated from protobuf field: repeated uint32 service_solicitation_uuids_32 = 30;
     */
    serviceSolicitationUuids32: number[];
    /**
     * @generated from protobuf field: bytes service_data_32_uuid = 31;
     */
    serviceData32Uuid: Uint8Array;
    /**
     * @generated from protobuf field: bytes service_data_128_uuid = 32;
     */
    serviceData128Uuid: Uint8Array;
    /**
     * @generated from protobuf field: bytes le_secure_connections_confirmation_value = 33;
     */
    leSecureConnectionsConfirmationValue: Uint8Array;
    /**
     * @generated from protobuf field: bytes le_secure_connections_random_value = 34;
     */
    leSecureConnectionsRandomValue: Uint8Array;
    /**
     * @generated from protobuf field: bytes uri = 35;
     */
    uri: Uint8Array;
    /**
     * @generated from protobuf field: pb.v2.GAPCommonDataType.IndoorPositioning indoor_positioning = 36;
     */
    indoorPositioning?: GAPCommonDataType_IndoorPositioning;
    /**
     * @generated from protobuf field: bytes transport_discovery_data = 37;
     */
    transportDiscoveryData: Uint8Array;
    /**
     * @generated from protobuf field: bytes le_supported_features = 38;
     */
    leSupportedFeatures: Uint8Array;
    /**
     * @generated from protobuf field: bytes channel_map_update_indication = 39;
     */
    channelMapUpdateIndication: Uint8Array;
    /**
     * @generated from protobuf field: bytes pb_adv_mesh_profile_specification = 40;
     */
    pbAdvMeshProfileSpecification: Uint8Array;
    /**
     * @generated from protobuf field: bytes mesh_message = 41;
     */
    meshMessage: Uint8Array;
    /**
     * @generated from protobuf field: bytes mesh_beacon = 42;
     */
    meshBeacon: Uint8Array;
    /**
     * @generated from protobuf field: bytes big_info = 43;
     */
    bigInfo: Uint8Array;
    /**
     * @generated from protobuf field: bytes broadcast_code = 44;
     */
    broadcastCode: Uint8Array;
    /**
     * @generated from protobuf field: bytes resolvable_set_identifier = 45;
     */
    resolvableSetIdentifier: Uint8Array;
    /**
     * @generated from protobuf field: uint32 advertising_interval_long = 46;
     */
    advertisingIntervalLong: number;
    /**
     * @generated from protobuf field: bytes public_broadcast_profile = 47;
     */
    publicBroadcastProfile: Uint8Array;
    /**
     * @generated from protobuf field: bytes encrypted_advertising_data = 48;
     */
    encryptedAdvertisingData: Uint8Array;
    /**
     * @generated from protobuf field: bytes periodic_advertising_response_timing_information = 49;
     */
    periodicAdvertisingResponseTimingInformation: Uint8Array;
    /**
     * @generated from protobuf field: bytes esl_profile = 50;
     */
    eslProfile: Uint8Array;
    /**
     * @generated from protobuf field: bytes three_d_information_data = 51;
     */
    threeDInformationData: Uint8Array;
    /**
     * @generated from protobuf field: bytes manufacturer_specific_data = 52;
     */
    manufacturerSpecificData: Uint8Array;
}
/**
 * @generated from protobuf message pb.v2.GAPCommonDataType.IndoorPositioning
 */
export interface GAPCommonDataType_IndoorPositioning {
    /**
     * @generated from protobuf field: uint32 version = 1;
     */
    version: number;
    /**
     * @generated from protobuf field: uint32 latitude = 2;
     */
    latitude: number;
    /**
     * @generated from protobuf field: uint32 longitude = 3;
     */
    longitude: number;
    /**
     * @generated from protobuf field: uint32 altitude = 4;
     */
    altitude: number;
    /**
     * @generated from protobuf field: uint32 floor_number = 5;
     */
    floorNumber: number;
    /**
     * @generated from protobuf field: uint32 uncertainty = 6;
     */
    uncertainty: number;
}
/**
 * @generated from protobuf message pb.v2.BluetoothInformation
 */
export interface BluetoothInformation {
    /**
     * @generated from protobuf field: bytes macAddress = 1;
     */
    macAddress: Uint8Array; // 6 bytes
    /**
     * @generated from protobuf field: sint32 rssi = 2;
     */
    rssi: number; // Received Signal Strength Indicator
    /**
     * @generated from protobuf field: bytes latest_adv_packet = 3;
     */
    latestAdvPacket: Uint8Array; // Advertisement packet
    /**
     * @generated from protobuf field: bytes latest_rsp_packet = 4;
     */
    latestRspPacket: Uint8Array; // Response packet
    /**
     * @generated from protobuf field: pb.v2.GAPCommonDataType gap_advertisement = 5;
     */
    gapAdvertisement?: GAPCommonDataType;
    /**
     * @generated from protobuf field: pb.v2.AppleAdvertisement apple_advertisement = 6;
     */
    appleAdvertisement?: AppleAdvertisement;
    /**
     * @generated from protobuf field: pb.v2.Eddystone eddystone_advertisement = 7;
     */
    eddystoneAdvertisement?: Eddystone;
}
/**
 * @generated from protobuf message pb.v2.Onboarding
 */
export interface Onboarding {
    /**
     * @generated from protobuf field: pb.v2.OnboardingStatus status = 1;
     */
    status: OnboardingStatus;
    /**
     * @generated from protobuf field: bool user_created = 2;
     */
    userCreated: boolean; // if this was created by a user, and not the system
    /**
     * @generated from protobuf field: string modified_by_urn = 3;
     */
    modifiedByUrn: string; // urn of the user who modified this
    /**
     * @generated from protobuf field: uint64 modified_timestamp = 4;
     */
    modifiedTimestamp: bigint; // timestamp of when this was modified
    /**
     * @generated from protobuf field: string created_by_service_urn = 5;
     */
    createdByServiceUrn: string; // urn of the service that created this
}
/**
 * @generated from protobuf message pb.v2.SensorsConfiguration
 */
export interface SensorsConfiguration {
    /**
     * @generated from protobuf field: sint32 offset_environment_temperature_millidegree_c = 1;
     */
    offsetEnvironmentTemperatureMillidegreeC: number; // Ambient temperature offset in millidegrees celsius
    /**
     * @generated from protobuf field: sint32 offset_environment_temperature_scale_factor = 2;
     */
    offsetEnvironmentTemperatureScaleFactor: number; // Ambient temperature scale factor, default is 1
}
/**
 * @generated from protobuf message pb.v2.Device
 */
export interface Device {
    /**
     * @generated from protobuf field: uint64 uuid = 1;
     */
    uuid: bigint; // Onboardable. Once this is set, it can be onboarded to new devices to make this "Device" in the system consistant
    /**
     * @generated from protobuf field: uint64 serial = 2;
     */
    serial: bigint; // Unique identifier based off the device, for example macAddress, imei, something hardware specific.
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string; // User's defined and user perscribed name
    /**
     * @generated from protobuf field: string found_name = 4;
     */
    foundName: string; // Device's discovered name (IE: Bluetooth advertisment)
    /**
     * @generated from protobuf field: uint32 device_type = 5;
     */
    deviceType: number; // Device Type
    /**
     * @generated from protobuf field: pb.v2.ConnectionInformation connection_info = 6;
     */
    connectionInfo?: ConnectionInformation;
    /**
     * User input
     *
     * @generated from protobuf field: string description = 7;
     */
    description: string;
    /**
     * @generated from protobuf field: string icon_url = 8;
     */
    iconUrl: string;
    /**
     * @generated from protobuf field: bytes color = 9;
     */
    color: Uint8Array; // RGB
    /**
     * @generated from protobuf field: pb.v2.Position manual_position = 10;
     */
    manualPosition?: Position; // Position set by user
    /**
     * @generated from protobuf field: pb.v2.Settings deviceSettings = 11;
     */
    deviceSettings?: Settings;
    /**
     * @generated from protobuf field: pb.v2.Settings targetSettings = 12;
     */
    targetSettings?: Settings;
    /**
     * Managment
     *
     * @generated from protobuf field: uint32 device_group_id = 13;
     */
    deviceGroupId: number;
    /**
     * @generated from protobuf field: uint32 device_group_id_variant = 14;
     */
    deviceGroupIdVariant: number;
    /**
     * @generated from protobuf field: repeated pb.v2.Tag tags = 15;
     */
    tags: Tag[];
    /**
     * @generated from protobuf field: string manufacturer = 16;
     */
    manufacturer: string;
    /**
     * @generated from protobuf field: string product_name = 17;
     */
    productName: string;
    /**
     * @generated from protobuf field: string hardware_version = 18;
     */
    hardwareVersion: string;
    /**
     * @generated from protobuf field: string manufacturer_type = 19;
     */
    manufacturerType: string;
    /**
     * System Settings
     *
     * @generated from protobuf field: bool is_blocked = 20;
     */
    isBlocked: boolean;
    /**
     * @generated from protobuf field: uint32 log_mask = 21;
     */
    logMask: number; // used to determine the log level to save (bits tbd)
    /**
     * Misc Metadata
     *
     * @generated from protobuf field: pb.v2.MessagePack msgpack = 22;
     */
    msgpack?: MessagePack;
    /**
     * @generated from protobuf field: repeated pb.v2.KeyValue keyValues = 23;
     */
    keyValues: KeyValue[];
    /**
     * asset
     *
     * @generated from protobuf field: string primary_asset_urn = 24;
     */
    primaryAssetUrn: string;
    /**
     * @generated from protobuf field: repeated string assets_urns = 25;
     */
    assetsUrns: string[];
    /**
     * @generated from protobuf field: pb.v2.BluetoothInformation bluetooth_info = 26;
     */
    bluetoothInfo?: BluetoothInformation;
    /**
     * @generated from protobuf field: pb.v2.Position reported_position = 27;
     */
    reportedPosition?: Position; // Position reported by device. (IE: GPS)
    /**
     * @generated from protobuf field: pb.v2.Position calculated_position = 28;
     */
    calculatedPosition?: Position; // Position calculated by wifi, bluetooth (RSSI weighted), etc
    /**
     * @generated from protobuf field: pb.v2.SensorsConfiguration sensors_configuration = 29;
     */
    sensorsConfiguration?: SensorsConfiguration;
}
// //////////////////////////////
// User Generated Data
// //////////////////////////////

// Holds many OTA Files, for a single device type, single partition, in order to preserve contiguous OTA order and update lifecycle

/**
 * Holds many DevicePartitions, for many devices of a single device type
 *
 * @generated from protobuf message pb.v2.DeviceGroup
 */
export interface DeviceGroup {
    /**
     * @generated from protobuf field: uint32 device_group_id = 1;
     */
    deviceGroupId: number;
    /**
     * @generated from protobuf field: uint32 device_group_id_variant = 2;
     */
    deviceGroupIdVariant: number;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated pb.v2.DeviceGroup.Partition partitions = 6;
     */
    partitions: DeviceGroup_Partition[]; // DevicePartitions are stored with DeviceGroup in the db
}
/**
 * @generated from protobuf message pb.v2.DeviceGroup.Partition
 */
export interface DeviceGroup_Partition {
    /**
     * @generated from protobuf field: uint32 order = 1;
     */
    order: number; // 0 is reserved for the default partition
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // EX: "nvm partition"
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string; // EX: "Non Volatile Memory partition of the esp32"
    /**
     * @generated from protobuf field: repeated string firmware_file_urns = 4;
     */
    firmwareFileUrns: string[]; // searchable in db - major.minor_md5b64
}
/**
 * @generated from protobuf message pb.v2.FirmwareFile
 */
export interface FirmwareFile {
    /**
     * @generated from protobuf field: bytes md5 = 1;
     */
    md5: Uint8Array;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // EX: "nvm partition"
    /**
     * @generated from protobuf field: uint32 ver_major = 3;
     */
    verMajor: number;
    /**
     * @generated from protobuf field: uint32 ver_minor = 4;
     */
    verMinor: number;
    /**
     * @generated from protobuf field: uint32 ver_patch = 5;
     */
    verPatch: number;
    /**
     * @generated from protobuf field: string hardware_type = 6;
     */
    hardwareType: string;
    /**
     * @generated from protobuf field: string release_notes = 7;
     */
    releaseNotes: string;
    /**
     * @generated from protobuf field: string commit_url = 8;
     */
    commitUrl: string;
}
/**
 * @generated from protobuf message pb.v2.File
 */
export interface File {
    /**
     * @generated from protobuf field: bytes md5 = 1;
     */
    md5: Uint8Array; // 16 md5 hash of the file - THIS IS THE MAIN ID FOR LOOKUP
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string mime_type = 3;
     */
    mimeType: string; // MIME type of the file
    /**
     * @generated from protobuf field: uint32 length = 4;
     */
    length: number; // length of the file
    /**
     * @generated from protobuf field: string notes = 5;
     */
    notes: string; // User input notes'
    /**
     * @generated from protobuf field: bytes data = 6;
     */
    data: Uint8Array; // file data -> stored as an attachment NOTE: Optionally returned depending on the rpc call
    /**
     * @generated from protobuf field: string data_url = 7;
     */
    dataUrl: string; // url to the file data (may differ from urn, IE external images)
}
/**
 * @generated from protobuf message pb.v2.Person
 */
export interface Person {
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string; // Alias - Username, callsign, user defined at user creation
    /**
     * @generated from protobuf field: bytes sign_pub = 2;
     */
    signPub: Uint8Array; // hex Encrypt public key
    /**
     * @generated from protobuf field: bytes sign_priv = 3;
     */
    signPriv: Uint8Array; // hex Encrypt private key
    /**
     * @generated from protobuf field: bytes encrypt_pub = 4;
     */
    encryptPub: Uint8Array; // hex Encrypt public key
    /**
     * @generated from protobuf field: bytes encrypt_priv = 5;
     */
    encryptPriv: Uint8Array; // hex Encrypt private key
    /**
     * @generated from protobuf field: string name = 6;
     */
    name: string; // User given name
    /**
     * @generated from protobuf field: string email = 7;
     */
    email: string;
    /**
     * @generated from protobuf field: repeated string roles = 8;
     */
    roles: string[]; // Person_Role enum, allowing for additional defined titles by user
    /**
     * @generated from protobuf field: repeated string contacts = 9;
     */
    contacts: string[]; // PUBLIC key of all contacts
    /**
     * @generated from protobuf field: string profile_picture_urn = 10;
     */
    profilePictureUrn: string; // Profile picture locator/files/USTD0G20L/F050LAY13MX/trisonicmini324231.txt
    /**
     * @generated from protobuf field: string short_username = 11;
     */
    shortUsername: string; // Userdefined short version of their name
    /**
     * @generated from protobuf field: bytes color = 12;
     */
    color: Uint8Array; // RGB - Color to a user, in chat/map icon etc.
    /**
     * @generated from protobuf field: pb.v2.Address address = 13;
     */
    address?: Address; // Address of the person
    /**
     * @generated from protobuf field: bool profile_not_provisioned = 14;
     */
    profileNotProvisioned: boolean; // If the profile has been onboarded to a device
    /**
     * @generated from protobuf field: bool temprary_password = 15;
     */
    tempraryPassword: boolean; // If the profile has been onboarded to a device
}
/**
 * @generated from protobuf message pb.v2.Asset
 */
export interface Asset {
    /**
     * @generated from protobuf field: uint64 uuid = 1;
     */
    uuid: bigint;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // User given name
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string; // User given description
    /**
     * @generated from protobuf field: repeated pb.v2.Position position = 4;
     */
    position: Position[]; // position the message was sent at
    /**
     * @generated from protobuf field: repeated pb.v2.Asset.AssetGeonodeConection asset_geonode_connections = 5;
     */
    assetGeonodeConnections: Asset_AssetGeonodeConection[];
    /**
     * @generated from protobuf field: repeated pb.v2.Asset.DeviceAssetConnection device_asset_connections = 6;
     */
    deviceAssetConnections: Asset_DeviceAssetConnection[];
    /**
     * @generated from protobuf field: repeated pb.v2.KeyValue keyValues = 7;
     */
    keyValues: KeyValue[];
    /**
     * @generated from protobuf field: repeated pb.v2.Asset.Label labels = 8;
     */
    labels: Asset_Label[]; // Barcodes, etc. associated with this asset
}
/**
 * @generated from protobuf message pb.v2.Asset.AssetGeonodeConection
 */
export interface Asset_AssetGeonodeConection {
    /**
     * @generated from protobuf field: string geonode_urn = 1;
     */
    geonodeUrn: string; // location, such as building, floor, etc. lowest level identifiable
    /**
     * @generated from protobuf field: uint64 start_epoch = 2;
     */
    startEpoch: bigint; // Entered Time
    /**
     * @generated from protobuf field: uint64 end_epoch = 3;
     */
    endEpoch: bigint; // Exit Time
}
/**
 * @generated from protobuf message pb.v2.Asset.DeviceAssetConnection
 */
export interface Asset_DeviceAssetConnection {
    /**
     * @generated from protobuf field: string device_urn = 1;
     */
    deviceUrn: string; // Devices this asset is associated with
    /**
     * @generated from protobuf field: uint64 start_epoch = 2;
     */
    startEpoch: bigint; // end epoch time
    /**
     * @generated from protobuf field: uint64 end_epoch = 3;
     */
    endEpoch: bigint; // end epoch time
}
/**
 * @generated from protobuf message pb.v2.Asset.Label
 */
export interface Asset_Label {
    /**
     * @generated from protobuf field: pb.v2.AssetLabelType type = 1;
     */
    type: AssetLabelType;
    /**
     * @generated from protobuf field: string key = 2;
     */
    key: string;
    /**
     * @generated from protobuf field: bytes data = 3;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message pb.v2.ChatMessage
 */
export interface ChatMessage {
    /**
     * @generated from protobuf field: uint64 urn = 1;
     */
    urn: bigint; // Unique identifier for looking up in the server
    /**
     * @generated from protobuf field: uint64 created_ms = 2;
     */
    createdMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 edited_ms = 3;
     */
    editedMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 deleted_ms = 4;
     */
    deletedMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: pb.v2.Position position = 5;
     */
    position?: Position; //  position the message was sent at
    /**
     * @generated from protobuf field: uint64 author_urn = 6;
     */
    authorUrn: bigint; // Person Sending, lookup key
    /**
     * @generated from protobuf field: uint64 recipient_urn = 7;
     */
    recipientUrn: bigint; // Person Receiving (channel urn, or persons lookup key)
    /**
     * Leaving this message open to the possibility of adding different types, such as photos .. audio maybe (PTT?) .. whatever.
     * TODO :: Add file message type
     *
     * @generated from protobuf field: string message = 8;
     */
    message: string;
    /**
     * @generated from protobuf oneof: ChatData
     */
    chatData: {
        oneofKind: "photoB64";
        /**
         * @generated from protobuf field: string photo_b64 = 9;
         */
        photoB64: string;
    } | {
        oneofKind: "audioMsg";
        /**
         * @generated from protobuf field: string audio_msg = 10;
         */
        audioMsg: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * you have liberty to change this stuff jordan.
 *
 * @generated from protobuf message pb.v2.ChannelMessage
 */
export interface ChannelMessage {
    /**
     * @generated from protobuf field: uint64 author_urn = 1;
     */
    authorUrn: bigint; // Person Sending, lookup key
    /**
     * @generated from protobuf field: uint64 recipient_urn = 2;
     */
    recipientUrn: bigint; // Person Receiving (channel urn, or persons lookup key)
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: string firmware_file_urns = 4;
     */
    firmwareFileUrns: string; // searchable in db - major.minor_md5b64
}
/**
 * @generated from protobuf message pb.v2.Channel
 */
export interface Channel {
    /**
     * @generated from protobuf field: uint64 urn = 1;
     */
    urn: bigint; // Unique identifier for looking up in the server
    /**
     * @generated from protobuf field: uint64 created_ms = 2;
     */
    createdMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 edited_ms = 3;
     */
    editedMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 deleted_ms = 4;
     */
    deletedMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 uuid = 5;
     */
    uuid: bigint;
    /**
     * @generated from protobuf field: string name = 6;
     */
    name: string; // Human readable name for the channel
    /**
     * @generated from protobuf field: uint64 created_by_urn = 7;
     */
    createdByUrn: bigint; // Created User's Pub
    /**
     * @generated from protobuf field: pb.v2.ChannelPrivacy privacy = 8;
     */
    privacy: ChannelPrivacy; // Channel privacy type
    /**
     * @generated from protobuf field: repeated string sub_channel_urn = 9;
     */
    subChannelUrn: string[]; // db id of sub channels 
    /**
     * @generated from protobuf field: string password = 10;
     */
    password: string; // ONLY FILLED OUT WHEN JOINING NOT SAVED OR RETURNED IN DB.
}
/**
 * @generated from protobuf message pb.v2.Tag
 */
export interface Tag {
    /**
     * @generated from protobuf field: uint64 urn = 1;
     */
    urn: bigint; // Unique identifier for looking up in the server
    /**
     * @generated from protobuf field: uint64 created_ms = 2;
     */
    createdMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 edited_ms = 3;
     */
    editedMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 deleted_ms = 4;
     */
    deletedMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: string name = 5;
     */
    name: string; // human readable name of the tag
    /**
     * @generated from protobuf field: uint32 tag_type = 6;
     */
    tagType: number; // TagType -> leaving the possibility for additional user generated tag types
    /**
     * @generated from protobuf field: uint64 parent_tag_urn = 7;
     */
    parentTagUrn: bigint; // SINGLE - Parent tag urn
    /**
     * @generated from protobuf field: repeated string child_tag_urn = 8;
     */
    childTagUrn: string[];
}
/**
 * Matching slack's ownership heiarchy => this can be aliased to organization
 *
 * @generated from protobuf message pb.v2.Workspace
 */
export interface Workspace {
    /**
     * @generated from protobuf field: uint64 urn = 1;
     */
    urn: bigint; // Unique identifier for looking up in the server
    /**
     * @generated from protobuf field: uint64 created_ms = 2;
     */
    createdMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 edited_ms = 3;
     */
    editedMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: uint64 deleted_ms = 4;
     */
    deletedMs: bigint; // milli seconds in epoch time
    /**
     * @generated from protobuf field: string name = 5;
     */
    name: string;
}
/**
 * @generated from protobuf message pb.v2.GeoNode
 */
export interface GeoNode {
    /**
     * @generated from protobuf field: uint64 uuid = 1;
     */
    uuid: bigint;
    /**
     * @generated from protobuf field: pb.v2.GeoNodeType type = 2;
     */
    type: GeoNodeType;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string; // User given name
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string; // User given description
    /**
     * @generated from protobuf field: repeated string tags = 5;
     */
    tags: string[]; // Tags associated with this node -> bathroom::cubbord::sink
    /**
     * @generated from protobuf field: pb.v2.Position center_position = 6;
     */
    centerPosition?: Position; // position the message was sent at
    /**
     * @generated from protobuf field: string geo_json = 7;
     */
    geoJson: string; // GeoJson stored as a GeoJson string (true GeoJson)
    /**
     * @generated from protobuf field: repeated bytes geo_json_serialized = 8;
     */
    geoJsonSerialized: Uint8Array[]; // Researved -> If we need to serilize and transport to save space, we can deal with it 
    /**
     * @generated from protobuf field: repeated pb.v2.KeyValue key_values = 9;
     */
    keyValues: KeyValue[];
}
/**
 * @generated from protobuf message pb.v2.Webhook
 */
export interface Webhook {
    /**
     * @generated from protobuf field: uint64 uuid = 1;
     */
    uuid: bigint;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string forwardingUrl = 3;
     */
    forwardingUrl: string;
}
/**
 * @generated from protobuf enum pb.v2.PositonFixType
 */
export enum PositonFixType {
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_NO_LOCK = 0;
     */
    POSITION_FIX_TYPE_NO_LOCK = 0,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_IMU = 1;
     */
    POSITION_FIX_TYPE_IMU = 1,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_2D_LOCK = 2;
     */
    POSITION_FIX_TYPE_2D_LOCK = 2,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_3D_LOCK = 3;
     */
    POSITION_FIX_TYPE_3D_LOCK = 3,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_GPS_IMU = 4;
     */
    POSITION_FIX_TYPE_GPS_IMU = 4,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_TIME_ONLY = 5;
     */
    POSITION_FIX_TYPE_TIME_ONLY = 5,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_WIFI = 6;
     */
    POSITION_FIX_TYPE_WIFI = 6,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_CELLID = 7;
     */
    POSITION_FIX_TYPE_CELLID = 7,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_IP_BASED = 8;
     */
    POSITION_FIX_TYPE_IP_BASED = 8,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_BLUETOOTH_GATEWAYS = 9;
     */
    POSITION_FIX_TYPE_BLUETOOTH_GATEWAYS = 9,
    /**
     * @generated from protobuf enum value: POSITION_FIX_TYPE_MANUAL_POSITION = 10;
     */
    POSITION_FIX_TYPE_MANUAL_POSITION = 10
}
/**
 * @generated from protobuf enum pb.v2.AppleAdvertismentType
 */
export enum AppleAdvertismentType {
    /**
     * 0x00
     *
     * @generated from protobuf enum value: APPLE_ADVERTISMENT_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * 0x02
     *
     * @generated from protobuf enum value: APPLE_ADVERTISMENT_TYPE_IBEACON = 2;
     */
    IBEACON = 2,
    /**
     * @generated from protobuf enum value: APPLE_ADVERTISMENT_TYPE_HANDOFF = 12;
     */
    HANDOFF = 12,
    /**
     * 0x10
     *
     * @generated from protobuf enum value: APPLE_ADVERTISMENT_TYPE_NEARBY_INFO = 16;
     */
    NEARBY_INFO = 16,
    /**
     * 0x12
     *
     * @generated from protobuf enum value: APPLE_ADVERTISMENT_TYPE_FIND_MY = 18;
     */
    FIND_MY = 18
}
/**
 * @generated from protobuf enum pb.v2.OnboardingStatus
 */
export enum OnboardingStatus {
    /**
     * @generated from protobuf enum value: ONBOARDING_STATUS_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: ONBOARDING_STATUS_CREATED = 1;
     */
    CREATED = 1,
    /**
     * @generated from protobuf enum value: ONBOARDING_STATUS_STAGED = 2;
     */
    STAGED = 2,
    /**
     * @generated from protobuf enum value: ONBOARDING_STATUS_ACTIVE = 3;
     */
    ACTIVE = 3,
    /**
     * @generated from protobuf enum value: ONBOARDING_STATUS_DISABLED = 4;
     */
    DISABLED = 4,
    /**
     * @generated from protobuf enum value: ONBOARDING_STATUS_DELETED = 5;
     */
    DELETED = 5
}
/**
 * @generated from protobuf enum pb.v2.AssetLabelType
 */
export enum AssetLabelType {
    /**
     * @generated from protobuf enum value: ASSET_LABEL_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: ASSET_LABEL_TYPE_BARCODE = 1;
     */
    BARCODE = 1,
    /**
     * @generated from protobuf enum value: ASSET_LABEL_TYPE_QRCODE = 2;
     */
    QRCODE = 2,
    /**
     * @generated from protobuf enum value: ASSET_LABEL_TYPE_NFC_TAG = 3;
     */
    NFC_TAG = 3
}
/**
 * @generated from protobuf enum pb.v2.ChannelPrivacy
 */
export enum ChannelPrivacy {
    /**
     * @generated from protobuf enum value: CHANNEL_PRIVACY_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: CHANNEL_PRIVACY_PUBLIC = 1;
     */
    PUBLIC = 1,
    /**
     * @generated from protobuf enum value: CHANNEL_PRIVACY_READ_ONLY = 2;
     */
    READ_ONLY = 2,
    /**
     * @generated from protobuf enum value: CHANNEL_PRIVACY_PRIVATE_PASSWORD = 3;
     */
    PRIVATE_PASSWORD = 3,
    /**
     * @generated from protobuf enum value: CHANNEL_PRIVACY_PRIVATE_INVITE = 4;
     */
    PRIVATE_INVITE = 4
}
/**
 * Designatss the Tree "root". Each are distinct Tag Trees.
 *
 * @generated from protobuf enum pb.v2.TagType
 */
export enum TagType {
    /**
     * @generated from protobuf enum value: TAG_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: TAG_TYPE_SYSTEM = 1;
     */
    SYSTEM = 1,
    /**
     * @generated from protobuf enum value: TAG_TYPE_APP = 2;
     */
    APP = 2,
    /**
     * @generated from protobuf enum value: TAG_TYPE_WORKSPACE = 3;
     */
    WORKSPACE = 3,
    /**
     * @generated from protobuf enum value: TAG_TYPE_CHANNEL = 4;
     */
    CHANNEL = 4,
    /**
     * @generated from protobuf enum value: TAG_TYPE_USER = 5;
     */
    USER = 5
}
// //////////////////////////////
// / System Generated Information

/**
 * @generated from protobuf enum pb.v2.GeoNodeType
 */
export enum GeoNodeType {
    /**
     * @generated from protobuf enum value: GEO_NODE_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: GEO_NODE_TYPE_BUILDING = 1;
     */
    BUILDING = 1,
    /**
     * @generated from protobuf enum value: GEO_NODE_TYPE_FLOOR = 2;
     */
    FLOOR = 2,
    /**
     * @generated from protobuf enum value: GEO_NODE_TYPE_AREA = 3;
     */
    AREA = 3,
    /**
     * @generated from protobuf enum value: GEO_NODE_TYPE_ROOM = 4;
     */
    ROOM = 4,
    /**
     * @generated from protobuf enum value: GEO_NODE_TYPE_MAX = 5;
     */
    MAX = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class Position$Type extends MessageType<Position> {
    constructor() {
        super("pb.v2.Position", [
            { no: 1, name: "latitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "elevation_cm", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 4, name: "geohash", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "location_precision_cm", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "fix_type", kind: "enum", T: () => ["pb.v2.PositonFixType", PositonFixType] },
            { no: 7, name: "plus_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "epoch_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 9, name: "rssi", kind: "scalar", opt: true, T: 17 /*ScalarType.SINT32*/ },
            { no: 10, name: "gateway_telemetry_urn", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Position>): Position {
        const message = { latitude: 0, longitude: 0, elevationCm: 0, geohash: 0n, locationPrecisionCm: 0, fixType: 0, plusCode: "", epochMs: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Position>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Position): Position {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* float latitude */ 1:
                    message.latitude = reader.float();
                    break;
                case /* float longitude */ 2:
                    message.longitude = reader.float();
                    break;
                case /* sint32 elevation_cm */ 3:
                    message.elevationCm = reader.sint32();
                    break;
                case /* uint64 geohash */ 4:
                    message.geohash = reader.uint64().toBigInt();
                    break;
                case /* uint32 location_precision_cm */ 5:
                    message.locationPrecisionCm = reader.uint32();
                    break;
                case /* pb.v2.PositonFixType fix_type */ 6:
                    message.fixType = reader.int32();
                    break;
                case /* string plus_code */ 7:
                    message.plusCode = reader.string();
                    break;
                case /* uint64 epoch_ms */ 8:
                    message.epochMs = reader.uint64().toBigInt();
                    break;
                case /* optional sint32 rssi */ 9:
                    message.rssi = reader.sint32();
                    break;
                case /* optional string gateway_telemetry_urn */ 10:
                    message.gatewayTelemetryUrn = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Position, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* float latitude = 1; */
        if (message.latitude !== 0)
            writer.tag(1, WireType.Bit32).float(message.latitude);
        /* float longitude = 2; */
        if (message.longitude !== 0)
            writer.tag(2, WireType.Bit32).float(message.longitude);
        /* sint32 elevation_cm = 3; */
        if (message.elevationCm !== 0)
            writer.tag(3, WireType.Varint).sint32(message.elevationCm);
        /* uint64 geohash = 4; */
        if (message.geohash !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.geohash);
        /* uint32 location_precision_cm = 5; */
        if (message.locationPrecisionCm !== 0)
            writer.tag(5, WireType.Varint).uint32(message.locationPrecisionCm);
        /* pb.v2.PositonFixType fix_type = 6; */
        if (message.fixType !== 0)
            writer.tag(6, WireType.Varint).int32(message.fixType);
        /* string plus_code = 7; */
        if (message.plusCode !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.plusCode);
        /* uint64 epoch_ms = 8; */
        if (message.epochMs !== 0n)
            writer.tag(8, WireType.Varint).uint64(message.epochMs);
        /* optional sint32 rssi = 9; */
        if (message.rssi !== undefined)
            writer.tag(9, WireType.Varint).sint32(message.rssi);
        /* optional string gateway_telemetry_urn = 10; */
        if (message.gatewayTelemetryUrn !== undefined)
            writer.tag(10, WireType.LengthDelimited).string(message.gatewayTelemetryUrn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Position
 */
export const Position = new Position$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Address$Type extends MessageType<Address> {
    constructor() {
        super("pb.v2.Address", [
            { no: 1, name: "address_line_1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address_line_2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "country", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Address>): Address {
        const message = { addressLine1: "", addressLine2: "", city: "", state: "", zip: "", country: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Address>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Address): Address {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address_line_1 */ 1:
                    message.addressLine1 = reader.string();
                    break;
                case /* string address_line_2 */ 2:
                    message.addressLine2 = reader.string();
                    break;
                case /* string city */ 3:
                    message.city = reader.string();
                    break;
                case /* string state */ 4:
                    message.state = reader.string();
                    break;
                case /* string zip */ 5:
                    message.zip = reader.string();
                    break;
                case /* string country */ 6:
                    message.country = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Address, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address_line_1 = 1; */
        if (message.addressLine1 !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.addressLine1);
        /* string address_line_2 = 2; */
        if (message.addressLine2 !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.addressLine2);
        /* string city = 3; */
        if (message.city !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.city);
        /* string state = 4; */
        if (message.state !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.state);
        /* string zip = 5; */
        if (message.zip !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.zip);
        /* string country = 6; */
        if (message.country !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.country);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Address
 */
export const Address = new Address$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectionInformation$Type extends MessageType<ConnectionInformation> {
    constructor() {
        super("pb.v2.ConnectionInformation", [
            { no: 1, name: "last_connection_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "micro_dev_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "public_key", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "private_key", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 5, name: "ip_v4", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "ip_v6", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 7, name: "port_in", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "port_out", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ConnectionInformation>): ConnectionInformation {
        const message = { lastConnectionMs: 0n, microDevId: 0, publicKey: new Uint8Array(0), privateKey: new Uint8Array(0), ipV4: new Uint8Array(0), ipV6: new Uint8Array(0), portIn: 0, portOut: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConnectionInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConnectionInformation): ConnectionInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 last_connection_ms */ 1:
                    message.lastConnectionMs = reader.uint64().toBigInt();
                    break;
                case /* uint32 micro_dev_id */ 2:
                    message.microDevId = reader.uint32();
                    break;
                case /* bytes public_key */ 3:
                    message.publicKey = reader.bytes();
                    break;
                case /* bytes private_key */ 4:
                    message.privateKey = reader.bytes();
                    break;
                case /* bytes ip_v4 */ 5:
                    message.ipV4 = reader.bytes();
                    break;
                case /* bytes ip_v6 */ 6:
                    message.ipV6 = reader.bytes();
                    break;
                case /* uint32 port_in */ 7:
                    message.portIn = reader.uint32();
                    break;
                case /* uint32 port_out */ 8:
                    message.portOut = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConnectionInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 last_connection_ms = 1; */
        if (message.lastConnectionMs !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.lastConnectionMs);
        /* uint32 micro_dev_id = 2; */
        if (message.microDevId !== 0)
            writer.tag(2, WireType.Varint).uint32(message.microDevId);
        /* bytes public_key = 3; */
        if (message.publicKey.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.publicKey);
        /* bytes private_key = 4; */
        if (message.privateKey.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.privateKey);
        /* bytes ip_v4 = 5; */
        if (message.ipV4.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.ipV4);
        /* bytes ip_v6 = 6; */
        if (message.ipV6.length)
            writer.tag(6, WireType.LengthDelimited).bytes(message.ipV6);
        /* uint32 port_in = 7; */
        if (message.portIn !== 0)
            writer.tag(7, WireType.Varint).uint32(message.portIn);
        /* uint32 port_out = 8; */
        if (message.portOut !== 0)
            writer.tag(8, WireType.Varint).uint32(message.portOut);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.ConnectionInformation
 */
export const ConnectionInformation = new ConnectionInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppleAdvertisement$Type extends MessageType<AppleAdvertisement> {
    constructor() {
        super("pb.v2.AppleAdvertisement", [
            { no: 1, name: "unknown", kind: "message", T: () => AppleAdvertisement_AppleUnknown },
            { no: 2, name: "iBeacon", kind: "message", T: () => AppleAdvertisement_IBeacon },
            { no: 3, name: "findMy", kind: "message", T: () => AppleAdvertisement_FindMy },
            { no: 4, name: "handoff", kind: "message", T: () => AppleAdvertisement_Handoff },
            { no: 5, name: "nearbyInfo", kind: "message", T: () => AppleAdvertisement_NearbyInfo }
        ]);
    }
    create(value?: PartialMessage<AppleAdvertisement>): AppleAdvertisement {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppleAdvertisement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppleAdvertisement): AppleAdvertisement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.AppleAdvertisement.AppleUnknown unknown */ 1:
                    message.unknown = AppleAdvertisement_AppleUnknown.internalBinaryRead(reader, reader.uint32(), options, message.unknown);
                    break;
                case /* pb.v2.AppleAdvertisement.IBeacon iBeacon */ 2:
                    message.iBeacon = AppleAdvertisement_IBeacon.internalBinaryRead(reader, reader.uint32(), options, message.iBeacon);
                    break;
                case /* pb.v2.AppleAdvertisement.FindMy findMy */ 3:
                    message.findMy = AppleAdvertisement_FindMy.internalBinaryRead(reader, reader.uint32(), options, message.findMy);
                    break;
                case /* pb.v2.AppleAdvertisement.Handoff handoff */ 4:
                    message.handoff = AppleAdvertisement_Handoff.internalBinaryRead(reader, reader.uint32(), options, message.handoff);
                    break;
                case /* pb.v2.AppleAdvertisement.NearbyInfo nearbyInfo */ 5:
                    message.nearbyInfo = AppleAdvertisement_NearbyInfo.internalBinaryRead(reader, reader.uint32(), options, message.nearbyInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppleAdvertisement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.AppleAdvertisement.AppleUnknown unknown = 1; */
        if (message.unknown)
            AppleAdvertisement_AppleUnknown.internalBinaryWrite(message.unknown, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.AppleAdvertisement.IBeacon iBeacon = 2; */
        if (message.iBeacon)
            AppleAdvertisement_IBeacon.internalBinaryWrite(message.iBeacon, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.AppleAdvertisement.FindMy findMy = 3; */
        if (message.findMy)
            AppleAdvertisement_FindMy.internalBinaryWrite(message.findMy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.AppleAdvertisement.Handoff handoff = 4; */
        if (message.handoff)
            AppleAdvertisement_Handoff.internalBinaryWrite(message.handoff, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.AppleAdvertisement.NearbyInfo nearbyInfo = 5; */
        if (message.nearbyInfo)
            AppleAdvertisement_NearbyInfo.internalBinaryWrite(message.nearbyInfo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppleAdvertisement
 */
export const AppleAdvertisement = new AppleAdvertisement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppleAdvertisement_AppleUnknown$Type extends MessageType<AppleAdvertisement_AppleUnknown> {
    constructor() {
        super("pb.v2.AppleAdvertisement.AppleUnknown", [
            { no: 1, name: "type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<AppleAdvertisement_AppleUnknown>): AppleAdvertisement_AppleUnknown {
        const message = { type: 0, data: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppleAdvertisement_AppleUnknown>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppleAdvertisement_AppleUnknown): AppleAdvertisement_AppleUnknown {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 type */ 1:
                    message.type = reader.uint32();
                    break;
                case /* bytes data */ 2:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppleAdvertisement_AppleUnknown, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).uint32(message.type);
        /* bytes data = 2; */
        if (message.data.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppleAdvertisement.AppleUnknown
 */
export const AppleAdvertisement_AppleUnknown = new AppleAdvertisement_AppleUnknown$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppleAdvertisement_IBeacon$Type extends MessageType<AppleAdvertisement_IBeacon> {
    constructor() {
        super("pb.v2.AppleAdvertisement.IBeacon", [
            { no: 1, name: "iBeacon_UUID", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "iBeacon_major", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "iBeacon_minor", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "iBeacon_tx_power", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<AppleAdvertisement_IBeacon>): AppleAdvertisement_IBeacon {
        const message = { iBeaconUUID: new Uint8Array(0), iBeaconMajor: 0, iBeaconMinor: 0, iBeaconTxPower: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppleAdvertisement_IBeacon>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppleAdvertisement_IBeacon): AppleAdvertisement_IBeacon {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes iBeacon_UUID */ 1:
                    message.iBeaconUUID = reader.bytes();
                    break;
                case /* uint32 iBeacon_major */ 2:
                    message.iBeaconMajor = reader.uint32();
                    break;
                case /* uint32 iBeacon_minor */ 3:
                    message.iBeaconMinor = reader.uint32();
                    break;
                case /* uint32 iBeacon_tx_power */ 4:
                    message.iBeaconTxPower = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppleAdvertisement_IBeacon, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes iBeacon_UUID = 1; */
        if (message.iBeaconUUID.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.iBeaconUUID);
        /* uint32 iBeacon_major = 2; */
        if (message.iBeaconMajor !== 0)
            writer.tag(2, WireType.Varint).uint32(message.iBeaconMajor);
        /* uint32 iBeacon_minor = 3; */
        if (message.iBeaconMinor !== 0)
            writer.tag(3, WireType.Varint).uint32(message.iBeaconMinor);
        /* uint32 iBeacon_tx_power = 4; */
        if (message.iBeaconTxPower !== 0)
            writer.tag(4, WireType.Varint).uint32(message.iBeaconTxPower);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppleAdvertisement.IBeacon
 */
export const AppleAdvertisement_IBeacon = new AppleAdvertisement_IBeacon$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppleAdvertisement_FindMy$Type extends MessageType<AppleAdvertisement_FindMy> {
    constructor() {
        super("pb.v2.AppleAdvertisement.FindMy", [
            { no: 1, name: "findMy_public_key", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "findMy_private_key", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "findMy_status", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "findMy_advertised_public_key", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 5, name: "findMy_first_bits_of_advertised_public_key", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "findMy_hint", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<AppleAdvertisement_FindMy>): AppleAdvertisement_FindMy {
        const message = { findMyPublicKey: new Uint8Array(0), findMyPrivateKey: new Uint8Array(0), findMyStatus: 0, findMyAdvertisedPublicKey: new Uint8Array(0), findMyFirstBitsOfAdvertisedPublicKey: 0, findMyHint: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppleAdvertisement_FindMy>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppleAdvertisement_FindMy): AppleAdvertisement_FindMy {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes findMy_public_key */ 1:
                    message.findMyPublicKey = reader.bytes();
                    break;
                case /* bytes findMy_private_key */ 2:
                    message.findMyPrivateKey = reader.bytes();
                    break;
                case /* uint32 findMy_status */ 3:
                    message.findMyStatus = reader.uint32();
                    break;
                case /* bytes findMy_advertised_public_key */ 4:
                    message.findMyAdvertisedPublicKey = reader.bytes();
                    break;
                case /* uint32 findMy_first_bits_of_advertised_public_key */ 5:
                    message.findMyFirstBitsOfAdvertisedPublicKey = reader.uint32();
                    break;
                case /* uint32 findMy_hint */ 6:
                    message.findMyHint = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppleAdvertisement_FindMy, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes findMy_public_key = 1; */
        if (message.findMyPublicKey.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.findMyPublicKey);
        /* bytes findMy_private_key = 2; */
        if (message.findMyPrivateKey.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.findMyPrivateKey);
        /* uint32 findMy_status = 3; */
        if (message.findMyStatus !== 0)
            writer.tag(3, WireType.Varint).uint32(message.findMyStatus);
        /* bytes findMy_advertised_public_key = 4; */
        if (message.findMyAdvertisedPublicKey.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.findMyAdvertisedPublicKey);
        /* uint32 findMy_first_bits_of_advertised_public_key = 5; */
        if (message.findMyFirstBitsOfAdvertisedPublicKey !== 0)
            writer.tag(5, WireType.Varint).uint32(message.findMyFirstBitsOfAdvertisedPublicKey);
        /* uint32 findMy_hint = 6; */
        if (message.findMyHint !== 0)
            writer.tag(6, WireType.Varint).uint32(message.findMyHint);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppleAdvertisement.FindMy
 */
export const AppleAdvertisement_FindMy = new AppleAdvertisement_FindMy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppleAdvertisement_Handoff$Type extends MessageType<AppleAdvertisement_Handoff> {
    constructor() {
        super("pb.v2.AppleAdvertisement.Handoff", [
            { no: 1, name: "version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "iv", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "auth", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<AppleAdvertisement_Handoff>): AppleAdvertisement_Handoff {
        const message = { version: 0, iv: 0, auth: 0, data: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppleAdvertisement_Handoff>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppleAdvertisement_Handoff): AppleAdvertisement_Handoff {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 version */ 1:
                    message.version = reader.uint32();
                    break;
                case /* uint32 iv */ 2:
                    message.iv = reader.uint32();
                    break;
                case /* uint32 auth */ 3:
                    message.auth = reader.uint32();
                    break;
                case /* bytes data */ 4:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppleAdvertisement_Handoff, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 version = 1; */
        if (message.version !== 0)
            writer.tag(1, WireType.Varint).uint32(message.version);
        /* uint32 iv = 2; */
        if (message.iv !== 0)
            writer.tag(2, WireType.Varint).uint32(message.iv);
        /* uint32 auth = 3; */
        if (message.auth !== 0)
            writer.tag(3, WireType.Varint).uint32(message.auth);
        /* bytes data = 4; */
        if (message.data.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppleAdvertisement.Handoff
 */
export const AppleAdvertisement_Handoff = new AppleAdvertisement_Handoff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppleAdvertisement_NearbyInfo$Type extends MessageType<AppleAdvertisement_NearbyInfo> {
    constructor() {
        super("pb.v2.AppleAdvertisement.NearbyInfo", [
            { no: 1, name: "status", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<AppleAdvertisement_NearbyInfo>): AppleAdvertisement_NearbyInfo {
        const message = { status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppleAdvertisement_NearbyInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppleAdvertisement_NearbyInfo): AppleAdvertisement_NearbyInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 status */ 1:
                    message.status = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppleAdvertisement_NearbyInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).uint32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppleAdvertisement.NearbyInfo
 */
export const AppleAdvertisement_NearbyInfo = new AppleAdvertisement_NearbyInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EddystoneTLM$Type extends MessageType<EddystoneTLM> {
    constructor() {
        super("pb.v2.EddystoneTLM", [
            { no: 1, name: "battery_voltage_mv", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "temperature", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "advertisement_frames_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "second_resolution_counter", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<EddystoneTLM>): EddystoneTLM {
        const message = { batteryVoltageMv: 0, temperature: 0, advertisementFramesCount: 0, secondResolutionCounter: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EddystoneTLM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EddystoneTLM): EddystoneTLM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 battery_voltage_mv */ 1:
                    message.batteryVoltageMv = reader.uint32();
                    break;
                case /* sint32 temperature */ 2:
                    message.temperature = reader.sint32();
                    break;
                case /* uint32 advertisement_frames_count */ 3:
                    message.advertisementFramesCount = reader.uint32();
                    break;
                case /* uint32 second_resolution_counter */ 4:
                    message.secondResolutionCounter = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EddystoneTLM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 battery_voltage_mv = 1; */
        if (message.batteryVoltageMv !== 0)
            writer.tag(1, WireType.Varint).uint32(message.batteryVoltageMv);
        /* sint32 temperature = 2; */
        if (message.temperature !== 0)
            writer.tag(2, WireType.Varint).sint32(message.temperature);
        /* uint32 advertisement_frames_count = 3; */
        if (message.advertisementFramesCount !== 0)
            writer.tag(3, WireType.Varint).uint32(message.advertisementFramesCount);
        /* uint32 second_resolution_counter = 4; */
        if (message.secondResolutionCounter !== 0)
            writer.tag(4, WireType.Varint).uint32(message.secondResolutionCounter);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.EddystoneTLM
 */
export const EddystoneTLM = new EddystoneTLM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EddystoneTLM_Encrypted$Type extends MessageType<EddystoneTLM_Encrypted> {
    constructor() {
        super("pb.v2.EddystoneTLM_Encrypted", [
            { no: 1, name: "encrypted", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "salt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "mic", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<EddystoneTLM_Encrypted>): EddystoneTLM_Encrypted {
        const message = { encrypted: new Uint8Array(0), salt: 0, mic: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EddystoneTLM_Encrypted>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EddystoneTLM_Encrypted): EddystoneTLM_Encrypted {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes encrypted */ 1:
                    message.encrypted = reader.bytes();
                    break;
                case /* uint32 salt */ 2:
                    message.salt = reader.uint32();
                    break;
                case /* uint32 mic */ 3:
                    message.mic = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EddystoneTLM_Encrypted, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes encrypted = 1; */
        if (message.encrypted.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.encrypted);
        /* uint32 salt = 2; */
        if (message.salt !== 0)
            writer.tag(2, WireType.Varint).uint32(message.salt);
        /* uint32 mic = 3; */
        if (message.mic !== 0)
            writer.tag(3, WireType.Varint).uint32(message.mic);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.EddystoneTLM_Encrypted
 */
export const EddystoneTLM_Encrypted = new EddystoneTLM_Encrypted$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EddystoneEID$Type extends MessageType<EddystoneEID> {
    constructor() {
        super("pb.v2.EddystoneEID", [
            { no: 1, name: "tx_power", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 2, name: "ephemeral_identifier", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<EddystoneEID>): EddystoneEID {
        const message = { txPower: 0, ephemeralIdentifier: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EddystoneEID>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EddystoneEID): EddystoneEID {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint32 tx_power */ 1:
                    message.txPower = reader.sint32();
                    break;
                case /* bytes ephemeral_identifier */ 2:
                    message.ephemeralIdentifier = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EddystoneEID, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint32 tx_power = 1; */
        if (message.txPower !== 0)
            writer.tag(1, WireType.Varint).sint32(message.txPower);
        /* bytes ephemeral_identifier = 2; */
        if (message.ephemeralIdentifier.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.ephemeralIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.EddystoneEID
 */
export const EddystoneEID = new EddystoneEID$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EddystoneUID$Type extends MessageType<EddystoneUID> {
    constructor() {
        super("pb.v2.EddystoneUID", [
            { no: 1, name: "tx_power", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 2, name: "namespace_id", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "instance_id", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<EddystoneUID>): EddystoneUID {
        const message = { txPower: 0, namespaceId: new Uint8Array(0), instanceId: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EddystoneUID>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EddystoneUID): EddystoneUID {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint32 tx_power */ 1:
                    message.txPower = reader.sint32();
                    break;
                case /* bytes namespace_id */ 2:
                    message.namespaceId = reader.bytes();
                    break;
                case /* bytes instance_id */ 3:
                    message.instanceId = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EddystoneUID, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint32 tx_power = 1; */
        if (message.txPower !== 0)
            writer.tag(1, WireType.Varint).sint32(message.txPower);
        /* bytes namespace_id = 2; */
        if (message.namespaceId.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.namespaceId);
        /* bytes instance_id = 3; */
        if (message.instanceId.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.instanceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.EddystoneUID
 */
export const EddystoneUID = new EddystoneUID$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EddystoneURL$Type extends MessageType<EddystoneURL> {
    constructor() {
        super("pb.v2.EddystoneURL", [
            { no: 1, name: "tx_power", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 2, name: "url_scheme", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EddystoneURL>): EddystoneURL {
        const message = { txPower: 0, urlScheme: 0, url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EddystoneURL>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EddystoneURL): EddystoneURL {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint32 tx_power */ 1:
                    message.txPower = reader.sint32();
                    break;
                case /* uint32 url_scheme */ 2:
                    message.urlScheme = reader.uint32();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EddystoneURL, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint32 tx_power = 1; */
        if (message.txPower !== 0)
            writer.tag(1, WireType.Varint).sint32(message.txPower);
        /* uint32 url_scheme = 2; */
        if (message.urlScheme !== 0)
            writer.tag(2, WireType.Varint).uint32(message.urlScheme);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.EddystoneURL
 */
export const EddystoneURL = new EddystoneURL$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Eddystone$Type extends MessageType<Eddystone> {
    constructor() {
        super("pb.v2.Eddystone", [
            { no: 1, name: "eddystone_telemetry", kind: "message", T: () => EddystoneTLM },
            { no: 2, name: "eddystone_telemetry_encrypted", kind: "message", T: () => EddystoneTLM_Encrypted },
            { no: 3, name: "eddystone_eid", kind: "message", T: () => EddystoneEID },
            { no: 4, name: "eddystone_uid", kind: "message", T: () => EddystoneUID },
            { no: 5, name: "eddystone_url", kind: "message", T: () => EddystoneURL }
        ]);
    }
    create(value?: PartialMessage<Eddystone>): Eddystone {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Eddystone>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Eddystone): Eddystone {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.EddystoneTLM eddystone_telemetry */ 1:
                    message.eddystoneTelemetry = EddystoneTLM.internalBinaryRead(reader, reader.uint32(), options, message.eddystoneTelemetry);
                    break;
                case /* pb.v2.EddystoneTLM_Encrypted eddystone_telemetry_encrypted */ 2:
                    message.eddystoneTelemetryEncrypted = EddystoneTLM_Encrypted.internalBinaryRead(reader, reader.uint32(), options, message.eddystoneTelemetryEncrypted);
                    break;
                case /* pb.v2.EddystoneEID eddystone_eid */ 3:
                    message.eddystoneEid = EddystoneEID.internalBinaryRead(reader, reader.uint32(), options, message.eddystoneEid);
                    break;
                case /* pb.v2.EddystoneUID eddystone_uid */ 4:
                    message.eddystoneUid = EddystoneUID.internalBinaryRead(reader, reader.uint32(), options, message.eddystoneUid);
                    break;
                case /* pb.v2.EddystoneURL eddystone_url */ 5:
                    message.eddystoneUrl = EddystoneURL.internalBinaryRead(reader, reader.uint32(), options, message.eddystoneUrl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Eddystone, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.EddystoneTLM eddystone_telemetry = 1; */
        if (message.eddystoneTelemetry)
            EddystoneTLM.internalBinaryWrite(message.eddystoneTelemetry, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.EddystoneTLM_Encrypted eddystone_telemetry_encrypted = 2; */
        if (message.eddystoneTelemetryEncrypted)
            EddystoneTLM_Encrypted.internalBinaryWrite(message.eddystoneTelemetryEncrypted, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.EddystoneEID eddystone_eid = 3; */
        if (message.eddystoneEid)
            EddystoneEID.internalBinaryWrite(message.eddystoneEid, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.EddystoneUID eddystone_uid = 4; */
        if (message.eddystoneUid)
            EddystoneUID.internalBinaryWrite(message.eddystoneUid, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.EddystoneURL eddystone_url = 5; */
        if (message.eddystoneUrl)
            EddystoneURL.internalBinaryWrite(message.eddystoneUrl, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Eddystone
 */
export const Eddystone = new Eddystone$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GAPCommonDataType$Type extends MessageType<GAPCommonDataType> {
    constructor() {
        super("pb.v2.GAPCommonDataType", [
            { no: 1, name: "flags", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "limitedDiscoverableMode", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "generalDiscoverableMode", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "brEdrNotSupported", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "simultaneousLeBrEdrController", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "simultaneousLeBrEdrHost", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "service_uuids_16", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "service_uuids_32", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "service_uuids_128", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 12 /*ScalarType.BYTES*/ },
            { no: 10, name: "local_name_short", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "local_name_complete", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "tx_power_level", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "class_of_device", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "simple_pairing_hash_c192", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 15, name: "simple_pairing_randomizer_r192", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 16, name: "security_manager_tk_value", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 17, name: "security_manager_oob_flags", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 18, name: "peripheral_connection_interval_range", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 19, name: "service_solicitation_uuids_16", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 20, name: "service_solicitation_uuids_128", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 12 /*ScalarType.BYTES*/ },
            { no: 21, name: "service_data_16_uuid", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 22, name: "public_target_address", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 23, name: "random_target_address", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 24, name: "appearance", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 25, name: "advertising_interval", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 26, name: "le_bluetooth_device_address", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 27, name: "le_role", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 28, name: "simple_pairing_hash_c256", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 29, name: "simple_pairing_randomizer_r256", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 30, name: "service_solicitation_uuids_32", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 31, name: "service_data_32_uuid", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 32, name: "service_data_128_uuid", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 33, name: "le_secure_connections_confirmation_value", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 34, name: "le_secure_connections_random_value", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 35, name: "uri", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 36, name: "indoor_positioning", kind: "message", T: () => GAPCommonDataType_IndoorPositioning },
            { no: 37, name: "transport_discovery_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 38, name: "le_supported_features", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 39, name: "channel_map_update_indication", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 40, name: "pb_adv_mesh_profile_specification", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 41, name: "mesh_message", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 42, name: "mesh_beacon", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 43, name: "big_info", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 44, name: "broadcast_code", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 45, name: "resolvable_set_identifier", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 46, name: "advertising_interval_long", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 47, name: "public_broadcast_profile", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 48, name: "encrypted_advertising_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 49, name: "periodic_advertising_response_timing_information", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 50, name: "esl_profile", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 51, name: "three_d_information_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 52, name: "manufacturer_specific_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GAPCommonDataType>): GAPCommonDataType {
        const message = { flags: 0, limitedDiscoverableMode: false, generalDiscoverableMode: false, brEdrNotSupported: false, simultaneousLeBrEdrController: false, simultaneousLeBrEdrHost: false, serviceUuids16: [], serviceUuids32: [], serviceUuids128: [], localNameShort: "", localNameComplete: "", txPowerLevel: 0, classOfDevice: 0, simplePairingHashC192: new Uint8Array(0), simplePairingRandomizerR192: new Uint8Array(0), securityManagerTkValue: new Uint8Array(0), securityManagerOobFlags: new Uint8Array(0), peripheralConnectionIntervalRange: 0, serviceSolicitationUuids16: [], serviceSolicitationUuids128: [], serviceData16Uuid: new Uint8Array(0), publicTargetAddress: new Uint8Array(0), randomTargetAddress: new Uint8Array(0), appearance: 0, advertisingInterval: 0, leBluetoothDeviceAddress: new Uint8Array(0), leRole: false, simplePairingHashC256: new Uint8Array(0), simplePairingRandomizerR256: new Uint8Array(0), serviceSolicitationUuids32: [], serviceData32Uuid: new Uint8Array(0), serviceData128Uuid: new Uint8Array(0), leSecureConnectionsConfirmationValue: new Uint8Array(0), leSecureConnectionsRandomValue: new Uint8Array(0), uri: new Uint8Array(0), transportDiscoveryData: new Uint8Array(0), leSupportedFeatures: new Uint8Array(0), channelMapUpdateIndication: new Uint8Array(0), pbAdvMeshProfileSpecification: new Uint8Array(0), meshMessage: new Uint8Array(0), meshBeacon: new Uint8Array(0), bigInfo: new Uint8Array(0), broadcastCode: new Uint8Array(0), resolvableSetIdentifier: new Uint8Array(0), advertisingIntervalLong: 0, publicBroadcastProfile: new Uint8Array(0), encryptedAdvertisingData: new Uint8Array(0), periodicAdvertisingResponseTimingInformation: new Uint8Array(0), eslProfile: new Uint8Array(0), threeDInformationData: new Uint8Array(0), manufacturerSpecificData: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GAPCommonDataType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GAPCommonDataType): GAPCommonDataType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 flags */ 1:
                    message.flags = reader.uint32();
                    break;
                case /* bool limitedDiscoverableMode */ 2:
                    message.limitedDiscoverableMode = reader.bool();
                    break;
                case /* bool generalDiscoverableMode */ 3:
                    message.generalDiscoverableMode = reader.bool();
                    break;
                case /* bool brEdrNotSupported */ 4:
                    message.brEdrNotSupported = reader.bool();
                    break;
                case /* bool simultaneousLeBrEdrController */ 5:
                    message.simultaneousLeBrEdrController = reader.bool();
                    break;
                case /* bool simultaneousLeBrEdrHost */ 6:
                    message.simultaneousLeBrEdrHost = reader.bool();
                    break;
                case /* repeated uint32 service_uuids_16 */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.serviceUuids16.push(reader.uint32());
                    else
                        message.serviceUuids16.push(reader.uint32());
                    break;
                case /* repeated uint32 service_uuids_32 */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.serviceUuids32.push(reader.uint32());
                    else
                        message.serviceUuids32.push(reader.uint32());
                    break;
                case /* repeated bytes service_uuids_128 */ 9:
                    message.serviceUuids128.push(reader.bytes());
                    break;
                case /* string local_name_short */ 10:
                    message.localNameShort = reader.string();
                    break;
                case /* string local_name_complete */ 11:
                    message.localNameComplete = reader.string();
                    break;
                case /* int32 tx_power_level */ 12:
                    message.txPowerLevel = reader.int32();
                    break;
                case /* uint32 class_of_device */ 13:
                    message.classOfDevice = reader.uint32();
                    break;
                case /* bytes simple_pairing_hash_c192 */ 14:
                    message.simplePairingHashC192 = reader.bytes();
                    break;
                case /* bytes simple_pairing_randomizer_r192 */ 15:
                    message.simplePairingRandomizerR192 = reader.bytes();
                    break;
                case /* bytes security_manager_tk_value */ 16:
                    message.securityManagerTkValue = reader.bytes();
                    break;
                case /* bytes security_manager_oob_flags */ 17:
                    message.securityManagerOobFlags = reader.bytes();
                    break;
                case /* uint32 peripheral_connection_interval_range */ 18:
                    message.peripheralConnectionIntervalRange = reader.uint32();
                    break;
                case /* repeated uint32 service_solicitation_uuids_16 */ 19:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.serviceSolicitationUuids16.push(reader.uint32());
                    else
                        message.serviceSolicitationUuids16.push(reader.uint32());
                    break;
                case /* repeated bytes service_solicitation_uuids_128 */ 20:
                    message.serviceSolicitationUuids128.push(reader.bytes());
                    break;
                case /* bytes service_data_16_uuid */ 21:
                    message.serviceData16Uuid = reader.bytes();
                    break;
                case /* bytes public_target_address */ 22:
                    message.publicTargetAddress = reader.bytes();
                    break;
                case /* bytes random_target_address */ 23:
                    message.randomTargetAddress = reader.bytes();
                    break;
                case /* uint32 appearance */ 24:
                    message.appearance = reader.uint32();
                    break;
                case /* uint32 advertising_interval */ 25:
                    message.advertisingInterval = reader.uint32();
                    break;
                case /* bytes le_bluetooth_device_address */ 26:
                    message.leBluetoothDeviceAddress = reader.bytes();
                    break;
                case /* bool le_role */ 27:
                    message.leRole = reader.bool();
                    break;
                case /* bytes simple_pairing_hash_c256 */ 28:
                    message.simplePairingHashC256 = reader.bytes();
                    break;
                case /* bytes simple_pairing_randomizer_r256 */ 29:
                    message.simplePairingRandomizerR256 = reader.bytes();
                    break;
                case /* repeated uint32 service_solicitation_uuids_32 */ 30:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.serviceSolicitationUuids32.push(reader.uint32());
                    else
                        message.serviceSolicitationUuids32.push(reader.uint32());
                    break;
                case /* bytes service_data_32_uuid */ 31:
                    message.serviceData32Uuid = reader.bytes();
                    break;
                case /* bytes service_data_128_uuid */ 32:
                    message.serviceData128Uuid = reader.bytes();
                    break;
                case /* bytes le_secure_connections_confirmation_value */ 33:
                    message.leSecureConnectionsConfirmationValue = reader.bytes();
                    break;
                case /* bytes le_secure_connections_random_value */ 34:
                    message.leSecureConnectionsRandomValue = reader.bytes();
                    break;
                case /* bytes uri */ 35:
                    message.uri = reader.bytes();
                    break;
                case /* pb.v2.GAPCommonDataType.IndoorPositioning indoor_positioning */ 36:
                    message.indoorPositioning = GAPCommonDataType_IndoorPositioning.internalBinaryRead(reader, reader.uint32(), options, message.indoorPositioning);
                    break;
                case /* bytes transport_discovery_data */ 37:
                    message.transportDiscoveryData = reader.bytes();
                    break;
                case /* bytes le_supported_features */ 38:
                    message.leSupportedFeatures = reader.bytes();
                    break;
                case /* bytes channel_map_update_indication */ 39:
                    message.channelMapUpdateIndication = reader.bytes();
                    break;
                case /* bytes pb_adv_mesh_profile_specification */ 40:
                    message.pbAdvMeshProfileSpecification = reader.bytes();
                    break;
                case /* bytes mesh_message */ 41:
                    message.meshMessage = reader.bytes();
                    break;
                case /* bytes mesh_beacon */ 42:
                    message.meshBeacon = reader.bytes();
                    break;
                case /* bytes big_info */ 43:
                    message.bigInfo = reader.bytes();
                    break;
                case /* bytes broadcast_code */ 44:
                    message.broadcastCode = reader.bytes();
                    break;
                case /* bytes resolvable_set_identifier */ 45:
                    message.resolvableSetIdentifier = reader.bytes();
                    break;
                case /* uint32 advertising_interval_long */ 46:
                    message.advertisingIntervalLong = reader.uint32();
                    break;
                case /* bytes public_broadcast_profile */ 47:
                    message.publicBroadcastProfile = reader.bytes();
                    break;
                case /* bytes encrypted_advertising_data */ 48:
                    message.encryptedAdvertisingData = reader.bytes();
                    break;
                case /* bytes periodic_advertising_response_timing_information */ 49:
                    message.periodicAdvertisingResponseTimingInformation = reader.bytes();
                    break;
                case /* bytes esl_profile */ 50:
                    message.eslProfile = reader.bytes();
                    break;
                case /* bytes three_d_information_data */ 51:
                    message.threeDInformationData = reader.bytes();
                    break;
                case /* bytes manufacturer_specific_data */ 52:
                    message.manufacturerSpecificData = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GAPCommonDataType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 flags = 1; */
        if (message.flags !== 0)
            writer.tag(1, WireType.Varint).uint32(message.flags);
        /* bool limitedDiscoverableMode = 2; */
        if (message.limitedDiscoverableMode !== false)
            writer.tag(2, WireType.Varint).bool(message.limitedDiscoverableMode);
        /* bool generalDiscoverableMode = 3; */
        if (message.generalDiscoverableMode !== false)
            writer.tag(3, WireType.Varint).bool(message.generalDiscoverableMode);
        /* bool brEdrNotSupported = 4; */
        if (message.brEdrNotSupported !== false)
            writer.tag(4, WireType.Varint).bool(message.brEdrNotSupported);
        /* bool simultaneousLeBrEdrController = 5; */
        if (message.simultaneousLeBrEdrController !== false)
            writer.tag(5, WireType.Varint).bool(message.simultaneousLeBrEdrController);
        /* bool simultaneousLeBrEdrHost = 6; */
        if (message.simultaneousLeBrEdrHost !== false)
            writer.tag(6, WireType.Varint).bool(message.simultaneousLeBrEdrHost);
        /* repeated uint32 service_uuids_16 = 7; */
        if (message.serviceUuids16.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.serviceUuids16.length; i++)
                writer.uint32(message.serviceUuids16[i]);
            writer.join();
        }
        /* repeated uint32 service_uuids_32 = 8; */
        if (message.serviceUuids32.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.serviceUuids32.length; i++)
                writer.uint32(message.serviceUuids32[i]);
            writer.join();
        }
        /* repeated bytes service_uuids_128 = 9; */
        for (let i = 0; i < message.serviceUuids128.length; i++)
            writer.tag(9, WireType.LengthDelimited).bytes(message.serviceUuids128[i]);
        /* string local_name_short = 10; */
        if (message.localNameShort !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.localNameShort);
        /* string local_name_complete = 11; */
        if (message.localNameComplete !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.localNameComplete);
        /* int32 tx_power_level = 12; */
        if (message.txPowerLevel !== 0)
            writer.tag(12, WireType.Varint).int32(message.txPowerLevel);
        /* uint32 class_of_device = 13; */
        if (message.classOfDevice !== 0)
            writer.tag(13, WireType.Varint).uint32(message.classOfDevice);
        /* bytes simple_pairing_hash_c192 = 14; */
        if (message.simplePairingHashC192.length)
            writer.tag(14, WireType.LengthDelimited).bytes(message.simplePairingHashC192);
        /* bytes simple_pairing_randomizer_r192 = 15; */
        if (message.simplePairingRandomizerR192.length)
            writer.tag(15, WireType.LengthDelimited).bytes(message.simplePairingRandomizerR192);
        /* bytes security_manager_tk_value = 16; */
        if (message.securityManagerTkValue.length)
            writer.tag(16, WireType.LengthDelimited).bytes(message.securityManagerTkValue);
        /* bytes security_manager_oob_flags = 17; */
        if (message.securityManagerOobFlags.length)
            writer.tag(17, WireType.LengthDelimited).bytes(message.securityManagerOobFlags);
        /* uint32 peripheral_connection_interval_range = 18; */
        if (message.peripheralConnectionIntervalRange !== 0)
            writer.tag(18, WireType.Varint).uint32(message.peripheralConnectionIntervalRange);
        /* repeated uint32 service_solicitation_uuids_16 = 19; */
        if (message.serviceSolicitationUuids16.length) {
            writer.tag(19, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.serviceSolicitationUuids16.length; i++)
                writer.uint32(message.serviceSolicitationUuids16[i]);
            writer.join();
        }
        /* repeated bytes service_solicitation_uuids_128 = 20; */
        for (let i = 0; i < message.serviceSolicitationUuids128.length; i++)
            writer.tag(20, WireType.LengthDelimited).bytes(message.serviceSolicitationUuids128[i]);
        /* bytes service_data_16_uuid = 21; */
        if (message.serviceData16Uuid.length)
            writer.tag(21, WireType.LengthDelimited).bytes(message.serviceData16Uuid);
        /* bytes public_target_address = 22; */
        if (message.publicTargetAddress.length)
            writer.tag(22, WireType.LengthDelimited).bytes(message.publicTargetAddress);
        /* bytes random_target_address = 23; */
        if (message.randomTargetAddress.length)
            writer.tag(23, WireType.LengthDelimited).bytes(message.randomTargetAddress);
        /* uint32 appearance = 24; */
        if (message.appearance !== 0)
            writer.tag(24, WireType.Varint).uint32(message.appearance);
        /* uint32 advertising_interval = 25; */
        if (message.advertisingInterval !== 0)
            writer.tag(25, WireType.Varint).uint32(message.advertisingInterval);
        /* bytes le_bluetooth_device_address = 26; */
        if (message.leBluetoothDeviceAddress.length)
            writer.tag(26, WireType.LengthDelimited).bytes(message.leBluetoothDeviceAddress);
        /* bool le_role = 27; */
        if (message.leRole !== false)
            writer.tag(27, WireType.Varint).bool(message.leRole);
        /* bytes simple_pairing_hash_c256 = 28; */
        if (message.simplePairingHashC256.length)
            writer.tag(28, WireType.LengthDelimited).bytes(message.simplePairingHashC256);
        /* bytes simple_pairing_randomizer_r256 = 29; */
        if (message.simplePairingRandomizerR256.length)
            writer.tag(29, WireType.LengthDelimited).bytes(message.simplePairingRandomizerR256);
        /* repeated uint32 service_solicitation_uuids_32 = 30; */
        if (message.serviceSolicitationUuids32.length) {
            writer.tag(30, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.serviceSolicitationUuids32.length; i++)
                writer.uint32(message.serviceSolicitationUuids32[i]);
            writer.join();
        }
        /* bytes service_data_32_uuid = 31; */
        if (message.serviceData32Uuid.length)
            writer.tag(31, WireType.LengthDelimited).bytes(message.serviceData32Uuid);
        /* bytes service_data_128_uuid = 32; */
        if (message.serviceData128Uuid.length)
            writer.tag(32, WireType.LengthDelimited).bytes(message.serviceData128Uuid);
        /* bytes le_secure_connections_confirmation_value = 33; */
        if (message.leSecureConnectionsConfirmationValue.length)
            writer.tag(33, WireType.LengthDelimited).bytes(message.leSecureConnectionsConfirmationValue);
        /* bytes le_secure_connections_random_value = 34; */
        if (message.leSecureConnectionsRandomValue.length)
            writer.tag(34, WireType.LengthDelimited).bytes(message.leSecureConnectionsRandomValue);
        /* bytes uri = 35; */
        if (message.uri.length)
            writer.tag(35, WireType.LengthDelimited).bytes(message.uri);
        /* pb.v2.GAPCommonDataType.IndoorPositioning indoor_positioning = 36; */
        if (message.indoorPositioning)
            GAPCommonDataType_IndoorPositioning.internalBinaryWrite(message.indoorPositioning, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* bytes transport_discovery_data = 37; */
        if (message.transportDiscoveryData.length)
            writer.tag(37, WireType.LengthDelimited).bytes(message.transportDiscoveryData);
        /* bytes le_supported_features = 38; */
        if (message.leSupportedFeatures.length)
            writer.tag(38, WireType.LengthDelimited).bytes(message.leSupportedFeatures);
        /* bytes channel_map_update_indication = 39; */
        if (message.channelMapUpdateIndication.length)
            writer.tag(39, WireType.LengthDelimited).bytes(message.channelMapUpdateIndication);
        /* bytes pb_adv_mesh_profile_specification = 40; */
        if (message.pbAdvMeshProfileSpecification.length)
            writer.tag(40, WireType.LengthDelimited).bytes(message.pbAdvMeshProfileSpecification);
        /* bytes mesh_message = 41; */
        if (message.meshMessage.length)
            writer.tag(41, WireType.LengthDelimited).bytes(message.meshMessage);
        /* bytes mesh_beacon = 42; */
        if (message.meshBeacon.length)
            writer.tag(42, WireType.LengthDelimited).bytes(message.meshBeacon);
        /* bytes big_info = 43; */
        if (message.bigInfo.length)
            writer.tag(43, WireType.LengthDelimited).bytes(message.bigInfo);
        /* bytes broadcast_code = 44; */
        if (message.broadcastCode.length)
            writer.tag(44, WireType.LengthDelimited).bytes(message.broadcastCode);
        /* bytes resolvable_set_identifier = 45; */
        if (message.resolvableSetIdentifier.length)
            writer.tag(45, WireType.LengthDelimited).bytes(message.resolvableSetIdentifier);
        /* uint32 advertising_interval_long = 46; */
        if (message.advertisingIntervalLong !== 0)
            writer.tag(46, WireType.Varint).uint32(message.advertisingIntervalLong);
        /* bytes public_broadcast_profile = 47; */
        if (message.publicBroadcastProfile.length)
            writer.tag(47, WireType.LengthDelimited).bytes(message.publicBroadcastProfile);
        /* bytes encrypted_advertising_data = 48; */
        if (message.encryptedAdvertisingData.length)
            writer.tag(48, WireType.LengthDelimited).bytes(message.encryptedAdvertisingData);
        /* bytes periodic_advertising_response_timing_information = 49; */
        if (message.periodicAdvertisingResponseTimingInformation.length)
            writer.tag(49, WireType.LengthDelimited).bytes(message.periodicAdvertisingResponseTimingInformation);
        /* bytes esl_profile = 50; */
        if (message.eslProfile.length)
            writer.tag(50, WireType.LengthDelimited).bytes(message.eslProfile);
        /* bytes three_d_information_data = 51; */
        if (message.threeDInformationData.length)
            writer.tag(51, WireType.LengthDelimited).bytes(message.threeDInformationData);
        /* bytes manufacturer_specific_data = 52; */
        if (message.manufacturerSpecificData.length)
            writer.tag(52, WireType.LengthDelimited).bytes(message.manufacturerSpecificData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GAPCommonDataType
 */
export const GAPCommonDataType = new GAPCommonDataType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GAPCommonDataType_IndoorPositioning$Type extends MessageType<GAPCommonDataType_IndoorPositioning> {
    constructor() {
        super("pb.v2.GAPCommonDataType.IndoorPositioning", [
            { no: 1, name: "version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "latitude", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "longitude", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "altitude", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "floor_number", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "uncertainty", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<GAPCommonDataType_IndoorPositioning>): GAPCommonDataType_IndoorPositioning {
        const message = { version: 0, latitude: 0, longitude: 0, altitude: 0, floorNumber: 0, uncertainty: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GAPCommonDataType_IndoorPositioning>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GAPCommonDataType_IndoorPositioning): GAPCommonDataType_IndoorPositioning {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 version */ 1:
                    message.version = reader.uint32();
                    break;
                case /* uint32 latitude */ 2:
                    message.latitude = reader.uint32();
                    break;
                case /* uint32 longitude */ 3:
                    message.longitude = reader.uint32();
                    break;
                case /* uint32 altitude */ 4:
                    message.altitude = reader.uint32();
                    break;
                case /* uint32 floor_number */ 5:
                    message.floorNumber = reader.uint32();
                    break;
                case /* uint32 uncertainty */ 6:
                    message.uncertainty = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GAPCommonDataType_IndoorPositioning, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 version = 1; */
        if (message.version !== 0)
            writer.tag(1, WireType.Varint).uint32(message.version);
        /* uint32 latitude = 2; */
        if (message.latitude !== 0)
            writer.tag(2, WireType.Varint).uint32(message.latitude);
        /* uint32 longitude = 3; */
        if (message.longitude !== 0)
            writer.tag(3, WireType.Varint).uint32(message.longitude);
        /* uint32 altitude = 4; */
        if (message.altitude !== 0)
            writer.tag(4, WireType.Varint).uint32(message.altitude);
        /* uint32 floor_number = 5; */
        if (message.floorNumber !== 0)
            writer.tag(5, WireType.Varint).uint32(message.floorNumber);
        /* uint32 uncertainty = 6; */
        if (message.uncertainty !== 0)
            writer.tag(6, WireType.Varint).uint32(message.uncertainty);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GAPCommonDataType.IndoorPositioning
 */
export const GAPCommonDataType_IndoorPositioning = new GAPCommonDataType_IndoorPositioning$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BluetoothInformation$Type extends MessageType<BluetoothInformation> {
    constructor() {
        super("pb.v2.BluetoothInformation", [
            { no: 1, name: "macAddress", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "rssi", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "latest_adv_packet", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "latest_rsp_packet", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 5, name: "gap_advertisement", kind: "message", T: () => GAPCommonDataType },
            { no: 6, name: "apple_advertisement", kind: "message", T: () => AppleAdvertisement },
            { no: 7, name: "eddystone_advertisement", kind: "message", T: () => Eddystone }
        ]);
    }
    create(value?: PartialMessage<BluetoothInformation>): BluetoothInformation {
        const message = { macAddress: new Uint8Array(0), rssi: 0, latestAdvPacket: new Uint8Array(0), latestRspPacket: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BluetoothInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BluetoothInformation): BluetoothInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes macAddress */ 1:
                    message.macAddress = reader.bytes();
                    break;
                case /* sint32 rssi */ 2:
                    message.rssi = reader.sint32();
                    break;
                case /* bytes latest_adv_packet */ 3:
                    message.latestAdvPacket = reader.bytes();
                    break;
                case /* bytes latest_rsp_packet */ 4:
                    message.latestRspPacket = reader.bytes();
                    break;
                case /* pb.v2.GAPCommonDataType gap_advertisement */ 5:
                    message.gapAdvertisement = GAPCommonDataType.internalBinaryRead(reader, reader.uint32(), options, message.gapAdvertisement);
                    break;
                case /* pb.v2.AppleAdvertisement apple_advertisement */ 6:
                    message.appleAdvertisement = AppleAdvertisement.internalBinaryRead(reader, reader.uint32(), options, message.appleAdvertisement);
                    break;
                case /* pb.v2.Eddystone eddystone_advertisement */ 7:
                    message.eddystoneAdvertisement = Eddystone.internalBinaryRead(reader, reader.uint32(), options, message.eddystoneAdvertisement);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BluetoothInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes macAddress = 1; */
        if (message.macAddress.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.macAddress);
        /* sint32 rssi = 2; */
        if (message.rssi !== 0)
            writer.tag(2, WireType.Varint).sint32(message.rssi);
        /* bytes latest_adv_packet = 3; */
        if (message.latestAdvPacket.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.latestAdvPacket);
        /* bytes latest_rsp_packet = 4; */
        if (message.latestRspPacket.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.latestRspPacket);
        /* pb.v2.GAPCommonDataType gap_advertisement = 5; */
        if (message.gapAdvertisement)
            GAPCommonDataType.internalBinaryWrite(message.gapAdvertisement, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.AppleAdvertisement apple_advertisement = 6; */
        if (message.appleAdvertisement)
            AppleAdvertisement.internalBinaryWrite(message.appleAdvertisement, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Eddystone eddystone_advertisement = 7; */
        if (message.eddystoneAdvertisement)
            Eddystone.internalBinaryWrite(message.eddystoneAdvertisement, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.BluetoothInformation
 */
export const BluetoothInformation = new BluetoothInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Onboarding$Type extends MessageType<Onboarding> {
    constructor() {
        super("pb.v2.Onboarding", [
            { no: 1, name: "status", kind: "enum", T: () => ["pb.v2.OnboardingStatus", OnboardingStatus, "ONBOARDING_STATUS_"] },
            { no: 2, name: "user_created", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "modified_by_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "modified_timestamp", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "created_by_service_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Onboarding>): Onboarding {
        const message = { status: 0, userCreated: false, modifiedByUrn: "", modifiedTimestamp: 0n, createdByServiceUrn: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Onboarding>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Onboarding): Onboarding {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.OnboardingStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* bool user_created */ 2:
                    message.userCreated = reader.bool();
                    break;
                case /* string modified_by_urn */ 3:
                    message.modifiedByUrn = reader.string();
                    break;
                case /* uint64 modified_timestamp */ 4:
                    message.modifiedTimestamp = reader.uint64().toBigInt();
                    break;
                case /* string created_by_service_urn */ 5:
                    message.createdByServiceUrn = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Onboarding, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.OnboardingStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* bool user_created = 2; */
        if (message.userCreated !== false)
            writer.tag(2, WireType.Varint).bool(message.userCreated);
        /* string modified_by_urn = 3; */
        if (message.modifiedByUrn !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.modifiedByUrn);
        /* uint64 modified_timestamp = 4; */
        if (message.modifiedTimestamp !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.modifiedTimestamp);
        /* string created_by_service_urn = 5; */
        if (message.createdByServiceUrn !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createdByServiceUrn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Onboarding
 */
export const Onboarding = new Onboarding$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SensorsConfiguration$Type extends MessageType<SensorsConfiguration> {
    constructor() {
        super("pb.v2.SensorsConfiguration", [
            { no: 1, name: "offset_environment_temperature_millidegree_c", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 2, name: "offset_environment_temperature_scale_factor", kind: "scalar", T: 17 /*ScalarType.SINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SensorsConfiguration>): SensorsConfiguration {
        const message = { offsetEnvironmentTemperatureMillidegreeC: 0, offsetEnvironmentTemperatureScaleFactor: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SensorsConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SensorsConfiguration): SensorsConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint32 offset_environment_temperature_millidegree_c */ 1:
                    message.offsetEnvironmentTemperatureMillidegreeC = reader.sint32();
                    break;
                case /* sint32 offset_environment_temperature_scale_factor */ 2:
                    message.offsetEnvironmentTemperatureScaleFactor = reader.sint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SensorsConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint32 offset_environment_temperature_millidegree_c = 1; */
        if (message.offsetEnvironmentTemperatureMillidegreeC !== 0)
            writer.tag(1, WireType.Varint).sint32(message.offsetEnvironmentTemperatureMillidegreeC);
        /* sint32 offset_environment_temperature_scale_factor = 2; */
        if (message.offsetEnvironmentTemperatureScaleFactor !== 0)
            writer.tag(2, WireType.Varint).sint32(message.offsetEnvironmentTemperatureScaleFactor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.SensorsConfiguration
 */
export const SensorsConfiguration = new SensorsConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Device$Type extends MessageType<Device> {
    constructor() {
        super("pb.v2.Device", [
            { no: 1, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "serial", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "found_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "device_type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "connection_info", kind: "message", T: () => ConnectionInformation },
            { no: 7, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "icon_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "color", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 10, name: "manual_position", kind: "message", T: () => Position },
            { no: 11, name: "deviceSettings", kind: "message", T: () => Settings },
            { no: 12, name: "targetSettings", kind: "message", T: () => Settings },
            { no: 13, name: "device_group_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "device_group_id_variant", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tag },
            { no: 16, name: "manufacturer", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "product_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "hardware_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "manufacturer_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "is_blocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "log_mask", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 22, name: "msgpack", kind: "message", T: () => MessagePack },
            { no: 23, name: "keyValues", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KeyValue },
            { no: 24, name: "primary_asset_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "assets_urns", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "bluetooth_info", kind: "message", T: () => BluetoothInformation },
            { no: 27, name: "reported_position", kind: "message", T: () => Position },
            { no: 28, name: "calculated_position", kind: "message", T: () => Position },
            { no: 29, name: "sensors_configuration", kind: "message", T: () => SensorsConfiguration }
        ]);
    }
    create(value?: PartialMessage<Device>): Device {
        const message = { uuid: 0n, serial: 0n, name: "", foundName: "", deviceType: 0, description: "", iconUrl: "", color: new Uint8Array(0), deviceGroupId: 0, deviceGroupIdVariant: 0, tags: [], manufacturer: "", productName: "", hardwareVersion: "", manufacturerType: "", isBlocked: false, logMask: 0, keyValues: [], primaryAssetUrn: "", assetsUrns: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Device>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Device): Device {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 uuid */ 1:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                case /* uint64 serial */ 2:
                    message.serial = reader.uint64().toBigInt();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string found_name */ 4:
                    message.foundName = reader.string();
                    break;
                case /* uint32 device_type */ 5:
                    message.deviceType = reader.uint32();
                    break;
                case /* pb.v2.ConnectionInformation connection_info */ 6:
                    message.connectionInfo = ConnectionInformation.internalBinaryRead(reader, reader.uint32(), options, message.connectionInfo);
                    break;
                case /* string description */ 7:
                    message.description = reader.string();
                    break;
                case /* string icon_url */ 8:
                    message.iconUrl = reader.string();
                    break;
                case /* bytes color */ 9:
                    message.color = reader.bytes();
                    break;
                case /* pb.v2.Position manual_position */ 10:
                    message.manualPosition = Position.internalBinaryRead(reader, reader.uint32(), options, message.manualPosition);
                    break;
                case /* pb.v2.Settings deviceSettings */ 11:
                    message.deviceSettings = Settings.internalBinaryRead(reader, reader.uint32(), options, message.deviceSettings);
                    break;
                case /* pb.v2.Settings targetSettings */ 12:
                    message.targetSettings = Settings.internalBinaryRead(reader, reader.uint32(), options, message.targetSettings);
                    break;
                case /* uint32 device_group_id */ 13:
                    message.deviceGroupId = reader.uint32();
                    break;
                case /* uint32 device_group_id_variant */ 14:
                    message.deviceGroupIdVariant = reader.uint32();
                    break;
                case /* repeated pb.v2.Tag tags */ 15:
                    message.tags.push(Tag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string manufacturer */ 16:
                    message.manufacturer = reader.string();
                    break;
                case /* string product_name */ 17:
                    message.productName = reader.string();
                    break;
                case /* string hardware_version */ 18:
                    message.hardwareVersion = reader.string();
                    break;
                case /* string manufacturer_type */ 19:
                    message.manufacturerType = reader.string();
                    break;
                case /* bool is_blocked */ 20:
                    message.isBlocked = reader.bool();
                    break;
                case /* uint32 log_mask */ 21:
                    message.logMask = reader.uint32();
                    break;
                case /* pb.v2.MessagePack msgpack */ 22:
                    message.msgpack = MessagePack.internalBinaryRead(reader, reader.uint32(), options, message.msgpack);
                    break;
                case /* repeated pb.v2.KeyValue keyValues */ 23:
                    message.keyValues.push(KeyValue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string primary_asset_urn */ 24:
                    message.primaryAssetUrn = reader.string();
                    break;
                case /* repeated string assets_urns */ 25:
                    message.assetsUrns.push(reader.string());
                    break;
                case /* pb.v2.BluetoothInformation bluetooth_info */ 26:
                    message.bluetoothInfo = BluetoothInformation.internalBinaryRead(reader, reader.uint32(), options, message.bluetoothInfo);
                    break;
                case /* pb.v2.Position reported_position */ 27:
                    message.reportedPosition = Position.internalBinaryRead(reader, reader.uint32(), options, message.reportedPosition);
                    break;
                case /* pb.v2.Position calculated_position */ 28:
                    message.calculatedPosition = Position.internalBinaryRead(reader, reader.uint32(), options, message.calculatedPosition);
                    break;
                case /* pb.v2.SensorsConfiguration sensors_configuration */ 29:
                    message.sensorsConfiguration = SensorsConfiguration.internalBinaryRead(reader, reader.uint32(), options, message.sensorsConfiguration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Device, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 uuid = 1; */
        if (message.uuid !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.uuid);
        /* uint64 serial = 2; */
        if (message.serial !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.serial);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string found_name = 4; */
        if (message.foundName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.foundName);
        /* uint32 device_type = 5; */
        if (message.deviceType !== 0)
            writer.tag(5, WireType.Varint).uint32(message.deviceType);
        /* pb.v2.ConnectionInformation connection_info = 6; */
        if (message.connectionInfo)
            ConnectionInformation.internalBinaryWrite(message.connectionInfo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string description = 7; */
        if (message.description !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.description);
        /* string icon_url = 8; */
        if (message.iconUrl !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.iconUrl);
        /* bytes color = 9; */
        if (message.color.length)
            writer.tag(9, WireType.LengthDelimited).bytes(message.color);
        /* pb.v2.Position manual_position = 10; */
        if (message.manualPosition)
            Position.internalBinaryWrite(message.manualPosition, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Settings deviceSettings = 11; */
        if (message.deviceSettings)
            Settings.internalBinaryWrite(message.deviceSettings, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Settings targetSettings = 12; */
        if (message.targetSettings)
            Settings.internalBinaryWrite(message.targetSettings, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* uint32 device_group_id = 13; */
        if (message.deviceGroupId !== 0)
            writer.tag(13, WireType.Varint).uint32(message.deviceGroupId);
        /* uint32 device_group_id_variant = 14; */
        if (message.deviceGroupIdVariant !== 0)
            writer.tag(14, WireType.Varint).uint32(message.deviceGroupIdVariant);
        /* repeated pb.v2.Tag tags = 15; */
        for (let i = 0; i < message.tags.length; i++)
            Tag.internalBinaryWrite(message.tags[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string manufacturer = 16; */
        if (message.manufacturer !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.manufacturer);
        /* string product_name = 17; */
        if (message.productName !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.productName);
        /* string hardware_version = 18; */
        if (message.hardwareVersion !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.hardwareVersion);
        /* string manufacturer_type = 19; */
        if (message.manufacturerType !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.manufacturerType);
        /* bool is_blocked = 20; */
        if (message.isBlocked !== false)
            writer.tag(20, WireType.Varint).bool(message.isBlocked);
        /* uint32 log_mask = 21; */
        if (message.logMask !== 0)
            writer.tag(21, WireType.Varint).uint32(message.logMask);
        /* pb.v2.MessagePack msgpack = 22; */
        if (message.msgpack)
            MessagePack.internalBinaryWrite(message.msgpack, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.KeyValue keyValues = 23; */
        for (let i = 0; i < message.keyValues.length; i++)
            KeyValue.internalBinaryWrite(message.keyValues[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* string primary_asset_urn = 24; */
        if (message.primaryAssetUrn !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.primaryAssetUrn);
        /* repeated string assets_urns = 25; */
        for (let i = 0; i < message.assetsUrns.length; i++)
            writer.tag(25, WireType.LengthDelimited).string(message.assetsUrns[i]);
        /* pb.v2.BluetoothInformation bluetooth_info = 26; */
        if (message.bluetoothInfo)
            BluetoothInformation.internalBinaryWrite(message.bluetoothInfo, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Position reported_position = 27; */
        if (message.reportedPosition)
            Position.internalBinaryWrite(message.reportedPosition, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Position calculated_position = 28; */
        if (message.calculatedPosition)
            Position.internalBinaryWrite(message.calculatedPosition, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.SensorsConfiguration sensors_configuration = 29; */
        if (message.sensorsConfiguration)
            SensorsConfiguration.internalBinaryWrite(message.sensorsConfiguration, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Device
 */
export const Device = new Device$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceGroup$Type extends MessageType<DeviceGroup> {
    constructor() {
        super("pb.v2.DeviceGroup", [
            { no: 1, name: "device_group_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "device_group_id_variant", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "partitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeviceGroup_Partition }
        ]);
    }
    create(value?: PartialMessage<DeviceGroup>): DeviceGroup {
        const message = { deviceGroupId: 0, deviceGroupIdVariant: 0, name: "", description: "", partitions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceGroup): DeviceGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 device_group_id */ 1:
                    message.deviceGroupId = reader.uint32();
                    break;
                case /* uint32 device_group_id_variant */ 2:
                    message.deviceGroupIdVariant = reader.uint32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* repeated pb.v2.DeviceGroup.Partition partitions */ 6:
                    message.partitions.push(DeviceGroup_Partition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 device_group_id = 1; */
        if (message.deviceGroupId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.deviceGroupId);
        /* uint32 device_group_id_variant = 2; */
        if (message.deviceGroupIdVariant !== 0)
            writer.tag(2, WireType.Varint).uint32(message.deviceGroupIdVariant);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* repeated pb.v2.DeviceGroup.Partition partitions = 6; */
        for (let i = 0; i < message.partitions.length; i++)
            DeviceGroup_Partition.internalBinaryWrite(message.partitions[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DeviceGroup
 */
export const DeviceGroup = new DeviceGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceGroup_Partition$Type extends MessageType<DeviceGroup_Partition> {
    constructor() {
        super("pb.v2.DeviceGroup.Partition", [
            { no: 1, name: "order", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "firmware_file_urns", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeviceGroup_Partition>): DeviceGroup_Partition {
        const message = { order: 0, name: "", description: "", firmwareFileUrns: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceGroup_Partition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceGroup_Partition): DeviceGroup_Partition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 order */ 1:
                    message.order = reader.uint32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* repeated string firmware_file_urns */ 4:
                    message.firmwareFileUrns.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceGroup_Partition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 order = 1; */
        if (message.order !== 0)
            writer.tag(1, WireType.Varint).uint32(message.order);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* repeated string firmware_file_urns = 4; */
        for (let i = 0; i < message.firmwareFileUrns.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.firmwareFileUrns[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DeviceGroup.Partition
 */
export const DeviceGroup_Partition = new DeviceGroup_Partition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FirmwareFile$Type extends MessageType<FirmwareFile> {
    constructor() {
        super("pb.v2.FirmwareFile", [
            { no: 1, name: "md5", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 16 } } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ver_major", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "ver_minor", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "ver_patch", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "hardware_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "release_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "commit_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FirmwareFile>): FirmwareFile {
        const message = { md5: new Uint8Array(0), name: "", verMajor: 0, verMinor: 0, verPatch: 0, hardwareType: "", releaseNotes: "", commitUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FirmwareFile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FirmwareFile): FirmwareFile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes md5 */ 1:
                    message.md5 = reader.bytes();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* uint32 ver_major */ 3:
                    message.verMajor = reader.uint32();
                    break;
                case /* uint32 ver_minor */ 4:
                    message.verMinor = reader.uint32();
                    break;
                case /* uint32 ver_patch */ 5:
                    message.verPatch = reader.uint32();
                    break;
                case /* string hardware_type */ 6:
                    message.hardwareType = reader.string();
                    break;
                case /* string release_notes */ 7:
                    message.releaseNotes = reader.string();
                    break;
                case /* string commit_url */ 8:
                    message.commitUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FirmwareFile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes md5 = 1; */
        if (message.md5.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.md5);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* uint32 ver_major = 3; */
        if (message.verMajor !== 0)
            writer.tag(3, WireType.Varint).uint32(message.verMajor);
        /* uint32 ver_minor = 4; */
        if (message.verMinor !== 0)
            writer.tag(4, WireType.Varint).uint32(message.verMinor);
        /* uint32 ver_patch = 5; */
        if (message.verPatch !== 0)
            writer.tag(5, WireType.Varint).uint32(message.verPatch);
        /* string hardware_type = 6; */
        if (message.hardwareType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.hardwareType);
        /* string release_notes = 7; */
        if (message.releaseNotes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.releaseNotes);
        /* string commit_url = 8; */
        if (message.commitUrl !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.commitUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.FirmwareFile
 */
export const FirmwareFile = new FirmwareFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class File$Type extends MessageType<File> {
    constructor() {
        super("pb.v2.File", [
            { no: 1, name: "md5", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 16 } } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "length", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 7, name: "data_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<File>): File {
        const message = { md5: new Uint8Array(0), name: "", mimeType: "", length: 0, notes: "", data: new Uint8Array(0), dataUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<File>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: File): File {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes md5 */ 1:
                    message.md5 = reader.bytes();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string mime_type */ 3:
                    message.mimeType = reader.string();
                    break;
                case /* uint32 length */ 4:
                    message.length = reader.uint32();
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* bytes data */ 6:
                    message.data = reader.bytes();
                    break;
                case /* string data_url */ 7:
                    message.dataUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: File, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes md5 = 1; */
        if (message.md5.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.md5);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string mime_type = 3; */
        if (message.mimeType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.mimeType);
        /* uint32 length = 4; */
        if (message.length !== 0)
            writer.tag(4, WireType.Varint).uint32(message.length);
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* bytes data = 6; */
        if (message.data.length)
            writer.tag(6, WireType.LengthDelimited).bytes(message.data);
        /* string data_url = 7; */
        if (message.dataUrl !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dataUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.File
 */
export const File = new File$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Person$Type extends MessageType<Person> {
    constructor() {
        super("pb.v2.Person", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sign_pub", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "sign_priv", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "encrypt_pub", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 5, name: "encrypt_priv", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "contacts", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "profile_picture_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "short_username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "color", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 13, name: "address", kind: "message", T: () => Address },
            { no: 14, name: "profile_not_provisioned", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "temprary_password", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Person>): Person {
        const message = { username: "", signPub: new Uint8Array(0), signPriv: new Uint8Array(0), encryptPub: new Uint8Array(0), encryptPriv: new Uint8Array(0), name: "", email: "", roles: [], contacts: [], profilePictureUrn: "", shortUsername: "", color: new Uint8Array(0), profileNotProvisioned: false, tempraryPassword: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Person>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Person): Person {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string username */ 1:
                    message.username = reader.string();
                    break;
                case /* bytes sign_pub */ 2:
                    message.signPub = reader.bytes();
                    break;
                case /* bytes sign_priv */ 3:
                    message.signPriv = reader.bytes();
                    break;
                case /* bytes encrypt_pub */ 4:
                    message.encryptPub = reader.bytes();
                    break;
                case /* bytes encrypt_priv */ 5:
                    message.encryptPriv = reader.bytes();
                    break;
                case /* string name */ 6:
                    message.name = reader.string();
                    break;
                case /* string email */ 7:
                    message.email = reader.string();
                    break;
                case /* repeated string roles */ 8:
                    message.roles.push(reader.string());
                    break;
                case /* repeated string contacts */ 9:
                    message.contacts.push(reader.string());
                    break;
                case /* string profile_picture_urn */ 10:
                    message.profilePictureUrn = reader.string();
                    break;
                case /* string short_username */ 11:
                    message.shortUsername = reader.string();
                    break;
                case /* bytes color */ 12:
                    message.color = reader.bytes();
                    break;
                case /* pb.v2.Address address */ 13:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* bool profile_not_provisioned */ 14:
                    message.profileNotProvisioned = reader.bool();
                    break;
                case /* bool temprary_password */ 15:
                    message.tempraryPassword = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Person, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string username = 1; */
        if (message.username !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.username);
        /* bytes sign_pub = 2; */
        if (message.signPub.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.signPub);
        /* bytes sign_priv = 3; */
        if (message.signPriv.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.signPriv);
        /* bytes encrypt_pub = 4; */
        if (message.encryptPub.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.encryptPub);
        /* bytes encrypt_priv = 5; */
        if (message.encryptPriv.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.encryptPriv);
        /* string name = 6; */
        if (message.name !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.name);
        /* string email = 7; */
        if (message.email !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.email);
        /* repeated string roles = 8; */
        for (let i = 0; i < message.roles.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.roles[i]);
        /* repeated string contacts = 9; */
        for (let i = 0; i < message.contacts.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.contacts[i]);
        /* string profile_picture_urn = 10; */
        if (message.profilePictureUrn !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.profilePictureUrn);
        /* string short_username = 11; */
        if (message.shortUsername !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.shortUsername);
        /* bytes color = 12; */
        if (message.color.length)
            writer.tag(12, WireType.LengthDelimited).bytes(message.color);
        /* pb.v2.Address address = 13; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bool profile_not_provisioned = 14; */
        if (message.profileNotProvisioned !== false)
            writer.tag(14, WireType.Varint).bool(message.profileNotProvisioned);
        /* bool temprary_password = 15; */
        if (message.tempraryPassword !== false)
            writer.tag(15, WireType.Varint).bool(message.tempraryPassword);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Person
 */
export const Person = new Person$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Asset$Type extends MessageType<Asset> {
    constructor() {
        super("pb.v2.Asset", [
            { no: 1, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "position", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position },
            { no: 5, name: "asset_geonode_connections", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Asset_AssetGeonodeConection },
            { no: 6, name: "device_asset_connections", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Asset_DeviceAssetConnection },
            { no: 7, name: "keyValues", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KeyValue },
            { no: 8, name: "labels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Asset_Label }
        ]);
    }
    create(value?: PartialMessage<Asset>): Asset {
        const message = { uuid: 0n, name: "", description: "", position: [], assetGeonodeConnections: [], deviceAssetConnections: [], keyValues: [], labels: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Asset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Asset): Asset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 uuid */ 1:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* repeated pb.v2.Position position */ 4:
                    message.position.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.Asset.AssetGeonodeConection asset_geonode_connections */ 5:
                    message.assetGeonodeConnections.push(Asset_AssetGeonodeConection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.Asset.DeviceAssetConnection device_asset_connections */ 6:
                    message.deviceAssetConnections.push(Asset_DeviceAssetConnection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.KeyValue keyValues */ 7:
                    message.keyValues.push(KeyValue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.Asset.Label labels */ 8:
                    message.labels.push(Asset_Label.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Asset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 uuid = 1; */
        if (message.uuid !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.uuid);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* repeated pb.v2.Position position = 4; */
        for (let i = 0; i < message.position.length; i++)
            Position.internalBinaryWrite(message.position[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.Asset.AssetGeonodeConection asset_geonode_connections = 5; */
        for (let i = 0; i < message.assetGeonodeConnections.length; i++)
            Asset_AssetGeonodeConection.internalBinaryWrite(message.assetGeonodeConnections[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.Asset.DeviceAssetConnection device_asset_connections = 6; */
        for (let i = 0; i < message.deviceAssetConnections.length; i++)
            Asset_DeviceAssetConnection.internalBinaryWrite(message.deviceAssetConnections[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.KeyValue keyValues = 7; */
        for (let i = 0; i < message.keyValues.length; i++)
            KeyValue.internalBinaryWrite(message.keyValues[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.Asset.Label labels = 8; */
        for (let i = 0; i < message.labels.length; i++)
            Asset_Label.internalBinaryWrite(message.labels[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Asset
 */
export const Asset = new Asset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Asset_AssetGeonodeConection$Type extends MessageType<Asset_AssetGeonodeConection> {
    constructor() {
        super("pb.v2.Asset.AssetGeonodeConection", [
            { no: 1, name: "geonode_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start_epoch", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "end_epoch", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Asset_AssetGeonodeConection>): Asset_AssetGeonodeConection {
        const message = { geonodeUrn: "", startEpoch: 0n, endEpoch: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Asset_AssetGeonodeConection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Asset_AssetGeonodeConection): Asset_AssetGeonodeConection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string geonode_urn */ 1:
                    message.geonodeUrn = reader.string();
                    break;
                case /* uint64 start_epoch */ 2:
                    message.startEpoch = reader.uint64().toBigInt();
                    break;
                case /* uint64 end_epoch */ 3:
                    message.endEpoch = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Asset_AssetGeonodeConection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string geonode_urn = 1; */
        if (message.geonodeUrn !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.geonodeUrn);
        /* uint64 start_epoch = 2; */
        if (message.startEpoch !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.startEpoch);
        /* uint64 end_epoch = 3; */
        if (message.endEpoch !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.endEpoch);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Asset.AssetGeonodeConection
 */
export const Asset_AssetGeonodeConection = new Asset_AssetGeonodeConection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Asset_DeviceAssetConnection$Type extends MessageType<Asset_DeviceAssetConnection> {
    constructor() {
        super("pb.v2.Asset.DeviceAssetConnection", [
            { no: 1, name: "device_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start_epoch", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "end_epoch", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Asset_DeviceAssetConnection>): Asset_DeviceAssetConnection {
        const message = { deviceUrn: "", startEpoch: 0n, endEpoch: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Asset_DeviceAssetConnection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Asset_DeviceAssetConnection): Asset_DeviceAssetConnection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_urn */ 1:
                    message.deviceUrn = reader.string();
                    break;
                case /* uint64 start_epoch */ 2:
                    message.startEpoch = reader.uint64().toBigInt();
                    break;
                case /* uint64 end_epoch */ 3:
                    message.endEpoch = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Asset_DeviceAssetConnection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string device_urn = 1; */
        if (message.deviceUrn !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceUrn);
        /* uint64 start_epoch = 2; */
        if (message.startEpoch !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.startEpoch);
        /* uint64 end_epoch = 3; */
        if (message.endEpoch !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.endEpoch);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Asset.DeviceAssetConnection
 */
export const Asset_DeviceAssetConnection = new Asset_DeviceAssetConnection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Asset_Label$Type extends MessageType<Asset_Label> {
    constructor() {
        super("pb.v2.Asset.Label", [
            { no: 1, name: "type", kind: "enum", T: () => ["pb.v2.AssetLabelType", AssetLabelType, "ASSET_LABEL_TYPE_"] },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<Asset_Label>): Asset_Label {
        const message = { type: 0, key: "", data: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Asset_Label>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Asset_Label): Asset_Label {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.AssetLabelType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string key */ 2:
                    message.key = reader.string();
                    break;
                case /* bytes data */ 3:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Asset_Label, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.AssetLabelType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string key = 2; */
        if (message.key !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.key);
        /* bytes data = 3; */
        if (message.data.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Asset.Label
 */
export const Asset_Label = new Asset_Label$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatMessage$Type extends MessageType<ChatMessage> {
    constructor() {
        super("pb.v2.ChatMessage", [
            { no: 1, name: "urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "created_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "edited_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "deleted_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "position", kind: "message", T: () => Position },
            { no: 6, name: "author_urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "recipient_urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 64, fixedLength: true } } },
            { no: 9, name: "photo_b64", kind: "scalar", oneof: "chatData", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 512, fixedLength: true } } },
            { no: 10, name: "audio_msg", kind: "scalar", oneof: "chatData", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 512, fixedLength: true } } }
        ]);
    }
    create(value?: PartialMessage<ChatMessage>): ChatMessage {
        const message = { urn: 0n, createdMs: 0n, editedMs: 0n, deletedMs: 0n, authorUrn: 0n, recipientUrn: 0n, message: "", chatData: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChatMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChatMessage): ChatMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 urn */ 1:
                    message.urn = reader.uint64().toBigInt();
                    break;
                case /* uint64 created_ms */ 2:
                    message.createdMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 edited_ms */ 3:
                    message.editedMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 deleted_ms */ 4:
                    message.deletedMs = reader.uint64().toBigInt();
                    break;
                case /* pb.v2.Position position */ 5:
                    message.position = Position.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                case /* uint64 author_urn */ 6:
                    message.authorUrn = reader.uint64().toBigInt();
                    break;
                case /* uint64 recipient_urn */ 7:
                    message.recipientUrn = reader.uint64().toBigInt();
                    break;
                case /* string message */ 8:
                    message.message = reader.string();
                    break;
                case /* string photo_b64 */ 9:
                    message.chatData = {
                        oneofKind: "photoB64",
                        photoB64: reader.string()
                    };
                    break;
                case /* string audio_msg */ 10:
                    message.chatData = {
                        oneofKind: "audioMsg",
                        audioMsg: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChatMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 urn = 1; */
        if (message.urn !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.urn);
        /* uint64 created_ms = 2; */
        if (message.createdMs !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.createdMs);
        /* uint64 edited_ms = 3; */
        if (message.editedMs !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.editedMs);
        /* uint64 deleted_ms = 4; */
        if (message.deletedMs !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.deletedMs);
        /* pb.v2.Position position = 5; */
        if (message.position)
            Position.internalBinaryWrite(message.position, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 author_urn = 6; */
        if (message.authorUrn !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.authorUrn);
        /* uint64 recipient_urn = 7; */
        if (message.recipientUrn !== 0n)
            writer.tag(7, WireType.Varint).uint64(message.recipientUrn);
        /* string message = 8; */
        if (message.message !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.message);
        /* string photo_b64 = 9; */
        if (message.chatData.oneofKind === "photoB64")
            writer.tag(9, WireType.LengthDelimited).string(message.chatData.photoB64);
        /* string audio_msg = 10; */
        if (message.chatData.oneofKind === "audioMsg")
            writer.tag(10, WireType.LengthDelimited).string(message.chatData.audioMsg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.ChatMessage
 */
export const ChatMessage = new ChatMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelMessage$Type extends MessageType<ChannelMessage> {
    constructor() {
        super("pb.v2.ChannelMessage", [
            { no: 1, name: "author_urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "recipient_urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 64, fixedLength: true } } },
            { no: 4, name: "firmware_file_urns", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChannelMessage>): ChannelMessage {
        const message = { authorUrn: 0n, recipientUrn: 0n, message: "", firmwareFileUrns: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelMessage): ChannelMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 author_urn */ 1:
                    message.authorUrn = reader.uint64().toBigInt();
                    break;
                case /* uint64 recipient_urn */ 2:
                    message.recipientUrn = reader.uint64().toBigInt();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* string firmware_file_urns */ 4:
                    message.firmwareFileUrns = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 author_urn = 1; */
        if (message.authorUrn !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.authorUrn);
        /* uint64 recipient_urn = 2; */
        if (message.recipientUrn !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.recipientUrn);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* string firmware_file_urns = 4; */
        if (message.firmwareFileUrns !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.firmwareFileUrns);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.ChannelMessage
 */
export const ChannelMessage = new ChannelMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Channel$Type extends MessageType<Channel> {
    constructor() {
        super("pb.v2.Channel", [
            { no: 1, name: "urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "created_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "edited_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "deleted_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "created_by_urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "privacy", kind: "enum", T: () => ["pb.v2.ChannelPrivacy", ChannelPrivacy, "CHANNEL_PRIVACY_"] },
            { no: 9, name: "sub_channel_urn", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Channel>): Channel {
        const message = { urn: 0n, createdMs: 0n, editedMs: 0n, deletedMs: 0n, uuid: 0n, name: "", createdByUrn: 0n, privacy: 0, subChannelUrn: [], password: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Channel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Channel): Channel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 urn */ 1:
                    message.urn = reader.uint64().toBigInt();
                    break;
                case /* uint64 created_ms */ 2:
                    message.createdMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 edited_ms */ 3:
                    message.editedMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 deleted_ms */ 4:
                    message.deletedMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 uuid */ 5:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                case /* string name */ 6:
                    message.name = reader.string();
                    break;
                case /* uint64 created_by_urn */ 7:
                    message.createdByUrn = reader.uint64().toBigInt();
                    break;
                case /* pb.v2.ChannelPrivacy privacy */ 8:
                    message.privacy = reader.int32();
                    break;
                case /* repeated string sub_channel_urn */ 9:
                    message.subChannelUrn.push(reader.string());
                    break;
                case /* string password */ 10:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Channel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 urn = 1; */
        if (message.urn !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.urn);
        /* uint64 created_ms = 2; */
        if (message.createdMs !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.createdMs);
        /* uint64 edited_ms = 3; */
        if (message.editedMs !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.editedMs);
        /* uint64 deleted_ms = 4; */
        if (message.deletedMs !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.deletedMs);
        /* uint64 uuid = 5; */
        if (message.uuid !== 0n)
            writer.tag(5, WireType.Varint).uint64(message.uuid);
        /* string name = 6; */
        if (message.name !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.name);
        /* uint64 created_by_urn = 7; */
        if (message.createdByUrn !== 0n)
            writer.tag(7, WireType.Varint).uint64(message.createdByUrn);
        /* pb.v2.ChannelPrivacy privacy = 8; */
        if (message.privacy !== 0)
            writer.tag(8, WireType.Varint).int32(message.privacy);
        /* repeated string sub_channel_urn = 9; */
        for (let i = 0; i < message.subChannelUrn.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.subChannelUrn[i]);
        /* string password = 10; */
        if (message.password !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Channel
 */
export const Channel = new Channel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tag$Type extends MessageType<Tag> {
    constructor() {
        super("pb.v2.Tag", [
            { no: 1, name: "urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "created_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "edited_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "deleted_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "tag_type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "parent_tag_urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "child_tag_urn", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Tag>): Tag {
        const message = { urn: 0n, createdMs: 0n, editedMs: 0n, deletedMs: 0n, name: "", tagType: 0, parentTagUrn: 0n, childTagUrn: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Tag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tag): Tag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 urn */ 1:
                    message.urn = reader.uint64().toBigInt();
                    break;
                case /* uint64 created_ms */ 2:
                    message.createdMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 edited_ms */ 3:
                    message.editedMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 deleted_ms */ 4:
                    message.deletedMs = reader.uint64().toBigInt();
                    break;
                case /* string name */ 5:
                    message.name = reader.string();
                    break;
                case /* uint32 tag_type */ 6:
                    message.tagType = reader.uint32();
                    break;
                case /* uint64 parent_tag_urn */ 7:
                    message.parentTagUrn = reader.uint64().toBigInt();
                    break;
                case /* repeated string child_tag_urn */ 8:
                    message.childTagUrn.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 urn = 1; */
        if (message.urn !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.urn);
        /* uint64 created_ms = 2; */
        if (message.createdMs !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.createdMs);
        /* uint64 edited_ms = 3; */
        if (message.editedMs !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.editedMs);
        /* uint64 deleted_ms = 4; */
        if (message.deletedMs !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.deletedMs);
        /* string name = 5; */
        if (message.name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.name);
        /* uint32 tag_type = 6; */
        if (message.tagType !== 0)
            writer.tag(6, WireType.Varint).uint32(message.tagType);
        /* uint64 parent_tag_urn = 7; */
        if (message.parentTagUrn !== 0n)
            writer.tag(7, WireType.Varint).uint64(message.parentTagUrn);
        /* repeated string child_tag_urn = 8; */
        for (let i = 0; i < message.childTagUrn.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.childTagUrn[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Tag
 */
export const Tag = new Tag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Workspace$Type extends MessageType<Workspace> {
    constructor() {
        super("pb.v2.Workspace", [
            { no: 1, name: "urn", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "created_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "edited_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "deleted_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Workspace>): Workspace {
        const message = { urn: 0n, createdMs: 0n, editedMs: 0n, deletedMs: 0n, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Workspace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Workspace): Workspace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 urn */ 1:
                    message.urn = reader.uint64().toBigInt();
                    break;
                case /* uint64 created_ms */ 2:
                    message.createdMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 edited_ms */ 3:
                    message.editedMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 deleted_ms */ 4:
                    message.deletedMs = reader.uint64().toBigInt();
                    break;
                case /* string name */ 5:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Workspace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 urn = 1; */
        if (message.urn !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.urn);
        /* uint64 created_ms = 2; */
        if (message.createdMs !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.createdMs);
        /* uint64 edited_ms = 3; */
        if (message.editedMs !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.editedMs);
        /* uint64 deleted_ms = 4; */
        if (message.deletedMs !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.deletedMs);
        /* string name = 5; */
        if (message.name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Workspace
 */
export const Workspace = new Workspace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeoNode$Type extends MessageType<GeoNode> {
    constructor() {
        super("pb.v2.GeoNode", [
            { no: 1, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["pb.v2.GeoNodeType", GeoNodeType, "GEO_NODE_TYPE_"] },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "center_position", kind: "message", T: () => Position },
            { no: 7, name: "geo_json", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "geo_json_serialized", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 12 /*ScalarType.BYTES*/ },
            { no: 9, name: "key_values", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KeyValue }
        ]);
    }
    create(value?: PartialMessage<GeoNode>): GeoNode {
        const message = { uuid: 0n, type: 0, name: "", description: "", tags: [], geoJson: "", geoJsonSerialized: [], keyValues: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeoNode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeoNode): GeoNode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 uuid */ 1:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                case /* pb.v2.GeoNodeType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* repeated string tags */ 5:
                    message.tags.push(reader.string());
                    break;
                case /* pb.v2.Position center_position */ 6:
                    message.centerPosition = Position.internalBinaryRead(reader, reader.uint32(), options, message.centerPosition);
                    break;
                case /* string geo_json */ 7:
                    message.geoJson = reader.string();
                    break;
                case /* repeated bytes geo_json_serialized */ 8:
                    message.geoJsonSerialized.push(reader.bytes());
                    break;
                case /* repeated pb.v2.KeyValue key_values */ 9:
                    message.keyValues.push(KeyValue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeoNode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 uuid = 1; */
        if (message.uuid !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.uuid);
        /* pb.v2.GeoNodeType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* repeated string tags = 5; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.tags[i]);
        /* pb.v2.Position center_position = 6; */
        if (message.centerPosition)
            Position.internalBinaryWrite(message.centerPosition, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string geo_json = 7; */
        if (message.geoJson !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.geoJson);
        /* repeated bytes geo_json_serialized = 8; */
        for (let i = 0; i < message.geoJsonSerialized.length; i++)
            writer.tag(8, WireType.LengthDelimited).bytes(message.geoJsonSerialized[i]);
        /* repeated pb.v2.KeyValue key_values = 9; */
        for (let i = 0; i < message.keyValues.length; i++)
            KeyValue.internalBinaryWrite(message.keyValues[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GeoNode
 */
export const GeoNode = new GeoNode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Webhook$Type extends MessageType<Webhook> {
    constructor() {
        super("pb.v2.Webhook", [
            { no: 1, name: "uuid", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "forwardingUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Webhook>): Webhook {
        const message = { uuid: 0n, name: "", forwardingUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Webhook>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Webhook): Webhook {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 uuid */ 1:
                    message.uuid = reader.uint64().toBigInt();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string forwardingUrl */ 3:
                    message.forwardingUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Webhook, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 uuid = 1; */
        if (message.uuid !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.uuid);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string forwardingUrl = 3; */
        if (message.forwardingUrl !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.forwardingUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.Webhook
 */
export const Webhook = new Webhook$Type();
