import { AllServcies } from '../startup/startup.service';

import { LoggerOptions } from '../logger/logger.service';
import { AllDevicesVMI } from '../../viewmodels/AllDevices.vmi';
import { 	EventLog,
			Telemetry,
			GPSScanRecord,
			VendorData,
			IndustrialData,
			SystemState,
			SensorInformation_Connection,
			LoraConfig,
} from '../../generated_proto/protobuf-ts/pb/v2/data';

import { DataBase, DataBaseInformation, SyncEventType, DataBaseInformation_SyncEvent, ModelType } from '../../generated_proto/protobuf-ts/pb/v2/models';
import { TelemetryViewModelImplemented } from '../../viewmodels/Telemetry.vmi';
import { BehaviorSubject } from 'rxjs';
import { DeviceTypeIds } from '../../viewmodels/Device.vmi';
import { AssetViewModelImplemented } from '../../viewmodels/Asset.vmi';
import { GeoNodeViewModelImplemented } from '../../viewmodels/geo/geonode.vmi';
import { SiteViewModelImplemented } from '../../viewmodels/geo/site.vmi';
import { AppManagementViewModelImplemented } from '../../viewmodels/AppManagement.vmi';

import {
	BluetoothInformation, 
	GAPCommonDataType,
	AppleAdvertisement, AppleAdvertisement_IBeacon,
	AppleAdvertismentType, AppleAdvertisement_AppleUnknown,
	AppleAdvertisement_FindMy, AppleAdvertisement_NearbyInfo,
	AppleAdvertisement_Handoff,
	Eddystone,
	Onboarding,
	OnboardingStatus,
	Position,
	PositonFixType,

} from "../../generated_proto/protobuf-ts/pb/v2/entities";
import { DeviceModel } from "../../generated_proto/protobuf-ts/pb/v2/models";
import { Device } from "../../generated_proto/protobuf-ts/pb/v2/entities";

import { GeolocateService } from "../../services/geolocate/geolocate.service";

import { DeviceViewModelImplemented, } from "../../viewmodels/Device.vmi";

export type FindByKey = 
{
	key:string, 	//"path.to.value"
	scale:number,
	// https://pouchdb.com/api.html#query_index
	// https://docs.couchdb.org/en/stable/api/database/find.html#selector-basics 
	// https://github.com/nolanlawson/pouchdb-find#dbfindrequest--callback
	selector:any,
	range?:{dateStartMillis: number, dateEndMillis: number},
};

export type FindByKeyResponse = {
	[leafKey:string]:{values:any[], epochMs:number[]}
}

export class DataBaseInformationImplemented implements DataBaseInformation {

	db?: DataBase; // the database we are syncing
	syncStatus?: SyncEventType; // Used for local storage, managing when vm is synced up
	lastUpdateMs?: bigint; // last time we synced
	totalDocs?: number; // total number of docs in the db
	syncedDocs?: number; // total number of docs in the db
	docCollection: DataBase[]; // client must implement a getTelemetryCollection() from db.
	errorStatus?: string; // error status if any
	syncEvents: DataBaseInformation_SyncEvent[]; // events that have occured.

	// POUCH DB CONNECTORS
	localDb:any; // local pouch db instance
	remoteDb:any; // remote pouch db instance
	sync?:any; // sync instance (if exists - you can call this. IE: Cancel.)

	public loggerOptions:LoggerOptions = {
		prefix:"DataBaseInformationImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	public checker:string = "";

	constructor(
		services:AllServcies,
		urn:string,
	) {
		this.setServices(services);
		this.db = DataBase.create();
		if(urn.length>0) {
			this.db.urn = urn;
			var uid:string = this.db.urn.split("/")[1];
			if(uid){
				this.db.uid = BigInt(uid);
			}
		}
		this.checker = ""+Date.now()+":"+Math.random()*1000;
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	// These are LOADED into memory (not in DB)
	// This is our current state of these documents so we know
	// to init sync's on document asks if needed.
	public dbIdCache:{[uid:number]:DataBase} = {};

	// This gets keys from the ACTUAL local db
	// So, if partial sync has happened, this will return all keys of what is locally synced
	public getLocalDbIds(): Promise<{[uid:number]:DataBase}> {
		return new Promise( (resolve, reject) => {
			this.services.pouch?.getLoaclDbIds(this).then( (ids) => {
				resolve(ids);
			}).catch( (err) => {
				console.error("Error getting local ids: ", err);
				reject(err);
			});
		});
	}

	public getUnsyncedInRange(starteEpochMs:number, endEpochMs:number): DataBase[] {
		// get docIds of all docs that are not synced
		var uids = Object.keys(this.dbIdCache).sort();
		var needToSync:DataBase[] = [];
		for (let index = 0; index < uids.length; index++) {
			const uid = uids[index];
			var db:DataBase = this.dbIdCache[uid];
			if( Number(db.latestMs) < starteEpochMs){
				break;
			}
			if(db.syncStatus) {
				if(db.syncStatus<=SyncEventType.COMPLETE) {
					needToSync.push(db);
				}
			}
		}
		return needToSync;
	}

	public getStatusInRange(startEpochMs?:number, endEpochMs?:number) : {epochMs:number[], syncedState:number[]}  {
		var epochMs:number[] = [];
		var syncedState:number[] = [];
		var dbIdKeys = Object.keys(this.dbIdCache).sort();

		if(startEpochMs && endEpochMs) {
			console.group("getStatusInRange range:");
			console.log("Start: ",  new Date( startEpochMs ));
			console.log("End:   ",  new Date( endEpochMs ));
			console.log("number of keys : ", dbIdKeys.length);
			console.groupEnd();
		}
		for (let index = 0; index < dbIdKeys.length; index++) {
			const item = dbIdKeys[index];
			const db = this.dbIdCache[item];
			if(startEpochMs && endEpochMs) {
				if(db.latestMs < startEpochMs || db.latestMs > endEpochMs) {
					continue;
				}
			}
			epochMs.push(Number(db.latestMs));
			if(db.syncStatus>=SyncEventType.COMPLETE) {
				syncedState.push(1);
			}
			else {
				syncedState.push(.99);
			}
		}
		return {epochMs:epochMs, syncedState:syncedState};
	}

	public addSyncEvent(event:DataBaseInformation_SyncEvent) {
		if(this.services.logger) this.services.logger.debug(this.loggerOptions,this.db?.uid+":addSyncEvent:"+SyncEventType[event.eventType||0], event.eventData);
		this.lastUpdateMs = BigInt(Date.now());
		if(!this.syncEvents) {
			this.syncEvents = [];
		}
		this.syncEvents.push(event);
	}
}
// Why this abstraction layer? Why not access the database directly?
// BECAUSE THE DATABASE MAY CHANGE - OR WE MAY COMPOSE FROM MANY DATA SOURCES TO GET THE DATA NEEDED.
// This way, we can select on what transport level we want, here, and can keep the same
// interfaces to VMI's to save/get data.

export class DataService {

	public loggerOptions:LoggerOptions = {
		prefix:"DataService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	constructor(
	) {
	}

	public init(services:AllServcies) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(services==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(services);
			}
			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	// Which URL to use based of the current deploy method
	public getBackendUrl(onlyBaseUrl:boolean=false):string {

		if(onlyBaseUrl){
			if(this.services.settings){
				if(this.services.settings.SETTINGS.APP_LOCAL_PRODUCTION_NODE == true){
					// console.log("Using local production node")
					return this.services.settings.SETTINGS.SERVER_LOCAL_URL+":8080";
				}
				else if(this.services.settings.SETTINGS.RUN_MODE != "LOCAL"){
					// console.log("Using production node")
					return this.services.settings.SETTINGS.SERVER_BACKEND_PRODUCTION_URL;
				}
				// console.log("Using local node")
				return this.services.settings.SETTINGS.SERVER_LOCAL_URL;
			}
			return "localhost"
		}

		if(this.services.settings){
			if(this.services.settings.SETTINGS.APP_LOCAL_PRODUCTION_NODE == true){
				// console.log("Using local production node")
				return "http://"+this.services.settings.SETTINGS.SERVER_LOCAL_URL+":8080";
			}
			else if(this.services.settings.SETTINGS.RUN_MODE != "LOCAL"){
				// console.log("Using production node")
				return "https://"+this.services.settings.SETTINGS.SERVER_BACKEND_PRODUCTION_URL;
			}
			// console.log("Using local node")
			return "http://"+this.services.settings.SETTINGS.SERVER_LOCAL_URL;
		}
		return "http://localhost"
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////
	// Generic Key
	////////////////////////////////////////////////////////////////////////////////////////////////////
	public getByKey(key:string) : Promise<any>{
		// return this.storage.get(key);
		if(this.services.pouch){
			return this.services.pouch.app.getByKey(key);
		}
		else {
			return Promise.reject(this.loggerOptions.prefix+":getByKey: Storage not implemented");
		}
	}
	public saveByKey(key:string, data:any) : Promise<boolean>{
		// return this.storage.set(key, data);
		if(this.services.pouch){
			return this.services.pouch.app.saveByKey(key, data);
		}
		else {
			return Promise.reject(this.loggerOptions.prefix+":saveByKey: Storage not implemented");
		}
	}
	public deleteByKey(key:string) : Promise<boolean>{
		// return this.storage.remove(key);
		if(this.services.pouch){
			return this.services.pouch.app.deleteByKey(key);
		}
		else {
			return Promise.reject(this.loggerOptions.prefix+":deleteByKey: Storage not implemented");
		}
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////////////////////////////////////
	// DB Managment and tools
	////////////////////////////////////////////////////////////////////////////////////////////////////
	public static DB_KEY_SPLIT_CHAR = ",";
	private static DB_PREFIX = "dB_";
	public getDbPrefix(appId?:number) : string {
		if(appId){
			return DataService.DB_PREFIX+appId+DataService.DB_KEY_SPLIT_CHAR;
		}
		if(this.services.settings?.SETTINGS.APP_ID){
			return DataService.DB_PREFIX+this.services.settings.SETTINGS.APP_ID+DataService.DB_KEY_SPLIT_CHAR;
		}
		return "";
	}
	public static getKeyPrefix(modelType:ModelType, pbVersion:number):string{
		return modelType+DataService.DB_KEY_SPLIT_CHAR+pbVersion+DataService.DB_KEY_SPLIT_CHAR;
	}

	public getDbii(urn:string): Promise<DataBaseInformationImplemented>{
		if( this.services.pouch ) {
			return Promise.resolve(this.services.pouch.getDbInformationImpelemnted(urn));
		}
		return Promise.reject(this.loggerOptions.prefix+":getDbiid:No PouchDB Service");
	}

	public initSync( urn:string, starteEpochMs?:number, endEpochMs?:number, maximumDocuments?:number,  updater?:BehaviorSubject<number>) : Promise<boolean> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.initSync(urn, starteEpochMs, endEpochMs, maximumDocuments, updater);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveDevice:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getSensorByUuid:No PouchDB Service");
	}

	public sync( urn:string, starteEpochMs?:number, endEpochMs?:number, maximumDocuments?:number,  updater?:BehaviorSubject<number>) : Promise<boolean> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.sync(urn, starteEpochMs, endEpochMs, maximumDocuments, updater);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveDevice:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getSensorByUuid:No PouchDB Service");
	}

	// !! WARNING !! - This is a slow operation, especially on first ask if not previously indexed
	// Sort by key is defaulted to time in db.updatedMs
	public collateByKeys(urn:string, keysFinder:FindByKey[], sortByKey?:string) : Promise<FindByKeyResponse> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.collateByKeys(urn, keysFinder, sortByKey);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"collateByKeys:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":collateByKeys:No PouchDB Service");
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////

	public getDevicesList(app_id:number) : Promise<AllDevicesVMI[]> {
		if(this.services.pouch && this.services.pouch.devices) return this.services.pouch.devices.getDevicesList(app_id);
		return Promise.reject(this.loggerOptions.prefix+":getDevicesList:No PouchDB Service");
	}

	public saveDevice( app_id:number, deviceVMI:AllDevicesVMI ) : Promise<boolean> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.saveDevice(app_id, deviceVMI);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveDevice:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":saveDevice:No PouchDB Service");
	}

	public deleteDevice( app_id:number, deviceVMI:AllDevicesVMI ) : Promise<boolean> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.deleteDevice(app_id, deviceVMI);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"deleteDevice:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":deleteDevice:No PouchDB Service");
	}

	public getDeviceByUuid( app_id:number, uuid:bigint ) : Promise<AllDevicesVMI> {
		return new Promise( async (resolve, reject) => {
			if( this.services.pouch && this.services.pouch.devices ) {
				try{
					var device = await this.services.pouch.devices.getDeviceByUuid(app_id, uuid);
					resolve(device);
				}
				catch(e){
					reject(e);
				}
			}
			else {
				if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveDevice:No PouchDB Service");
				reject(this.loggerOptions.prefix+":getSensorByUuid:No PouchDB Service");
			}
		});
	}

	public getDeviceByUrn( app_id:number, urn:string ) : Promise<AllDevicesVMI> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.getDeviceByUrn(app_id, urn);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveDevice:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getSensorByUuid:No PouchDB Service");
	}

	public getDeviceUUIDsByDeviceType( app_id:number, deviceType:DeviceTypeIds ) : Promise<bigint[]> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.getDeviceUUIDsByDeviceType(app_id, deviceType);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"getDeviceIdsByDeviceType:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getDeviceIdsByDeviceType:No PouchDB Service");
	}
	public getDeviceURNsByDeviceType( app_id:number, deviceType:DeviceTypeIds ) : Promise<string[]> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.getDeviceURNsByDeviceType(app_id, deviceType);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"getDeviceIdsByDeviceType:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getDeviceIdsByDeviceType:No PouchDB Service");
	}

	public getTelemetry(db:DataBase, starteEpochMs?:number, stopEpochMs?:number) : Promise<TelemetryViewModelImplemented[]> {
		return Promise.reject(this.loggerOptions.prefix+":getSensorByUuid:No PouchDB Service");
	}

	public async saveTelemetry(app_id:number, sensorVMI:AllDevicesVMI, telemetry:Telemetry, attached_name?:string, attached_raw?:Uint8Array, reported_by_device_urn?:string, sendToRemote:boolean=false) : Promise<TelemetryViewModelImplemented> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.saveTelemetry(app_id, sensorVMI, telemetry, attached_name, attached_raw, reported_by_device_urn, sendToRemote);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveTelemetry:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":saveTelemetry:No PouchDB Service");
	}

	public saveEventLog(app_id:number, deviceVMI:AllDevicesVMI, eventLog:EventLog) : Promise<boolean> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.saveEventLog(app_id, deviceVMI, eventLog);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveEventLog:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":saveEventLog:No PouchDB Service");
	}

	public getAssets(app_id:number) : Promise<AssetViewModelImplemented[]>{
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.assetManagement.getAssetList(app_id);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"getAssets:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getAssets:No PouchDB Service");
	}

	public saveAsset(app_id:number, assetVMI:AssetViewModelImplemented) : Promise<boolean> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.assetManagement.saveAsset(app_id, assetVMI);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveAsset:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":saveAsset:No PouchDB Service");
	}

	public saveGeoNode(app_id:number, geonodeVMI:GeoNodeViewModelImplemented) : Promise<boolean> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.geoNodePouchdbService.saveGeoNode(app_id, geonodeVMI);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"saveGeoNode:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":saveGeoNode:No PouchDB Service");
	}

	public deleteGeoNode(app_id:number, geonodeVMI:GeoNodeViewModelImplemented) : Promise<boolean> {
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.geoNodePouchdbService.deleteGeoNode(app_id, geonodeVMI);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"deleteGeoNode:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":deleteGeoNode:No PouchDB Service");
	}

	public getSites(app_id:number) : Promise<SiteViewModelImplemented[]>{
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.geoNodePouchdbService.getSiteList(app_id);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"getBuildings:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getBuildings:No PouchDB Service");
	}
	public getSiteByUUID(app_id:number, uuid:bigint) : Promise<SiteViewModelImplemented>{
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.geoNodePouchdbService.getSiteByUUID(app_id, uuid);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"getBuildingByUUID:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getBuildingByUUID:No PouchDB Service");
	}

	public deleteSite(app_id:number, building:SiteViewModelImplemented) : Promise<boolean>{
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.geoNodePouchdbService.deleteBuilding(app_id, building);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"deleteBuilding:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":deleteBuilding:No PouchDB Service");
	}
	public getGeoNodeByURN(app_id:number, urn:string) : Promise<GeoNodeViewModelImplemented>{
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.geoNodePouchdbService.getGeoNodeByUrn(app_id, urn);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"getGeoNodeByURN:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getGeoNodeByURN:No PouchDB Service");
	}
	public getGeoNodeByUUID(app_id:number, uuid:bigint) : Promise<GeoNodeViewModelImplemented>{
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.geoNodePouchdbService.getGeoNodeByUUID(app_id, uuid);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"getGeoNodeByURN:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getGeoNodeByURN:No PouchDB Service");
	}

	public getAppMangement(app_id:number) : Promise<AppManagementViewModelImplemented>{
		if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.app.getAppMangement(app_id);
		if(this.services.logger) this.services.logger.error(this.loggerOptions,"getAppMangement:No PouchDB Service");
		return Promise.reject(this.loggerOptions.prefix+":getAppMangement:No PouchDB Service");
	}
	// public getWebhookList(urn:string, ) : Promise<FindByKeyResponse> {
	// 	if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.getWebhookList(urn, );
	// 	if(this.services.logger) this.services.logger.error(this.loggerOptions,"collateByKeys:No PouchDB Service");
	// 	return Promise.reject(this.loggerOptions.prefix+":collateByKeys:No PouchDB Service");
	// }

	// public saveWebhook(urn:string, ) : Promise<FindByKeyResponse> {
	// 	if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.saveWebhook(urn, );
	// 	if(this.services.logger) this.services.logger.error(this.loggerOptions,"collateByKeys:No PouchDB Service");
	// 	return Promise.reject(this.loggerOptions.prefix+":collateByKeys:No PouchDB Service");
	// }

	// public deleteWebhook(urn:string, ) : Promise<FindByKeyResponse> {
	// 	if( this.services.pouch && this.services.pouch.devices ) return this.services.pouch.devices.deleteWebhook(urn, );
	// 	if(this.services.logger) this.services.logger.error(this.loggerOptions,"collateByKeys:No PouchDB Service");
	// 	return Promise.reject(this.loggerOptions.prefix+":collateByKeys:No PouchDB Service");
	// }

	// data helpers
	public static UuidToMacAddress(uuid:bigint):Uint8Array{
		let macAddress = new Uint8Array(6);
		let uuidString = uuid.toString(16);
		let uuidStringArray = uuidString.split("");
		for(let i = 0; i < 6; i++){
			let hexString = uuidStringArray[i*2] + uuidStringArray[i*2+1];
			macAddress[i] = parseInt(hexString, 16);
		}
		return macAddress;
	}

	public static MacAddressToUuid(macAddress:Uint8Array):bigint{
		let uuidString = "";
		for(let i = 0; i < 6; i++){
			let hexString = macAddress[i].toString(16);
			if(hexString.length < 2){
				hexString = "0" + hexString;
			}
			uuidString += hexString;
		}
		return BigInt("0x" + uuidString);
	}

	public static UuidToMacAddressFormattedString(uuid:bigint):string{
		let macAddress = DataService.UuidToMacAddress(uuid);
		var formatted = DataService.MacAddressToUuidFormattedString(macAddress).replace(/(..?)/g, '$1:').toUpperCase();
		return formatted.substring(0, formatted.length - 1);
	}

	public static MacAddressToUuidFormattedString(macAddress:Uint8Array):string{
		let uuidString = "";
		for(let i = 0; i < 6; i++){
			let hexString = macAddress[i].toString(16);
			if(hexString.length < 2){
				hexString = "0" + hexString;
			}
			uuidString += hexString;
		}
		return uuidString;
	}

	public static ConvertMacStringToUUID(macAddressString:string):bigint{
		let macAddressArray = macAddressString.replace(":", "").replace(" ", "").split("");
		var uuid:bigint = BigInt(0);
		if(macAddressArray.length != 12){
			return uuid;
		}
		var macAddress:Uint8Array = new Uint8Array(6);
		for(let i = 0; i < 6; i++){
			let hexString = macAddressArray[i*2] + macAddressArray[i*2+1];
			macAddress[i] = parseInt(hexString, 16);
		}
		uuid = DataService.MacAddressToUuid(macAddress);
		return uuid;
	}

	buildBlueoothInformation(macAddres:Uint8Array, rssi:number, advPacket?:Uint8Array, rspPacket?:Uint8Array) : BluetoothInformation{
		var bluetoothInformation = BluetoothInformation.create();
		bluetoothInformation.gapAdvertisement = GAPCommonDataType.create();
		// parse and build the bluetooth information
		bluetoothInformation.macAddress = macAddres;
		bluetoothInformation.rssi = rssi;

		var fullAdvertisment:Uint8Array|null = null;
		var combinedPacket:boolean = false;
		if(advPacket && rspPacket && advPacket.length > 0 && rspPacket.length > 0){
			fullAdvertisment = new Uint8Array(advPacket.length + rspPacket.length);
			fullAdvertisment.set(advPacket);
			fullAdvertisment.set(rspPacket, advPacket.length);
			bluetoothInformation.latestAdvPacket = advPacket;
			bluetoothInformation.latestRspPacket = rspPacket;
			combinedPacket = true;
		}
		else if(advPacket && advPacket.length > 0){
			fullAdvertisment = advPacket;
			bluetoothInformation.latestAdvPacket = advPacket;
		}
		else if(rspPacket && rspPacket.length > 0){
			fullAdvertisment = rspPacket;
			bluetoothInformation.latestRspPacket = rspPacket;
		}

		if(fullAdvertisment == null || fullAdvertisment.length == 0){
			return bluetoothInformation;
		}

		// TODO :: PRase and build all the types
		// this.services.logger?.debug(this.loggerOptions,"Parsing through fullAdvertisment: ");
		// for loop of advert
		var i = 0;
		while (i < fullAdvertisment.length){
			const length = fullAdvertisment[i];
			const type = fullAdvertisment[i+1];
			const element = fullAdvertisment.slice(i+2, i+2+length-1);
			// this.services.logger?.debug(this.loggerOptions," :: Length : ", length, " Type : ", type, " Element : ", element);
			switch(type){
				case 0x01: // flags
					bluetoothInformation.gapAdvertisement.flags = Number(element[0]);
					break;
				case 0x02: // 16-bit service uuids
					bluetoothInformation.gapAdvertisement.serviceUuids16 = [];
					break;
				case 0x03: // 16-bit service uuids
					bluetoothInformation.gapAdvertisement.serviceUuids16 = [];
					break;
				// case 0x04: // 32-bit service uuids
				// 	bluetoothInformation.serviceUuids32 = element;
				// 	break;
				// case 0x05: // 32-bit service uuids
				// 	bluetoothInformation.serviceUuids32 = element;
				// 	break;
				// case 0x06: // 128-bit service uuids
				// 	bluetoothInformation.serviceUuids128 = element;
				// 	break;
				case 0x07: // 128-bit service uuids
					bluetoothInformation.gapAdvertisement.serviceUuids128 = [element];
					break;
				case 0x08: // short name
					bluetoothInformation.gapAdvertisement.localNameShort = String.fromCharCode.apply(null, element);
					this.services.logger?.debug(this.loggerOptions," :: Local Name Short : "+combinedPacket+" :: ", bluetoothInformation.gapAdvertisement.localNameShort)
					break;
				case 0x09: // complete name
					bluetoothInformation.gapAdvertisement.localNameComplete = String.fromCharCode.apply(null, element);
					break;
				case 0x0A: // complete name
					bluetoothInformation.gapAdvertisement.txPowerLevel = element[0];
					break;
				case 0x12: // complete name
					// this.services.logger?.debug(this.loggerOptions,"0x12 :: Peripheral Connection Interval Range: ", EncryptionHelper.toHexString(element));
					// bluetoothInformation.gapAdvertisement.peripheralConnectionIntervalRange = new DataView(element).getUint32(0, false);
					break;
				case 0x16: // complete name
					// this.services.logger?.debug(this.loggerOptions,"0x16 :: Service Data ­ 16­bit UUID: ", EncryptionHelper.toHexString(element));
					// bluetoothInformation.gapAdvertisement.peripheralConnectionIntervalRange = this.extractUint16BE(element, 0);
					break;
				case 0xFF: // manufacturer specific data
					bluetoothInformation.gapAdvertisement.manufacturerSpecificData = element;
					// 4c 00 12 02 54 01
					if(element[0] == 0x4c && element[1] == 0x00){
						bluetoothInformation.appleAdvertisement = AppleAdvertisement.create();
						var appleType = element[2];
						if(appleType == AppleAdvertismentType.IBEACON){
							bluetoothInformation.appleAdvertisement.iBeacon = AppleAdvertisement_IBeacon.create();
							bluetoothInformation.appleAdvertisement.iBeacon.iBeaconUUID = element.slice(4, 16);
							bluetoothInformation.appleAdvertisement.iBeacon.iBeaconMajor = (element[20] << 8) | element[21];
							bluetoothInformation.appleAdvertisement.iBeacon.iBeaconMinor = (element[22] << 8) | element[23];
							bluetoothInformation.appleAdvertisement.iBeacon.iBeaconTxPower = element[24];
						}
						else if(appleType == AppleAdvertismentType.FIND_MY){
							// "Offline finding type"
							var findMyLenth = element[3];
							if(findMyLenth == 0x19){
								// Finding
								// 4c 00
								// 12 19
								// 10
								// 7c 22 e3 8f b9 37 af f9 a6 3f a0 08 95 52 8d c7 f3 65 07 e5 ba 63 03 b2
								// this.services.logger?.debug(this.loggerOptions,"\tFindMy: ", EncryptionHelper.toHexString(element));
								bluetoothInformation.appleAdvertisement.findMy = AppleAdvertisement_FindMy.create();
								bluetoothInformation.appleAdvertisement.findMy.findMyStatus = element[4];
							}
							else {
								// finding these smaller ones, not sure what they're about
								// 4c 00
								// 12 02
								// 54 01
								// 4c 00
								// 12 02
								// 54 01
								bluetoothInformation.appleAdvertisement.unknown = AppleAdvertisement_AppleUnknown.create();
								bluetoothInformation.appleAdvertisement.unknown.type = appleType;
								bluetoothInformation.appleAdvertisement.unknown.data = element;
							}
						}
						else if(appleType == AppleAdvertismentType.HANDOFF){
							// this.services.logger?.debug(this.loggerOptions,"\tHandoff: ", EncryptionHelper.toHexString(element));
							// 4c 00 apple
							// 0c handoff
							// 0e length -> 14
							// 08 version
							// 47 ce iv
							// 27 auth
							// 07 77 87 64 1e 2b 1e c6 46 ca 10 06 09 19 e5 4f 12 08 // should be 10 byte payload but is too long
							// 4c 00 0c 0e 08 47 ce 27 07 77 87 64 1e 2b 1e c6 46 ca 10 06 09 19 e5 4f 12 08
							bluetoothInformation.appleAdvertisement.handoff = AppleAdvertisement_Handoff.create();
							bluetoothInformation.appleAdvertisement.handoff.version = element[2+1];
							bluetoothInformation.appleAdvertisement.handoff.iv = (element[2+3] << 8) | element[2+4];
							bluetoothInformation.appleAdvertisement.handoff.auth = element[2+5];
							bluetoothInformation.appleAdvertisement.handoff.data = element.slice(2+6);
						}
						else if(appleType == AppleAdvertismentType.NEARBY_INFO){
							bluetoothInformation.appleAdvertisement.nearbyInfo = AppleAdvertisement_NearbyInfo.create();
							
						}
						else {
							this.services.logger?.debug(this.loggerOptions,"Unknown Apple Type: 0x"+ appleType.toString(16).padStart(2, '0') + " Data: ", this.services.encryption?.toHexString(element.slice(4)));
						}
					}
					else if(element[0] == 0x20) { // parse out eddy stone
						bluetoothInformation.eddystoneAdvertisement = Eddystone.create();
						this.services.logger?.debug(this.loggerOptions,"Parsing out the edyy stone")
						this.services.logger?.debug(this.loggerOptions,"Eddystone: ", this.services.encryption?.toHexString(element));
						// if(element[1] == 0x00){ // unecnrypted eddy stone
							
						// }
					}
					break;
				default:
					// this.services.logger?.debug(this.loggerOptions,"Unknown type: 0x"+type.toString(16).padStart(2, '0'));
					break;
			}
			i += length + 1;
		}

		return bluetoothInformation;
	}

	private generateCalculatedPositionFromRssi(positions:Position[]) : Promise<Position>{
		return new Promise( async (resolve, reject) => {
			if(!positions || positions.length == 0){
				reject(new Error('No positions to calculate average from'));
				return;
			}
			if(positions.length == 1){
				var newPosition = Position.clone(positions[0]);
				newPosition.fixType = PositonFixType.POSITION_FIX_TYPE_BLUETOOTH_GATEWAYS;
				newPosition.epochMs = BigInt(Date.now());
				resolve(positions[0]);
				return;
			}
			let totalWeight = 0;
			let latitudeSum = 0;
			let longitudeSum = 0;
			let locationPrecisionSum = 0;

			for (const position of positions) {
				// Use inverse of locationPrecisionCm as weight, with a minimum value of 1
				const precisionWeight = position.locationPrecisionCm ? 1 / position.locationPrecisionCm : 1;

				// Use RSSI as another weight, set to 1 if not available
				const rssiWeight = position.rssi ? Math.abs(position.rssi) : 1;

				// Combine the two weights
				const combinedWeight = precisionWeight * rssiWeight;

				totalWeight += combinedWeight;

				latitudeSum += position.latitude * combinedWeight;
				longitudeSum += position.longitude * combinedWeight;
				locationPrecisionSum += position.locationPrecisionCm * combinedWeight;
			}

			if (totalWeight === 0) {
				reject(new Error('No positions to calculate average from'));
				return;
			}

			const avgLatitude = latitudeSum / totalWeight;
			const avgLongitude = longitudeSum / totalWeight;
			const avgLocationPrecisionCm = locationPrecisionSum / totalWeight;

			resolve({
				latitude: avgLatitude,
				longitude: avgLongitude,
				elevationCm: 0,
				geohash: BigInt(0),
				plusCode: '',
				locationPrecisionCm: Math.round(avgLocationPrecisionCm),
				fixType: PositonFixType.POSITION_FIX_TYPE_BLUETOOTH_GATEWAYS,
				epochMs: BigInt(Date.now()),
			});
		});
	}

	private async findAndUpdateDeviceFromBluetoothInformation( app_id:number, bluetoothInformation:BluetoothInformation, gatewayVMI:AllDevicesVMI, gatewayTelemetryVMI:TelemetryViewModelImplemented): Promise<boolean>{
		return new Promise( async (resolve,reject) => {
			var updateDevice = async (deviceVMI:AllDevicesVMI, uuid?:bigint) => {
				if(!deviceVMI.model){
					deviceVMI.model = DeviceModel.create();
				}
				if(!deviceVMI.model.device){
					deviceVMI.model.device = Device.create();
				}
				if(!deviceVMI.model.onboarding){
					deviceVMI.model.onboarding = Onboarding.create();
					deviceVMI.model.onboarding.status = OnboardingStatus.CREATED;
					deviceVMI.model.onboarding.userCreated = false;
				}
				if(uuid){
					deviceVMI.model.device.uuid = uuid;
				}
				deviceVMI.model.device!.bluetoothInfo = bluetoothInformation;
				deviceVMI.model.reportedByDeviceUrn = gatewayVMI.generateURN();

				if(bluetoothInformation.gapAdvertisement?.localNameComplete && (bluetoothInformation.gapAdvertisement?.localNameComplete[0] == "P" && bluetoothInformation.gapAdvertisement?.localNameComplete[1] == "L" )){
					if(bluetoothInformation.gapAdvertisement?.localNameComplete.length>2){
						var name = bluetoothInformation.gapAdvertisement?.localNameComplete.substring(2);
						var number = parseInt(name);
						if(number){
							if(bluetoothInformation.appleAdvertisement && bluetoothInformation.appleAdvertisement.iBeacon && bluetoothInformation.appleAdvertisement.iBeacon.iBeaconMajor && bluetoothInformation.appleAdvertisement.iBeacon.iBeaconMajor == number){
								deviceVMI.model.device!.deviceType = DeviceTypeIds.PL_BLUETOOTH_BEACON_V1;
							}
							else {
								var lastFourOfMac = this.services.encryption?.toHexString(bluetoothInformation.macAddress).slice(-4);
								if(lastFourOfMac && bluetoothInformation.gapAdvertisement?.localNameComplete.toLowerCase().includes(lastFourOfMac)){
									deviceVMI.model.device!.deviceType = DeviceTypeIds.PL_BLUETOOTH_BEACON_V2_1;
								}
							}
						}
						// v2 has exactly the "PL" characters in the name
						else if(bluetoothInformation.gapAdvertisement?.localNameComplete == "PL") {
							deviceVMI.model.device!.deviceType = DeviceTypeIds.PL_BLUETOOTH_BEACON_V2;
						}
					}
				}
				if(deviceVMI.model.device!.deviceType == DeviceTypeIds.GENERIC_BLUETOOTH_BEACON || (deviceVMI.model.device!.deviceType >= DeviceTypeIds.PL_BLUETOOTH_BEACON && deviceVMI.model.device!.deviceType <= DeviceTypeIds.PL_BLUETOOTH_BEACON_V2 ) || deviceVMI.model.device!.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V2_1 ){
					// need to determine if we want to save ALL unknown sensors
					if(deviceVMI.model.onboarding.status<=OnboardingStatus.STAGED){
						deviceVMI.model.onboarding.status = OnboardingStatus.STAGED;
					}
					if(!deviceVMI.model.gatewayPositions){
						deviceVMI.model.gatewayPositions = [];
					}
					
					var position:Position|undefined = undefined;
					if(gatewayVMI.model.device){
						// this.services.logger?.debug(this.loggerOptions,"gatewayTelemetry: ", gatewayTelemetry)
						if(gatewayVMI.model.device.manualPosition){
							position = gatewayVMI.model.device.manualPosition;
						}
						else if(gatewayVMI.model.device.reportedPosition){
							position = gatewayVMI.model.device.reportedPosition;
						}
						else if(gatewayVMI.model.device.calculatedPosition){
							position = gatewayVMI.model.device.calculatedPosition;
						}
						if(position){
							position.gatewayTelemetryUrn = gatewayTelemetryVMI.generateURN();
							var telemetryDbUrn = position.gatewayTelemetryUrn.split("/")[0];
							position.rssi = bluetoothInformation.rssi;
							position.epochMs = BigInt(gatewayTelemetryVMI.model?.telemetry?.epochMs || Date.now());
							deviceVMI.model.gatewayPositions = deviceVMI.model.gatewayPositions.filter( (position:Position) => {
								return position.gatewayTelemetryUrn?.split("/")[0] != telemetryDbUrn;
							});
							deviceVMI.model.gatewayPositions.push(position);
						}
					}

					var minutes = 10;
					var thresholdMinutesAgo:bigint = BigInt(Date.now() - (minutes*60*1000))
					this.services.logger?.debug(this.loggerOptions," Saving Gateway Telemetries : ")
					deviceVMI.model.gatewayPositions = deviceVMI.model.gatewayPositions
																.filter( (position:Position) => {
																	var isWithin10 = position.epochMs >= thresholdMinutesAgo;
																	return isWithin10;
																})
																.sort( (a:Position, b:Position) => Number(b.epochMs - a.epochMs))
																.slice(0,15);
					if(this.services.data){
						var calculatedPosition = await this.generateCalculatedPositionFromRssi(deviceVMI.model.gatewayPositions);
						if(calculatedPosition){
							this.services.logger?.debug(this.loggerOptions," :: Calculated Position : ", calculatedPosition)
							deviceVMI.model.device!.calculatedPosition = calculatedPosition;
							var telemetry = Telemetry.create();
							telemetry.uuid = deviceVMI.model.device!.uuid;
							telemetry.epochMs = BigInt(Date.now());
							telemetry.latitude1E7 = Math.round(calculatedPosition.latitude*1e7);
							telemetry.longitude1E7 = Math.round(calculatedPosition.longitude*1e7);
							telemetry.elevationCm = Math.round(calculatedPosition.elevationCm);
							telemetry.gpsScanRecord = GPSScanRecord.create();
							telemetry.gpsScanRecord.fixType = calculatedPosition.fixType;
							// Need to save the device with the new telemetry
							await this.services.data.saveTelemetry(app_id, deviceVMI, telemetry).then( async (telemetryVMI:TelemetryViewModelImplemented) => {

							}).catch( (err) => {
								this.services.logger?.error(this.loggerOptions,"Error saving telemetry to device", err);
							});
							if(deviceVMI.model.device.primaryAssetUrn){
								// this.services.data.saveAsset
							}
						}
						
					}
					else {
						this.services.logger?.warn(this.loggerOptions,"No data service available, not saving telemetry :: Saving device only");
						await deviceVMI.save();
					}
				}
				resolve(true);
			}
			if(bluetoothInformation.macAddress){
				if(bluetoothInformation.macAddress.length != 6){
					resolve(false);
					return;
				}
				var uuid:bigint = DataService.MacAddressToUuid(bluetoothInformation.macAddress);
				if(uuid <= 0){
					this.services.logger?.error(this.loggerOptions,"Invalid UUID for device : "+bluetoothInformation.macAddress);
					return resolve(false);
				}
				this.services.data?.getDeviceByUuid( app_id, uuid ).then( async (deviceVMI:AllDevicesVMI) => {
					await updateDevice(deviceVMI);
				}).catch( async (err)=> {
					if(err.status == 404){
						// Make a new device and save to the database
						var deviceVMI = new DeviceViewModelImplemented(this.services);
						await updateDevice(deviceVMI, uuid);
					}
				});
			}
			else {
				this.services.logger?.verbose(this.loggerOptions,"No mac address found in bluetoothInformation");
				reject("No mac address found in bluetoothInformation");
			}
		})
	}


	saveTelemetryToSensor(app_id:number, deviceVMI:AllDevicesVMI, telemetry:Telemetry) : Promise<boolean> {
		return new Promise( async ( resolve, reject) => {
			if(telemetry.sensorTypeId){
				if(deviceVMI.model.device){
					deviceVMI.model.device.deviceType = telemetry.sensorTypeId;
				}
			}
			if( this.services.data ){

				if(telemetry.vendorData?.data.oneofKind == "rf"){
					if( deviceVMI.model.device && !deviceVMI.model.device?.calculatedPosition &&
						(telemetry.vendorData.data.rf.wifiScanRecord && telemetry.vendorData.data.rf.wifiScanRecord.length > 0 ||
						telemetry.vendorData.data.rf.cellScanRecord && telemetry.vendorData.data.rf.cellScanRecord.length > 0) &&
						telemetry.sensorTypeId != 1
					){
						try{
							var position = await GeolocateService.RfCollectionsToPosition(telemetry.vendorData.data.rf)
							if(position){
								deviceVMI.model.device.calculatedPosition = position;
							}
						}
						catch(e){
							this.services.logger?.error(this.loggerOptions,"Error Calculated Position", e)
						}
					}
				}
				try{
					await this.updateIndustrial(app_id, telemetry, deviceVMI.generateURN());
					if(telemetry.vendorData?.data.oneofKind == "industrial" ){
						// delete after saving
						delete telemetry.vendorData;
					}
				}
				catch(e){
					this.services.logger?.error(this.loggerOptions,"Error Updating Industrial", e)
				}
				
				// Save the Device, and its telemetry.
				this.services.data.saveTelemetry(app_id, deviceVMI, telemetry).then( async (telemetryVMI:TelemetryViewModelImplemented) => {
					// If this is an RF gateway to bluetooth devices, find and update them
					if(telemetry.vendorData?.data.oneofKind == "rf" && telemetry.vendorData?.data?.rf?.bleScanRecord){
						// Find each bluetooth and update in the system (if required)
						for (let i = 0; i < telemetry.vendorData.data.rf.bleScanRecord.length; i++) {
							const bleScanRecord = telemetry.vendorData.data.rf.bleScanRecord[i];
							if(bleScanRecord && bleScanRecord.advPacket){
								bleScanRecord.macAddress = bleScanRecord.macAddress.reverse(); // comeing from the esp in revers order .. potential issues for other things
								var bluetoothInformation = this.buildBlueoothInformation(bleScanRecord.macAddress, bleScanRecord.rssi, bleScanRecord.advPacket, bleScanRecord.rspPacket);
								await this.findAndUpdateDeviceFromBluetoothInformation(app_id, bluetoothInformation, deviceVMI, telemetryVMI);
							}
						}
					}
				}).catch( (err) => {
					this.services.logger?.error(this.loggerOptions,"Error saving telemetry to device", err);
				});
			}
			if(this.services.webhook){
				this.services.webhook.telemetryWebhook(app_id, telemetry).then( (saved) => {
					this.services.logger?.debug(this.loggerOptions," :: Webhooks checked : ", saved);
				}).catch( (err) => {
					this.services.logger?.error(this.loggerOptions,"Error sending telemetry to webhook", err);
				});
			}
			resolve(true);
		});
	}


	/// Specific funcitons

	// Update and build industrial if they exits
	private updateIndustrial(app_id, telemetry:Telemetry, gatewayURN?:string) : Promise<boolean>{
		return new Promise( async (resolve, reject) => {
			try{
				if(telemetry.vendorData?.data.oneofKind == "industrial" ){
					var industrial = telemetry.vendorData?.data.industrial;
					var industrialDeviceTelemetry = Telemetry.create();

					if( telemetry.sensorTypeId == DeviceTypeIds.HUM_GATEWAY ){
						industrialDeviceTelemetry.sensorTypeId = DeviceTypeIds.HUM_SENSOR;
					}
					else  if( telemetry.sensorTypeId == DeviceTypeIds.GENERIC_SIMULATED ){
						industrialDeviceTelemetry.sensorTypeId = DeviceTypeIds.GENERIC_SIMULATED;
					}

					industrialDeviceTelemetry.latitude1E7 = telemetry.latitude1E7;
					industrialDeviceTelemetry.longitude1E7 = telemetry.longitude1E7;
					industrialDeviceTelemetry.gpsScanRecord = telemetry.gpsScanRecord;

					if(industrial.vibration){
						if(industrial.vibration.id){
							industrialDeviceTelemetry.uuid = industrial.vibration.id;
							industrialDeviceTelemetry.vendorData = VendorData.create();
							industrialDeviceTelemetry.vendorData.data.oneofKind = "industrial";
							if(industrialDeviceTelemetry.vendorData?.data.oneofKind == "industrial"){
								industrialDeviceTelemetry.vendorData.data.industrial = IndustrialData.create();
								industrialDeviceTelemetry.vendorData.data.industrial.vibration = industrial.vibration
								industrialDeviceTelemetry.system = SystemState.create();
								if(industrial.vibration.sensorInformation && industrial.vibration.sensorInformation.sensorSoftwareVersion){
									const buffer = new ArrayBuffer(4);
									const dataView = new DataView(buffer);
									dataView.setUint32(0, industrial.vibration.sensorInformation.sensorSoftwareVersion, true);
									industrialDeviceTelemetry.system.currentVersion = new Uint8Array(buffer);
								}
								else {
									industrialDeviceTelemetry.system.currentVersion = new Uint8Array([0,0,0,0]);
								}
								if(industrial.vibration.batteryVoltageCentivolts){
									industrialDeviceTelemetry.system.batteryVoltageMv = industrial.vibration.batteryVoltageCentivolts *10;
								}
								if(industrial.vibration.bearingTemperatureCelsius){
									industrialDeviceTelemetry.system.systemTemperatureC = industrial.vibration.bearingTemperatureCelsius;
								}
								if(industrial.vibration.epochSeconds){
									industrialDeviceTelemetry.system.uptimeTicks = Number(industrial.vibration.epochSeconds);
								}
								if(industrial.vibration.sensorInformation?.connection == SensorInformation_Connection.LORA){
									industrialDeviceTelemetry.system.currentLoraConfig = LoraConfig.create();
									industrialDeviceTelemetry.system.currentLoraConfig.rssi = industrial.vibration.sensorInformation.sensorRssiDbm;
								}
							}
							await this.saveIndustrial(app_id, Object.assign({}, industrialDeviceTelemetry), gatewayURN)
						}
					}
					if(industrial.valve){
						if(industrial.valve.id){
							industrialDeviceTelemetry.uuid = industrial.valve.id;
							industrialDeviceTelemetry.vendorData = VendorData.create();
							industrialDeviceTelemetry.vendorData.data.oneofKind = "industrial";
							if(industrialDeviceTelemetry.vendorData?.data.oneofKind == "industrial"){
								industrialDeviceTelemetry.vendorData.data.industrial = IndustrialData.create();
								industrialDeviceTelemetry.vendorData.data.industrial.valve = industrial.valve;
								industrialDeviceTelemetry.system = SystemState.create();
								if(industrial.valve.sensorInformation && industrial.valve.sensorInformation.sensorSoftwareVersion){
									const buffer = new ArrayBuffer(4);
									const dataView = new DataView(buffer);
									dataView.setUint32(0, industrial.valve.sensorInformation.sensorSoftwareVersion, true);
									industrialDeviceTelemetry.system.currentVersion = new Uint8Array(buffer);
								}
								else {
									industrialDeviceTelemetry.system.currentVersion = new Uint8Array([0,0,0,0]);
								}
								if(industrial.valve.batteryVoltageCentivolts){
									industrialDeviceTelemetry.system.batteryVoltageMv = industrial.valve.batteryVoltageCentivolts *10;
								}
								if(industrial.valve.epochSeconds){
									industrialDeviceTelemetry.system.uptimeTicks = Number(industrial.valve.epochSeconds);
								}
								if(industrial.valve.sensorInformation?.connection == SensorInformation_Connection.LORA){
									industrialDeviceTelemetry.system.currentLoraConfig = LoraConfig.create();
									industrialDeviceTelemetry.system.currentLoraConfig.rssi = industrial.valve.sensorInformation.sensorRssiDbm;
								}
							}
							await this.saveIndustrial(app_id, Object.assign({}, industrialDeviceTelemetry), gatewayURN)
						}
					}
					if(industrial.strain){
						if(industrial.strain.id){
							industrialDeviceTelemetry.uuid = industrial.strain.id;
							industrialDeviceTelemetry.vendorData = VendorData.create();
							industrialDeviceTelemetry.vendorData.data.oneofKind = "industrial";
							if(industrialDeviceTelemetry.vendorData?.data.oneofKind == "industrial"){
								industrialDeviceTelemetry.vendorData.data.industrial = IndustrialData.create();
								industrialDeviceTelemetry.vendorData.data.industrial.strain = industrial.strain;
								industrialDeviceTelemetry.system = SystemState.create();
								if(industrial.strain.sensorInformation && industrial.strain.sensorInformation.sensorSoftwareVersion){
									const buffer = new ArrayBuffer(4);
									const dataView = new DataView(buffer);
									dataView.setUint32(0, industrial.strain.sensorInformation.sensorSoftwareVersion, true);
									industrialDeviceTelemetry.system.currentVersion = new Uint8Array(buffer);
								}
								else {
									industrialDeviceTelemetry.system.currentVersion = new Uint8Array([0,0,0,0]);
								}
								if(industrial.strain.batteryVoltageCentivolts){
									industrialDeviceTelemetry.system.batteryVoltageMv = industrial.strain.batteryVoltageCentivolts *10;
								}
								if(industrial.strain.epochSeconds){
									industrialDeviceTelemetry.system.uptimeTicks = Number(industrial.strain.epochSeconds);
								}
								if(industrial.strain.sensorInformation?.connection == SensorInformation_Connection.LORA){
									industrialDeviceTelemetry.system.currentLoraConfig = LoraConfig.create();
									industrialDeviceTelemetry.system.currentLoraConfig.rssi = industrial.strain.sensorInformation.sensorRssiDbm;
								}
							}
							await this.saveIndustrial(app_id, Object.assign({}, industrialDeviceTelemetry), gatewayURN)
						}
					}
				}
				resolve(true);
			}
			catch(e) {
				reject(e);
			}
		})
	}

	private saveIndustrial(app_id, telemetry:Telemetry, gatewayURN?:string) : Promise<any>{
		if(!this.services.data)return Promise.reject("data service not available");
		if(!telemetry.uuid || telemetry.uuid == 0n){
			return Promise.reject("No UUID");
		}
		return this.services.data?.getDeviceByUuid( app_id, telemetry.uuid ).then( async (deviceVMI:AllDevicesVMI) => {
			this.services.data?.saveTelemetry(app_id, deviceVMI, telemetry, undefined,undefined,gatewayURN).then( (saved) => {}).catch( (err) => {
				this.services.logger?.error(this.loggerOptions,"Error saving telemetry to device", err);
			});
		}).catch( async (err)=> {
			if(err.status == 404){
				// Make a new device and save to the database
				var deviceVMI = new DeviceViewModelImplemented(this.services);
				deviceVMI.updateFromTelemetry(telemetry);
				this.services.logger?.verbose(this.loggerOptions,"Saving new device : with app id : "+app_id);
				deviceVMI.save(app_id).then( async (saved) => {
					if(saved){
						this.services.logger?.verbose(this.loggerOptions,"New Sensor Saved, adding telemetry");
						this.services.data?.saveTelemetry(app_id, deviceVMI, telemetry,undefined,undefined,gatewayURN).then( (saved) => {}).catch( (err) => {
							this.services.logger?.error(this.loggerOptions,"Error saving telemetry to device", err);
						});
					}
				}).catch( (err) => {
					this.services.logger?.error(this.loggerOptions,"saveIndustrial :: Error saving device", err);
				});
			}
		});
	}



}
