
import { Person } from "../generated_proto/protobuf-ts/pb/v2/entities";
import { PersonViewModel, PersonModel } from "../generated_proto/protobuf-ts/pb/v2/models";
import { AllServcies } from "../services/startup/startup.service"

import { ViewModelImplemented } from "./ViewModelImplemented";

export class PersonViewModelImplemented extends ViewModelImplemented implements PersonViewModel {

	model:PersonModel = PersonModel.create();
	isOnline: boolean = false;

	public photoUrl:string = "";

	private log_prefix:string = " :: PersonViewModelImplemented : ";

	public services:AllServcies;
	constructor(
		services?:AllServcies,
	) {
		super();
		if(services){
			this.services = services;
		}
	}

	public setServices(services:AllServcies){
		if(services){
			this.services = services;
		}
	}

	public setPerson(person: Person ) {
		this.model.person = person;
		if(this.model.person.profilePictureUrn){
			if(this.services.data){
				var url = this.services.data.getBackendUrl()+"/";
				this.photoUrl = url+this.model.person.profilePictureUrn+"/attachment";
			}
		}
	}

	// This is what will update our remote copy to the db
	// IE: Pulling in from outside source (user input, bluetooth, whatever)
	// And passing it to remote, if it fails, handle the failre, if successful,
	// calls updatVMI to update the VMI (this class, what is presented to the user)
	// IE:: Update the users VMI 
	public async update() {
		
		// Send our update to the remote
	}

	refresh() {
		// Update our local UI
		// This is where you can handle any UI specific bindings from the model's data 
	}

	stream() {
		// console.log(this.log_prefix, "Calling Stream");
		// this.services.grpc.streamPerson(this);
	}

	// public getPreviousChannels() : Promise<Channel[]>{
	// 	return new Promise( (resolve, reject) => {
	// 		resolve(this.getUserProfile().getChannelsList());
	// 	})
	// }

	// public addActiveChannel( newChannel:Channel) : Promise<boolean> {
	// 	return new Promise( async (resolve, reject) => {
	// 		// Add if not found in list
	// 		if(!this.userProfileVMI.getChannelsList().find((findChannel:Channel) => findChannel.getName() == newChannel.getName())){
	// 			this.userProfileVMI.addChannels(newChannel);
	// 			if(this.services.pouch) this.services.pouch.updateUserVMI(this.userProfileVMI).then( (saved) => {
	// 				(saved) ? resolve(true) : resolve(false);
	// 				this.update.next(true);
	// 			}).catch( (e) => {reject(e)});
	// 		}
	// 		else { // Dont need to push save ... already in list
	// 			resolve(true);
	// 		}
	// 	})
	// }

}