import { Component, OnInit } from '@angular/core';

import { AllServcies } from '../../services/startup/startup.service';
import { FullstackComponent } from '../fullstack-component/fullstack.component';

@Component({
	selector: 'app-channel',
	templateUrl: './channel.component.html',
	styleUrls: ['./channel.component.css'],
})
export class ChannelComponent implements FullstackComponent {

	public allServices: AllServcies;
	public showSomething = "nothing";

	constructor() { }

	// To make this more clear, these example datas shold be whatever this data the component needs.
	// For example, you shold get the data from teh page.ts, and pass that data to this component.
	// In this way, we dont have the COMPONENT layerd and pulling in new data via these services. 
	// Instead, we have the PAGE layer pulling in the data, and passing it to the COMPONENT layer.
	// This makes it so we dont have many copies of data or hidden data underneath the compoments that are difficult
	// to debug or to find out where the data is coming from.

	public init(services:AllServcies, example:any): Promise<any> {
		return new Promise((resolve, reject) => {
			// do stuff to init your thing
			this.allServices = services;
			resolve(example);
		});
	}

	public test(): Promise<any> {
		return new Promise((resolve, reject) => {

			// refer to the steps in the builder we talked about
			// 1. create a channel
			this.showSomething = "creating channel";
		
			resolve("Test is done? Show test results?");
		});
	}

}
