import { Injectable, ElementRef, } from '@angular/core';
// import { AllServcies, } from '../startup/startup.service';
import { AllDevicesVMI } from '../../viewmodels/AllDevices.vmi';

import { ModalController, } from "@ionic/angular";
import { MapTilelayerModalComponent, } from "./map-tilelayer-modal/map-tilelayer-modal.component";
import { MapSettingsModalComponent, } from "./map-settings-modal/map-settings-modal.component";

import { Deck, MapView, MapController, Position2D, FlyToInterpolator, WebMercatorViewport, } from "@deck.gl/core";
import { BitmapLayer, PathLayer, IconLayer, GeoJsonLayer, TextLayer, LineLayer, ScatterplotLayer, RGBAColor } from "@deck.gl/layers";
import { TileLayer, TripsLayer, } from "@deck.gl/geo-layers";

import * as GeoJSON from 'geojson';
import { AllServcies } from '../../services/startup/startup.service';
import { AllGeoNodeImplemented } from 'src/app/viewmodels/geo/geonode.vmi';
import { EditableGeoJsonLayer } from '@nebula.gl/layers';


export type LayerOptions = {
	name?: string,
	selected?: boolean,
	devices?: AllDevicesVMI[],
	telemetryMapDataPoints?: MapDataPoint[][],
	geoNodes?: AllGeoNodeImplemented[],
	startMs?: number,
	endMs?: number,
	length?: number,
	layer?: any, // this is the layer that will be put in deck.gl's layers (so, icon layer, whatever)
}

// https://deck.gl/docs/api-reference/core/deck#gettooltip
export interface MapTooltip {
	html?: string, // innerHTML
	className?: string, // CSS Class (uses class deck-tooltip in theme-app<id> by default )
	style?: string, // direct CSS
}

// One data point, that holds what we need to make any map type
export type MapDataPoint = {
	coordinates?: number[]; // in form [lng, lat]
	elevation?: number,
	timestamp?: number,
	color?: Uint8Array;
	from?: any; 	// Line point start
	to?: any; 	// Line point end
	icon?: string;
	deviceVMI?: AllDevicesVMI;
	getTooltip?: (d) => MapTooltip;
	getClick?: (d) => void;
}

export enum TileLayerTypes { // NOTE: If you set the values directly in an enum, you can then get them array-ified by Object.keys(TileLayerTypes);
	ALL = "ALL",
	TOPO = "TOPO",
	SAT = "SAT",
	STREET = "STREET",
	OTHER = "OTHER",
}
export type TileLayerOptions = {
	name: string,
	links: string[],
	type: TileLayerTypes,
	image?: string,
}

export type MapSettings = {
	followLatest?: boolean,
	followAll?: boolean,
	zoomAll?: boolean,
	showFAALayer?: boolean,
	showTracks?: boolean,
	startMs?: number,
	endMs?: number,
}
export const DEFAULT_MAP_SETTINGS: MapSettings = {
	followLatest: false,
	followAll: true,
	zoomAll: false,
	showFAALayer: false,
	showTracks: false,
}

@Injectable({
	providedIn: 'root'
})
export class MapService {

	private DEFAULT_MIN_ZOOM: number = 17;
	private DEFAULT_MAX_ZOOM: number = 17;

	constructor(
		private modalController: ModalController,
	) {
	}

	init(allServices: AllServcies): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			if (allServices == null) {
				reject({ code: 0, message: "Services Not Given" });
			}
			else {
				this.setServices(allServices);

				// Compute these links only once
				this.tileLayerOptions = this.tileLayerOptions.map((titleLayer: TileLayerOptions) => {
					titleLayer.image = this.layerLinkToImgURL(titleLayer.links[0]);
					return titleLayer;
				})
				this.selectedTileLayer = this.tileLayerOptions[17]
				resolve(true);
			}
		});
	}

	// I've found we have issues when navigating with nav push/pops that the page will re-instance itself on 
	// divs that already exist. so you get a "double map" effect with a new on top of old. this will help eliminate that.
	// Also making sure we call destroy on decks we don't need anymore. before we were actually having memory leaks in Angular.
	private services; //:AllServcies ;
	public setServices(services) {
		this.services = services
	}

	public getTileLayerOptions(type?: TileLayerTypes): TileLayerOptions[] {
		if (type == null || type == TileLayerTypes.ALL) {
			return this.tileLayerOptions;
		}
		return this.tileLayerOptions.filter((search: TileLayerOptions) => search.type == type)
	}

	private layerLinkToImgURL(layerLink: string): string {
		var x = 657; var y = 1577; var z = 12
		layerLink = layerLink.replace("{x}", "" + x);
		layerLink = layerLink.replace("{y}", "" + y);
		layerLink = layerLink.replace("{z}", "" + z);
		return layerLink
	}


	private tileLayerOptions: TileLayerOptions[] = [
		// See here for google api info:  https://stackoverflow.com/questions/23017766/google-maps-tile-url-for-hybrid-maptype-tiles
		// h = roads only
		// m = standard road map
		// p = terrain
		// r = somehow altered road map
		// s = satellite only
		// t = terrain only
		// y = hybrid
		{
			name: "google topology",
			links: ["https://mt.google.com/vt/lyrs=p&l=en&x={x}&y={y}&z={z}"],
			type: TileLayerTypes.TOPO,
		},
		{
			name: "mapbox outdoors topology",
			links: ["https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamhlbWJkIiwiYSI6ImNqcHpueHpyZjBlMjAzeG9kNG9oNzI2NTYifQ.K7fqhk2Z2YZ8NIV94M-5nA"],
			type: TileLayerTypes.TOPO,
		},
		{
			name: "Thunderforest Outdoors",
			links: ["https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.TOPO,
		},
		{
			name: "google satellite",
			links: ["https://mt.google.com/vt/lyrs=y,h&l=en&x={x}&y={y}&z={z}"],
			type: TileLayerTypes.SAT,
		},
		{
			name: "mapbox satellite",
			links: ["https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamhlbWJkIiwiYSI6ImNqcHpueHpyZjBlMjAzeG9kNG9oNzI2NTYifQ.K7fqhk2Z2YZ8NIV94M-5nA"],
			type: TileLayerTypes.SAT,
		},
		{
			name: "google standard",
			links: ["https://mt.google.com/vt/lyrs=m&l=en&x={x}&y={y}&z={z}"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "google traffic",
			links: ["https://mt.google.com/vt/lyrs=s@221097413,traffic&l=en&x={x}&y={y}&z={z}"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Thunderforest OpenCycle Topology",
			links: ["https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.TOPO,
		},
		{
			name: "Thunderforest Transport",
			links: ["https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Thunderforest Landscape",
			links: ["https://tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.TOPO,
		},
		{
			name: "Dark",
			links: ["https://tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Spinal Map",
			links: ["https://tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.OTHER,
		},
		{
			name: "Pioneer",
			links: ["https://tile.thunderforest.com/pioneer/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.OTHER,
		},
		{
			name: "Mobile Atlas",
			links: ["https://tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Neighborhood",
			links: ["https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Atlas",
			links: ["https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=0f849b5644cc400c979bbc37be08c8c9"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Mapbox Street",
			links: ["https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamhlbWJkIiwiYSI6ImNqcHpueHpyZjBlMjAzeG9kNG9oNzI2NTYifQ.K7fqhk2Z2YZ8NIV94M-5nA"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Mapbox Light",
			links: ["https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamhlbWJkIiwiYSI6ImNqcHpueHpyZjBlMjAzeG9kNG9oNzI2NTYifQ.K7fqhk2Z2YZ8NIV94M-5nA"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Mapbox Dark",
			links: ["https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamhlbWJkIiwiYSI6ImNqcHpueHpyZjBlMjAzeG9kNG9oNzI2NTYifQ.K7fqhk2Z2YZ8NIV94M-5nA"],
			type: TileLayerTypes.STREET,
		},
		{
			name: "Open Topomap (slow)",
			links: ["https://b.tile.opentopomap.org/{z}/{x}/{y}.png"],
			type: TileLayerTypes.TOPO,
		},
		{
			name: "3D Terrain",
			links: ["https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoiamhlbWJkIiwiYSI6ImNqcHpueHpyZjBlMjAzeG9kNG9oNzI2NTYifQ.K7fqhk2Z2YZ8NIV94M-5nA"],
			type: TileLayerTypes.TOPO,
		},
	];

	private selectedTileLayer: TileLayerOptions = this.tileLayerOptions[0];
	public getSelectedTileLayerOptions(): TileLayerOptions {
		return this.selectedTileLayer;
	}

	private currentTileLayer: TileLayer = null;
	// Show Modal - Allow user to select the vaious layer types
	async selectTileLayer(): Promise<boolean> {
		this.currentTileLayer = null;
		return new Promise(async (resolve, reject) => {
			const mapTilelayerModalComponent = await this.modalController.create({
				component: MapTilelayerModalComponent,
				cssClass: "full-modal-cover-narrow",
			});
			mapTilelayerModalComponent.onDidDismiss().then((rawData: any) => {
				if (rawData['data']) {
					this.selectedTileLayer = rawData['data'] as TileLayerOptions;
				}
				resolve(true);
			});
			await mapTilelayerModalComponent.present();
		})
	}

	async changeSettings(currentSettings: MapSettings, showTimeSelector: boolean = true): Promise<MapSettings> {
		return new Promise(async (resolve, reject) => {
			const mapSettingsModalComponent = await this.modalController.create({
				component: MapSettingsModalComponent,
				cssClass: "small-modal-cover",
				backdropDismiss: false,
				componentProps: {
					settings: currentSettings,
					showTimeSelector: showTimeSelector
				},
			});
			mapSettingsModalComponent.onWillDismiss().then((rawData: any) => {
				console.log(rawData);
			});
			mapSettingsModalComponent.onDidDismiss().then((rawData: any) => {
				console.log(rawData);
				if (rawData['data']) {
					var settings: MapSettings = rawData['data'] as MapSettings;
					resolve(settings)
				}
				else {
					reject("could not get map settings");
				}
			});
			await mapSettingsModalComponent.present();
		})
	}

	// Builds Tile Layer - Uses the currently selected tile layer.
	private getTileLayer(): TileLayer {

		// If there is already a tile layer, return whats already chosen
		if (this.currentTileLayer != null) { return this.currentTileLayer }

		// otherwise, rebuild with selectedTileLayer given from selectTileLayer() (or just the default);
		const showBorder = false, onTilesLoad = null;
		const devicePixelRatio = (typeof window !== "undefined" && window.devicePixelRatio) || 1;
		let maxZoom = (this.selectedTileLayer.name == "Open Topomap (slow)") || (this.selectedTileLayer.name == "google topology" ? 17 : 19);

		return new TileLayer({
			data: this.selectedTileLayer.links,
			// Since these OSM tiles support HTTP/2, we can make many concurrent requests
			// and we aren't limited by the browser to a certain number per domain.
			maxRequests: 20,
			onViewportLoad: onTilesLoad,
			autoHighlight: showBorder,
			highlightColor: [60, 60, 60, 40],
			// https://wiki.openstreetmap.org/wiki/Zoom_levels
			minZoom: 0,
			maxZoom: maxZoom,
			tileSize: 512 / devicePixelRatio,
			renderSubLayers: (props) => {
				const { bbox: { west, south, east, north }, } = props.tile;
				let bl = new BitmapLayer({
					...props,
					data: null,
					image: props.data,
					bounds: [west, south, east, north],
				});
				let pl = new PathLayer({
					id: `${props.id}-border`,
					data: [
						[
							[west, north],
							[west, south],
							[east, south],
							[east, north],
							[west, north],
						],
					],
					getColor: [255, 0, 0],
					widthMinPixels: 4,
				});
				if (showBorder) return [bl, pl];
				return [bl];
			},
		});
	}

	public buildMap(canvas_id: string, mapCanvas: ElementRef<HTMLCanvasElement>, layers: any[], mapClick?: (mapPoint: MapDataPoint) => void, mapGetTooltip?: (mapPoint: MapDataPoint) => void, onViewStateChange?: (change: any) => void): Promise<Deck> {
		return new Promise((resolve, reject) => {
			const INITIAL_VIEW_STATE = {
				longitude: -100.789,
				latitude: 41.874,
				zoom: 3,
			};


			// Hack. Put on the function stack to allow render to cycle. Allows us to grab id with document.getElementById()
			// Why? Allows for multiple maps to be on a single page w/o hard coded id at compile time. We can generate multiple maps via components.
			// IE: Also solves other render issues. hidden divs, modals, tab switching, push/pop on nav router, etc. I've seen ALL those issues
			// with deckgl, leaflet and mapbox
			setTimeout(() => {
				let deck: Deck = null;
				try {
					deck = new Deck({
						canvas: mapCanvas.nativeElement,
						width: "100%",
						height: "100%",
						layers: layers,
						effects: [],
						initialViewState: INITIAL_VIEW_STATE,
						controller: {
							doubleClickZoom: false,
						},
						pickingRadius: 5, // Allows for a "buffer" around the icon pixels (ie: internal of circles)
						views: [
							new MapView({
								repeat: true,
								// controller: new MapController({ touchZoom: true, touchRotate: true }),
							}),
						],
						onViewStateChange: (change) => {
							if (onViewStateChange) return onViewStateChange(change);
							return null;
						},
						onClick: (d) => {
							if (mapClick) return mapClick(d.object as MapDataPoint);
							if (d.object) if (d.object.getClick) return d.object.getClick();
							return null;
						},
						getTooltip: (d) => {
							if (mapGetTooltip) return mapGetTooltip(d.object as MapDataPoint);
							if (d.object) if (d.object.getTooltip) return d.object.getTooltip();
							return null;
						},

					});
					resolve(deck)
				} catch (err) {
					console.error(err);
					reject(err)
				}
			}, 1000)
		})
	}

	public updateMap(deck: Deck, layers: any[]) {
		if (deck == null) return;


		layers.unshift(this.getTileLayer()) // Add the current chosen Tile Layer
		deck.setProps({
			layers: layers,
		});
	}

	public buildIconLayer(id: string, mapPoints: MapDataPoint[], opacity: number = 1): IconLayer<MapDataPoint> {
		var defaultIcon = "assets/app/map/full-fill.png";
		mapPoints = mapPoints.filter((d) => d != null);
		return new IconLayer({
			id: id,
			data: mapPoints,
			pickable: true,
			opacity: 100,
			getIcon: (d) => {
				var url = ""
				if (!d) {
					url = "";
				}
				else {
					(d.icon == null || d.icon == "") ? url = defaultIcon : url = d.icon;
				}
				return {
					url: url,
					x: 0,
					y: 0,
					width: 124,
					height: 184,
					mask: true,
				}
			},
			sizeScale: 7,
			getPosition: (d) => <Position2D>d.coordinates,
			getSize: (d) => 15,
			getColor: (d) => <RGBAColor>d.color,
			getPixelOffset: (d) => [0, -25],
		});
	}

	public getCenterTargetIconLayer(id: string, mapPoint: MapDataPoint): IconLayer<MapDataPoint> {
		var defaultIcon = "assets/app/map/center_target.png";
		return new IconLayer({
			id: id,
			data: [mapPoint],
			pickable: true,
			getIcon: (d) => {
				return {
					url: defaultIcon,
					x: 0,
					y: -(512 / 4) / 2,
					width: 512 / 4,
					height: 512 / 4,
					mask: true,
				}
			},
			sizeScale: 6,
			getPosition: (d) => <Position2D>d.coordinates,
			getSize: (d) => 15,
			getColor: (d) => <RGBAColor>d.color,
		});
	}

	public geoJsonData: any = null
	public getFaaLayer(): Promise<LayerOptions[]> {
		return new Promise((resolve, reject) => {
			if (this.geoJsonData == null) {
				// this.services.http.get<GeoJSON.FeatureCollection>("assets/app/map/data/FAA_Data_V2_Primary.geojson").subscribe(
				// 	(resp) => {
				// 		this.geoJsonData = resp;
				// 		var faaLayers:LayerOptions[]=[];
				// 		var faa_text = this.buildGeoJSONNames("faa-text", this.geoJsonData);
				// 		faaLayers.push({
				// 			name:"faa-text",
				// 			layer:faa_text,
				// 		});
				// 		var faa_geo = this.buildGeoJSON("faa-geo", this.geoJsonData)
				// 		faaLayers.push({
				// 			name:"faa-geo",
				// 			layer:faa_geo,
				// 		});
				// 		resolve(faaLayers);
				// 	},
				// 	(err) => {
				// 		console.log(err);
				// 		reject(err);
				// 	}
				// );
			}
			else {
				var faaLayers: LayerOptions[] = [];
				var faa_text = this.buildGeoJSONNames("faa-text", this.geoJsonData);
				faaLayers.push({
					name: "faa-text",
					layer: faa_text,
				});
				var faa_geo = this.buildGeoJSON("faa-geo", this.geoJsonData)
				faaLayers.push({
					name: "faa-geo",
					layer: faa_geo,
				});
				resolve(faaLayers);
			}
		})
	}

	public buildGeoJSON(mapLayerId: string, data: GeoJSON.FeatureCollection): GeoJsonLayer<any> {
		if (data.features.length > 0 && data.features[0].geometry.type === 'Polygon') {
			return new GeoJsonLayer({
				id: mapLayerId,
				data: data.features,
				opacity: 0.8,
				stroked: false,
				filled: true,
				extruded: true,
				wireframe: true,
				// getElevation: (f) => f.properties.CEILING,
				// getFillColor: (f) => COLOR_SCALE(f.properties.growth),
				getFillColor: [255, 70, 30, 50],
				getLineColor: [255, 0, 0],
				pickable: false,
			});
		}
		return new GeoJsonLayer({
			id: mapLayerId,
			data: data.features,
			filled: true,
			pointRadiusMinPixels: 5,
			pointRadiusMaxPixels: 200,
			opacity: 0.4,
			pointRadiusScale: 0.6,
			getRadius: 100,
			getFillColor: [255, 70, 30, 180],
			autoHighlight: true,
			visible: true,
		});
	}

	public buildGeoJSONNames(layerId: string, data: GeoJSON.FeatureCollection): TextLayer<any> {
		if (data.features.length > 0) {
			var textData: any = [];
			data.features.forEach((data) => {
				if (data.properties) {
					var lnglat: number[] = [data.properties.LONGITUDE, data.properties.LATITUDE, 1100];
					var dataPoint: any =
					{
						name: data.properties.CEILING.toString(),
						coordinates: lnglat
					}
					textData.push(dataPoint);
				}
			});
			return new TextLayer({
				id: layerId,
				data: textData,
				pickable: false,
				getPosition: d => d.coordinates,
				getText: d => d.name,
				getColor: [255, 255, 255],
				getSize: 32,
				getAngle: 0,
				getTextAnchor: 'middle',
				getAlignmentBaseline: 'center'
			});
		}
	}

	public buildTripLayer(id: string, mapPoints: MapDataPoint[]) {
		var input = [
			{
				waypoints: mapPoints
			}
		]
		var trailLength: number = (mapPoints[0].timestamp || Date.now()) - (mapPoints[mapPoints.length - 1].timestamp || 0);
		return new TripsLayer({
			id: id,
			data: input,
			getPath: d => d.waypoints.map(p => p.coordinates),
			// deduct start timestamp from each data point to avoid overflow
			getTimestamps: d => d.waypoints.map(p => p.timestamp),
			getColor: [253, 128, 93],
			opacity: 0.8,
			widthMinPixels: 15,
			rounded: true,
			trailLength: trailLength,
			fadeTrail: false,
			currentTime: mapPoints[0].timestamp
		});
	}

	public buildLineLayer(id: string, mapPoints: MapDataPoint[]): LineLayer<MapDataPoint> {
		for (let index = 1; index < mapPoints.length; index++) {
			mapPoints[index].from = mapPoints[index - 1].coordinates
			mapPoints[index].to = mapPoints[index].coordinates
		}
		mapPoints = mapPoints.filter((d) => d != null);
		console.log(" :: buildLineLayer :: mapPoints", mapPoints);
		return new LineLayer({
			id: id,
			data: mapPoints,
			pickable: true,
			getWidth: 5,
			getSourcePosition: d => d.from,
			getTargetPosition: d => d.to,
			getColor: d => {
				var r = d.color[0];
				var g = d.color[1];
				var b = d.color[2];
				var newr = r - (r * .4);
				var newg = g - (g * .4);
				var newb = b - (b * .4);
				return [newr, newg, newb, d.color[3]];
			},
			opacity: 0.1,
		});
	}

	public buildCircleLayer(id: string, mapPoints: MapDataPoint[]): ScatterplotLayer<MapDataPoint> {
		return new ScatterplotLayer({
			id: id,
			data: mapPoints.reverse(),
			pickable: true,
			opacity: 0.5,
			stroked: true,
			filled: true,
			radiusScale: 6,
			radiusMinPixels: 3,
			radiusMaxPixels: 100,
			lineWidthMinPixels: 1,
			lineWidthMaxPixels: 10,
			// onHover: d => console.log(d.object, d.x, d.y),
			getPosition: d => d.coordinates,
			getRadius: d => .05,
			getFillColor: d => {
				var r = d.color[0];
				var g = d.color[1];
				var b = d.color[2];
				var newr = r - (r * .4);
				var newg = g - (g * .4);
				var newb = b - (b * .4);
				return [newr, newg, newb, d.color[3]];
			},
			getLineColor: d => {
				var r = d.color[0];
				var g = d.color[1];
				var b = d.color[2];
				var newr = r - (r * .4);
				var newg = g - (g * .4);
				var newb = b - (b * .4);
				return [newr, newg, newb, d.color[3]];
			},
			getElevation: d => d.elevation
		});

	}

	public buildBitmapLayer(id: string, boundingBox: number[] | number[][], imageUrl: string) {
		console.log("buildBitmapLayer", id, boundingBox, imageUrl)
		return new BitmapLayer({
			id: id,
			bounds: boundingBox,
			image: imageUrl,
		});
	}
	public static myFeatureCollection = {
		type: "FeatureCollection",
		features: new Array()
	};
	public buildEditableGeoJsonLayer(id: string, data: any, mode: any, editFn: any): any {
		return new EditableGeoJsonLayer({
			id: id,
			data: MapService.myFeatureCollection,
			selectedFeatureIndexes: [0],
			mode: mode,
			// Styles
			filled: true,
			pointRadiusMinPixels: 2,
			pointRadiusScale: 2000,
			extruded: true,
			getElevation: 1000,
			getFillColor: [200, 0, 80, 0],
			// Interactive props
			pickable: true,
			autoHighlight: true,
			onEdit: editFn,
			// onEdit: ({ updatedData, editType, featureIndexes, editContext }) => {
			// MapService.myFeatureCollection = updatedData;
			//   console.log(MapService.myFeatureCollection, updatedData);			  
			//   this.deck.setProps({ layers: this.updateMap() });
			// }
		})

	}

	public zoomToLngLat(deck: Deck, longitude: number, latitude: number, zoom: number = this.DEFAULT_MAX_ZOOM) {
		if (!longitude || !latitude || latitude == 0 || longitude == 0) return;
		var viewstate = {
			longitude: longitude,
			latitude: latitude,
			zoom: zoom,
			transitionDuration: 300,
			transitionInterpolator: new FlyToInterpolator(),
		};
		deck.setProps({
			initialViewState: viewstate
		})
	}
	public zoomToMapPoint(deck: Deck, mapPoint: MapDataPoint, zoom: number = this.DEFAULT_MAX_ZOOM) {
		if (!mapPoint.coordinates || mapPoint.coordinates[0] == 0 || mapPoint.coordinates[1] == 0) return;
		var viewstate = {
			longitude: mapPoint.coordinates[0],
			latitude: mapPoint.coordinates[1],
			zoom: zoom,
			transitionDuration: 300,
			transitionInterpolator: new FlyToInterpolator(),
		};
		deck.setProps({
			initialViewState: viewstate
		})
	}

	/// Bounds and other helpers
	public getDefaultBounds(): number[][] {
		return [
			[0, 0],
			[0, 0]
		]
	}

	public updateBounds(bounds: number[][], points: MapDataPoint[]): number[][] {
		var minLng = bounds[0][0];
		var minLat = bounds[0][1];
		var maxLng = bounds[1][0];
		var maxLat = bounds[1][1];
		for (let index = 0; index < points.length; index++) {
			const p = points[index];
			if (!p) {
				continue;
			}
			if (!p.coordinates) {
				continue;
			}
			if (minLng == 0 || minLat == 0 || maxLng == 0 || maxLat == 0) {
				minLng = p.coordinates[0];
				maxLng = p.coordinates[0];
				minLat = p.coordinates[1];
				maxLat = p.coordinates[1];
			}
			else {
				if (p.coordinates[0] <= minLng) {
					minLng = p.coordinates[0];
				}
				if (p.coordinates[1] <= minLat) {
					minLat = p.coordinates[1];
				}
				if (p.coordinates[0] >= maxLng) {
					maxLng = p.coordinates[0];
				}
				if (p.coordinates[1] >= maxLat) {
					maxLat = p.coordinates[1];
				}
			}
		}
		return [
			[minLng, minLat],
			[maxLng, maxLat],
		]
	}

	public zoomFitLayers(deck: Deck, bounds: number[][], currentPoint?: MapDataPoint): boolean {
		var viewport: WebMercatorViewport = new WebMercatorViewport(deck.viewState);

		if (bounds[0][0] == 0 || bounds[0][1] == 0 || bounds[1][0] == 0 || bounds[1][1] == 0) {
			return false; // invalid bounds
		}
		if (viewport) {
			let { longitude, latitude, zoom } = viewport.fitBounds(bounds);
			console.log("Fit bounds is returning: longitude:", longitude, " ::latitude:", latitude, " ::zoom:", zoom);
			zoom = Math.floor(zoom)
			if (zoom >= this.DEFAULT_MAX_ZOOM) {
				zoom = this.DEFAULT_MAX_ZOOM;
			}
			if (zoom <= this.DEFAULT_MIN_ZOOM) {
				zoom = this.DEFAULT_MIN_ZOOM;
			}
			if (currentPoint && currentPoint.coordinates) {
				console.log("Current point set to : ", currentPoint.coordinates[0]);
				longitude = currentPoint.coordinates[0];
				latitude = currentPoint.coordinates[1];
				zoom = this.DEFAULT_MIN_ZOOM;
			}
			var viewstate = {
				longitude: longitude,
				latitude: latitude,
				zoom: zoom - 1,
				transitionDuration: 300,
				transitionInterpolator: new FlyToInterpolator(),
			};
			if (viewstate) {
				deck.viewState = viewstate;
			}
			return true;
		}
		return false;
	}
}
