
import { crc16kermit } from 'crc'; // !Important! :: Supported by b
import { AllServcies } from '../startup/startup.service';
import { EncryptionService } from '../encryption/encryption.service';

import { Buffer } from 'buffer';


export enum UtpMessageType {
	CLIENT_PUB_ECC256_KEY_SEND_ONBOARDING, //using client's onboarding key
    SERVER_PUB_ECC256_KEY_SEND_ONBOARDING, //using client's onboarding key
    MESSAGE_ECC256_GCM_SEND, 
    MESSAGE_ACK,
    MESSAGE_NACK,
    RAW_STREAM,
    // CLIENT_PUB_ECC256_KEY_SEND_OPEN, 
    // SERVER_PUB_ECC256_KEY_SEND_OPEN,
	ECC_MAX_MESSAGE
}
export enum UtpErrorCode {
	ECC_ERROR_UKNOWN,
	ECC_ERROR_MESSAGE_TYPE,
	ECC_ERROR_MESSAGE_LENGTH,
	ECC_ERROR_SECRET_MISSING,
	ECC_ERROR_DECRYPT_FAILURE,
	ECC_ERROR_MAX
}

export class SimpleEccFrame {
	static ECC_MAGIC_BYTE = 0x85; // sync byte at start of payload
	static ECC_HEADER_SIZE = 12;
	static ECC_HEADER_CRC_SIZE = SimpleEccFrame.ECC_HEADER_SIZE-2;

	static ECC_MAX_DATA_SIZE = (500 - SimpleEccFrame.ECC_HEADER_SIZE) 

	// Used only in the onboarding process
	static PUB_ECC256_KEY_ONBOARDING:Uint8Array = Uint8Array.from( [
		149,  97, 214, 161, 141,  87,  90,   8, 108, 173, 166,
		8, 156, 141, 121,  21,  48, 204,  76,  83, 167, 114,
		175, 147, 251, 141, 139, 251,  74,  63, 135,  70,  58,
		12,  97, 120,  28,   4, 217,  60, 124, 242, 131,  83,
		235,  43, 123, 159,   9,   3,  62,  65,  13, 241, 230,
		182, 110,  35, 174, 207,  56, 195,  27,   9
	]);
	static CLIENT_TO_SERVER_KEY_ONBOARDING:Uint8Array = SimpleEccFrame.PUB_ECC256_KEY_ONBOARDING.slice(0, SimpleEccFrame.PUB_ECC256_KEY_ONBOARDING.length/2);
	static SERVER_TO_CLIENT_KEY_ONBOARDING:Uint8Array = SimpleEccFrame.PUB_ECC256_KEY_ONBOARDING.slice(SimpleEccFrame.PUB_ECC256_KEY_ONBOARDING.length/2);

	// NOTE :: THIS WILL MOVE -> eventually will be moved to an offline Service to sign the server public key's below
	// d7bb66805ddc8a09d14a359e291adf42d471b3838716ae8647d786c82e127a29
	static PRIV_ECC256_KEY_ONBOARDING:Uint8Array = Uint8Array.from([
		215, 187, 102, 128,  93, 220, 138,   9,
		209,  74,  53, 158,  41,  26, 223,  66,
		212, 113, 179, 131, 135,  22, 174, 134,
		71, 215, 134, 200,  46,  18, 122,  41
	]);

	// TODO :: Add Signature to this public key
	// TODO :: Pass this in via parameter -> load from external data source
	// ca3d26c6e1e5eaac4ff82bbcd2955b5825ccb919785c1e3150d812e1c764816421b76926eed5a45666783182d5d0f15429725ebb7c6273ebb4ddb7ab019dcf28
	private PUB_ECC256_KEY_SERVER:Uint8Array = Uint8Array.from([
		202,  61,  38, 198, 225, 229, 234, 172,  79, 248,  43,
		188, 210, 149,  91,  88,  37, 204, 185,  25, 120,  92,
		30,  49,  80, 216,  18, 225, 199, 100, 129, 100,  33,
		183, 105,  38, 238, 213, 164,  86, 102, 120,  49, 130,
		213, 208, 241,  84,  41, 114,  94, 187, 124,  98, 115,
		235, 180, 221, 183, 171,   1, 157, 207,  40
	]);
	//56f0c6ed430b8a59c80d68e3a811633a6ea4d0744c6157a8960fb08bd35cf0e9
	private PRIV_ECC256_KEY_SERVER:Uint8Array = Uint8Array.from([
		86, 240, 198, 237,  67, 11, 138,  89,
		200,  13, 104, 227, 168, 17,  99,  58,
		110, 164, 208, 116,  76, 97,  87, 168,
		150,  15, 176, 139, 211, 92, 240, 233
	]);

	public setPublicKey(publicKey:Uint8Array){
		this.PUB_ECC256_KEY_SERVER = publicKey;
	}
	public setPrivateKey(privateKey:Uint8Array){
		this.PRIV_ECC256_KEY_SERVER = privateKey;
	}

	// static CLIENT_PUB_ECC256_KEY_ONBOARDING:number[] = this.onboard_public_key.slice(0, this.onboard_public_key.length/2);
	// static onboarding_shared_key:number[] = [0x9a, 0xc9, 0x9f, 0x33, 0x63, 0x2e, 0x5a, 0x76, 0x8d, 0xe7, 0xe8, 0x1b, 0xf8, 0x54, 0xc2, 0x7c,
	// 0x46, 0xe3, 0xfb, 0xf2, 0xab, 0xba, 0xcd, 0x29, 0xec, 0x4a, 0xff, 0x51, 0x73, 0x69, 0xc6, 0x67];

	private valid:boolean = false;
	private header_buffer:Uint8Array = new Uint8Array(SimpleEccFrame.ECC_HEADER_SIZE);
	public client_secret = new Uint8Array(32);
	public hasClientSecret(){return this.client_secret.length > 0}
	public isValid(){return this.valid}

	raw:Uint8Array;
	ecc_magic_byte:number;		// all urpc calls start with URPC_MAGIC_BYTE  (0x72) 'r'
	message_type:number;		// see RPC_MESSAGE_TYPE
	dev_id:number;				// 2 Byte device ID Hash. Either provided by application or generated during init
	tag:number;					// 32 bit tag used in encryption 
	length:number;				// size data, can be zero
	crc:number;					// crc of everything except above. or equal to size for testing
	data:Uint8Array;
	iv:Uint8Array;

	constructor(
		servcies:AllServcies,
		fullData?:Uint8Array
	) {
		if(fullData){
			this.parseFullData(fullData)
		}
	}


	init(services:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(!services){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(services);
				resolve(true);
			}
		});
	}

	private services:AllServcies;;
	public setServices(services) {
		this.services = services;
	}

	// Byte offsets for each position w/in the data packet
	// Keep in nework order, big-endian
	// https://datatracker.ietf.org/doc/html/rfc1700
	static UrpcFrameOffset:any = {
		isLittleEndian : false,
		urpc_magic_byte: 0,
		message_type: 1,
		dev_id: 2,
		tag: 4,
		length: 8,
		crc: 10,
		data: 12,
	}

	public async parseFullData( data:Uint8Array ) : Promise<Uint8Array> {
		return new Promise( async (resolve, reject) =>  {
			try {
				this.raw = Uint8Array.from(data);
				var data_view:DataView = new DataView(this.raw.buffer);
				this.ecc_magic_byte = data_view.getUint8(SimpleEccFrame.UrpcFrameOffset.urpc_magic_byte);
				this.message_type = data_view.getUint8(SimpleEccFrame.UrpcFrameOffset.message_type);
				this.dev_id = data_view.getUint16(SimpleEccFrame.UrpcFrameOffset.dev_id, SimpleEccFrame.UrpcFrameOffset.isLittleEndian);
				this.tag = data_view.getUint32(SimpleEccFrame.UrpcFrameOffset.tag, SimpleEccFrame.UrpcFrameOffset.isLittleEndian);
				this.length = data_view.getUint16(SimpleEccFrame.UrpcFrameOffset.length, SimpleEccFrame.UrpcFrameOffset.isLittleEndian);
				this.crc = data_view.getUint16(SimpleEccFrame.UrpcFrameOffset.crc, SimpleEccFrame.UrpcFrameOffset.isLittleEndian);
				this.valid = (this.crc == this.calc_crc(this.raw));
				this.data = this.raw.slice(SimpleEccFrame.UrpcFrameOffset.data);
				this.iv = this.raw.slice(0,SimpleEccFrame.UrpcFrameOffset.tag);
				// this.log("Parsed Full:");
				if(this.isValid()){
					if(this.message_type == UtpMessageType.CLIENT_PUB_ECC256_KEY_SEND_ONBOARDING){
						console.log("UtpMessageType.CLIENT_PUB_ECC256_KEY_SEND_ONBOARDING: Client's encrypted public key is : ", EncryptionService.toHexString(this.data));
						await this.generateDerivationKey();


						resolve(new Uint8Array(0));
					}
					else if(this.message_type == UtpMessageType.MESSAGE_ECC256_GCM_SEND){
						console.log("UtpMessageType.MESSAGE_ECC256_GCM_SEND: Message ECC256 GCM Send");
						if(this.client_secret){
							var client_data = await this.decryptData(this.client_secret);
							if(client_data){
								resolve(client_data);
							}
							else {
								reject({code:0, message:"Decryption Failed"});
							}
						}
					}
					else if(this.message_type == UtpMessageType.RAW_STREAM){
						console.log("UtpMessageType.RAW_STREAM: Raw Stream");
						if(this.client_secret){
							var client_data = await this.decryptData(this.client_secret);
							if(client_data){
								resolve(client_data);
							}
							else {
								reject({code:0, message:"Decryption Failed"});
							}
						}
					}
					else {
						console.log("Unknown Message Type: ", this.message_type);
						if(this.client_secret){
							var client_data = await this.decryptData(this.client_secret);
							if(client_data){
								resolve(client_data);
							}
							else {
								reject({code:0, message:"Decryption Failed"});
							}
						}
					}
				}
				else {
					reject({code:0, message:"Invalid Frame"});
				}
			}
			catch(e){
				console.error("Error parsing full data: ", e);
				reject({code:0, message:"Error parsing full data"});
			}
		});
	}

	public parseByByte( byte:number ) {
		var data_view:DataView = new DataView(this.header_buffer.buffer);
		//TODO if needed
		return 0;
	}

	// Build a Uint8 buffer with all the correct formatting
	public async serialize(secret:Uint8Array, data?:Uint8Array) : Promise<Uint8Array> {
		return new Promise<Uint8Array>( async (resolve, reject) => {
			// TODO :: Can probably just overwrite the important parts of the frame
			// instead of full copy over.
			try{
				// optional : set data before serialize
				if(data != null && data.length > 0){
					this.data = data;
					// console.log("Serialize: data set : ", EncryptionHelper.toHexString(data));
				}
				// console.log("Serialize start");
				var out = new Uint8Array(SimpleEccFrame.ECC_HEADER_SIZE + this.data.length);
				var out_view = new DataView(out.buffer);
				out_view.setUint8(SimpleEccFrame.UrpcFrameOffset.urpc_magic_byte, SimpleEccFrame.ECC_MAGIC_BYTE);
				out_view.setUint8(SimpleEccFrame.UrpcFrameOffset.message_type, this.message_type);
				out_view.setUint16(SimpleEccFrame.UrpcFrameOffset.dev_id, this.dev_id, SimpleEccFrame.UrpcFrameOffset.isLittleEndian);
				
				var iv = out.slice(0,SimpleEccFrame.UrpcFrameOffset.tag);
				if(this.data != null && this.data.length>0){
					var results:{data:Uint8Array, tag:number} = await this.encryptData(secret, iv, this.data )
					out_view.setUint32(SimpleEccFrame.UrpcFrameOffset.tag, results.tag, SimpleEccFrame.UrpcFrameOffset.isLittleEndian)
					out_view.setUint16(SimpleEccFrame.UrpcFrameOffset.length, results.data.length, SimpleEccFrame.UrpcFrameOffset.isLittleEndian);
					out.set(results.data, SimpleEccFrame.UrpcFrameOffset.data);
				}
				out_view.setUint16(SimpleEccFrame.UrpcFrameOffset.crc, this.calc_crc(out), SimpleEccFrame.UrpcFrameOffset.isLittleEndian);
				// var out_frame_check = new SimpleEccFrame(this.services, out);
				// out_frame_check.log("Serializing Response : ");
				resolve( out );
			}
			catch(e){
				reject(e);
			}
		})
	}

	public log (prefix:string="") {
		console.log(prefix+" : Frame is : ");
		if(this.ecc_magic_byte){
			console.log("urpc_magic_byte:",this.ecc_magic_byte.toString(16));
		}
		else {
			console.log("urpc_magic_byte: undefined");
		}
		if(this.message_type){
			console.log("message_type:",this.message_type.toString(16));
		}
		else{
			console.log("message_type: undefined");
		}
		if(this.dev_id){
			console.log("dev_id:",this.dev_id.toString(16));
		}
		else {
			console.log("dev_id: undefined");
		}
		if(this.tag){
			console.log("tag:",this.tag.toString(16));
		}
		else {
			console.log("tag: undefined");
		}
		if(this.length){
			console.log("length:",this.length.toString(16));
		}
		else {
			console.log("length: undefined");
		}
		if(this.crc){
			console.log("crc:",this.crc.toString(16));
		}
		else {
			console.log("crc: undefined");
		}
		if(this.data){
			console.log("data:",EncryptionService.toHexString(this.data));
		}
		else {
			console.log("data: undefined");
		}
		console.log("valid:",this.valid);
	}

	private calc_crc( data:Uint8Array ) : number {
		var arrayOne = data.slice(0,SimpleEccFrame.UrpcFrameOffset.crc);
		var arrayTwo = data.slice(SimpleEccFrame.UrpcFrameOffset.data,data.length)
		var mergedArray = new Uint8Array(arrayOne.length + arrayTwo.length);
		mergedArray.set(arrayOne);
		mergedArray.set(arrayTwo, arrayOne.length);
		return crc16kermit(Buffer.from(mergedArray))
	}

	public encryptData(secret:Uint8Array, iv:Uint8Array, data:Uint8Array) : Promise<{data:Uint8Array, tag:number}> {
		if(this.services.encryption){
			return this.services.encryption.aesGcmEncrypt(secret, iv, data);
		}
		return Promise.reject("No encryption service available");
	};

	public decryptData(secret:Uint8Array) : Promise<Uint8Array> {
		// Add the tag onto the end of the encrypted data
		if(this.services.encryption){
			console.log("Decrypting Data");
			console.log("secret: ", EncryptionService.toHexString(secret));
			console.log("iv: ", EncryptionService.toHexString(this.iv));
			console.log("data: ", EncryptionService.toHexString(this.data));
			return this.services.encryption.aesGcmDecrypt(secret, this.iv, this.data, this.raw.slice(SimpleEccFrame.UrpcFrameOffset.tag, SimpleEccFrame.UrpcFrameOffset.tag+4))
		}
		return Promise.reject("No encryption service available");
	}
	
	public async generateDerivationKey( ) {
		if(this.services.encryption){
			console.log("Generating Derivation Key")
			var server_private_key = this.PRIV_ECC256_KEY_SERVER;
			var client_public_key = await this.decryptData(SimpleEccFrame.CLIENT_TO_SERVER_KEY_ONBOARDING);
			console.log("Got client public key: ", EncryptionService.toHexString(client_public_key));
			this.client_secret = await this.services.encryption.eccGcmCalculateSecret(server_private_key, client_public_key);
			console.log("Calculated client secret: (this.client_secret) ", EncryptionService.toHexString(this.client_secret));
		}
	}

	public async getOnboardingBytes(services:AllServcies) : Promise<Uint8Array>{
		return new Promise( async (resolve, reject) => {
			this.services = services;
			var secret = SimpleEccFrame.CLIENT_TO_SERVER_KEY_ONBOARDING;
			if(this.services.encryption){
				this.message_type = UtpMessageType.SERVER_PUB_ECC256_KEY_SEND_ONBOARDING;
				console.log("Giving device the public key: ", EncryptionService.toHexString(this.PUB_ECC256_KEY_SERVER));
				var bytes = await this.serialize(secret, this.PUB_ECC256_KEY_SERVER);
				resolve(bytes);
			}
			else {
				reject({code:0, message:"Encryption Service Not Given"});
			}
		});
	}
}