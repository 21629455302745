import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Settings, SettingsKeyValue } from '../../../generated_proto/protobuf-ts/pb/v2/settings';

@Component({
	selector: 'app-edit-settings-modal',
	templateUrl: './edit-settings-modal.page.html',
	styleUrls: ['./edit-settings-modal.page.css'],
})
export class EditSettingsModalPage implements OnInit {

	public targetSettings:Settings;

	constructor(
		public modalController: ModalController,
	)
	{

	}

	ngOnInit() {
		console.log("targetSettings:", this.targetSettings)
		this.targetSettings.keyValues.map( (keyValue) => {
			console.log("keyValue:", keyValue)
			if(keyValue.value.oneofKind == "valueNumber" && !keyValue.value["valueNumber"] || keyValue.value["valueNumber"] == 0){
				keyValue.value["valueNumber"] = BigInt(0);
			}
		})
	}

	async ionViewDidEnter() {
		if(this.targetSettings){
			console.log("Modal starting with targetSettings:", this.targetSettings);
		}
		else {
			this.targetSettings = Settings.create();
		}
	}
	save() {
		this.modalController.dismiss({
			"targetSettings": this.targetSettings
		});
	}
	cancel() {
		this.modalController.dismiss();
	}
}



