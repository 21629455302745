import { JsonValue } from "@protobuf-ts/runtime"

import { ViewModelImplemented } from "./ViewModelImplemented";
import { AllServcies } from "../services/startup/startup.service"
// import { File, } from '../generated_proto/google/app/model/v1/data_pb';
import { FirmwareFileViewModel, FirmwareFileModel, ModelType, DataBase } from '../generated_proto/protobuf-ts/pb/v2/models';
import { FirmwareFile, } from '../generated_proto/protobuf-ts/pb/v2/entities';

import { DataService } from "../services/data/data.service";
import { UtilService } from "../services/util/util.service";
import { LoggerOptions } from "../services/logger/logger.service";
import { FileViewModelImplemented } from './File.vmi';


export class FirmwareFileViewModelImplemented extends ViewModelImplemented implements FirmwareFileViewModel {

	public model:FirmwareFileModel;
	public checkedIfInUse: boolean = false;
	public inUseGroupUrns: string[] = [];

	public services:AllServcies;

	public file:FileViewModelImplemented; // File is populated through md5 ask when needed (only when needed)

	public loggerOptions:LoggerOptions = {
		prefix:"FirmwareFileViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	// Since these objects are created at runtime, they need to be passed references to the Services
	constructor(
		services:AllServcies
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		// Can pass as param, not used in this case but would
		// be useful behavior for sub classes
		this.services = services;
		this.model = FirmwareFileModel.create();
		this.model.firmware = FirmwareFile.create();
		this.file = new FileViewModelImplemented(this.services);
	}

	modelToJson() : JsonValue {
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		return FirmwareFileModel.toJson(this.model);
	}

	getMd5HexString(): string {
		if(this.services.encryption && this.model.firmware?.md5){
			return "0x"+this.services.encryption.toHexString(this.model.firmware.md5);
		}
		console.error("Encryption Service Not Set");
		return "";
	}

	getShortDisplayCommitUrl( length:number ): string {
		var commitUrl = this.model.firmware?.commitUrl;
		if(commitUrl){
			if(commitUrl.length > length){
				return commitUrl.slice(0, length)+"...";
			}
			return commitUrl;
		}
		return "";
	}

	getDisplayNameVersion() : string {
		if( this.model.firmware ){
			if(this.model.firmware.name){
				return this.model.firmware.name+"-"+this.getVersionString();
			}
			else {
				return this.getVersionString();
			}
		}
		return "";
	}

	getVersionString() : string {
		if( this.model.firmware ){
			return this.model.firmware.verMajor+"."+this.model.firmware.verMinor;
		}
		return "";
	}

	update(){

	}

	updateByUrn(urn:string){
		this.model = FirmwareFileModel.create();
		this.model.firmware = FirmwareFile.create();
		var urnSplit = urn.split("/");
		var dbKey = urnSplit[urnSplit.length-1];
		var keySplit = dbKey.split(",");
		if(keySplit[2]){
			var versionSplit = keySplit[2].split(".");
			var verMajor = versionSplit[0];
			var verMinor = versionSplit[1];
			var md5 = versionSplit[2];
			this.model.firmware.verMajor = parseInt(verMajor);
			this.model.firmware.verMinor = parseInt(verMinor);
			this.model.firmware.md5 = UtilService.base64DecodeURL(md5);
		}
		
		
	}

	async setFile(file_in:any){
		await this.file.setFile(file_in);
		if(this.model.firmware && this.file.model.file){
			this.model.firmware.md5 = this.file.model.file?.md5;
		}
	}

	setModel(firmwareFileModel:FirmwareFileModel) : FirmwareFileViewModelImplemented {
		if(firmwareFileModel){
			this.model = firmwareFileModel;
		}
		return this;
	}

	updateLocalFromDb(json:any) : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			this.setModel(FirmwareFileModel.fromJson(json, {ignoreUnknownFields: true}));
			this.updateDb();
			if(!this.services.settings){
				reject("Settings Service Not Set");
				return;
			}
			if(!this.services.settings.SETTINGS.APP_ID){
				reject("App ID Not Set");
				return;
			}

			if(this.model.firmware){
				this.services.pouch?.files.getFile(this.services.settings.SETTINGS.APP_ID, this.model.firmware.md5).then( (file) => {
					this.file = file;
					resolve(true);
				}).catch( (err) => {
					console.error("Error getting file from pouch", err);
					reject("Error getting file from pouch"+err);
				});
			}
			else {
				reject("No Firmware File");
			}
		})
	}

	getFile(app_id?:number): Promise<FileViewModelImplemented> {
		return new Promise( (resolve, reject) => {
			if(this.model.firmware){
				if(!this.services.settings){
					reject("Settings Service Not Set");
					return;
				}
				if(!this.services.settings.SETTINGS.APP_ID){
					reject("App ID Not Set");
					return;
				}
				var app_id_used:number = 0;
				if(app_id){
					app_id_used = app_id;
				}
				else if(this.services.settings.SETTINGS.APP_ID){
					app_id_used = this.services.settings.SETTINGS.APP_ID;
				}
				else {
					reject("App ID Not Set");
					return;
				}
				this.services.pouch?.files.getFile(app_id_used, this.model.firmware.md5).then( (file) => {
					resolve(file);
				}).catch( (err) => {
					console.error("Error getting file from pouch", err);
					reject("Error getting file from pouch"+err);
				});
			}
			else {
				reject("No Firmware File");
			}
		});
	}

	updateDb(db?:DataBase){
		if(!this.model.db){
			this.model.db = DataBase.create();
		}
		if(db){
			try{
				DataBase.mergePartial(this.model.db, db);
			}
			catch(e){
				console.error("updateDb:error: ",e);
			}
		}
		if( this.model.db.createdMs && this.model.db.createdMs <= 0){
			this.model.db.createdMs = BigInt(Date.now());
		}
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.FIRMWARE_FILE_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}

	public static PB_VERSION = 2;
	static GenerateDbId(dbPrefix:string) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return dbPrefix + ModelType.FIRMWARE_FILE_MODEL;
	}
	static GenerateDbKey(verMajor:number, verMinor:number, md5:Uint8Array) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		// convert md5 to base64url
		var md5_string = UtilService.base64EncodeURL(md5);
		var key = verMajor+"."+verMinor+"."+md5_string;
		return DataService.getKeyPrefix(ModelType.FIRMWARE_FILE_MODEL,FirmwareFileViewModelImplemented.PB_VERSION)+key;
	}
	public generateDbKey() : string {
		if(this.model.firmware){
			return FirmwareFileViewModelImplemented.GenerateDbKey(
				this.model.firmware.verMajor,
				this.model.firmware.verMinor,
				this.model.firmware.md5
			);
		}
		return "";
	}
	static GenerateURN(dbPrefix:string, firmware:FirmwareFile) : string {
		if(firmware){
			var dbid = this.GenerateDbId(dbPrefix);
			return dbid+"/"+this.GenerateDbKey(firmware.verMajor, firmware.verMinor, firmware.md5);
		}
		console.error("generateURN: No UUID");
		return "";
	}
	public generateURN() : string {
		if(this.model && this.model.firmware && this.services.data?.getDbPrefix()){
			console.log();
			return FirmwareFileViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.model.firmware);
		}
		return "";
	}

	refresh() {
		// Update our local UI
		// This is where you can handle any UI specific bindings from the model's data 
	}

	save(app_id?:number, isNew:boolean=true) : Promise<boolean>{
		console.log("vmi save, is new : ", isNew);
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}

		return new Promise( async (resolve, reject) => {
			this.updateDb();
			if(this.model.db?.editedMs){
				this.model.db.editedMs = BigInt(Date.now());
			}
			if(!this.file.fileIsSet){
				reject("File not set");
				return;
			}
			// Save the base file
			if(this.services.pouch && this.services.pouch.files){
				if(isNew){
					if(!this.services.settings){
						reject("Settings Service Not Set");
						return;
					}
					if(!this.services.settings.SETTINGS.APP_ID){
						reject("App ID Not Set");
						return;
					}
					var saved = await this.services.pouch.files.saveFile(this.services.settings.SETTINGS.APP_ID, this.file);
					if(!saved){
						reject("Error saving file");
					}
				}
				if(this.services.pouch && this.services.pouch.files){
					this.services.pouch.deviceManagement.saveFirmwareFile(app_id||2, this, isNew).then( (saved) => {
						if(saved){
							resolve(true);
						}
						else {
							reject("Error saving firmware file");
						}
					}).catch( (err) => {
						reject("Error saving firmware file: "+err);
					});
				}
				else{
					reject("Pouch Service/app Not Set");
				}
			}
			else{
				reject("Pouch Service/files Not Set");
			}

		})
	}

	delete():Promise<boolean>{
		return new Promise( async (resolve, reject) => {
			if(this.services.pouch && this.services.pouch.app){
				if(!this.services.settings){
					reject("Settings Service Not Set");
					return;
				}
				if(!this.services.settings.SETTINGS.APP_ID){
					reject("App ID Not Set");
					return;
				}
				this.services.pouch.deviceManagement.deleteFirmwareFile(this.services.settings.SETTINGS.APP_ID, this, true)
				.then( (success) => {
					(success) ? resolve(true) : resolve(false);
				})
				.catch( (err) => {
					reject(err);
				});
			}
		});
	}
	saveToDisk(filePath?:string) : Promise<boolean>{
		if(this.file){
			var fileName = this.model.firmware?.name + "-" + this.model.firmware?.verMajor + "." + this.model.firmware?.verMinor;
			console.log("Filename is : ", fileName)
			return this.file.saveToDisk(fileName, filePath);
		}
		return Promise.reject("File does not exist");
	}

	async downloadFirmware() : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			if(this.services.pouch && this.services.pouch.files){
				if(this.model.firmware){
					if(!this.services.settings){
						reject("Settings Service Not Set");
						return;
					}
					if(!this.services.settings.SETTINGS.APP_ID){
						reject("App ID Not Set");
						return;
					}
					this.services.pouch.files.getFile(this.services.settings.SETTINGS.APP_ID, this.model.firmware.md5).then((file)=>{
						file.saveToDisk(this.model.firmware?.name+"-"+this.getVersionString())
							.then( (done) => resolve(done))
							.catch( () => resolve(false));
					}).catch( () =>{
						resolve(false)
					});
				}
				else {
					reject(false);
				}
			}
			else{
				console.error("Pouch Service/files Not Set");
				reject(false);
			}
		})
	}

}