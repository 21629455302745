
import { DeviceTypeGroups, DeviceViewModelImplemented } from "../../viewmodels/Device.vmi";
import { AllServcies } from "../../services/startup/startup.service"
import { DeviceTypeIds, } from "../../viewmodels/Device.vmi";

export class HumGatewayViewModelImplemented extends DeviceViewModelImplemented {

	constructor
	(
		services:AllServcies,
	)
	{
		super(services);
		if(this.model.device){
			this.model.device.deviceType = DeviceTypeIds.HUM_SENSOR;
		}
	}

	public static getStaticTitle() : string {
		return "Hum Gateway"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return HumGatewayViewModelImplemented.getStaticTitle();
	}

	public thumbnailImagePath:string = "assets/app/hum/hum-gateway.webp";
	public getThumbnailImagePath() : string{
		return this.thumbnailImagePath;
	}

	public getDeviceTypeGroups() : DeviceTypeGroups {
		var groups:DeviceTypeGroups = [];
		if(this.services.settings?.SETTINGS?.APP_ID == 2){
			groups.push({
				deviceTypeId: DeviceTypeIds.HUM,
				formattedName: "HUM",
			});
		}
		if(this.model.device?.deviceType == DeviceTypeIds.HUM_GATEWAY){
			groups.push({
				deviceTypeId: DeviceTypeIds.HUM_GATEWAY,
				formattedName: "HUM Gateways",
			});
		}
		return groups;
	}

}