
import { DeviceTypeGroups, DeviceTypeIds, } from "../../viewmodels/Device.vmi";
import { BluetoothBeaconViewModelImplemented } from "../../viewmodels/BluetoothBeacon.vmi";
import { BluetoothConnection, } from "../../services/bluetooth/ble.connetion"
import { CONNECTION_STATE, } from "../../services/constants"
import { AllServcies, } from "../../services/startup/startup.service";
import { DataBase, DeviceModel, ModelType } from "../../generated_proto/protobuf-ts/pb/v2/models";

import { BluetoothCommonInterface, BluetoothRequestOptions } from "../../services/bluetooth/bluetooth.service";
import { DataService } from "../../services/data/data.service";


export class PlStationBluetoothConnection extends BluetoothConnection {

	public heartBeatIntervalMs:number = 110000;

	constructor
	(
		services:AllServcies,
		bluetoothCommonInterface?:BluetoothCommonInterface,
	)
	{
		super(services, bluetoothCommonInterface);
		if(services){
			this.services = services;
		}
		if(bluetoothCommonInterface){
			this.bluetoothCommonInterface = bluetoothCommonInterface;
		}
		else {
			console.error("ERROR CREATING PlStationBluetoothConnection CONNECTION");
		}
	}

	async initalizeDevice() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.connectionState = CONNECTION_STATE.CONNECTING;
			if(!this.getIsConnected()){
				try{
					await this.connect().catch( (e) => reject(e));
				}
				catch(e){
					reject(e);
				}
			}
			resolve(true);
		});
	}

	getRequesetFilter() : BluetoothRequestOptions {
		var requestFilter = {
			namePrefix: "PS",
			services:[
			],
			optionalServices: [
			], // Note this is required to allow ANY usage of the service on web ble
		}
		return requestFilter;
	}
}

export class  PlStationViewModelImplemented extends BluetoothBeaconViewModelImplemented {

	public bluetoothConnection:PlStationBluetoothConnection;
	public showTelemetryOffset:boolean = false;

	constructor
	(
		services:AllServcies,
		bluetoothConnection?:BluetoothConnection,
		model?:DeviceModel,
	)
	{
		super(services, bluetoothConnection, model);
		if(this.model.device){
			this.model.device.deviceType = DeviceTypeIds.PL_STATION;
			if(this.model.device.uuid){
				this.macAddress = DataService.UuidToMacAddress(this.model.device.uuid);
			}
		}
	}

	public static getStaticTitle() : string {
		return "PureLocate Station"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		return PlStationViewModelImplemented.getStaticTitle();
	}

	public thumbnailImagePath:string = "assets/app/pl/station-v1.jpg";
	public getThumbnailImagePath() : string{
		if(this.model.device?.deviceType == DeviceTypeIds.PL_STATION_DEV){
			return "assets/app/pl/station-dev.jpg";
		}
		else if(this.model.device?.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V2){
			return "assets/app/pl/station-v2.jpg";
		}
		return this.thumbnailImagePath;
	}

	public getDisplayUuid() : string {
		if(this.model.device?.uuid){
			return "MAC: "+DataService.UuidToMacAddressFormattedString(this.model.device.uuid);
		}
		return "MAC: n/a";
	}

	public getDeviceTypeGroups() : DeviceTypeGroups {
		var groups:DeviceTypeGroups = [];
		if(this.services.settings?.SETTINGS?.APP_ID == 2){
			groups.push({
				deviceTypeId: DeviceTypeIds.PL,
				formattedName: "PureLocate",
			});
		}
		groups.push({
			deviceTypeId: DeviceTypeIds.PL_STATION,
			formattedName: "Station",
		});
		if(this.model.device?.deviceType == DeviceTypeIds.PL_STATION_DEV){
			groups.push({
				deviceTypeId: DeviceTypeIds.PL_STATION_DEV,
				formattedName: "Development Stations",
			});
		}
		if(this.model.device?.deviceType == DeviceTypeIds.PL_STATION_V1){
			groups.push( {
				deviceTypeId: DeviceTypeIds.PL_STATION_V1,
				formattedName: "Large Plugs",
			});
		}
		if(this.model.device?.deviceType == DeviceTypeIds.PL_STATION_V2){
			groups.push( {
				deviceTypeId: DeviceTypeIds.PL_STATION_V2,
				formattedName: "Small Plugs",
			});
		}
		return groups;
	}

	// public getPagePath() : string {
	// 	console.log("PlStationViewModelImplemented :: getPagePath : ", this.model.device?.uuid);
	// 	if(this.model.device){
	// 		if(this.isLocal && this.bluetoothConnection && this.bluetoothConnection.bluetoothCommonInterface.identifier){
	// 			var encodedStringBtoA = btoa(this.bluetoothConnection.bluetoothCommonInterface.identifier).replace('+', '-').replace('/', '_').replace(/=+$/, '');;
	// 			return "vendors/pl/pl-beacon/" + this.model.device.uuid+"/" + encodedStringBtoA;
	// 		}
	// 		return "vendors/pl/pl-beacon/" + this.model.device.uuid;
	// 	}
	// 	return "vendors/pl/pl-beacon/" + 0;
	// }

}