import { AllServcies } from "../../services/startup/startup.service";


import { Empty, } from "../../generated_proto/protobuf-ts/pb/common";
import { Alive, FailRequest, SaveTelemetryRequest, } from "../../generated_proto/protobuf-ts/pb/v2/service_transports";

import { GrpcServiceClient, } from "../../generated_proto/protobuf-ts/pb/v2/service.client";
import {GrpcWebFetchTransport} from "@protobuf-ts/grpcweb-transport";

import { Telemetry } from "../../generated_proto/protobuf-ts/pb/v2/data";

// import {ChannelCredentials} from "@grpc/grpc-js";

// only do this if we're in nodejs and not in a browser
if (typeof process !== 'undefined' && process.versions && process.versions.node) {
	// fetch polyfill via https://github.com/node-fetch/node-fetch
	import('node-fetch').then(({ default: fetch, Headers }) => {
		globalThis.fetch = fetch;
		globalThis.Headers = Headers;
	}).catch(error => {
		console.error('Failed to import node-fetch:', error);
	});
}

export class GrpcWebClient {

	private services:AllServcies;
	private client:GrpcServiceClient;
	private host = 'https://app.palmexus.com';  

	constructor(
		
	){

	}

	init(services:AllServcies, hostOverride?:string){
		this.services = services;

		if(this.services.data?.getBackendUrl()){
			this.host = this.services.data?.getBackendUrl();
		}
		if(hostOverride){
			this.host = hostOverride;
		}

		const transport = new GrpcWebFetchTransport({
			baseUrl: this.host+"/grpc-web",
		});

		this.client = new GrpcServiceClient(transport);
	}

	public async serviceAlive() : Promise<Alive>{
		return new Promise<Alive>(async (resolve, reject) => {
			try{
				var request = Empty.create();
				const call = this.client.serviceAlive(request);
				const response = await call.response;
				const status = await call.status;
				if(status.code == "OK"){
					resolve(response);
				}
				else {
					reject(status);
				}
			}
			catch(err){
				console.error("! :: ERROR: ", err);
				reject(err);
			}
		});
	}

	public async saveTelemetry( app_id:number, telemetryModel:Telemetry) : Promise<boolean>{
		return new Promise<boolean>(async (resolve, reject) => {
			try{
				var request:SaveTelemetryRequest = SaveTelemetryRequest.create();
				request.appId = app_id;
				request.telemetry = telemetryModel;
				const call = this.client.saveTelemetry(request);
				const response = await call.response;
				const status = await call.status;
				if(status.code == "OK"){
					if(response.failRequest == FailRequest.OK){
						resolve(true);
					}
					else {
						reject(response.failRequest);
					}
				}
				else {
					reject(status);
				}
			}
			catch(err){
				console.error("! :: ERROR: ", err);
				reject(err);
			}
		});
	}
}
