import { JsonValue } from "@protobuf-ts/runtime"

import { ViewModelImplemented } from "./ViewModelImplemented";
import { AllServcies } from "../services/startup/startup.service"
// import { File, } from '../generated_proto/google/app/model/v1/data_pb';
import { DataBase, FileModel, FileViewModel, ModelType, } from '../generated_proto/protobuf-ts/pb/v2/models';
import { File, } from '../generated_proto/protobuf-ts/pb/v2/entities';
import { DataService } from "../services/data/data.service";
import { UtilService } from "../services/util/util.service";
import { LoggerOptions } from "../services/logger/logger.service";

import axios, { AxiosRequestConfig, AxiosPromise } from "axios";

export class FileViewModelImplemented extends ViewModelImplemented implements FileViewModel {

	public model:FileModel;

	public log_prefix:string = " :: FileViewModelImplemented : ";
	public services:AllServcies;

	public fileIsSet:boolean = false;
	private jsFile:any; // File object from javascript NOT THE PROTO OBJECT

	public loggerOptions:LoggerOptions = {
		prefix:"FileViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	public isImage:boolean = false;
	public attachmentAsURL:string|null = null; // Data converted to URL

	// Since these objects are created at runtime, they need to be passed references to the Services
	constructor(
		services:AllServcies
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		// Can pass as param, not used in this case but would
		// be useful behavior for sub classes
		this.services = services;
		this.model = FileModel.create();
	}

	private md5FromFile( file:any ) : Promise<Uint8Array> {
		return new Promise(async (resolve, reject) => {
			var reader = new FileReader();
			reader.onloadend = async () =>{
				if(this.services.encryption){
					var md5 = await this.services.encryption.md5(new Uint8Array(reader.result as ArrayBuffer));
					resolve(md5);
				}
				else{
					reject("Encryption Service Not Set");
				}
			};
			reader.readAsArrayBuffer(file); // cast to array buffer because of this function
		})
	}

	async setFile(file_in:any){
		this.jsFile = file_in;
		var md5:Uint8Array = await this.md5FromFile(this.jsFile);
		console.log("Set file md5 : ", md5);
		if(!this.model){
			this.model = FileModel.create();
		}
		this.model.file = File.create();

		(this.jsFile.size) ? this.model.file.length = (this.jsFile.size) : this.model.file.length = 0;
		(md5) ? this.model.file.md5 = md5 : this.model.file.md5 = new Uint8Array(0);
		console.log("this.model.file.md5 : ", this.model.file.md5);

		(this.jsFile.type) ? this.model.file.mimeType = (this.jsFile.type) : this.model.file.mimeType = 'UNKNOWN';
		// check if mimeTyps is image
		if(this.model.file.mimeType.includes("image")){
			this.isImage = true;
		}
		(this.jsFile.name) ? this.model.file.name = this.jsFile.name : this.model.file.name = 'UNKNOWN';

		this.updateDb();
		if(this.model.db){
			this.model.db.createdMs = BigInt(this.jsFile.lastModified);
			this.model.db.receivedMs = BigInt(Date.now());
		}
		this.fileIsSet = true;
	}
	getFile(){
		return this.jsFile;
	}

	getMd5HexString(): string {
		if(this.services.encryption && this.model.file?.md5){
			return "0x"+this.services.encryption.toHexString(this.model.file?.md5);
		}
		console.error("Encryption Service Not Set");
		return "";
	}

	modelToJson() : JsonValue {
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		return FileModel.toJson(this.model);
	}

	update(){

	}

	setModel(fileModel:FileModel) : FileViewModelImplemented {
		if(fileModel){
			this.model = fileModel;
		}
		return this;
	}

	updateLocalFromDb(json:any) : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			this.setModel(FileModel.fromJson(json, {ignoreUnknownFields: true}));
			if(this.model.file){
				if(this.model.file.data)this.fileIsSet = true;
				if(this.model.file.mimeType.includes("image")){
					this.isImage = true;
				}
				// If we dont have a dataURL, we know its on our "backend" server, and needs to be converted
				// and returned from that backend server
				if(!this.model.file.dataUrl && this.model.db && this.services.data ){
					this.model.file.dataUrl = this.services.data.getBackendUrl()+"/"+this.model.db.urn+"/attachment";
				}
			}
			resolve(true);
		})
	}

	private static PB_VERSION = 2;
	static GenerateDbId(dbPrefix:string) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return dbPrefix + ModelType.FILE_MODEL;
	}
	static GenerateDbKey(md5:Uint8Array) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		// convert md5 to base64url
		return DataService.getKeyPrefix(ModelType.FILE_MODEL,FileViewModelImplemented.PB_VERSION)+ UtilService.base64EncodeURL(md5);
	}
	public generateDbKey() : string {
		if(this.model.file?.md5 ){
			return FileViewModelImplemented.GenerateDbKey(this.model.file.md5);
		}
		return "";
	}
	static GenerateURN(dbPrefix:string, file:File) : string {
		if(file){
			var dbid = this.GenerateDbId(dbPrefix);
			return dbid+"/"+this.GenerateDbKey(file.md5);
		}
		console.error("generateURN: No UUID");
		return "";
	}
	public generateURN() : string {
		if(this.model && this.model.file && this.services.data?.getDbPrefix()){
			return FileViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.model.file);
		}
		return "";
	}
	updateDb(db?:DataBase){
		if(!this.model.db){
			this.model.db = DataBase.create();
		}
		if(db){
			try{
				DataBase.mergePartial(this.model.db, db);
			}
			catch(e){
				console.error("updateDb:error: ",e);
			}
		}
		if( this.model.db.createdMs && this.model.db.createdMs <= 0){
			this.model.db.createdMs = BigInt(Date.now());
		}
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.FILE_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}

	pouchAttachmentToUrl(pouchAttachment: {data:string, content_type:string } ): Promise<string> {
		return new Promise( (resolve, reject) => {
			if(!pouchAttachment.data){
				resolve("");
				return;
			}
			const byteCharacters = atob(pouchAttachment.data)
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			var type = pouchAttachment.content_type;
			const byteArray = new Uint8Array(byteNumbers)
			if(this.model.file){
				this.model.file.data = byteArray;
			}
			if(typeof window === 'undefined'){
				// TODO :: If node actually needs the uri here we can do it if need be. Blob doesn't exist here.
				resolve("");
			}
			else {
				const blob: Blob = new Blob([byteArray], { type, })
				var reader = new FileReader ();
				reader.readAsDataURL(blob)
				reader.onload = (_event) => {
					resolve(reader.result as string) // we know its a string due to calling readAsDataURL
				}
				reader.onerror = (error) => {
					reject(error);
				}
				reader.onabort = (error) => {
					reject(error);
				}
			}
		})
	}

	downloadAttachment(): Promise<boolean> {
		return new Promise( (resolve, reject) => {
			if(this.model.file && this.model.file?.data.length > 0){
				resolve(true);
			}
			else {
				if(this.services.pouch && this.model.file){
					if(!this.services.settings){
						reject("Settings Service Not Set");
						return;
					}
					if(!this.services.settings.SETTINGS.APP_ID){
						reject("App ID Not Set");
						return;
					}
					this.services.pouch.files.getFile(this.services.settings.SETTINGS.APP_ID, this.model.file.md5, true)
					.then((file)=>{
						resolve(true)
					}).catch( () =>{
						resolve(false)
					});
				}
				else{
					reject("No Pouch Service");
				}
			}
		})
	}

	refresh() {
		// Update our local UI
		// This is where you can handle any UI specific bindings from the model's data 
	}
	
	save() : Promise<boolean>{
		if(this.services.pouch){
			if(!this.services.settings){
				return Promise.reject("Settings Service Not Set")
			}
			if(!this.services.settings.SETTINGS.APP_ID){
				return Promise.reject("App ID Not Set")
			}
			return this.services.pouch.files.saveFile(this.services.settings.SETTINGS.APP_ID, this);
		}
		return Promise.reject("No Pouch Service");
	}

	getFileNameOnly(): string {
		if(this.model.file?.name){
			return this.model.file.name.split('.').slice(0, -1).join('.')
		}
		return "";
	}

	getFileExension(): string {
		if(this.model.file?.name){
			return "."+this.model.file.name.split('.').pop();
		}
		return "";
	}

	getAuthToken() : Promise<string> {
		return new Promise( async (resolve, reject) => {
			if(!this.services || !this.services.auth){
				console.error("bad services");
				reject("services")
				return;
			}
			this.services.auth.getAuth().then( (foundAuth) => {
				if(foundAuth?.method.oneofKind == "token"){
					resolve(foundAuth.method.token.token);
				}
				else {
					reject("no token found");
				}
			}).catch( (e)=> {
				console.error("Auth Error: ", e);
			})
		});
	}

	saveToDisk(fileName?:string, filePath?:string) : Promise<boolean>{
		if(!fileName){
			fileName = this.getFileNameOnly();
		}
		return new Promise( async(resolve, reject) => {
			await this.downloadAttachment();
			// check if in nodejs or browser  < -- thats all i added in to get below to work
			if(typeof window === 'undefined'){
				// nodejs
				if(!filePath){
					filePath = "."
				}
				var fs = require('fs');
				fs.writeFileSync(filePath, this.model.file?.data, (err) => {
					if(err){
						console.log(this.log_prefix + " :: saveToDisk :: ERROR :: " + err);
						this.services.uiHelper?.showError("Error saving file to disk", err);
						reject(false);
					}
					else{
						console.log(this.log_prefix + " :: saveToDisk :: SUCCESS");
						resolve(true);
					}
				});
			}
			else{
				// browser
				if(this.model.file?.dataUrl){
					if(this.services.auth?.hasAuth){
						var token = await this.getAuthToken();
						axios({
							url: this.model.file.dataUrl,
							method: 'GET',
							responseType: 'blob',
							headers: {
								'Authorization': 'Bearer '+token,
							}
						})
						.then(response => {
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', fileName+this.getFileExension());
							document.body.appendChild(link);
							link.click();
							resolve(true);
						})
						.catch(error => {
							// Handle error
							reject("Error downloading file");
						});
					}
					else {
						reject("No Auth");
					}
				}
				else if(this.model.file?.data){
					var a = document.createElement("a");
					var file = new Blob([this.model.file?.data], {type: this.model.file?.mimeType});
					a.href = URL.createObjectURL(file);
					a.download = fileName+this.getFileExension();
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					resolve(true);
				}
				else {
					resolve(false);
				}
			}
		});
	}

}	