import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TelemetryViewModelImplemented } from '../../viewmodels/Telemetry.vmi';

import Dygraph from "dygraphs";

@Component({
	selector: 'component-dygraph-sensor-view',
	templateUrl: './dygraph-sensor-view.component.html',
	styleUrls: ['./dygraph-sensor-view.component.scss'],
})
export class DyGraphSensorComponent implements OnInit, OnChanges {

	@Input()
	public inputTelemetries:TelemetryViewModelImplemented[] = [];

	@Output()
	public filtered = new EventEmitter<TelemetryViewModelImplemented[]>();

	// Date management

	private oldestMillis:number = 0;
	private newestMillis:number = Date.now();

	public allLabels: string[] = [];
	public selectedLabels: string[] = [];
	public dropdownSettings = {};

	private graph;

	constructor(
	) { }

	ngOnInit() {
		this.generateLabels(this.inputTelemetries);
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.inputTelemetries) {
			this.generateLabels(this.inputTelemetries);
			this.drawDyGraphSensor(this.inputTelemetries);
		}
	}
	public flattenJSON(obj={}, res = {}, extraKey = '') {
		var keys = Object.keys(obj);
		for (let index = 0; index < keys.length; index++) {
			const key = keys[index];
			if(typeof obj[key] !== 'object'){
				if(obj[key] != undefined)
				{
					res[extraKey + key] = obj[key];
				}
			}else{
				this.flattenJSON(obj[key], res, `${extraKey}${key}.`);
			};
		}
		return res;
	};
	public telemetryFlatTable :any = []
	formatFlatTelemetry(telemetries: TelemetryViewModelImplemented[]) {
		if(telemetries)
		{
			
			console.log("telemetries", telemetries);
			// console.log("tel lenght", telemetries.length);
			// this.telemetryFlatTable = [];
			// var data_object = telemetries.map((t) => 
			for (var index in telemetries) 
			{
				var final:any = {}
				var t=telemetries[index];
				var viewObject = t.toViewObject();
				var flatproto = this.flattenJSON(viewObject.protobuf);
				var flattened = this.flattenJSON(viewObject.protobuf.gatewayData);
				final.timeStamp = viewObject.timeStampFormatted;
				final.epochMiliseconds = viewObject.protobuf.sentMs;
				final = {...final, ...flatproto, ...flattened};
				if(final.epochMiliseconds)
				{
					this.telemetryFlatTable[final.epochMiliseconds] = final
				}
				else
				{
					this.telemetryFlatTable[final.receivedMs] = final
				}
			}
			console.log("telemetryFlatTable", this.telemetryFlatTable);
		}
	}

	onFilterChange(item: any) {
		console.log("selected",item);
		// this.selectedLabels.push(item);
		this.drawDyGraphSensor(this.inputTelemetries);
	}

	onItemSelect(item: any) {
		console.log("selected",item);
		// this.selectedLabels.push(item);
		this.drawDyGraphSensor(this.inputTelemetries);
	}
	  
	onSelectAll(items: any) {
		// this.selectedLabels = items;
		console.log("all items",items);
		this.drawDyGraphSensor(this.inputTelemetries);
	}

	generateLabels(telemetries?: TelemetryViewModelImplemented[]) {
		this.formatFlatTelemetry(this.inputTelemetries);


		for (let telemetry_key of Object.keys(this.telemetryFlatTable)){

			for (let key of Object.keys(this.telemetryFlatTable[telemetry_key]))
			{
				if(!isNaN(this.telemetryFlatTable[telemetry_key][key]))
				{
					this.allLabels.push(key);
				}
			};
			this.allLabels = [...this.allLabels]; //THIS IS IMPORTANT to trigger the change detection
			console.log("allLabels", this.allLabels);
			break;
		};
	}


	drawDyGraphSensor(telemetries?: TelemetryViewModelImplemented[]) {

		if(!telemetries)
		{
			return;
		}
		let arrData:any[] = [];
		
		
		this.formatFlatTelemetry(telemetries);
		console.log("Telemetry Flat Table:", this.telemetryFlatTable);
		
		
		var first = true;
		
		if(!this.telemetryFlatTable)
		{
			return;
		}
		
		console.log("selectedLabels",this.selectedLabels);

		
		let arrLabels = ["Date"];
		var index = 0;
		for (let telemetry_key of Object.keys(this.telemetryFlatTable)){
			arrData.push([])
			arrData[index].push(Number(telemetry_key));
			for (let key of Object.keys(this.telemetryFlatTable[telemetry_key]))
			{
				if(!isNaN(this.telemetryFlatTable[telemetry_key][key]))
				{
					if(this.selectedLabels.includes(key) )
					{
						// console.log("rendering key", key);
						if(first)
						{
							//TODO: NOTE a large assumption here that the first index has the same values as the rest
							arrLabels.push(key); //build the labels 
						}
						arrData[index].push(this.telemetryFlatTable[telemetry_key][key]);
					}
				}
			};
			
			index++;
			first = false;
		};
		console.log("arrData",arrData);
		console.log("arrLabels",arrLabels);
		
		let parent = this;

		this.graph = new Dygraph(document.getElementById("dyGraphSensor"), arrData, {
			legend: "always",
			animatedZooms: true,
			rollPeriod: 1,
			labels: arrLabels,
			drawAxis: true,
			drawGrid: false,
			
			axes: {
				x: {
					axisLabelFormatter: function (d, gran) {
					return new Date(d).toLocaleString();
					},
					valueFormatter: function (ms) {
					return new Date(ms).toLocaleString();
					},
					axisLabelFontSize: 11, 
				},
			},
		});

		setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 50); //superhack but works
	}

	emitFiltered(dateStartMillis:number, dateEndMillis:number){
		var filteredTelemetries = this.inputTelemetries.filter((telemetryVMI:TelemetryViewModelImplemented) => {
			return telemetryVMI.getBestEpoch() >= dateStartMillis && telemetryVMI.getBestEpoch() <= dateEndMillis;
		});
		this.filtered.emit(filteredTelemetries);
	}

}
