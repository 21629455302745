import { Component, OnInit, Input, } from '@angular/core';
import { ModalController, } from '@ionic/angular';

@Component({
	selector: 'app-select-bluetooth-web-modal',
	templateUrl: './select-bluetooth-web-modal.component.html',
	styleUrls: ['./select-bluetooth-web-modal.component.css'],
})
export class SelectBluetoothWebModalComponent implements OnInit {

	@Input() devices: any[] = [];

	constructor(private modalCtrl: ModalController) { }

	ngOnInit() {
		console.log("Devices in modal :: ", this.devices)
	}

	selectDevice(device:any) {
		console.log("Selected device :: ", device);
		this.modalCtrl.dismiss(device);
	}
}
