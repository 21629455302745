
export class WebrtcService {

	constructor() { }

	init() : Promise<any>
	{
		return new Promise( async (resolve, reject) =>  {
			resolve(true);
		});
	}

	generateOffer() : Promise<string>
	{
		return new Promise( async (resolve, reject) =>  {

			// copy stuff from fiddle
			// button.disabled = true;
			// dcInit(dc = pc.createDataChannel("chat"));
			// haveGum.then(() => pc.createOffer()).then(d => pc.setLocalDescription(d))
			// 	.catch(log);
			// pc.onicecandidate = e => {
			// 	if (e.candidate) return;
			// 	offer.value = pc.localDescription.sdp;
			// 	offer.select();
			// 	answer.placeholder = "Paste answer here";
			// };
			resolve("offer");
		});
	}

	acceptAnswer(answer:string) : Promise<boolean>	
	{
		return new Promise( async (resolve, reject) =>  {
			resolve(true);
		});
	}

	// message will probably be a chat mesage proto, not a string.
	startChat( messageCb:(message:string)=>void ) : Promise<boolean>	
	{
		return new Promise( async (resolve, reject) =>  {

			// set up listerns and call cb.
			// messageCb(test);

			resolve(true);
		});
	}

	sendMessage(message:string) : Promise<boolean>
	{
		return new Promise( async (resolve, reject) =>  {
			resolve(true);
		});
	}
}


// Dev notes:
// Original Fiddle that works with copy/paste of the offer/answer:
// https://jsfiddle.net/jib1/nnc13tw2/