
import { DeviceTypeGroups, DeviceTypeIds, } from "../../viewmodels/Device.vmi";
import { BluetoothBeaconViewModelImplemented } from "../../viewmodels/BluetoothBeacon.vmi";
import { BluetoothConnection, } from "../../services/bluetooth/ble.connetion"
import { CONNECTION_STATE, } from "../../services/constants"
import { AllServcies, } from "../../services/startup/startup.service";
import { DataBase, DeviceModel, ModelType } from "../../generated_proto/protobuf-ts/pb/v2/models";

import { BluetoothCommonInterface, BluetoothRequestOptions } from "../../services/bluetooth/bluetooth.service";
import { numberToUUID } from "@capacitor-community/bluetooth-le";

import { DataService, } from "../../services/data/data.service";
import { Device } from "../../generated_proto/protobuf-ts/pb/v2/entities";

export class PlBeaconBluetoothConnection extends BluetoothConnection {

	public heartBeatIntervalMs:number = 110000;

	constructor
	(
		services:AllServcies,
		bluetoothCommonInterface?:BluetoothCommonInterface,
	)
	{
		super(services, bluetoothCommonInterface);
		if(services){
			this.services = services;
		}
		if(bluetoothCommonInterface){
			this.bluetoothCommonInterface = bluetoothCommonInterface;
		}
		else {
			console.error("ERROR CREATING STEBLUETOOTH CONNECTION");
		}
	}

	async initalizeDevice() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.connectionState = CONNECTION_STATE.CONNECTING;
			if(!this.getIsConnected()){
				try{
					await this.connect().catch( (e) => reject(e));
				}
				catch(e){
					reject(e);
				}
			}
			resolve(true);
		});
	}

	getRequesetFilter() : BluetoothRequestOptions {
		var requestFilter = {
			namePrefix: "PL",
			services:[
			],
			optionalServices: [
				numberToUUID(0x1800),
				numberToUUID(0x180F),
				numberToUUID(0x1802),
			], // Note this is required to allow ANY usage of the service on web ble
		}
		// console.log("Name : 0x1800", numberToUUID(0x1800));
		// console.log("Battery : 0x180F", numberToUUID(0x180F));
		// console.log("requestFilter Information :", requestFilter);
		return requestFilter;
	}


	getName() : Promise<string> {
		return new Promise( async (resolve, reject) => {
			try{
				if(!this.getIsConnected()){await this.connect().catch( (e) => reject(e));}
				var name = await this.services.bluetooth?.readCharacteristic(this, 0x1800, 0x2A00);
				console.log("NAME : ", name);
				await this.services.util?.sleep(100)
				await this.disconnect();
				resolve("");
			}
			catch(e){
				reject(e);
			}
		});
	}

	ring() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			try{
				if(!this.getIsConnected()){await this.connect().catch( (e) => reject(e));}
				await this.services.bluetooth?.writeCharacteristic(this, 0x1802, 0x2A06, 0x02, true);
				await this.services.util?.sleep(100)
				resolve(true);
			}
			catch(e){
				reject(e);
			}
		});
	}

	stopRing() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			try{
				if(!this.getIsConnected()){await this.connect().catch( (e) => reject(e));}
				await this.services.bluetooth?.writeCharacteristic(this, 0x1802, 0x2A06, 0x00);
				await this.services.util?.sleep(100)
				await this.disconnect();
				resolve(true);
			}
			catch(e){
				reject(e);
			}
		});
	}

	async readMac() : Promise<Uint8Array> {
		return new Promise( async (resolve, reject) => {
			try{
				console.log("readMac")
				if(!this.getIsConnected()){
					console.log("Not connected, connecting")
					await this.connect().catch( (e) => reject(e));
				}
				console.log("Connected, reading mac")
				var mac = await this.services.bluetooth?.readCharacteristic(this, 0xFF00, 0xFF0C);
				await this.services.util?.sleep(100)
				if(mac){
					resolve(Uint8Array.from(mac));
				}
				else {
					reject("Battery Read Failure");
				}
			}
			catch(e){
				reject(e);
			}
		});
	}

	async readBattery() : Promise<number> {
		return new Promise( async (resolve, reject) => {
			try{
				if(!this.getIsConnected()){await this.connect().catch( (e) => reject(e));}
				var battery = await this.services.bluetooth?.readCharacteristic(this, 0x180F, 0x2A19);
				await this.services.util?.sleep(100)
				// await this.disconnect();
				if(battery){
					resolve(battery[0]);
				}
				else {
					reject("Battery Read Failure");
				}
			}
			catch(e){
				reject(e);
			}
		});
	}
}

export class  PlBeaconDeviceViewModelImplemented extends BluetoothBeaconViewModelImplemented {

	public bluetoothConnection:PlBeaconBluetoothConnection;
	public showTelemetryOffset:boolean = false;

	private hasDoneBluetoothPreconnect:boolean = false;

	constructor
	(
		services:AllServcies,
		bluetoothConnection?:BluetoothConnection,
		model?:DeviceModel,
	)
	{
		super(services, bluetoothConnection, model);
		if(this.model.device){
			this.model.device.deviceType = DeviceTypeIds.PL_BLUETOOTH_BEACON_V2;
			if(this.model.device.uuid){
				this.macAddress = DataService.UuidToMacAddress(this.model.device.uuid);
			}
		}
	}

	public static getStaticTitle() : string {
		return "PureLocate Beacon"
	}
	public getTitle():string{ // so we can access this w/o an instance i guess
		if(this.model.device?.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V1){
			return "PureLocate Tag";
		}
		else if(this.model.device?.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V2_1){
			return "PureLocate Beacon v2.1";
		}
		else{
			return "PureLocate Beacon v2.0";
		}
	}

	public thumbnailImagePath:string = "assets/app/pl/pltagv2.webp";
	public getThumbnailImagePath() : string{
		if(this.model.device?.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V1){
			return "assets/app/pl/pltagv1.webp";
		}
		return this.thumbnailImagePath;
	}

	public getPagePath() : string {
		console.log("PlBeaconDeviceViewModelImplemented :: getPagePath : ", this.model.device?.uuid);
		if(this.model.device){
			console.log("Going to page : ", this.model.device.uuid)
			if(this.isLocal && this.bluetoothConnection && this.bluetoothConnection.bluetoothCommonInterface.identifier){
				var encodedStringBtoA = btoa(this.bluetoothConnection.bluetoothCommonInterface.identifier).replace('+', '-').replace('/', '_').replace(/=+$/, '');;
				return "vendors/pl/pl-beacon/" + this.model.device.uuid+"/" + encodedStringBtoA;
			}
			return "vendors/pl/pl-beacon/" + this.model.device.uuid;
		}
		return "vendors/pl/pl-beacon/" + 0;
	}

	public getDisplayUuid() : string {
		if(this.model.device?.uuid){
			return "MAC: "+DataService.UuidToMacAddressFormattedString(this.model.device.uuid);
		}
		return "MAC: n/a";
	}

	public getDeviceTypeGroups() : DeviceTypeGroups {
		var groups:DeviceTypeGroups = [];
		if(this.services.settings?.SETTINGS?.APP_ID == 2){
			groups.push({
				deviceTypeId: DeviceTypeIds.PL,
				formattedName: "PureLocate",
			});
		}
		groups.push({
			deviceTypeId: DeviceTypeIds.PL_BLUETOOTH_BEACON,
			formattedName: "Any Beacon",
		});
		if(this.model.device?.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V1){
			groups.push({
				deviceTypeId: DeviceTypeIds.PL_BLUETOOTH_BEACON_V1,
				formattedName: "Tag",
			});
		}
		if(this.model.device?.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V2){
			groups.push( {
				deviceTypeId: DeviceTypeIds.PL_BLUETOOTH_BEACON_V2,
				formattedName: "Audio v2.0",
			});
		}
		if(this.model.device?.deviceType == DeviceTypeIds.PL_BLUETOOTH_BEACON_V2_1){
			groups.push( {
				deviceTypeId: DeviceTypeIds.PL_BLUETOOTH_BEACON_V2_1,
				formattedName: "Audio v2.1",
			});
		}
		return groups;
	}

	async save(app_id?:number) : Promise<boolean>{
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}

		if(this.isTemporary && this.isLocal && this.bluetoothConnection && !this.hasDoneBluetoothPreconnect){
			console.log("Doing the preconnect to get the full mac address");
			var mac:Uint8Array = await this.bluetoothConnection.readMac();
			if(mac && this.model.device){
				console.log("Read mac address over bluetooth: ", mac);
				this.model.device.uuid = DataService.MacAddressToUuid(mac);
				console.log("Read mac address over bluetooth formatted: ", DataService.UuidToMacAddressFormattedString(this.model.device.uuid));
				this.isTemporary = false;
			}
			else {
				console.error("Failed to read mac address");
			}
		}

		if(this.services.data) return this.services.data.saveDevice(app_id||2, this);
		if(this.services.logger) this.services.logger.error(this.loggerOptions, "save: DataService missing");
		return Promise.reject(this.loggerOptions.prefix+":DataService missing");
	}

}