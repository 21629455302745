import { AllSensorVMI } from '../../viewmodels/AllSensors.vmi';
import { SensorViewModelImplemented, } from '../../viewmodels/sensor.vmi';
import { SerialPortConnection, } from "./serialport.connection"

import { Sensor, SensorType, } from "../../generated_proto/google/app/model/v1/data_pb"
import { LoggerOptions } from "../logger/logger.service"

import { AllServcies } from '../startup/startup.service';


////////////////////////////////////////////////////////////////////////////////////////////////////
// Vendor Specific
////////////////////////////////////////////////////////////////////////////////////////////////////

// STL
// import { OpenCommsConstants } from '../../vendors/stl/parsers/opencoms.parser';
// import { SensorConstants } from "../../vendors/stl/stl-constants"
// import { ER2SensorViewModelImplemented } from "../../vendors/stl/viewmodels/EageRaySensor.vmi"
// import { SlingShotSensorViewModelImplemented } from "../../vendors/stl/viewmodels/SlingShotSensor.vmi"
// import { ER2RaditionSensorViewModelImplemented } from '../../vendors/stl/viewmodels/ER2RadiationSensor.vmi';
// import { StlComportHelper,} from "../../vendors/stl/helpers/stl-comport.helper"

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface ComPortProfile {

}

export class ComportService {

	private loggerOptions:LoggerOptions = {
		prefix:"ComportService",
		allOn:true,
		verboseOn:false,
		debugOn:true,
	};

	private findDeviceType(comPort) : AllSensorVMI {
		var newSensorVMI:AllSensorVMI;

		// TODO :: Handle this more gneerically

		newSensorVMI = new SensorViewModelImplemented(this.services);
		var newSensor = new Sensor();
		newSensor.setType(SensorType.SENSOR_UNKNOWN)
		newSensorVMI.setSensor(newSensor);
		return newSensorVMI;

	}
	////////////////////////////////////////////////////////////////////////////////////////////////////

	private Serialport:any = null;
	private FS:any = null;

	constructor
	(
		// private stlComportHelper:StlComportHelper
	) 
	{
	
	}
		////////////////////////////////////////////////////////////////////////////////////////////////////
	// Vendor Specific -- add or delete helpers when adding/removing from vendor folder
	////////////////////////////////////////////////////////////////////////////////////////////////////
	init(allServices:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
				if(!this.services.platform){
					reject({code:0, message:"Platform Not Given"});
					return;
				}
				if (this.services.platform.is('electron')) {
					this.Serialport = (<any>window).require('serialport');
					this.FS =  (<any>window).require('fs');
				}
				resolve(true);
			}
		});
	}
	

	

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public async getScan( sensorType_input?:SensorType ): Promise<AllSensorVMI[]> {
		await this.services.startup?.ready();
		if(!this.services.platform || !this.services.logger){
			return Promise.reject({code:0, message:"Platform Not Given"});
		}
		if( this.services.platform.is("cordova")){
			// Android and iOS
			this.services.logger.debug(this.loggerOptions, "Calling cordova scan");
			return Promise.reject(this.loggerOptions.prefix+"Cordova not supproted yet")
		}
		else if(this.services.platform.is("electron")) { 
			return new Promise(async (resolve, reject) => {
				if (this.Serialport == null) {
					reject("Error with the serial port library");
					return;
				}
				var sensorsVMI: SensorViewModelImplemented[] = [];

				await this.Serialport.list().then((ports, err) => {
					if (err) {
						if(this.services.logger){
							reject(this.services.logger.error(this.loggerOptions, err.message, err))
						}
						else{
							reject(err);
						}
						return;
					}
					if (ports.length === 0) {
						if(this.services.logger){
							reject(this.services.logger.error(this.loggerOptions, "No ports discovered"))
						}
						else{
							reject("No ports discovered");
						}
						return;
					}
					for (let index = 0; index < ports.length; index++) {
						const comPort = ports[index];
						if(!this.services.logger){
							reject("Logger not set");
							return;
						}
						if (comPort.path.includes("/dev/ttyS")) {
							this.services.logger.verbose(this.loggerOptions, "Skipping", comPort.path);
							continue;
						}
						
						this.services.logger.debug(this.loggerOptions, "Com port info is", comPort);
						// var newSensorVMI:AllSensorVMI = this.findDeviceType(comPort);
						// newSensorVMI.getSensor().setIsLocal(true);
						// newSensorVMI.getSensor().setCreatedMs(Date.now());
						// newSensorVMI.serialPortConnection = new SerialPortConnection(this.services);
						// newSensorVMI.serialPortConnection.setSensorType(newSensorVMI.getSensor().getType());
						// newSensorVMI.serialPortConnection.setContext(comPort);
						// newSensorVMI.serialPortConnection.path = comPort.path;
						// newSensorVMI.serialPortConnection.pnpId = comPort.pnpId;
						// sensorsVMI.push(newSensorVMI);
					}
				})
	
				if(sensorType_input){
					// sensorsVMI = sensorsVMI.filter( (sensorVMI:SensorViewModelImplemented) => sensorVMI.getSensor().getType() == sensorType_input)
				}
				resolve(sensorsVMI);
			});
		}
		else {
			// Web or other left
			return new Promise( (resolve, reject) => {
				var sensorsVMI: SensorViewModelImplemented[] = [];
				var newSensorVMI = new SensorViewModelImplemented(this.services);
					var newSensor = new Sensor();
				var context = {}
				newSensor.setIsLocal(true);
				newSensor.setCreatedMs(Date.now());
				newSensorVMI.serialPortConnection = new SerialPortConnection(this.services);
				newSensorVMI.serialPortConnection.setContext(context);
				newSensorVMI.serialPortConnection.path = "/virtual/path";
				newSensorVMI.setSensor(newSensor);
				sensorsVMI.push(newSensorVMI);
				resolve(sensorsVMI);
			})
		}
	}

	private processData( serialPortConnection: SerialPortConnection, data){

		for (let byte of data) 
		{

			// TODO :: Make this parser more generic. Perhaps have the vendor class register its parsers to be called from this service?
			
		}
	}

	connect(serialPortConnection: SerialPortConnection, baudrate:number, selectedFile:any=null) : Promise<boolean>{
		if(!this.services.platform || !this.services.logger){
			return Promise.reject({code:0, message:"Platform Not Given"});
		}
		if(this.services.platform.is("cordova")){
			// Android and iOS
			this.services.logger.debug(this.loggerOptions, "Calling cordova scan");
			return Promise.reject("Cordova not supproted yet")
		}
		else if(this.services.platform.is("electron")) {
			// Desktop Packaged App
			return new Promise( (resolve, reject) => {
				if(!this.services.platform || !this.services.logger){
					reject({code:0, message:"Platform Not Given"});
					return;
				}
				if(serialPortConnection.context == null){
					reject(this.services.logger.error(this.loggerOptions, "Bad serialport context"));
					return;
				}

				// TODO : this should be more generic. init by the underlying parser that needs it
				// serialPortConnection.context.stl_state_state = { state: OpenCommsConstants.ParserStates.STATE_SYNC, valid: 0, index: 0, length: 0, headercrc: 0, payloadcrc: 0xffff, currentpayloadcrc: 0, payload: null, payloadDataView: null };

				if(selectedFile){
					// handle data
					this.services.logger.warn(this.loggerOptions, "reading from file", selectedFile);
					serialPortConnection.setIsConnected(true);
					resolve(true)
					if(this.FS){
						const readable = this.FS.createReadStream(selectedFile.path);
						// Instructions for reading data
						readable.on('readable', () => {
							let data;
							while (null !== (data = readable.read())) {
								if(this.services.logger){
									this.services.logger.debug(this.loggerOptions, "Got File Data : ", data);
								}
								this.processData(serialPortConnection, data);
							}
						});
					}
				}
				else {
					if(serialPortConnection.context.serial != null){
						serialPortConnection.setIsConnected(serialPortConnection.context.serial.isOpen)
					}
					if(!serialPortConnection.getIsConnected()){
						// console.log("Asking : ", serialPortConnection.context.path, " : ", baudrate, " : To open ::");
						serialPortConnection.context.serial = new this.Serialport(serialPortConnection.context.path, { autoOpen: true , baudRate: baudrate});
						serialPortConnection.context.serial.open(async () => {
							if(this.services.logger){
								this.services.logger.debug(this.loggerOptions, "[event]:"+serialPortConnection.context.path+" : Open");
							}
							serialPortConnection.setIsConnected(true);
							resolve(true);
						});	
		
						serialPortConnection.context.serial.on('data', (data) => {
							if(this.services.logger){
								this.services.logger.debug(this.loggerOptions, "[event]:"+serialPortConnection.context.path+" : Data", data);
							}
							this.processData(serialPortConnection, data);
						})
		
						serialPortConnection.context.serial.on('error', function(err) {
							console.log('Error: ', err.message)
							this.logger.error(this.loggerOptions, "[event]:"+serialPortConnection.context.path+" : Error : "+ err.message, err);
						})
					}
					else {
						if(this.services.logger){
							this.services.logger.debug(this.loggerOptions, "Buadrate is : ", serialPortConnection.context.serial.baudRate);
						}
						if(serialPortConnection.context.serial.baudRate != baudrate){
							if(this.services.logger){
								this.services.logger.debug(this.loggerOptions, "Changing baudrate to : ", baudrate);
							}
							serialPortConnection.context.serial.update({baudRate:baudrate}, err => {
								if(err){
									if(this.services.logger){
										this.services.logger.error(this.loggerOptions,  "Update Baud", err);
									}
									reject(err);
								}
								else {
									resolve(true);
								}
							});
						}
					}
				}
			});
		}
		else {
			// Web or other left
			return new Promise( (resolve, reject) => {
				if(selectedFile){
					var reader = new FileReader();
					reader.readAsBinaryString(selectedFile.file);
					reader.onload = ()=> {
						if(this.services.logger){
							this.services.logger.debug(this.loggerOptions, "Full file from web :",reader.result);
						}
					}
				}
				serialPortConnection.setIsConnected(true)
				resolve(true);
			})
		}
	}

	disconnect(serialPortConnection: SerialPortConnection ) : Promise<boolean> {
		if(!this.services.platform || !this.services.logger){
			return Promise.reject("Platform or Logger not set");
		}
		if( this.services.platform.is("cordova")){
			// Android and iOS
			this.services.logger.debug(this.loggerOptions, "Calling disconnect");
			return Promise.reject(this.services.logger?.error(this.loggerOptions, "Cordova not supproted yet") || "Cordova not supproted yet")
		}
		else if(this.services.platform.is("electron")) {
			// desktop
			return new Promise( (resolve, reject) => {
				if(serialPortConnection.context == null){
					reject(this.services.logger?.error(this.loggerOptions, "Bad serialport context") || "Bad serialport context");
					return;
				}
				serialPortConnection.setIsConnected(serialPortConnection.context.serial.isOpen)
				if(serialPortConnection.context.serial.isOpen){
					serialPortConnection.context.serial.close(err => {
						if(err){
							serialPortConnection.setIsConnected(serialPortConnection.context.serial.isOpen)
							reject(this.services.logger?.error(this.loggerOptions, "close error", err) || "close error "+err);
						}
						else {
							this.services.logger?.debug(this.loggerOptions, "Closed : ", serialPortConnection.context.path);
							resolve(true);
						}
					});
				}
				else{
					resolve(true);
				}
			});
		}
		else {
			// web
			return new Promise( (resolve,reject) => {
				serialPortConnection.setIsConnected(false)
				resolve(true);
			});
		}
	};
	
	writeByte( serialPortConnection: SerialPortConnection, hex:number ) : Promise<boolean>{
		if(!this.services.platform){
			return Promise.reject("Platform not set");
		}
		if( this.services.platform.is("cordova")){
			// Android and iOS

			this.services.logger?.debug(this.loggerOptions, "writeByte: " + hex);
			return new Promise((resove,reject)=>{reject("Cordova not supproted yet")});
		}
		else if(this.services.platform.is("electron")) {
			// desktop
			return new Promise( (resolve, reject) => {
				if(!serialPortConnection.getIsConnected()){
					reject(this.services.logger?.error(this.loggerOptions, "Com port not connected") || "Com port not connected");
					return;
				}
				if(serialPortConnection.context.serial){
					this.services.logger?.verbose(this.loggerOptions, "Writing : "+ hex + " : To : " + serialPortConnection.context.serial);
					serialPortConnection.context.serial.write(Buffer.from([hex]))
				}
				else {
					this.services.logger?.warn(this.loggerOptions, "No context.serial (ok, if opened from file)");
					this.services.logger?.verbose(this.loggerOptions, "Writing : "+ hex + " : To : " + serialPortConnection.context.serial);
				}
				resolve(true);
			});
		}
		else {
			// web
			return new Promise( (resolve,reject) => {
				this.services.logger?.verbose(this.loggerOptions, "Writing : "+ hex + " : To : " + serialPortConnection.context.serial);
				resolve(true);
			});
		}
	}
}
