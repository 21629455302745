import { DeviceTypeIds } from "../../app/generated_proto/protobuf-ts/pb/system";
import { DeviceModel } from "../generated_proto/protobuf-ts/pb/v2/models";
import { BluetoothConnection } from "../services/bluetooth/ble.connetion";
import { DataService } from "../services/data/data.service";
import { AllServcies } from "../services/startup/startup.service";
import { DeviceViewModelImplemented, } from "./Device.vmi"


export class BluetoothBeaconViewModelImplemented extends DeviceViewModelImplemented {

	public macAddress: Uint8Array = new Uint8Array(6); // formatted from the UUID

	constructor
	(
		services:AllServcies,
		bluetoothConnection?:BluetoothConnection,
		model?:DeviceModel,
	)
	{
		super(services, bluetoothConnection, model);
		if(this.model.device){
			this.model.device.deviceType = DeviceTypeIds.GENERIC_BLUETOOTH_BEACON;
		}
		this.update();
	}

	update(){
		super.update();
		if(this.model.device){
			if(this.model.device.uuid){
				this.macAddress = DataService.UuidToMacAddress(this.model.device.uuid);
			}
		}
	}

	public addMacAddressString(macAddressString:string):void{
		// remove any ":" from the mac address
		let macAddressArray = macAddressString.replace(":", "").replace(" ", "").split("");
		for(let i = 0; i < 6; i++){
			let hexString = macAddressArray[i*2] + macAddressArray[i*2+1];
			this.macAddress[i] = parseInt(hexString, 16);
		}
		if(this.model.device){
			this.model.device.uuid = DataService.MacAddressToUuid(this.macAddress);
			this.macAddress = DataService.UuidToMacAddress(this.model.device.uuid);
		}
	}

	public macAddressAsFormattedString():string{
		return DataService.MacAddressToUuidFormattedString(this.macAddress);
	}


}