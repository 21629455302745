// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/v2/models.proto" (package "pb.v2", syntax proto3)
// tslint:disable
//
// Interface to storage for data retreval and viewing.
//
//
// These interfaces are challenging for two reaons
// 1. They are interpreted by the client. Sometimes that client is syncing 
//    or requesting from the db directly. IE:  Pouch Client syncs DeviceModel and THEN makes its own determination on when to sync
//    additional data depending on the clients needs and desired. While "isOnline" is calculated based off its telemetry syncing updates.
// 2. The other interpretation, is by agents on behalf of the client. IE:
//    The grpc client is requesting data from the db
//    - This situation, the client is EXPECTING all fields to be filled in. isOnline already precalculated.
//    - The client is EXPECTING the data to be filled in and all "dehydrated" fields are "hydrated" and ready for immediate use.
//
//
// The Reqeest / Response protos will wrap the Models and provide options on how to "hydrate" the Models such as time filters, etc.
//
//
// Messages w/in Models may be stored in different ways.
// For example, the server may store the messages in DeviceModel,
// as 
// {
// uint64 modified_by = 2;
// PersonModel created_by_id;
// }
//
//
// The filling out of many of these things are either done at runtime or cached at the VMI level.
// IE: isOnline => this is calculated but is stored in teh DeviceVMI message to be presented to the front
// end but isnt stored in backend
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { GeoNode } from "./entities";
import { AppManagement } from "./server";
import { EventLog } from "./data";
import { Person } from "./entities";
import { ChatMessage } from "./entities";
import { Channel } from "./entities";
import { Webhook } from "./entities";
import { DeviceGroup } from "./entities";
import { FirmwareFile } from "./entities";
import { File } from "./entities";
import { Asset } from "./entities";
import { Position } from "./entities";
import { Onboarding } from "./entities";
import { Device } from "./entities";
import { Telemetry } from "./data";
/**
 * @generated from protobuf message pb.v2.DatabaseSaveSettings
 */
export interface DatabaseSaveSettings {
    /**
     * @generated from protobuf field: optional pb.v2.DatabaseSaveBeahvior save_behavior = 1;
     */
    saveBehavior?: DatabaseSaveBeahvior; // how to save
    /**
     * @generated from protobuf field: optional uint32 save_frequncy_ms = 2;
     */
    saveFrequncyMs?: number; // how often to save
    /**
     * @generated from protobuf field: optional uint32 max_save_epoch_ms = 3;
     */
    maxSaveEpochMs?: number; // max period of save time
    /**
     * @generated from protobuf field: optional uint32 max_save_count = 4;
     */
    maxSaveCount?: number; // how many to save before we delete the oldest
}
/**
 * These fields are not all required. They are a common set of all data neede for any models to be retreived from the db.
 * This allows straight serializtion, and easy indexing on known fields. If an index is added. it will likely also reside in the Database message.
 * NOTE:: Optionals throw warnings from protoc -> but force the "memeber?" in interfaces to be optional in TS.
 *
 * @generated from protobuf message pb.v2.DataBase
 */
export interface DataBase {
    /**
     * @generated from protobuf field: optional uint64 uid = 1;
     */
    uid?: bigint; // unique id.
    /**
     * @generated from protobuf field: optional string urn = 2;
     */
    urn?: string; // generateURN() - Pouchdb DATABASE identifier. combined with uid, gives primary key: IE: urn+uid
    /**
     * @generated from protobuf field: repeated string read_groups = 3;
     */
    readGroups: string[]; // groups/users who can read this data
    /**
     * @generated from protobuf field: repeated string write_groups = 4;
     */
    writeGroups: string[]; // groups/users who can write this data
    /**
     * @generated from protobuf field: optional uint64 model_type = 5;
     */
    modelType?: bigint; // model type, from enum to int. should be generated by us, so 1,2,3,4,5
    /**
     * @generated from protobuf field: optional uint64 model_version = 6;
     */
    modelVersion?: bigint; // model type, from enum to int. should be generated by us, so 1,2,3,4,5
    /**
     * @generated from protobuf field: optional fixed64 geohash = 7;
     */
    geohash?: bigint; // geohash of the location -> primary GPS to be used for searching in the db. the "best" position.
    /**
     * @generated from protobuf field: optional fixed64 created_ms = 8;
     */
    createdMs?: bigint; // When the resouce was craetd -> File (local os time), telemetery, local device time, or, time created at server for those records made at server
    /**
     * @generated from protobuf field: optional fixed64 received_ms = 9;
     */
    receivedMs?: bigint; // Time when received. So, if device creates and caches, this is when the cache is delivered
    /**
     * @generated from protobuf field: optional fixed64 edited_ms = 10;
     */
    editedMs?: bigint; // Time when user edits
    /**
     * @generated from protobuf field: optional fixed64 deleted_ms = 11;
     */
    deletedMs?: bigint; // Time Deleted by user or service
    /**
     * @generated from protobuf field: optional fixed64 updated_ms = 12;
     */
    updatedMs?: bigint; // Time updated by service
    /**
     * @generated from protobuf field: optional fixed64 latest_ms = 13;
     */
    latestMs?: bigint; // COMMON ANY TIME THIS IS UPDATED -> Easiest to check on a db search
    /**
     * @generated from protobuf field: optional bytes md5 = 14;
     */
    md5?: Uint8Array; // md5 of the data.
    /**
     * @generated from protobuf field: optional string static_url = 15;
     */
    staticUrl?: string; // url to the static data.
    /**
     * @generated from protobuf field: optional uint64 workspace_uid = 16;
     */
    workspaceUid?: bigint; // should be generated by us, so 1,2,3,4,5
    /**
     * @generated from protobuf field: optional uint64 channel_uid = 17;
     */
    channelUid?: bigint; // should be generated by us, or perhaps user created. so 1,2,3,4,5  or if string -> SHA1(cropped to 32 bits)
    /**
     * @generated from protobuf field: optional fixed64 uuid = 18;
     */
    uuid?: bigint; // from device, so imei  or if MAC+something else to make it a uuid
    /**
     * @generated from protobuf field: optional pb.v2.SyncEventType sync_status = 19;
     */
    syncStatus?: SyncEventType; // Used for local storage, managing when vm is synced up
    /**
     * @generated from protobuf field: optional pb.v2.DatabaseMessageSaveType save_type = 20;
     */
    saveType?: DatabaseMessageSaveType; // Is this message saved to the database or not
}
// Hold information about they sync sition of our database.
// The client can storey this information and use it to determine if it needs to sync.
// IE: The db is the same db as the device

/**
 * @generated from protobuf message pb.v2.DataBaseInformation
 */
export interface DataBaseInformation {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase; // the database we are syncing
    /**
     * @generated from protobuf field: optional pb.v2.SyncEventType sync_status = 2;
     */
    syncStatus?: SyncEventType; // Used for local storage, managing when vm is synced up
    /**
     * @generated from protobuf field: optional uint64 last_update_ms = 3;
     */
    lastUpdateMs?: bigint; // last time we synced
    /**
     * @generated from protobuf field: optional uint32 total_docs = 4;
     */
    totalDocs?: number; // total number of docs in the db
    /**
     * @generated from protobuf field: optional uint32 synced_docs = 5;
     */
    syncedDocs?: number; // total number of docs in the db
    /**
     * @generated from protobuf field: repeated pb.v2.DataBase doc_collection = 6;
     */
    docCollection: DataBase[]; // client must implement a getTelemetryCollection() from db.
    /**
     * / ONLY TEMPORARY : Nice to have for debug mode
     *
     * @generated from protobuf field: optional string error_status = 7;
     */
    errorStatus?: string; // error status if any
    /**
     * @generated from protobuf field: repeated pb.v2.DataBaseInformation.SyncEvent sync_events = 8;
     */
    syncEvents: DataBaseInformation_SyncEvent[]; // events that have occured.
}
/**
 * @generated from protobuf message pb.v2.DataBaseInformation.SyncEvent
 */
export interface DataBaseInformation_SyncEvent {
    /**
     * @generated from protobuf field: optional pb.v2.SyncEventType event_type = 1;
     */
    eventType?: SyncEventType; // is the db online?
    /**
     * @generated from protobuf field: optional string event_data = 2;
     */
    eventData?: string; // error status if any
}
/**
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * Serilized only used in the DB that can store binary based data. Allows us to serialze all to the same
 * format.
 *
 * What have this as a seperate message? -> so we can serialize and deserialize to find the schema type ONLY
 * Some databases (IE: Redis) Are binary safe. Meaning we can searilzie and deserialize the message as is, with the type
 * we can know what to do with bytes -> deserialize to the correct schema.
 * Looking them up by urn.
 *
 * @generated from protobuf message pb.v2.SerializedModel
 */
export interface SerializedModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: bytes serialized = 2;
     */
    serialized: Uint8Array;
}
/**
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * Telemetry Models
 *
 * @generated from protobuf message pb.v2.TelemetryModel
 */
export interface TelemetryModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.Telemetry telemetry = 2;
     */
    telemetry?: Telemetry; // Sepcial case -> actually stores in db as a message in sub message form.
    /**
     * @generated from protobuf field: string reported_by_device_urn = 3;
     */
    reportedByDeviceUrn: string; // comes through gateway or seen by. IE: Lora gateway for Industrial, or BluetoothBeacon seen by station
}
/**
 * Remember -> View models are implemented on the clients or the rpc service. They are not stored in the db.
 *
 * @generated from protobuf message pb.v2.TelemetryViewModel
 */
export interface TelemetryViewModel {
    /**
     * @generated from protobuf field: pb.v2.TelemetryModel model = 1;
     */
    model?: TelemetryModel;
    /**
     * Any other derrived data needed to present the data to the user.
     *
     * @generated from protobuf field: bool hasPosition = 2;
     */
    hasPosition: boolean;
    /**
     * @generated from protobuf field: bytes color_rgb = 3;
     */
    colorRgb: Uint8Array;
    /**
     * @generated from protobuf field: string currentVersionFormatted = 4;
     */
    currentVersionFormatted: string;
}
/**
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * Device Models
 *
 * @generated from protobuf message pb.v2.DeviceModel
 */
export interface DeviceModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.Device device = 2;
     */
    device?: Device;
    /**
     * @generated from protobuf field: optional fixed64 last_connect_ms = 3;
     */
    lastConnectMs?: bigint;
    /**
     * @generated from protobuf field: optional pb.v2.DatabaseSaveSettings save_settings = 4;
     */
    saveSettings?: DatabaseSaveSettings;
    /**
     * @generated from protobuf field: pb.v2.Telemetry derived_telemetry = 5;
     */
    derivedTelemetry?: Telemetry;
    /**
     * @generated from protobuf field: pb.v2.Onboarding onboarding = 6;
     */
    onboarding?: Onboarding;
    /**
     * @generated from protobuf field: uint32 total_telemetry_count = 7;
     */
    totalTelemetryCount: number;
    /**
     * @generated from protobuf field: uint64 newest_telemetry_ms = 8;
     */
    newestTelemetryMs: bigint;
    /**
     * @generated from protobuf field: uint64 oldest_telemetry_ms = 9;
     */
    oldestTelemetryMs: bigint;
    /**
     * @generated from protobuf field: string device_group_urn = 10;
     */
    deviceGroupUrn: string;
    /**
     * @generated from protobuf field: string reported_by_device_urn = 11;
     */
    reportedByDeviceUrn: string; // comes through gateway or seen by. IE: Lora gateway for Industrial, or BluetoothBeacon seen by station
    /**
     * @generated from protobuf field: repeated pb.v2.Position gateway_positions = 12;
     */
    gatewayPositions: Position[]; // O(1) from db. Use this to calculate and then elimnate "recently seen" to build a calculated from
}
/**
 * Remember -> View models are implemented on the clients or the rpc service. They are not stored in the db.
 *
 * @generated from protobuf message pb.v2.DeviceViewModel
 */
export interface DeviceViewModel {
    /**
     * @generated from protobuf field: pb.v2.DeviceModel model = 1;
     */
    model?: DeviceModel;
    /**
     * Inferred. View Model will hold collection of TelemetryViewModel
     *
     * @generated from protobuf field: pb.v2.DataBase telemetry_db = 2;
     */
    telemetryDb?: DataBase; // client must implement a getTelemetryCollection() from db.
    /**
     * @generated from protobuf field: repeated pb.v2.DataBase raw_collection = 3;
     */
    rawCollection: DataBase[]; // client must implement a getTelemetryCollection() from db.
    /**
     * @generated from protobuf field: bool isOnline = 4;
     */
    isOnline: boolean;
    /**
     * @generated from protobuf field: bool isLocal = 5;
     */
    isLocal: boolean;
    /**
     * @generated from protobuf field: bool hasPosition = 6;
     */
    hasPosition: boolean;
    /**
     * @generated from protobuf field: bytes color_rgb = 7;
     */
    colorRgb: Uint8Array;
}
/**
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * Asset Models
 *
 * @generated from protobuf message pb.v2.AssetModel
 */
export interface AssetModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.Asset asset = 2;
     */
    asset?: Asset;
}
/**
 * @generated from protobuf message pb.v2.AssetViewModel
 */
export interface AssetViewModel {
    /**
     * @generated from protobuf field: pb.v2.AssetModel model = 1;
     */
    model?: AssetModel;
    /**
     * @generated from protobuf field: bool isOnline = 4;
     */
    isOnline: boolean;
    /**
     * @generated from protobuf field: bool isLocal = 5;
     */
    isLocal: boolean;
    /**
     * @generated from protobuf field: bool hasPosition = 6;
     */
    hasPosition: boolean;
}
/**
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * Device Managment Models
 *
 * @generated from protobuf message pb.v2.FileModel
 */
export interface FileModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.File file = 2;
     */
    file?: File;
}
/**
 * @generated from protobuf message pb.v2.FileViewModel
 */
export interface FileViewModel {
    /**
     * @generated from protobuf field: pb.v2.FileModel model = 1;
     */
    model?: FileModel;
}
/**
 * Firmware Models
 *
 * @generated from protobuf message pb.v2.FirmwareFileModel
 */
export interface FirmwareFileModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.FirmwareFile firmware = 2;
     */
    firmware?: FirmwareFile;
    /**
     * @generated from protobuf field: string file_urn = 3;
     */
    fileUrn: string;
}
/**
 * @generated from protobuf message pb.v2.FirmwareFileViewModel
 */
export interface FirmwareFileViewModel {
    /**
     * @generated from protobuf field: pb.v2.FirmwareFileModel model = 1;
     */
    model?: FirmwareFileModel;
    /**
     * @generated from protobuf field: bool checked_if_in_use = 2;
     */
    checkedIfInUse: boolean;
    /**
     * @generated from protobuf field: repeated string in_use_group_urns = 3;
     */
    inUseGroupUrns: string[];
}
/**
 * Device Groups
 *
 * @generated from protobuf message pb.v2.DeviceGroupModel
 */
export interface DeviceGroupModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.DeviceGroup device_group = 2;
     */
    deviceGroup?: DeviceGroup;
    /**
     * @generated from protobuf field: string photo_urn = 3;
     */
    photoUrn: string;
}
/**
 * @generated from protobuf message pb.v2.DeviceGroupViewModel
 */
export interface DeviceGroupViewModel {
    /**
     * @generated from protobuf field: pb.v2.DeviceGroupModel model = 1;
     */
    model?: DeviceGroupModel;
    /**
     * Inferred. View Model will hold collection of DeviceViewModel
     *
     * @generated from protobuf field: string photo_url = 2;
     */
    photoUrl: string;
}
/**
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * App Models
 *
 * @generated from protobuf message pb.v2.WebhookModel
 */
export interface WebhookModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.Webhook webhook = 2;
     */
    webhook?: Webhook;
}
/**
 * @generated from protobuf message pb.v2.WebhookViewModel
 */
export interface WebhookViewModel {
    /**
     * @generated from protobuf field: pb.v2.WebhookModel model = 1;
     */
    model?: WebhookModel;
}
/**
 * @generated from protobuf message pb.v2.ChannelModel
 */
export interface ChannelModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.Channel channel = 2;
     */
    channel?: Channel;
    /**
     * @generated from protobuf field: optional fixed64 last_connect_ms = 3;
     */
    lastConnectMs?: bigint;
    /**
     * @generated from protobuf field: repeated pb.v2.DataBase admins = 4;
     */
    admins: DataBase[]; // client must implement a getTelemetryCollection() from db.
    /**
     * @generated from protobuf field: repeated pb.v2.DataBase members = 5;
     */
    members: DataBase[]; // client must implement a getTelemetryCollection() from db.
}
/**
 * @generated from protobuf message pb.v2.ChannelViewModel
 */
export interface ChannelViewModel {
    /**
     * @generated from protobuf field: pb.v2.ChannelModel model = 1;
     */
    model?: ChannelModel;
    /**
     * @generated from protobuf field: repeated pb.v2.DataBase device_collection = 2;
     */
    deviceCollection: DataBase[]; // client must implement a getTelemetryCollection() from db.
    /**
     * @generated from protobuf field: uint32 total_users_count = 4;
     */
    totalUsersCount: number;
}
/**
 * @generated from protobuf message pb.v2.ChannelMessageModel
 */
export interface ChannelMessageModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.ChatMessage chat_message = 2;
     */
    chatMessage?: ChatMessage;
}
/**
 * @generated from protobuf message pb.v2.ChannelMessageViewModel
 */
export interface ChannelMessageViewModel {
    /**
     * @generated from protobuf field: pb.v2.ChannelMessageModel model = 1;
     */
    model?: ChannelMessageModel;
    /**
     * @generated from protobuf oneof: ChatData
     */
    chatData: {
        oneofKind: "photoB64";
        /**
         * @generated from protobuf field: string photo_b64 = 2;
         */
        photoB64: string;
    } | {
        oneofKind: "audioMsg";
        /**
         * @generated from protobuf field: string audio_msg = 3;
         */
        audioMsg: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message pb.v2.PersonModel
 */
export interface PersonModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.Person person = 2;
     */
    person?: Person;
    /**
     * @generated from protobuf field: optional fixed64 last_connect_ms = 3;
     */
    lastConnectMs?: bigint;
    /**
     * @generated from protobuf field: repeated pb.v2.DataBase contacts = 4;
     */
    contacts: DataBase[]; // client must implement a getTelemetryCollection() from db.
}
/**
 * Remember -> View models are implemented on the clients or the rpc service. They are not stored in the db.
 *
 * @generated from protobuf message pb.v2.PersonViewModel
 */
export interface PersonViewModel {
    /**
     * @generated from protobuf field: pb.v2.PersonModel model = 1;
     */
    model?: PersonModel; // Inferred. View Model will hold collection of TelemetryViewModel
    /**
     * Inferred calculations based off client/transport agent
     *
     * @generated from protobuf field: bool isOnline = 2;
     */
    isOnline: boolean;
}
/**
 * //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * / Server Models
 *
 * @generated from protobuf message pb.v2.EventModel
 */
export interface EventModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.EventLog event = 2;
     */
    event?: EventLog;
}
/**
 * @generated from protobuf message pb.v2.EventViewModel
 */
export interface EventViewModel {
    /**
     * @generated from protobuf field: pb.v2.EventModel model = 1;
     */
    model?: EventModel;
}
/**
 * @generated from protobuf message pb.v2.AppManagementModel
 */
export interface AppManagementModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.AppManagement app_management = 2;
     */
    appManagement?: AppManagement;
}
/**
 * @generated from protobuf message pb.v2.AppManagementViewModel
 */
export interface AppManagementViewModel {
    /**
     * @generated from protobuf field: pb.v2.AppManagementModel model = 1;
     */
    model?: AppManagementModel;
}
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// / GeoLocations

/**
 * @generated from protobuf message pb.v2.GeoNodeModel
 */
export interface GeoNodeModel {
    /**
     * @generated from protobuf field: pb.v2.DataBase db = 1;
     */
    db?: DataBase;
    /**
     * @generated from protobuf field: pb.v2.GeoNode geo_node = 3;
     */
    geoNode?: GeoNode;
    /**
     * @generated from protobuf field: repeated string photo_urns = 4;
     */
    photoUrns: string[];
    /**
     * @generated from protobuf field: optional string parent_urns = 5;
     */
    parentUrns?: string; // urn of parent
    /**
     * @generated from protobuf field: repeated string children_urns = 6;
     */
    childrenUrns: string[]; // urns of children
    /**
     * @generated from protobuf field: string image_url = 7;
     */
    imageUrl: string;
}
/**
 * UI -> has everything linked togheter that needs to be linked together
 *
 * @generated from protobuf message pb.v2.GeoNodeViewModel
 */
export interface GeoNodeViewModel {
    /**
     * @generated from protobuf field: pb.v2.GeoNodeModel model = 1;
     */
    model?: GeoNodeModel;
    /**
     * calculate but is not stored
     * Common to ALL things stored as a GeoNode (IE: Building, Floor, Area, Room and future)
     *
     * @generated from protobuf field: string formatted_name = 2;
     */
    formattedName: string;
}
/**
 * Important to include in the keys when serializing. So when we deserialize, we can use the
 * key to call the correct "breaking" version of protobuf and upgrade it if nessisary
 * IDEALLY :: THIS SHOULD NEVER EVER CHANGE -> JUST A CONTINGENCY BECAUSE WE ALWAYS FIND
 * A WAY TO BREAK THE PROTOBUF WITH FUTURE FEATURE REQUESTS (because we can't think of everything)
 *
 * @generated from protobuf enum pb.v2.PB
 */
export enum PB {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: VERSION = 2;
     */
    VERSION = 2
}
/**
 * If we pull a random doc out of the DB, we need to know what schama from json/non proto to serialze it back to
 * protobuf scheama
 *
 * @generated from protobuf enum pb.v2.ModelType
 */
export enum ModelType {
    /**
     * @generated from protobuf enum value: MODEL_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_SERIALIZED = 1;
     */
    SERIALIZED = 1,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_TELEMETRY_MODEL = 2;
     */
    TELEMETRY_MODEL = 2,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_DEVICE_MODEL = 3;
     */
    DEVICE_MODEL = 3,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_FILE_MODEL = 4;
     */
    FILE_MODEL = 4,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_FIRMWARE_FILE_MODEL = 5;
     */
    FIRMWARE_FILE_MODEL = 5,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_DEVICE_GROUP_MODEL = 6;
     */
    DEVICE_GROUP_MODEL = 6,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_WEBHOOK_MODEL = 7;
     */
    WEBHOOK_MODEL = 7,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_EVENT_MODEL = 8;
     */
    EVENT_MODEL = 8,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_ASSET_MODEL = 9;
     */
    ASSET_MODEL = 9,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_GEONODE_MODEL = 10;
     */
    GEONODE_MODEL = 10,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_APP_MANAGEMENT_MODEL = 11;
     */
    APP_MANAGEMENT_MODEL = 11,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_APP_WORKSPACE_MODEL = 12;
     */
    APP_WORKSPACE_MODEL = 12,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_APP_CHANNEL_MODEL = 13;
     */
    APP_CHANNEL_MODEL = 13,
    /**
     * @generated from protobuf enum value: MODEL_TYPE_APP_CHANNEL_MESSAGE_MODEL = 14;
     */
    APP_CHANNEL_MESSAGE_MODEL = 14
}
/**
 * @generated from protobuf enum pb.v2.SyncEventType
 */
export enum SyncEventType {
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_OFFLINE = 1;
     */
    OFFLINE = 1,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_ID_LOADED = 2;
     */
    ID_LOADED = 2,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_STARTED = 3;
     */
    STARTED = 3,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_CHANGE = 4;
     */
    CHANGE = 4,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_PAUSED = 5;
     */
    PAUSED = 5,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_ACTIVE = 6;
     */
    ACTIVE = 6,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_DENIED = 7;
     */
    DENIED = 7,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_COMPLETE = 8;
     */
    COMPLETE = 8,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_ERROR = 9;
     */
    ERROR = 9,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_SYNC_FINISHED = 10;
     */
    SYNC_FINISHED = 10,
    /**
     * @generated from protobuf enum value: SYNC_EVENT_TYPE_LIVE = 11;
     */
    LIVE = 11
}
/**
 * @generated from protobuf enum pb.v2.DatabaseSaveBeahvior
 */
export enum DatabaseSaveBeahvior {
    /**
     * @generated from protobuf enum value: DATABASE_SAVE_BEHAVIOR_UNKNOWN = 0;
     */
    DATABASE_SAVE_BEHAVIOR_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: DATABASE_SAVE_BEHAVIOR_KEEP_ALL = 1;
     */
    DATABASE_SAVE_BEHAVIOR_KEEP_ALL = 1,
    /**
     * @generated from protobuf enum value: DATABASE_SAVE_BEHAVIOR_KEEP_LATEST = 2;
     */
    DATABASE_SAVE_BEHAVIOR_KEEP_LATEST = 2,
    /**
     * @generated from protobuf enum value: DATABASE_SAVE_BEHAVIOR_KEEP_MAX_EPOCH_RANGE = 3;
     */
    DATABASE_SAVE_BEHAVIOR_KEEP_MAX_EPOCH_RANGE = 3,
    /**
     * @generated from protobuf enum value: DATABASE_SAVE_BEHAVIOR_KEEP_MAX_COUNT = 4;
     */
    DATABASE_SAVE_BEHAVIOR_KEEP_MAX_COUNT = 4
}
/**
 * @generated from protobuf enum pb.v2.DatabaseMessageSaveType
 */
export enum DatabaseMessageSaveType {
    /**
     * @generated from protobuf enum value: DATABASE_MESSAGE_SAVE_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * Is not persisted to the database
     *
     * @generated from protobuf enum value: DATABASE_MESSAGE_SAVE_TYPE_EPHEMERAL = 1;
     */
    EPHEMERAL = 1,
    /**
     * Is persisted to the database
     *
     * @generated from protobuf enum value: DATABASE_MESSAGE_SAVE_TYPE_PERSISTENT = 2;
     */
    PERSISTENT = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class DatabaseSaveSettings$Type extends MessageType<DatabaseSaveSettings> {
    constructor() {
        super("pb.v2.DatabaseSaveSettings", [
            { no: 1, name: "save_behavior", kind: "enum", opt: true, T: () => ["pb.v2.DatabaseSaveBeahvior", DatabaseSaveBeahvior] },
            { no: 2, name: "save_frequncy_ms", kind: "scalar", opt: true, T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "max_save_epoch_ms", kind: "scalar", opt: true, T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "max_save_count", kind: "scalar", opt: true, T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<DatabaseSaveSettings>): DatabaseSaveSettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatabaseSaveSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatabaseSaveSettings): DatabaseSaveSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional pb.v2.DatabaseSaveBeahvior save_behavior */ 1:
                    message.saveBehavior = reader.int32();
                    break;
                case /* optional uint32 save_frequncy_ms */ 2:
                    message.saveFrequncyMs = reader.uint32();
                    break;
                case /* optional uint32 max_save_epoch_ms */ 3:
                    message.maxSaveEpochMs = reader.uint32();
                    break;
                case /* optional uint32 max_save_count */ 4:
                    message.maxSaveCount = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatabaseSaveSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional pb.v2.DatabaseSaveBeahvior save_behavior = 1; */
        if (message.saveBehavior !== undefined)
            writer.tag(1, WireType.Varint).int32(message.saveBehavior);
        /* optional uint32 save_frequncy_ms = 2; */
        if (message.saveFrequncyMs !== undefined)
            writer.tag(2, WireType.Varint).uint32(message.saveFrequncyMs);
        /* optional uint32 max_save_epoch_ms = 3; */
        if (message.maxSaveEpochMs !== undefined)
            writer.tag(3, WireType.Varint).uint32(message.maxSaveEpochMs);
        /* optional uint32 max_save_count = 4; */
        if (message.maxSaveCount !== undefined)
            writer.tag(4, WireType.Varint).uint32(message.maxSaveCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DatabaseSaveSettings
 */
export const DatabaseSaveSettings = new DatabaseSaveSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataBase$Type extends MessageType<DataBase> {
    constructor() {
        super("pb.v2.DataBase", [
            { no: 1, name: "uid", kind: "scalar", opt: true, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "urn", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "read_groups", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "write_groups", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "model_type", kind: "scalar", opt: true, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "model_version", kind: "scalar", opt: true, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "geohash", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "created_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 9, name: "received_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 10, name: "edited_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 11, name: "deleted_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 12, name: "updated_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 13, name: "latest_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 14, name: "md5", kind: "scalar", opt: true, T: 12 /*ScalarType.BYTES*/ },
            { no: 15, name: "static_url", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "workspace_uid", kind: "scalar", opt: true, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 17, name: "channel_uid", kind: "scalar", opt: true, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 18, name: "uuid", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 19, name: "sync_status", kind: "enum", opt: true, T: () => ["pb.v2.SyncEventType", SyncEventType, "SYNC_EVENT_TYPE_"] },
            { no: 20, name: "save_type", kind: "enum", opt: true, T: () => ["pb.v2.DatabaseMessageSaveType", DatabaseMessageSaveType, "DATABASE_MESSAGE_SAVE_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<DataBase>): DataBase {
        const message = { readGroups: [], writeGroups: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataBase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataBase): DataBase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional uint64 uid */ 1:
                    message.uid = reader.uint64().toBigInt();
                    break;
                case /* optional string urn */ 2:
                    message.urn = reader.string();
                    break;
                case /* repeated string read_groups */ 3:
                    message.readGroups.push(reader.string());
                    break;
                case /* repeated string write_groups */ 4:
                    message.writeGroups.push(reader.string());
                    break;
                case /* optional uint64 model_type */ 5:
                    message.modelType = reader.uint64().toBigInt();
                    break;
                case /* optional uint64 model_version */ 6:
                    message.modelVersion = reader.uint64().toBigInt();
                    break;
                case /* optional fixed64 geohash */ 7:
                    message.geohash = reader.fixed64().toBigInt();
                    break;
                case /* optional fixed64 created_ms */ 8:
                    message.createdMs = reader.fixed64().toBigInt();
                    break;
                case /* optional fixed64 received_ms */ 9:
                    message.receivedMs = reader.fixed64().toBigInt();
                    break;
                case /* optional fixed64 edited_ms */ 10:
                    message.editedMs = reader.fixed64().toBigInt();
                    break;
                case /* optional fixed64 deleted_ms */ 11:
                    message.deletedMs = reader.fixed64().toBigInt();
                    break;
                case /* optional fixed64 updated_ms */ 12:
                    message.updatedMs = reader.fixed64().toBigInt();
                    break;
                case /* optional fixed64 latest_ms */ 13:
                    message.latestMs = reader.fixed64().toBigInt();
                    break;
                case /* optional bytes md5 */ 14:
                    message.md5 = reader.bytes();
                    break;
                case /* optional string static_url */ 15:
                    message.staticUrl = reader.string();
                    break;
                case /* optional uint64 workspace_uid */ 16:
                    message.workspaceUid = reader.uint64().toBigInt();
                    break;
                case /* optional uint64 channel_uid */ 17:
                    message.channelUid = reader.uint64().toBigInt();
                    break;
                case /* optional fixed64 uuid */ 18:
                    message.uuid = reader.fixed64().toBigInt();
                    break;
                case /* optional pb.v2.SyncEventType sync_status */ 19:
                    message.syncStatus = reader.int32();
                    break;
                case /* optional pb.v2.DatabaseMessageSaveType save_type */ 20:
                    message.saveType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataBase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional uint64 uid = 1; */
        if (message.uid !== undefined)
            writer.tag(1, WireType.Varint).uint64(message.uid);
        /* optional string urn = 2; */
        if (message.urn !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.urn);
        /* repeated string read_groups = 3; */
        for (let i = 0; i < message.readGroups.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.readGroups[i]);
        /* repeated string write_groups = 4; */
        for (let i = 0; i < message.writeGroups.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.writeGroups[i]);
        /* optional uint64 model_type = 5; */
        if (message.modelType !== undefined)
            writer.tag(5, WireType.Varint).uint64(message.modelType);
        /* optional uint64 model_version = 6; */
        if (message.modelVersion !== undefined)
            writer.tag(6, WireType.Varint).uint64(message.modelVersion);
        /* optional fixed64 geohash = 7; */
        if (message.geohash !== undefined)
            writer.tag(7, WireType.Bit64).fixed64(message.geohash);
        /* optional fixed64 created_ms = 8; */
        if (message.createdMs !== undefined)
            writer.tag(8, WireType.Bit64).fixed64(message.createdMs);
        /* optional fixed64 received_ms = 9; */
        if (message.receivedMs !== undefined)
            writer.tag(9, WireType.Bit64).fixed64(message.receivedMs);
        /* optional fixed64 edited_ms = 10; */
        if (message.editedMs !== undefined)
            writer.tag(10, WireType.Bit64).fixed64(message.editedMs);
        /* optional fixed64 deleted_ms = 11; */
        if (message.deletedMs !== undefined)
            writer.tag(11, WireType.Bit64).fixed64(message.deletedMs);
        /* optional fixed64 updated_ms = 12; */
        if (message.updatedMs !== undefined)
            writer.tag(12, WireType.Bit64).fixed64(message.updatedMs);
        /* optional fixed64 latest_ms = 13; */
        if (message.latestMs !== undefined)
            writer.tag(13, WireType.Bit64).fixed64(message.latestMs);
        /* optional bytes md5 = 14; */
        if (message.md5 !== undefined)
            writer.tag(14, WireType.LengthDelimited).bytes(message.md5);
        /* optional string static_url = 15; */
        if (message.staticUrl !== undefined)
            writer.tag(15, WireType.LengthDelimited).string(message.staticUrl);
        /* optional uint64 workspace_uid = 16; */
        if (message.workspaceUid !== undefined)
            writer.tag(16, WireType.Varint).uint64(message.workspaceUid);
        /* optional uint64 channel_uid = 17; */
        if (message.channelUid !== undefined)
            writer.tag(17, WireType.Varint).uint64(message.channelUid);
        /* optional fixed64 uuid = 18; */
        if (message.uuid !== undefined)
            writer.tag(18, WireType.Bit64).fixed64(message.uuid);
        /* optional pb.v2.SyncEventType sync_status = 19; */
        if (message.syncStatus !== undefined)
            writer.tag(19, WireType.Varint).int32(message.syncStatus);
        /* optional pb.v2.DatabaseMessageSaveType save_type = 20; */
        if (message.saveType !== undefined)
            writer.tag(20, WireType.Varint).int32(message.saveType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DataBase
 */
export const DataBase = new DataBase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataBaseInformation$Type extends MessageType<DataBaseInformation> {
    constructor() {
        super("pb.v2.DataBaseInformation", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "sync_status", kind: "enum", opt: true, T: () => ["pb.v2.SyncEventType", SyncEventType, "SYNC_EVENT_TYPE_"] },
            { no: 3, name: "last_update_ms", kind: "scalar", opt: true, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "total_docs", kind: "scalar", opt: true, T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "synced_docs", kind: "scalar", opt: true, T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "doc_collection", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataBase },
            { no: 7, name: "error_status", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "sync_events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataBaseInformation_SyncEvent }
        ]);
    }
    create(value?: PartialMessage<DataBaseInformation>): DataBaseInformation {
        const message = { docCollection: [], syncEvents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataBaseInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataBaseInformation): DataBaseInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* optional pb.v2.SyncEventType sync_status */ 2:
                    message.syncStatus = reader.int32();
                    break;
                case /* optional uint64 last_update_ms */ 3:
                    message.lastUpdateMs = reader.uint64().toBigInt();
                    break;
                case /* optional uint32 total_docs */ 4:
                    message.totalDocs = reader.uint32();
                    break;
                case /* optional uint32 synced_docs */ 5:
                    message.syncedDocs = reader.uint32();
                    break;
                case /* repeated pb.v2.DataBase doc_collection */ 6:
                    message.docCollection.push(DataBase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* optional string error_status */ 7:
                    message.errorStatus = reader.string();
                    break;
                case /* repeated pb.v2.DataBaseInformation.SyncEvent sync_events */ 8:
                    message.syncEvents.push(DataBaseInformation_SyncEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataBaseInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional pb.v2.SyncEventType sync_status = 2; */
        if (message.syncStatus !== undefined)
            writer.tag(2, WireType.Varint).int32(message.syncStatus);
        /* optional uint64 last_update_ms = 3; */
        if (message.lastUpdateMs !== undefined)
            writer.tag(3, WireType.Varint).uint64(message.lastUpdateMs);
        /* optional uint32 total_docs = 4; */
        if (message.totalDocs !== undefined)
            writer.tag(4, WireType.Varint).uint32(message.totalDocs);
        /* optional uint32 synced_docs = 5; */
        if (message.syncedDocs !== undefined)
            writer.tag(5, WireType.Varint).uint32(message.syncedDocs);
        /* repeated pb.v2.DataBase doc_collection = 6; */
        for (let i = 0; i < message.docCollection.length; i++)
            DataBase.internalBinaryWrite(message.docCollection[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* optional string error_status = 7; */
        if (message.errorStatus !== undefined)
            writer.tag(7, WireType.LengthDelimited).string(message.errorStatus);
        /* repeated pb.v2.DataBaseInformation.SyncEvent sync_events = 8; */
        for (let i = 0; i < message.syncEvents.length; i++)
            DataBaseInformation_SyncEvent.internalBinaryWrite(message.syncEvents[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DataBaseInformation
 */
export const DataBaseInformation = new DataBaseInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataBaseInformation_SyncEvent$Type extends MessageType<DataBaseInformation_SyncEvent> {
    constructor() {
        super("pb.v2.DataBaseInformation.SyncEvent", [
            { no: 1, name: "event_type", kind: "enum", opt: true, T: () => ["pb.v2.SyncEventType", SyncEventType, "SYNC_EVENT_TYPE_"] },
            { no: 2, name: "event_data", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DataBaseInformation_SyncEvent>): DataBaseInformation_SyncEvent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataBaseInformation_SyncEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataBaseInformation_SyncEvent): DataBaseInformation_SyncEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional pb.v2.SyncEventType event_type */ 1:
                    message.eventType = reader.int32();
                    break;
                case /* optional string event_data */ 2:
                    message.eventData = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataBaseInformation_SyncEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional pb.v2.SyncEventType event_type = 1; */
        if (message.eventType !== undefined)
            writer.tag(1, WireType.Varint).int32(message.eventType);
        /* optional string event_data = 2; */
        if (message.eventData !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.eventData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DataBaseInformation.SyncEvent
 */
export const DataBaseInformation_SyncEvent = new DataBaseInformation_SyncEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SerializedModel$Type extends MessageType<SerializedModel> {
    constructor() {
        super("pb.v2.SerializedModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "serialized", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<SerializedModel>): SerializedModel {
        const message = { serialized: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SerializedModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SerializedModel): SerializedModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* bytes serialized */ 2:
                    message.serialized = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SerializedModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bytes serialized = 2; */
        if (message.serialized.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.serialized);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.SerializedModel
 */
export const SerializedModel = new SerializedModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TelemetryModel$Type extends MessageType<TelemetryModel> {
    constructor() {
        super("pb.v2.TelemetryModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "telemetry", kind: "message", T: () => Telemetry },
            { no: 3, name: "reported_by_device_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TelemetryModel>): TelemetryModel {
        const message = { reportedByDeviceUrn: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TelemetryModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TelemetryModel): TelemetryModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.Telemetry telemetry */ 2:
                    message.telemetry = Telemetry.internalBinaryRead(reader, reader.uint32(), options, message.telemetry);
                    break;
                case /* string reported_by_device_urn */ 3:
                    message.reportedByDeviceUrn = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TelemetryModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Telemetry telemetry = 2; */
        if (message.telemetry)
            Telemetry.internalBinaryWrite(message.telemetry, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string reported_by_device_urn = 3; */
        if (message.reportedByDeviceUrn !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.reportedByDeviceUrn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.TelemetryModel
 */
export const TelemetryModel = new TelemetryModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TelemetryViewModel$Type extends MessageType<TelemetryViewModel> {
    constructor() {
        super("pb.v2.TelemetryViewModel", [
            { no: 1, name: "model", kind: "message", T: () => TelemetryModel },
            { no: 2, name: "hasPosition", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "color_rgb", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "currentVersionFormatted", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TelemetryViewModel>): TelemetryViewModel {
        const message = { hasPosition: false, colorRgb: new Uint8Array(0), currentVersionFormatted: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TelemetryViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TelemetryViewModel): TelemetryViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.TelemetryModel model */ 1:
                    message.model = TelemetryModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* bool hasPosition */ 2:
                    message.hasPosition = reader.bool();
                    break;
                case /* bytes color_rgb */ 3:
                    message.colorRgb = reader.bytes();
                    break;
                case /* string currentVersionFormatted */ 4:
                    message.currentVersionFormatted = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TelemetryViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.TelemetryModel model = 1; */
        if (message.model)
            TelemetryModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool hasPosition = 2; */
        if (message.hasPosition !== false)
            writer.tag(2, WireType.Varint).bool(message.hasPosition);
        /* bytes color_rgb = 3; */
        if (message.colorRgb.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.colorRgb);
        /* string currentVersionFormatted = 4; */
        if (message.currentVersionFormatted !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.currentVersionFormatted);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.TelemetryViewModel
 */
export const TelemetryViewModel = new TelemetryViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceModel$Type extends MessageType<DeviceModel> {
    constructor() {
        super("pb.v2.DeviceModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "device", kind: "message", T: () => Device },
            { no: 3, name: "last_connect_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "save_settings", kind: "message", T: () => DatabaseSaveSettings },
            { no: 5, name: "derived_telemetry", kind: "message", T: () => Telemetry },
            { no: 6, name: "onboarding", kind: "message", T: () => Onboarding },
            { no: 7, name: "total_telemetry_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "newest_telemetry_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 9, name: "oldest_telemetry_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 10, name: "device_group_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "reported_by_device_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "gateway_positions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position }
        ]);
    }
    create(value?: PartialMessage<DeviceModel>): DeviceModel {
        const message = { totalTelemetryCount: 0, newestTelemetryMs: 0n, oldestTelemetryMs: 0n, deviceGroupUrn: "", reportedByDeviceUrn: "", gatewayPositions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceModel): DeviceModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.Device device */ 2:
                    message.device = Device.internalBinaryRead(reader, reader.uint32(), options, message.device);
                    break;
                case /* optional fixed64 last_connect_ms */ 3:
                    message.lastConnectMs = reader.fixed64().toBigInt();
                    break;
                case /* optional pb.v2.DatabaseSaveSettings save_settings */ 4:
                    message.saveSettings = DatabaseSaveSettings.internalBinaryRead(reader, reader.uint32(), options, message.saveSettings);
                    break;
                case /* pb.v2.Telemetry derived_telemetry */ 5:
                    message.derivedTelemetry = Telemetry.internalBinaryRead(reader, reader.uint32(), options, message.derivedTelemetry);
                    break;
                case /* pb.v2.Onboarding onboarding */ 6:
                    message.onboarding = Onboarding.internalBinaryRead(reader, reader.uint32(), options, message.onboarding);
                    break;
                case /* uint32 total_telemetry_count */ 7:
                    message.totalTelemetryCount = reader.uint32();
                    break;
                case /* uint64 newest_telemetry_ms */ 8:
                    message.newestTelemetryMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 oldest_telemetry_ms */ 9:
                    message.oldestTelemetryMs = reader.uint64().toBigInt();
                    break;
                case /* string device_group_urn */ 10:
                    message.deviceGroupUrn = reader.string();
                    break;
                case /* string reported_by_device_urn */ 11:
                    message.reportedByDeviceUrn = reader.string();
                    break;
                case /* repeated pb.v2.Position gateway_positions */ 12:
                    message.gatewayPositions.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Device device = 2; */
        if (message.device)
            Device.internalBinaryWrite(message.device, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional fixed64 last_connect_ms = 3; */
        if (message.lastConnectMs !== undefined)
            writer.tag(3, WireType.Bit64).fixed64(message.lastConnectMs);
        /* optional pb.v2.DatabaseSaveSettings save_settings = 4; */
        if (message.saveSettings)
            DatabaseSaveSettings.internalBinaryWrite(message.saveSettings, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Telemetry derived_telemetry = 5; */
        if (message.derivedTelemetry)
            Telemetry.internalBinaryWrite(message.derivedTelemetry, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Onboarding onboarding = 6; */
        if (message.onboarding)
            Onboarding.internalBinaryWrite(message.onboarding, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* uint32 total_telemetry_count = 7; */
        if (message.totalTelemetryCount !== 0)
            writer.tag(7, WireType.Varint).uint32(message.totalTelemetryCount);
        /* uint64 newest_telemetry_ms = 8; */
        if (message.newestTelemetryMs !== 0n)
            writer.tag(8, WireType.Varint).uint64(message.newestTelemetryMs);
        /* uint64 oldest_telemetry_ms = 9; */
        if (message.oldestTelemetryMs !== 0n)
            writer.tag(9, WireType.Varint).uint64(message.oldestTelemetryMs);
        /* string device_group_urn = 10; */
        if (message.deviceGroupUrn !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.deviceGroupUrn);
        /* string reported_by_device_urn = 11; */
        if (message.reportedByDeviceUrn !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.reportedByDeviceUrn);
        /* repeated pb.v2.Position gateway_positions = 12; */
        for (let i = 0; i < message.gatewayPositions.length; i++)
            Position.internalBinaryWrite(message.gatewayPositions[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DeviceModel
 */
export const DeviceModel = new DeviceModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceViewModel$Type extends MessageType<DeviceViewModel> {
    constructor() {
        super("pb.v2.DeviceViewModel", [
            { no: 1, name: "model", kind: "message", T: () => DeviceModel },
            { no: 2, name: "telemetry_db", kind: "message", T: () => DataBase },
            { no: 3, name: "raw_collection", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataBase },
            { no: 4, name: "isOnline", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "isLocal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "hasPosition", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "color_rgb", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<DeviceViewModel>): DeviceViewModel {
        const message = { rawCollection: [], isOnline: false, isLocal: false, hasPosition: false, colorRgb: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceViewModel): DeviceViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DeviceModel model */ 1:
                    message.model = DeviceModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* pb.v2.DataBase telemetry_db */ 2:
                    message.telemetryDb = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.telemetryDb);
                    break;
                case /* repeated pb.v2.DataBase raw_collection */ 3:
                    message.rawCollection.push(DataBase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool isOnline */ 4:
                    message.isOnline = reader.bool();
                    break;
                case /* bool isLocal */ 5:
                    message.isLocal = reader.bool();
                    break;
                case /* bool hasPosition */ 6:
                    message.hasPosition = reader.bool();
                    break;
                case /* bytes color_rgb */ 7:
                    message.colorRgb = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DeviceModel model = 1; */
        if (message.model)
            DeviceModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.DataBase telemetry_db = 2; */
        if (message.telemetryDb)
            DataBase.internalBinaryWrite(message.telemetryDb, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.DataBase raw_collection = 3; */
        for (let i = 0; i < message.rawCollection.length; i++)
            DataBase.internalBinaryWrite(message.rawCollection[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool isOnline = 4; */
        if (message.isOnline !== false)
            writer.tag(4, WireType.Varint).bool(message.isOnline);
        /* bool isLocal = 5; */
        if (message.isLocal !== false)
            writer.tag(5, WireType.Varint).bool(message.isLocal);
        /* bool hasPosition = 6; */
        if (message.hasPosition !== false)
            writer.tag(6, WireType.Varint).bool(message.hasPosition);
        /* bytes color_rgb = 7; */
        if (message.colorRgb.length)
            writer.tag(7, WireType.LengthDelimited).bytes(message.colorRgb);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DeviceViewModel
 */
export const DeviceViewModel = new DeviceViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetModel$Type extends MessageType<AssetModel> {
    constructor() {
        super("pb.v2.AssetModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "asset", kind: "message", T: () => Asset }
        ]);
    }
    create(value?: PartialMessage<AssetModel>): AssetModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssetModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssetModel): AssetModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.Asset asset */ 2:
                    message.asset = Asset.internalBinaryRead(reader, reader.uint32(), options, message.asset);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssetModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Asset asset = 2; */
        if (message.asset)
            Asset.internalBinaryWrite(message.asset, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AssetModel
 */
export const AssetModel = new AssetModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetViewModel$Type extends MessageType<AssetViewModel> {
    constructor() {
        super("pb.v2.AssetViewModel", [
            { no: 1, name: "model", kind: "message", T: () => AssetModel },
            { no: 4, name: "isOnline", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "isLocal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "hasPosition", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AssetViewModel>): AssetViewModel {
        const message = { isOnline: false, isLocal: false, hasPosition: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssetViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssetViewModel): AssetViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.AssetModel model */ 1:
                    message.model = AssetModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* bool isOnline */ 4:
                    message.isOnline = reader.bool();
                    break;
                case /* bool isLocal */ 5:
                    message.isLocal = reader.bool();
                    break;
                case /* bool hasPosition */ 6:
                    message.hasPosition = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssetViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.AssetModel model = 1; */
        if (message.model)
            AssetModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool isOnline = 4; */
        if (message.isOnline !== false)
            writer.tag(4, WireType.Varint).bool(message.isOnline);
        /* bool isLocal = 5; */
        if (message.isLocal !== false)
            writer.tag(5, WireType.Varint).bool(message.isLocal);
        /* bool hasPosition = 6; */
        if (message.hasPosition !== false)
            writer.tag(6, WireType.Varint).bool(message.hasPosition);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AssetViewModel
 */
export const AssetViewModel = new AssetViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileModel$Type extends MessageType<FileModel> {
    constructor() {
        super("pb.v2.FileModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "file", kind: "message", T: () => File }
        ]);
    }
    create(value?: PartialMessage<FileModel>): FileModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FileModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileModel): FileModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.File file */ 2:
                    message.file = File.internalBinaryRead(reader, reader.uint32(), options, message.file);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.File file = 2; */
        if (message.file)
            File.internalBinaryWrite(message.file, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.FileModel
 */
export const FileModel = new FileModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileViewModel$Type extends MessageType<FileViewModel> {
    constructor() {
        super("pb.v2.FileViewModel", [
            { no: 1, name: "model", kind: "message", T: () => FileModel }
        ]);
    }
    create(value?: PartialMessage<FileViewModel>): FileViewModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FileViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileViewModel): FileViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.FileModel model */ 1:
                    message.model = FileModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.FileModel model = 1; */
        if (message.model)
            FileModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.FileViewModel
 */
export const FileViewModel = new FileViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FirmwareFileModel$Type extends MessageType<FirmwareFileModel> {
    constructor() {
        super("pb.v2.FirmwareFileModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "firmware", kind: "message", T: () => FirmwareFile },
            { no: 3, name: "file_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FirmwareFileModel>): FirmwareFileModel {
        const message = { fileUrn: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FirmwareFileModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FirmwareFileModel): FirmwareFileModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.FirmwareFile firmware */ 2:
                    message.firmware = FirmwareFile.internalBinaryRead(reader, reader.uint32(), options, message.firmware);
                    break;
                case /* string file_urn */ 3:
                    message.fileUrn = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FirmwareFileModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.FirmwareFile firmware = 2; */
        if (message.firmware)
            FirmwareFile.internalBinaryWrite(message.firmware, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string file_urn = 3; */
        if (message.fileUrn !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fileUrn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.FirmwareFileModel
 */
export const FirmwareFileModel = new FirmwareFileModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FirmwareFileViewModel$Type extends MessageType<FirmwareFileViewModel> {
    constructor() {
        super("pb.v2.FirmwareFileViewModel", [
            { no: 1, name: "model", kind: "message", T: () => FirmwareFileModel },
            { no: 2, name: "checked_if_in_use", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "in_use_group_urns", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FirmwareFileViewModel>): FirmwareFileViewModel {
        const message = { checkedIfInUse: false, inUseGroupUrns: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FirmwareFileViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FirmwareFileViewModel): FirmwareFileViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.FirmwareFileModel model */ 1:
                    message.model = FirmwareFileModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* bool checked_if_in_use */ 2:
                    message.checkedIfInUse = reader.bool();
                    break;
                case /* repeated string in_use_group_urns */ 3:
                    message.inUseGroupUrns.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FirmwareFileViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.FirmwareFileModel model = 1; */
        if (message.model)
            FirmwareFileModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool checked_if_in_use = 2; */
        if (message.checkedIfInUse !== false)
            writer.tag(2, WireType.Varint).bool(message.checkedIfInUse);
        /* repeated string in_use_group_urns = 3; */
        for (let i = 0; i < message.inUseGroupUrns.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.inUseGroupUrns[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.FirmwareFileViewModel
 */
export const FirmwareFileViewModel = new FirmwareFileViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceGroupModel$Type extends MessageType<DeviceGroupModel> {
    constructor() {
        super("pb.v2.DeviceGroupModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "device_group", kind: "message", T: () => DeviceGroup },
            { no: 3, name: "photo_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeviceGroupModel>): DeviceGroupModel {
        const message = { photoUrn: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceGroupModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceGroupModel): DeviceGroupModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.DeviceGroup device_group */ 2:
                    message.deviceGroup = DeviceGroup.internalBinaryRead(reader, reader.uint32(), options, message.deviceGroup);
                    break;
                case /* string photo_urn */ 3:
                    message.photoUrn = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceGroupModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.DeviceGroup device_group = 2; */
        if (message.deviceGroup)
            DeviceGroup.internalBinaryWrite(message.deviceGroup, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string photo_urn = 3; */
        if (message.photoUrn !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.photoUrn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DeviceGroupModel
 */
export const DeviceGroupModel = new DeviceGroupModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceGroupViewModel$Type extends MessageType<DeviceGroupViewModel> {
    constructor() {
        super("pb.v2.DeviceGroupViewModel", [
            { no: 1, name: "model", kind: "message", T: () => DeviceGroupModel },
            { no: 2, name: "photo_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeviceGroupViewModel>): DeviceGroupViewModel {
        const message = { photoUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceGroupViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceGroupViewModel): DeviceGroupViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DeviceGroupModel model */ 1:
                    message.model = DeviceGroupModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* string photo_url */ 2:
                    message.photoUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceGroupViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DeviceGroupModel model = 1; */
        if (message.model)
            DeviceGroupModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string photo_url = 2; */
        if (message.photoUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.photoUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.DeviceGroupViewModel
 */
export const DeviceGroupViewModel = new DeviceGroupViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebhookModel$Type extends MessageType<WebhookModel> {
    constructor() {
        super("pb.v2.WebhookModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "webhook", kind: "message", T: () => Webhook }
        ]);
    }
    create(value?: PartialMessage<WebhookModel>): WebhookModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WebhookModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebhookModel): WebhookModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.Webhook webhook */ 2:
                    message.webhook = Webhook.internalBinaryRead(reader, reader.uint32(), options, message.webhook);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebhookModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Webhook webhook = 2; */
        if (message.webhook)
            Webhook.internalBinaryWrite(message.webhook, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.WebhookModel
 */
export const WebhookModel = new WebhookModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebhookViewModel$Type extends MessageType<WebhookViewModel> {
    constructor() {
        super("pb.v2.WebhookViewModel", [
            { no: 1, name: "model", kind: "message", T: () => WebhookModel }
        ]);
    }
    create(value?: PartialMessage<WebhookViewModel>): WebhookViewModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WebhookViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebhookViewModel): WebhookViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.WebhookModel model */ 1:
                    message.model = WebhookModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebhookViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.WebhookModel model = 1; */
        if (message.model)
            WebhookModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.WebhookViewModel
 */
export const WebhookViewModel = new WebhookViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelModel$Type extends MessageType<ChannelModel> {
    constructor() {
        super("pb.v2.ChannelModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "channel", kind: "message", T: () => Channel },
            { no: 3, name: "last_connect_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "admins", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataBase },
            { no: 5, name: "members", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataBase }
        ]);
    }
    create(value?: PartialMessage<ChannelModel>): ChannelModel {
        const message = { admins: [], members: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelModel): ChannelModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.Channel channel */ 2:
                    message.channel = Channel.internalBinaryRead(reader, reader.uint32(), options, message.channel);
                    break;
                case /* optional fixed64 last_connect_ms */ 3:
                    message.lastConnectMs = reader.fixed64().toBigInt();
                    break;
                case /* repeated pb.v2.DataBase admins */ 4:
                    message.admins.push(DataBase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated pb.v2.DataBase members */ 5:
                    message.members.push(DataBase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Channel channel = 2; */
        if (message.channel)
            Channel.internalBinaryWrite(message.channel, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional fixed64 last_connect_ms = 3; */
        if (message.lastConnectMs !== undefined)
            writer.tag(3, WireType.Bit64).fixed64(message.lastConnectMs);
        /* repeated pb.v2.DataBase admins = 4; */
        for (let i = 0; i < message.admins.length; i++)
            DataBase.internalBinaryWrite(message.admins[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.DataBase members = 5; */
        for (let i = 0; i < message.members.length; i++)
            DataBase.internalBinaryWrite(message.members[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.ChannelModel
 */
export const ChannelModel = new ChannelModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelViewModel$Type extends MessageType<ChannelViewModel> {
    constructor() {
        super("pb.v2.ChannelViewModel", [
            { no: 1, name: "model", kind: "message", T: () => ChannelModel },
            { no: 2, name: "device_collection", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataBase },
            { no: 4, name: "total_users_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ChannelViewModel>): ChannelViewModel {
        const message = { deviceCollection: [], totalUsersCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelViewModel): ChannelViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.ChannelModel model */ 1:
                    message.model = ChannelModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* repeated pb.v2.DataBase device_collection */ 2:
                    message.deviceCollection.push(DataBase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 total_users_count */ 4:
                    message.totalUsersCount = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.ChannelModel model = 1; */
        if (message.model)
            ChannelModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated pb.v2.DataBase device_collection = 2; */
        for (let i = 0; i < message.deviceCollection.length; i++)
            DataBase.internalBinaryWrite(message.deviceCollection[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* uint32 total_users_count = 4; */
        if (message.totalUsersCount !== 0)
            writer.tag(4, WireType.Varint).uint32(message.totalUsersCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.ChannelViewModel
 */
export const ChannelViewModel = new ChannelViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelMessageModel$Type extends MessageType<ChannelMessageModel> {
    constructor() {
        super("pb.v2.ChannelMessageModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "chat_message", kind: "message", T: () => ChatMessage }
        ]);
    }
    create(value?: PartialMessage<ChannelMessageModel>): ChannelMessageModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelMessageModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelMessageModel): ChannelMessageModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.ChatMessage chat_message */ 2:
                    message.chatMessage = ChatMessage.internalBinaryRead(reader, reader.uint32(), options, message.chatMessage);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelMessageModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.ChatMessage chat_message = 2; */
        if (message.chatMessage)
            ChatMessage.internalBinaryWrite(message.chatMessage, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.ChannelMessageModel
 */
export const ChannelMessageModel = new ChannelMessageModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelMessageViewModel$Type extends MessageType<ChannelMessageViewModel> {
    constructor() {
        super("pb.v2.ChannelMessageViewModel", [
            { no: 1, name: "model", kind: "message", T: () => ChannelMessageModel },
            { no: 2, name: "photo_b64", kind: "scalar", oneof: "chatData", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "audio_msg", kind: "scalar", oneof: "chatData", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChannelMessageViewModel>): ChannelMessageViewModel {
        const message = { chatData: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelMessageViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelMessageViewModel): ChannelMessageViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.ChannelMessageModel model */ 1:
                    message.model = ChannelMessageModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* string photo_b64 */ 2:
                    message.chatData = {
                        oneofKind: "photoB64",
                        photoB64: reader.string()
                    };
                    break;
                case /* string audio_msg */ 3:
                    message.chatData = {
                        oneofKind: "audioMsg",
                        audioMsg: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelMessageViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.ChannelMessageModel model = 1; */
        if (message.model)
            ChannelMessageModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string photo_b64 = 2; */
        if (message.chatData.oneofKind === "photoB64")
            writer.tag(2, WireType.LengthDelimited).string(message.chatData.photoB64);
        /* string audio_msg = 3; */
        if (message.chatData.oneofKind === "audioMsg")
            writer.tag(3, WireType.LengthDelimited).string(message.chatData.audioMsg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.ChannelMessageViewModel
 */
export const ChannelMessageViewModel = new ChannelMessageViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonModel$Type extends MessageType<PersonModel> {
    constructor() {
        super("pb.v2.PersonModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "person", kind: "message", T: () => Person },
            { no: 3, name: "last_connect_ms", kind: "scalar", opt: true, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "contacts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataBase }
        ]);
    }
    create(value?: PartialMessage<PersonModel>): PersonModel {
        const message = { contacts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PersonModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonModel): PersonModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.Person person */ 2:
                    message.person = Person.internalBinaryRead(reader, reader.uint32(), options, message.person);
                    break;
                case /* optional fixed64 last_connect_ms */ 3:
                    message.lastConnectMs = reader.fixed64().toBigInt();
                    break;
                case /* repeated pb.v2.DataBase contacts */ 4:
                    message.contacts.push(DataBase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.Person person = 2; */
        if (message.person)
            Person.internalBinaryWrite(message.person, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional fixed64 last_connect_ms = 3; */
        if (message.lastConnectMs !== undefined)
            writer.tag(3, WireType.Bit64).fixed64(message.lastConnectMs);
        /* repeated pb.v2.DataBase contacts = 4; */
        for (let i = 0; i < message.contacts.length; i++)
            DataBase.internalBinaryWrite(message.contacts[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.PersonModel
 */
export const PersonModel = new PersonModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonViewModel$Type extends MessageType<PersonViewModel> {
    constructor() {
        super("pb.v2.PersonViewModel", [
            { no: 1, name: "model", kind: "message", T: () => PersonModel },
            { no: 2, name: "isOnline", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PersonViewModel>): PersonViewModel {
        const message = { isOnline: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PersonViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonViewModel): PersonViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.PersonModel model */ 1:
                    message.model = PersonModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* bool isOnline */ 2:
                    message.isOnline = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.PersonModel model = 1; */
        if (message.model)
            PersonModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool isOnline = 2; */
        if (message.isOnline !== false)
            writer.tag(2, WireType.Varint).bool(message.isOnline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.PersonViewModel
 */
export const PersonViewModel = new PersonViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventModel$Type extends MessageType<EventModel> {
    constructor() {
        super("pb.v2.EventModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "event", kind: "message", T: () => EventLog }
        ]);
    }
    create(value?: PartialMessage<EventModel>): EventModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventModel): EventModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.EventLog event */ 2:
                    message.event = EventLog.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.EventLog event = 2; */
        if (message.event)
            EventLog.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.EventModel
 */
export const EventModel = new EventModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventViewModel$Type extends MessageType<EventViewModel> {
    constructor() {
        super("pb.v2.EventViewModel", [
            { no: 1, name: "model", kind: "message", T: () => EventModel }
        ]);
    }
    create(value?: PartialMessage<EventViewModel>): EventViewModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventViewModel): EventViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.EventModel model */ 1:
                    message.model = EventModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.EventModel model = 1; */
        if (message.model)
            EventModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.EventViewModel
 */
export const EventViewModel = new EventViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppManagementModel$Type extends MessageType<AppManagementModel> {
    constructor() {
        super("pb.v2.AppManagementModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 2, name: "app_management", kind: "message", T: () => AppManagement }
        ]);
    }
    create(value?: PartialMessage<AppManagementModel>): AppManagementModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppManagementModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppManagementModel): AppManagementModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.AppManagement app_management */ 2:
                    message.appManagement = AppManagement.internalBinaryRead(reader, reader.uint32(), options, message.appManagement);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppManagementModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.AppManagement app_management = 2; */
        if (message.appManagement)
            AppManagement.internalBinaryWrite(message.appManagement, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppManagementModel
 */
export const AppManagementModel = new AppManagementModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppManagementViewModel$Type extends MessageType<AppManagementViewModel> {
    constructor() {
        super("pb.v2.AppManagementViewModel", [
            { no: 1, name: "model", kind: "message", T: () => AppManagementModel }
        ]);
    }
    create(value?: PartialMessage<AppManagementViewModel>): AppManagementViewModel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppManagementViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppManagementViewModel): AppManagementViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.AppManagementModel model */ 1:
                    message.model = AppManagementModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppManagementViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.AppManagementModel model = 1; */
        if (message.model)
            AppManagementModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppManagementViewModel
 */
export const AppManagementViewModel = new AppManagementViewModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeoNodeModel$Type extends MessageType<GeoNodeModel> {
    constructor() {
        super("pb.v2.GeoNodeModel", [
            { no: 1, name: "db", kind: "message", T: () => DataBase },
            { no: 3, name: "geo_node", kind: "message", T: () => GeoNode },
            { no: 4, name: "photo_urns", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "parent_urns", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "children_urns", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GeoNodeModel>): GeoNodeModel {
        const message = { photoUrns: [], childrenUrns: [], imageUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeoNodeModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeoNodeModel): GeoNodeModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.DataBase db */ 1:
                    message.db = DataBase.internalBinaryRead(reader, reader.uint32(), options, message.db);
                    break;
                case /* pb.v2.GeoNode geo_node */ 3:
                    message.geoNode = GeoNode.internalBinaryRead(reader, reader.uint32(), options, message.geoNode);
                    break;
                case /* repeated string photo_urns */ 4:
                    message.photoUrns.push(reader.string());
                    break;
                case /* optional string parent_urns */ 5:
                    message.parentUrns = reader.string();
                    break;
                case /* repeated string children_urns */ 6:
                    message.childrenUrns.push(reader.string());
                    break;
                case /* string image_url */ 7:
                    message.imageUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeoNodeModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.DataBase db = 1; */
        if (message.db)
            DataBase.internalBinaryWrite(message.db, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.GeoNode geo_node = 3; */
        if (message.geoNode)
            GeoNode.internalBinaryWrite(message.geoNode, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string photo_urns = 4; */
        for (let i = 0; i < message.photoUrns.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.photoUrns[i]);
        /* optional string parent_urns = 5; */
        if (message.parentUrns !== undefined)
            writer.tag(5, WireType.LengthDelimited).string(message.parentUrns);
        /* repeated string children_urns = 6; */
        for (let i = 0; i < message.childrenUrns.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.childrenUrns[i]);
        /* string image_url = 7; */
        if (message.imageUrl !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.imageUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GeoNodeModel
 */
export const GeoNodeModel = new GeoNodeModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeoNodeViewModel$Type extends MessageType<GeoNodeViewModel> {
    constructor() {
        super("pb.v2.GeoNodeViewModel", [
            { no: 1, name: "model", kind: "message", T: () => GeoNodeModel },
            { no: 2, name: "formatted_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GeoNodeViewModel>): GeoNodeViewModel {
        const message = { formattedName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeoNodeViewModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeoNodeViewModel): GeoNodeViewModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* pb.v2.GeoNodeModel model */ 1:
                    message.model = GeoNodeModel.internalBinaryRead(reader, reader.uint32(), options, message.model);
                    break;
                case /* string formatted_name */ 2:
                    message.formattedName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeoNodeViewModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* pb.v2.GeoNodeModel model = 1; */
        if (message.model)
            GeoNodeModel.internalBinaryWrite(message.model, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string formatted_name = 2; */
        if (message.formattedName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.formattedName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.GeoNodeViewModel
 */
export const GeoNodeViewModel = new GeoNodeViewModel$Type();
