
import { BluetoothCommonInterface } from "../../services/bluetooth/bluetooth.service";
import { AllDevicesVMI } from "../../viewmodels/AllDevices.vmi";

import { CommsAssetDeviceViewModelImplemented } from "./comms-asset.vmi";
import { Device, } from "../../generated_proto/protobuf-ts/pb/v2/entities"
import { AllServcies } from "../../services/startup/startup.service";
import { CommsAssetBluetoothConnection } from "./comms-asset.vmi";
import { DeviceTypeIds } from "../../viewmodels/Device.vmi";

export class CommsBluetooth {
	
	constructor
	(
		private allServices:AllServcies,
	)
	{
		if(allServices){
			this.setServices(allServices);
		}
	}

	public services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getSupportedDevices() : AllDevicesVMI[] {
		if(this.services.settings?.SETTINGS.APP_ID){
			if(this.services.settings.SETTINGS.APP_ID != 18){
				return [];
			}
		}
		var possibleVMIs:AllDevicesVMI[] = [];
		possibleVMIs.push(new CommsAssetDeviceViewModelImplemented(this.services, new CommsAssetBluetoothConnection(this.services, {name:"COMMS"})));
		console.log("CommsBluetooth : getSupportedDevices", possibleVMIs);
		return possibleVMIs;
	}

	public async makeDeviceWithType(device: BluetoothCommonInterface): Promise<AllDevicesVMI | undefined> {
		return new Promise( async (resolve,reject) => {
			if(this.services.settings?.SETTINGS.APP_ID){
				if(this.services.settings.SETTINGS.APP_ID != 18){
					resolve(undefined);
					return;
				}
			}
			if(device.name !== undefined){
				var name_filter = "COMMS";
				if(device.name && device.name.toLowerCase().includes(name_filter.toLowerCase())){
					if(device.identifier){
						var blc = new CommsAssetBluetoothConnection(this.services, device)
						var newDevice = new CommsAssetDeviceViewModelImplemented(this.services);
						console.log("Found and new deviceVMI: Calling to set BLUETOOTH CONNECTION");
						var deviceModel = Device.create();
						// Possible : connect and get the mac address here -> build a unique id to connect to
						// await blc.connect().then( () => {
						// 	console.log("Device is connected")
						// }).catch( (e) => {
						// 	console.error("Device failed to connect : ", e);
						// });

						// To look this up in the db in any capacity, we need to know the ID before we could try and go look it up
						// With web, we have no other data to base this off of other than the name (any advert is hidden)
						// OR - connect and ask the device for some more information
						console.log("device: ", device);
						var address_number = Number("0x"+device.identifier.replace(/[ :-]+/g, ""))%100000000;
						if(isNaN(address_number)){
							console.log("Address number given as a string (web)");
							console.log("Address number given as a string (web)");
							console.log("Address number given as a string (web)");
							address_number = 0;
							if (device.identifier.length >= 0) {
								for (let i = 0; i < device.identifier.length; i++) {
									const char = device.identifier.charCodeAt(i);
									address_number = (address_number << 5) - address_number + char;
									address_number = address_number & address_number; // Convert to 32-bit integer
								}
							}
							// abs value
							address_number = Math.abs(address_number);
						}
						else {
							console.log("Address number given as a number (not web)");
							console.log("Address number given as a number (not web)");
							console.log("Address number given as a number (not web)");
						}
						console.log("address_number: ", address_number);
						console.log("full ble device: ", device);
						var uuid = address_number;
						deviceModel.uuid = BigInt(uuid); // <======= issue here
						

						deviceModel.name = "Comms Module";
						deviceModel.foundName = device.name;
						deviceModel.deviceType = DeviceTypeIds.SL_MULTI_COMMS; // doesnt really matter since not pulling it out of a db and casting back.
						newDevice.setDevice(deviceModel);
						newDevice.setBlueToothConnection(blc);
						resolve(newDevice);
						return;
					}
				}
				else {
					resolve(undefined);
					return;
				}
			}
		})
	}
}

// 30364928
// Found and exisiting to deviceVMI:  1661035124194:312
// ADD AND GO TO BLUETOOTH ::  1661035124194:312