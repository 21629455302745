import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { Subject,  } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChartComponent } from 'ng-apexcharts';

import lodash from "lodash";
import deepdash from "deepdash";
const _ = deepdash(lodash);

interface StartEndMs{
	dateStartMillis: number;
	dateEndMillis: number;
}

@Component({
	selector: 'component-day-selector',
	templateUrl: './day-selector.component.html',
	styleUrls: ['./day-selector.component.scss'],
})
export class DaySelectorComponent implements OnInit, OnChanges {

	@ViewChild('apexTimeSelection') apexTimeSelection: ChartComponent;

	readonly debounceTime:number = 100;

	@Input()
	public inputEpochMsList:number[] = [];

	@Input()
	public selected_max:number;

	@Input()
	public selected_min:number;

	// Debounce output: https://stackoverflow.com/questions/39959102/how-do-i-debounce-the-output-of-an-inner-component
	@Output()
	public changedStartStopEpoch = new EventEmitter<StartEndMs>();
	private changedStartStopEpochDebouncer: Subject<StartEndMs> = new Subject<StartEndMs>();


	@Output()
	public filteredEpochMsList = new EventEmitter<number[]>();
	private filteredEpochMsListDebouncer: Subject<number[]> = new Subject<number[]>();

	private _filteredEpochMsList:number[] = [];
	// Date management

	public oldestMillis:number = 0;
	public newestMillis:number = Date.now();

	private chartMin:number ;
	private chartMax:number ;

	private graph;

	public max; // = this.selected_max; //new Date().getTime(); // Current timestamp
	public min;// = this.selected_min; //new Date(this.max - (1000*60*60*24)).getTime(); // timestamp 24 hours before
	public range; // = this.max - this.min;
	public chartOptions:any;
	public selectionTimeoutId;

	constructor(
	)
	{
		this.max = this.selected_max;
		this.min = this.selected_min;
		this.range = this.max - this.min;

		this.changedStartStopEpochDebouncer.pipe(debounceTime(this.debounceTime))
		.subscribe((value) => 	{
			this.changedStartStopEpoch.emit(value);
		});

		this.filteredEpochMsListDebouncer.pipe(debounceTime(this.debounceTime))
		.subscribe((value) => 	{
			this.filteredEpochMsList.emit(value);
		});
	}

	ngOnDestroy() {
		this.changedStartStopEpochDebouncer.unsubscribe();
		this.filteredEpochMsListDebouncer.unsubscribe();
	}

	public isInit:boolean = false;
	ngOnInit() {
	}

	public refresh(){
		this.apexTimeSelection.render();
	}

	ones_array = [1];

	scrolledfunction(chartContext, { xaxis }) {
		console.log("scrolled", xaxis);
	}

	public ngOnChanges(changes: SimpleChanges) {
		console.log("NG ON CHANGES : DAY SELECTOR : ", );
		this.isInit = true;
		this.inputEpochMsList = this.inputEpochMsList.sort();
		this.max = this.inputEpochMsList[this.inputEpochMsList.length-1]
		this.min = new Date(this.max - (1000*60*60*24)).getTime(); // timestamp 24 hours before

		this.ones_array = new Array(this.inputEpochMsList.length).fill(1);
		let parent = this;
		// this.chartOptions = {
		// 	series: [{
		// 		name: "",
		// 		data: this.ones_array
		// 	}],
		// 	chart: {
		// 		id: "timeline-chart",
		// 		height: 125,
		// 		type: "bar",
		// 		toolbar: {
		// 			autoSelected: "selection",
		// 			show: false
		// 		},
		// 		selection: {
		// 			enabled: true,
		// 			xaxis: {
		// 				min: this.selected_min,
		// 				max: this.selected_max
		// 			  },
		// 		},
		// 		events: {
		// 			// dataPointSelection: (event, chartContext, config) => {
		// 			// 	parent.emitFiltered(this.inputEpochMsList[config.dataPointIndex]-1, this.inputEpochMsList[config.dataPointIndex]+1 );
		// 			// },
		// 			selection: (chart , { xaxis, yaxis }) => {
						
		// 				// added first level debounce via timeout
		// 				clearTimeout(this.selectionTimeoutId);
		// 				this.selectionTimeoutId = setTimeout(() => {
		// 					console.log("events selection callback: xaxis", xaxis);
		// 					if(xaxis.min)
		// 					{
		// 						console.log("min : ", new Date(xaxis.min));
		// 						console.log("max : ", new Date(xaxis.max));
		// 						if((xaxis.min != parent.selected_min) || (xaxis.max !=parent.selected_max) )
		// 						{
		// 							parent.emitFiltered(xaxis.min, xaxis.max );

		// 							parent.selected_max = xaxis.max;
		// 							parent.selected_min = xaxis.min;
		// 						}
		// 						else
		// 						{
		// 							console.log("no change detected : ");
		// 						}
		// 					}
		// 				}, 1000);

		// 			},

		// 		},
		// 		animations: {
		// 			enabled: false,
		// 		},
		// 	},
		// 	tooltip: {
		// 		x: {
		// 			show: true,
		// 			format: 'dd MMM h:mm:tt',
		// 			formatter: undefined,
		// 		},
		// 	},
		// 	yaxis: {
		// 		max: 1,
		// 		tickAmount: 1,
		// 		show: false,
		// 		floating: true,
		// 		axisTicks: {
		// 			show: false
		// 		},
		// 		axisBorder: {
		// 			show: false
		// 		},
		// 		labels: {
		// 			show: false
		// 		},
		// 	},
		// 	dataLabels: {
		// 		enabled: false
		// 	},
		// 	stroke: {
		// 		curve: "straight"
		// 	},
		// 	title: {
		// 		text: "",
		// 		align: "center",
		// 		margin: 0,
		// 	},
		// 	xaxis: {
		// 		type: 'datetime',
		// 		labels: {
		// 			datetimeUTC: false,
		// 			datetimeFormatter: {
		// 				year: 'yyyy',
		// 				month: "MMM 'yy",
		// 				day: 'ddd MMM dd',
		// 				hour: 'h:mm:tt',
		// 				minute: 'h:mm:ss tt',
		// 			},
		// 		},
		// 		categories: this.inputEpochMsList
		// 	},
		// };

		console.log("Day selector data has been set : ");
	}

	emitFiltered(dateStartMillis:number, dateEndMillis:number){
		// this._filteredEpochMsList = this.inputEpochMsList.filter((epoch:number) => {
		// 	return epoch >= dateStartMillis && epoch <= dateEndMillis;
		// });
		console.log("this._filteredEpochMsList: ", this._filteredEpochMsList)
		this.changedStartStopEpochDebouncer.next({dateStartMillis:Math.floor(dateStartMillis), dateEndMillis:Math.floor(dateEndMillis)});
		// this.filteredEpochMsListDebouncer.next(this._filteredEpochMsList);
	}

	showLastYear(){
		this.min = new Date(this.max - (1000*60*60*24*356)).getTime(); // timestamp last year
		this.apexTimeSelection.zoomX(this.min, this.max);
		this.emitFiltered(this.min, this.max)
	}

	public showLast24Hr(emit:boolean=true){
		this.min = new Date(this.max - (1000*60*60*24)).getTime(); // timestamp 24 hours before
		//update apexTimeSelection
		this.apexTimeSelection.zoomX(this.min, this.max);
		if(emit)this.emitFiltered(this.min, this.max)
	}

	showLastWeek(){
		this.min = new Date(this.max - (1000*60*60*24*7)).getTime(); // timestamp 7 days  before
		this.apexTimeSelection.zoomX(this.min, this.max);
		this.emitFiltered(this.min, this.max)
	}

	showLastMonth(){
		this.min = new Date(this.max - (1000*60*60*24*30)).getTime(); // timestamp 30 days before
		this.apexTimeSelection.zoomX(this.min, this.max);
		this.emitFiltered(this.min, this.max)
	}

	zoomSelection(){
		this.max = this.selected_max;
		this.min = this.selected_min;			
		this.apexTimeSelection.zoomX(this.min, this.max);
		this.emitFiltered(this.min, this.max)
	}

	zoomFit(){
		this.apexTimeSelection.resetSeries();
	}


}
