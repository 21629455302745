import { Injectable, ApplicationRef, } from '@angular/core';
import { ModalController, ToastController, } from '@ionic/angular';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { SelectBluetoothWebModalComponent } from '../../components/select-bluetooth-web-modal/select-bluetooth-web-modal.component';

export type ShowErrorOptions = {
	duration?:number, 
	userDismiss?:boolean,
}

@Injectable({
	providedIn: 'root'
})
export class UiHelpersService {

	constructor(
		private toastController:ToastController,
		private applicationRef:ApplicationRef,
		private modalController: ModalController,
	)
	{

	}

	private toast:HTMLIonToastElement;

	async showSuccess(message:string, options?:ShowErrorOptions) : Promise<HTMLIonToastElement>{
		if(this.toast){
			this.toast.dismiss();
		}
		var toasterOptions:any = {
			header: 'Success',
			message: message,
			position: 'bottom',
			color:"success",
			buttons: [
				{
					text: 'Dismiss',
					role: 'cancel',
				}
			]
		};
		if(options){
			if(options.userDismiss){
				if(options.duration){
					toasterOptions.duration = options.duration;
				}
				else {
					toasterOptions.duration = 2000;
				}
			}
		}
		this.toast = await this.toastController.create(toasterOptions);
		this.toast.present();
		return this.toast;
	}

	async showWarning(message:string, options?:ShowErrorOptions) : Promise<HTMLIonToastElement>{
		if(this.toast){
			this.toast.dismiss();
		}
		var toasterOptions:any = {
			header: 'Warning',
			message: message,
			position: 'bottom',
			color:"warning",
			buttons: [
				{
					text: 'Dismiss',
					role: 'cancel',
				}
			]
		};
		if(options){
			if(options.userDismiss){
				if(options.duration){
					toasterOptions.duration = options.duration;
				}
				else {
					toasterOptions.duration = 2000;
				}
			}
		}
		this.toast = await this.toastController.create(toasterOptions);
		this.toast.present();
		return this.toast;
	}

	async showError(message:string, options?:ShowErrorOptions) : Promise<HTMLIonToastElement>{
		if(this.toast){
			this.toast.dismiss();
		}
		var toasterOptions:any = {
			header: 'Error',
			message: message,
			position: 'bottom',
			color:"danger",
			buttons: [
				{
					text: 'Dismiss',
					role: 'cancel',
				}
			]
		};
		if(options){
			if(!options.userDismiss){
				if(options.duration){
					toasterOptions.duration = options.duration;
				}
				else {
					toasterOptions.duration = 2000;
				}
			}
		}
		this.toast = await this.toastController.create(toasterOptions);
		this.toast.present();
		return this.toast;
	}

	async showLoading(message:string) : Promise<HTMLIonToastElement> {
		if(this.toast){
			this.toast.dismiss();
		}
		var toasterOptions:any = {
			header: 'Loading',
			message: message,
			position: 'bottom',
			buttons: [
				{
					text: 'Dismiss',
					role: 'cancel',
				}
			]
		};
		this.toast = await this.toastController.create(toasterOptions);
		await this.toast.present();
		return this.toast;
	}

	public static getBarIconFromRssi(rssi:number) : string {
		rssi = Math.abs(rssi);
		if(rssi < 55){
			return "signal_cellular_4_bar";
		}
		else if(rssi < 65){
			return "signal_cellular_3_bar";
		}
		else if(rssi < 75){
			return "signal_cellular_2_bar";
		}
		return "signal_cellular_1_bar";
	}

	shareAsFile(fileName: string, dataString?:string, base64Data?:string) : Promise<any> {
		var data;
		if(dataString){
			// convert string to b64
			data = btoa(dataString);
		}
		else if(base64Data){
			data=base64Data
		}
		else {
			return Promise.reject("No data provided");
		}
		return Filesystem.writeFile({
				path: fileName,
				data: data,
				directory: Directory.Cache
			})
			.then(() => {
				return Filesystem.getUri({
					directory: Directory.Cache,
					path: fileName
				});
			})
			.then((uriResult) => {
				return Share.share({
					title: fileName,
					text: fileName,
					url: uriResult.uri,
				});
		});
	}

	detectChanges(){
		this.applicationRef.tick();
	}

	selectDevice(devices) : Promise<any>{
		return new Promise( async (resolve,reject)=>{
			console.log("selectDevice:: ", devices)
			console.log("Sending back the first device found for now");
			const modal = await this.modalController.create({
				component: SelectBluetoothWebModalComponent,
				cssClass: 'no-background-modal',
				componentProps: {
					devices: devices
				},
			});
			var selectDevice = new Promise( async (resolve,reject) => {
				modal.onDidDismiss().then((result) => {
					console.log("result: ", result)
					if (result.data != undefined) {
						const selectedValue = result.data;
						console.log("selectedValue: ", selectedValue);
						resolve(selectedValue);
					}
				});
				await modal.present();
			})
			selectDevice.then((device)=>{
				resolve(device);
			})
			.catch((err)=>{
				reject(err);
			})
		});
	}

}
