import { AllServcies } from "../../services/startup/startup.service";
import { DataBase, GeoNodeModel, GeoNodeViewModel, ModelType } from "../../generated_proto/protobuf-ts/pb/v2/models";
import { GeoNodeViewModelImplemented, } from "./geonode.vmi";
import { JsonValue } from "@protobuf-ts/runtime";
import { FloorViewModelImplemented } from "./floor.vmi";

export class SiteViewModelImplemented extends GeoNodeViewModelImplemented {	

	constructor(
		services:AllServcies,
	) {
		super(services);
	// 	this.floors = [];		
	}
	// public building: GeoNodeViewModel;
	public floors: FloorViewModelImplemented[];
	public formattedName:string;
	// // public floorVMIs: FloorViewModelImplemented[];
	
	getPagePath() {
		return "protected/sites/site/" + this.model?.geoNode?.uuid;
	}
	// jsonToModel(json:any){
	// 	if(json){
	// 		console.log("BuildingViewModelImplemented.jsonToModel", json);
	// 		// this. = BuildingViewModel.fromJson(json, {ignoreUnknownFields:true});
	// 	}
	// }		
	// save(app_id?:number) : Promise<boolean>{
	// 	if(!app_id){
	// 		app_id = this.services.settings?.SETTINGS.APP_ID;
	// 	}
	// 	// generate a random uuid from the name and timestamp
	// 	var name = this.model?.geoNode?.name || "";
	// 	name += Date.now(); // add timestamp to name to make it unique
	// 	// make random uint from name
	// 	var hash = 0;
	// 	for (var i = 0; i < name.length; i++) {
	// 		hash = name.charCodeAt(i) + ((hash << 5) - hash);
	// 	}
	// 	if(this.model && this.model.geoNode) {
	// 		this.model.geoNode.uuid = BigInt(hash);
	// 	}
	// 	if(this.services.data) return this.services.data.saveGeoNode(app_id||2, this);
	// 	if(this.services.logger) this.services.logger.error(this.loggerOptions, "save: DataService missing");
	// 	return Promise.reject(this.loggerOptions.prefix+":DataService missing");
	// }
	// updateDb(db?:DataBase){
	// 	if(!this.model?.db){
	// 		if(!this.model){
	// 			this.building = BuildingViewModel.create();
	// 		}
	// 		this.model!.db = DataBase.create();
	// 	}
	// 	if(db){
	// 		try{
	// 			DataBase.mergePartial(this.model?.db!, db);
	// 		}
	// 		catch(err){
	// 			console.error("updateDb: Failed to merge db: "+err);
	// 		}
	// 	}
	// 	if(this.building?.model?.db?.createdMs && this.building?.model?.db?.createdMs! <= 0){
	// 		this.building.model.db!.createdMs = BigInt(Date.now());
	// 	}
		
	// 	if(this.building?.model?.db){
	// 		this.building.model.db!.urn = this.generateURN();
	// 		this.building.model.db!.modelType = BigInt(ModelType.BUILDING_MODEL);
	// 		this.building.model.db!.updatedMs = BigInt(Date.now());
	// 	}
	// }
	public getBuildingName() {
		return this.model?.geoNode?.name || "No Name";
	}
	public ionItemDescription: any;
	public getIonItemDescription(): any {
		if(this.ionItemDescription == null){
			this.ionItemDescription = this.buildIonItemDescription();
		}
		return this.ionItemDescription;
	}
	public buildIonItemDescription(): any {
		var html = `
			<div>
				<ion-label> 
					<div class="ion-label-title">${this.getName()}</div> 
					<div class="ion-label-body">
						${this.getUUID()}<br>
						Created: ${new Date(Number(this.model?.db?.updatedMs||0)).toLocaleDateString()} ${new Date(Number(this.model?.db?.updatedMs||0)).toLocaleTimeString()}
					</div>
				</ion-label>
			</div>
			`;

		if(this.services.domSanitizer) this.ionItemDescription = this.services.domSanitizer.bypassSecurityTrustHtml(html);
		return this.ionItemDescription;
	}

	public addFloor(floor: FloorViewModelImplemented): Promise<boolean> {
		return new Promise((resolve, reject) => {
			console.log(floor)
			this.model?.childrenUrns.push(floor.model?.db?.urn || "");
			return this.save();
		});
	}

	public removeFloor(floor: FloorViewModelImplemented): Promise<boolean> {
		return new Promise((resolve, reject) => {
			var index = this.model?.childrenUrns.indexOf(floor.model?.db?.urn || "");
			if(index != undefined && index >= 0){
				this.model?.childrenUrns.splice(index, 1);
				resolve(true);
			}
			else{
				reject("Floor not found");
			}
		});
	}
}
