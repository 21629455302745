import { Injectable } from '@angular/core';
import { Router, CanLoad, CanActivate } from '@angular/router';

import { AuthService, } from './auth.service';

import * as Settings from '../settings/settings.json'
import { StartupService } from '../startup/startup.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements  CanActivate {
	
	constructor(
		private router:Router,
		private authService:AuthService,
		private startupService:StartupService,
	)
	{

	}

	private async getCanLoad() : Promise<boolean> 
	{
		return new Promise( async (resolve, reject) => {
			await this.startupService.ready();
			this.authService.isAuthenticated().then( (auth) => {
				// console.log("AuthGuard.getCanLoad() auth: ", auth);
				if (auth) {
					resolve(true);
				}
				else {
					this.router.navigate(['login']);
					resolve(false);
				}
			}).catch( (e) => {
				resolve(false);
			});
		});
	}

	public async canActivate() {
		return this.getCanLoad();
	}
}

@Injectable({
	providedIn: 'root'
})
export class AppAdminGuard implements  CanActivate {
	
	constructor(
		private router:Router,
		private authService:AuthService,
		private startupService:StartupService,
	)
	{

	}

	private async getCanLoad() : Promise<boolean> 
	{
		return new Promise( async (resolve, reject) => {
			await this.startupService.ready();
			this.authService.isAuthenticated().then( (auth) => {
				if (auth) {
					resolve(true);
				}
				else {
					this.router.navigate(['login']);
					resolve(false);
				}
			}).catch( (e) => {
				resolve(false);
			});
		});
	}

	public async canActivate() {
		return this.getCanLoad();
	}
}

@Injectable({
	providedIn: 'root'
})
export class ChannelGuard implements  CanActivate {
	
	constructor(
		private router:Router,
		private authService:AuthService,
	)
	{

	}

	private async getCanLoad() : Promise<boolean> 
	{
		return new Promise( (resolve, reject) => {
			this.authService.isAuthenticated().then( (auth) => {
				if (auth) {
					this.authService.isChannelJoined().then( (joined) => {
						if(joined){
							resolve(true);
						}
						else {
							this.router.navigate(['protected/channel']);
							resolve(false)
						}
					})
					.catch( (e) => {
						this.router.navigate(['protected/channel']);
						resolve(false);
					})
				}
				else {
					this.router.navigate(['login']);
					resolve(false);
				}
			}).catch( (e) => {
				resolve(false);
			});
		});
	}

	public async canActivate() {
		return this.getCanLoad();
	}
}
