
import { BluetoothCommonInterface } from "../../services/bluetooth/bluetooth.service";
import { AllDevicesVMI } from "../../viewmodels/AllDevices.vmi";

import { Device, } from "../../generated_proto/protobuf-ts/pb/v2/entities"
import { AllServcies } from "../../services/startup/startup.service";
import { DeviceTypeIds } from "../../generated_proto/protobuf-ts/pb/system";
import { EncryptionService } from "../../services/encryption/encryption.service";

import { MirionDeviceViewModelImplemented, MirionBluetoothConnection } from "./mirion.vmi"

export class MirionBluetooth {
	
	constructor
	(
		private allServices:AllServcies,
	)
	{
		if(allServices){
			this.setServices(allServices);
		}
	}

	public services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getSupportedDevices() : AllDevicesVMI[] {
		if(this.services.settings?.SETTINGS.APP_ID){
			if(this.services.settings.SETTINGS.APP_ID != 16 && this.services.settings.SETTINGS.APP_ID != 14){
				return [];
			}
		}
		var possibleVMIs:AllDevicesVMI[] = [];
		possibleVMIs.push(new MirionDeviceViewModelImplemented(this.services, new MirionBluetoothConnection(this.services, {name:"MIRION"})));
		return possibleVMIs;
	}

	public async makeDeviceWithType(device: BluetoothCommonInterface): Promise<AllDevicesVMI | undefined> {
		console.log("MirionBluetooth : makeDeviceWithType");
		return new Promise( async (resolve,reject) => {
			if(this.services.settings?.SETTINGS.APP_ID){
				if(this.services.settings.SETTINGS.APP_ID != 16 && this.services.settings.SETTINGS.APP_ID != 14){
					resolve(undefined);
					return;
				}
				console.log("MirionBluetooth : makeDeviceWithType : APP_ID is 16 or 14");
				if(device.deviceTypeId ){
					console.log("MirionBluetooth : makeDeviceWithType : device.deviceTypeId is not undefined");
					if(device.deviceTypeId >= DeviceTypeIds.MIRION && device.deviceTypeId <= DeviceTypeIds.MIRION_ACCURAD){
						console.log("Found and exisiting to deviceVMI: ", device.deviceTypeId)
						if(device.name){
							console.log('device.name: ', device.name)
							var nameTest = "0x"+device.name.replace(/[^0-9a-fA-F]/g, "");
							console.log("nameTest: ", nameTest);
							var address_number = Number("0x"+device.name.replace(/[^0-9a-fA-F]/g, ""))%100000000;
							console.log("address_number: ", address_number);
							console.log("Device is : ", JSON.stringify(device));
							var uuid = address_number;
							console.log("Asking for device with uuid: ", uuid);
							await this.services.proto?.getDeviceViewModelImplemented(uuid).then( (deviceVMI:AllDevicesVMI) => {
								console.log("Found and exisiting to deviceVMI: ", deviceVMI.checkTimer);
								var deviceModel = Device.create();
								deviceModel.deviceType = DeviceTypeIds.MIRION_ACCURAD;
								deviceModel.name = "AccuRad";
								deviceModel.uuid = BigInt(uuid);
								deviceModel.foundName = device.name!;
								deviceVMI.setDevice(deviceModel);
								deviceVMI.setBlueToothConnection(new MirionBluetoothConnection(this.services, device));
								resolve(deviceVMI);
							}).catch( () => {
								var newDevice = new MirionDeviceViewModelImplemented(this.services);
								var deviceModel = Device.create();
								deviceModel.name = "AccuRad";
								deviceModel.deviceType = DeviceTypeIds.MIRION_ACCURAD;
								deviceModel.foundName = device.name!;
								deviceModel.uuid = BigInt(uuid);
								newDevice.setDevice(deviceModel);
								newDevice.setBlueToothConnection(new MirionBluetoothConnection(this.services, device));
								resolve(newDevice);
								return;
							})
						}
						
					}
				}
				else {
					console.log("MirionBluetooth : makeDeviceWithType : device.deviceTypeId is undefined :: ", device.deviceTypeId )
				}
			}
				
			resolve(undefined);
			return;
		})
	}
}

// 30364928
// Found and exisiting to deviceVMI:  1661035124194:312
// ADD AND GO TO BLUETOOTH ::  1661035124194:312