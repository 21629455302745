import { AllServcies } from "../startup/startup.service"
import { GPSPosition, Sensor } from '../../generated_proto/google/app/model/v1/data_pb'
import { CONNECTION_STATE } from "../constants"
import { DeviceViewModelImplemented } from "../../viewmodels/Device.vmi";
import { BluetoothCommonInterface, BluetoothRequestOptions } from "./bluetooth.service";
import { LoggerOptions } from "../logger/logger.service";
import { UtilService } from "../util/util.service";

export class BluetoothConnection {

	private loggerOptions:LoggerOptions = {
		prefix:"BluetoothConnection",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	public connectionState:CONNECTION_STATE = CONNECTION_STATE.UNKNOWN;
	public parentVMI:DeviceViewModelImplemented;
	private isConnected:boolean = false;
	public isStreaming:boolean = false;
	public isSubscribed:boolean = false;
	public services:AllServcies;
	public rssi:number=0;
	public rssi_history:number[]=[];
	public heartBeatIntervalMs:number = 1000;
	public hearBeatTimeout:any = null;

	public bluetoothCommonInterface:BluetoothCommonInterface;

	public counter:number = 0;

	public keepConnected:boolean = false;
	public showDisconnected:()=>any = ()=>{};

	constructor
	(
		services:AllServcies,
		bluetoothCommonInterface?:BluetoothCommonInterface,
	)
	{
		if(services){
			this.services = services;
		}
		if(bluetoothCommonInterface){
			this.bluetoothCommonInterface = bluetoothCommonInterface;
		}
	}

	// This context depends on the underlying BLE connection being used,
	// -> each plugin uses it slightly differently, its just so you can call its functions
	// and be happy.
	// DONT USE <bleContext> FOR ANY PRESENTATIONAL ARTIFACTS
	// - If you need something vie	wed from this, add it to this class or SensorViewModelImplemented and populate
	// it in the ble service
	public bleContext:any = null;
	setContext( context:any ) {
		this.bleContext = context;
	}

	getContext() : any {
		return this.bleContext;
	}

	getRequesetFilter() : BluetoothRequestOptions {
		this.services.logger?.warn(this.loggerOptions, "getRequesetFilter : not implemented");
		return {};
	}

	async save( uuid:bigint ) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			this.bluetoothCommonInterface.uuidString = uuid.toString();
			try{
				if(!this.services.bluetooth){
					throw new Error("Bluetooth Service Not Set");
				}
				await this.services.bluetooth.saveDeviceConnection(this);
				resolve(true);
			}
			catch(e){
				console.error("Failed to save device", e);
				reject(e);
			}
		});
	}

	async connect() {
		if(this.services && this.services.bluetooth){
			var connected:boolean = false;
			var firstConnect:boolean = true;
			var retryLimit:number = 15;
			var counter = 0;

			while(!connected && (this.keepConnected || firstConnect ) && (counter<retryLimit) ){
				firstConnect = false;
				try{
					connected = await this.services.bluetooth.connect(this);
				}
				catch(e){
					connected = false;
					console.error("BluetoothConnection.connect() : error connecting", e);
				}
				UtilService.delay(1500);
				counter++;
			}
		}
		else {
			console.error("error doing the connection!! services missing");
		}
	}

	async disconnect() {
		this.keepConnected = false;
		this.services.logger?.warn(this.loggerOptions, "bleDisconnect : not implemented");
		await this.services.bluetooth?.disconnect(this);
		await this.services.bluetooth?.removeDeviceConnection(this);
	}

	setIsConnected( isConnected:boolean=false ){
		this.isConnected = isConnected;
		if(this.isConnected){
			if(this.connectionState<=CONNECTION_STATE.CONNECTED){
				this.connectionState = CONNECTION_STATE.CONNECTED;
				if(this.parentVMI){
					// this.parentVMI.addTelemetry(null, new TextEncoder().encode("CONNECTED"))
				}
			}
		}
		else {
			console.log(" :: Setting display for disconnected ::");
			this.connectionState = CONNECTION_STATE.DISCONNECTED;
			this.showDisconnected();
			this.stopHeartBeat();
			this.isConnected = false;
			this.isStreaming = false;
			this.isSubscribed = false;
			this.bleContext = null;
		}
	}

	getIsConnected(){
		return this.isConnected;
	}

	setRssi(rssi:number){
		this.rssi = rssi;
		this.rssi_history.unshift(rssi)
	}

	////////////////////////////////////////////////////////////////////////////////////////////
	// Virtual Functions -> implemented in the inherited classes
	////////////////////////////////////////////////////////////////////////////////////////////

	// Connect to the correct service and do whatever each device needs to start 
	// streaming Telemetry protos from the bluetooth device
	async initalizeDevice() : Promise<boolean> {
		console.error(" :: initalizeDevice : NOT IMPLEMENTED ::");
		return new Promise( async (resolve, reject) => {
			reject("unknown sensor for bluetooth initalizeDevice");
		});
	}

	async startStreamingData() : Promise<boolean> {
		console.error(" :: startStreamingData : NOT IMPLEMENTED ::");
		return new Promise( async (resolve, reject) => {
			reject("unknown sensor for bluetooth startStreamingData");
		});
	}

	async stopStreamingData() : Promise<boolean> {
		console.error(" :: stopStreamingData : NOT IMPLEMENTED ::");
		return new Promise( async (resolve, reject) => {
			reject("unknown sensor for bluetooth stopStreamingData");
		});
	}
	async getSerialNumber() : Promise<boolean> {
		console.error(" :: getSerialNumber : NOT IMPLEMENTED ::");
		return new Promise( async (resolve, reject) => {
			reject("unknown sensor for bluetooth getSerialNumber");
		});
	}

	async getThreshold() : Promise<boolean> {
		return new Promise( async (resolve, reject) => {
			reject("unknown sensor for bluetooth getThreshold");
		});
	}

	async getWarning() : Promise<boolean> {
		console.error(" :: initalizeDevice : NOT IMPLEMENTED ::");
		return new Promise( async (resolve, reject) => {
			reject("unknown sensor for bluetooth getWarning");
		});
	}

	async setCommand(command:string) : Promise<boolean> {
		console.error(" :: initalizeDevice : NOT IMPLEMENTED ::");

		return new Promise( async (resolve, reject) => {
			reject("unknown sensor for bluetooth setCommand");
		});
	}

	async processStreamingData(data:Uint8Array) {
		console.log("unknown sensor for bluetooth processStreamingData : ", data);
	}

	async startHeartBeat() {
		// NOTE :: Not needed by all sensors
		console.log("unknown sensor for bluetooth startHeartBeat");
	}

	async readBattery() : Promise<number> {
		console.error(" :: initalizeDevice : NOT IMPLEMENTED ::");
		return new Promise( async (resolve, reject) => {
			reject("unknown sensor for bluetooth readBattery");
		});
	}
	////////////////////////////////////////////////////////////////////////////////////////////


	stopHeartBeat() {
		console.log(" :: killing heartbeat ::");
		if(this.hearBeatTimeout){
			clearInterval(this.hearBeatTimeout);
		}
	}


	readCommsCharacteristicString(characteristic) : Promise<string> {
		// not implemented
		return Promise.reject("not implemented");
	}

	readCommsCharacteristic(characteristic) : Promise<Uint8Array> {
		// not implemented
		return Promise.reject("not implemented");
	}

	writeCommsCharacteristic(characteristic, value) : Promise<boolean> {
		// not implemented
		return Promise.reject("not implemented");
	}

	async commsConnect() :Promise<void> {
		// not implemented
		return Promise.reject("not implemented");
	}

	commsDisconnect() :void {
		// not implemented
	}
}



// https://medium.com/arkulpa/ios-stay-connected-to-an-external-ble-device-as-much-as-possible-699d434846d2
