
export class UtilService {

	constructor
	(
	)
	{
	}

	public getStrDateFromMillis(millis: number): string{
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
		let date = new Date(millis);
		var formatted = monthNames[date.getMonth()] + " "+date.getDate() + ", " + date.getFullYear();
		return formatted;
	}

	public sleep(ms: number) : Promise<void> {
		return new Promise((r) => setTimeout(r, ms))
	}

	public epochFormatted(epoch:number) : string{
		if(epoch<=0){
			return "n/a"
		}

		var date = new Date(epoch);
		var now = new Date();

		var hours = date.getHours();
		var minutes = date.getMinutes();
		var ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		var minutes_str = minutes < 10 ? '0'+minutes : minutes;
		var strTime = hours + ':' + minutes_str + ' ' + ampm;

		var formatted:string = "";
		if(date.getDate()!=now.getDate()){
			formatted += date.toLocaleString('default', { month: 'short' }) + " " + date.getDate()+",   "
		}
		formatted += strTime
		return formatted;
	}

	public djb2_xor(str) : number {
		let len = str.length
		let h = 5381
		for (let i = 0; i < len; i++) {
			h = h * 33 ^ str.charCodeAt(i)
		}
		return h >>> 0
	}

	public toHexString(byteArray) {
		return Array.from(byteArray, (byte:number) => {
			return ('0' + (byte & 0xFF).toString(16)).slice(-2);
		}).join('')
	}


	public buf2hex(buffer) { // buffer is an ArrayBuffer
		return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
	}

	public hexToBuff(s) {
		// look up tables
		var to_hex_array:any[] = [];
		var to_byte_map = {};
		for (var ord=0; ord<=0xff; ord++) {
			var s:any = ord.toString(16);
			if (s.length < 2) {
				s = "0" + s;
			}
			to_hex_array.push(s);
			to_byte_map[s] = ord;
		}

		var length2 = s.length;
		if ((length2 % 2) != 0) {
			throw "hex string must have length a multiple of 2";
		}
		var length = length2 / 2;
		var result = new Uint8Array(length);
		for (var i=0; i<length; i++) {
			var i2 = i * 2;
			var b = s.substring(i2, i2 + 2);
			result[i] = to_byte_map[b];
		}
		return result;
	}

	public static flattenJson(ob, onlyLeafs:boolean=false) {
		var toReturn = {};
	
		for (var i in ob) {
			if (!ob.hasOwnProperty(i)) continue;
	
			if ((typeof ob[i]) == 'object' && ob[i] !== null) {
				var flatObject = this.flattenJson(ob[i], onlyLeafs);
				for (var x in flatObject) {
					if (!flatObject.hasOwnProperty(x)) continue;
					if(onlyLeafs){
						toReturn[x] = flatObject[x];
					}
					else {
						toReturn[i + '.' + x] = flatObject[x];
					}
				}
			} else {
				toReturn[i] = ob[i];
			}
		}
		return toReturn;
	}

	public static base64EncodeURL(byteArray:Uint8Array) {
		return btoa(Array.from(byteArray).map(val => {
			return String.fromCharCode(val);
		}).join('')).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=/g, '');
	}

	public static base64DecodeURL(b64urlstring:string) {
		return new Uint8Array(atob(b64urlstring.replace(/-/g, '+').replace(/_/g, '/')).split('').map(val => {
			return val.charCodeAt(0);
		}));
	}

	public static getTimeSinceText( epochMiliseconds:number ) : string {
		var current_time:number = Date.now();
		var seconds_since:number = ((current_time - epochMiliseconds)/1000);

		if(seconds_since < 60){
			return "" + Math.round(seconds_since) + " seconds ago"
		}
		// if minutes ago
		else if(seconds_since > 60 && seconds_since <= 60*60){
			return "" + Math.round((seconds_since / 60)) + " Minutes ago";
		}
		// if 12 hours ago
		else if(seconds_since > 60*60 && seconds_since <= 60*60*24){
			var hourText="Hours"
			if(Math.round(seconds_since / (60*60)) == 1){
				hourText = "Hour";
			}
			return "" +  Math.round(seconds_since / (60*60)) + " "+hourText+" and " + Math.round((seconds_since / 60)%60 )  + " Minutes ago"
		}
		else {
			return new Date(epochMiliseconds).toLocaleDateString()
		}
	}

	public static hexColorToRgbA(hex):Uint8Array{
		if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
			var c= hex.substring(1).split('');
			if(c.length== 3){
				c= [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c= '0x'+c.join('');
			return new Uint8Array([(c>>16)&255, (c>>8)&255, c&255, 255])
		}
		throw new Error('Bad Hex');
	}

	public static rgbToHexColor(rgba:Uint8Array) {
		return "#" + ((1 << 24) + (rgba[0] << 16) + (rgba[1] << 8) + rgba[2]).toString(16).slice(1);
	}

	public static stringToInteger( input : string) : number {
		var isnum = /^\d+$/.test(input);
		if(isnum) {
			return parseInt(input);
		}
		else {
			return 0;
		}
	}

	public static stringToFloat( input : string) : number {
		var float:number = parseFloat(input);;
		if(Number.isNaN(float)) {
			return 0;
		}
		else{
			return float;
		}
	}

	public static embeddedBigIntToString( input : any ) : any {
		return JSON.parse(JSON.stringify(input, (_, v) => typeof v === "bigint" ? v.toString() : v))
	}

	public static isValidHttpUrl( string:string ) {
		let url;
		try {
			url = new URL(string);
		} catch (_) {
			return false;
		}
		return url.protocol === "http:" || url.protocol === "https:";
	}

	public static uint8toAscii(data:Uint8Array) {
		var charCodeTranslated:string = "";
		for (let index = 0; index < data.length; index++) {
			const byte = data[index];
			charCodeTranslated += String.fromCharCode(byte);
		}
		return charCodeTranslated;
	}

	public static convertRssiToDistance(rssi: number, measuredRssiAt1Meter = -69, environmentalScale = 2): number {
		const minDistance: number = 0.25;
		const maxDistance: number = 20;
		if (!rssi) return NaN;
		let distance = Math.pow(10, (measuredRssiAt1Meter - rssi) / (10 * environmentalScale));
		distance = Math.round(distance * 100) / 100;
		if (distance > maxDistance) {
			distance = maxDistance
		} else if (distance < minDistance) {
			distance = minDistance;
		}
		return Math.floor(distance * 3.28084);
	}

	public static async delay(milisec) {
		return new Promise(resolve => {
			setTimeout(() => { resolve('') }, milisec);
		})
	}

}
