import { Component, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';
//// Table
import { MatPaginator, } from '@angular/material/paginator';
import { MatTableDataSource,  } from '@angular/material/table';
import { MatSort, } from '@angular/material/sort';
import { TelemetryViewModelImplemented } from '../../viewmodels/Telemetry.vmi';

@Component({
	selector: 'generic-table-component',
	templateUrl: './generic-table.component.html',
	styleUrls: ['./generic-table.component.css'],
})
export class GenericTableComponent implements OnInit {

	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
	
	public telemetryViewModelImplemented:TelemetryViewModelImplemented[] = [];

	constructor(
		public changeDetectorRef:ChangeDetectorRef,
	){

	}

	ngOnInit() {}

	public refresh( telemetries:TelemetryViewModelImplemented[]) {
		this.telemetryViewModelImplemented = telemetries;
		console.log("Refresh called on GenericTableComponent");
		this.tableRender(telemetries);
		this.changeDetectorRef.detectChanges();
	}

	public flattenJSON(obj={}, res = {}, extraKey = '') {
		var keys = Object.keys(obj);
		for (let index = 0; index < keys.length; index++) {
			const key = keys[index];
			if(typeof obj[key] !== 'object'){
				if(obj[key]) //only add if value exists. Note filters out values of 0
				{
					res[extraKey + key] = obj[key];
				}
			}else{
				this.flattenJSON(obj[key], res, `${extraKey}${key}.`);
			};
		}
		return res;
	};

	public selectedRowIndex: number = -1;
	public dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [];
	fullColumnOptions : string[] = [];
	public dropdownSettings = {
		singleSelection: false,
		allowSearchFilter: true,
		itemsShowLimit: 0,
	};

	public compareWith(e1, e2) {
		// fallback to single element comparison
		return e1 && e2 ? e1.id === e2.id : e1 === e2;
	}

	displayColumnsChange( event ) {
		this.displayedColumns = event.detail.value;
	}

	// TODO :: Sashi i think you were working on this here
	onItemSelect(event){
		console.warn("!!WARN : Not Implemented : ", event);
	}
	onSelectAll(event){
		console.warn("!!WARN : Not Implemented : ", event);
	}
	
	public tableRender(telemetries: TelemetryViewModelImplemented[]) {
		var data_object = telemetries.map((t) => {
			var viewObject = t.toViewObject();
			var flatproto = this.flattenJSON(viewObject.protobuf.telemetry);
			var final:any = {}
			// // Any leading or customized i guess for the table
			// final.timeStamp = viewObject.timeStampFormatted;
			// final.batteryVoltageCentivolts = viewObject.protobuf.battery.batteryVoltageCentivolts;
			// final.epochMiliseconds = viewObject.protobuf.sentMs;
			final = {...final, ...flatproto,};
			if(this.fullColumnOptions.length <= 0){
				this.fullColumnOptions = Object.keys(final);
				this.displayedColumns = Object.keys(final);
			}
			return final
		})

		// console.log("this.fullColumnOptions",this.fullColumnOptions);

		this.dataSource = new MatTableDataSource<any>(data_object);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.changeDetectorRef.detectChanges();
	}

}
