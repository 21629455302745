import { AllServcies } from '../startup/startup.service';
import * as Settings from "./settings.json"
import { BehaviorSubject } from 'rxjs';
export class SettingsService {
	public SETTINGS:any = {};

	public sub: BehaviorSubject<any> = new BehaviorSubject(this.SETTINGS);

	constructor(
	)
	{
		this.SETTINGS = JSON.parse(JSON.stringify(Settings));
		if(this.SETTINGS.default != undefined){
			this.SETTINGS = this.SETTINGS.default;
		}
	}

	init(allServices:AllServcies, systemSettings?:any) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
				if(systemSettings){
					this.SETTINGS = Object.assign(this.SETTINGS, systemSettings);
				}

				resolve(true);
			}
		});
	}

	async afterInit(){
		// TODO :: .. get the app settings from gun (if they're available)
		// Then we can change things such as colors, text, names or whatever live
		// without recompiling as well as having settings for multiple apps
		if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.lightActive = false;
		}
		if( this.SETTINGS.APP_DEFAULT_LIGHT ){
			this.lightActive = true;
		}
		await this.services.data?.getByKey("lightActive").then( (result) => {
			this.lightActive = result;
		}).catch( (e) => {
			console.warn("[OK] [settings] : lightActive not set yet ");
		})

		this.SETTINGS.APP_SHOW_LOCAL_ADMIN_TOOLS = false;
		await this.services.data?.getByKey("isLocaAdminMode").then( (result)=> {
			this.SETTINGS.APP_SHOW_LOCAL_ADMIN_TOOLS = result;
		}).catch( (e) => {
			console.warn("[OK] [settings] : isLocaAdminMode not set yet ");
		});

	}

	private services:AllServcies;
	public setServices(services){
		this.services = services
	}

	public lightActive:boolean = true;
	public setLightActive(light:boolean){
		this.lightActive = light;
		this.services.data?.saveByKey("lightActive", light).then( (res) => {
			console.log("saved lightActive: ", res);
		})
		.catch( (e) => {
			console.error(e);
		})
		this.sub.next({LIGHT_ACTIVE:light});
	}

	public userSetStartupId(id:number) : Promise<boolean>{
		return new Promise( async (resolve, reject) => {
			this.services.data?.saveByKey("idActiveOverride", id).then( (res) => {
				console.log("saved idActiveOverride: ", res);
				resolve(true);
			})
			.catch( (e) => {
				console.error(e);
				reject(e);
			})
		});
	}
	public userClearActiveAppId() : Promise<boolean>{
		return new Promise( async (resolve, reject) => {
			this.services.data?.deleteByKey("idActiveOverride").then( (res) => {
				console.log("deleted idActiveOverride: ", res);
				resolve(true);
			})
			.catch( (e) => {
				console.error(e);
				reject(e);
			})
		});
	}

	public startupSetStartupId(id:number){
		this.SETTINGS.APP_ID = id;
		this.SETTINGS.OVER_RIDE_APP_ID = id;
	}
}
