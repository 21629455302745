
import { BluetoothConnection, } from "./ble.connetion"

import { SensorViewModelImplemented } from '../../viewmodels/sensor.vmi';
import { AllServcies } from '../startup/startup.service';
import { BluetoothCommonInterface, BluetoothService } from './bluetooth.service';
import { AllSensorVMI } from '../../viewmodels/AllSensors.vmi'; 
import { DeviceTypeIds, DeviceViewModelImplemented } from "../../viewmodels/Device.vmi";
import { AllDevicesVMI } from "../../viewmodels/AllDevices.vmi";
import { ConnectionInformation } from "../../generated_proto/protobuf-ts/pb/v2/entities";

import { Characteristic } from "@abandonware/noble"

// process.env.BLUETOOTH_HCI_SOCKET_USB_VID=0x0a12
// process.env.BLUETOOTH_HCI_SOCKET_USB_PID=0x0001

export class ElecBleService {

	private log_prefix:string = " :: ElecBleService : ";

	private bluetooth:any = null;
	private bluetooth_poweredOn:boolean = false;
	private bluetoothService:BluetoothService;

	constructor
	(
	)
	{

	}

	init(bluetoothService:BluetoothService, allServices:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(bluetoothService==null || allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.bluetoothService = bluetoothService;
				this.setServices(allServices);
				if(!this.services.platform){
					reject({code:1, message:"Platform Service Not Given"});
					return;
				}
				if(this.services.platform.is('electron')){
					// Import the exposed API object
					this.bluetooth = (<any>window).electronBluetoothBridgeAPI;
					// Access the exposed API functions
					var answerAlive = await this.bluetooth.alive(); // Logs 'hello' to the console and returns 'hello'			
					if(!answerAlive || answerAlive.length < 1){
						reject({code:2, message:"Bluetooth Bridge Not Alive"});
						return;
					}
					this.bluetooth_poweredOn = false;
					if(this.bluetooth){
						this.bluetooth.on('stateChange', (state) => {
							console.log("BLE Changed state:  ", state);
							if (state === 'poweredOn') {
								this.bluetooth_poweredOn = true;
							} else {
								this.bluetooth_poweredOn = false;
							}
						});
					}
				}
				resolve(true);
			}
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	// {
	// 	id: '<id>',
	// 	address: '<BT address'>, // Bluetooth Address of device, or 'unknown' if not known
	// 	addressType: '<BT address type>', // Bluetooth Address type (public, random), or 'unknown' if not known
	// 	connectable: trueOrFalseOrUndefined, // true or false, or undefined if not known
	// 	advertisement: {
	// 	  localName: '<name>',
	// 	  txPowerLevel: someInteger,
	// 	  serviceUuids: ['<service UUID>', ...],
	// 	  serviceSolicitationUuid: ['<service solicitation UUID>', ...],
	// 	  manufacturerData: someBuffer, // a Buffer
	// 	  serviceData: [
	// 		  {
	// 			  uuid: '<service UUID>',
	// 			  data: someBuffer // a Buffer
	// 		  },
	// 		  // ...
	// 	  ]
	// 	},
	// 	rssi: integerValue,
	// 	mtu: integerValue // MTU will be null, until device is connected and hci-socket is used
	//   };
	public getScan(milliseconds_scan:number=2000, allowUnknowns:boolean=false, filterByDeviceType:DeviceTypeIds[]=[]) : Promise<AllDevicesVMI[]> {
		return new Promise<AllDevicesVMI[]>( (resolve, reject)=> {
			console.log("elec getScan");
			var devicesVMI:AllDevicesVMI[] = [];
			if(!this.services.platform){
				reject({code:1, message:"Platform Service Not Given"});
				return;
			}
			if(this.services.platform.is('electron')){
				// https://github.com/abandonware/noble#start-scanning
				this.bluetooth.startScanning([], true);
				this.bluetooth.on('discover', async (peripheral) => {
					try{
						// console.log("peripheral: ", peripheral)
						var newDeviceVMI:AllDevicesVMI;
						var formatted:BluetoothCommonInterface = {
							name:peripheral.advertisement.localName || "unknown",
							context:peripheral,
							identifier:peripheral.uuid,
							manufactureData:peripheral.advertisement.manufacturerData,
							rssi:peripheral.rssi,
						}
						// console.log("peripheral.advertisement.localName: ", peripheral.advertisement.localName)

						// find and dedup before we get here because its expenseive to make new vmis if we're not going to use them.
						newDeviceVMI = await this.bluetoothService.makeDeviceWithType(formatted, allowUnknowns);
						newDeviceVMI.isLocal = true;
						if(!newDeviceVMI.bluetoothConnection){
							reject({code:2, message:"Bluetooth Connection Not Found"});
							return;
						}
						newDeviceVMI.bluetoothConnection.setContext(peripheral);
						if(peripheral.rssi){
							newDeviceVMI.bluetoothConnection.rssi = peripheral.rssi;
						}
						if(newDeviceVMI.model.device){
							newDeviceVMI.model.device.connectionInfo = ConnectionInformation.create();
							newDeviceVMI.model.device.connectionInfo.lastConnectionMs = BigInt(Date.now());
						}

						// Use filter via the settings
						if(this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES && this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES.length){
							for(var i=0; i<this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES.length; i++){
								var deviceType = this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES[i]
								// if not in filterByDeviceType, add it
								if(filterByDeviceType.indexOf(deviceType) == -1){
									filterByDeviceType.push(deviceType);
								}
							}
						}
						// use filter via the parameter
						if(filterByDeviceType.length>0){
							var found = false;
							for(var i=0; i<filterByDeviceType.length; i++){
								if(newDeviceVMI.model.device?.deviceType == filterByDeviceType[i]){
									found = true;
									break;
								}
							}
							if(!found){
								return;
							}
						}
						var found = false;
						// Deduplication -> some platforms dont do this correctly, so checking ourselves.
						for(var i = 0; i < devicesVMI.length; i++) {
							if(!devicesVMI[i] || !devicesVMI[i].bluetoothConnection){
								continue;
							}
							else if (devicesVMI && devicesVMI[i] && devicesVMI[i].bluetoothConnection != null && devicesVMI[i].bluetoothConnection!.bleContext.uuid == peripheral.uuid) {
								found = true;
								break;
							}
						}
						if(!found){
							devicesVMI.push(newDeviceVMI);
						}
					}
					catch(e){
						// console.log("E: ", e)
					}
				});

				var firstScan:boolean = true;
				var waitScan = setInterval(() => {
					if(!firstScan){
						this.bluetooth.stopScanning();
						devicesVMI = devicesVMI.sort((a, b) => {
							if( b.bluetoothConnection && a.bluetoothConnection){
								return b.bluetoothConnection.rssi-a.bluetoothConnection.rssi
							}
							else{
								return 0;
							}
						})
						clearInterval(waitScan);
						resolve(devicesVMI);
					}
					else {
						if(firstScan){
							setTimeout( () => {
								firstScan = false;
							}, milliseconds_scan);
						}
					}
				}, milliseconds_scan);
			}
			else {
				reject(" :: Not an electron platform ::");
			}
		});
	}

	public async getScanByName(serachName:string, timeout:number=2000) : Promise<AllDevicesVMI>{
		return new Promise<AllDevicesVMI>( (resolve, reject)=> {
			console.log("elec getScanByName");
			var deviceVMI:AllDevicesVMI;
			if(!this.services.platform){
				reject({code:1, message:"Platform Service Not Given"});
				return;
			}
			if(this.services.platform.is('electron')){
				// https://github.com/abandonware/noble#start-scanning
				this.bluetooth.startScanning([], true);
				this.bluetooth.on('discover', async (peripheral) => {
					if(peripheral.advertisement.localName && (peripheral.advertisement.localName == serachName)){
						console.log("Found Name: ", peripheral.advertisement.localName)
						try{
							var newDeviceVMI:AllDevicesVMI;
							var formatted:BluetoothCommonInterface = {
								name:peripheral.advertisement.localName || "unknown",
								context:peripheral,
								identifier:peripheral.uuid,
								manufactureData:peripheral.advertisement.manufacturerData,
								rssi:peripheral.rssi,
							}
							// find and dedup before we get here because its expenseive to make new vmis if we're not going to use them.
							newDeviceVMI = await this.bluetoothService.makeDeviceWithType(formatted, false);
							newDeviceVMI.isLocal = true;
							if(!newDeviceVMI.bluetoothConnection){
								reject({code:2, message:"Bluetooth Connection Not Found"});
								return;
							}
							newDeviceVMI.bluetoothConnection.setContext(peripheral);
							if(peripheral.rssi){
								newDeviceVMI.bluetoothConnection.rssi = peripheral.rssi;
							}
							if(newDeviceVMI.model.device){
								newDeviceVMI.model.device.connectionInfo = ConnectionInformation.create();
								newDeviceVMI.model.device.connectionInfo.lastConnectionMs = BigInt(Date.now());
							}
	
							// Use filter via the settings
							var filterByDeviceType:DeviceTypeIds[] = [];
							if(this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES && this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES.length){
								for(var i=0; i<this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES.length; i++){
									var deviceType = this.services.settings?.SETTINGS.APP_SHOW_ONLY_DEVICE_TYPES[i]
									// if not in filterByDeviceType, add it
									if(filterByDeviceType.indexOf(deviceType) == -1){
										filterByDeviceType.push(deviceType);
									}
								}
							}
							// use filter via the parameter
							if(filterByDeviceType.length>0){
								var found = false;
								for(var i=0; i<filterByDeviceType.length; i++){
									if(newDeviceVMI.model.device?.deviceType == filterByDeviceType[i]){
										found = true;
										break;
									}
								}
								if(!found){
									return;
								}
							}
							deviceVMI = newDeviceVMI;
							this.bluetooth.stopScanning();
							resolve(deviceVMI);
							return;
						}
						catch(e){
						}
					}
				});

				var firstScan:boolean = true;
				var waitScan = setInterval(() => {
					if(!firstScan){
						this.bluetooth.stopScanning();
						clearInterval(waitScan);
						if(!deviceVMI){
							reject("timeout on getScanByName : " + serachName);
						}
					}
					else {
						if(firstScan){
							setTimeout( () => {
								firstScan = false;
							}, timeout);
						}
					}
				}, timeout);
			}
			else {
				reject(" :: Not an electron platform ::");
			}
		});
	}

	public async connect( bluetoothConnection:BluetoothConnection ) : Promise<boolean> {
		console.log("Electron calling to connect")
		return await new Promise( async (resolve, reject) => {
			if(bluetoothConnection.getContext() == null || bluetoothConnection.getContext() == undefined){
				reject("missing connection");
			}
			console.log(":: Passing to electron connect ::")
			await this.bluetooth.connect(bluetoothConnection.getContext(), ()=>{
				console.log(" :: Frontend sees diconnect to bluetooth ::")
				bluetoothConnection.setIsConnected(false);
			}).then( (connected) => {
				bluetoothConnection.setIsConnected(connected);
				resolve(connected);
			})
			.catch( (e) => {
				console.error(this.log_prefix, "connect: ", e);
				reject(e);
			})
		});
	}

	public async disconnect( bluetoothConnection:BluetoothConnection ) : Promise<boolean> {
		return await new Promise( (resolve, reject) => {
			if(bluetoothConnection.getContext()){
				this.bluetooth.disconnect(bluetoothConnection.getContext(), (disconnected)=>{
					if(disconnected){
						var context = bluetoothConnection.getContext();
						delete(context.services);
						context.services = null;
						delete(context.characteristics);
						context.characteristics = null;
						delete(bluetoothConnection.bleContext);
						bluetoothConnection.bleContext = null;
						bluetoothConnection.setContext(null);
						// console.log(" :: DISCONNECTED ::");
						resolve(true);
					}
					else {
						reject("disconnect failed");
					}
				})
				.catch( (e) => {
					console.error(this.log_prefix, "disconnect: ", e);
					reject(e);
				})
			}
		});
	}

	public async forceGetCharacteristics(bluetoothConnection:BluetoothConnection) : Promise<any>{
		return await new Promise((resolve, reject) => {
			console.log(" :: discoverAllServicesAndCharacteristics : START :: ", bluetoothConnection.getContext());
			if(bluetoothConnection.getContext()){
				this.bluetooth.forceGetCharacteristics(bluetoothConnection.getContext()).then((done) => {
					if(done) {
						resolve(true);
					}
					else {
						console.error(" :: discoverAllServicesAndCharacteristics failed : ");
						reject(" :: discoverAllServicesAndCharacteristics failed : ");
						return;
					}
				});
			}
			else {
				reject(" :: discoverAllServicesAndCharacteristics failed : ");
				return;
			}
		});
	}

	static async webDiscoveryOverride(callback){
		console.log(" :: webDiscoveryOverride : START :: ")
		var electronBridgeForApi = (<any>window).electronBluetoothBridgeAPI;
		electronBridgeForApi.webDiscoveryOverride(callback);
		console.log(" :: webDiscoveryOverride : END :: ")

	}
	static async selectedDeviceChosenByUser(id:string){
		console.log(" :: selectedDeviceChosenByUser : START :: ", id)
		var electronBridgeForApi = (<any>window).electronBluetoothBridgeAPI;
		electronBridgeForApi.webDiscoverySelected(id);
		console.log(" :: selectedDeviceChosenByUser : END :: ")
	}

	public async writeCharacteristic( bluetoothConnection:BluetoothConnection, characteristic_input:string|number="", data:Uint8Array, withoutResponse:boolean=false ) : Promise<boolean> {
		return new Promise( async (resolve, reject) => {

			if(typeof characteristic_input == "number"){
				console.log("characteristic_input: ", characteristic_input);
				// convert number to hex padded with 4 zeros
				characteristic_input = characteristic_input.toString(16).padStart(4, '0');
			}
			if(!bluetoothConnection.getIsConnected()){
				console.warn("called write when not connected")
				reject("called write when not connected");
				return;
			}
			var context = bluetoothConnection.getContext();
			if(context == null){
				reject(this.log_prefix + "writeCharacteristic : context is missing");
				return;
			}

			await this.bluetooth.writeCharacteristic(bluetoothConnection.getContext(), "", characteristic_input, data, withoutResponse)
			.then( async (done) => {
				console.log("write done: ", done);
				resolve(done);
			}
			).catch( (e) => {
				reject(e);
			});
		});
	}

	public readCharacteristic( bluetoothConnection:BluetoothConnection, service_input:string|number="", characteristic_input:string|number="" ) : Promise<Uint8Array> {
		return new Promise( async (resolve, reject) => {
			var hasResolveReject:boolean = false;
			setTimeout( () => {
				if(!hasResolveReject){
					hasResolveReject = true;
					if(bluetoothConnection && bluetoothConnection.disconnect){
						bluetoothConnection.disconnect();
					}
					reject("timeout");
					hasResolveReject = true;
				}
			}
			, 5000);
			if(typeof characteristic_input == "number"){
				// convert number to hex padded with 4 zeros
				characteristic_input = characteristic_input.toString(16).padStart(4, '0');
			}
			if(!bluetoothConnection.getIsConnected()){
				console.warn("called write when not connected")
				hasResolveReject = true;
				reject("called write when not connected");
				return;
			}
			var context = bluetoothConnection.getContext();
			if(context == null){
				hasResolveReject = true;
				reject(this.log_prefix + "readCharacteristic : context is missing");
				return;
			}
			await this.bluetooth.readCharacteristic(bluetoothConnection.getContext(), service_input, characteristic_input)
			.then( async (value:Uint8Array) => {
				hasResolveReject = true;
				resolve(value);
			})
			.catch( (e) => {
				reject(e);
			});
		});
	}

	public async subscribeCharacteristic( bluetoothConnection:BluetoothConnection, service_input:string|number="", characteristic_input:string|number="", cb:(data:Uint8Array)=>void ) : Promise<boolean>{
		return new Promise( async (resolve, reject)=>{
			console.log("subscribeCharacteristic");
			var hasResolveReject:boolean = false;
			setTimeout( () => {
				if(!hasResolveReject){
					hasResolveReject = true;
					if(bluetoothConnection && bluetoothConnection.disconnect){
						bluetoothConnection.disconnect();
					}
					reject("timeout");
					hasResolveReject = true;
				}
			}
			, 5000);
			if(!bluetoothConnection.getIsConnected()){
				console.warn("called write when not connected")
				reject("called write when not connected")
				return;
			}
			if(typeof characteristic_input == "number"){
				console.log("characteristic_input: ", characteristic_input);
				characteristic_input = characteristic_input.toString(16).padStart(4, '0');
			}
			var context = bluetoothConnection.getContext();
			if(context == null){
				reject(this.log_prefix + "subscribeCharacteristic : context is missing");
				return;
			}
			await this.bluetooth.subscribeCharacteristic(bluetoothConnection.getContext(), service_input, characteristic_input, cb)
			.then( async (hasSubscribed:boolean) => {
				hasResolveReject = true;
				resolve(hasSubscribed);
			})
			.catch( (e) => {
				reject(e);
			});
			
		});
	}

	// Underlying lib treats notify and indicate the same for electron w/characteristic.subscribe() but, broke it out anyways if it needs some future specific things.
	public async indicateCharacteristic( bluetoothConnection:BluetoothConnection, characteristic_string:string="", cb:(data:Uint8Array)=>void ) : Promise<boolean>{
		return new Promise( async (resolve, reject)=>{
			console.log("indicateCharacteristic");
			if(!bluetoothConnection.getIsConnected()){
				console.warn("called write when not connected")
				reject("called write when not connected")
				return;
			}
			var context = bluetoothConnection.getContext();
			if(context == null){
				reject(this.log_prefix + "indicateCharacteristic : context is missing");
				return;
			}
			reject("This isnt implemented yet");
			// var characteristic:any = null;
			// await this.getCharacteristic(bluetoothConnection, characteristic_string).then( (char) => {
			// 	console.log("indicateCharacteristic found characteristic: ", char);
			// 	characteristic = char;
			// })
			// .catch( (e) => {
			// 	reject(e);
			// })
			// if(characteristic){
			// 	console.log("indicateCharacteristic start sub ");
			// 	characteristic.subscribe((e)=>{
			// 		console.log("indicateCharacteristic in sub");
			// 		if(e){
			// 			console.error("indicateCharacteristic failed : ", e);
			// 		}
			// 		characteristic.on('data', (data:Buffer) => {
			// 			console.log("Charictersitic on indicate : data : ", data);
			// 			// bluetoothConnection.processStreamingData(Uint8Array.from(data))
			// 			cb(Uint8Array.from(data))
			// 		});
			// 		resolve(true);
			// 	});
			// }
			// else {
			// 	reject("charactersitic not found");
			// }
		});
	}


}
