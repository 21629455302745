import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bigint'
})
export class BigintPipe implements PipeTransform {
	transform(value: bigint, ...args: unknown[]): number {
		return Number(value);
	}
}
