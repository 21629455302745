import { AllServcies } from '../startup/startup.service';

import { DeviceGroupViewModelImplemented } from '../../viewmodels/DeviceGroup.vmi';
import { ModelType } from '../../generated_proto/protobuf-ts/pb/v2/models';
import { FirmwareFileViewModelImplemented } from '../../viewmodels/FirmwareFile.vmi';
import { LoggerOptions } from '../logger/logger.service';


export class DeviceManagementPouchdbService {

	public loggerOptions:LoggerOptions = {
		prefix:"DeviceManagementPouchdbService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	constructor(
	)
	{

	}

	public init(allServices:AllServcies) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {

			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
			}
			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getFirmwarelist( app_id:number ) : Promise<FirmwareFileViewModelImplemented[]> {
		var firmwareList:FirmwareFileViewModelImplemented[] = [];
		var searchOptions = {
			include_docs: true,
			attachments: false,
		}
		return new Promise(async (resolve, reject) => {
			if(!this.services.data || !this.services.pouch){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.FIRMWARE_FILE_MODEL);
			db.remoteDb.allDocs(searchOptions)
			.then( (result:any) => {
				if(result.rows.length>0){
					for (let index = 0; index < result.rows.length; index++) {
						const row = result.rows[index];
						const doc = row.doc;
						if(doc._id[0] == "_" ){
							continue;
						}
						var newFirmware:FirmwareFileViewModelImplemented = new FirmwareFileViewModelImplemented(this.services);
						newFirmware.updateLocalFromDb(doc);
						firmwareList.push(newFirmware);
					}
					resolve(firmwareList);
				}
				else {
					resolve([]);
				}
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

	public getFirmwareByKey( app_id:number, key:string ) : Promise<FirmwareFileViewModelImplemented> {
		return new Promise( async (resolve, reject) => {
			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}
			var _id = key;
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.FIRMWARE_FILE_MODEL);
			db.remoteDb.get(_id)
			.then((doc) => {
				var newFirmware:FirmwareFileViewModelImplemented = new FirmwareFileViewModelImplemented(this.services);
				newFirmware.updateLocalFromDb(doc);
				resolve(newFirmware);
			})
			.catch((err) => {
				reject(err);
			});
		})

	}

	public getFirmwareInGroups(app_id:number, firmwareFile:FirmwareFileViewModelImplemented) : Promise<string[]> {
		return new Promise( async (resolve, reject) => {
			this.getDeviceGroupList(app_id).then( (deviceGroups:DeviceGroupViewModelImplemented[]) => {
				var fileUrn = firmwareFile.model.db?.urn || "";
				if(deviceGroups.length>0){
					var deviceGroupUrnsFound:string[] = [];
					for (let index = 0; index < deviceGroups.length; index++) {
						const deviceGroup = deviceGroups[index];
						if(deviceGroup.model.deviceGroup && deviceGroup.model.deviceGroup.partitions?.length>0){
							var deviceGroupUrn = deviceGroup.model.db?.urn||"";
							for (let index = 0; index < deviceGroup.model.deviceGroup.partitions.length; index++) {
								const partition = deviceGroup.model.deviceGroup.partitions[index];
								partition.firmwareFileUrns.map( (firmwareFileUrn) => {
									if(firmwareFileUrn==fileUrn){
										deviceGroupUrnsFound.push(deviceGroupUrn);
									}
								});
							}
						}
					}
					resolve(deviceGroupUrnsFound);
				}
				else {
					resolve([]);
				}
			}).catch( (err) => {
				reject(err);
			});
		});
	}

	public deleteFirmwareFile(app_id:number, firmwareFile:FirmwareFileViewModelImplemented, deleteFile:boolean=true) : Promise<boolean>{
		return new Promise<boolean>( async (resolve, reject) => {
			try{
				if(!this.services.pouch){
					reject({code:0, message:"pouch Not Given"});
					return;
				}
				if(!this.services.data){
					reject({code:0, message:"data Not Given"});
					return;
				}
				var groupUrns:string[] = await this.getFirmwareInGroups(app_id, firmwareFile);
				if(groupUrns.length<=0){
					var _id = firmwareFile.generateDbKey();
					var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.FIRMWARE_FILE_MODEL);
					await db.remoteDb.get(_id)
					.then( (doc:any) => {
						db.remoteDb.remove(doc).then( () => {
							if(deleteFile){
								
								this.services.pouch?.files.deleteFile(app_id, firmwareFile.file);
							}
							resolve(true)
						}).catch( (err) => {
							this.services.logger?.error(this.loggerOptions,"[deleteFirmwareFile::remove]", err);
							reject(err)
						});
					}).catch( (err) => {
						this.services.logger?.error(this.loggerOptions,"[deleteFirmwareFile::get]", err);
						reject(err)
					});
				}
				else {
					var idsOnly = groupUrns.map( (groupUrn) => {
						var dbKeySplit = groupUrn.split("/");
						var deviceGroupIdSplit = dbKeySplit[dbKeySplit.length-1].split(",");
						var deviceGroupId = deviceGroupIdSplit[2];
						if(deviceGroupId){
							return deviceGroupId;
						}
					});
					reject({code:0, message:"Firmware File In Use", groupIds:idsOnly});
				}
			}
			catch(err){
				this.services.logger?.error(this.loggerOptions,"[deleteFirmwareFile]", err);
				reject(err);
			}
		});
	}

	public saveFirmwareFile( app_id:number, firmwareFile:FirmwareFileViewModelImplemented, isNew:boolean=true ) : Promise<boolean>{
		return new Promise<boolean>(async (resolve, reject) => {
			if(!firmwareFile.model.firmware){
				reject({code:0, message:"No Firmware File"});
				return;
			}
			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}

			var _id = firmwareFile.generateDbKey();
			var full_doc:any = firmwareFile.modelToJson();
			full_doc._id = _id;
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.FIRMWARE_FILE_MODEL);
			db.remoteDb.get(_id)
			.then((doc) => {
				if(isNew){
					reject("File already exist")
				}
				else {
					db.remoteDb.put(Object.assign(doc, full_doc))
					.then((result) => {
						resolve(true);
					})
					.catch((err) => {
						reject(err);
					})
				}
			})
			.catch((err) => {
				if(err.status != 404){
					reject(err);
					return;
				}
				if(isNew){
					db.remoteDb.put(full_doc) // save full doc
					.then( () => {
						resolve(true)
					})
					.catch( (err) => {
						this.services.logger?.error(this.loggerOptions,"[saveFirmwareFile]", err);
						reject(true)
					});
				}
				else{
					reject("File not found")
				}
			});
		})
	}

	public getDeviceGroupList(app_id:number) : Promise<DeviceGroupViewModelImplemented[]> {
		var deviceGroupList:DeviceGroupViewModelImplemented[] = [];
		var searchOptions = {
			include_docs: true,
			attachments: false,
		}
		return new Promise( async (resolve, reject) => {
			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_GROUP_MODEL);
			db.remoteDb.allDocs(searchOptions)
			.then( (result:any) => {
				if(result.rows.length>0){
					for (let index = 0; index < result.rows.length; index++) {
						const row = result.rows[index];
						const doc = row.doc;
						if(doc._id[0] == "_" ){
							continue;
						}
						var newDeviceGroup:DeviceGroupViewModelImplemented = new DeviceGroupViewModelImplemented(this.services);
						newDeviceGroup.updateLocalFromDb(doc);
						deviceGroupList.push(newDeviceGroup);
					}
					resolve(deviceGroupList);
				}
				else {
					resolve([]);
				}
			}).catch( (err) => {
				this.services.logger?.error(this.loggerOptions,"[getDeviceGroupList]", err);
				reject(err)
			});
		})
	}

	public getDeviceGroup( app_id:number, groupIdQuery:number, variant:number ) : Promise<DeviceGroupViewModelImplemented> {
		console.log("getDeviceGroupgetDeviceGroupgetDeviceGroup: ", groupIdQuery, variant);
		var _id = DeviceGroupViewModelImplemented.GenerateDbKey(groupIdQuery, variant);
		return new Promise( async (resolve, reject) => {
			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_GROUP_MODEL);
			db.remoteDb.get(_id)
			.then( (doc:any) => {
				var newDeviceGroup:DeviceGroupViewModelImplemented = new DeviceGroupViewModelImplemented(this.services);
				newDeviceGroup.updateLocalFromDb(doc);
				resolve(newDeviceGroup);
			}).catch( (err) => {
				this.services.logger?.verbose(this.loggerOptions,`[getDeviceGroup] :: Device group ${groupIdQuery} not found ::`);
				reject(err)
			});

			// If there is a variation that fails, get the original (if possible)
			if(variant != 0){
				_id = DeviceGroupViewModelImplemented.GenerateDbKey(groupIdQuery, 0);
				db.remoteDb.get(_id)
				.then( (doc:any) => {
					var newDeviceGroup:DeviceGroupViewModelImplemented = new DeviceGroupViewModelImplemented(this.services);
					newDeviceGroup.updateLocalFromDb(doc);
					resolve(newDeviceGroup);
				}).catch( (err) => {
					this.services.logger?.verbose(this.loggerOptions,`[getDeviceGroup] :: Device group ${groupIdQuery} not found ::`);
					reject(err)
				});
			}
		})
	}

	public saveDeviceGroup(app_id:number, deviceGroup:DeviceGroupViewModelImplemented ) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			deviceGroup.updateDb();
			var _id = deviceGroup.generateDbKey();
			var full_doc:any = deviceGroup.modelToJson();
			full_doc._id = _id;

			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_GROUP_MODEL);
			db.remoteDb.get(_id)
			.then((doc) => {
				doc = Object.assign(doc, full_doc)
				db.remoteDb.put(doc) // save full doc
				.then( () => {
					resolve(true)
				})
				.catch( (err) => {
					this.services.logger?.error(this.loggerOptions,"[saveDeviceGroup::update]", err);
					reject(true)
				});
			})
			.catch((err) => {
				if(err.status != 404){
					reject(err);
					return;
				}
				db.remoteDb.put(full_doc) // save full doc
				.then( () => {
					resolve(true)
				})
				.catch( (err) => {
					this.services.logger?.error(this.loggerOptions,"[saveDeviceGroup::new]", err);
					reject(true)
				});
			});
		})
	}

	public deleteDeviceGroup( app_id:number, deviceGroup:DeviceGroupViewModelImplemented ) : Promise<boolean>{
		return new Promise( async (resolve, reject) => {
			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_GROUP_MODEL);
			deviceGroup.updateDb();
			var _id = deviceGroup.generateDbKey();

			console.log("deleteDeviceGroupdeleteDeviceGroupdeleteDeviceGroup: ", _id);
			if(deviceGroup.model.deviceGroup?.deviceGroupIdVariant == 0){
				// check if all other variants are deleted
				var searchOptions = {
					include_docs: false,
					attachments: false,
				}
				await db.remoteDb.allDocs(searchOptions).then( (result:any) => {
					console.log("deleteDeviceGroupdeleteDeviceGroupdeleteDeviceGroup: ", result	)
					if(result.rows.length>0){
						for (let index = 0; index < result.rows.length; index++) {
							const doc = result.rows[index];
							var docSplit = doc.id.split(",");
							if(docSplit[2] == deviceGroup.model.deviceGroup?.deviceGroupId.toString()){
								if(docSplit[3]){
									reject("Variants still exist - Delete all variants first");
									return;
								}
							}
						}
						db.remoteDb.get(_id)
						.then( (doc:any) => {
							db.remoteDb.remove(doc).then( () => {
								resolve(true)
							}).catch( (err) => {
								this.services.logger?.error(this.loggerOptions,"[deleteDeviceGroup::remove]", err);
								reject(err)
							});
						}).catch( (err) => {
							this.services.logger?.error(this.loggerOptions,"[deleteDeviceGroup::get]", err);
							reject(err)
						});
					}
				}).catch( (err) => {
					console.error(err);
					reject(err)
					return;
				});
			}
			else {
				db.remoteDb.get(_id)
				.then( (doc:any) => {
					db.remoteDb.remove(doc).then( () => {
						resolve(true)
					}).catch( (err) => {
						this.services.logger?.error(this.loggerOptions,"[deleteDeviceGroup::remove]", err);
						reject(err)
					});
				}).catch( (err) => {
					this.services.logger?.error(this.loggerOptions,"[deleteDeviceGroup::get]", err);
					reject(err)
				});
			}
		})
	}

	checkGroupId( app_id:number, groupIdQuery:number ) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(groupIdQuery == 0){
				reject("Group id must be greater than 0");
			}
			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}
			var searchOptions = {
				include_docs: false,
				attachments: false,
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.DEVICE_GROUP_MODEL);
			db.remoteDb.allDocs(searchOptions).then( (result:any) => {
				if(result.rows.length>0){
					for (let index = 0; index < result.rows.length; index++) {
						const doc = result.rows[index];
						if(doc._id[0] == "_" ){
							continue;
						}
						var docSplit = doc.id.split(",");
						if(docSplit[2] == groupIdQuery){
							resolve(false);
							return;
						}
					}
					resolve(true);
					return;
				}
			}).catch( (err) => {
				console.error(err);
				reject(err)
			});
		});
	}
}
