import { AreaViewModelImplemented } from "./area.vmi";
import { GeoNodeViewModelImplemented, } from "./geonode.vmi";
import { RoomViewModelImplemented } from "./room.vmi";

export class FloorViewModelImplemented extends GeoNodeViewModelImplemented {

	public areaVMIs: AreaViewModelImplemented[];
	public roomVMIs: RoomViewModelImplemented[];

	public uploadFloorPlanImage(file: File) {

	}

	public ionItemDescription: any;
	public getIonItemDescription(): any {
		if (this.ionItemDescription == null) {
			this.ionItemDescription = this.buildIonItemDescription();
		}
		return this.ionItemDescription;
	}
	public buildIonItemDescription(): any {
		var html = `
			<div>
				<ion-label> 
					<div class="ion-label-body">
						UUID: ${this.getUUID()}<br>
						Created: ${new Date(Number(this.model?.db?.updatedMs || 0)).toLocaleDateString()} ${new Date(Number(this.model?.db?.updatedMs || 0)).toLocaleTimeString()}
					</div>
				</ion-label>
			</div>
			`;

		if (this.services.domSanitizer) this.ionItemDescription = this.services.domSanitizer.bypassSecurityTrustHtml(html);
		return this.ionItemDescription;
	}

}