// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/v2/server.proto" (package "pb.v2", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// //////////////////////////////
// / System Generated Information

/**
 * @generated from protobuf message pb.v2.ServerEventLog
 */
export interface ServerEventLog {
    /**
     * @generated from protobuf field: string from = 1;
     */
    from: string; // User/System that created the log .. this can be >> by levels ::service:urpc:device_urn
    /**
     * @generated from protobuf field: uint32 duration_ms = 2;
     */
    durationMs: number;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: bytes payload = 4;
     */
    payload: Uint8Array;
    /**
     * @generated from protobuf field: bytes attachment = 5;
     */
    attachment: Uint8Array; // Optional attachment of datat to the database document
    /**
     * @generated from protobuf field: string attachment_url = 6;
     */
    attachmentUrl: string; // Optional url to any other source of data (IE: Attachment URL)
    /**
     * @generated from protobuf field: string meta_data = 7;
     */
    metaData: string;
}
/**
 * @generated from protobuf message pb.v2.UserAppManagement
 */
export interface UserAppManagement {
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * @generated from protobuf field: string user_urn = 2;
     */
    userUrn: string;
    /**
     * @generated from protobuf field: bool is_app_admin = 3;
     */
    isAppAdmin: boolean;
    /**
     * @generated from protobuf field: uint64 last_login_attempt_epoch_ms = 4;
     */
    lastLoginAttemptEpochMs: bigint;
    /**
     * @generated from protobuf field: bool blacklisted = 5;
     */
    blacklisted: boolean;
    /**
     * @generated from protobuf field: pb.v2.UserBlockReason block_reason = 6;
     */
    blockReason: UserBlockReason;
    /**
     * @generated from protobuf field: pb.v2.UserAppManagement.AdminBanned admin_banned = 7;
     */
    adminBanned?: UserAppManagement_AdminBanned;
    /**
     * @generated from protobuf field: pb.v2.UserAppManagement.LoginBanned login_banned = 8;
     */
    loginBanned?: UserAppManagement_LoginBanned;
}
/**
 * @generated from protobuf message pb.v2.UserAppManagement.AdminBanned
 */
export interface UserAppManagement_AdminBanned {
    /**
     * @generated from protobuf field: uint64 epoch_ms = 1;
     */
    epochMs: bigint;
    /**
     * @generated from protobuf field: string admin_user_name = 2;
     */
    adminUserName: string;
}
/**
 * @generated from protobuf message pb.v2.UserAppManagement.LoginBanned
 */
export interface UserAppManagement_LoginBanned {
    /**
     * @generated from protobuf field: uint64 window_first_failure_epoch_ms = 1;
     */
    windowFirstFailureEpochMs: bigint;
    /**
     * @generated from protobuf field: uint64 window_last_failure_epoch_ms = 2;
     */
    windowLastFailureEpochMs: bigint;
    /**
     * @generated from protobuf field: uint32 window_login_counter = 3;
     */
    windowLoginCounter: number;
    /**
     * @generated from protobuf field: uint32 total_failed_windows = 4;
     */
    totalFailedWindows: number;
    /**
     * @generated from protobuf field: string login_ip = 5;
     */
    loginIp: string;
}
/**
 * @generated from protobuf message pb.v2.AppManagement
 */
export interface AppManagement {
    /**
     * @generated from protobuf field: repeated pb.v2.UserAppManagement users_managment = 1;
     */
    usersManagment: UserAppManagement[];
    /**
     * @generated from protobuf field: repeated string previous_log_db_pointer = 2;
     */
    previousLogDbPointer: string[];
    /**
     * @generated from protobuf field: repeated pb.v2.AppManagement.BlockedIps blocked_ips = 3;
     */
    blockedIps: AppManagement_BlockedIps[];
    /**
     * @generated from protobuf oneof: events
     */
    events: {
        oneofKind: "serverErrors";
        /**
         * @generated from protobuf field: pb.v2.ServerEventLog server_errors = 4;
         */
        serverErrors: ServerEventLog;
    } | {
        oneofKind: "serverLog";
        /**
         * @generated from protobuf field: pb.v2.ServerEventLog server_log = 5;
         */
        serverLog: ServerEventLog;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message pb.v2.AppManagement.BlockedIps
 */
export interface AppManagement_BlockedIps {
    /**
     * @generated from protobuf field: string ip = 1;
     */
    ip: string;
    /**
     * @generated from protobuf field: bool blocked = 2;
     */
    blocked: boolean;
    /**
     * @generated from protobuf field: uint64 last_epoch_ms = 3;
     */
    lastEpochMs: bigint;
    /**
     * @generated from protobuf field: uint32 block_counter = 4;
     */
    blockCounter: number;
}
/**
 * @generated from protobuf enum pb.v2.UserBlockReason
 */
export enum UserBlockReason {
    /**
     * @generated from protobuf enum value: USER_BLOCK_REASON_UKNOWN = 0;
     */
    USER_BLOCK_REASON_UKNOWN = 0,
    /**
     * @generated from protobuf enum value: USER_BLOCK_REASON_ADMIN_BANNED = 1;
     */
    USER_BLOCK_REASON_ADMIN_BANNED = 1,
    /**
     * @generated from protobuf enum value: USER_BLOCK_REASON_LOGIN_BANNED = 2;
     */
    USER_BLOCK_REASON_LOGIN_BANNED = 2,
    /**
     * @generated from protobuf enum value: USER_BLOCK_IP_BANNED = 3;
     */
    USER_BLOCK_IP_BANNED = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class ServerEventLog$Type extends MessageType<ServerEventLog> {
    constructor() {
        super("pb.v2.ServerEventLog", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "duration_ms", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 32, fixedLength: true } } },
            { no: 4, name: "payload", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 32, fixedLength: true } } },
            { no: 5, name: "attachment", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 32, fixedLength: true } } },
            { no: 6, name: "attachment_url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { nanopb: { maxSize: 32, fixedLength: true } } },
            { no: 7, name: "meta_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ServerEventLog>): ServerEventLog {
        const message = { from: "", durationMs: 0, message: "", payload: new Uint8Array(0), attachment: new Uint8Array(0), attachmentUrl: "", metaData: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ServerEventLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServerEventLog): ServerEventLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* uint32 duration_ms */ 2:
                    message.durationMs = reader.uint32();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* bytes payload */ 4:
                    message.payload = reader.bytes();
                    break;
                case /* bytes attachment */ 5:
                    message.attachment = reader.bytes();
                    break;
                case /* string attachment_url */ 6:
                    message.attachmentUrl = reader.string();
                    break;
                case /* string meta_data */ 7:
                    message.metaData = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServerEventLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* uint32 duration_ms = 2; */
        if (message.durationMs !== 0)
            writer.tag(2, WireType.Varint).uint32(message.durationMs);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* bytes payload = 4; */
        if (message.payload.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.payload);
        /* bytes attachment = 5; */
        if (message.attachment.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.attachment);
        /* string attachment_url = 6; */
        if (message.attachmentUrl !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.attachmentUrl);
        /* string meta_data = 7; */
        if (message.metaData !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.metaData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.ServerEventLog
 */
export const ServerEventLog = new ServerEventLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserAppManagement$Type extends MessageType<UserAppManagement> {
    constructor() {
        super("pb.v2.UserAppManagement", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_urn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_app_admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "last_login_attempt_epoch_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "blacklisted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "block_reason", kind: "enum", T: () => ["pb.v2.UserBlockReason", UserBlockReason] },
            { no: 7, name: "admin_banned", kind: "message", T: () => UserAppManagement_AdminBanned },
            { no: 8, name: "login_banned", kind: "message", T: () => UserAppManagement_LoginBanned }
        ]);
    }
    create(value?: PartialMessage<UserAppManagement>): UserAppManagement {
        const message = { username: "", userUrn: "", isAppAdmin: false, lastLoginAttemptEpochMs: 0n, blacklisted: false, blockReason: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserAppManagement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserAppManagement): UserAppManagement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string username */ 1:
                    message.username = reader.string();
                    break;
                case /* string user_urn */ 2:
                    message.userUrn = reader.string();
                    break;
                case /* bool is_app_admin */ 3:
                    message.isAppAdmin = reader.bool();
                    break;
                case /* uint64 last_login_attempt_epoch_ms */ 4:
                    message.lastLoginAttemptEpochMs = reader.uint64().toBigInt();
                    break;
                case /* bool blacklisted */ 5:
                    message.blacklisted = reader.bool();
                    break;
                case /* pb.v2.UserBlockReason block_reason */ 6:
                    message.blockReason = reader.int32();
                    break;
                case /* pb.v2.UserAppManagement.AdminBanned admin_banned */ 7:
                    message.adminBanned = UserAppManagement_AdminBanned.internalBinaryRead(reader, reader.uint32(), options, message.adminBanned);
                    break;
                case /* pb.v2.UserAppManagement.LoginBanned login_banned */ 8:
                    message.loginBanned = UserAppManagement_LoginBanned.internalBinaryRead(reader, reader.uint32(), options, message.loginBanned);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserAppManagement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string username = 1; */
        if (message.username !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.username);
        /* string user_urn = 2; */
        if (message.userUrn !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userUrn);
        /* bool is_app_admin = 3; */
        if (message.isAppAdmin !== false)
            writer.tag(3, WireType.Varint).bool(message.isAppAdmin);
        /* uint64 last_login_attempt_epoch_ms = 4; */
        if (message.lastLoginAttemptEpochMs !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.lastLoginAttemptEpochMs);
        /* bool blacklisted = 5; */
        if (message.blacklisted !== false)
            writer.tag(5, WireType.Varint).bool(message.blacklisted);
        /* pb.v2.UserBlockReason block_reason = 6; */
        if (message.blockReason !== 0)
            writer.tag(6, WireType.Varint).int32(message.blockReason);
        /* pb.v2.UserAppManagement.AdminBanned admin_banned = 7; */
        if (message.adminBanned)
            UserAppManagement_AdminBanned.internalBinaryWrite(message.adminBanned, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.UserAppManagement.LoginBanned login_banned = 8; */
        if (message.loginBanned)
            UserAppManagement_LoginBanned.internalBinaryWrite(message.loginBanned, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.UserAppManagement
 */
export const UserAppManagement = new UserAppManagement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserAppManagement_AdminBanned$Type extends MessageType<UserAppManagement_AdminBanned> {
    constructor() {
        super("pb.v2.UserAppManagement.AdminBanned", [
            { no: 1, name: "epoch_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "admin_user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserAppManagement_AdminBanned>): UserAppManagement_AdminBanned {
        const message = { epochMs: 0n, adminUserName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserAppManagement_AdminBanned>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserAppManagement_AdminBanned): UserAppManagement_AdminBanned {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 epoch_ms */ 1:
                    message.epochMs = reader.uint64().toBigInt();
                    break;
                case /* string admin_user_name */ 2:
                    message.adminUserName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserAppManagement_AdminBanned, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 epoch_ms = 1; */
        if (message.epochMs !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.epochMs);
        /* string admin_user_name = 2; */
        if (message.adminUserName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.adminUserName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.UserAppManagement.AdminBanned
 */
export const UserAppManagement_AdminBanned = new UserAppManagement_AdminBanned$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserAppManagement_LoginBanned$Type extends MessageType<UserAppManagement_LoginBanned> {
    constructor() {
        super("pb.v2.UserAppManagement.LoginBanned", [
            { no: 1, name: "window_first_failure_epoch_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "window_last_failure_epoch_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "window_login_counter", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "total_failed_windows", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "login_ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserAppManagement_LoginBanned>): UserAppManagement_LoginBanned {
        const message = { windowFirstFailureEpochMs: 0n, windowLastFailureEpochMs: 0n, windowLoginCounter: 0, totalFailedWindows: 0, loginIp: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserAppManagement_LoginBanned>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserAppManagement_LoginBanned): UserAppManagement_LoginBanned {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 window_first_failure_epoch_ms */ 1:
                    message.windowFirstFailureEpochMs = reader.uint64().toBigInt();
                    break;
                case /* uint64 window_last_failure_epoch_ms */ 2:
                    message.windowLastFailureEpochMs = reader.uint64().toBigInt();
                    break;
                case /* uint32 window_login_counter */ 3:
                    message.windowLoginCounter = reader.uint32();
                    break;
                case /* uint32 total_failed_windows */ 4:
                    message.totalFailedWindows = reader.uint32();
                    break;
                case /* string login_ip */ 5:
                    message.loginIp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserAppManagement_LoginBanned, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 window_first_failure_epoch_ms = 1; */
        if (message.windowFirstFailureEpochMs !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.windowFirstFailureEpochMs);
        /* uint64 window_last_failure_epoch_ms = 2; */
        if (message.windowLastFailureEpochMs !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.windowLastFailureEpochMs);
        /* uint32 window_login_counter = 3; */
        if (message.windowLoginCounter !== 0)
            writer.tag(3, WireType.Varint).uint32(message.windowLoginCounter);
        /* uint32 total_failed_windows = 4; */
        if (message.totalFailedWindows !== 0)
            writer.tag(4, WireType.Varint).uint32(message.totalFailedWindows);
        /* string login_ip = 5; */
        if (message.loginIp !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.loginIp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.UserAppManagement.LoginBanned
 */
export const UserAppManagement_LoginBanned = new UserAppManagement_LoginBanned$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppManagement$Type extends MessageType<AppManagement> {
    constructor() {
        super("pb.v2.AppManagement", [
            { no: 1, name: "users_managment", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserAppManagement },
            { no: 2, name: "previous_log_db_pointer", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "blocked_ips", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AppManagement_BlockedIps },
            { no: 4, name: "server_errors", kind: "message", oneof: "events", T: () => ServerEventLog },
            { no: 5, name: "server_log", kind: "message", oneof: "events", T: () => ServerEventLog }
        ]);
    }
    create(value?: PartialMessage<AppManagement>): AppManagement {
        const message = { usersManagment: [], previousLogDbPointer: [], blockedIps: [], events: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppManagement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppManagement): AppManagement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated pb.v2.UserAppManagement users_managment */ 1:
                    message.usersManagment.push(UserAppManagement.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string previous_log_db_pointer */ 2:
                    message.previousLogDbPointer.push(reader.string());
                    break;
                case /* repeated pb.v2.AppManagement.BlockedIps blocked_ips */ 3:
                    message.blockedIps.push(AppManagement_BlockedIps.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* pb.v2.ServerEventLog server_errors */ 4:
                    message.events = {
                        oneofKind: "serverErrors",
                        serverErrors: ServerEventLog.internalBinaryRead(reader, reader.uint32(), options, (message.events as any).serverErrors)
                    };
                    break;
                case /* pb.v2.ServerEventLog server_log */ 5:
                    message.events = {
                        oneofKind: "serverLog",
                        serverLog: ServerEventLog.internalBinaryRead(reader, reader.uint32(), options, (message.events as any).serverLog)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppManagement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated pb.v2.UserAppManagement users_managment = 1; */
        for (let i = 0; i < message.usersManagment.length; i++)
            UserAppManagement.internalBinaryWrite(message.usersManagment[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string previous_log_db_pointer = 2; */
        for (let i = 0; i < message.previousLogDbPointer.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.previousLogDbPointer[i]);
        /* repeated pb.v2.AppManagement.BlockedIps blocked_ips = 3; */
        for (let i = 0; i < message.blockedIps.length; i++)
            AppManagement_BlockedIps.internalBinaryWrite(message.blockedIps[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.ServerEventLog server_errors = 4; */
        if (message.events.oneofKind === "serverErrors")
            ServerEventLog.internalBinaryWrite(message.events.serverErrors, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* pb.v2.ServerEventLog server_log = 5; */
        if (message.events.oneofKind === "serverLog")
            ServerEventLog.internalBinaryWrite(message.events.serverLog, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppManagement
 */
export const AppManagement = new AppManagement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppManagement_BlockedIps$Type extends MessageType<AppManagement_BlockedIps> {
    constructor() {
        super("pb.v2.AppManagement.BlockedIps", [
            { no: 1, name: "ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "blocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "last_epoch_ms", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "block_counter", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<AppManagement_BlockedIps>): AppManagement_BlockedIps {
        const message = { ip: "", blocked: false, lastEpochMs: 0n, blockCounter: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppManagement_BlockedIps>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppManagement_BlockedIps): AppManagement_BlockedIps {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ip */ 1:
                    message.ip = reader.string();
                    break;
                case /* bool blocked */ 2:
                    message.blocked = reader.bool();
                    break;
                case /* uint64 last_epoch_ms */ 3:
                    message.lastEpochMs = reader.uint64().toBigInt();
                    break;
                case /* uint32 block_counter */ 4:
                    message.blockCounter = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppManagement_BlockedIps, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ip = 1; */
        if (message.ip !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ip);
        /* bool blocked = 2; */
        if (message.blocked !== false)
            writer.tag(2, WireType.Varint).bool(message.blocked);
        /* uint64 last_epoch_ms = 3; */
        if (message.lastEpochMs !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.lastEpochMs);
        /* uint32 block_counter = 4; */
        if (message.blockCounter !== 0)
            writer.tag(4, WireType.Varint).uint32(message.blockCounter);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message pb.v2.AppManagement.BlockedIps
 */
export const AppManagement_BlockedIps = new AppManagement_BlockedIps$Type();
