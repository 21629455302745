// @generated by protobuf-ts 2.8.2
// @generated from protobuf file "pb/v2/service.proto" (package "pb.v2", syntax proto3)
// tslint:disable
//
// gRPC Servies. Superset of also uRPC endpoints, so all uRPC's can be used over gRPC (IE: Android/iOS/web can use same interfaces made in uRPC's)
//
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { GrpcService } from "./service";
import type { StreamDeviceModelResponse } from "./service_transports";
import type { StreamDeviceModelRequest } from "./service_transports";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { SaveTelemetryResponse } from "./service_transports";
import type { SaveTelemetryRequest } from "./service_transports";
import type { GetTokenResponse } from "./service_transports";
import type { UserCredentials } from "./auth";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Alive } from "./service_transports";
import type { Empty } from "../common";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service pb.v2.GrpcService
 */
export interface IGrpcServiceClient {
    /**
     * @generated from protobuf rpc: ServiceAlive(Empty) returns (pb.v2.Alive);
     */
    serviceAlive(input: Empty, options?: RpcOptions): UnaryCall<Empty, Alive>;
    /**
     * @generated from protobuf rpc: GetToken(pb.v2.UserCredentials) returns (pb.v2.GetTokenResponse);
     */
    getToken(input: UserCredentials, options?: RpcOptions): UnaryCall<UserCredentials, GetTokenResponse>;
    // ////////////////////////
    // *** Auth Guarded *** //
    // ////////////////////////

    /**
     * @generated from protobuf rpc: SaveTelemetry(pb.v2.SaveTelemetryRequest) returns (pb.v2.SaveTelemetryResponse);
     */
    saveTelemetry(input: SaveTelemetryRequest, options?: RpcOptions): UnaryCall<SaveTelemetryRequest, SaveTelemetryResponse>;
    /**
     * @generated from protobuf rpc: StreamDeviceModel(pb.v2.StreamDeviceModelRequest) returns (stream pb.v2.StreamDeviceModelResponse);
     */
    streamDeviceModel(input: StreamDeviceModelRequest, options?: RpcOptions): ServerStreamingCall<StreamDeviceModelRequest, StreamDeviceModelResponse>;
}
/**
 * @generated from protobuf service pb.v2.GrpcService
 */
export class GrpcServiceClient implements IGrpcServiceClient, ServiceInfo {
    typeName = GrpcService.typeName;
    methods = GrpcService.methods;
    options = GrpcService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ServiceAlive(Empty) returns (pb.v2.Alive);
     */
    serviceAlive(input: Empty, options?: RpcOptions): UnaryCall<Empty, Alive> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Alive>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetToken(pb.v2.UserCredentials) returns (pb.v2.GetTokenResponse);
     */
    getToken(input: UserCredentials, options?: RpcOptions): UnaryCall<UserCredentials, GetTokenResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserCredentials, GetTokenResponse>("unary", this._transport, method, opt, input);
    }
    // ////////////////////////
    // *** Auth Guarded *** //
    // ////////////////////////

    /**
     * @generated from protobuf rpc: SaveTelemetry(pb.v2.SaveTelemetryRequest) returns (pb.v2.SaveTelemetryResponse);
     */
    saveTelemetry(input: SaveTelemetryRequest, options?: RpcOptions): UnaryCall<SaveTelemetryRequest, SaveTelemetryResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveTelemetryRequest, SaveTelemetryResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StreamDeviceModel(pb.v2.StreamDeviceModelRequest) returns (stream pb.v2.StreamDeviceModelResponse);
     */
    streamDeviceModel(input: StreamDeviceModelRequest, options?: RpcOptions): ServerStreamingCall<StreamDeviceModelRequest, StreamDeviceModelResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<StreamDeviceModelRequest, StreamDeviceModelResponse>("serverStreaming", this._transport, method, opt, input);
    }
}
