
import { BluetoothConnection, } from "./ble.connetion"
import { CapacitorBleService } from "./capacitor-ble.service"
// import { CordovaBleService, } from "./cordova-ble.service"
import { ElecBleService, } from "./elec-ble.service"
import { DesktopBleService, } from "./desktop-ble.service"

import { LoggerOptions } from "../logger/logger.service"
import { AllServcies } from '../startup/startup.service';
import { DeviceTypeIds, DeviceViewModelImplemented } from "../../viewmodels/Device.vmi";
import { AllDevicesVMI } from "../../viewmodels/AllDevices.vmi";


import { BluetoothHelper } from "../../vendors/bluetooth.helper";
import { EncryptionService } from "../encryption/encryption.service"
import {
	AppleAdvertisement, AppleAdvertismentType, AppleAdvertisement_AppleUnknown, AppleAdvertisement_NearbyInfo,
	AppleAdvertisement_IBeacon, AppleAdvertisement_FindMy, AppleAdvertisement_Handoff,
	Eddystone
} from "../../generated_proto/protobuf-ts/pb/v2/entities"


// This might later be defined as a proto itself, to be shared/stored for future use and connetion scenarios (IE: Remote BLE gateway use)
export interface BluetoothCommonInterface {
	uuidString?:string, // Used to relate to the VMI
	name?:string,
	address?:string, // NOTE: in condensed and lowercase version 112233445566
	// Leaving seperate from address, as some devices CAN present mac in manufacurer data
	identifier?:string, // NOTE: iOS presents a unique identifier, Android presents a MAC address
	manufactureData?:Uint8Array,
	context?:any, // different fro different platforms, but used to store the context of the connection for that platform
	rssi?:number,
	deviceTypeId?:number,
}

export interface BluetoothRequestOptions {
	name?:string,
	namePrefix?:string,
	services?:string[],
	optionalServices?:string[],
	allowDuplicates?:boolean,
	allowUnknowns?:boolean,
	deviceTypeId?:number,
}

export class BluetoothService {
	
	private loggerOptions:LoggerOptions = {
		prefix:"BluetoothService",
		allOn:true,
		verboseOn:false,
		debugOn:true,
	};

	private capacitorBleService:CapacitorBleService;
	// private cordovaBleService:CordovaBleService;
	private elecBleService:ElecBleService;
	private desktopBleService:DesktopBleService;

	private bluetoothHelper:BluetoothHelper;

	private bluetoothConnections:{
		[address:string]:BluetoothConnection|any // not really "any", but all are extended from BluetoothConnection. This is just to avoid circular dependencies
	} = {};

	constructor
	(

	)
	{

	}

	////////////////////////////////////////////////////////////////////////////////////////////////////
	// Vendor Specific -- add or delete helpers when adding/removing from vendor folder
	////////////////////////////////////////////////////////////////////////////////////////////////////
	init(allServices:AllServcies) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
				if(this.services.platform){
					if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true){
						// Desktop Packaged App
						this.elecBleService = new ElecBleService();
						this.elecBleService.init(this, allServices);
					}
					else {
						this.capacitorBleService = new CapacitorBleService();
						this.capacitorBleService.init(this, allServices);
					}
					// else if(this.services.platform.is("capacitor") || this.services.platform.is("desktop") || (this.services.platform.is("android") && this.services.platform.is("mobileweb"))){
					// 	// Android and iOS - NEW
					// 	this.capacitorBleService = new CapacitorBleService();
					// 	this.capacitorBleService.init(this, allServices);
					// }
					// else if(this.services.platform.is("desktop")) {
					// 	this.desktopBleService = new DesktopBleService();
					// 	this.desktopBleService.init(this, allServices);
					// }
				}

				////////////////////////////////////////////////////////////////////////////////////////////////////
				// Start vendor helper
				this.bluetoothHelper = new BluetoothHelper();
				this.bluetoothHelper.init(allServices)
				////////////////////////////////////////////////////////////////////////////////////////////////////
				resolve(true);
			}
		});
	}

	
	public static ConvertRssiToDistance(rssi: number, measuredRssiAt1Meter:number = -69, environmentalScale:number = 2): number {
		var minDistance: number = 1;
		var maxDistance: number = 15;
		if (!rssi) return minDistance;
		let distance = Math.pow(10, (measuredRssiAt1Meter - rssi) / (10 * environmentalScale));
		distance = Math.round(distance * 100) / 100;
		// window["LOG"] && console.log("distance", distance)
		if (distance > maxDistance) {
			distance = maxDistance
		} else if (distance < minDistance) {
			distance = minDistance;
		}
		return distance;
	}

	public getSupportedDevices() : AllDevicesVMI[] {
		return this.bluetoothHelper.getSupportedDevices();
	}

	public makeDeviceWithType(device:BluetoothCommonInterface, allowUnknowns:boolean=true) : Promise<AllDevicesVMI> {
		return new Promise( async (resolve, reject) =>  {
			// Search the vendor helpers for a match
			console.log("FOUND THE DEVICE WITH AN R IN THE FRONT :: ")
			var newDeviceVMI = await this.bluetoothHelper.makeDeviceWithType(device);
			// Make the default device, which is a generic device
			if(newDeviceVMI === undefined ){
				if(!allowUnknowns){
					reject("Device not supported");
					return;
				}
				newDeviceVMI = new DeviceViewModelImplemented(this.services, new BluetoothConnection(this.services));
			}
			if(device.identifier){
				this.bluetoothConnections[device.identifier] = newDeviceVMI.bluetoothConnection;
			}
			if(device.name && newDeviceVMI.model.device){
				newDeviceVMI.model.device.name = device.name;
			}
			resolve(newDeviceVMI);
		});
	}

	public getDeviceConnection(uuid:bigint) : BluetoothConnection|null {
		if(this.bluetoothConnections[uuid.toString()]){
			return this.bluetoothConnections[uuid.toString()];
		}
		return null;
	}

	public saveDeviceConnection(connection:BluetoothConnection) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(!this.services.platform){
				return Promise.reject({code:0, message:"Platform Not Set"});
			}
			if( this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true){
				// context cant be serialized and saved
				// each lib must rebuild its own context if needed
				try{
					console.log(" :: SAVING Bluetooth Connection cleaned of context")
					var cleanedContext = { id:connection.bluetoothCommonInterface.context.id, uuid:connection.bluetoothCommonInterface.context.uuid };
					var cleanedInterface:BluetoothCommonInterface = Object.assign({}, connection.bluetoothCommonInterface, {context:cleanedContext})
					await this.services.data?.saveByKey(connection.bluetoothCommonInterface.uuidString+"_btcmi", cleanedInterface);
					console.log(" :: SAVED Bluetooth Connection cleaned of context")
				}
				catch(e){
					console.error(e);
				}
			}
			else {
				try{
					await this.services.data?.saveByKey(connection.bluetoothCommonInterface.uuidString+"_btcmi", connection.bluetoothCommonInterface);
					console.log(" :: SAVED Bluetooth Connection")
				}
				catch(e){
					console.error(e);
				}
			}
			if(connection.bluetoothCommonInterface.uuidString){
				this.bluetoothConnections[connection.bluetoothCommonInterface.uuidString] = connection;
			}
			resolve(true);
		});
	}
	public removeDeviceConnection(connection:BluetoothConnection) : Promise<boolean> {
		return new Promise( async (resolve, reject) =>  {
			if(connection.bluetoothCommonInterface.uuidString){
				delete this.bluetoothConnections[connection.bluetoothCommonInterface.uuidString];
			}
			resolve(true);
		});
	}
	

	////////////////////////////////////////////////////////////////////////////////////////////////////

	private services:AllServcies;
	public setServices(services){
		this.services = services
	}

	private buildUint8ByCharCode( data:string ) : Uint8Array{
		var data_:any[] = []
		for (let index = 0; index < data.length; index++) {
			const element = data[index];
			data_.push(element.charCodeAt(0))
		}
		return new Uint8Array(data_)
	}

	public getScan(milliseconds_scan:number=2000, allowUnknowns:boolean=false,  filterByDeviceType:DeviceTypeIds[]=[]) : Promise<AllDevicesVMI[]> {
		console.log(":: getScan :: ")
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if( this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true){
			// Desktop Packaged App
			console.log("Calling electron scan");
			return this.elecBleService.getScan(milliseconds_scan, allowUnknowns, filterByDeviceType);
		}
		else {
			if(this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] == true){
				console.log("Forcing Web Bluetooth")
			}
			console.log("Calling capacitor scan :: NOTE this will fail on web (without flags set on experimental, select platform)");
			return this.capacitorBleService.getScan(milliseconds_scan, allowUnknowns, filterByDeviceType);
		}
		// else if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
		// 	// Desktop Packaged App
		// 	console.log("Calling electron scan");
		// 	return this.elecBleService.getScan(milliseconds_scan, allowUnknowns, filterByDeviceType);
		// }
		// else {
		// 	// Web or other left
		// 	// Leaving this because you CAN flip the flags to raw scans
		// 	// and potentially could be built into chrome in the future
		// 	return Promise.reject("Not Implemented : BLE Scans are not implemented in browser. (without modifications)");
		// }
	}

	public getScanByName(serachName:string, timeout:number=2000) : Promise<AllDevicesVMI> {
		console.log(":: getScan :: ")
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			// Desktop Packaged App
			console.log("Calling electron getScanByName");
			return this.elecBleService.getScanByName(serachName, timeout);
		}
		else {
			// Web or other left
			// Leaving this because you CAN flip the flags to raw scans
			// and potentially could be built into chrome in the future
			return Promise.reject("Not Implemented : BLE Scans are not implemented in browser. (without modifications)");
		}
	}

	public requestDevice(requestOptions:BluetoothRequestOptions) : Promise<AllDevicesVMI> {
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if(this.services.platform.is("electron")){
			// if there is a web request, electron has to override the selection process
			console.log("Calling electron webDiscoveryOverride")
			ElecBleService.webDiscoveryOverride( (data)=>{
				console.log("webDiscoveryOverride frontend received : ", data)
				console.log(" TODO :: SELECT AND THEN SEND THIS BACK OVER TO THE ELECTRON SERVICE")
				if(this.services.uiHelper){
					this.services.uiHelper.selectDevice(data).then( (selectedDevice)=>{
						console.log("requestDevice :: Ui helper returned : ", selectedDevice, " :: ")
						ElecBleService.selectedDeviceChosenByUser(selectedDevice.deviceId);
					})
					.catch( (e) => {
						console.error(e);
						ElecBleService.selectedDeviceChosenByUser("");
					})
				}
				else{
					console.log("No UI Helper")
					ElecBleService.selectedDeviceChosenByUser("");
				}
			});
		}
		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			return Promise.reject("Not Implemented : BLE requestDevice are not implemented. (without modifications)");
		}
		else{
			if(this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] == true){
				console.log("Forcing Web Bluetooth")
			}
			return this.capacitorBleService.requestDevice(requestOptions);
		}
		// else if( this.services.platform.is("capacitor") || this.services.platform.is("desktop") || (this.services.platform.is("android") && this.services.platform.is("mobileweb"))){
		// 	return this.capacitorBleService.requestDevice(requestOptions);
		// }
		// else if( this.services.platform.is("cordova")){
		// 	return Promise.reject("Not Implemented : BLE requestDevice are not implemented. (without modifications)");
		// }
		// else {
		// 	return Promise.reject("Not Implemented : BLE requestDevice are not implemented. (without modifications)");
		// }
	}

	public connect( bluetoothConnection:BluetoothConnection ) : Promise<boolean> {
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			// Desktop Packaged App
			return this.elecBleService.connect(bluetoothConnection);
		}
		else {
			if(this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] == true){
				console.log("Forcing Web Bluetooth")
			}
			// Android and iOS, and web
			console.log(":: Calling Capacitor Connect ::");
			return this.capacitorBleService.connect(bluetoothConnection);
		}
		// else if( this.services.platform.is("capacitor") || this.services.platform.is("desktop") || (this.services.platform.is("android") && this.services.platform.is("mobileweb"))){
		// 	// Android and iOS, and web
		// 	console.log(":: Calling Capacitor Connect ::");
		// 	return this.capacitorBleService.connect(bluetoothConnection);
		// }
		// else if( this.services.platform.is("cordova")){
			// Android and iOS - OLD PLUGIN
			// console.log(":: Calling Cordova Connect ::");
			// return this.cordovaBleService.connect(bluetoothConnection);
		// }
		// else {
		// 	// Web or other left
		// 	return new Promise( (resolve, reject) => {
		// 		console.warn("Web BLE Not Implemented");
		// 		var myCharacteristic;
		// 		var serviceUuid = "23506f6c-696d-6173-7465-725f55554944";
		// 		var characteristicUuid = "23496e73-7472-756d-656e-745f64617461";
		// 		let mobileNavigatorObject: any = window.navigator;
		// 		if(mobileNavigatorObject && mobileNavigatorObject.bluetooth) {
		// 		// Here write your logic of mobileNavigatorObject.bluetooth.requestDevice();
		// 			mobileNavigatorObject.bluetooth.requestDevice({filters: [{services: [serviceUuid]}]})
		// 			.then(device => {
		// 				console.log('Connecting to GATT Server...');
		// 				return device.gatt.connect();
		// 			})
		// 			.then(server => {
		// 				console.log('Getting Service...');
		// 				return server.getPrimaryService(serviceUuid);
		// 			})
		// 			.then(service => {
		// 				console.log('Getting Characteristic...');
		// 				return service.getCharacteristic(characteristicUuid);
		// 			})
		// 			.then(characteristic => {
		// 				myCharacteristic = characteristic;
		// 				return myCharacteristic.startNotifications().then(_ => {
		// 				console.log('> Notifications started');
		// 				myCharacteristic.addEventListener('characteristicvaluechanged',
		// 					(event) => {
		// 						let value = event.target.value;
		// 						let a:any[] = [];
		// 						// Convert raw data bytes to hex values just for the sake of showing something.
		// 						// In the "real" world, you'd use data.getUint8, data.getUint16 or even
		// 						// TextDecoder to process raw data bytes.
		// 						for (let i = 0; i < value.byteLength; i++) {
		// 						a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
		// 						}
		// 						console.log('> ' + a.join(' '));
		// 					});
		// 				});
		// 			})
		// 			.catch(error => {
		// 				console.log('Argh! ' + error);
		// 				reject(error);
		// 			});
		// 		}
		// 		else {
		// 			reject("Web BLE Not Implemented");
		// 		}

		// 	})
		// }
	}

	public disconnect( bluetoothConnection:BluetoothConnection ) : Promise<boolean> {
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			// Desktop Packaged App
			bluetoothConnection.keepConnected = false;
			return this.elecBleService.disconnect(bluetoothConnection);
		}
		else{
			if(this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] == true){
				console.log("Forcing Web Bluetooth")
			}
			// Android and iOS, and web
			bluetoothConnection.keepConnected = false;
			return this.capacitorBleService.disconnect(bluetoothConnection);
		}
		// else if( this.services.platform.is("capacitor") || this.services.platform.is("desktop") || (this.services.platform.is("android") && this.services.platform.is("mobileweb"))){
		// 	// Android and iOS, and web
		// 	bluetoothConnection.keepConnected = false;
		// 	return this.capacitorBleService.disconnect(bluetoothConnection);
		// }
		// else {
		// 	// Web or other left
		// 	return Promise.reject("Web BLE Not Implemented");
		// }
	}

	public forceGetCharacteristics( bluetoothConnection:BluetoothConnection ) {
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			// Desktop Packaged App
			return this.elecBleService.forceGetCharacteristics(bluetoothConnection);
		}
		else {
			if(this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] == true){
				console.log("Forcing Web Bluetooth")
			}
			// Android and iOS, and web
			return this.capacitorBleService.forceGetCharacteristics(bluetoothConnection);
		}
		// else if( this.services.platform.is("capacitor")){
		// 	// Android and iOS
		// 	return this.capacitorBleService.forceGetCharacteristics(bluetoothConnection);
		// }
		// else if( this.services.platform.is("cordova")){
		// 	// Android and iOS
		// 	// return this.cordovaBleService.forceGetCharacteristics(bluetoothConnection);
		// }
		// else {
		// 	// Web or other left
		// 	return new Promise( (resolve, reject) => {
		// 		console.warn("writeCharacteristic: Web BLE Not Implemented");
		// 		resolve(true);
		// 	})
		// }
	}

	public writeCharacteristic( bluetoothConnection:BluetoothConnection, service_input:string|number="", characteristic_input:string|number="", data_input:string|number|Uint8Array, withoutResponse:boolean=false ) {
		// if data_input is a string
		var data;
		if(typeof data_input === "string") {
			data = this.buildUint8ByCharCode(data_input)
		}
		else if(typeof data_input === "number"){
			data = new Uint8Array([data_input]);
		}
		else {
			data = data_input;
		}

		// if services input is stirng
		if(typeof service_input === "string") {
			if(service_input.length <= 0 ){
				console.warn("BLE Write called with no service_input identified");
				return;
			}
		}
		else if (typeof service_input === "number") {
			if(service_input <= 0 ){
				console.warn("BLE Write called with no service_input identified");
				return;
			}
		}

		// if characteristic input is stirng
		if(typeof characteristic_input === "string") {
			if(characteristic_input.length <= 0 ){
				console.warn("BLE Write called with no characteristic_input identified");
				return;
			}
		}
		else if (typeof characteristic_input === "number") {
			if(characteristic_input <= 0 ){
				console.warn("BLE Write called with no characteristic_input identified");
				return;
			}
		}
		console.log("Calling writeCharacteristic: ", service_input, " ::: ", characteristic_input, " ::: ", EncryptionService.toHexString(data));
		if(data.length<=0){
			console.warn("BLE Write called with no data");
			return;
		}
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			// Desktop Packaged App
			return this.elecBleService.writeCharacteristic(bluetoothConnection, characteristic_input, data, withoutResponse);
		}
		else {
			if(this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] == true){
				console.log("Forcing Web Bluetooth")
			}
			// Android and iOS, and web
			return this.capacitorBleService.writeCharacteristic(bluetoothConnection, service_input, characteristic_input, data, withoutResponse);
		}
		// else if( this.services.platform.is("capacitor") || this.services.platform.is("desktop") || (this.services.platform.is("android") && this.services.platform.is("mobileweb")) ){
		// 	// Android and iOS
		// 	return this.capacitorBleService.writeCharacteristic(bluetoothConnection, service_input, characteristic_input, data, withoutResponse);
		// }
		// // else if( this.services.platform.is("cordova")){
		// 	// Android and iOS
		// 	// return this.cordovaBleService.writeCharacteristic(bluetoothConnection, characteristic_string, data_string)
		// // }
		// else {
		// 	// Web or other left
		// 	return new Promise( (resolve, reject) => {
		// 		console.warn("writeCharacteristic: Web BLE Not Implemented");
		// 		resolve(true);
		// 	})
		// }
	}

	public readCharacteristic( bluetoothConnection:BluetoothConnection, service_input:string|number="", characteristic_input:string|number="" ) : Promise<Uint8Array> {
		// console.log(":: readCharacteristic :: ", service_input, " ::: ", characteristic_input)
		// if services input is stirng
		if(typeof service_input === "string") {
			if(service_input.length <= 0 ){
				console.warn("BLE Write called with no service_input identified");
				Promise.reject("BLE Write called with no service_input identified");
			}
		}
		else if (typeof service_input === "number") {
			if(service_input <= 0 ){
				Promise.reject("BLE Write called with no service_input identified");
			}
		}

		// if characteristic input is stirng
		if(typeof characteristic_input === "string") {
			if(characteristic_input.length <= 0 ){
				Promise.reject("BLE Write called with no characteristic_input identified");
			}
		}
		else if (typeof characteristic_input === "number") {
			if(characteristic_input <= 0 ){
				Promise.reject("BLE Write called with no characteristic_input identified");
			}
		}
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}

		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			// Desktop Packaged App
			console.log("electron :: Calling readCharacteristic: ", service_input, " ::: ", characteristic_input);
			return this.elecBleService.readCharacteristic(bluetoothConnection, service_input, characteristic_input);
		}
		else {
			if(this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] == true){
				console.log("Forcing Web Bluetooth")
			}
			// Android and iOS
			console.log("capacitor :: Calling readCharacteristic: ", service_input, " ::: ", characteristic_input);
			return this.capacitorBleService.readCharacteristic(bluetoothConnection, service_input, characteristic_input);
		}
		// else if( this.services.platform.is("capacitor") || this.services.platform.is("desktop") || (this.services.platform.is("android") && this.services.platform.is("mobileweb")) ){
		// 	// Android and iOS
		// 	return this.capacitorBleService.readCharacteristic(bluetoothConnection, service_input, characteristic_input);
		// }
		// // else if( this.services.platform.is("cordova")){
		// 	// Android and iOS
		// 	// return this.cordovaBleService.writeCharacteristic(bluetoothConnection, characteristic_string, data_string)
		// // }
		// else {
		// 	// Web or other left
		// 	return new Promise( (resolve, reject) => {
		// 		console.warn("readCharacteristic: BLE Not Implemented");
		// 		Promise.reject("readCharacteristic: BLE Not Implemented");
		// 	})
		// }
		// return Promise.reject("readCharacteristic: BLE Not Implemented");
	}

	public subscribeCharacteristic( bluetoothConnection:BluetoothConnection, service_input:string|number="", characteristic_input:string|number="", cb:(data:Uint8Array)=>void ) : Promise<boolean> {
		if(!service_input ){
			console.warn("BLE Subscirbe called with no characteristic identified");
			return Promise.reject("BLE Subscirbe called with no characteristic identified");
		}
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			// Desktop Packaged App
			console.log("electron :: Asking for sub charactersitc: ", characteristic_input)
			return this.elecBleService.subscribeCharacteristic(bluetoothConnection, service_input, characteristic_input, cb);
		}
		else {
			// Android and iOS, and web
			console.log('capacitor :: Asking for sub charactersitc: ', service_input, characteristic_input)
			return this.capacitorBleService.subscribeCharacteristic(bluetoothConnection, service_input, characteristic_input, cb);
		}
		// else if( this.services.platform.is("capacitor")){
		// 	// Android and iOS
		// 	return this.capacitorBleService.subscribeCharacteristic(bluetoothConnection, service_input, characteristic_input, cb);
		// }
		// // else if( this.services.platform.is("cordova")){
		// 	// Android and iOS
		// 	// return this.cordovaBleService.subscribeCharacteristic(bluetoothConnection, characteristic_string, cb);
		// // }
		// else {
		// 	// Web or other left
		// 	return new Promise( (resolve, reject) => {
		// 		console.warn("subscribeCharacteristic : Web BLE Not Implemented");
		// 		resolve(true);
		// 		// setInterval(function(){
		// 		// 	console.log(" :: bluetoothConnection Publishing to device : ", bluetoothConnection.parentVMI.toObject());
		// 		// 	bluetoothConnection.processStreamingData("GT,,3,17\r\n")
		// 		// }, 2000);
		// 	})
		// }
	}

	public indicateCharacteristic( bluetoothConnection:BluetoothConnection, service_string:string="", characteristic_string:string="", cb:(data:Uint8Array)=>void ) : Promise<boolean> {
		if(characteristic_string.length <= 0 ){
			console.warn("BLE Subscirbe called with no characteristic identified");
			return Promise.reject("BLE Subscirbe called with no characteristic identified");
		}
		if(!this.services.platform){
			return Promise.reject({code:0, message:"Platform Not Set"});
		}
		if(this.services.platform.is("electron") && this.services.settings?.SETTINGS["APP_FORCE_WEB_BLUETOOTH"] != true) {
			// Desktop Packaged App
			return this.elecBleService.indicateCharacteristic(bluetoothConnection, characteristic_string, cb);
		}
		else {
			// Android and iOS, and web
			return this.capacitorBleService.indicateCharacteristic(bluetoothConnection, service_string, characteristic_string, cb);
		}
		// else if( this.services.platform.is("capacitor")){
		// 	// Android and iOS
		// 	return this.capacitorBleService.indicateCharacteristic(bluetoothConnection, service_string, characteristic_string, cb);
		// }
		// // else if( this.services.platform.is("cordova")){
		// 	// Android and iOS
		// 	// return this.cordovaBleService.indicateCharacteristic(bluetoothConnection, characteristic_string, cb);
		// // }
		// else {
		// 	// Web or other left
		// 	return new Promise( (resolve, reject) => {
		// 		console.warn("indicateCharacteristic : Web BLE Not Implemented");
		// 		resolve(true);
		// 	})
		// }
	}

	public parseManufacturerData(manufacturerData:Uint8Array) : Promise<{appleAdvertisement?:AppleAdvertisement,eddystone?:Eddystone}> {
		return new Promise( (resolve,reject) => {
			var result:{appleAdvertisement?:AppleAdvertisement,eddystone?:Eddystone} = {};
			if(manufacturerData[0] == 0x4c && manufacturerData[1] == 0x00){
				var appleAdvertisement = AppleAdvertisement.create();
				var appleType = manufacturerData[2];
				if(appleType == AppleAdvertismentType.IBEACON){
					appleAdvertisement.iBeacon = AppleAdvertisement_IBeacon.create();
					appleAdvertisement.iBeacon.iBeaconUUID = manufacturerData.slice(4, 16);
					appleAdvertisement.iBeacon.iBeaconMajor = (manufacturerData[20] << 8) | manufacturerData[21];
					appleAdvertisement.iBeacon.iBeaconMinor = (manufacturerData[22] << 8) | manufacturerData[23];
					appleAdvertisement.iBeacon.iBeaconTxPower = manufacturerData[24];
				}
				else if(appleType == AppleAdvertismentType.FIND_MY){
					// "Offline finding type"
					var findMyLenth = manufacturerData[3];
					if(findMyLenth == 0x19){
						// Finding
						// 4c 00
						// 12 19
						// 10
						// 7c 22 e3 8f b9 37 af f9 a6 3f a0 08 95 52 8d c7 f3 65 07 e5 ba 63 03 b2
						// console.log("\tFindMy: ", EncryptionHelper.toHexString(element));
						appleAdvertisement.findMy = AppleAdvertisement_FindMy.create();
						appleAdvertisement.findMy.findMyStatus = manufacturerData[4];
					}
					else {
						// finding these smaller ones, not sure what they're about
						// 4c 00
						// 12 02
						// 54 01
						// 4c 00
						// 12 02
						// 54 01
						appleAdvertisement.unknown = AppleAdvertisement_AppleUnknown.create();
						appleAdvertisement.unknown.type = appleType;
						appleAdvertisement.unknown.data = manufacturerData;
					}
				}
				else if(appleType == AppleAdvertismentType.HANDOFF){
					// console.log("\tHandoff: ", EncryptionHelper.toHexString(element));
					// 4c 00 apple
					// 0c handoff
					// 0e length -> 14
					// 08 version
					// 47 ce iv
					// 27 auth
					// 07 77 87 64 1e 2b 1e c6 46 ca 10 06 09 19 e5 4f 12 08 // should be 10 byte payload but is too long
					// 4c 00 0c 0e 08 47 ce 27 07 77 87 64 1e 2b 1e c6 46 ca 10 06 09 19 e5 4f 12 08
					appleAdvertisement.handoff = AppleAdvertisement_Handoff.create();
					appleAdvertisement.handoff.version = manufacturerData[2+1];
					appleAdvertisement.handoff.iv = (manufacturerData[2+3] << 8) | manufacturerData[2+4];
					appleAdvertisement.handoff.auth = manufacturerData[2+5];
					appleAdvertisement.handoff.data = manufacturerData.slice(2+6);
				}
				else if(appleType == AppleAdvertismentType.NEARBY_INFO){
					appleAdvertisement.nearbyInfo = AppleAdvertisement_NearbyInfo.create();
					
				}
				else {
					console.log("Unknown Apple Type: 0x", appleType.toString(16).padStart(2, '0') , " Data: ", EncryptionService.toHexString(manufacturerData.slice(4)));
				}
				result.appleAdvertisement = appleAdvertisement;
			}
			else if(manufacturerData[0] == 0x20) { // parse out eddy stone
				var eddystone = Eddystone.create();
				console.log("Parsing out the edyy stone")
				// if(element[1] == 0x00){ // unecnrypted eddy stone
					
				// }
				result.eddystone = eddystone;
			}
			resolve(result);
		});
	}

	
}
