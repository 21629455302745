import { ViewModelImplemented } from "./ViewModelImplemented";
import { JsonValue } from "@protobuf-ts/runtime"
import { WebhookViewModel, WebhookModel, ModelType, DataBase, } from '../generated_proto/protobuf-ts/pb/v2/models';
import { LoggerOptions } from "../services/logger/logger.service";
import { UtilService } from "../services/util/util.service";
import { DataService } from "../services/data/data.service";
import { Webhook } from "../generated_proto/protobuf-ts/pb/v2/entities";
import { AllServcies } from "../services/startup/startup.service"

export class WebhookViewModelImplemented extends ViewModelImplemented implements WebhookViewModel {
	
	model: WebhookModel;

	public loggerOptions:LoggerOptions = {
		prefix:"FileViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	constructor
	(
		services:AllServcies,
		model?:WebhookModel,
	)
	{
		super();
		this.model = WebhookModel.create();
		this.model.webhook = Webhook.create();
		if(model){
			this.setModel(model);
		}
		if(services){
			this.services = services;
		}
	}
	setModel(fileModel:WebhookModel) : WebhookViewModelImplemented {
		if(fileModel){
			this.model = fileModel;
		}
		return this;
	}

	updateLocalFromDb(json:any) : Promise<boolean> {
		return new Promise( (resolve, reject) => {
			this.setModel(WebhookModel.fromJson(json, {ignoreUnknownFields: true}));
			resolve(true);
		})
	}

	modelToJson(){
		// Complete any thing needed in the model to make sure its ready to save, like fill out the urn
		return WebhookModel.toJson(this.model);
	}

	private static PB_VERSION = 2;
	static GenerateDbId(dbPrefix:string) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return dbPrefix + ModelType.WEBHOOK_MODEL;
	}
	static GenerateDbKey(uuid:bigint) : string {
		// Just a helper to generate a database ID, might add to this to version the holding db later.
		return DataService.getKeyPrefix(ModelType.WEBHOOK_MODEL,WebhookViewModelImplemented.PB_VERSION)+ uuid.toString();
	}
	public generateDbKey() : string {
		if(this.model.webhook?.uuid ){
			return WebhookViewModelImplemented.GenerateDbKey(this.model.webhook.uuid);
		}
		return "";
	}
	static GenerateURN(dbPrefix:string, webhook:Webhook) : string {
		if(webhook){
			var dbid = this.GenerateDbId(dbPrefix);
			return dbid+"/"+this.GenerateDbKey(webhook.uuid);
		}
		console.error("generateURN: No UUID");
		return "";
	}
	public generateURN() : string {
		if(this.model && this.model.webhook && this.services.data?.getDbPrefix()){
			return WebhookViewModelImplemented.GenerateURN(this.services.data?.getDbPrefix(), this.model.webhook);
		}
		return "";
	}
	updateDb(db?:DataBase){
		if(!this.model.db){
			this.model.db = DataBase.create();
		}
		if(db){
			try{
				DataBase.mergePartial(this.model.db, db);
			}
			catch(err){
				console.error("updateDb: Failed to merge db: "+err);
			}
		}
		if( this.model.db.createdMs && this.model.db.createdMs <= 0){
			this.model.db.createdMs = BigInt(Date.now());
		}
		if(this.model.webhook) this.model.db.uid = BigInt(this.model.webhook.uuid);
		this.model.db.urn = this.generateURN();
		this.model.db.modelType = BigInt(ModelType.WEBHOOK_MODEL);
		this.model.db.updatedMs = BigInt(Date.now());
	}

	save(app_id?:number) : Promise<boolean>{
		// this.setCreatedMs(Date.now());
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		return new Promise( async (resolve, reject) => {
			if(this.services.pouch && this.services.pouch.files){
				if(!this.services.settings){
					reject("Settings Service Not Set");
					return;
				}
				if(!this.services.settings.SETTINGS.APP_ID){
					reject("App ID Not Set");
					return;
				}
				console.log("Attempting to save webhook to pouchdb");
				await this.services.pouch.webhook.saveWebhook(this.services.settings.SETTINGS.APP_ID, this).then( () => {
					console.log("saveWebhook: Success");
					resolve(true);
				}).catch( () =>{
					reject("Failed to save photo");
				})
			}
			else {
				reject("Missing Pouch -> Files services");
			}
		})
	}

	delete(app_id?:number):Promise<boolean>{
		if(!app_id){
			app_id = this.services.settings?.SETTINGS.APP_ID;
		}
		return new Promise( async (resolve, reject) => {
			if(this.services.pouch && this.services.pouch.app){
				this.services.pouch.webhook.deleteWebhook(app_id||2, this)
				.then( (success) => {
					(success) ? resolve(true) : resolve(false);
				})
				.catch( (err) => {
					reject(err);
				});
			}
		});
	}

}