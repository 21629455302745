import { Component, SimpleChanges, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core';
import {
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexTitleSubtitle,
	ApexFill,
	ApexLegend,
	ApexStroke,
	ApexPlotOptions,
	ApexYAxis,
	ApexTooltip
} from 'ng-apexcharts';
import { eventFormatted, EventType, eventTypes } from 'src/app/viewmodels/Device.vmi';


export type ChartOptions = {
	series: ApexAxisChartSeries;
	plotOptions: ApexPlotOptions;
	chart: ApexChart;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis;
	title: ApexTitleSubtitle;
	fill: ApexFill;
	legend: ApexLegend;
	stroke: ApexStroke;
	tooltip: ApexTooltip;
};


@Component({
	selector: 'event-log-component',
	templateUrl: './event-log.component.html',
	styleUrls: ['./event-log.component.scss'],
})


export class EventLogComponent implements OnInit {
	@ViewChild("chart") chart: any;
	public chartOptions: ChartOptions;
	public events: eventFormatted[] = [];
	public isChartLoading = false;
	private timeline: String[];
	constructor(public changeDetectorRef: ChangeDetectorRef,) {
		this.timeline = [];
		this.chartOptions = {
			series: [
			],
			chart: {
				height: 650,
				type: "rangeBar",
				zoom: {
					type: 'xy',
				},
				animations: {
					enabled: false // set to false to turn off animations
				}
			},
			yaxis: {
				reversed: true,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '80%',
				}
			},
			xaxis: {
				type: 'datetime',
				// labels: {
				// 	datetimeUTC: false,
				// 	datetimeFormatter: {
				// 		year: 'yyyy',
				// 		month: "MMM 'yy",
				// 		day: 'ddd MMM dd',
				// 		hour: 'h:mm:tt',
				// 		minute: 'h:mm:ss tt',
				// 	},					
				// },
			},
			tooltip: {
				x: {
					format: 'MM/dd/yy HH:mm'
				}
			},
			title: {

			},
			stroke: {
				width: 1
			},
			fill: {
				type: 'solid',
				opacity: 0.6
			},
			legend: {
				position: 'top',
				horizontalAlign: 'left',
				show: true,
			}
		}

	}
	ngOnInit(): void {
		// console.log("EventLogComponent ngOnInit")
	}
	public ngOnChanges(changes: SimpleChanges) {
		// console.log("changes is : ", changes);
	}


	refresh(events: eventFormatted[]) {
		this.events = events;
		var timeSet = new Set<string>();
		this.events.forEach((event) => {
			timeSet.add(event.start_time);
		});
		this.timeline = Array.from(timeSet);
		this.chartOptions.xaxis.categories = this.timeline;
		var series: any[] = [];
		const eventMap = new Map<string, eventFormatted[]>();
		for (const event of this.events) {
			if (eventMap.has(event.event_name)) {
				eventMap.get(event.event_name)?.push(event);
			}
			else {
				eventMap.set(event.event_name, [event]);
			}
		}
		for (const key of eventMap.keys()) {
			var data: any[] = [];
			for (const event of eventMap.get(key) ?? []) {
				data.push({
					x: event.event_name,
					y: [
						new Date(event.start_time).getTime(),
						new Date(event.stop_time).getTime(),
					]
				});
			}

			var value = {
				name: key,
				data: data
			}
			series.push(value)
		}
		series.sort((a, b) => {
			const aIndex = eventTypes.map((obj) => obj.name).indexOf(a.name);
			const bIndex = eventTypes.map((obj) => obj.name).indexOf(b.name);

			if (aIndex < bIndex) {
				return -1;
			} else if (aIndex > bIndex) {
				return 1;
			} else {
				return 0;
			}
		});
		this.chartOptions.series = series;
		this.changeDetectorRef.detectChanges();
	}
}