// @generated by protobuf-ts 2.8.2 with parameter client_grpc1,server_grpc1
// @generated from protobuf file "pb/v2/vendors/ste_ble_data.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// Firmware Side Only

/**
 * @generated from protobuf message SessionLog
 */
export interface SessionLog {
    /**
     * @generated from protobuf field: uint32 average_exposure_rate = 1;
     */
    averageExposureRate: number;
    /**
     * @generated from protobuf field: uint32 alert_status = 2;
     */
    alertStatus: number;
    /**
     * @generated from protobuf field: uint32 high_alarm_level = 3;
     */
    highAlarmLevel: number; // highest alarm level
    /**
     * @generated from protobuf field: uint32 high_alarm_level_time = 4;
     */
    highAlarmLevelTime: number; // time of highest alarm level
    /**
     * @generated from protobuf field: uint32 average_alarm_level = 5;
     */
    averageAlarmLevel: number; // average alarm level
    /**
     * @generated from protobuf field: uint32 total_alarm_time = 6;
     */
    totalAlarmTime: number; // total alarm time
    /**
     * @generated from protobuf field: uint32 average_counts = 7;
     */
    averageCounts: number; // average counts
    /**
     * @generated from protobuf field: bool saturated = 8;
     */
    saturated: boolean; // if in window received the "!" alarm.
    /**
     * @generated from protobuf field: uint32 log_count = 9;
     */
    logCount: number; // count
}
/**
 * @generated from protobuf message Location
 */
export interface Location {
    /**
     * @generated from protobuf field: sfixed32 latitude_1e7 = 1 [json_name = "latitude1e7"];
     */
    latitude1E7: number; // signed fixed point representation. signed fixed * 1e-7 = floating. 
    /**
     * @generated from protobuf field: sfixed32 longitude_1e7 = 2 [json_name = "longitude1e7"];
     */
    longitude1E7: number; // signed fixed point representation. signed fixed * 1e-7 = floating. 
}
/**
 * @generated from protobuf message Session
 */
export interface Session {
    /**
     * @generated from protobuf field: Location location = 1;
     */
    location?: Location;
    /**
     * @generated from protobuf field: uint64 time_start = 2;
     */
    timeStart: bigint;
    /**
     * @generated from protobuf field: bytes pub_key = 3;
     */
    pubKey: Uint8Array;
}
/**
 * / Commands below
 *
 * @generated from protobuf message SessionLogResponse
 */
export interface SessionLogResponse {
    /**
     * @generated from protobuf field: uint32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: uint32 total_bytes = 2;
     */
    totalBytes: number;
    /**
     * @generated from protobuf field: uint32 current_position = 3;
     */
    currentPosition: number;
    /**
     * @generated from protobuf field: uint32 segment_length = 4;
     */
    segmentLength: number;
    /**
     * @generated from protobuf field: Session session = 5;
     */
    session?: Session;
    /**
     * @generated from protobuf field: bytes raw_encoded_session_logs = 6;
     */
    rawEncodedSessionLogs: Uint8Array;
}
/**
 * @generated from protobuf message SessionLogs
 */
export interface SessionLogs {
    /**
     * @generated from protobuf field: uint32 session_id = 1;
     */
    sessionId: number;
    /**
     * @generated from protobuf field: uint32 total_log_count = 2;
     */
    totalLogCount: number;
}
/**
 * @generated from protobuf message SessionEntry
 */
export interface SessionEntry {
    /**
     * @generated from protobuf field: uint32 session_id = 1;
     */
    sessionId: number;
    /**
     * @generated from protobuf field: uint32 size = 2;
     */
    size: number;
    /**
     * @generated from protobuf field: uint64 timestamp = 3;
     */
    timestamp: bigint; // can add more, like info about start stop time, etc
    // whatever metadata would be good to have
}
/**
 * @generated from protobuf message SessionIdList
 */
export interface SessionIdList {
    /**
     * @generated from protobuf field: uint32 total_session_count = 1;
     */
    totalSessionCount: number;
    /**
     * @generated from protobuf field: repeated SessionEntry session_entries = 2;
     */
    sessionEntries: SessionEntry[];
    /**
     * @generated from protobuf field: bool is_final_list = 3;
     */
    isFinalList: boolean;
}
/**
 * @generated from protobuf message SystemInfo
 */
export interface SystemInfo {
    /**
     * @generated from protobuf field: bytes version = 1;
     */
    version: Uint8Array;
}
/**
 * @generated from protobuf message CommandError
 */
export interface CommandError {
    /**
     * @generated from protobuf field: SteCommands command = 1;
     */
    command: SteCommands; // the command that failed
    /**
     * @generated from protobuf field: ErrorCodes error_code = 2;
     */
    errorCode: ErrorCodes;
    /**
     * @generated from protobuf oneof: error_data
     */
    errorData: {
        oneofKind: "sessionId";
        /**
         * @generated from protobuf field: uint32 session_id = 3;
         */
        sessionId: number; // the session id that failed
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message DeviceModel
 */
export interface DeviceModel {
    /**
     * @generated from protobuf field: bytes model = 1;
     */
    model: Uint8Array;
}
/**
 * @generated from protobuf message SteCommand
 */
export interface SteCommand {
    /**
     * @generated from protobuf field: SteCommands command = 1;
     */
    command: SteCommands;
    /**
     * @generated from protobuf oneof: command_data
     */
    commandData: {
        oneofKind: "serialNumber";
        /**
         * @generated from protobuf field: uint32 serial_number = 2;
         */
        serialNumber: number;
    } | {
        oneofKind: "sessionTimestamp";
        /**
         * @generated from protobuf field: uint64 session_timestamp = 3;
         */
        sessionTimestamp: bigint;
    } | {
        oneofKind: "location";
        /**
         * @generated from protobuf field: Location location = 4;
         */
        location: Location;
    } | {
        oneofKind: "sessionIdList";
        /**
         * @generated from protobuf field: SessionIdList session_id_list = 5;
         */
        sessionIdList: SessionIdList;
    } | {
        oneofKind: "sessionId";
        /**
         * @generated from protobuf field: uint32 session_id = 6;
         */
        sessionId: number;
    } | {
        oneofKind: "logResponse";
        /**
         * @generated from protobuf field: SessionLogResponse log_response = 7;
         */
        logResponse: SessionLogResponse;
    } | {
        oneofKind: "systemInfo";
        /**
         * @generated from protobuf field: SystemInfo system_info = 8;
         */
        systemInfo: SystemInfo;
    } | {
        oneofKind: "error";
        /**
         * @generated from protobuf field: CommandError error = 9;
         */
        error: CommandError;
    } | {
        oneofKind: "deviceModel";
        /**
         * @generated from protobuf field: DeviceModel device_model = 10;
         */
        deviceModel: DeviceModel;
    } | {
        oneofKind: "loggingToggle";
        /**
         * @generated from protobuf field: uint32 logging_toggle = 11;
         */
        loggingToggle: number; // 0 = off, 1 = on
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf enum ErrorCodes
 */
export enum ErrorCodes {
    /**
     * @generated from protobuf enum value: ERROR_CODES_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: ERROR_CODES_NO_LOGS_FOR_SESSION = 1;
     */
    NO_LOGS_FOR_SESSION = 1,
    /**
     * @generated from protobuf enum value: ERROR_CODES_BAD_OWNERSHIP_KEY_FOR_SESSION = 2;
     */
    BAD_OWNERSHIP_KEY_FOR_SESSION = 2,
    /**
     * @generated from protobuf enum value: ERROR_CODES_MAX = 3;
     */
    MAX = 3
}
/**
 * @generated from protobuf enum SteCommands
 */
export enum SteCommands {
    /**
     * @generated from protobuf enum value: STE_COMMANDS_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * done
     *
     * @generated from protobuf enum value: STE_COMMANDS_SET_SERIAL_NUMBER = 1;
     */
    SET_SERIAL_NUMBER = 1,
    /**
     * done
     *
     * @generated from protobuf enum value: STE_COMMANDS_GET_SERIAL_NUMBER = 2;
     */
    GET_SERIAL_NUMBER = 2,
    /**
     * done
     *
     * @generated from protobuf enum value: STE_COMMANDS_SET_SESSION_TIMESTAMP = 3;
     */
    SET_SESSION_TIMESTAMP = 3,
    /**
     * done
     *
     * @generated from protobuf enum value: STE_COMMANDS_SET_LOCATION = 4;
     */
    SET_LOCATION = 4,
    /**
     * done
     *
     * @generated from protobuf enum value: STE_COMMANDS_GET_SESSION_IDS = 5;
     */
    GET_SESSION_IDS = 5,
    /**
     * this one
     *
     * @generated from protobuf enum value: STE_COMMANDS_GET_SESSION_LOGS = 6;
     */
    GET_SESSION_LOGS = 6,
    /**
     * this one's response
     *
     * @generated from protobuf enum value: STE_COMMANDS_GET_SESSION_LOGS_RESPONSE = 7;
     */
    GET_SESSION_LOGS_RESPONSE = 7,
    /**
     * this one's response
     *
     * @generated from protobuf enum value: STE_COMMANDS_START_FULL_CURRENT_SESSION_NOTIFY = 8;
     */
    START_FULL_CURRENT_SESSION_NOTIFY = 8,
    /**
     * this one's response
     *
     * @generated from protobuf enum value: STE_COMMANDS_UPDATE_CURRENT_SESSION_NOTIFY = 9;
     */
    UPDATE_CURRENT_SESSION_NOTIFY = 9,
    /**
     * done
     *
     * @generated from protobuf enum value: STE_COMMANDS_DELETE_SESSION = 10;
     */
    DELETE_SESSION = 10,
    /**
     * done
     *
     * @generated from protobuf enum value: STE_COMMANDS_DELETE_ALL_SESSIONS = 11;
     */
    DELETE_ALL_SESSIONS = 11,
    /**
     * need to do
     *
     * @generated from protobuf enum value: STE_COMMANDS_GET_BATTERY_LEVEL = 12;
     */
    GET_BATTERY_LEVEL = 12,
    /**
     * need to do
     *
     * @generated from protobuf enum value: STE_COMMANDS_GET_SYSTEM_INFO = 13;
     */
    GET_SYSTEM_INFO = 13,
    /**
     * @generated from protobuf enum value: STE_COMMANDS_COMMANDS_MAX = 14;
     */
    COMMANDS_MAX = 14,
    /**
     * @generated from protobuf enum value: STE_COMMANDS_SET_LOGGING_STATE = 15;
     */
    SET_LOGGING_STATE = 15,
    /**
     * @generated from protobuf enum value: STE_COMMANDS_GET_LOGGING_STATE = 16;
     */
    GET_LOGGING_STATE = 16,
    /**
     * @generated from protobuf enum value: STE_COMMANDS_SET_MODEL = 17;
     */
    SET_MODEL = 17,
    /**
     * @generated from protobuf enum value: STE_COMMANDS_GET_MODEL = 18;
     */
    GET_MODEL = 18
}
// @generated message type with reflection information, may provide speed optimized methods
class SessionLog$Type extends MessageType<SessionLog> {
    constructor() {
        super("SessionLog", [
            { no: 1, name: "average_exposure_rate", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "alert_status", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "high_alarm_level", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "high_alarm_level_time", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "average_alarm_level", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "total_alarm_time", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "average_counts", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "saturated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "log_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SessionLog>): SessionLog {
        const message = { averageExposureRate: 0, alertStatus: 0, highAlarmLevel: 0, highAlarmLevelTime: 0, averageAlarmLevel: 0, totalAlarmTime: 0, averageCounts: 0, saturated: false, logCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SessionLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionLog): SessionLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 average_exposure_rate */ 1:
                    message.averageExposureRate = reader.uint32();
                    break;
                case /* uint32 alert_status */ 2:
                    message.alertStatus = reader.uint32();
                    break;
                case /* uint32 high_alarm_level */ 3:
                    message.highAlarmLevel = reader.uint32();
                    break;
                case /* uint32 high_alarm_level_time */ 4:
                    message.highAlarmLevelTime = reader.uint32();
                    break;
                case /* uint32 average_alarm_level */ 5:
                    message.averageAlarmLevel = reader.uint32();
                    break;
                case /* uint32 total_alarm_time */ 6:
                    message.totalAlarmTime = reader.uint32();
                    break;
                case /* uint32 average_counts */ 7:
                    message.averageCounts = reader.uint32();
                    break;
                case /* bool saturated */ 8:
                    message.saturated = reader.bool();
                    break;
                case /* uint32 log_count */ 9:
                    message.logCount = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 average_exposure_rate = 1; */
        if (message.averageExposureRate !== 0)
            writer.tag(1, WireType.Varint).uint32(message.averageExposureRate);
        /* uint32 alert_status = 2; */
        if (message.alertStatus !== 0)
            writer.tag(2, WireType.Varint).uint32(message.alertStatus);
        /* uint32 high_alarm_level = 3; */
        if (message.highAlarmLevel !== 0)
            writer.tag(3, WireType.Varint).uint32(message.highAlarmLevel);
        /* uint32 high_alarm_level_time = 4; */
        if (message.highAlarmLevelTime !== 0)
            writer.tag(4, WireType.Varint).uint32(message.highAlarmLevelTime);
        /* uint32 average_alarm_level = 5; */
        if (message.averageAlarmLevel !== 0)
            writer.tag(5, WireType.Varint).uint32(message.averageAlarmLevel);
        /* uint32 total_alarm_time = 6; */
        if (message.totalAlarmTime !== 0)
            writer.tag(6, WireType.Varint).uint32(message.totalAlarmTime);
        /* uint32 average_counts = 7; */
        if (message.averageCounts !== 0)
            writer.tag(7, WireType.Varint).uint32(message.averageCounts);
        /* bool saturated = 8; */
        if (message.saturated !== false)
            writer.tag(8, WireType.Varint).bool(message.saturated);
        /* uint32 log_count = 9; */
        if (message.logCount !== 0)
            writer.tag(9, WireType.Varint).uint32(message.logCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SessionLog
 */
export const SessionLog = new SessionLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("Location", [
            { no: 1, name: "latitude_1e7", kind: "scalar", jsonName: "latitude1e7", T: 15 /*ScalarType.SFIXED32*/ },
            { no: 2, name: "longitude_1e7", kind: "scalar", jsonName: "longitude1e7", T: 15 /*ScalarType.SFIXED32*/ }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = { latitude1E7: 0, longitude1E7: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sfixed32 latitude_1e7 = 1 [json_name = "latitude1e7"];*/ 1:
                    message.latitude1E7 = reader.sfixed32();
                    break;
                case /* sfixed32 longitude_1e7 = 2 [json_name = "longitude1e7"];*/ 2:
                    message.longitude1E7 = reader.sfixed32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sfixed32 latitude_1e7 = 1 [json_name = "latitude1e7"]; */
        if (message.latitude1E7 !== 0)
            writer.tag(1, WireType.Bit32).sfixed32(message.latitude1E7);
        /* sfixed32 longitude_1e7 = 2 [json_name = "longitude1e7"]; */
        if (message.longitude1E7 !== 0)
            writer.tag(2, WireType.Bit32).sfixed32(message.longitude1E7);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Session$Type extends MessageType<Session> {
    constructor() {
        super("Session", [
            { no: 1, name: "location", kind: "message", T: () => Location },
            { no: 2, name: "time_start", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "pub_key", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 64, fixedLength: true } } }
        ]);
    }
    create(value?: PartialMessage<Session>): Session {
        const message = { timeStart: 0n, pubKey: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Session>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Session): Session {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Location location */ 1:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* uint64 time_start */ 2:
                    message.timeStart = reader.uint64().toBigInt();
                    break;
                case /* bytes pub_key */ 3:
                    message.pubKey = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Session, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Location location = 1; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint64 time_start = 2; */
        if (message.timeStart !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.timeStart);
        /* bytes pub_key = 3; */
        if (message.pubKey.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.pubKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Session
 */
export const Session = new Session$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionLogResponse$Type extends MessageType<SessionLogResponse> {
    constructor() {
        super("SessionLogResponse", [
            { no: 1, name: "id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "total_bytes", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "current_position", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "segment_length", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "session", kind: "message", T: () => Session },
            { no: 6, name: "raw_encoded_session_logs", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 500 } } }
        ]);
    }
    create(value?: PartialMessage<SessionLogResponse>): SessionLogResponse {
        const message = { id: 0, totalBytes: 0, currentPosition: 0, segmentLength: 0, rawEncodedSessionLogs: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SessionLogResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionLogResponse): SessionLogResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 id */ 1:
                    message.id = reader.uint32();
                    break;
                case /* uint32 total_bytes */ 2:
                    message.totalBytes = reader.uint32();
                    break;
                case /* uint32 current_position */ 3:
                    message.currentPosition = reader.uint32();
                    break;
                case /* uint32 segment_length */ 4:
                    message.segmentLength = reader.uint32();
                    break;
                case /* Session session */ 5:
                    message.session = Session.internalBinaryRead(reader, reader.uint32(), options, message.session);
                    break;
                case /* bytes raw_encoded_session_logs */ 6:
                    message.rawEncodedSessionLogs = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionLogResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).uint32(message.id);
        /* uint32 total_bytes = 2; */
        if (message.totalBytes !== 0)
            writer.tag(2, WireType.Varint).uint32(message.totalBytes);
        /* uint32 current_position = 3; */
        if (message.currentPosition !== 0)
            writer.tag(3, WireType.Varint).uint32(message.currentPosition);
        /* uint32 segment_length = 4; */
        if (message.segmentLength !== 0)
            writer.tag(4, WireType.Varint).uint32(message.segmentLength);
        /* Session session = 5; */
        if (message.session)
            Session.internalBinaryWrite(message.session, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bytes raw_encoded_session_logs = 6; */
        if (message.rawEncodedSessionLogs.length)
            writer.tag(6, WireType.LengthDelimited).bytes(message.rawEncodedSessionLogs);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SessionLogResponse
 */
export const SessionLogResponse = new SessionLogResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionLogs$Type extends MessageType<SessionLogs> {
    constructor() {
        super("SessionLogs", [
            { no: 1, name: "session_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "total_log_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SessionLogs>): SessionLogs {
        const message = { sessionId: 0, totalLogCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SessionLogs>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionLogs): SessionLogs {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 session_id */ 1:
                    message.sessionId = reader.uint32();
                    break;
                case /* uint32 total_log_count */ 2:
                    message.totalLogCount = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionLogs, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 session_id = 1; */
        if (message.sessionId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.sessionId);
        /* uint32 total_log_count = 2; */
        if (message.totalLogCount !== 0)
            writer.tag(2, WireType.Varint).uint32(message.totalLogCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SessionLogs
 */
export const SessionLogs = new SessionLogs$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionEntry$Type extends MessageType<SessionEntry> {
    constructor() {
        super("SessionEntry", [
            { no: 1, name: "session_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "timestamp", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<SessionEntry>): SessionEntry {
        const message = { sessionId: 0, size: 0, timestamp: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SessionEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionEntry): SessionEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 session_id */ 1:
                    message.sessionId = reader.uint32();
                    break;
                case /* uint32 size */ 2:
                    message.size = reader.uint32();
                    break;
                case /* uint64 timestamp */ 3:
                    message.timestamp = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 session_id = 1; */
        if (message.sessionId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.sessionId);
        /* uint32 size = 2; */
        if (message.size !== 0)
            writer.tag(2, WireType.Varint).uint32(message.size);
        /* uint64 timestamp = 3; */
        if (message.timestamp !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.timestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SessionEntry
 */
export const SessionEntry = new SessionEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionIdList$Type extends MessageType<SessionIdList> {
    constructor() {
        super("SessionIdList", [
            { no: 1, name: "total_session_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "session_entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SessionEntry, options: { nanopb: { maxCount: 100 } } },
            { no: 3, name: "is_final_list", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SessionIdList>): SessionIdList {
        const message = { totalSessionCount: 0, sessionEntries: [], isFinalList: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SessionIdList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionIdList): SessionIdList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 total_session_count */ 1:
                    message.totalSessionCount = reader.uint32();
                    break;
                case /* repeated SessionEntry session_entries */ 2:
                    message.sessionEntries.push(SessionEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool is_final_list */ 3:
                    message.isFinalList = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionIdList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 total_session_count = 1; */
        if (message.totalSessionCount !== 0)
            writer.tag(1, WireType.Varint).uint32(message.totalSessionCount);
        /* repeated SessionEntry session_entries = 2; */
        for (let i = 0; i < message.sessionEntries.length; i++)
            SessionEntry.internalBinaryWrite(message.sessionEntries[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool is_final_list = 3; */
        if (message.isFinalList !== false)
            writer.tag(3, WireType.Varint).bool(message.isFinalList);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SessionIdList
 */
export const SessionIdList = new SessionIdList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SystemInfo$Type extends MessageType<SystemInfo> {
    constructor() {
        super("SystemInfo", [
            { no: 1, name: "version", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 3, fixedLength: true } } }
        ]);
    }
    create(value?: PartialMessage<SystemInfo>): SystemInfo {
        const message = { version: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SystemInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SystemInfo): SystemInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes version */ 1:
                    message.version = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SystemInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes version = 1; */
        if (message.version.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.version);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SystemInfo
 */
export const SystemInfo = new SystemInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommandError$Type extends MessageType<CommandError> {
    constructor() {
        super("CommandError", [
            { no: 1, name: "command", kind: "enum", T: () => ["SteCommands", SteCommands, "STE_COMMANDS_"] },
            { no: 2, name: "error_code", kind: "enum", T: () => ["ErrorCodes", ErrorCodes, "ERROR_CODES_"] },
            { no: 3, name: "session_id", kind: "scalar", oneof: "errorData", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<CommandError>): CommandError {
        const message = { command: 0, errorCode: 0, errorData: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CommandError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CommandError): CommandError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SteCommands command */ 1:
                    message.command = reader.int32();
                    break;
                case /* ErrorCodes error_code */ 2:
                    message.errorCode = reader.int32();
                    break;
                case /* uint32 session_id */ 3:
                    message.errorData = {
                        oneofKind: "sessionId",
                        sessionId: reader.uint32()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CommandError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* SteCommands command = 1; */
        if (message.command !== 0)
            writer.tag(1, WireType.Varint).int32(message.command);
        /* ErrorCodes error_code = 2; */
        if (message.errorCode !== 0)
            writer.tag(2, WireType.Varint).int32(message.errorCode);
        /* uint32 session_id = 3; */
        if (message.errorData.oneofKind === "sessionId")
            writer.tag(3, WireType.Varint).uint32(message.errorData.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CommandError
 */
export const CommandError = new CommandError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceModel$Type extends MessageType<DeviceModel> {
    constructor() {
        super("DeviceModel", [
            { no: 1, name: "model", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { nanopb: { maxSize: 16, fixedLength: true } } }
        ]);
    }
    create(value?: PartialMessage<DeviceModel>): DeviceModel {
        const message = { model: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceModel): DeviceModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes model */ 1:
                    message.model = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes model = 1; */
        if (message.model.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.model);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeviceModel
 */
export const DeviceModel = new DeviceModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SteCommand$Type extends MessageType<SteCommand> {
    constructor() {
        super("SteCommand", [
            { no: 1, name: "command", kind: "enum", T: () => ["SteCommands", SteCommands, "STE_COMMANDS_"] },
            { no: 2, name: "serial_number", kind: "scalar", oneof: "commandData", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "session_timestamp", kind: "scalar", oneof: "commandData", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "location", kind: "message", oneof: "commandData", T: () => Location },
            { no: 5, name: "session_id_list", kind: "message", oneof: "commandData", T: () => SessionIdList },
            { no: 6, name: "session_id", kind: "scalar", oneof: "commandData", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "log_response", kind: "message", oneof: "commandData", T: () => SessionLogResponse },
            { no: 8, name: "system_info", kind: "message", oneof: "commandData", T: () => SystemInfo },
            { no: 9, name: "error", kind: "message", oneof: "commandData", T: () => CommandError },
            { no: 10, name: "device_model", kind: "message", oneof: "commandData", T: () => DeviceModel },
            { no: 11, name: "logging_toggle", kind: "scalar", oneof: "commandData", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SteCommand>): SteCommand {
        const message = { command: 0, commandData: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SteCommand>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SteCommand): SteCommand {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SteCommands command */ 1:
                    message.command = reader.int32();
                    break;
                case /* uint32 serial_number */ 2:
                    message.commandData = {
                        oneofKind: "serialNumber",
                        serialNumber: reader.uint32()
                    };
                    break;
                case /* uint64 session_timestamp */ 3:
                    message.commandData = {
                        oneofKind: "sessionTimestamp",
                        sessionTimestamp: reader.uint64().toBigInt()
                    };
                    break;
                case /* Location location */ 4:
                    message.commandData = {
                        oneofKind: "location",
                        location: Location.internalBinaryRead(reader, reader.uint32(), options, (message.commandData as any).location)
                    };
                    break;
                case /* SessionIdList session_id_list */ 5:
                    message.commandData = {
                        oneofKind: "sessionIdList",
                        sessionIdList: SessionIdList.internalBinaryRead(reader, reader.uint32(), options, (message.commandData as any).sessionIdList)
                    };
                    break;
                case /* uint32 session_id */ 6:
                    message.commandData = {
                        oneofKind: "sessionId",
                        sessionId: reader.uint32()
                    };
                    break;
                case /* SessionLogResponse log_response */ 7:
                    message.commandData = {
                        oneofKind: "logResponse",
                        logResponse: SessionLogResponse.internalBinaryRead(reader, reader.uint32(), options, (message.commandData as any).logResponse)
                    };
                    break;
                case /* SystemInfo system_info */ 8:
                    message.commandData = {
                        oneofKind: "systemInfo",
                        systemInfo: SystemInfo.internalBinaryRead(reader, reader.uint32(), options, (message.commandData as any).systemInfo)
                    };
                    break;
                case /* CommandError error */ 9:
                    message.commandData = {
                        oneofKind: "error",
                        error: CommandError.internalBinaryRead(reader, reader.uint32(), options, (message.commandData as any).error)
                    };
                    break;
                case /* DeviceModel device_model */ 10:
                    message.commandData = {
                        oneofKind: "deviceModel",
                        deviceModel: DeviceModel.internalBinaryRead(reader, reader.uint32(), options, (message.commandData as any).deviceModel)
                    };
                    break;
                case /* uint32 logging_toggle */ 11:
                    message.commandData = {
                        oneofKind: "loggingToggle",
                        loggingToggle: reader.uint32()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SteCommand, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* SteCommands command = 1; */
        if (message.command !== 0)
            writer.tag(1, WireType.Varint).int32(message.command);
        /* uint32 serial_number = 2; */
        if (message.commandData.oneofKind === "serialNumber")
            writer.tag(2, WireType.Varint).uint32(message.commandData.serialNumber);
        /* uint64 session_timestamp = 3; */
        if (message.commandData.oneofKind === "sessionTimestamp")
            writer.tag(3, WireType.Varint).uint64(message.commandData.sessionTimestamp);
        /* Location location = 4; */
        if (message.commandData.oneofKind === "location")
            Location.internalBinaryWrite(message.commandData.location, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* SessionIdList session_id_list = 5; */
        if (message.commandData.oneofKind === "sessionIdList")
            SessionIdList.internalBinaryWrite(message.commandData.sessionIdList, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint32 session_id = 6; */
        if (message.commandData.oneofKind === "sessionId")
            writer.tag(6, WireType.Varint).uint32(message.commandData.sessionId);
        /* SessionLogResponse log_response = 7; */
        if (message.commandData.oneofKind === "logResponse")
            SessionLogResponse.internalBinaryWrite(message.commandData.logResponse, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* SystemInfo system_info = 8; */
        if (message.commandData.oneofKind === "systemInfo")
            SystemInfo.internalBinaryWrite(message.commandData.systemInfo, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* CommandError error = 9; */
        if (message.commandData.oneofKind === "error")
            CommandError.internalBinaryWrite(message.commandData.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* DeviceModel device_model = 10; */
        if (message.commandData.oneofKind === "deviceModel")
            DeviceModel.internalBinaryWrite(message.commandData.deviceModel, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* uint32 logging_toggle = 11; */
        if (message.commandData.oneofKind === "loggingToggle")
            writer.tag(11, WireType.Varint).uint32(message.commandData.loggingToggle);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SteCommand
 */
export const SteCommand = new SteCommand$Type();
