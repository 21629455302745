
import { AllServcies } from "../services/startup/startup.service"
import { DashboardViewModel, PersonViewModel } from '../generated_proto/google/app/viewmodel/v1/app_pb';


export class DashboardViewModelImplemented extends DashboardViewModel {

	private log_prefix:string = " :: DashboardViewModelImplemented : ";
	private services:AllServcies;

	// Since these objects are created at runtime, they need to be passed references to the Services
	constructor(
		services:AllServcies
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		// Can pass as param, not used in this case but would
		// be useful behavior for sub classes
		this.services = services;
	}

	update(update:DashboardViewModel) {
		// Update our local from a grpc response

		// Update our Existing people objects if getting a dashboard update (likely from server)
		if(update.getPeopleList().length>0){
			update.getPeopleList().map((person:PersonViewModel) =>{
				// this.services.proto.updatePersonViewModelImplemented(person);
			});
		}
		Object.assign(this, update);
	}

	refresh() {
		// Update our local UI
		// This is where you can handle any UI specific bindings from the model's data 
	}

	stream() {
		// console.log(this.log_prefix, "Calling Stream");
		// this.services.grpc.streamDashboard(this);
		
	}


}	