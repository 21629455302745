import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SensorTimelineComponentPageRoutingModule } from './sensor-timeline-component-routing.module';

import { SensorTimelineComponentPage } from './sensor-timeline-component.page';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		SensorTimelineComponentPageRoutingModule,
		
	],
	declarations: [
		SensorTimelineComponentPage,
	],
	exports: [
		SensorTimelineComponentPage,
	]
})
export class SensorTimelineComponentPageModule {}
