import { Component, OnInit, SimpleChanges, ViewChild, Input } from '@angular/core';

import { StartupService } from 'src/app/services/startup/startup.service';
@Component({
	selector: 'apex-chart-component',
	templateUrl: './apex-chart.component.html',
	styleUrls: ['./apex-chart.component.css'],
})
export class ApexChartComponent implements OnInit {
	@ViewChild("chart") chart;

	@Input()
	public data = [];

	@Input()
	public timeline = [];

	@Input()
	public name:string = "";

	@Input()
	public units:string = "";

	@Input()
	public useMaxMin:boolean = false;

	@Input()
	public max:number = Number.MAX_VALUE;

	@Input()
	public min:number = 0;

	public chartOptions;

	constructor( private startupService: StartupService) {

	}

	ngOnInit() {
		// console.log("SensorChartComponent ngOnInit");
		
		this.updateChart();
	}
	
	public ngOnChanges(changes: SimpleChanges) {
		// console.log("changes is : ", changes);
	}
	
	updateChart() {
		this.startupService.ready().then(() => {
			var setupChartOptions:any = {
				title: {
					text: this.name,
					align: "center",
					floating: true,
					margin: 0,
				},
				series: [
					{
						name: this.name,
						data: this.data
					}
				],
				plotOptions: {
					area: {
						fill: {
							type: 'class',
							class: 'area-custom-gradient',
						},
					},
				},
				chart: {
					type: "area",
					height: 150,
					animations: {
						enabled: false,
					},
					sparkline: {
						enabled: false,
					},
				},
				stroke: {
					width: 1,
				},
				dataLabels: {
					enabled: false
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
						datetimeFormatter: {
							year: 'yyyy',
							month: "MMM 'yy",
							day: 'ddd MMM dd',
							hour: 'h:mm:tt',
							minute: 'h:mm:ss tt',
						},
					},
					categories: this.timeline
				},
				yaxis: {
					tickAmount: 1,
					show: true,
					floating: false,
					decimalsInFloat: 2,
					axisTicks: {
						show: true
					},
					axisBorder: {
						show: true
					},
					labels: {
						show: true
					},
					title: {
						text: this.units,
					},
					cssClass: 'apexcharts-yaxis-text',
				},
				tooltip: {
					x: {
						format: "dd MMM yyyy h:mm:tt"
					}
				},
			};
	
			if(this.useMaxMin){
				setupChartOptions.yaxis.max = this.max;
				setupChartOptions.yaxis.min = this.min;
			}

			if(this.startupService.services.settings?.lightActive == true){
				if(this.startupService.services.settings?.SETTINGS.APP_STYLE_LIGHT_ACCENT_COLOR){
					setupChartOptions.colors = [this.startupService.services.settings.SETTINGS.APP_STYLE_LIGHT_ACCENT_COLOR];
					console.log("this.startupService.services.settings.SETTINGS.APP_STYLE_LIGHT_ACCENT_COLOR :: ", this.startupService.services.settings.SETTINGS.APP_STYLE_LIGHT_ACCENT_COLOR)
				}
			}
			else {
				if(this.startupService.services.settings?.SETTINGS.APP_STYLE_DARK_ACCENT_COLOR){
					setupChartOptions.colors = [this.startupService.services.settings.SETTINGS.APP_STYLE_DARK_ACCENT_COLOR];
					console.log("this.startupService.services.settings.SETTINGS.APP_STYLE_DARK_ACCENT_COLOR :: ", this.startupService.services.settings.SETTINGS.APP_STYLE_DARK_ACCENT_COLOR)
				}
			}

			this.chartOptions = setupChartOptions;
	
			//call in 10 ms superhack? 
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 10);
		});
	}

	isNumber(val): boolean { return (typeof val === 'number') && (val != 0); }

}
	