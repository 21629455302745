import { Component, AfterViewInit, ViewChild, Output, ElementRef, EventEmitter } from '@angular/core';

import { StartupService } from "../../../services/startup/startup.service"

import { DEFAULT_MAP_SETTINGS, MapDataPoint, MapService, MapSettings, TileLayerOptions, } from "../map.service"
import { Deck, WebMercatorViewport, MapView, } from "@deck.gl/core";
import { timeStamp } from 'console';
import { Position } from '../../../generated_proto/protobuf-ts/pb/v2/entities';


@Component({
	selector: 'map-select-position',
	templateUrl: './map-select-position.component.html',
	styleUrls: ['./map-select-position.component.css'],
})
export class MapSelectPositionComponent implements AfterViewInit {
	@Output()
	public positionSelected = new EventEmitter<{latitude:number, longitude:number}>();

	@ViewChild('mapSelectPosition') mapCanvas:ElementRef<HTMLCanvasElement>;

	public bindTest:string = "testasdfasdfasd";

	public loading:boolean = true;

	public deck:Deck = null;
	public mapName:string = "Select-Position";
	public mapSettings:MapSettings = DEFAULT_MAP_SETTINGS;

	public latitude:number = 0;
	public longitude:number = 0;

	public showAddress:boolean = true;
	public address:string = "";

	constructor(
		public mapService:MapService,
		private startupService:StartupService,
	)
	{
		
	}

	ngAfterViewInit() {
		// !IMPORTANT! - So angular will run the @ViewChild methods after the view is rendered
	}

	public async init(existingPosition?:Position) {
		await this.mapService.buildMap(this.mapName, this.mapCanvas, [], undefined, undefined, this.onViewStateChange.bind(this) ).then( (deck:Deck) => {
			this.loading = false;
			this.deck = deck;
			if(existingPosition){
				console.log("Drawing with existing position : ", existingPosition);
				this.drawCenterPoint(existingPosition.longitude, existingPosition.latitude);
				this.mapService.zoomToLngLat(this.deck, existingPosition.longitude, existingPosition.latitude, 20);
			}
			else {
				this.drawCenterPoint(this.deck.viewState.longitude, this.deck.viewState.latitude);
			}
		});
	}

	onViewStateChange(change:any) {
		console.log(change.viewState);
		this.drawCenterPoint(change.viewState.longitude, change.viewState.latitude);
	}

	drawCenterPoint(longitude:number, latitude:number){
		this.longitude = longitude;
		this.latitude = latitude;
		if(this.deck){
			// TODO :: Get --accent variable from theme
			var style = window.getComputedStyle(document.documentElement);

			var centerPoint:MapDataPoint = {
				coordinates: [longitude, latitude],
				color: new Uint8Array([0, 0, 0, 255])
			}
			var iconLayer = this.mapService.getCenterTargetIconLayer("select-center", centerPoint);
			this.mapService.updateMap(this.deck, [iconLayer] );
		}
	}

	toggleAddress(){
		this.showAddress = !this.showAddress;
	}

	getAddress(){
		this.showAddress = false;
		if(this.address.length>0){
			this.loading = true;
			this.startupService.services.geolocate?.addressToPosition(this.address).then( (geometry) => {
				this.loading = false;
				if(geometry.lng != 0 && geometry.lat != 0){
					this.drawCenterPoint(geometry.lng, geometry.lat);
					this.startupService.services.map?.zoomToLngLat(this.deck, geometry.lng, geometry.lat);
				}
				else {
					this.startupService.services.uiHelper?.showError("Incorrect Response: "+JSON.stringify(geometry), {userDismiss:false, duration:3000});
				}
			})
			.catch((err) => {
				this.startupService.services.uiHelper?.showError("Could not find address: "+JSON.stringify(err), {userDismiss:false, duration:3000});
			})
		}
	}

	public selectTileLayer() {
		this.mapService.selectTileLayer().then( () => {
			this.drawCenterPoint(this.latitude, this.longitude);
		});
	}

	public savePosition(){
		this.positionSelected.emit({longitude:this.longitude, latitude:this.latitude});
	}


}
