import { AllServcies } from '../startup/startup.service';

import { ModelType } from '../../generated_proto/protobuf-ts/pb/v2/models';
import { LoggerOptions } from '../logger/logger.service';
import { GeoNodeViewModelImplemented, AllGeoNodeImplemented } from '../../viewmodels/geo/geonode.vmi';
import { GeoNodeType } from '../../generated_proto/protobuf-ts/pb/v2/entities';
import { SiteViewModelImplemented } from '../../viewmodels/geo/site.vmi';
import { FloorViewModelImplemented } from '../../viewmodels/geo/floor.vmi';
import { RoomViewModelImplemented } from '../../viewmodels/geo/room.vmi';
import { AreaViewModelImplemented } from '../../viewmodels/geo/area.vmi';

export class GeoNodePouchdbService {

	public loggerOptions: LoggerOptions = {
		prefix: "AssetManagementPouchdbService",
		allOn: true,
		verboseOn: true,
		debugOn: true,
	};

	constructor(
	) {

	}

	public init(allServices: AllServcies): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			if (allServices == null) {
				reject({ code: 0, message: "Services Not Given" });
			}
			else {
				this.setServices(allServices);
			}
			resolve(true);
		});
	}

	private services: AllServcies;;
	public setServices(services) {
		this.services = services
	}
	public getGeoNodeByUUID(app_id: number, uuid: bigint): Promise<GeoNodeViewModelImplemented> {
		return new Promise(async (resolve, reject) => {
			console.log("getBuildingByUUID: ", app_id, uuid);
			if (!this.services.data || !this.services.pouch) {
				reject({ code: 0, message: "Services Not Given" });
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id) + ModelType.GEONODE_MODEL);
			var dbConnection;
			if (this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY) {
				dbConnection = db.localDb;
			}
			else {
				dbConnection = db.remoteDb;
			}
			dbConnection.get(GeoNodeViewModelImplemented.GenerateDbKey(uuid))
				.then((doc: any) => {
					console.log(doc);
					if (this.services.proto) {
						var newThing: GeoNodeViewModelImplemented = this.services.proto.createGeoNodeFromDb(doc) as GeoNodeViewModelImplemented;// = this.services.proto.createGeoNodeFromDb(doc) as SiteViewModelImplemented;
						console.log(newThing);
						resolve(newThing);
					}
				}).catch((err) => {
					reject(err);
				});
		});
	}
	getGeoNodeByUrn(app_id: number, urn: string): Promise<GeoNodeViewModelImplemented> {
		return new Promise(async (resolve, reject) => {
			if (this.services.pouch && this.services.pouch.app) {
				if (this.services.data) {
					var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id) + ModelType.DEVICE_MODEL);
					var dbConnection;
					if (this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY) {
						dbConnection = db.localDb;
					}
					else {
						dbConnection = db.remoteDb;
					}
					dbConnection.get(urn)
						.then((doc: any) => {
							console.log("getGeoNodeByUrn:doc", doc)
							if (this.services.proto) {
								var newThing: AllGeoNodeImplemented = this.services.proto.createGeoNodeFromDb(doc)
								resolve(newThing);
							}
						}).catch((err) => {
							reject(err);
						});
				}
				else {
					reject("Data Service not set");
				}
			}
			else {
				reject("Pouch/app not set");
			}
		});
	}

	public getSiteList(app_id: number): Promise<SiteViewModelImplemented[]> {
		return new Promise(async (resolve, reject) => {
			var geolist = await this.getGeoList(app_id, GeoNodeType.BUILDING);
			var buildingList: SiteViewModelImplemented[] = geolist.filter((item: AllGeoNodeImplemented) => { return item.getType() == GeoNodeType.BUILDING; }) as SiteViewModelImplemented[];
			resolve(buildingList);
		});
	}
	public getSiteByUUID(app_id: number, uuid: bigint): Promise<SiteViewModelImplemented> {
		return new Promise(async (resolve, reject) => {
			console.log("getBuildingByUUID: ", app_id, uuid);
			if (!this.services.data || !this.services.pouch) {
				reject({ code: 0, message: "Services Not Given" });
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id) + ModelType.GEONODE_MODEL);
			var dbConnection;
			if (this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY) {
				dbConnection = db.localDb;
			}
			else {
				dbConnection = db.remoteDb;
			}
			dbConnection.get(SiteViewModelImplemented.GenerateDbKey(uuid))
				.then((doc: any) => {
					console.log(doc);
					if (this.services.proto) {
						var newThing: SiteViewModelImplemented = this.services.proto.createGeoNodeFromDb(doc) as SiteViewModelImplemented;// = this.services.proto.createGeoNodeFromDb(doc) as SiteViewModelImplemented;
						console.log(newThing);
						resolve(newThing);
					}
				}).catch((err) => {
					reject(err);
				});
		});
	}
	// add to this, search via prefix for URN (get all in bulding or floor)
	public getGeoList(app_id: number, type?: GeoNodeType): Promise<AllGeoNodeImplemented[]> {
		var buildingList: AllGeoNodeImplemented[] = [];
		var searchOptions = {
			include_docs: true,
			attachments: false,
		}
		return new Promise(async (resolve, reject) => {
			if (!this.services.data || !this.services.pouch) {
				reject({ code: 0, message: "Services Not Given" });
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id) + ModelType.GEONODE_MODEL);
			db.remoteDb.allDocs(searchOptions)
				.then((result: any) => {
					if (result.rows.length > 0) {
						for (let index = 0; index < result.rows.length; index++) {
							const row = result.rows[index];
							const doc = row.doc;
							if (doc._id[0] == "_") {
								continue;
							}
							if (type) {
								// TODO :: find if it has the right type.
								// CHECK AFTER WE BUILD A BUILDING ADD
								switch (type) {
									case GeoNodeType.BUILDING:
										console.log("buildingVMI", doc);
										var buildingVMI = new SiteViewModelImplemented(this.services);
										buildingVMI.jsonToModel(doc);
										buildingList.push(buildingVMI);
										break;
									case GeoNodeType.FLOOR:
										var floorVMI = new FloorViewModelImplemented(this.services);
										floorVMI.jsonToModel(doc);
										buildingList.push(floorVMI);
										break;
									case GeoNodeType.ROOM:
										var roomVMI = new RoomViewModelImplemented(this.services);
										roomVMI.jsonToModel(doc);
										buildingList.push(roomVMI);
										break;
									case GeoNodeType.AREA:
										var areaVMI = new AreaViewModelImplemented(this.services);
										areaVMI.jsonToModel(doc);
										buildingList.push(areaVMI);
										break;
									default:
										var geoVMI = new GeoNodeViewModelImplemented(this.services);
										geoVMI.jsonToModel(doc);
										buildingList.push(geoVMI);
										break;
								}
							}
						}
					}
					resolve(buildingList);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	public saveGeoNode(app_id: number, geoNodeVMI: GeoNodeViewModelImplemented): Promise<boolean> {
		return new Promise((resolve, reject) => {
			console.log("saveGeoNode: ", geoNodeVMI);
			geoNodeVMI.updateDb();
			var full_doc = Object.assign({}, geoNodeVMI.modelToJson());
			full_doc["_id"] = geoNodeVMI.generateDbKey();

			if (!this.services.pouch) {
				if (this.services.logger) this.services.logger.error(this.loggerOptions, "Pouch not set");
				reject(this.loggerOptions.prefix + "Pouch not set");
				return;
			}
			if (!this.services.data) {
				reject("Data Service not set");
				return
			}
			console.log("saveGeoNode:DB URL ", this.services.data.getDbPrefix(app_id) + ModelType.GEONODE_MODEL + "," + geoNodeVMI.getUUID());
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id) + ModelType.GEONODE_MODEL);
			var dbConnection;
			if (this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY) {
				dbConnection = db.localDb;
			}
			else {
				dbConnection = db.remoteDb;
			}
			dbConnection.get(geoNodeVMI.generateDbKey())
				.then((doc: any) => {
					console.log("saveGeoNode:Doc exists");
					doc = Object.assign(doc, full_doc);
					// Onlytime this is updated after a delete would be from the uRPC or other service
					doc._deleted = false;
					if (!this.services.pouch) {
						if (this.services.logger) this.services.logger.error(this.loggerOptions, "Pouch not set");
						reject(this.loggerOptions.prefix + "Pouch not set");
						return;
					}
					console.log("saveGeoNode:updating doc with put");
					return dbConnection.put(doc)
				})
				.then((result: any) => {

					resolve(true);
				}).catch((err) => {
					if (this.services.logger) this.services.logger.warn(this.loggerOptions, "Not Found");
					if (err.status != 404) {
						if (this.services.logger) this.services.logger.error(this.loggerOptions, "Error", err);
						reject(err);
						return;
					}
					if (this.services.pouch) {
						if (this.services.logger) this.services.logger.warn(this.loggerOptions, "Creating New Sensor");
						dbConnection.put(full_doc).then((result: any) => {
							resolve(true);
						}).catch((err) => {
							reject(err);
						});
					}
					else {
						if (this.services.logger) this.services.logger
						reject(this.loggerOptions.prefix + "Pouch not set");
					}
				});
		});
	}
	public deleteBuilding(app_id: number, buildingVMI: SiteViewModelImplemented): Promise<boolean> {
		return this.deleteGeoNode(app_id, buildingVMI);
	}
	public deleteGeoNode(app_id: number, geonodeVMI: AllGeoNodeImplemented): Promise<boolean> {
		return new Promise((resolve, reject) => {
			geonodeVMI.updateDb();
			if (!this.services.pouch) {
				if (this.services.logger) this.services.logger.error(this.loggerOptions, "Pouch not set");
				reject(this.loggerOptions.prefix + "Pouch not set");
				return;
			}
			if (!this.services.data) {
				reject("Data Service not set");
				return
			}
			console.log("deleteBuilding:DB URL ", this.services.data.getDbPrefix(app_id) + ModelType.GEONODE_MODEL);
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id) + ModelType.GEONODE_MODEL);
			var dbConnection;
			if (this.services.settings?.SETTINGS.APP_DATA_LOCAL_ONLY) {
				dbConnection = db.localDb;
			}
			else {
				dbConnection = db.remoteDb;
			}
			dbConnection.get(geonodeVMI.generateDbKey())
				.then((doc: any) => {
					console.log("deleteDevice:Doc exists ::: DELETING ");
					doc._deleted = true;
					if (!this.services.pouch) {
						if (this.services.logger) this.services.logger.error(this.loggerOptions, "Pouch not set");
						reject(this.loggerOptions.prefix + "Pouch not set");
						return;
					}
					return dbConnection.put(doc)
				}).catch((err) => {
					if (this.services.logger) this.services.logger.warn(this.loggerOptions, "Not Found");
					reject(err);
				});


		});
	}
}
