import { Component, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';
//// Table
import { MatPaginator, } from '@angular/material/paginator';
import { MatTableDataSource,  } from '@angular/material/table';
import { MatSort, } from '@angular/material/sort';
import { TelemetryViewModelImplemented } from '../../viewmodels/Telemetry.vmi';
import { AllDevicesVMI } from 'src/app/viewmodels/AllDevices.vmi';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceViewModelImplemented } from '../../viewmodels/Device.vmi'

@Component({
	selector: 'generic-device-table-component',
	templateUrl: './generic-device-table.component.html',
	styleUrls: ['./generic-device-table.component.scss'],
})
export class GenericDeviceTableComponent implements OnInit {

	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
	devices: AllDevicesVMI[]
	public telemetryViewModelImplemented:TelemetryViewModelImplemented[] = [];

	constructor(
		public changeDetectorRef:ChangeDetectorRef,
		public router: Router,
	){

	}

	ngOnInit() {}

	public async refresh( devices:AllDevicesVMI[]) {
		// this.telemetryViewModelImplemented = telemetries.filter(value => Object.keys(value).length !== 0);
		this.devices = devices;
		await this.tableRender(devices);
		this.changeDetectorRef.detectChanges();
	}

	public flattenJSON(obj={}, res = {}, extraKey = '') {
		var keys = Object.keys(obj);
		for (let index = 0; index < keys.length; index++) {
			const key = keys[index];
			if(typeof obj[key] !== 'object'){
				if(obj[key]) //only add if value exists. Note filters out values of 0
				{
					res[extraKey + key] = obj[key];
				}
			}else{
				this.flattenJSON(obj[key], res, `${extraKey}${key}.`);
			};
		}
		return res;
	};

	public selectedRowIndex: number = -1;
	public dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [];
	fullColumnOptions : string[] = [];
	public dropdownSettings = {
		singleSelection: false,
		allowSearchFilter: true,
		itemsShowLimit: 0,
	};

	public compareWith(e1, e2) {
		// fallback to single element comparison
		return e1 && e2 ? e1.id === e2.id : e1 === e2;
	}

	displayColumnsChange( event ) {
		this.displayedColumns = event.detail.value;
	}

	// TODO :: Sashi i think you were working on this here
	onItemSelect(event){
		console.warn("!!WARN : Not Implemented : ", event);
	}
	onSelectAll(event){
		console.warn("!!WARN : Not Implemented : ", event);
	}
	traverseObject(obj) {
		var object = {};
		for (const key in obj) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				if( obj[key]["tableValid"] ===  true){
					if ("displayname" in obj[key] && "value" in obj[key]) {
						object[obj[key]["displayname"]] = `${obj[key]["value"].toFixed(2)} ${obj[key]["units"]}`;
					}
					object = {...object, ...this.traverseObject(obj[key])}; // Recursive call for nested objects
				} else {
					object = {...object, ...this.traverseObject(obj[key])}; // Recursive call for nested objects
				}
				
			}
		}
		return object;

	}
	getHeaders(obj){
		var object = {};
		for (const key in obj) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				if ("displayname" in obj[key]) {
					object[key] = obj[key]["displayname"]
				}
				if("priority" in obj[key]){
					object[key]["priority"] = obj[key]["priority"];
				}
				object = {...object, ...this.getHeaders(obj[key])}; // Recursive call for nested objects
			}
		}
		return object;
	}
	
	public async tableRender(devices: AllDevicesVMI[]) {
		var data_object: any[] = [];
		for(var i = 0; i < devices.length; i++){
			var device = devices[i];
			var fmtTelem = await device.getFormattedDerrivedTelemeteryForTable();
			var root_keys = Object.keys(fmtTelem);
			var keys: string[] = [];
			// console.log(fmtTelem)
			// if(root_keys.length != 0){
			// 	for(var j = 0; j < root_keys.length; j++){
			// 		console.log(Object.keys(fmtTelem[root_keys[j]]))
			// 	}
			// }
			// console.log(keys)
			var thing = this.traverseObject(fmtTelem);
			var device_data = {'Image': device.getThumbnailImagePath(), 'Name': device.getDisplayName(), 'UUID': device.getDisplayUuid(), "Reported By": device.reportedByDeviceVMI?.getDisplayName() || "", "Last Updated": `${new Date(Number(device.model.db?.updatedMs||0)).toLocaleDateString()} ${new Date(Number(device.model.db?.updatedMs||0)).toLocaleTimeString()}`, ...thing};
			data_object.push(device_data);
		}
		this.fullColumnOptions = Object.keys(data_object[0]);
		this.displayedColumns = Object.keys(data_object[0]);
		// console.log("this.fullColumnOptions",this.fullColumnOptions);

		this.dataSource = new MatTableDataSource<any>(data_object);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.changeDetectorRef.detectChanges();
	}

	public goToDevicePage(element){
		var found = this.devices.find((device:AllDevicesVMI) => "SN: "+device.model.device?.uuid === element.UUID);
		if(found){
			found.goToPage(true);			
		}
	}
	cindex: number
	public clickEvent(index) {
		this.cindex = index
	}

}
