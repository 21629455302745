import { Injectable, HostListener, } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ModalController, Platform, NavController, } from '@ionic/angular';
import { Router, } from '@angular/router';

// https://stackoverflow.com/questions/48913344/angular-5-adding-html-content-dynamically
import { DomSanitizer, Title } from "@angular/platform-browser"
import { AuthService } from '../auth/auth.service';
import { SettingsService } from "../settings/settings.service"
import { DataService } from '../data/data.service';
import { ProtoService, } from '../proto/proto.service';
import { PouchdbService } from '../pouchdb/pouchdb.service';
import { LoggerService } from '../logger/logger.service';
import { UserService } from '../user/user.service';
import { MenuService } from '../menu/menu.service';
import { MapService } from '../../components/map/map.service';
import { EncryptionService } from "../encryption/encryption.service"
import { BehaviorSubject } from 'rxjs';
import { BluetoothService } from '../bluetooth/bluetooth.service';
import { ComportService } from "../comport/comport.service"
import { EventService, } from "../events/events.service"
import { UiHelpersService } from "../ui-helpers/ui-helpers.service"
import { UtilService } from '../util/util.service';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import { GeolocateService } from '../geolocate/geolocate.service';
import { HttpApiClientService } from '../http-api-client/http-api-client.service'
import { SimpleEccService } from "../simple-ecc/simple-ecc.service"
import { BarcodeHandlerService } from '../barcode-handler/barcode-handler.service';
import { GrpcWebClient } from "../grpc-web-client/grpc-web.client"
import { MqttClient } from '../mqtt-client/mqtt-client.service';
import { url } from 'inspector';
import { WebrtcService } from '../webrtc/webrtc.service';
// import { NativeService } from "../native/native.service"
// import { GrpcService } from '../grpc/grpc.service';
// import { ExecutableService  } from '../executable/executable.service';
// import { ParsersService } from '../parsers/parsers.service';
// import { NotificationsService } from "../notifications/notifications.service"

// Why do this?
// Three reasons:
// 1- Created classes (IE: VMI's) will not have access to services in the same way other objects created in pages would
//    - This way, we're able to bundle a reference to all services for those newly generated components
// 2 - Compiling, and dependencies - We can have related services call each other w/o circular dependencies
// 3 - Startup cleanly. Each Init can be done in order. IE: Boot (on any page), init pouch connection, get user, handle if logged out, etc.

// What this now requires from a developer:
// Any new services made must be linked here, and initialized.
// Use these references w/in services (instead of "import")
// Use these references w/in VMI's or any created.

export interface AllServcies {
	startup?: StartupService;
	platform?:Platform,
	http?:HttpClient,
	router?:Router,
	nav?:NavController,
	domSanitizer?:DomSanitizer,
	modal?:ModalController,
	auth?:AuthService,
	settings?:SettingsService
	data?:DataService,
	proto?:ProtoService,
	pouch?:PouchdbService,
	pouchCreator?:any,
	pouchIsServerNode?:boolean,
	bluetooth?:BluetoothService,
	// cordovaBluetooth?:BluetoothLE,
	comport?:ComportService,
	logger?:LoggerService,
	user?:UserService,
	menu?:MenuService
	event?:EventService,
	map?:MapService,
	uiHelper?:UiHelpersService,
	encryption?:EncryptionService,
	util?:UtilService,
	localNotifications?:LocalNotificationsService,
	geolocate?:GeolocateService,
	httpApiClient?:HttpApiClientService,
	simpleEcc?:SimpleEccService,
	barcodeHandler?:BarcodeHandlerService,
	grpcWebClient?:GrpcWebClient
	express?:any,
	serverSettings?:any,
	mqttClient?:MqttClient,
	webhook?:any, // STUB: Not applicable on web
	webrtc?:WebrtcService
	// grpc?:GrpcService,
	// native?:NativeService,
	// executable?:ExecutableService,
	// parsers?:ParsersService,
	// notifications?:NotificationsService,
}

@Injectable({
	providedIn: 'root'
})
export class StartupService {

	private isInit:boolean = false;
	private readySubject:BehaviorSubject<boolean> = new BehaviorSubject(false);

	public services:AllServcies;
	

	constructor(
		private titleService: Title,
		private platform:Platform,
		private http:HttpClient,
		private router:Router,
		private navController:NavController,
		private domSanitizer:DomSanitizer,
		private modal:ModalController,
		private authService:AuthService,
		private userService:UserService,
		private menuService:MenuService,
		private mapService:MapService,
		private eventService:EventService,
		private uiHelpersService:UiHelpersService,
		private localNotificationsService:LocalNotificationsService,
		// private geolocateService:GeolocateService,
		// private nativeService:NativeService,
		// private grpcService:GrpcService,
		// private executableService:ExecutableService,
		// private parsersService:ParsersService,
		// private notifications:NotificationsService,
	)
	{
		
	}

	public async init() : Promise<boolean>{
		

		return new Promise( async (resolve, reject) => {
			// THIS IS THE MASTER BOOT ORDER
			// Before, we always had problems with loading, and what was loaded first, vs second
			// We relied on the constructors to do all the start up, and would have race conditions
			
			// !!! THIS SHOULD EXPLICITLY STEP THROUGH ALL THINGS TO DO AT STARTUP AND THE ORDER IN WHICH IT SHOULD BE DONE !!!
			if(!this.isInit){
				this.platform.ready().then(() => {
					var isWeb = !(this.platform.is("cordova") || this.platform.is("capacitor") || this.platform.is("pwa") || this.platform.is("mobileweb"));
					if(isWeb){
						// This is done to force pouch to use the correct data getting Blob instead of nodejs (since nodeIntegration is unreliable on platforms build)
						(window as any).process = {
							browser: true,
						}
					}
					if(this.platform.is("android") || this.platform.is("ios") || this.platform.is("tablet")){
						(window as any).screen.orientation.lock('portrait');
					}
				});
				
				// Cant rely on this being grabbed from proto service.
				this.services = {};
				this.services.startup = this;
				this.services.platform = this.platform;
				this.services.http = this.http;
				this.services.router = this.router;
				this.services.nav = this.navController;
				this.services.domSanitizer = this.domSanitizer;
				this.services.modal = this.modal;
				this.services.settings = new SettingsService();
				this.services.auth = this.authService;
				this.services.data = new DataService();
				this.services.proto = new ProtoService();
				this.services.pouch = new PouchdbService();
				this.services.user = this.userService;
				this.services.menu = this.menuService;
				this.services.logger = new LoggerService();
				this.services.encryption = new EncryptionService();
				this.services.map = this.mapService;
				this.services.bluetooth = new BluetoothService();
				this.services.comport = new ComportService();
				this.services.event = this.eventService;
				this.services.uiHelper = this.uiHelpersService;
				this.services.util = new UtilService();
				this.services.localNotifications = this.localNotificationsService;
				this.services.geolocate = new GeolocateService();
				this.services.httpApiClient = new HttpApiClientService();
				this.services.simpleEcc = new SimpleEccService();
				this.services.barcodeHandler = new BarcodeHandlerService();
				this.services.grpcWebClient = new GrpcWebClient();
				this.services.mqttClient = new MqttClient();
				this.services.webrtc = new WebrtcService();

				// this.services.native = this.nativeService;
				// this.services.grpc = this.grpcService;
				// this.services.executable = this.executableService;
				// this.services.parsers = this.parsersService;
				// this.services.notifications = this.notifications;

				// This order is pretty important. Make sure the services that are calling other services are handled here
				// correctly. This allows us for example to: 
				// 1) get settings or login from pouch or other places
				// 2) and then apply to our process.
				
				await this.services.settings.init(this.services);
				await this.services.proto.init(this.services);
				await this.menuService.init(this.services);
				await this.services.data.init(this.services); // Gets local storage ready
				await this.services.pouch.init(this.services);
				await this.services.httpApiClient.init(this.services);
				await this.authService.init(this.services); // Setup the auth service to handle logged in state
				await this.userService.init(this.services);
				await this.services.encryption.init();
				console.log("called encryption init")
				// await this.nativeService.init(this.services); // Gets GPS situated and running
				await this.services.bluetooth.init(this.services);
				await this.services.simpleEcc.init(this.services);
				await this.mapService.init(this.services);
				await this.localNotificationsService.init(this.services);
				await this.services.barcodeHandler.init(this.services);
				await this.services.geolocate.init(this.services).catch((err) => {console.error("[init] : geolocateService : ",err);});
				await this.services.grpcWebClient.init(this.services);

				await this.services.webrtc.init();

				if(this.services.settings.SETTINGS["APP_SUPPORT_MQTT_CLIENT"] == true){
					if(this.services.data?.getBackendUrl()){
						var uri = this.services.data?.getBackendUrl(true);
						if(this.services.settings && this.services.settings.SETTINGS.RUN_MODE != "LOCAL"){
							// secure
							await this.services.mqttClient.init(uri, 443, "mqtts", "", "", true, true, (topic:string, payload:Buffer|string) => {
								console.log(" :: MQTT Message :: ", topic, payload.toString());
							});
						}
						else {
							await this.services.mqttClient.init(uri, 80, "mqtts", "", "", false, true, (topic:string, payload:Buffer|string) => {
								console.log(" :: MQTT Message :: ", topic, payload.toString());
							});
						}
						await this.services.mqttClient.connect();
						console.log("Staring subscription");
						await this.services.mqttClient.startMqttSubscription("#", 0);
					}
				}

				this.services.grpcWebClient.serviceAlive().then((response) => {
					console.log("gRPC Service Alive: ", response);
				}).catch((err) => {
					console.error("gRPC Service Not Alive: ", err);
				});

				// // await this.grpcService.init();
				// await this.comportService.init(this.services);
				// this.notifications.init(); 
				// TODO :: Pull the info from logged in user pouch if possible.
				if(this.services.settings.SETTINGS.APP_DEFAULT_BASE_TITLE){
					var title:string = this.services.settings.SETTINGS.APP_DEFAULT_BASE_TITLE
					if(this.services.settings.SETTINGS.APP_DEFAULT_SECOND_TITLE){
						title += " | " + this.services.settings.SETTINGS.APP_DEFAULT_SECOND_TITLE;
					}
					this.titleService.setTitle(title);
				}
				await this.services.settings.afterInit();

				this.isInit = true;
				this.readySubject.next(true);
				resolve(true);
			}
		})
	}

	public async ready() : Promise<boolean>{
		return new Promise( async (resolve, reject) => {
			if(this.readySubject.value == true){
				resolve(true);
				return;
			}
			var readySub = this.readySubject.subscribe( (ready) => {
				if(ready){
					resolve(true);
					if(readySub)readySub.unsubscribe();
				}
			});
		})
	}
}
