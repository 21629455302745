
import { BluetoothCommonInterface } from "../../services/bluetooth/bluetooth.service";
import { AllDevicesVMI } from "../../viewmodels/AllDevices.vmi";

import { StePagerDeviceViewModelImplemented } from "./radpager.vmi";
import { LrmBluetoothConnection, LrmDeviceViewModelImplemented } from "./lrm.vmi";
import { SteRadPackDeviceViewModelImplemented } from "./radpack.vmi";

import { Device, } from "../../generated_proto/protobuf-ts/pb/v2/entities"
import { AllServcies } from "../../services/startup/startup.service";
import { SteBluetoothConnection } from "./ste.vmi";
import { DeviceTypeIds } from "../../generated_proto/protobuf-ts/pb/system";
import { SteDevKitDeviceViewModelImplemented } from "./stedevkit.vmi";
import { EncryptionService } from "../../services/encryption/encryption.service";

export class SteBluetooth {
	
	constructor
	(
		private allServices:AllServcies,
	)
	{
		if(allServices){
			this.setServices(allServices);
		}
	}

	public services:AllServcies;;
	public setServices(services){
		this.services = services
	}

	public getSupportedDevices() : AllDevicesVMI[] {
		if(this.services.settings?.SETTINGS.APP_ID){
			if(this.services.settings.SETTINGS.APP_ID != 16 && this.services.settings.SETTINGS.APP_ID != 14){
				return [];
			}
		}
		var possibleVMIs:AllDevicesVMI[] = [];
		possibleVMIs.push(new StePagerDeviceViewModelImplemented(this.services, new SteBluetoothConnection(this.services, {name:"RADIATION PAGER"})));
		possibleVMIs.push(new SteRadPackDeviceViewModelImplemented(this.services, new SteBluetoothConnection(this.services, {name:"RADPACK BLE"})));
		possibleVMIs.push(new LrmDeviceViewModelImplemented(this.services, new LrmBluetoothConnection(this.services, {name:"LRM"})));
		// possibleVMIs.push(new SteDevKitDeviceViewModelImplemented(this.services, new SteBluetoothConnection(this.services, {name:"RADIATION PAGER"})));
		return possibleVMIs;
	}

	public async makeDeviceWithType(device: BluetoothCommonInterface): Promise<AllDevicesVMI | undefined> {
		return new Promise( async (resolve,reject) => {
			if(this.services.settings?.SETTINGS.APP_ID){
				if(this.services.settings.SETTINGS.APP_ID != 16 && this.services.settings.SETTINGS.APP_ID != 14){
					resolve(undefined);
					return;
				}
			}
			if(device.name !== undefined){
				console.log(" .::  Searching and comparing ::. ", device.name.toLowerCase())
				var name_filter = "RADIATION";
				console.log(`compare ${device.name.toLowerCase()} against ${name_filter.toLowerCase()} :: ${device.name.toLowerCase().includes(name_filter.toLowerCase())} :: ${device.name.toLowerCase()==("RP")}`)
				if(device.name && (device.name.toLowerCase().includes(name_filter.toLowerCase()) || device.name.toLowerCase()==("RP"))){
					console.log("FOUND RADIATION PAGER :: NRF VERSION");
					if((device.name.toLocaleLowerCase() == "radiation p")||(device.manufactureData && device.manufactureData[0] == 0x01)){
						console.log("ADDING TO THE NRF TYPES : ", device.name)
						console.log("Found Manufacture Data: ", device.manufactureData)
						if(device.identifier){
							var address_number = Number("0x"+device.identifier.replace(/[ :-]+/g, ""))%100000000;
							var uuid = address_number;
							if(device.manufactureData && device.manufactureData.length>0){
								var data:Uint8Array = Uint8Array.from(device.manufactureData);
								console.log("Got manufacture Data After copy: ", EncryptionService.toHexString(data))
								var data_view:DataView = new DataView(data.buffer);
								var serial_number = data_view.getUint32(2, false);
								console.log("Serial Number From Advert: ", serial_number);
							}

							await this.services.proto?.getDeviceViewModelImplemented(uuid).then( (deviceVMI:AllDevicesVMI) => {
								var deviceModel = Device.create();
								console.log("Making a type for STE_NRF")
								deviceModel.deviceType = DeviceTypeIds.STE_NRF;
								deviceModel.uuid = BigInt(uuid);
								if(serial_number && serial_number>0){
									deviceModel.serial = BigInt(serial_number);
								}
								deviceVMI.setDevice(deviceModel);
								deviceVMI.setBlueToothConnection(new SteBluetoothConnection(this.services, device));
								resolve(deviceVMI);
							}).catch( () => {
								var newDevice = new StePagerDeviceViewModelImplemented(this.services);
								var deviceModel = Device.create();
								console.log("Making a type for STE_NRF")
								deviceModel.deviceType = DeviceTypeIds.STE_NRF;
								deviceModel.name = "Radiation Pager- NRF";
								deviceModel.uuid = BigInt(uuid);
								if(serial_number && serial_number>0){
									deviceModel.serial = BigInt(serial_number);
								}
								newDevice.setDevice(deviceModel);
								newDevice.setBlueToothConnection(new SteBluetoothConnection(this.services, device));
								resolve(newDevice);
								return;
							})
						}
					}
					else {
						if(device.identifier){
							var address_number = Number("0x"+device.identifier.replace(/[ :-]+/g, ""))%100000000;
							var uuid = address_number;
							await this.services.proto?.getDeviceViewModelImplemented(uuid).then( (deviceVMI:AllDevicesVMI) => {
								var deviceModel = Device.create();
								deviceModel.deviceType = DeviceTypeIds.STE_PAGER_S;
								deviceModel.uuid = BigInt(uuid);
								deviceVMI.setDevice(deviceModel);
								deviceVMI.setBlueToothConnection(new SteBluetoothConnection(this.services, device));
								resolve(deviceVMI);
							}).catch( () => {
								var newDevice = new StePagerDeviceViewModelImplemented(this.services);
								var deviceModel = Device.create();
								deviceModel.deviceType = DeviceTypeIds.STE_PAGER_S;
								deviceModel.name = "Radiation Pager";
								deviceModel.uuid = BigInt(uuid);
								newDevice.setDevice(deviceModel);
								newDevice.setBlueToothConnection(new SteBluetoothConnection(this.services, device));
								resolve(newDevice);
								return;
							})
						}
					}
				}

				var name_filters = ["RADPACK BLE",];
				for (let index = 0; index < name_filters.length; index++) {
					const name_filter = name_filters[index];
					if(device.name && device.name.toLowerCase().includes(name_filter.toLowerCase())){
						if(device.identifier){
							var address_number = Number("0x"+device.identifier.replace(/[ :-]+/g, ""))%100000000;
							console.log("address_number: ", address_number);
							var uuid = address_number;
							console.log("Asking for device with uuid: ", uuid);
							await this.services.proto?.getDeviceViewModelImplemented(uuid).then( (deviceVMI:AllDevicesVMI) => {
								console.log("Found and exisiting to deviceVMI: ", deviceVMI.checkTimer);
								var deviceModel = Device.create();
								deviceModel.deviceType = DeviceTypeIds.STE_RADPACK;
								deviceModel.name = "RadPack";
								deviceModel.uuid = BigInt(uuid);
								deviceVMI.setDevice(deviceModel);
								deviceVMI.setBlueToothConnection(new SteBluetoothConnection(this.services, device));
								resolve(deviceVMI);
							}).catch( () => {
								var newDevice = new SteRadPackDeviceViewModelImplemented(this.services);
								console.log("Found and new deviceVMI: Calling to set BLUETOOTH CONNECTION");
								var deviceModel = Device.create();
								deviceModel.name = "RadPack";
								deviceModel.deviceType = DeviceTypeIds.STE_RADPACK;
								deviceModel.uuid = BigInt(uuid);
								newDevice.setDevice(deviceModel);
								newDevice.setBlueToothConnection(new SteBluetoothConnection(this.services, device));
								resolve(newDevice);
								return;
							})
						}
					}
				}

				var name_lrm_filter = ["LRM BLE",];
				for (let index = 0; index < name_lrm_filter.length; index++) {
					const name_filter = name_lrm_filter[index];
					if(device.name && device.name.toLowerCase().includes(name_filter.toLowerCase())){
						if(device.identifier){
							console.log("FOUND RADIATION PAGER :: OLD PAGER DETECTED");
							var address_number = Number("0x"+device.identifier.replace(/[ :-]+/g, ""))%100000000;
							console.log("address_number: ", address_number);
							console.log("Device is : ", JSON.stringify(device));
							var uuid = address_number;
							console.log("Asking for device with uuid: ", uuid);
							await this.services.proto?.getDeviceViewModelImplemented(uuid).then( (deviceVMI:AllDevicesVMI) => {
								console.log("Found and exisiting to deviceVMI: ", deviceVMI.checkTimer);
								var deviceModel = Device.create();
								deviceModel.deviceType = DeviceTypeIds.STE_LRM;
								deviceModel.name = "LRM";
								deviceModel.uuid = BigInt(uuid);
								deviceVMI.setDevice(deviceModel);
								deviceVMI.setBlueToothConnection(new LrmBluetoothConnection(this.services, device));
								resolve(deviceVMI);
							}).catch( () => {
								var newDevice = new LrmDeviceViewModelImplemented(this.services);
								console.log("Found and new deviceVMI: Calling to set BLUETOOTH CONNECTION");
								var deviceModel = Device.create();
								deviceModel.name = "LRM";
								deviceModel.deviceType = DeviceTypeIds.STE_LRM;
								deviceModel.uuid = BigInt(uuid);
								newDevice.setDevice(deviceModel);
								newDevice.setBlueToothConnection(new LrmBluetoothConnection(this.services, device));
								resolve(newDevice);
								return;
							})
						}
					}
				}
			}
			console.log("No STE device found")
			resolve(undefined);
			return;
		})
	}
}

// 30364928
// Found and exisiting to deviceVMI:  1661035124194:312
// ADD AND GO TO BLUETOOTH ::  1661035124194:312