import { Component, OnInit, ViewChild, } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Position } from '../../../generated_proto/protobuf-ts/pb/v2/entities';
import { MapSelectPositionComponent } from '../map-select-position/map-select-position.component';

@Component({
	selector: 'app-map-select-position-modal',
	templateUrl: './map-select-position-modal.page.html',
	styleUrls: ['./map-select-position-modal.page.css'],
})
export class MapSelectPositionModalPage implements OnInit {
	
	@ViewChild('mapSelectPositionComponent') mapSelectPositionComponent:MapSelectPositionComponent;

	public existingPosition:Position;

	constructor( 
		public modalController: ModalController,
	)
	{

	}

	ngOnInit() {
	}
	
	async ionViewDidEnter() {
		if(this.mapSelectPositionComponent){
			console.log("Modal starting with existingPosition:", this.existingPosition);
			this.mapSelectPositionComponent.init(this.existingPosition);
		}
	}

	positionSelected(position:{latitude:number, longitude:number}) {
		this.modalController.dismiss({
			"position": position
		});
	}

}
