import { AllServcies, } from '../startup/startup.service';
import { LoggerOptions, } from '../logger/logger.service';
import { WebhookViewModelImplemented } from "../../viewmodels/Webhook.vmi"

import { ModelType } from '../../generated_proto/protobuf-ts/pb/v2/models';

export class WebhookPouchdbService {

	public loggerOptions:LoggerOptions = {
		prefix:"WebhookPouchdbService",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	constructor(
	)
	{

	}
z
	public init(allServices:AllServcies) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(allServices==null){
				reject({code:0, message:"Services Not Given"});
			}
			else {
				this.setServices(allServices);
			}
			if(this.services.pouch && this.services.data)
			resolve(true);
		});
	}

	private services:AllServcies;;
	public setServices(services){
		this.services = services
	}


	public getWebhooks( app_id:number ) : Promise<WebhookViewModelImplemented[]> {
		var webhooks:WebhookViewModelImplemented[] = [];

		var searchOptions = {
			include_docs: true,
			attachments: false,
		}
		return new Promise( (resolve, reject) => {
			if(!this.services.pouch || !this.services.data){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.WEBHOOK_MODEL);
			db.remoteDb.allDocs(searchOptions)
			.then( (result:any) => {
				if(result.rows.length>0){
					for (let index = 0; index < result.rows.length; index++) {
						const row = result.rows[index];
						const doc = row.doc;
						if(doc._id[0] == "_" ){
							continue;
						}
						var newWebhook:WebhookViewModelImplemented = new WebhookViewModelImplemented(this.services);
						newWebhook.updateLocalFromDb(doc);
						webhooks.push(newWebhook);
					}
					resolve(webhooks);
				}
				else {
					resolve([]);
				}
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

	public subscribeWebhook( app_id:number, cb:(webhook:WebhookViewModelImplemented)=>void ) : Promise<boolean> {

		var subOptions = {
			since: 'now',
			live: true,
			include_docs: true
		}
		return new Promise( (resolve, reject) => {
			if(!this.services.pouch || !this.services.data){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.WEBHOOK_MODEL);
			db.remoteDb.changes(subOptions).on('change', function (change) {
				// received a change
				if(change.doc){
					var newWebhook:WebhookViewModelImplemented = new WebhookViewModelImplemented(this.services);
					newWebhook.updateLocalFromDb(change.doc);
					cb(newWebhook);
				}
			}).on('error', function (err) {
				// handle errors
				console.log(err);
			});
		})
	}

	public getWebhook( app_id:number, webhookUuid:number ) : Promise<WebhookViewModelImplemented> {
		var _id = WebhookViewModelImplemented.GenerateDbKey(BigInt(webhookUuid));
		return new Promise( async (resolve, reject) => {
			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.WEBHOOK_MODEL);
			db.remoteDb.get(_id)
			.then( (doc:any) => {
				var newDeviceGroup:WebhookViewModelImplemented = new WebhookViewModelImplemented(this.services);
				newDeviceGroup.updateLocalFromDb(doc);
				resolve(newDeviceGroup);
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}

	public saveWebhook( app_id:number, webhook:WebhookViewModelImplemented ) : Promise<boolean>{
		return new Promise(async (resolve, reject) => {
			if(!webhook.model.webhook){
				reject("No File Found");
				return;
			}
			var full_doc:any = Object.assign({}, webhook.modelToJson());
			full_doc._id = webhook.generateDbKey();

			console.log("Saving full doc:", full_doc);
			if(!this.services.pouch || !this.services.data){
				reject({code:0, message:"Services Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.WEBHOOK_MODEL);
			db.remoteDb.get(webhook.generateDbKey())
			.then((doc) => {
				// update doc
				doc = Object.assign(doc, full_doc);
				db.remoteDb.put(doc) // save full doc
				.then( () => {
					resolve(true)
				})
				.catch( (err) => {
					console.log(err);
					reject(true)
				});
			})
			.catch((err) => {
				db.remoteDb.put(full_doc) // save full doc
				.then( () => {
					resolve(true)
				})
				.catch( (err) => {
					console.log(err);
					reject(true)
				});
			});
		})
	}

	public deleteWebhook( app_id:number, webhook:WebhookViewModelImplemented ) : Promise<boolean>{
		return new Promise( async (resolve, reject) => {
			if(!this.services.pouch){
				reject({code:0, message:"pouch Not Given"});
				return;
			}
			if(!this.services.data){
				reject({code:0, message:"data Not Given"});
				return;
			}
			var db = this.services.pouch.getDbInformationImpelemnted(this.services.data.getDbPrefix(app_id)+ModelType.WEBHOOK_MODEL);
			webhook.updateDb();
			var _id = webhook.generateDbKey();
			console.log("Calling delete on : "+_id);

			db.remoteDb.get(_id)
			.then( (doc:any) => {
				db.remoteDb.remove(doc).then( () => {
					console.log("Deleted");
					resolve(true)
				}).catch( (err) => {
					console.log(err);
					reject(err)
				});
			}).catch( (err) => {
				console.log(err);
				reject(err)
			});
		})
	}
}