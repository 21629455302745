import { GeoNodeType } from "../../generated_proto/protobuf-ts/pb/v2/entities";
import { AllServcies } from "../../services/startup/startup.service";

import { GeoNodeViewModelImplemented, } from "./geonode.vmi";

export class AreaViewModelImplemented extends GeoNodeViewModelImplemented {

	constructor(
		services: AllServcies,
	) {
		super(services);
		if (this.model && this.model.geoNode) {
			this.model.geoNode.type = GeoNodeType.AREA;
		}
	}

	public ionItemDescription: any;
	public getIonItemDescription(): any {
		if (this.ionItemDescription == null) {
			this.ionItemDescription = this.buildIonItemDescription();
		}
		return this.ionItemDescription;
	}
	public buildIonItemDescription(): any {
		var html = `
			<div>
				<ion-label> 
					<div class="ion-label-body">
                        Name: ${this.getName()}<br>
						UUID: ${this.getUUID()}<br>
						Created: ${new Date(Number(this.model?.db?.updatedMs || 0)).toLocaleDateString()} ${new Date(Number(this.model?.db?.updatedMs || 0)).toLocaleTimeString()}<br>                        
					</div>
				</ion-label>
                
			</div>
			`;

		if (this.services.domSanitizer) this.ionItemDescription = this.services.domSanitizer.bypassSecurityTrustHtml(html);
		return this.ionItemDescription;
	}
}
