import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { StartupService } from '../../services/startup/startup.service';

@Pipe({
	name: 'imageAuth'
})
export class ImageAuthPipe implements PipeTransform {

	constructor(
		private http: HttpClient,
		private startupService:StartupService,
	) {}

	async transform(src: string|undefined ): Promise<string> {
		try {
			if(src?.length === 0 || !src || src === 'undefined'){
				throw new Error('No src');
			}
			await this.startupService.ready();
			const token:string|undefined = await this.startupService.services.httpApiClient?.getAuthToken();
			if (!token && token?.length === 0) {
				throw new Error('No token');
			}
			const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
			const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
			const reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onloadend = () => resolve(reader.result as string);
				if(imageBlob){
					reader.readAsDataURL(imageBlob as Blob);
				}
			});
		} catch (e) {
			return 'assets/icons/broken_image.png';
		}
	}

}
