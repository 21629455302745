import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { MapService, TileLayerOptions, TileLayerTypes, } from "../map.service"

@Component({
	selector: 'map-tilelayer-modal',
	templateUrl: './map-tilelayer-modal.component.html',
	styleUrls: ['./map-tilelayer-modal.component.scss'],
})
export class MapTilelayerModalComponent implements OnInit {

	public tileLayers:TileLayerOptions[] = []
	public seletedTileLayerName:string = "";

	constructor(
		private modalController: ModalController,
		private mapService:MapService,
	) {}
	
	public tileLayerTypes = Object.keys(TileLayerTypes);

	ngOnInit() {
		this.tileLayers = this.mapService.getTileLayerOptions();
		this.seletedTileLayerName = this.mapService.getSelectedTileLayerOptions().name;
	}

	onClickBaseLayer(layer: TileLayerOptions) {
		this.modalController.dismiss(layer);
	}

	selectType(type:string){
		var typed = type as TileLayerTypes;
		this.tileLayers = this.mapService.getTileLayerOptions(typed);
	}

	closeModal() {
		this.modalController.dismiss();
	}
}
