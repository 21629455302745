
import { AllServcies } from "../services/startup/startup.service"
// import { DevicePartition, } from '../generated_proto/google/app/model/v1/data_pb';
import { DeviceGroup_Partition } from "../generated_proto/protobuf-ts/pb/v2/entities"

import { ViewModelImplemented } from "./ViewModelImplemented";
import { FirmwareFileViewModelImplemented } from "./FirmwareFile.vmi";
import { LoggerOptions } from "../services/logger/logger.service";

export class DevicePartitionViewModelImplemented extends ViewModelImplemented implements DeviceGroup_Partition {

	order: number = 0; // 0 is reserved for the default partition
	name: string = ""; // EX: "nvm partition"
	description: string = ""; // EX: "Non Volatile Memory partition of the esp32"
	firmwareFileUrns: string[] = []; // searchable in db - major.minor_md5b64

	public loggerOptions:LoggerOptions = {
		prefix:"DevicePartitionViewModelImplemented",
		allOn:true,
		verboseOn:true,
		debugOn:true,
	};

	public firmwareList:FirmwareFileViewModelImplemented[] = [];

	public showChain:boolean = true;

	// Since these objects are created at runtime, they need to be passed references to the Services
	constructor(
		services:AllServcies
	)
	{
		super(); // make sure we call the constructor given from generated protobuf
		// Can pass as param, not used in this case but would
		// be useful behavior for sub classes
		this.services = services;
	}

	update(deviceGroupPartition:DeviceGroup_Partition){
		Object.assign(this, deviceGroupPartition);
		if(this.firmwareFileUrns.length>0){
			for (let index = 0; index < this.firmwareFileUrns.length; index++) {
				const firmwareUrn = this.firmwareFileUrns[index];
				var newFirmware:FirmwareFileViewModelImplemented = new FirmwareFileViewModelImplemented(this.services);
				newFirmware.updateByUrn(firmwareUrn);
				newFirmware.updateDb();
				this.firmwareList.push(newFirmware);
			}
			this.sortFirmware();
		}
		if(!this.order){
			this.order = 0;
		}
		if(this.name){
			this.loggerOptions.prefix = this.loggerOptions.prefix+":"+this.name;
		}
	}

	setName(value: string) {
		this.name = value;
	}

	addFirmware(addFirmware:FirmwareFileViewModelImplemented) {
		if(addFirmware.model.db?.urn){
			// check if it is already in the list
			var found = false;
			for (let index = 0; index < this.firmwareList.length; index++) {
				const firmware = this.firmwareList[index];
				if(firmware.getVersionString() == addFirmware.getVersionString()){
					found = true;
					break;
				}
			}
			if(!found){
				this.firmwareFileUrns.push(addFirmware.model.db.urn);
				this.firmwareList.push(addFirmware);
			}
			else {
				this.services.uiHelper?.showError("Firmware Version Already In List", {duration:1000, userDismiss:false});
			}
		}
		this.sortFirmware();
	}

	deleteFirmware(firmwareFile:FirmwareFileViewModelImplemented){
		console.log("Deleting Firmware: ", firmwareFile);
		if(firmwareFile.model.db?.urn){
			this.firmwareFileUrns = this.firmwareFileUrns.filter(id => id !== firmwareFile.model.db?.urn);
			this.firmwareList = this.firmwareList.filter(id => id !== firmwareFile);
		}
		this.sortFirmware();
	}

	sortFirmware(){
		this.firmwareList = this.firmwareList.sort((a,b)=>{
			var aVersionMajor = a.model.firmware?.verMajor || 0;
			var aVersionMinor = a.model.firmware?.verMinor || 0;
			var aVersion = aVersionMajor +"."+ aVersionMinor;
			var bVersionMajor = b.model.firmware?.verMajor || 0;
			var bVersionMinor = b.model.firmware?.verMinor || 0;
			// shift the version to the right so that it is easier to compare
			var bVersion = bVersionMajor +"."+ bVersionMinor;
			return bVersion.localeCompare(aVersion);
		});
		this.firmwareFileUrns = this.firmwareList.map(firmware => firmware.model.db?.urn || "") || [];
	}

	getLatestFirmwareVersionString(): string {
		this.sortFirmware()
		if(this.firmwareList.length>0){
			var latestFirmware = this.firmwareList[0];
			return "v"+latestFirmware.getDisplayNameVersion();
		}
		return "NOT SET";
	}

	getFirmwareList() : FirmwareFileViewModelImplemented[] {
		return this.firmwareList
	}

	// used in api
	getNextFirmware(verMajor:number, verMinor:number): Promise<FirmwareFileViewModelImplemented> {
		return new Promise( (resolve,reject) => {
			this.sortFirmware();
			var versionString = verMajor+"."+verMinor;
			// check if version string is greater than any in list
			for (let index = 0; index < this.firmwareList.length; index++) {
				const firmware = this.firmwareList[index];
				var firmwareVersionString = firmware.getVersionString();
				if(firmwareVersionString.localeCompare(versionString)>0){
					this.services.logger?.verbose(this.loggerOptions, "Found new firmare : ", firmware.getVersionString());
					resolve(firmware);
					return;
				}
			}
			reject("No New Firmware");
		});
	}

}